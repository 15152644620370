import styled from 'styled-components';

export const Styles = styled.div`
    width: 250px;
    padding: 15px;
    .user-manager-header {
        h4 {
            font-size: 18px;
            color: #f7941d;
        }
    }
    h5 {
        font-size: 15px;
        text-transform: uppercase;
        color: #5e72e4;
    }
    li {
        color: #f7941d;
    }
    .managers, .pickers {
        color: #efeeee;
        margin-bottom: 25px;
    }
    .user-info-container {
        padding: 15px;
    }
`;

export const StyledHr = styled.hr`
    background-color: #344675;
    width: auto;
    margin-top: 0;
    padding-top: 0;
    padding-right: 13px;
    padding-left: 13px;
    margin-bottom: 20px;
`;