import React from 'react';

export const SearchIcon = ({
    fill = '#ffffff',
    width = '18',
    className = 'search-icon',
    height = '18',
    viewBox = '0 0 18 18',
}) => 
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        width={width} 
        height={height} 
        viewBox={viewBox}
    >
        <path d="M.229 15.557l3.42-3.42a7.841 7.841 0 1 1 2.212.213l-3.419 3.42a.782.782 0 0 1-1.107 0L.23 16.664a.782.782 0 1-.001-1.107zm16.206-7.731a6.261 6.261 0 1 0-6.261 6.261 6.268 6.268 0 0 6.261-6.261z" fill={fill} />
    </svg>;

export const AddIcon = ({
    fill = '#efeeee',
    width = '17',
    className = 'add-icon',
    height = '17',
    viewBox = '0 0 17 17',
}) => 
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width={width} 
        height={height} 
        viewBox={viewBox}
    >
        <path d="M15.541 3H4.568A1.567 1.567 0 0 0 3 4.568v10.973a1.567 1.567 0 0 0 1.568 1.568h10.973a1.572 1.572 0 0 0 1.568-1.568V4.568A1.572 1.572 0 0 0 15.541 3zm-1.568 7.838h-3.135v3.135H9.271v-3.135H6.135V9.271h3.136V6.135h1.568v3.136h3.135z" fill={fill} />
    </svg>;

export const UploadIcon = ({
    fill = '#efeeee',
    width = '18',
    className = 'upload-icon',
    height = '19',
    viewBox = '0 0 18 19',
}) => 
    <svg
        xmlns="http://www.w3.org/2000/svg" 
        width={width} 
        height={height} 
        viewBox={viewBox}
    >
        <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" fill={fill}/>
    </svg>;

export const LocalOfferIcon = ({
    fill = '#efeeee',
    width = '22',
    className = 'local-offer-icon',
    height = '18',
    viewBox = '0 0 22 22',
}) => 
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width={width} 
        height={height} 
        viewBox={viewBox}
    >
        <path d="M2.59 11.58l9-9A1.988 1.988 0 0 1 13 2h7a2.006 2.006 0 0 1 2 2v7a2 2 0 0 1-.59 1.42l-9 9A1.988 1.988 0 0 1 11 22a1.955 1.955 0 0 1-1.41-.59l-7-7A1.955 1.955 0 0 1 2 13a2.02 2.02 0 0 1 .59-1.42zm15.063-3.733a1.5 1.5 0 1 0-1.5-1.5 1.5 1.5 0 0 0 1.5 1.5z" fill={fill} />
    </svg>;

export const PencilIcon = ({
    style={
            fill:`none`, 
            stroke: `#cdcaca`, 
            strokeMiterLimit:`10`, 
            strokeWidth:`2px`,
            strokeLinecap:`square`
    }
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.217" height="22.397" viewBox="0 0 22.217 22.397">
            <g id="pencil" transform="translate(1.217 1.181)">
                <g id="Group_1335" data-name="Group 1335">
                    <path id="Line_30" d="M0 0l5.205 5.205" data-name="Line 30" transform="translate(12.189 2.605)" style={style}/>
                    <path id="Line_31" d="M0 0l5.205 5.205" data-name="Line 31" transform="translate(1.041 13.754)" style={style}/>
                    <path id="Path_544" d="M7.949 20.837L2 21.828l.991-5.949 13.47-13.47a1.983 1.983 0 0 1 2.8 0l2.154 2.154a1.983 1.983 0 0 1 0 2.8z" data-name="Path 544" transform="translate(-2 -1.828)" style={style}/>
                </g>
            </g>
        </svg>
    )
}
;

export const TrashIcon = ({
    style={
            fill:`none`, 
            stroke: `#cdcaca`, 
            strokeMiterLimit:`10`, 
            strokeWidth:`2px`,
            strokeLinecap:`round`,
            strokeLineJoin: `round`
    }
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21.954" viewBox="0 0 22 21.954">
            <g id="trash-simple" transform="translate(1 1)">
                <g id="Group_1328" data-name="Group 1328">
                    <path id="Path_502" d="M18 9v14H4V9" data-name="Path 502" transform="translate(-1 -3.046)" style={style}/>
                    <path id="Line_23" d="M0 0h20" data-name="Line 23" transform="translate(0 4.406)" style={style}/>
                    <path id="Line_24" d="M0 0v6" data-name="Line 24" transform="translate(10 9.884)" style={style}/>
                    <path id="Line_25" d="M0 0v6" data-name="Line 25" transform="translate(6.364 9.884)" style={style}/>
                    <path id="Line_26" d="M0 0v6" data-name="Line 26" transform="translate(13.636 9.884)" style={style}/>
                    <path id="Path_503" d="M8 5V1h8v4" data-name="Path 503" transform="translate(-2 -1)" style={style}/>
                </g>
            </g>
        </svg>
    )
}
;

export function SettingsCog (props) {
    return (
        <svg 
            width="16"
            height="16" 
            viewBox="0 0 16 16" 
            id="settingscog-icon"
            fill={props.fill ? props.fill : "#efeeee"}
        >
            <path d="M17 10.455v-2.91h-2.372a5.8 5.8 0 0 0-.62-1.5l1.677-1.677-2.057-2.053-1.677 1.677a5.8 5.8 0 0 0-1.5-.62V1H7.545v2.372a5.8 5.8 0 0 0-1.5.62L4.372 2.315 2.315 4.372l1.677 1.677a5.8 5.8 0 0 0-.62 1.5H1v2.909h2.372a5.8 5.8 0 0 0 .62 1.5l-1.677 1.67 2.057 2.057 1.677-1.677a5.784 5.784 0 0 0 1.5.62V17h2.909v-2.372a5.8 5.8 0 0 0 1.5-.62l1.677 1.677 2.057-2.057-1.677-1.677a5.784 5.784 0 0 0 .62-1.5H17zm-8 .727A2.182 2.182 0 1 1 11.182 9 2.182 2.182 0 0 1 9 11.182z" data-name="Path 345" transform="translate(-1 -1)" />
        </svg>
    )
}