import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.css';

import { useGlobalContext } from '../../context';
import * as shipment_urls from '../../urls/api-shipments/admin';
import axios from 'axios';

const MerchantsMarketsSelection = () => {
  const { setMarketIdMerchants } = useGlobalContext();
  const [options, setOptions] = useState([]);

  function fetchMarkets() {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        query: `{
        Markets {   
          market_name
          market_id
        }
      }`
      }
    })
      .then(res => {
        let optionsBack = res.data.data.Markets.map(d => ({
          value: d.market_id,
          label: d.market_name
        }));
        setOptions(optionsBack);
      })
      .catch(error => {
        throw error;
      });
  }
  useEffect(() => {
    fetchMarkets();
  }, []);

  const selectMarket = selectedOption => {
    setMarketIdMerchants(selectedOption.value);
  };
  return (
    <Select
      // className="mt-4 col-md-8 col-offset-4"
      onChange={selectMarket}
      options={options}
      defaultValue={{ label: 'Lake Charles', value: 'b641bc13-8f56-4f4b-b1ea-c1f833b982bd' }}
    />
  );
};

export default MerchantsMarketsSelection;
