import styled from 'styled-components';

export const Styles = styled.div`
    margin-bottom: 30px;
    height: auto;
    width: 100%;
    background-color: #27293d;
    border-radius: 5px;
    padding: 20px;
        h4 {
            color: #f7941d;
            font-weight: 500;
        }
        .input-container {
            display: flex;
            flex-direction: column;
                label {
                    font-size: 13px;
                    font-weight: 300px;
                    color: #efeeee;
                }
                input {
                    margin-bottom: 25px;
                }
                .description {
                    height: 100px;
                }
        }
        .dropdown-container {
            display: flex;
            flex-direction: row;
                .department, .collection {
                    width: 50%;
                    margin-right: 50px;
                        label {
                            font-size: 13px;
                            font-weight: 300px;
                            color: #efeeee;
                        }
                        .form-control {
                            padding: 0;
                        }
                        .input-button {
                            display: flex;
                            flex-direction: row;
                                button {
                                    height: 35px;
                                    width: 35px;
                                    background-color: #5e72e4;
                                    color: #efeeee;
                                    font-size: 23px;
                                    border-radius: 5px;
                                    border: transparent;
                                    margin-left: 15px;
                                }
                        }
                        .plus-button {
                            height: 32px;
                            width: 32px;
                            margin-left: 5px;
                            cursor: pointer;
                        }
                }
        }
    }
`;