import React, { useState, useEffect, useRef } from 'react';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import './Marker.css';
import config from '../../config';
import MapFilters from './MapFiltersMerchant';
import Polyline from './PolylinesMerchant';
// import Polygon from './PolygonsMerchant';  // Disable for merchant map view
import driverIcon from '../../assets/img/driver_icon.png';
import defaultDriverProfile from '../../assets/img/default_driver_profile.png';

let trafficLayerInstance; // This needs to be a global variable to avoid creating a new one each time

function MerchantMap({
  coordinates,
  trafficLayer,
  setTrafficLayer,
  readyShipmentsCheck,
  setReadyShipmentsCheck,
  deliveringShipmentsCheck,
  setDeliveringShipmentsCheck,
  driversEnrouteCheck,
  setDriversEnrouteCheck,
  readyShipments,
  deliveringShipments,
  driversEnroute,
  shipmentClick,
  google
}) {
  // const defaultCenter = { lat: 30.23119, lng: -93.21163 };
  const [shipmentsToDisplay, setShipmentsToDisplay] = useState([]);
  const [map, setMap] = useState(null);

  const mapRef = useRef(null);

  const [selectedDriver, setSelectedDriver] = useState(null); // Track the selected driver
  const [infoWindowVisible, setInfoWindowVisible] = useState(false); // Control InfoWindow visibility

  useEffect(() => {
    updateShipmentsToDisplay();
  }, [readyShipmentsCheck, readyShipments, deliveringShipmentsCheck, deliveringShipments, shipmentClick]);

  const updateShipmentsToDisplay = () => {
    let allShipments = [];

    if (readyShipmentsCheck && readyShipments.length > 0) {
      readyShipments.forEach(ship => {
        allShipments.push(ship);
      });
    }
    if (deliveringShipmentsCheck && deliveringShipments.length > 0) {
      deliveringShipments.forEach(ship => {
        allShipments.push(ship);
      });
    }
    if (shipmentClick !== '') {
      allShipments.push(shipmentClick);
    } else {
      setInfoWindowVisible(false);
    }

    setShipmentsToDisplay(allShipments);
  };

  useEffect(() => {
    if (!driversEnrouteCheck) {
      setInfoWindowVisible(false);
    }
  }, [driversEnrouteCheck]);

  useEffect(() => {
    if (map && google) {
      trafficLayerInstance = new google.maps.TrafficLayer(); // Create the layer
    }
  }, [map, google]);

  useEffect(() => {
    if (trafficLayerInstance) {
      // Check if the layer exists
      if (trafficLayer) {
        trafficLayerInstance.setMap(map);
      } else {
        trafficLayerInstance.setMap(null);
      }
    }
  }, [trafficLayer]);

  const handleApiLoaded = (mapProps, map) => {
    mapRef.current = map;
    setMap(map);
  };

  const pickUpIcon = {
    url: 'https://maps.google.com/mapfiles/kml/paddle/blu-blank.png',
    scaledSize: new window.google.maps.Size(32, 32)
  };

  const dropOffIcon = {
    url: 'https://maps.google.com/mapfiles/kml/paddle/red-blank.png',
    scaledSize: new window.google.maps.Size(32, 32)
  };

  const trackingIcon = {
    url: 'https://maps.google.com/mapfiles/kml/paddle/grn-blank.png',
    scaledSize: new window.google.maps.Size(32, 32)
  };

  const renderSingleShipmentMarkers = () => {
    if (!shipmentClick) {
      return null;
    }

    return shipmentClick.Orders.map(order => {
      const isPickUp = order.actions.hasOwnProperty('pick_up') && !order.actions['pick_up'][0].includes('(drop)');
      const icon = isPickUp ? pickUpIcon : dropOffIcon;
      const label = isPickUp ? 'Pick Up: ' : 'Drop Off: ';

      return (
        <Marker
          key={order.order_friendly_id}
          position={{ lat: order.location_blob.lat, lng: order.location_blob.lng }}
          icon={icon}
          title={'Ship_ID: ' + shipmentClick.shipment_friendly_id + '\n' + label + order.location_blob.name + '\nAddress: ' + order.location_blob.address_1}
        ></Marker>
      );
    });
  };

  const handleMapClick = () => {
    setInfoWindowVisible(false);
  };

  const handleDriverClick = driver => {
    setSelectedDriver(driver);
    setInfoWindowVisible(true);
  };

  const renderEnrouteDriversMarkers = driversEnroute => {
    return driversEnroute.map(driver => {
      return (
        <Marker
          key={driver.driver_id}
          position={{
            lat: driver.LastDriverActions.location_blob.lat,
            lng: driver.LastDriverActions.location_blob.lng
          }}
          title={`${driver.User.first_name} ${driver.User.last_name}\n${driver.User.phone} `}
          icon={driverIcon}
          onClick={() => handleDriverClick(driver)} // Handle driver click
        />
      );
    });
  };

  const renderSingleDriverMarker = shipmentClick => {
    if (!shipmentClick || (shipmentClick.status !== 'shipment_claimed' && shipmentClick.status !== 'shipment_assigned') || !shipmentClick.driver) {
      return null;
    }
    const driverName = `${shipmentClick.Route.RouteDrivers[0].driver_blob.first_name} ${shipmentClick.Route.RouteDrivers[0].driver_blob.last_name}`;
    const phone = shipmentClick.Route.RouteDrivers[0].driver_blob.phone;

    return (
      <Marker
        key={shipmentClick.driver.driver_id}
        position={{
          lat: shipmentClick.driver.LastDriverActions.location_blob.lat,
          lng: shipmentClick.driver.LastDriverActions.location_blob.lng
        }}
        title={`Ship_ID: ${shipmentClick.shipment_friendly_id}\nDriver: ${driverName}\nPhone: ${phone}`}
        icon={driverIcon}
        onClick={() => handleDriverClick(shipmentClick.driver)}
      />
    );
  };

  const renderSingleShipmentTQLTrackingMarkers = shipmentClick => {
    if (
      !shipmentClick ||
      !(shipmentClick.status === 'shipment_claimed' || shipmentClick.status === 'shipment_assigned') ||
      !shipmentClick.driver ||
      !shipmentClick.Route.RouteDrivers[0].tracking_blob ||
      !shipmentClick.Route.RouteDrivers[0].tracking_blob.hasOwnProperty('status') ||
      !(shipmentClick.Route.RouteDrivers[0].tracking_blob.status === 'IN TRANSIT' || shipmentClick.Route.RouteDrivers[0].tracking_blob.status === 'DELIVERED') ||
      !shipmentClick.Route.RouteDrivers[0].tracking_blob.hasOwnProperty('trackingDetails') ||
      !(shipmentClick.Route.RouteDrivers[0].tracking_blob.trackingDetails.length > 0)
    ) {
      return null;
    }
    return shipmentClick.Route.RouteDrivers[0].tracking_blob.trackingDetails.map((track, index) => {
      return (
        <Marker
          // key={track.time} can't use time as key, which cause one marker not to disappear when shipmentClick changes to null
          key={index}
          position={{ lat: track.latitude, lng: track.longitude }}
          icon={trackingIcon}
          title={'Ship_ID: ' + shipmentClick.shipment_friendly_id + '\nTime: ' + track.time + '\nLocation:' + track.location}
        ></Marker>
      );
    });
  };

  const renderMarkersForReadyShipments = () => {
    if (readyShipments.length === 0) {
      return null;
    }

    return readyShipments.map(shipment => {
      return shipment.Orders.map(order => {
        const isPickUp = order.actions.hasOwnProperty('pick_up') && !order.actions['pick_up'][0].includes('(drop)');
        const icon = isPickUp ? pickUpIcon : dropOffIcon;
        const label = isPickUp ? 'Pick Up: ' : 'Drop Off: ';

        return (
          <Marker
            key={order.order_friendly_id}
            position={{ lat: order.location_blob.lat, lng: order.location_blob.lng }}
            icon={icon}
            title={'Ship_ID: ' + shipment.shipment_friendly_id + '\n' + label + order.location_blob.name + '\nAddress: ' + order.location_blob.address_1}
          ></Marker>
        );
      });
    });
  };

  const renderMarkersForDeliveringShipments = () => {
    if (deliveringShipments.length === 0) {
      return null;
    }

    return deliveringShipments.map(shipment => {
      return shipment.Orders.map(order => {
        const isPickUp = order.actions.hasOwnProperty('pick_up') && !order.actions['pick_up'][0].includes('(drop)');
        const icon = isPickUp ? pickUpIcon : dropOffIcon;
        const label = isPickUp ? 'Pick Up: ' : 'Drop Off: ';

        return (
          <Marker
            key={order.order_friendly_id}
            position={{ lat: order.location_blob.lat, lng: order.location_blob.lng }}
            icon={icon}
            title={'Ship_ID: ' + shipment.shipment_friendly_id + '\n' + label + order.location_blob.name + '\nAddress: ' + order.location_blob.address_1}
          ></Marker>
        );
      });
    });
  };

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <MapFilters
        trafficLayer={trafficLayer}
        setTrafficLayer={setTrafficLayer}
        readyShipmentsCheck={readyShipmentsCheck}
        setReadyShipmentsCheck={setReadyShipmentsCheck}
        deliveringShipmentsCheck={deliveringShipmentsCheck}
        setDeliveringShipmentsCheck={setDeliveringShipmentsCheck}
        driversEnrouteCheck={driversEnrouteCheck}
        setDriversEnrouteCheck={setDriversEnrouteCheck}
      />
      <Map
        google={google}
        initialCenter={{ lat: coordinates.latitude, lng: coordinates.longitude }}
        //! This line cause the map jump!!!!
        // center={{ lat: coordinates.latitude, lng: coordinates.longitude }}
        zoom={11}
        onReady={handleApiLoaded}
        visible={true}
        style={{ width: '100%', height: '80%' }}
        disableDefaultUI
        onClick={handleMapClick}
      >
        {renderSingleShipmentMarkers()}
        {renderSingleDriverMarker(shipmentClick)}
        {renderEnrouteDriversMarkers(driversEnroute)}
        {renderMarkersForReadyShipments()}
        {renderMarkersForDeliveringShipments()}
        {renderSingleShipmentTQLTrackingMarkers(shipmentClick)}
        <Polyline map={mapRef.current} google={google} shipments={shipmentsToDisplay} />
        {/* <Polygon map={mapRef.current} google={google} shipments={shipmentsToDisplay} /> */}
        {selectedDriver && (
          <InfoWindow
            visible={infoWindowVisible}
            position={{
              lat: selectedDriver.LastDriverActions.location_blob.lat,
              lng: selectedDriver.LastDriverActions.location_blob.lng
            }}
            onClose={() => setInfoWindowVisible(false)} // Close InfoWindow on close
          >
            <div style={{ padding: '2px' }}>
              <p style={{ color: 'black', fontSize: 15, fontWeight: 'bold' }}>{`${selectedDriver.User.first_name} ${selectedDriver.User.last_name}`}</p>
              <p style={{ color: 'black' }}>{selectedDriver.User.phone}</p>
              <p style={{ color: 'black' }}>{selectedDriver.User.vehicle_info}</p>
              {selectedDriver.User.permit && <p style={{ color: 'black' }}>Permit: {selectedDriver.User.permit}</p>}
              <img src={selectedDriver.User.profile_photo_url || defaultDriverProfile} alt="Default Profile" style={{ width: '100px', height: 'auto' }} />
            </div>
          </InfoWindow>
        )}
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: config.config.GOOGLE_MAP.accessKey
})(MerchantMap);
