import { AUTH_CONFIG } from './auth0-variables';
var jwks = require('jwks-rsa');
var jwt = require('jsonwebtoken');

export default async function getRoles() {
    if(AUTH_CONFIG.client === null) {
        AUTH_CONFIG.client = jwks({
            cache: true,
            rateLimit: true,
            jwksRequestsPerMinute: 10,
            jwksUri: AUTH_CONFIG.jwksUri,
            strictSsl: false
        });
    }
    let client = await AUTH_CONFIG.client;
    let promise = new Promise((resolve, reject) => {
        client.getSigningKey(AUTH_CONFIG.SIGNING_KEY, function(err, key) {
            if(err) {
                console.log(err);
            }
            var signingKey = key.publicKey || key.rsaPublicKey;
            jwt.verify(localStorage.getItem("access_token"), signingKey, { algorithms: AUTH_CONFIG.algorithms }, function(error, decode) {
                if(error) {
                    resolve("error");
                }
                else {
                    resolve(decode['https://gophr.app/roles']);
                }
            });
        });
      });

    var resp = await promise;
    
    return resp;
  }