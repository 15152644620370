import React, { useState, useEffect } from 'react';
import { Input, ListGroup, ListGroupItem } from 'reactstrap';
import * as shipment_urls from '../../urls/api-shipments/merchant';
import axios from 'axios';

const WaypointCustomerOnlyDropAutoComplete = ({ dropWaypointsArray, setDropWaypointsArray, index, storeId }) => {
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState('');
  const [hasSelected, setHasSelected] = useState(false);
  const [focus, setFocus] = useState(true);

  const reset = () => {
    setDropWaypointsArray(
      dropWaypointsArray.map((ar, i) =>
        i === index
          ? {
              ...dropWaypointsArray[index],
              isWaypointAutoFilled: false,
              waypointFirstname: '',
              waypointLastname: '',
              waypointPhone: '',
              waypointFullAddress: '',
              waypointAddress1: null,
              waypointAddress2: null,
              waypointCity: '',
              waypointState: '',
              waypointZip: '',
              waypointCountry: '',
              waypointLat: '',
              waypointLng: '',
              waypointDropoffInstruction: '',
              waypointBusinessName: '',
              waypointCustomerSuggestions: [],
              dropItemsError: [false]
            }
          : ar
      )
    );
  };

  useEffect(() => {
    if (!focus && !hasSelected) {
      setShowSuggestions(false);
    }
  }, [focus, hasSelected]);

  useEffect(() => {
    if (dropWaypointsArray[index].waypointCustomerSuggestions.length > 0) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [dropWaypointsArray[index].waypointCustomerSuggestions]);

  useEffect(() => {
    if (!hasSelected) {
      fetchCustomer(input, storeId);
    }
  }, [input, hasSelected]);

  useEffect(() => {
    if (input == '' && dropWaypointsArray[index].isWaypointAutoFilled) {
      reset();
    }
    // setIsWaypointAutoFilled(true);
  }, [input]);

  useEffect(() => {
    if (dropWaypointsArray[index].isWaypointAutoFilled) {
      setDropWaypointsArray(dropWaypointsArray.map((ar, i) => (i === index ? { ...dropWaypointsArray[index], isWaypointAutoFilled: false } : ar)));
    }
  }, [dropWaypointsArray[index].waypointFullAddress]);

  const fetchCustomer = (customerName, storeId) => {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        query:
          `{
          direct_customer_search(args: {customer_name: "` +
          customerName +
          `", st_id: "` +
          storeId +
          `"}) {
            drop_off_instructions
            pick_up_instructions
            location_blob
          }
        }`
      }
    })
      .then(response => {
        if (response.data.data) {
          setDropWaypointsArray(
            dropWaypointsArray.map((ar, i) => (i === index ? { ...dropWaypointsArray[index], waypointCustomerSuggestions: response.data.data.direct_customer_search } : ar))
          );
        } else {
          console.log('getting autofill customer error: ', response);
        }
      })
      .catch(error => {
        console.log('autofill-error ', error);
      });
  };

  const onChange = e => {
    const userInput = e.target.value;
    setInput(userInput);
    setDropWaypointsArray(dropWaypointsArray.map((ar, i) => (i === index ? { ...dropWaypointsArray[index], waypointFirstname: userInput } : ar)));
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const onClick = indexSuggest => {
    let selectedInfo = dropWaypointsArray[index].waypointCustomerSuggestions[indexSuggest];
    setHasSelected(true); //! solve the suggestion shows up again after the click
    setDropWaypointsArray(
      dropWaypointsArray.map((ar, i) =>
        i === index
          ? {
              ...dropWaypointsArray[index],
              isWaypointAutoFilled: true,
              waypointFirstname: selectedInfo.location_blob.first_name,
              waypointLastname: selectedInfo.location_blob.last_name,
              waypointPhone: selectedInfo.location_blob.phone,
              waypointFullAddress: selectedInfo.location_blob.address,
              waypointAddress1: selectedInfo.location_blob.address_1,
              waypointAddress2: selectedInfo.location_blob.address_2,
              waypointCity: selectedInfo.location_blob.city,
              waypointState: selectedInfo.location_blob.state,
              waypointZip: selectedInfo.location_blob.zip,
              waypointCountry: selectedInfo.location_blob.country,
              waypointLat: selectedInfo.location_blob.lat,
              waypointLng: selectedInfo.location_blob.lng,
              waypointBusinessName: selectedInfo.location_blob.business_name,
              waypointDropoffInstruction: selectedInfo.drop_off_instructions,
              waypointCustomerSuggestions: []
            }
          : ar
      )
    );
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    setHasSelected(false);
    // setIsWaypointAutoFilled(false);
  };

  const SuggestionsListComponent = () => {
    return dropWaypointsArray[index].waypointCustomerSuggestions.length ? (
      <ListGroup flush>
        {dropWaypointsArray[index].waypointCustomerSuggestions.map((suggestion, indexSuggest) => {
          if (indexSuggest < 5) {
            return (
              <ListGroupItem
                style={{ backgroundColor: '#1e1e2b', border: 'none', color: '#cbcac9' }}
                onMouseOver={e => (e.target.style.background = 'linear-gradient(90deg, hsla(218, 100%, 42%, 0.6) 0%, hsla(0, 0%, 98%, 1) 200%)')}
                onMouseLeave={e => (e.target.style.background = '#1e1e2b')}
                key={indexSuggest}
                onClick={() => onClick(indexSuggest)}
              >
                {`${suggestion.location_blob.name}, ${suggestion.location_blob.phone}, ${suggestion.location_blob.address_1}`}
              </ListGroupItem>
            );
          }
        })}
      </ListGroup>
    ) : null;
  };

  return (
    <>
      <Input
        type="text"
        onChange={onChange}
        onBlur={() => {
          setTimeout(() => {
            setFocus(false);
          }, '200');
        }}
        onClick={() => setFocus(true)}
        // onKeyDown={onKeyDown}
        required
        // readOnly={isWaypointStore}
        name="storeFirstName"
        id="customer-first-name"
        placeholder="John"
        value={dropWaypointsArray[index].waypointFirstname}
      />
      {showSuggestions && input && <SuggestionsListComponent />}
    </>
  );
};

export default WaypointCustomerOnlyDropAutoComplete;
