import config from '../../config';
export const usersHost = config.config.api_users.admin.usersHost;
export const hasuraHost = config.config.api_users.admin.hasuraHost;
export const hasuraRest = config.config.api_users.admin.hasuraRest;
export const wssHasuraHost = config.config.api_users.admin.wssHasuraHost;

//Admin routes
export const createSuperMerchant = usersHost + '/admin/create/super-merchant';
export const createDriver = usersHost + '/admin/create/driver';
export const driverEndpoint = usersHost + '/admin/driver/';
export const editUser = usersHost + '/admin/edit/'; // + access token
