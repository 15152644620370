import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import axios from 'axios';

import { Styles } from './Styles/LandingContainerStyles';
import Loading from '../Global/Loading';
import { Department } from './Departments';
import { Category } from './Categories';
import { Product } from './Products';
import RightSideBar from './RightSideBar';
import EditStoreContainer from './EditStore/EditStoreContainer';
import getRoles from '../../Auth/getRoles';

import * as inventory_urls from '../../urls/api-inventory/admin';

export default function LandingContainer(props) {
  const history = useHistory();
  const [store, setStore] = useState([]);
  const [loading, setLoading] = useState(true);
  const [storeId, setStoreId] = useState(props.location.pathname.split('/')[4]);
  const [roles, setRoles] = useState('99999');

  useEffect(() => {
    getRoles().then(val => {
      if (val === 'error') {
        history.push('/logout');
      } else {
        setRoles(val);
      }
    });
  }, []);

  const getStores = storeId => {
    axios({
      method: `post`,
      url: inventory_urls.hasuraHost,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      data: { query: `{Stores(where: {store_id: {_eq: "` + storeId + `"}}) { store_id city state name phone logo address_1 address_2 StoreOwnerships {email} } }` }
    })
      .then(response => {
        setLoading(false);
        if (response.data.errors) {
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].message === 'Malformed Authorization header' || response.data.errors[0].extensions.code === 'invalid-jwt') {
              this.props.history.push('/logout');
            }
          }
        }
        if (response.data.data) {
          console.log('store ', response);
          setStore(response.data.data.Stores[0]);
        }
      })
      .catch(error => {});
  };

  useEffect(() => {
    getStores(storeId);
  }, []);

  if (loading) return <Loading />;
  return (
    <Styles className="landing-container">
      {roles[0] < 230 ? <RightSideBar roles={roles} storeId={props.match.params.id} /> : null}
      <Switch>
        <Route path="/admin/inventory/store/:store_id/edit" component={EditStoreContainer} />
        <Route path="/admin/inventory/store/:store_id/category/:category_id" component={Product} />
        <Route path="/admin/inventory/store/:store_id/department/:department_id" component={Category} />
        <Route exact path="/admin/inventory/store/:id" render={props => <Department storeId={props.match.params.id} />} />
      </Switch>
    </Styles>
  );
}
