import React from 'react';
import { Label, FormGroup, Input, Row, Col } from 'reactstrap';
import PickupCustomerAutoComplete from './PickupCustomerAutoComplete';
//! Google Map AutoComplete
import GoogleMapAutoComplete from '../../../Helpers/GoogleMapAutoComplete';
import '../../../Helpers/googleMapAutoComplete.css';
import MapboxAutocomplete from '../../../../assets/react-mapbox-autocomplete/ReactMapboxAutocomplete';
import '../../../../assets/react-mapbox-autocomplete/index.css';
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ29waHItZW5naW5lZXJpbmciLCJhIjoiY2syYzd6NjdrMmo3azNtcGU1cXZzMTh1MSJ9.cHMxwcG_MoQk-l4ZO3bdEA';

const PickupInfoRegular = ({
  isPickupStore,
  setIsPickupStore,
  setIsDropoffStore,
  errors,
  storeFirstName,
  pickFirstname,
  setPickFirstname,
  pickupCustomerSuggestions,
  setPickupCustomerSuggestions,
  storeId,
  setPickLastname,
  setPickPhone,
  pickFullAddress,
  setPickFullAddress,
  setPickupInstruction,
  isPickupAutoFilled,
  setIsPickupAutoFilled,
  setPickAddress1,
  setPickAddress2,
  setPickCity,
  setPickState,
  setPickZip,
  setPickCountry,
  setPickLat,
  setPickLng,
  setPickBusinessName,
  storeLastName,
  pickLastname,
  storePhone,
  pickPhone,
  storeName,
  pickBusinessName,
  storeAddress,
  handlePickupSelect,
  vendorLat,
  vendorLong,
  pickupInstruction
}) => {
  return (
    <>
      <Row style={{ paddingTop: 0 }}>
        <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="store-address">
          <Label check>
            <Input
              required
              type="radio"
              checked={isPickupStore}
              value="mp"
              name="store-address-radios"
              id="store-address"
              onClick={() => {
                setIsPickupStore(true);
                setIsDropoffStore(false);
              }}
            />
            <span id="radio" className="form-check-sign" /> Store Address
          </Label>
        </FormGroup>
        <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="shipment-weight">
          <Label check>
            <Input
              required
              type="radio"
              checked={!isPickupStore}
              name="store-address-radios"
              value="dp"
              id="different-address"
              onClick={() => {
                setIsPickupStore(false);
              }}
            />
            <span id="radio" className="form-check-sign" /> Different Address
          </Label>
        </FormGroup>
      </Row>
      <Row form={true}>
        <Col style={{ textAlign: 'left' }}>
          <Label for="storeFirstName">First Name</Label>
          <FormGroup className={errors.pickupFirstname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
            {/* <Input
                  required
                  type="text"
                  readOnly={isPickupStore}
                  name="storeFirstName"
                  id="customer-first-name"
                  placeholder="John"
                  value={isPickupStore ? storeFirstName : pickFirstname}
                  onChange={e => (isPickupStore ? null : setPickFirstname(e.target.value))}
                /> */}
            <PickupCustomerAutoComplete
              isPickupStore={isPickupStore}
              storeFirstName={storeFirstName}
              pickFirstname={pickFirstname}
              setPickFirstname={setPickFirstname}
              pickupCustomerSuggestions={pickupCustomerSuggestions}
              setPickupCustomerSuggestions={setPickupCustomerSuggestions}
              storeId={storeId}
              setPickLastname={setPickLastname}
              setPickPhone={setPickPhone}
              pickFullAddress={pickFullAddress}
              setPickFullAddress={setPickFullAddress}
              setPickupInstruction={setPickupInstruction}
              isPickupAutoFilled={isPickupAutoFilled}
              setIsPickupAutoFilled={setIsPickupAutoFilled}
              setPickAddress1={setPickAddress1}
              setPickAddress2={setPickAddress2}
              setPickCity={setPickCity}
              setPickState={setPickState}
              setPickZip={setPickZip}
              setPickCountry={setPickCountry}
              setPickLat={setPickLat}
              setPickLng={setPickLng}
              setPickBusinessName={setPickBusinessName}
            />
            <div hidden={!errors.pickupFirstname}>
              <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
            </div>
          </FormGroup>
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <Label for="storeLastName">Last Name</Label>
          <FormGroup className={errors.pickupLastname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
            <Input
              required
              readOnly={isPickupStore}
              type="text"
              name="storeLastName"
              id="store-last-name"
              placeholder="Doe"
              onChange={e => (isPickupStore ? null : setPickLastname(e.target.value))}
              value={isPickupStore ? storeLastName : pickLastname}
            />
            <div hidden={!errors.pickupLastname}>
              <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
            </div>
          </FormGroup>
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <Label for="storePhone">Phone Number</Label>
          <FormGroup className={errors.pickupPhone ? 'has-danger' : 'thisIsAPlaceholderClass'}>
            <Input
              readOnly={isPickupStore}
              required
              type="text"
              name="storePhone"
              id="store-phone"
              placeholder="555-555-5555"
              onChange={e => (isPickupStore ? null : setPickPhone(e.target.value))}
              value={isPickupStore ? storePhone : pickPhone}
            />
            <div hidden={!errors.pickupPhone}>
              <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid phone number (10-13 digits)</p>
            </div>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="order-pickup">Business Name (optional)</Label>
            <Input
              readOnly={isPickupStore}
              type="text"
              name="pickBusinessName"
              id="pick-business-name"
              placeholder="Store Name"
              onChange={e => (isPickupStore ? null : setPickBusinessName(e.target.value))}
              value={isPickupStore ? storeName : pickBusinessName}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label>Pickup Address</Label>
            {isPickupAutoFilled ? (
              <Input value={isPickupStore ? storeAddress : pickFullAddress} onChange={e => (isPickupStore ? null : setPickFullAddress(e.target.value))} />
            ) : (
              // <MapboxAutocomplete
              //   id="mapbox-autocomplete"
              //   inputClass={errors.pickupAddress ? 'form-control mb-2' + ' has-danger' : 'form-control mb-2 has-danger'}
              //   publicKey={MAPBOX_TOKEN}
              //   onSuggestionSelect={handlePickupSelect}
              //   country="us"
              //   resetSearch={false}
              //   readOnly={isPickupStore}
              //   defaultValue={isPickupStore ? storeAddress : null}
              //   value={isPickupStore ? storeAddress : pickFullAddress}
              //   query={pickFullAddress}
              //   placeholder="Street Address"
              //   proximityLat={vendorLat}
              //   proximityLng={vendorLong}
              // />
              <GoogleMapAutoComplete
                inputClass={errors.pickupAddress ? 'form-control mb-2' + ' has-danger' : 'form-control mb-2 has-danger'}
                onSuggestionSelect={handlePickupSelect}
                country="us"
                resetSearch={false}
                readOnly={isPickupStore}
                defaultValue={isPickupStore ? storeAddress : null}
                value={isPickupStore ? storeAddress : pickFullAddress}
                query={pickFullAddress}
                placeholder="Street Address"
                proximityLat={vendorLat}
                proximityLng={vendorLong}
              />
            )}

            {/* <div hidden={errorMessageDistance.errorCode !== 1}>
              <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageDistance.errorMessage}</p>
            </div> */}
            <div hidden={!errors.pickupAddress}>
              <p style={{ color: 'red', marginTop: '4px' }}>Please choose an address from the dropdown menu</p>
            </div>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="order-pickup">Pickup Instructions (optional)</Label>
            <Input
              type="text"
              name="dropoff"
              id="pickup-instructions"
              placeholder="Park in back, attendant will assist."
              onChange={e => {
                if (e.target.value.length < 510) {
                  return setPickupInstruction(e.target.value);
                } else {
                  return;
                }
              }}
              value={pickupInstruction}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default PickupInfoRegular;
