import React from 'react';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.css';

import { useGlobalContext } from '../../context';

const DispatchMarketsSelection = ({ memoizedOptions }) => {
  const { setMarketId, setCoordinates, setMarketIdArray, setIsSingleMarket, selectedMarketLabelValue, setSelectedMarketLabelValue, setRegionId } = useGlobalContext();

  const selectMarket = selectedOption => {
    setSelectedMarketLabelValue(selectedOption);
    if (selectedOption.label.includes('Country') || selectedOption.label.includes('Region')) {
      setMarketIdArray(selectedOption.value.idArray);
      setIsSingleMarket(false);
      if (selectedOption.label.includes('Region')) {
        setRegionId(selectedOption.value.id);
      } else {
        setRegionId('');
      }
    } else {
      setMarketId(selectedOption.value.id);
      setMarketIdArray([selectedOption.value.id]);
      setIsSingleMarket(true);
    }
    setCoordinates(selectedOption.value.coordinates);
  };

  const formatOptionLabel = ({ label }) => {
    const spaces = label.match(/^\s*/)[0]; // Get the number of spaces at the front of the label
    return (
      <div style={{ whiteSpace: 'pre' }}>
        {spaces} {/* Add the spaces at the front */}
        {label.trim()} {/* Remove the spaces from the label */}
      </div>
    );
  };

  return (
    <Select
      // className="mt-4 col-md-8 col-offset-4"
      value={selectedMarketLabelValue}
      onChange={selectMarket}
      options={memoizedOptions}
      formatOptionLabel={formatOptionLabel}
      // defaultValue={{ label: 'Lake Charles, LA', value: { id: 'b641bc13-8f56-4f4b-b1ea-c1f833b982bd', coordinates: { latitude: 30.23119, longitude: -93.21163 } } }}
    />
  );
};

export default DispatchMarketsSelection;
