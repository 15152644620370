import styled from 'styled-components';

export const Styles = styled.div`
    background-color: #27293d;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 30px;
    width: 100%;
        h4 {
            color: #f7941d;
            font-weight: 500;
        }
        label {
            font-size: 13px;
            font-weight: 300px;
            color: #efeeee;
        }
        select {
          background-color: #27293d;
          color: #efeeee; 
        }
        .weight-row {
            width: 50%;
        }
        .input-select-container {
            display: flex;
            flex-direction: inherit;
        }
`;

export const StyledHr = styled.hr`
    background-color: #344675;
    width: auto;
    margin-top: 0;
    padding-top: 0;
    padding-right: 13px;
    padding-left: 13px;
    margin-bottom: 20px;
`;