import React from 'react';
import ReactDOM from 'react-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CardSetupForm from './CardSetupFrom';
import { Styles, StyledHr } from '../Styles/AddCardStyle';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

import config from '../../../config';

const publicKey = config.config.STRIPE.publicKey;
const connectedAccountID = config.config.STRIPE.connectedAccountID;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const stripePromise = loadStripe(publicKey, {
  stripeAccount: connectedAccountID
});

export const AddCard = ({ closePaymentModal }) => {
  return (
    <Styles className="store-manager-container">
      <button
        style={{
          position: 'relative',
          top: '2.7rem',
          left: '33.7rem',
          fontSize: '1.5rem',
          cursor: 'pointer',
          color: 'hsl(360, 67%, 44%)',
          zIndex: '1',
          background: 'transparent'
        }}
        onClick={closePaymentModal}
      >
        <FaTimes />
      </button>
      <Card>
        <CardHeader className="store-header">
          <h4>Add Card</h4>
          <StyledHr />
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Elements stripe={stripePromise}>
                <CardSetupForm />
              </Elements>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Styles>
  );
};
