import React, { useState, useEffect } from 'react';
import { Label, Input, FormGroup, Col } from 'reactstrap';
import ForkLift, { HandLoadUnload, Dolly, Hazmat, HotBag, Cooler, TWIC, PalletJack, Liftgate, Umbrella, Medical } from '../../../../assets/svgs/ToolsAndEquipments';

const Tools = ({
  setIsForkLift,
  isForkLift,
  setIsDolly,
  isDolly,
  setIsHandLoadUnload,
  isHandLoadUnload,
  setIsHazmat,
  isHazmat,
  setIsHotBag,
  isHotBag,
  setIsCooler,
  isCooler,
  isTWICCard,
  setIsTWICCard,
  isLiftgate,
  setIsLiftgate,
  isPalletJack,
  setIsPalletJack,
  isWeatherProtection,
  setIsWeatherProtection,
  isMedical,
  setIsMedical,
  weight,
  vehicleType
}) => {
  const [isForkLiftHover, setIsForkLiftHover] = useState(false);
  const [isDollyHover, setIsDollyHover] = useState(false);
  const [isHandLoadUnloadHover, setIsHandLoadUnloadHover] = useState(false);
  const [isHazmatHover, setIsHazmatHover] = useState(false);
  const [isHotBagHover, setIsHotBagHover] = useState(false);
  const [isCoolerHover, setIsCoolerHover] = useState(false);
  const [isTWICCardHover, setIsTWICCardHover] = useState(false);
  const [isLiftgateHover, setIsLiftgateHover] = useState(false);
  const [isPalletJackHover, setIsPalletJackHover] = useState(false);
  const [isUmbrellaHover, setIsUmbrellaHover] = useState(false);
  const [isMedicalHover, setIsMedicalHover] = useState(false);
  const [isHandLoadUnloadDisabled, setIsHandLoadUnloadDisabled] = useState(false);
  const [isLiftgateDisabled, setIsLiftgateDisabled] = useState(false);

  useEffect(() => {
    if (isLiftgate) {
      setIsHandLoadUnloadDisabled(true);
    } else {
      setIsHandLoadUnloadDisabled(false);
    }
    setIsHandLoadUnload(false);
  }, [isLiftgate]);

  useEffect(() => {
    if (vehicleType) {
      if (!(vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'box_truck')) {
        setIsLiftgateDisabled(true);
      } else {
        setIsLiftgateDisabled(false);
      }
      setIsLiftgate(false);
    }
  }, [vehicleType]);

  return (
    <>
      <Col md="auto">
        <Label inline for="equipment_tools" style={{ textAlign: 'left' }}>
          Equipments and Tools Needed
        </Label>
        <div style={{ marginTop: '20px' }}>
          <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
            <Label check>
              <div style={{ marginTop: '-12px' }} onMouseEnter={() => setIsHandLoadUnloadHover(true)} onMouseLeave={() => setIsHandLoadUnloadHover(false)}>
                <HandLoadUnload name="HandLoadUnload" fill={isHandLoadUnloadDisabled ? '#333333' : '#5e72e4'}></HandLoadUnload>
              </div>
              {!isHandLoadUnloadDisabled && (
                <Input
                  type="checkbox"
                  id="tool-check"
                  onClick={e => {
                    setIsHandLoadUnload(!isHandLoadUnload);
                  }}
                  disabled={isHandLoadUnloadDisabled}
                />
              )}
              <span className="form-check-sign" style={{ marginLeft: '12px' }} />
              {isHandLoadUnloadHover && 'Hand load'}
            </Label>
          </FormGroup>
        </div>
        <div style={{ marginTop: '20px' }}>
          <FormGroup check className="mt-3">
            <Label check>
              <div style={{ marginTop: '-15px', marginLeft: '10px' }} onMouseEnter={() => setIsLiftgateHover(true)} onMouseLeave={() => setIsLiftgateHover(false)}>
                <Liftgate name="Liftgate" fill={isLiftgateDisabled ? '#333333' : '#5e72e4'}></Liftgate>
              </div>
              {!isLiftgateDisabled && (
                <Input
                  type="checkbox"
                  id="tool-check"
                  onClick={e => {
                    setIsLiftgate(!isLiftgate);
                  }}
                  disabled={isLiftgateDisabled}
                />
              )}
              <span className="form-check-sign" style={{ marginLeft: '15px', marginBottom: '2px' }} />
              {isLiftgateHover && 'Liftgate'}
            </Label>
          </FormGroup>
        </div>

        <div style={{ marginTop: '20px' }}>
          <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
            <Label check>
              <div style={{ marginTop: '-15px', marginLeft: '10px' }} onMouseEnter={() => setIsPalletJackHover(true)} onMouseLeave={() => setIsPalletJackHover(false)}>
                <PalletJack name="PalletJack"></PalletJack>
              </div>
              <Input
                type="checkbox"
                id="tool-check"
                onClick={e => {
                  setIsPalletJack(!isPalletJack);
                }}
              />
              <span className="form-check-sign" style={{ marginLeft: '12px' }} />
              {isPalletJackHover && 'Pallet Jack'}
            </Label>
          </FormGroup>
        </div>

        <div style={{ marginTop: '20px' }}>
          <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
            <Label check>
              <div style={{ marginTop: '-15px' }} onMouseEnter={() => setIsDollyHover(true)} onMouseLeave={() => setIsDollyHover(false)}>
                <Dolly name="Dolly"></Dolly>
              </div>
              <Input
                type="checkbox"
                id="tool-check"
                onClick={e => {
                  setIsDolly(!isDolly);
                }}
              />
              <span className="form-check-sign" style={{ marginLeft: '20px' }} />
              {isDollyHover && 'Dolly'}
            </Label>
          </FormGroup>
        </div>
      </Col>
      <Col md="auto" style={{ marginLeft: -25, width: '12%' }}>
        <div style={{ marginTop: '43px' }}>
          <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
            <Label check>
              <div style={{ marginTop: '-14px' }} onMouseEnter={() => setIsHazmatHover(true)} onMouseLeave={() => setIsHazmatHover(false)}>
                <Hazmat name="Hazmat"></Hazmat>
              </div>
              <Input
                type="checkbox"
                id="tool-check"
                onClick={e => {
                  setIsHazmat(!isHazmat);
                }}
              />
              <span className="form-check-sign" style={{ marginLeft: '12px' }} />
              {isHazmatHover && 'Hazmat'}
            </Label>
          </FormGroup>
        </div>
        <div style={{ marginTop: '18px' }}>
          <FormGroup check className="mt-3" style={{ marginTop: '10px' }} onMouseEnter={() => setIsTWICCardHover(true)} onMouseLeave={() => setIsTWICCardHover(false)}>
            <Label check>
              <div style={{ marginTop: '-12px' }}>
                <TWIC name="TWIC"></TWIC>
              </div>
              <Input
                type="checkbox"
                id="tool-check"
                onClick={e => {
                  setIsTWICCard(!isTWICCard);
                }}
              />
              <span className="form-check-sign" style={{ marginLeft: '5px' }} />
              {isTWICCardHover && 'TWIC Card'}
            </Label>
          </FormGroup>
        </div>
        <div style={{ marginTop: '13px' }}>
          <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
            <Label check>
              <div style={{ marginTop: '-12px' }} onMouseEnter={() => setIsUmbrellaHover(true)} onMouseLeave={() => setIsUmbrellaHover(false)}>
                <Umbrella name="Umbrella"></Umbrella>
              </div>
              <Input
                type="checkbox"
                id="tool-check"
                onClick={e => {
                  setIsWeatherProtection(!isWeatherProtection);
                }}
              />
              <span className="form-check-sign" style={{ marginLeft: '2px' }} />
              {isUmbrellaHover && 'Weather Protection'}
            </Label>
          </FormGroup>
        </div>

        <div style={{ marginTop: '17px' }}>
          <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
            <Label check>
              <div style={{ marginTop: '-12px' }} onMouseEnter={() => setIsMedicalHover(true)} onMouseLeave={() => setIsMedicalHover(false)}>
                <Medical name="TWIC"></Medical>
              </div>
              <Input
                type="checkbox"
                id="tool-check"
                onClick={e => {
                  setIsMedical(!isMedical);
                }}
              />
              <span className="form-check-sign" style={{ marginLeft: '12px' }} />
              {isMedicalHover && 'Medical'}
            </Label>
          </FormGroup>
        </div>
      </Col>
    </>
  );
};

export default Tools;
