/*!
* Black Dashboard PRO React - v1.0.0
* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Coded by Creative Tim
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import classnames from "classnames";
import Select from "react-select";
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormGroup,
  Row,
  Col
} from "reactstrap";

import states from '../../../us_states';
import vehicle_types from '../../../vehicle_types';

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      make: "",
      model: "",
      color: "",
      year: "",
      plate_state: "",
      plate_value: "",
      description: "",
      type: "",
      makeState: "",
      modelState: "",
      colorState: "",
      yearState: "",
      plate_stateState: "has-danger",
      plate_valueState: "",
      descriptionState: "",
      typeState: "has-danger"
    };
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  isValidated = () => {
    if (
      this.state.makeState === "has-success" &&
      this.state.modelState === "has-success" &&
      this.state.colorState === "has-success" &&
      this.state.yearState === "has-success" &&
      this.state.plate_stateState === "has-success" &&
      this.state.plate_valueState === "has-success" &&
      this.state.descriptionState === "has-success" && 
      this.state.typeState === "has-success"
    ) {
      return true;
    } else {
      if (this.state.makeState !== "has-success") {
        this.setState({ makeState: "has-danger" });
      }
      if(this.state.modelState !== "has-success") {
        this.setState({ modelState: "has-danger" });
      }
      if(this.state.colorState !== "has-success") {
        this.setState({colorState: "has-danger" });
      }
      if(this.state.yearState !== "has-success") {
        this.setState({yearState: "has-danger" });
      }
      if(this.state.plate_stateState !== "has-success") {
        this.setState({ plate_stateState: "has-danger" });
      }
      if(this.state.plate_valueState !== "has-success") {
        this.setState({ plate_valueState: "has-danger"});
      }
      if(this.state.descriptionState !== "has-success") {
        this.setState({ descriptionState: "has-danger" });
      }
      if(this.state.typeState !== "has-success") {
        this.setState({ typeState: "has-danger" });
      }
      return false;
    }
  };

  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };

  render() {
    return (
      <>
        <h4 className="info-text">Vehicle Information</h4>
        <form autoComplete="off">
          <input autoComplete="off" hidden="true"></input>
          <Row className="justify-content-center mt-5">
            
            <Col sm="5">
              <label>Vehicle Make</label>
              <InputGroup
                className={classnames(this.state.makeState, {
                  "input-group-focus": this.state.makeFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-atom" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="make"
                  type="text"
                  onChange={e => this.change(e, "make", "length", 1)}
                  onFocus={e => this.setState({ makeFocus: true })}
                  onBlur={e => this.setState({ makeFocus: false })}
                />
                {this.state.makeState === "has-danger" ? (
                  <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>
              <label>Vehicle Model</label>

              <InputGroup
                className={classnames(this.state.modelState, {
                  "input-group-focus": this.state.modelFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-atom" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="model"
                  type="text"
                  onChange={e => this.change(e, "model", "length", 1)}
                  onFocus={e => this.setState({ modelFocus: true })}
                  onBlur={e => this.setState({ modelFocus: false })}
                />
                {this.state.modelState === "has-danger" ? (
                  <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>
              <label>Vehicle Color</label>

              <InputGroup
                className={classnames(this.state.colorState, {
                  "input-group-focus": this.state.colorFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-atom" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="color"
                  type="text"
                  onChange={e => this.change(e, "color", "length", 1)}
                  onFocus={e => this.setState({ colorFocus: true })}
                  onBlur={e => this.setState({ colorFocus: false })}
                />
                {this.state.colorState === "has-danger" ? (
                  <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>
              <label>Vehicle Model Year</label>

              <InputGroup
                className={classnames(this.state.yearState, {
                  "input-group-focus": this.state.yearFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-atom" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="year"
                  type="text"
                  onChange={e => this.change(e, "year", "length", 4)}
                  onFocus={e => this.setState({ yearFocus: true })}
                  onBlur={e => this.setState({ yearFocus: false })}
                />
                {this.state.yearState === "has-danger" ? (
                  <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>
            </Col>
            <Col sm="5">
              <FormGroup>
                <label>Vehicle License Plate State</label>
                <Select
                  className={classnames("react-select info", this.state.plate_stateState, {
                    "input-group-focus": this.state.plate_stateFocus
                  })}
                  classNamePrefix="react-select"
                  name="plate_state"
                  onChange={value => this.setState({ plate_state: value, plate_stateState: "has-success" })}
                  options={states}
                  placeholder="Select a state"
                />
              </FormGroup>
              <label>Vehicle License Plate Number</label>

              <InputGroup
                className={classnames(this.state.plate_valueState, {
                  "input-group-focus": this.state.plate_valueFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-atom" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="plate_value"
                  type="text"
                  onChange={e => this.change(e, "plate_value", "length", 1)}
                  onFocus={e => this.setState({ plate_valueFocus: true })}
                  onBlur={e => this.setState({ plate_valueFocus: false })}
                />
                {this.state.plate_valueState === "has-danger" ? (
                  <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>
                <label>Vehicle Description</label>
                <InputGroup
                  className={classnames(this.state.descriptionState, {
                    "input-group-focus": this.state.descriptionFocus
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="tim-icons icon-atom" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="description"
                    type="text"
                    onChange={e => this.change(e, "description", "length", 1)}
                    onFocus={e => this.setState({ descriptionFocus: true })}
                    onBlur={e => this.setState({ descriptionFocus: false })}
                  />
                  {this.state.descriptionState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
              
              <FormGroup>
                <label>Vehicle Type</label>

                <Select
                  className={classnames("react-select info", this.state.typeState, {
                    "input-group-focus": this.state.typeFocus
                  })}
                  classNamePrefix="react-select"
                  name="type"
                  onChange={value => this.setState({ type: value, typeState: "has-success" })}
                  options={vehicle_types}
                  placeholder="Select a type"
                />
              </FormGroup>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

export default Wizard;
