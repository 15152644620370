const dev = {
  api_inventory: {
    admin: {
      inventoryHost: 'https://inventory.api-dev.gophr.app',
      hasuraHost: 'https://dev-api-inventory.hasura.gophr.app/v1/graphql',
      inventoryHasuraAdminSecret: process.env.REACT_APP_INVENTORY_HASURA_ADMIN_SECRET
    }
  },
  api_shipments: {
    admin: {
      shipmentHost: 'https://dev-api-shipments.gophr.app',
      hasuraHost: 'https://dev-api-shipments.hasura.gophr.app/v1/graphql',
      wssHasuraHost: 'wss://dev-api-shipments.hasura.gophr.app/v1/graphql',
      shipmentsHasuraAdminSecret: process.env.REACT_APP_SHIPMENTS_HASURA_ADMIN_SECRET
    },

    merchant: {
      shipmentHost: 'https://dev-api-shipments.gophr.app',
      hasuraHost: 'https://dev-api-shipments.hasura.gophr.app/v1/graphql'
    }

    // Dev2 shipment endpoints:

    // admin:  {
    //     shipmentHost: "https://dev2-api-shipments.gophr.app",
    //     hasuraHost: "https://dev2-api-shipments.hasura.gophr.app/v1/graphql",
    //     wssHasuraHost: "wss://dev2-api-shipments.hasura.gophr.app/v1/graphql"
    // },

    // merchant: {
    //     shipmentHost: "https://dev2-api-shipments.gophr.app",
    //     hasuraHost: "https://dev2-api-shipments.hasura.gophr.app/v1/graphql"
    // }
  },
  api_users: {
    admin: {
      usersHost: 'https://dev-api-users.gophr.app',
      hasuraHost: 'https://dev-api-users.hasura.gophr.app/v1/graphql',
      hasuraRest: 'https://dev-api-users.hasura.app/api/rest',
      wssHasuraHost: 'wss://dev-api-users.hasura.gophr.app/v1/graphql',
      usersHasuraAdminSecret: process.env.REACT_APP_USERS_HASURA_ADMIN_SECRET
    }
  },
  AUTH_CONFIG: {
    domain: 'gophr-dev.auth0.com',
    clientId: 'R8Lux0ToGL7R7Jky66Ue1mshz2ZOEDzc',
    callbackUrl: 'http://localhost:3000/callback',
    container: 'auth0-login-container',
    audience: 'https://dev.gophr.app',
    jwksUri: 'https://gophr-dev.auth0.com/.well-known/jwks.json',
    algorithms: ['RS256'],
    issuer: 'https://gophr-dev.auth0.com/',
    client: null,
    SIGNING_KEY: 'NDVFQjY3MUUyMUQ5RkI0RkFFRjAzRjIzNjlEMzUzOTg0MDU5ODUyNw'
  },
  api_payments: {
    host: 'https://dev-api-payments.gophr.app',
    client_id: process.env.REACT_APP_PAYMENT_CLIENT_ID,
    client_secret: process.env.REACT_APP_PAYMENT_CLIENT_SECRET
  },
  STRIPE: {
    publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    connectedAccountID: process.env.REACT_APP_CONNECTED_STRIPE_ACCOUNT_ID
  },
  GOOGLE_MAP: {
    accessKey: process.env.REACT_APP_GOOGLE_MAP_KEY
  }
};

const prod = {
  api_inventory: {
    admin: {
      inventoryHost: 'https://api-inventory.gophr.app',
      hasuraHost: 'https://api-inventory.hasura.gophr.app/v1/graphql',
      InventoryHasuraAdminSecret: process.env.REACT_APP_INVENTORY_HASURA_ADMIN_SECRET
    }
  },
  api_shipments: {
    admin: {
      shipmentHost: 'https://api-shipments.gophr.app',
      hasuraHost: 'https://api-shipments.hasura.gophr.app/v1/graphql',
      wssHasuraHost: 'wss://api-shipments.hasura.gophr.app/v1/graphql',
      shipmentsHasuraAdminSecret: process.env.REACT_APP_SHIPMENTS_HASURA_ADMIN_SECRET
    },
    merchant: {
      shipmentHost: 'https://api-shipments.gophr.app',
      hasuraHost: 'https://api-shipments.hasura.gophr.app/v1/graphql'
    }
  },
  api_users: {
    admin: {
      usersHost: 'https://api-users.gophr.app',
      hasuraHost: 'https://api-users.hasura.gophr.app/v1/graphql',
      hasuraRest: 'https://api-users.hasura.gophr.app/api/rest',
      wssHasuraHost: 'wss://api-users.hasura.gophr.app/v1/graphql',
      usersHasuraAdminSecret: process.env.REACT_APP_USERS_HASURA_ADMIN_SECRET
    }
  },
  AUTH_CONFIG: {
    domain: 'gophr.auth0.com',
    clientId: '08DTSFzoagUGkvFibKF8h1Bo8tuXJzBA',
    callbackUrl: 'https://dashboard.gophr.app/callback',
    container: 'auth0-login-container',
    audience: 'https://gophr.app',
    jwksUri: 'https://gophr.auth0.com/.well-known/jwks.json',
    algorithms: ['RS256'],
    issuer: 'https://gophr.auth0.com/',
    client: null,
    SIGNING_KEY: 'MzRFOUM3OTE0NzQ5NUI5NjE4OUI0MkUxMkI2RkFFNkYyRUFFNjNENw'
  },
  api_payments: {
    //! Host might change
    host: 'https://api-payments-624e1a02485839ede974c7fc8dd13f0b.gophr.app',
    client_id: process.env.REACT_APP_PAYMENT_CLIENT_ID,
    client_secret: process.env.REACT_APP_PAYMENT_CLIENT_SECRET
  },
  STRIPE: {
    publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    connectedAccountID: process.env.REACT_APP_CONNECTED_STRIPE_ACCOUNT_ID
  },
  GOOGLE_MAP: {
    accessKey: process.env.REACT_APP_GOOGLE_MAP_KEY
  }
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;
export default {
  config
};
