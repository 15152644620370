import React, { useState, useEffect } from 'react';
import { Input, ListGroup, ListGroupItem } from 'reactstrap';
import * as shipment_urls from '../../urls/api-shipments/merchant';
import axios from 'axios';

const PickupCustomerAutoComplete = ({
  isPickupStore,
  storeFirstName,
  pickFirstname,
  setPickFirstname,
  pickupCustomerSuggestions,
  setPickupCustomerSuggestions,
  storeId,
  setPickLastname,
  setPickPhone,
  pickFullAddress,
  setPickFullAddress,
  setPickup,
  isPickupAutoFilled,
  setIsPickupAutoFilled,
  setPickAddress1,
  setPickAddress2,
  setPickCity,
  setPickState,
  setPickZip,
  setPickCountry,
  setPickLat,
  setPickLng,
  setPickBusinessName
}) => {
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState('');
  const [hasSelected, setHasSelected] = useState(false);
  const [focus, setFocus] = useState(true);

  const reset = () => {
    setPickFirstname('');
    setPickupCustomerSuggestions('');
    setPickLastname('');
    setPickPhone('');
    setPickFullAddress('');
    setPickup('');
    // setIsPickupAutoFilled(false);
    setPickAddress1('');
    setPickAddress2('');
    setPickCity('');
    setPickState('');
    setPickZip('');
    setPickCountry('');
    setPickLat('');
    setPickLng('');
    setPickBusinessName('');
  };

  useEffect(() => {
    if (!focus && !hasSelected) {
      setShowSuggestions(false);
    }
  }, [focus, hasSelected]);

  useEffect(() => {
    if (pickupCustomerSuggestions.length > 0) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [pickupCustomerSuggestions]);

  useEffect(() => {
    if (!hasSelected) {
      fetchCustomer(input, storeId);
    }
  }, [input, hasSelected]);

  useEffect(() => {
    if (input == '' && isPickupAutoFilled) {
      reset();
    }
    // setIsPickupAutoFilled(true);
  }, [input]);

  useEffect(() => {
    if (isPickupAutoFilled) {
      setIsPickupAutoFilled(false);
    }
  }, [pickFullAddress]);

  const fetchCustomer = (customerName, storeId) => {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        query:
          `{
          direct_customer_search(args: {customer_name: "` +
          customerName +
          `", st_id: "` +
          storeId +
          `"}) {
            drop_off_instructions
            pick_up_instructions
            location_blob
          }
        }`
      }
    })
      .then(response => {
        if (response.data.data) {
          setPickupCustomerSuggestions(response.data.data.direct_customer_search);
          // console.log('response ', response.data.data);
        } else {
          console.log('getting autofill customer error: ', response);
        }
      })
      .catch(error => {
        console.log('autofill-error ', error);
      });
  };

  const onChange = e => {
    if (isPickupStore) {
      return;
    }

    const userInput = e.target.value;

    setInput(userInput);
    setPickFirstname(userInput);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const onClick = index => {
    let selectedInfo = pickupCustomerSuggestions[index];
    setHasSelected(true); //! solve the suggestion shows up again after the click
    // console.log('pickup-selected ', selectedInfo);
    setIsPickupAutoFilled(true);
    setPickFirstname(selectedInfo.location_blob.first_name);
    setPickLastname(selectedInfo.location_blob.last_name);
    setPickPhone(selectedInfo.location_blob.phone);
    setPickFullAddress(selectedInfo.location_blob.address);
    setPickAddress1(selectedInfo.location_blob.address_1);
    setPickAddress2(selectedInfo.location_blob.address_2);
    setPickCity(selectedInfo.location_blob.city);
    setPickState(selectedInfo.location_blob.state);
    setPickZip(selectedInfo.location_blob.zip);
    setPickCountry(selectedInfo.location_blob.country);
    setPickLat(selectedInfo.location_blob.lat);
    setPickLng(selectedInfo.location_blob.lng);
    setPickup(selectedInfo.pick_up_instructions);
    if (selectedInfo.location_blob.business_name) {
      setPickBusinessName(selectedInfo.location_blob.business_name);
    }
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    setPickupCustomerSuggestions([]);
    setHasSelected(false);
    // setIsPickupAutoFilled(false);
  };

  // const onKeyDown = e => {
  //   // User pressed the enter key
  //   if (e.keyCode === 13) {
  //     setPickFirstname(filteredSuggestions[activeSuggestionIndex]);
  //     setInput(filteredSuggestions[activeSuggestionIndex]);
  //     setActiveSuggestionIndex(0);
  //     setShowSuggestions(false);
  //   }
  //   // User pressed the up arrow
  //   else if (e.keyCode === 38) {
  //     if (activeSuggestionIndex === 0) {
  //       return;
  //     }

  //     setActiveSuggestionIndex(activeSuggestionIndex - 1);
  //   }
  //   // User pressed the down arrow
  //   else if (e.keyCode === 40) {
  //     if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
  //       return;
  //     }

  //     setActiveSuggestionIndex(activeSuggestionIndex + 1);
  //   }
  // };

  const SuggestionsListComponent = () => {
    return pickupCustomerSuggestions.length ? (
      <ListGroup flush>
        {pickupCustomerSuggestions.map((suggestion, index) => {
          if (index < 5) {
            return (
              <ListGroupItem
                style={{ backgroundColor: '#1e1e2b', border: 'none', color: '#cbcac9' }}
                onMouseOver={e => (e.target.style.background = 'linear-gradient(90deg, hsla(218, 100%, 42%, 0.6) 0%, hsla(0, 0%, 98%, 1) 200%)')}
                onMouseLeave={e => (e.target.style.background = '#1e1e2b')}
                key={index}
                onClick={() => onClick(index)}
              >
                {`${suggestion.location_blob.name}, ${suggestion.location_blob.phone}, ${suggestion.location_blob.address_1}`}
              </ListGroupItem>
            );
          }
        })}
      </ListGroup>
    ) : null;
  };

  return (
    <>
      <Input
        type="text"
        onChange={onChange}
        onBlur={() => {
          setTimeout(() => {
            setFocus(false);
          }, '200');
        }}
        onClick={() => setFocus(true)}
        // onKeyDown={onKeyDown}
        required
        readOnly={isPickupStore}
        name="storeFirstName"
        id="customer-first-name"
        placeholder="John"
        value={isPickupStore ? storeFirstName : pickFirstname}
      />
      {showSuggestions && input && <SuggestionsListComponent />}
    </>
  );
};

export default PickupCustomerAutoComplete;
