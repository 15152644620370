import React from 'react';

export function StoreManagerIcon(props) {
  return (
    <svg id="store-manager-icon" width="30" height="30" viewBox="0 0 30 30" fill={props.fill ? props.fill : '#5E72E4'} style={{ marginBottom: `20px` }}>
      <path d="M23.137 8H7.008v2.016h16.129zm1.008 10.08v-2.016l-1.008-5.04H7.008L6 16.064v2.016h1.008v6.048h10.08V18.08h4.032v6.048h2.016V18.08zm-9.072 4.032H9.024V18.08h6.048z" />
    </svg>
  );
}

export function UserManagerIcon(props) {
  return (
    <svg id="user-manager-icon" width="23.508" height="28.59" viewBox="0 0 17.508 14.59" fill={props.fill ? props.fill : '#5E72E4'} style={{ marginBottom: `5px` }}>
      <path d="M16.778 2H13.86a1.459 1.459 0 1 1-2.918 0H6.565a1.459 1.459 0 0 1-2.918 0H.729A.73.73 0 0 0 0 2.729v13.132a.73.73 0 0 0 .729.729h16.049a.73.73 0 0 0 .729-.729V2.729A.73.73 0 0 0 16.778 2zM5.836 7.106a1.459 1.459 0 1 1-1.459 1.46 1.459 1.459 0 0 1 1.459-1.46zm-2.918 5.837a2.188 2.188 0 0 1 2.188-2.188h1.459a2.188 2.188 0 0 1 2.188 2.188zm11.672-.729h-4.377v-1.46h4.377zm0-2.918h-4.377v-1.46h4.377z" />
    </svg>
  );
}

export function CredentialsIcon(props) {
  return (
    <svg
      id="credentials-icon"
      width="23.318"
      height="13.466"
      viewBox="0 0 23.318 13.466"
      fill={props.fill ? props.fill : '#5E72E4'}
      style={{
        stroke: `#5E72E4`,
        strokeLineCap: `round`,
        strokeLineJoin: `round`,
        strokeWidth: `2px`,
        marginBottom: `20px`
      }}
    >
      <path d="M7.746 2.34L2.428 7.659l5.318 5.319" />
      <path d="M2.428 2.34l5.318 5.319-5.318 5.318" />
    </svg>
  );
}
