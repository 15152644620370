import React, { useState, useEffect, useRef } from 'react';
import { Label, FormGroup, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip } from 'reactstrap';
import Car, {
  SUV,
  Truck,
  Trailer,
  Wheeler18DryVan,
  CarHauler,
  EnclosedTrailer,
  Wheeler18FlatBed,
  DumpTrailer,
  MonsterTruck,
  LiftTruck,
  Float53FeetAsGooseneckTrailer
} from '../../../../assets/svgs/CarTypes';
import './VehicleTypes.css';

const VehicleTypes = ({ weight, vehicleType, setVehicleType }) => {
  const [isTruckHover, setIsTruckHover] = useState(false);
  const [isMonsterTruckHover, setIsMonsterTruckHover] = useState(false);
  const [isLiftTruckHover, setIsLiftTruckHover] = useState(false);
  const [is18WheelerDryVanHover, setIs18WheelerDryVanHover] = useState(false);
  const [is18WheelerFlatBedHover, setIs18WheelerFlatBedHover] = useState(false);
  const [isEnclosedTrailerHover, setIsEnclosedTrailerHover] = useState(false);
  const [isDumpTrailerHover, setIsDumpTrailerHover] = useState(false);
  const [isGooseneckTrailerHover, setIsGooseneckTrailerHover] = useState(false);
  const [isCarHaulerHover, setIsCarHaulerHover] = useState(false);
  const [isTrailerHover, setIsTrailerHover] = useState(false);

  const [is18WellerOpen, setIs18WellerOpen] = useState(false);
  const [isTruckOpen, setIsTruckOpen] = useState(false);
  const [isTrailerOpen, setIsTrailerOpen] = useState(false);

  const toggle18Wheeler = () => setIs18WellerOpen(prevState => !prevState);
  const toggleTruck = () => setIsTruckOpen(prevState => !prevState);
  const toggleTrailer = () => setIsTrailerOpen(prevState => !prevState);

  const refVehicle = useRef(null);

  useEffect(() => {
    setIsTruckHover(false);
    setIsMonsterTruckHover(false);
    setIsLiftTruckHover(false);
    setIsEnclosedTrailerHover(false);
    setIsDumpTrailerHover(false);
    setIsGooseneckTrailerHover(false);
    setIsCarHaulerHover(false);
    setIsTrailerHover(false);
    setIs18WheelerDryVanHover(false);
    setIs18WheelerFlatBedHover(false);
  }, [weight, vehicleType]);

  const handleFill = name => {
    if (name === vehicleType) {
      return '#f7941d';
    } else {
      return '#5e72e4';
    }
  };

  const handleFillGrayOut = name => {
    if (name === vehicleType) {
      setVehicleType('');
    }
    return '#333333';
  };

  const MonsterTruckDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        <div
          onClick={() => (weight > 4000 ? null : setVehicleType('heavy_duty_truck'))}
          onMouseEnter={() => setIsMonsterTruckHover(true)}
          onMouseLeave={() => setIsMonsterTruckHover(false)}
        >
          <MonsterTruck
            alt="Monster Truck"
            title="Monster Truck"
            name="Monster Truck"
            size={1.5}
            fill={weight > 4000 ? handleFillGrayOut('heavy_duty_truck') : handleFill('heavy_duty_truck')}
          ></MonsterTruck>
        </div>
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isMonsterTruckHover} target={refVehicle} toggle={() => setIsMonsterTruckHover(!isMonsterTruckHover)}>
          1-Ton Truck
        </Tooltip>
      </Label>
    );
  };

  const LiftTruckDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 10000 ? (
          <div>
            <LiftTruck alt="Lift Truck" title="Lift Truck" name="Lift Truck" size={1.5} fill={handleFillGrayOut('box_truck')}></LiftTruck>
          </div>
        ) : (
          <div onClick={() => setVehicleType('box_truck')} onMouseEnter={() => setIsLiftTruckHover(true)} onMouseLeave={() => setIsLiftTruckHover(false)}>
            <LiftTruck alt="Lift Truck" title="Lift Truck" name="Lift Truck" size={1.5} fill={handleFill('box_truck')}></LiftTruck>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isLiftTruckHover} target={refVehicle} toggle={() => setIsLiftTruckHover(!isLiftTruckHover)}>
          Box Truck
        </Tooltip>
      </Label>
    );
  };

  const TruckDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 2000 ? (
          <div>
            <Truck name="Truck" fill={handleFillGrayOut('Truck')}></Truck>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('Truck');
              // setIsTruckOpen(!isTruckOpen);
            }}
            onMouseEnter={() => setIsTruckHover(true)}
            onMouseLeave={() => setIsTruckHover(false)}
          >
            <Truck name="Truck" fill={handleFill('Truck')}></Truck>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isTruckHover} target={refVehicle} toggle={() => setIsTruckHover(!isTruckHover)}>
          Half-Ton Truck
        </Tooltip>
      </Label>
    );
  };

  const EnclosedTrailerDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 5000 ? (
          <div>
            <EnclosedTrailer alt="Enclosed Trailer" title="Enclosed Trailer" name="Enclosed Trailer" size={1.5} fill={handleFillGrayOut('enclosed_trailer')}></EnclosedTrailer>
          </div>
        ) : (
          <div onClick={() => setVehicleType('enclosed_trailer')} onMouseEnter={() => setIsEnclosedTrailerHover(true)} onMouseLeave={() => setIsEnclosedTrailerHover(false)}>
            <EnclosedTrailer alt="Enclosed Trailer" title="Enclosed Trailer" name="Enclosed Trailer" size={1.5} fill={handleFill('enclosed_trailer')}></EnclosedTrailer>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isEnclosedTrailerHover} target={refVehicle} toggle={() => setIsEnclosedTrailerHover(!isEnclosedTrailerHover)}>
          Enclosed Trailer
        </Tooltip>
      </Label>
    );
  };

  const DumpTrailerDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 10000 ? (
          <div>
            <DumpTrailer alt="Dump Trailer" title="Dump Trailer" name="Dump Trailer" size={1.5} fill={handleFillGrayOut('dump_trailer')}></DumpTrailer>
          </div>
        ) : (
          <div onClick={() => setVehicleType('dump_trailer')} onMouseEnter={() => setIsDumpTrailerHover(true)} onMouseLeave={() => setIsDumpTrailerHover(false)}>
            <DumpTrailer alt="Dump Trailer" title="Dump Trailer" name="Dump Trailer" size={1.5} fill={handleFill('dump_trailer')}></DumpTrailer>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isDumpTrailerHover} target={refVehicle} toggle={() => setIsDumpTrailerHover(!isDumpTrailerHover)}>
          Dump Trailer
        </Tooltip>
      </Label>
    );
  };

  const Float53FeetAsGooseneckTrailerDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 15000 ? (
          <div>
            <Float53FeetAsGooseneckTrailer
              alt="Gooseneck Trailer"
              title="Gooseneck Trailer"
              name="Gooseneck Trailer"
              size={1.5}
              fill={handleFillGrayOut('gooseneck_trailer')}
            ></Float53FeetAsGooseneckTrailer>
          </div>
        ) : (
          <div onClick={() => setVehicleType('gooseneck_trailer')} onMouseEnter={() => setIsGooseneckTrailerHover(true)} onMouseLeave={() => setIsGooseneckTrailerHover(false)}>
            <Float53FeetAsGooseneckTrailer
              alt="Gooseneck Trailer"
              title="Gooseneck Trailer"
              name="Gooseneck Trailer"
              size={1.5}
              fill={handleFill('gooseneck_trailer')}
            ></Float53FeetAsGooseneckTrailer>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isGooseneckTrailerHover} target={refVehicle} toggle={() => setIsGooseneckTrailerHover(!isGooseneckTrailerHover)}>
          Gooseneck Trailer
        </Tooltip>
      </Label>
    );
  };

  const CarHaulerDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 10000 ? (
          <div>
            <CarHauler alt="Car Hauler" title="Car Hauler" name="Car Hauler" size={1.5} fill={handleFillGrayOut('car_hauler')}></CarHauler>
          </div>
        ) : (
          <div onClick={() => setVehicleType('car_hauler')} onMouseEnter={() => setIsCarHaulerHover(true)} onMouseLeave={() => setIsCarHaulerHover(false)}>
            <CarHauler alt="Car Hauler" title="Car Hauler" name="Car Hauler" size={1.5} fill={handleFill('car_hauler')}></CarHauler>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isCarHaulerHover} target={refVehicle} toggle={() => setIsCarHaulerHover(!isCarHaulerHover)}>
          Car Hauler
        </Tooltip>
      </Label>
    );
  };

  const TrailerDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 5000 ? (
          <div>
            <Trailer alt="Truck and Trailer" title="Truck and Trailer" name="Truck and Trailer" size={1.5} fill={handleFillGrayOut('trailer')}></Trailer>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('trailer');
              // setIsTrailerOpen(!isTrailerOpen);
            }}
            onMouseEnter={() => setIsTrailerHover(true)}
            onMouseLeave={() => setIsTrailerHover(false)}
          >
            <Trailer alt="Truck and Trailer" title="Truck and Trailer" name="Truck and Trailer" size={1.5} fill={handleFill('trailer')}></Trailer>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isTrailerHover} target={refVehicle} toggle={() => setIsTrailerHover(!isTrailerHover)}>
          Utility Trailer
        </Tooltip>
      </Label>
    );
  };

  const Wheeler18DryVanDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        <div onClick={() => setVehicleType('18_wheeler_dry_van')} onMouseEnter={() => setIs18WheelerDryVanHover(true)} onMouseLeave={() => setIs18WheelerDryVanHover(false)}>
          <Wheeler18DryVan alt="18 wheeler dry van" title="18 wheeler dry van" name="18 wheeler dry van" size={1.5} fill={handleFill('18_wheeler_dry_van')}></Wheeler18DryVan>
        </div>
        <Tooltip placement="bottom" ref={refVehicle} isOpen={is18WheelerDryVanHover} target={refVehicle} toggle={() => setIs18WheelerDryVanHover(!is18WheelerDryVanHover)}>
          18 Wheeler Dry Van
        </Tooltip>
      </Label>
    );
  };

  const Wheeler18FlatBedDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        <div
          onClick={() => {
            setVehicleType('18_wheeler_flat_bed');
            // setIs18WellerOpen(!is18WellerOpen);
          }}
          onMouseEnter={() => setIs18WheelerFlatBedHover(true)}
          onMouseLeave={() => setIs18WheelerFlatBedHover(false)}
        >
          <Wheeler18FlatBed alt="18 wheeler flat bed" title="18 wheeler flat bed" name="18 wheeler flat bed" size={1.5} fill={handleFill('18_wheeler_flat_bed')}></Wheeler18FlatBed>
        </div>
        <Tooltip placement="bottom" ref={refVehicle} isOpen={is18WheelerFlatBedHover} target={refVehicle} toggle={() => setIs18WheelerFlatBedHover(!is18WheelerFlatBedHover)}>
          18 Wheeler Flat Bed
        </Tooltip>
      </Label>
    );
  };

  const TruckDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 2000 ? (
          <div>
            <Truck name="Truck" fill={handleFillGrayOut('Truck')}></Truck>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('Truck');
              toggleTruck();
            }}
            onMouseEnter={() => setIsTruckHover(true)}
            onMouseLeave={() => setIsTruckHover(false)}
          >
            <Truck name="Truck" fill={handleFill('Truck')}></Truck>
          </div>
        )}
        <span style={{ marginLeft: '10px' }} />
        {isTruckHover && 'Half-Ton Truck'}
      </Label>
    );
  };

  const MonsterTruckDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 4000 ? (
          <div>
            <MonsterTruck alt="Monster Truck" title="Monster Truck" name="Monster Truck" size={1.5} fill={handleFillGrayOut('heavy_duty_truck')}></MonsterTruck>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('heavy_duty_truck');
              toggleTruck();
            }}
            onMouseEnter={() => setIsMonsterTruckHover(true)}
            onMouseLeave={() => setIsMonsterTruckHover(false)}
          >
            <MonsterTruck alt="Monster Truck" title="Monster Truck" name="Monster Truck" size={1.5} fill={handleFill('heavy_duty_truck')}></MonsterTruck>
          </div>
        )}
        <span style={{ marginLeft: '10px' }} />
        {isMonsterTruckHover && '1-Ton Truck'}
      </Label>
    );
  };

  const LiftTruckDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 10000 ? (
          <div>
            <LiftTruck alt="Lift Truck" title="Lift Truck" name="Lift Truck" size={1.5} fill={handleFillGrayOut('box_truck')}></LiftTruck>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('box_truck');
              toggleTruck();
            }}
            onMouseEnter={() => setIsLiftTruckHover(true)}
            onMouseLeave={() => setIsLiftTruckHover(false)}
          >
            <LiftTruck alt="Lift Truck" title="Lift Truck" name="Lift Truck" size={1.5} fill={handleFill('box_truck')}></LiftTruck>
          </div>
        )}
        <span style={{ marginLeft: '10px' }} />
        {isLiftTruckHover && 'Box Truck'}
      </Label>
    );
  };

  const TrailerDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 5000 ? (
          <div>
            <Trailer alt="Truck and Trailer" title="Truck and Trailer" name="Truck and Trailer" size={1.5} fill={handleFillGrayOut('trailer')}></Trailer>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('trailer');
              toggleTrailer();
            }}
            onMouseEnter={() => setIsTrailerHover(true)}
            onMouseLeave={() => setIsTrailerHover(false)}
          >
            <Trailer alt="Truck and Trailer" title="Truck and Trailer" name="Truck and Trailer" size={1.5} fill={handleFill('trailer')}></Trailer>
          </div>
        )}
        <span style={{ marginLeft: '5px', marginTop: '5px' }} />
        {isTrailerHover && 'Utility Trailer'}
      </Label>
    );
  };

  const EnclosedTrailerDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 5000 ? (
          <div>
            <EnclosedTrailer alt="Enclosed Trailer" title="Enclosed Trailer" name="Enclosed Trailer" size={1.5} fill={handleFillGrayOut('enclosed_trailer')}></EnclosedTrailer>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('enclosed_trailer');
              toggleTrailer();
            }}
            onMouseEnter={() => setIsEnclosedTrailerHover(true)}
            onMouseLeave={() => setIsEnclosedTrailerHover(false)}
          >
            <EnclosedTrailer alt="Enclosed Trailer" title="Enclosed Trailer" name="Enclosed Trailer" size={1.5} fill={handleFill('enclosed_trailer')}></EnclosedTrailer>
          </div>
        )}
        <span style={{ marginLeft: '5px', marginTop: '10px' }} />
        {isEnclosedTrailerHover && 'Enclosed Trailer'}
      </Label>
    );
  };

  const DumpTrailerDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 10000 ? (
          <div>
            <DumpTrailer alt="Dump Trailer" title="Dump Trailer" name="Dump Trailer" size={1.5} fill={handleFillGrayOut('dump_trailer')}></DumpTrailer>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('dump_trailer');
              toggleTrailer();
            }}
            onMouseEnter={() => setIsDumpTrailerHover(true)}
            onMouseLeave={() => setIsDumpTrailerHover(false)}
          >
            <DumpTrailer alt="Dump Trailer" title="Dump Trailer" name="Dump Trailer" size={1.5} fill={handleFill('dump_trailer')}></DumpTrailer>
          </div>
        )}
        <span style={{ marginLeft: '5px', marginTop: '10px' }} />
        {isDumpTrailerHover && 'Dump Trailer'}
      </Label>
    );
  };

  const Float53FeetAsGooseneckTrailerDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 15000 ? (
          <div>
            <Float53FeetAsGooseneckTrailer
              alt="Gooseneck Trailer"
              title="Gooseneck Trailer"
              name="Gooseneck Trailer"
              size={1.5}
              fill={handleFillGrayOut('gooseneck_trailer')}
            ></Float53FeetAsGooseneckTrailer>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('gooseneck_trailer');
              toggleTrailer();
            }}
            onMouseEnter={() => setIsGooseneckTrailerHover(true)}
            onMouseLeave={() => setIsGooseneckTrailerHover(false)}
          >
            <Float53FeetAsGooseneckTrailer
              alt="Gooseneck Trailer"
              title="Gooseneck Trailer"
              name="Gooseneck Trailer"
              size={1.5}
              fill={handleFill('gooseneck_trailer')}
            ></Float53FeetAsGooseneckTrailer>
          </div>
        )}
        <span style={{ marginLeft: '5px', marginTop: '10px' }} />
        {isGooseneckTrailerHover && 'Gooseneck Trailer'}
      </Label>
    );
  };

  const CarHaulerDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 10000 ? (
          <div>
            <CarHauler alt="Car Hauler" title="Car Hauler" name="Car Hauler" size={1.5} fill={handleFillGrayOut('car_hauler')}></CarHauler>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('car_hauler');
              toggleTrailer();
            }}
            onMouseEnter={() => setIsCarHaulerHover(true)}
            onMouseLeave={() => setIsCarHaulerHover(false)}
          >
            <CarHauler alt="Car Hauler" title="Car Hauler" name="Car Hauler" size={1.5} fill={handleFill('car_hauler')}></CarHauler>
          </div>
        )}
        <span style={{ marginLeft: '5px', marginTop: '10px' }} />
        {isCarHaulerHover && 'Car Hauler'}
      </Label>
    );
  };

  const Wheeler18FlatBedDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        <div
          onClick={() => {
            setVehicleType('18_wheeler_flat_bed');
            toggle18Wheeler();
            // setIs18WellerOpen(!is18WellerOpen);
          }}
          onMouseEnter={() => setIs18WheelerFlatBedHover(true)}
          onMouseLeave={() => setIs18WheelerFlatBedHover(false)}
        >
          <Wheeler18FlatBed alt="18 wheeler flat bed" title="18 wheeler flat bed" name="18 wheeler flat bed" size={1.5} fill={handleFill('18_wheeler_flat_bed')}></Wheeler18FlatBed>
        </div>
        <span style={{ marginTop: '15px' }} />
        {is18WheelerFlatBedHover && '18 Wheeler Flat Bed'}
      </Label>
    );
  };

  const Wheeler18DryVanDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        <div
          onClick={() => {
            setVehicleType('18_wheeler_dry_van');
            toggle18Wheeler();
          }}
          onMouseEnter={() => setIs18WheelerDryVanHover(true)}
          onMouseLeave={() => setIs18WheelerDryVanHover(false)}
        >
          <Wheeler18DryVan alt="18 wheeler dry van" title="18 wheeler dry van" name="18 wheeler dry van" size={1.5} fill={handleFill('18_wheeler_dry_van')}></Wheeler18DryVan>
        </div>
        <span style={{ marginTop: '15px' }} />
        {is18WheelerDryVanHover && '18 Wheeler Dry Van'}
      </Label>
    );
  };
  return (
    <>
      <Label inline for="shipment-size" style={{ marginLeft: '10px' }}>
        Shipment Size (click 🔽 for more options)
      </Label>
      <Row style={{ textAlign: 'center' }}>
        <div style={{ display: 'inline-block', backgroundColor: '#27293e' }}>
          <FormGroup check className="form-check-radio" id="shipment-size" style={{ textAlign: 'left', display: 'inline-block' }}>
            <Label className="form-check-label">
              {weight > 500 ? (
                <div>
                  <Car name="Car" fill={handleFillGrayOut('Car')}></Car>
                </div>
              ) : (
                <div onClick={() => setVehicleType('Car')}>
                  <Car name="Car" fill={handleFill('Car')}></Car>
                </div>
              )}
            </Label>

            <Label className="form-check-label">
              {weight > 1000 ? (
                <div>
                  <SUV name="SUV" fill={handleFillGrayOut('SUV')}></SUV>
                </div>
              ) : (
                <div onClick={() => setVehicleType('SUV')}>
                  <SUV name="SUV" fill={handleFill('SUV')}></SUV>
                </div>
              )}
            </Label>

            <Label className="form-check-label" style={{ paddingRight: '10px', marginRight: '10px' }}>
              <UncontrolledDropdown className="dropdown-vehicle" isOpen={isTruckOpen} toggle={toggleTruck}>
                <DropdownToggle className="dropdown-toggle-vehicle" caret tag="button" type="button">
                  {vehicleType === 'heavy_duty_truck' ? (
                    <MonsterTruckDropToggle />
                  ) : vehicleType === 'box_truck' ? (
                    <LiftTruckDropToggle />
                  ) : weight < 2000 ? (
                    <TruckDropToggle />
                  ) : weight < 4000 ? (
                    <MonsterTruckDropToggle />
                  ) : weight < 10000 ? (
                    <LiftTruckDropToggle />
                  ) : (
                    <TruckDropToggle />
                  )}
                  🔽
                </DropdownToggle>
                <DropdownMenu>
                  {(weight < 2000 || weight > 10000) && (
                    <>
                      {(vehicleType === 'heavy_duty_truck' || vehicleType === 'box_truck') && (
                        <DropdownItem className="dropdown-item-vehicle" text>
                          <TruckDropItem />
                        </DropdownItem>
                      )}
                      {vehicleType !== 'heavy_duty_truck' && (
                        <DropdownItem className="dropdown-item-vehicle" text>
                          <MonsterTruckDropItem />
                        </DropdownItem>
                      )}
                      {vehicleType !== 'box_truck' && (
                        <DropdownItem className="dropdown-item-vehicle" text>
                          <LiftTruckDropItem />
                        </DropdownItem>
                      )}
                    </>
                  )}
                  {weight >= 2000 && weight < 4000 && (
                    <>
                      <DropdownItem className="dropdown-item-vehicle" text>
                        <TruckDropItem />
                      </DropdownItem>

                      {vehicleType === 'box_truck' && (
                        <DropdownItem className="dropdown-item-vehicle" text>
                          <MonsterTruckDropItem />
                        </DropdownItem>
                      )}
                      {vehicleType !== 'box_truck' && (
                        <DropdownItem className="dropdown-item-vehicle" text>
                          <LiftTruckDropItem />
                        </DropdownItem>
                      )}
                    </>
                  )}
                  {weight >= 4000 && weight < 10000 && (
                    <>
                      <DropdownItem className="dropdown-item-vehicle" text>
                        <TruckDropItem />
                      </DropdownItem>
                      <DropdownItem className="dropdown-item-vehicle" text>
                        <MonsterTruckDropItem />
                      </DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Label>

            <Label className="form-check-label-vehicle-dropdown">
              <UncontrolledDropdown className="dropdown-vehicle" isOpen={isTrailerOpen} toggle={toggleTrailer}>
                <DropdownToggle className="dropdown-toggle-vehicle" caret tag="button" type="button">
                  {vehicleType === 'enclosed_trailer' ? (
                    <EnclosedTrailerDropToggle />
                  ) : vehicleType === 'dump_trailer' ? (
                    <DumpTrailerDropToggle />
                  ) : vehicleType === 'gooseneck_trailer' ? (
                    <Float53FeetAsGooseneckTrailerDropToggle />
                  ) : vehicleType === 'car_hauler' ? (
                    <CarHaulerDropToggle />
                  ) : weight < 5000 ? (
                    <TrailerDropToggle />
                  ) : weight < 10000 ? (
                    <DumpTrailerDropToggle />
                  ) : weight < 15000 ? (
                    <Float53FeetAsGooseneckTrailerDropToggle />
                  ) : (
                    <TrailerDropToggle />
                  )}
                  🔽
                </DropdownToggle>
                <DropdownMenu>
                  {(weight < 5000 || weight > 18000) && (
                    <>
                      {(vehicleType === 'enclosed_trailer' || vehicleType === 'dump_trailer' || vehicleType === 'gooseneck_trailer' || vehicleType === 'car_hauler') && (
                        <DropdownItem className="dropdown-item-vehicle" text>
                          <TrailerDropItem />
                        </DropdownItem>
                      )}
                      {vehicleType !== 'enclosed_trailer' && (
                        <DropdownItem className="dropdown-item-vehicle" text>
                          <EnclosedTrailerDropItem />
                        </DropdownItem>
                      )}
                      {vehicleType !== 'dump_trailer' && (
                        <DropdownItem className="dropdown-item-vehicle" text>
                          <DumpTrailerDropItem />
                        </DropdownItem>
                      )}

                      {vehicleType !== 'car_hauler' && (
                        <DropdownItem className="dropdown-item-vehicle" text>
                          <CarHaulerDropItem />
                        </DropdownItem>
                      )}
                      {vehicleType !== 'gooseneck_trailer' && (
                        <DropdownItem className="dropdown-item-vehicle" text>
                          <Float53FeetAsGooseneckTrailerDropItem />
                        </DropdownItem>
                      )}
                    </>
                  )}
                  {weight > 5000 && weight < 10000 && (
                    <>
                      <DropdownItem className="dropdown-item-vehicle" text>
                        <TrailerDropItem />
                      </DropdownItem>
                      <DropdownItem className="dropdown-item-vehicle" text>
                        <EnclosedTrailerDropItem />
                      </DropdownItem>

                      {vehicleType == '' ? null : vehicleType === 'car_hauler' || vehicleType === 'gooseneck_trailer' ? (
                        <DropdownItem className="dropdown-item-vehicle" text>
                          <DumpTrailerDropItem />
                        </DropdownItem>
                      ) : null}

                      {/* {vehicleType !== 'dump_trailer' && (
                        <DropdownItem className="dropdown-item-vehicle" text>
                          <DumpTrailerDropItem />
                        </DropdownItem>
                      )} */}
                      {vehicleType !== 'car_hauler' && (
                        <DropdownItem className="dropdown-item-vehicle" text>
                          <CarHaulerDropItem />
                        </DropdownItem>
                      )}

                      {vehicleType !== 'gooseneck_trailer' && (
                        <DropdownItem className="dropdown-item-vehicle" text>
                          <Float53FeetAsGooseneckTrailerDropItem />
                        </DropdownItem>
                      )}
                    </>
                  )}
                  {weight > 10000 && weight < 18000 && (
                    <>
                      <DropdownItem className="dropdown-item-vehicle" text>
                        <TrailerDropItem />
                      </DropdownItem>
                      <DropdownItem className="dropdown-item-vehicle" text>
                        <EnclosedTrailerDropItem />
                      </DropdownItem>
                      <DropdownItem className="dropdown-item-vehicle" text>
                        <DumpTrailerDropItem />
                      </DropdownItem>
                      <DropdownItem className="dropdown-item-vehicle" text>
                        <CarHaulerDropItem />
                      </DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Label>
            <Label className="form-check-label-vehicle-dropdown">
              <UncontrolledDropdown className="dropdown-vehicle" isOpen={is18WellerOpen} toggle={toggle18Wheeler}>
                <DropdownToggle className="dropdown-toggle-vehicle" caret tag="button" type="button">
                  {vehicleType === '18_wheeler_dry_van' ? <Wheeler18DryVanDropToggle /> : <Wheeler18FlatBedDropToggle />}
                  🔽
                </DropdownToggle>
                <DropdownMenu>
                  {vehicleType === '18_wheeler_dry_van' && (
                    <DropdownItem className="dropdown-item-vehicle" text>
                      <Wheeler18FlatBedDropItem />
                    </DropdownItem>
                  )}
                  {vehicleType !== '18_wheeler_dry_van' && (
                    <DropdownItem className="dropdown-item-vehicle" text>
                      <Wheeler18DryVanDropItem />
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Label>
          </FormGroup>
        </div>
      </Row>
    </>
  );
};

export default VehicleTypes;
