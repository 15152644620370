import React from 'react';
import { Label, FormGroup, Input } from 'reactstrap';

const ContainsAlcohol = ({ containsAlcohol, setContainsAlcohol }) => {
  return (
    <>
      <FormGroup check className="mt-3" style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Label check>
          <Input
            type="checkbox"
            id="schedule-check"
            onClick={e => {
              setContainsAlcohol(!containsAlcohol);
            }}
          />
          <span className="form-check-sign" />
          This shipment contains an alcoholic beverage
        </Label>
      </FormGroup>
    </>
  );
};

export default ContainsAlcohol;
