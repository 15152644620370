/*!
 * Black Dashboard PRO React - v1.0.0
 * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table,
  Input,
  Label,
  Row,
  Col,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import axios from 'axios';
import NotificationAlert from 'react-notification-alert';
import { Switch, Route, Link } from 'react-router-dom';

import CreateMerchantWizard from './forms/CreateMerchantWizard';
import CreateDriverWizard from './forms/CreateDriverWizard';
import User from '../components/Profiles/User';
import config from '../config';

import isAuthenticated from '../Auth/isAuthenticated';
import * as user_urls from '../urls/api-users/admin';
import { FaThinkPeaks } from 'react-icons/fa';
import { dedentBlockStringValue } from 'graphql/language/blockString';

class ExtendedTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      form: '',
      validToken: false,
      users: [],
      shownUsers: [],
      modal: false,
      userId: null,
      username: null,
      firstname: null,
      lastname: null,
      email: null,
      phone_number: null,
      dob: null,
      oldFirstName: null,
      oldLastName: null,
      oldEmail: null,
      oldPhoneNumber: null,
      oldDob: null
    };
  }

  handleFirstName = e => {
    this.setState({
      firstname: e.target.value
    });
  };

  handleLastName = e => {
    this.setState({
      lastname: e.target.value
    });
  };

  handlePhone = e => {
    this.setState({
      phone_number: e.target.value
    });
  };

  handleEmail = e => {
    this.setState({
      email: e.target.value
    });
  };

  handleDOB = e => {
    this.setState({
      dob: e.target.value
    });
  };

  resetPassword = userEmail => {
    // Function: Send a user a password reset email

    this.setState({ modal: false });
    axios({
      method: 'POST',
      url: 'https://' + config.config.AUTH_CONFIG.domain + '/dbconnections/change_password',
      headers: { 'content-type': 'application/json' },
      data: {
        client_id: config.config.AUTH_CONFIG.clientId,
        email: userEmail,
        connection: 'Username-Password-Authentication'
      }
    })
      .then(response => {
        this.notify('A reset password prompt has been emailed to ' + userEmail);
      })
      .catch(error => {
        if (error.response) {
          console.log('error: ', error.response);
        } else {
          console.log('error: ', error);
        }
      });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  toggleForm = form => {
    this.setState({ showForm: !this.state.showForm, form: form });
  };

  handleClick = event => {
    this.setState({ activeTab: event.target.name });
  };

  filterRole = role => {
    var filtered = [];
    if (role === 'all') {
      filtered = this.state.users;
    } else {
      this.state.users.forEach(element => {
        if (element.Roles && element.Roles.length > 0 && element.Roles[0].role === role) {
          filtered.push(element);
        }
      });
    }
    this.setState({ shownUsers: filtered });
  };

  handleSearchChange = event => {
    var val = event.target.value;
    var filtered = [];
    if (event.target.value === '') {
      this.setState({ shownUsers: this.state.users });
    } else {
      this.state.shownUsers.forEach(element => {
        let name = element.firstname + ' ' + element.lastname;
        if (
          element.username.toLowerCase().includes(val.toLowerCase()) ||
          element.email
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase()) ||
          name.toLowerCase().includes(val.toLowerCase())
        ) {
          filtered.push(element);
        }
      });
      this.setState({ shownUsers: filtered });
    }
  };

  notify = message => {
    if (message === 'Successfully added merchant' || message === 'Successfully added driver' || message === 'Successfully edited user info') {
      this.fetchAllUsers();
    }
    var options = {};
    options = {
      place: 'tc',
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      // type: type,
      // icon: icon,
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  componentWillMount() {
    if (!isAuthenticated()) {
      this.props.history.push('/');
    }

    if (this.props.role === 0 || this.props.role === 100) {
      this.setState({ validToken: true });
      this.fetchAllUsers();
    } else {
      this.setState({ validToken: false });
      this.props.history.push('/');
    }
  }

  fetchAllUsers() {
    const QUERY = { query: '{Users {auth0_id username firstname lastname email phone Roles { role_description role }}}' };
    axios({
      method: 'post',
      url: user_urls.hasuraHost,
      withCredentials: true,
      data: QUERY,
      headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('access_token') }
    })
      .then(response => {
        if (response.data.errors) {
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].message === 'Malformed Authorization header' || response.data.errors[0].extensions.code === 'invalid-jwt') {
              this.props.history.push('/logout');
            }
          }
        }
        //
        if (response.data.data) {
          this.setState({ users: response.data.data.Users, shownUsers: response.data.data.Users });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  editUser = () => {
    let data = {};
    if (this.state.oldFirstName !== this.state.firstname && this.state.firstname !== null) {
      data.firstname = this.state.firstname;
    }
    if (this.state.oldLastName !== this.state.lastname && this.state.lastname !== null) {
      data.lastname = this.state.lastname;
    }
    if (this.state.oldEmail !== this.state.email && this.state.email !== null) {
      data.email = this.state.email;
    }
    if (this.state.oldPhoneNumber !== this.state.phone_number && this.state.phone_number !== null) {
      data.phone_number = this.state.phone_number;
    }
    axios({
      data: data,
      method: 'put',
      url: user_urls.editUser + this.state.userId,
      withCredentials: true,
      headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('access_token') }
    })
      .then(response => {
        this.notify('Successfully edited user info');
      })
      .catch(error => {
        console.log('error', error.response);
      });
  };

  reset = () => {
    this.setState({
      firstname: null,
      lastname: null,
      email: null,
      phone_number: null,
      dob: null
    });
  };

  render() {
    return (
      this.state.validToken && (
        <Switch>
          <Route path="/admin/users/:userId" component={User} />
          <Route exact path="/admin/users">
            <>
              <div className="rna-container">
                <NotificationAlert ref="notificationAlert" />
              </div>
              <div className="content">
                <Row>
                  <Col>
                    <div className="float-right">
                      <UncontrolledDropdown style={{ marginRight: 20, marginTop: 5, marginBottom: 20 }}>
                        {!this.state.showForm ? (
                          <DropdownToggle caret={false} className="btn btn-success" color="default" data-toggle="dropdown" type="button">
                            <div>
                              <i className="tim-icons icon-simple-add" style={{ marginRight: 10 }} />
                              Add User
                            </div>
                          </DropdownToggle>
                        ) : (
                          <Button
                            className="btn btn-danger"
                            onClick={() => {
                              this.toggleForm();
                            }}
                          >
                            <div>
                              <i className="tim-icons icon-simple-remove" style={{ marginRight: 10 }} />
                              Cancel
                            </div>
                          </Button>
                        )}
                        {!this.state.showForm && (
                          <DropdownMenu right>
                            <DropdownItem onClick={() => this.toggleForm('merchant')}>Merchant</DropdownItem>
                            <DropdownItem onClick={() => this.toggleForm('driver')}>Driver</DropdownItem>
                          </DropdownMenu>
                        )}
                      </UncontrolledDropdown>
                    </div>
                    <Card className="card-form">
                      <Collapse isOpen={this.state.showForm}>
                        {this.state.form === 'merchant' && <CreateMerchantWizard toggleDropdown={this.toggleForm} notify={this.notify}></CreateMerchantWizard>}
                        {this.state.form === 'driver' && <CreateDriverWizard toggleDropdown={this.toggleForm} notify={this.notify}></CreateDriverWizard>}
                      </Collapse>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <div className="tools float-right">
                          <Row>
                            <Col>
                              <Input placeholder="Search users..." onChange={this.handleSearchChange}></Input>
                            </Col>
                            <Col md="19">
                              <UncontrolledDropdown style={{ marginRight: 20, marginTop: 5 }}>
                                <DropdownToggle caret className="btn-link btn-icon" color="default" data-toggle="dropdown" type="button">
                                  <i className="fas fa-filter"></i>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem className="text-success" onClick={() => this.filterRole('all')}>
                                    All
                                  </DropdownItem>
                                  <DropdownItem onClick={() => this.filterRole(0)}>Super Admins</DropdownItem>
                                  <DropdownItem onClick={() => this.filterRole(100)}>Admins</DropdownItem>
                                  <DropdownItem onClick={() => this.filterRole(200)}>Merchants</DropdownItem>
                                  <DropdownItem onClick={() => this.filterRole(300)}>Drivers</DropdownItem>
                                  <DropdownItem onClick={() => this.filterRole(400)}>Customers</DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </Col>
                          </Row>
                        </div>
                        <CardTitle tag="h3">
                          <i className="tim-icons text-primary" /> Users
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Table>
                          <thead className="text-primary">
                            <tr>
                              <th>Auth0-ID</th>
                              <th>Username</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Primary Phone</th>
                              <th>Role</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.shownUsers.map((user, index) => {
                              const roleDescription = user.Roles && user.Roles.length > 0 ? user.Roles[0].role_description : 'No role';
                              return (
                                <tr key={index}>
                                  <td>{user.auth0_id}</td>
                                  <td>{user.username}</td>
                                  <td>
                                    {user.firstname} {user.lastname}
                                  </td>
                                  <td>{user.email}</td>
                                  <td>{user.phone}</td>
                                  <td>{roleDescription}</td>
                                  <td>
                                    <i
                                      className="tim-icons icon-pencil"
                                      style={{ cursor: 'pointer', fontSize: '1.5em', paddingRight: '10px' }}
                                      onClick={() => {
                                        this.setState({
                                          firstname: user.firstname,
                                          lastname: user.lastname,
                                          phone_number: user.phone,
                                          email: user.email,
                                          dob: user.dob,
                                          oldFirstName: user.firstname,
                                          oldLastName: user.lastname,
                                          oldPhoneNumber: user.phone,
                                          oldEmail: user.email,
                                          userId: user.auth0_id
                                        });
                                        this.toggle();
                                      }}
                                    />
                                    <Route path="/admin/users/:user_id" component={User} />
                                    <Link to={'/admin/users/' + user.auth0_id}>
                                      <i className="tim-icons icon-badge" style={{ fontSize: '1.5em', cursor: 'pointer', color: '#cdcaca', marginLeft: '10px' }} />
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} style={{ color: `black` }}>
                      <ModalHeader>
                        <strong>Edit User Info</strong>
                      </ModalHeader>
                      <ModalBody>
                        <Label>First Name</Label>
                        <Input required type="text" style={{ color: `black` }} onChange={e => this.handleFirstName(e)} value={this.state.firstname} />
                        <Label>Last Name</Label>
                        <Input required type="text" style={{ color: `black` }} onChange={e => this.handleLastName(e)} value={this.state.lastname} />
                        <Label>Email</Label>
                        <Input required type="email" style={{ color: `black` }} onChange={e => this.handleEmail(e)} value={this.state.email} />
                        <Label>Phone Number</Label>
                        <Input required type="phone" style={{ color: `black` }} onChange={e => this.handlePhone(e)} value={this.state.phone_number} />
                        <div
                          style={{
                            textAlign: 'center',
                            marginTop: '5%',
                            color: '#EA2835',
                            cursor: 'pointer'
                          }}
                          onClick={() => this.resetPassword(this.state.oldEmail)}
                        >
                          Send password reset email to {this.state.oldEmail}
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="danger"
                          onClick={() => {
                            this.toggle();
                            this.reset();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            this.editUser();
                            this.toggle();
                          }}
                        >
                          Save
                        </Button>
                      </ModalFooter>
                    </Modal>
                    {/* </Card> */}
                  </Col>
                </Row>
              </div>
            </>
          </Route>
        </Switch>
      )
    );
  }
}

export default ExtendedTables;
