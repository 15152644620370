import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Button, Card, CardBody, CardTitle, Label, FormGroup, Input, Row, Col, Collapse, Container } from 'reactstrap';

export default function MapFilters({
  trafficLayer,
  setTrafficLayer,
  readyShipmentsCheck,
  setReadyShipmentsCheck,
  deliveringShipmentsCheck,
  setDeliveringShipmentsCheck,
  driversEnrouteCheck,
  setDriversEnrouteCheck
}) {
  function reset() {
    let trafficCB = document.getElementById('traffic-layer');
    trafficCB.checked = false;
    let shipmentReadyCB = document.getElementById('shipment-ready');
    shipmentReadyCB.checked = false;
    let shipmentDeliveringCB = document.getElementById('shipment-delivering');
    shipmentDeliveringCB.checked = false;
    let driverEnrouteCB = document.getElementById('driver-enroute');
    driverEnrouteCB.checked = false;
    setTrafficLayer(false);
    setReadyShipmentsCheck(false);
    setDeliveringShipmentsCheck(false);
    setDriversEnrouteCheck(false);
  }
  return (
    <Container>
      <Row style={{ display: 'flex', justifyContent: 'space-around', color: 'white' }}>
        <Col xs lg="2">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="traffic-layer"
                value={trafficLayer}
                onClick={e => {
                  setTrafficLayer(!trafficLayer);
                }}
              />
              <span className="form-check-sign" />
              Traffic
            </Label>
          </FormGroup>
        </Col>
        <Col xs lg="2">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                name="status"
                type="checkbox"
                id="shipment-ready"
                onClick={e => {
                  setReadyShipmentsCheck(!readyShipmentsCheck);
                }}
              />
              <span className="form-check-sign" />
              Ready
            </Label>
          </FormGroup>
        </Col>
        <Col xs lg="3">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                name="status"
                type="checkbox"
                id="shipment-delivering"
                onClick={e => {
                  setDeliveringShipmentsCheck(!deliveringShipmentsCheck);
                }}
              />
              <span className="form-check-sign" />
              Delivering
            </Label>
          </FormGroup>
        </Col>
        <Col xs lg="2">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="driver-enroute"
                // defaultChecked="checked"
                onClick={e => {
                  setDriversEnrouteCheck(!driversEnrouteCheck);
                }}
              />
              <span className="form-check-sign" />
              Drivers
            </Label>
          </FormGroup>
        </Col>
        <Col xs lg="2">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Button
              size="sm"
              color="default"
              onClick={() => {
                reset();
              }}
              style={{ marginTop: '-5px' }}
            >
              Clear
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
}
