import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { useHistory } from 'react-router';
// import moment from 'moment';
import moment from 'moment-timezone';
import axios from 'axios';
import * as inventory_urls from '../Merchant/urls/merchanturls';

import { Styles, StyledHr } from './Styles/CredentialStyle';
import Loading from '../Global/Loading';
import { Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaTimes } from 'react-icons/fa';

export const Credentials = props => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  // const [storeId, setStoreId] = useState(props.store.store_id);
  const [clientId, setClientId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [copied, setCopied] = useState(false);
  const [toCopyValue, setToCopyValue] = useState(null);

  // useEffect(() => {
  //     axios({
  //         method: `post`,
  //         headers: {
  //             'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
  //             'content-type': 'application/json'
  //         },
  //         url: inventory_urls.hasuraHost,
  //         data: {query: `{Stores (where: { store_id: {_eq: "`+ props.store.store_id +`" } } ) { store_id}}`}
  //     }).then(response => {
  //         console.log(response.data.data.Stores[0].store_id);
  //         setStoreId(response.data.data.Stores[0].store_id);
  //     });
  // }, []);

  useEffect(() => {
    axios({
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: inventory_urls.openAPICredentials + props.store.store_id + '/open-api/get-credentials'
    })
      .then(response => {
        //   console.log("response.data.payload", response.data.payload.client_id)
        setClientId(response.data.payload.client_id);
        setClientSecret(response.data.payload.client_secret);
      })
      .catch(error => {
        console.log('error: ', error.response);
      });
  }, []);
  // console.log(clientId)
  // console.log(clientSecret)

  const resetCredentials = () => {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: inventory_urls.openAPICredentials + props.store.store_id + '/open-api/create-credentials'
    })
      .then(response => {
        console.log('response: ', response);
        //   console.log("response.data.payload", response.data.payload.client_id)
        setClientId(response.data.payload.client_id);
        setClientSecret(response.data.payload.client_secret);
      })
      .catch(error => {
        console.log('error: ', error.response);
      });
  };

  if (loading) return <Loading />;
  return (
    <Styles className="store-manager-container">
      <button
        style={{
          position: 'relative',
          top: '2.7rem',
          left: '15.2rem',
          fontSize: '1.5rem',
          cursor: 'pointer',
          color: 'hsl(360, 67%, 44%)',
          zIndex: '1',
          background: 'transparent'
        }}
        onClick={props.closeCredentialsModal}
      >
        <FaTimes />
      </button>
      <Card>
        <CardHeader className="store-header">
          <h4>Credentials</h4>
          <StyledHr />
        </CardHeader>
        <CardBody>
          <p>
            {/* Information regarding the following. Lorem porem gorem jjih khaslkd a sldkj. Las asldj aksdjasdkljhaiosd ak. Kshd as akj hdakd a kjahsdkasjhd aasd asd f aasd asd. */}
          </p>

          {/* <StyledHr /> */}

          <Row>
            <Col>
              <p style={{ fontSize: '12px', marginBottom: '2px', letterSpacing: '0.5px' }}>CLIENT ID</p>
            </Col>

            <Col style={{ textAlign: 'right' }}>
              <p style={{ fontSize: '11px', color: '#5e72e4', cursor: 'pointer' }} onClick={resetCredentials}>
                Reset?
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup style={{ marginBottom: '25px' }}>
                <Input
                  placeholder="CLIENT ID"
                  style={{
                    border: 'none',
                    backgroundColor: 'rgba(52, 70, 117, 0.25)',
                    color: '#cdcaca'
                  }}
                  value={clientId}
                />
                <InputGroupText
                  style={{
                    border: 'none',
                    borderRadius: '0px 5px 5px 0px',
                    backgroundColor: 'rgba(52, 70, 117, 0.25)',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(clientId);
                  }}
                >
                  <div
                    style={{
                      height: '10px',
                      width: '10px',
                      backgroundColor: 'none',
                      borderColor: '#42559b',
                      borderWidth: '1.5px',
                      borderStyle: 'solid',
                      borderRadius: '3px',
                      marginTop: '-5px',
                      marginLeft: '-5px'
                    }}
                  ></div>

                  <div
                    style={{
                      height: '10px',
                      width: '10px',
                      backgroundColor: '#2d2f47',
                      borderColor: '#42559b',
                      borderWidth: '1.5px',
                      borderStyle: 'solid',
                      borderRadius: '3px',
                      marginTop: '1px',
                      marginLeft: '-6px'
                    }}
                  ></div>
                </InputGroupText>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <p style={{ fontSize: '12px', marginBottom: '2px', letterSpacing: '0.5px' }}>CLIENT SECRET</p>
            </Col>

            <Col style={{ textAlign: 'right' }}>
              <p style={{ fontSize: '11px', color: '#5e72e4', cursor: 'pointer' }} onClick={resetCredentials}>
                Reset?
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup style={{ marginBottom: '25px' }}>
                <Input placeholder="CLIENT SECRET" style={{ border: 'none', backgroundColor: 'rgba(52, 70, 117, 0.25)', color: '#cdcaca' }} value={clientSecret} />
                <InputGroupText
                  style={{
                    border: 'none',
                    borderRadius: '0px 5px 5px 0px',
                    backgroundColor: 'rgba(52, 70, 117, 0.25)',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(clientSecret);
                  }}
                >
                  <div
                    style={{
                      height: '10px',
                      width: '10px',
                      backgroundColor: 'none',
                      borderColor: '#42559b',
                      borderWidth: '1.5px',
                      borderStyle: 'solid',
                      borderRadius: '3px',
                      marginTop: '-5px',
                      marginLeft: '-5px'
                    }}
                  ></div>

                  <div
                    style={{
                      height: '10px',
                      width: '10px',
                      backgroundColor: '#2d2f47',
                      borderColor: '#42559b',
                      borderWidth: '1.5px',
                      borderStyle: 'solid',
                      borderRadius: '3px',
                      marginTop: '1px',
                      marginLeft: '-6px'
                    }}
                  ></div>
                </InputGroupText>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <p style={{ fontSize: '12px', marginBottom: '2px', letterSpacing: '0.5px' }}>STORE ID</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup style={{ marginBottom: '25px' }}>
                <Input placeholder="STORE ID" style={{ border: 'none', backgroundColor: 'rgba(52, 70, 117, 0.25)', color: '#cdcaca' }} value={props.store.store_id} />
                <InputGroupText
                  style={{
                    border: 'none',
                    borderRadius: '0px 5px 5px 0px',
                    backgroundColor: 'rgba(52, 70, 117, 0.25)',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(props.store.store_id);
                  }}
                >
                  <div
                    style={{
                      height: '10px',
                      width: '10px',
                      backgroundColor: 'none',
                      borderColor: '#42559b',
                      borderWidth: '1.5px',
                      borderStyle: 'solid',
                      borderRadius: '3px',
                      marginTop: '-5px',
                      marginLeft: '-5px'
                    }}
                  ></div>

                  <div
                    style={{
                      height: '10px',
                      width: '10px',
                      backgroundColor: '#2d2f47',
                      borderColor: '#42559b',
                      borderWidth: '1.5px',
                      borderStyle: 'solid',
                      borderRadius: '3px',
                      marginTop: '1px',
                      marginLeft: '-6px'
                    }}
                  ></div>
                </InputGroupText>
              </InputGroup>
            </Col>
          </Row>
          {/* <Row style={{height: '347px'}}></Row>
                                <Row>
                                    <Col>
                                        <p>Give Feedback</p>
                                    </Col>
                                    <Col>
                                        <p>Need Help?</p>
                                    </Col>
                                </Row> */}
        </CardBody>
      </Card>
    </Styles>
  );
};
