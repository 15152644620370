import styled from 'styled-components';

export const Styles = styled.div`
    margin: 120px 30px 10px 115px;
    width: 85%;
        div.header {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 130px;
                border-radius: 5px;
                background-color: #27293d;
                margin-top: -40px;
                    .routing-title {
                        width: 50%;
                        margin: auto 0;
                        h4 {
                            color: #feffff;
                            font-size: 16px;
                            font-weight: 300;
                            margin-left: 15px;
                        }
                        a {
                            color: #f15a29;
                            font-weight: 600;
                            font-size: 18px;      
                            margin-left: 10px;              
                        }
                    }
                div.upload-add-container {
                    display: flex;
                    flex-direction: row-reverse;
                    width: 50%;
                    margin: auto 0;
                        button.upload, button.product {
                            display: flex;
                            flex-direction: initial;
                            align-items: center;
                            justify-content: center;
                            width: 250px;
                            height: 50px;
                            border-radius: 5px;
                            background-color: rgba(94, 114, 228, 0.5);
                            font-size: 13px;
                            font-weight: 600;
                            color: #efeeee;
                            border-color: transparent;
                            margin-right: 5px;
                        }
                            button.upload {
                                background-color: rgba(94, 114, 228, 0.5);
                            }
                            button.product {
                                background-image: linear-gradient(to right, #f7941d, #f15a29);
                            }
                    }
                    
            }
            div.table-container {
                background-color: #27293d;
                border-radius: 5px;
                padding: 10px 10px;
            }
`;