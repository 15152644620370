import React, { useState, useEffect } from "react";
import Switch from "react-switch"; 
// import moment from 'moment';
import moment from 'moment-timezone';
import { Styles } from './Styles/EditDaysOfWeekStyles';
import { FormGroup } from "reactstrap";
import ReactDatetime from 'react-datetime';
import { formatError } from "graphql";
import Loading from '../../Global/Loading';

export const EditDaysOfWeek = (props) => {
  const [openTime, setOpenTime] = useState(null);
  const [closeTime, setCloseTime] = useState(null);
  const [localChecked, setLocalChecked] = useState(false);
  const [loading, setLoading] = useState(true);

  const onClick = () => {
    
    if (localChecked) {
      setOpenTime(null);
      setCloseTime(null);
    } else if (props.oldOpenTime && props.oldCloseTime){
      setOpenTime(moment.utc().day(props.dayOfWeek).hour(props.oldOpenTime.split(':')[0]).minute(props.oldOpenTime.split(':')[1]).second(0).millisecond(0).tz(props.getTimezone));
      setCloseTime(moment.utc().day(props.dayOfWeek).hour(props.oldCloseTime.split(':')[0]).minute(props.oldCloseTime.split(':')[1]).second(0).millisecond(0).tz(props.getTimezone));
    }
    else {
      setOpenTime(moment.utc().day(props.dayOfWeek).hour(13).minute(0).second(0).millisecond(0));
      setCloseTime(moment.utc().day(props.dayOfWeek).hour(1).minute(0).second(0).millisecond(0));
    }
    console.log("Moment object opentime", moment.utc().day(props.dayOfWeek).hour(8).minute(0).second(0).millisecond(0))
    console.log("Moment object closetime", moment.utc().day(props.dayOfWeek).hour(8).minute(0).second(0).millisecond(0))
    setLocalChecked(!localChecked);
    props.onCheckClick(props.dayOfWeek);  
  };

  var moment = require('moment');
  var now = moment();

  let timeConstraint = { 
      hours: {
          min: 0, max: 23, step: 1
      },
      minutes: {
          min: 0, max: 59, step: 1
      }
  }

  useEffect(() => {
    if (openTime && localChecked) {
      if (!closeTime) {
        props.onChange(props.dayOfWeek, openTime, openTime);
      } else {
        props.onChange(props.dayOfWeek, openTime, closeTime);
      }
    } else if (!localChecked) {
      props.onChange(props.dayOfWeek, null, null);
    }
  }, [openTime, closeTime, localChecked]);

  useEffect(() => {
    if (props.oldOpenTime && props.oldCloseTime) {
      // let oldOpenTime = new Date();
      // let oldCloseTime = new Date();
      // let openArray = props.oldOpenTime.split(':');
      // let closeArray = props.oldCloseTime.split(':');

      // console.log("OLD Open", props.oldOpenTime);
      // console.log("OLD Closed", props.oldCloseTime);
     // console.log("DOW", props.dayOfWeek);

      // TODO: Replace the hardcoded timezone with props value
      // let convertedOpenTime = moment.utc().day(props.dayOfWeek).hour(props.oldOpenTime.split(':')[0]).minute(props.oldOpenTime.split(':')[1]).second(0).millisecond(0).tz('America/Chicago');
      // let convertedCloseTime = moment.utc().day(props.dayOfWeek).hour(props.oldCloseTime.split(':')[0]).minute(props.oldCloseTime.split(':')[1]).second(0).millisecond(0).tz('America/Chicago');
      // console.log("NEW Open", convertedOpenTime.format("HH:mm:ss"));
      // console.log("NEW Closed", convertedCloseTime.format("HH:mm:ss"));



      // oldOpenTime.setHours(openArray[0]);
      // oldOpenTime.setMinutes(openArray[1]);
      // oldOpenTime.setSeconds(openArray[2]);

      // oldCloseTime.setHours(openArray[0]);
      // oldCloseTime.setMinutes(openArray[1]);
      // oldCloseTime.setSeconds(openArray[2]);

      // setOpenTime(oldOpenTime);
      // setCloseTime(oldCloseTime);

      let [oHour, oMinute, oSecond] = props.oldOpenTime.split(':');
      // let [oHour, oMinute, oSecond] = convertedOpenTime.format('HH:mm:ss').split(':')
      let openHour = parseInt(oHour);
      let openMinute = parseInt(oMinute);
      let openSecond = parseInt(oSecond);
      let openM;
      
      if (openHour < 0) {
        openM = "PM";
        openHour = openHour + 12;
      } else if (openHour > 0 && openHour < 12) {
        openM = "AM";
      } else if (openHour > 12) {
        openM = "PM";
        openHour = openHour - 12;
      } else if (openHour === 0) {
        openHour = 12;
        openM = "AM";
      } else {
        openHour = 12;
        openM = "PM";
      }

      if (openMinute.toString().length < 2) {
        openMinute = "0" + openMinute;
      }
      if (openSecond.toString().length < 2) {
        openSecond = "0" + openSecond;
      }
      

      if(openHour/10 >= 1){
        // openHour = openHour;
        setOpenTime(openHour + ":" + openMinute + ":" + openSecond);
        // console.log("openHour", openHour)
        // console.log("openMinute", closeMinute)
        // console.log("openSecond", openSecond)
      } else {
        // openHour = openHour;
        setOpenTime("0" + openHour + ":" + openMinute + ":" + openSecond);
        // console.log("openHour", openHour)
        // console.log("openMinute", openMinute)
        // console.log("openSecond", openSecond)
      }

      let [cHour, cMinute, cSecond] = props.oldCloseTime.split(':');
      // let [cHour, cMinute, cSecond] = convertedCloseTime.format('HH:mm:ss').split(':')

      let closeHour = parseInt(cHour);
      let closeMinute = parseInt(cMinute);
      let closeSecond = parseInt(cSecond);
      let closeM;

      if (closeHour < 0) {
        closeM = "PM";
        closeHour = closeHour + 12;
      } else if (closeHour > 0 && closeHour < 12) {
        closeM = "AM";
      } else if (closeHour > 12) {
        closeM = "PM";
        closeHour = closeHour - 12;
      } else if (closeHour === 0) {
        closeHour = 12;
        closeM = "AM";
      } else {
        closeHour = 12;
        closeM = "PM";
      }

      if (closeMinute.toString().length < 2) {
        closeMinute = "0" + closeMinute;
      }
      if (closeSecond.toString().length < 2) {
        closeSecond = "0" + closeSecond;
      }

      if(closeHour/10 >= 1){
        // closeHour = closeHour;
        setCloseTime(closeHour + ":" + closeMinute + ":" + closeSecond);
        // console.log("closeHour", closeHour)
        // console.log("closeMinute", closeMinute)
        // console.log("closeSecond", closeSecond)
      } else {
        // closeHour = closeHour;
        setCloseTime("0" + closeHour + ":" + closeMinute + ":" + closeSecond);
        // console.log("closeHour", closeHour)
        // console.log("closeMinute", closeMinute)
        // console.log("closeSecond", closeSecond)
      }
      
      

    }
    else {
      setOpenTime(props.oldOpenTime);
      setCloseTime(props.oldCloseTime);
    }
    
    
    setLocalChecked(props.checked);
    setLoading(false);
  }, [])

  const handleOpenChange = (e) => {
    // let timeInUtc = e.utc().format("HH:mm");
    setOpenTime(e)
    // console.log("open time", e)
    // console.log("open time in utc", timeInUtc)
  }

  const handleCloseChange = (e) => {
    // let timeInUtc = e.utc().format("HH:mm");
    setCloseTime(e)
    // console.log("close time", e);
    // console.log("close time in utc", timeInUtc)
  }

  // console.log("Timezone", props.getTimezone);
  
  if (loading) return <Loading />;
  return (
        <Styles className="individual-days">
          <div>{props.label}</div>
          <FormGroup>
            <ReactDatetime
                inputProps={{
                    className: "open-time",
                    placeholder: "8:00:00",
                    readOnly: true,
                    disabled: !localChecked,
                    // defaultValue: "8:00 AM"
                }}
                className= "react-date-time"
                onChange={e => handleOpenChange(e)}
                timeFormat={true}
                timeConstraints={timeConstraint}
                dateFormat={false}
                closeOnTab={true}
                // value={openTime ? openTime : ""}
                defaultValue={props.oldOpenTime ? moment.utc().day(props.dayOfWeek).hour(props.oldOpenTime.split(':')[0]).minute(props.oldOpenTime.split(':')[1]).second(0).millisecond(0).tz(props.getTimezone) : ""}
              
            />
          </FormGroup>
          <p>-</p>
          <FormGroup>
            <ReactDatetime
                inputProps={{
                    className: "close-time",
                    placeholder: "8:00:00",
                    readOnly: true,
                    disabled: !localChecked,
                    // defaultValue: "8:00 PM"
                }}
                onChange={e => handleCloseChange(e)}
                timeFormat={true}
                timeConstraints={timeConstraint}
                dateFormat={false}
                closeOnTab={true}
                // value={closeTime ? closeTime : ""}
                defaultValue={props.oldCloseTime ? moment.utc().day(props.dayOfWeek).hour(props.oldCloseTime.split(':')[0]).minute(props.oldCloseTime.split(':')[1]).second(0).millisecond(0).tz(props.getTimezone) : ""}  
            />
          </FormGroup>
          <Switch
            checked={localChecked}
            onChange={onClick}
            onColor="#2bbbae"
            offColor="#f15a29"
          />
        </Styles>
  );
};