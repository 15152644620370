import React, {useState, useEffect} from 'react';
import { FormGroup, UncontrolledTooltip } from 'reactstrap';

import { Styles } from './Styles/ProductImageStyles';
import { UploadIcon } from '../../../assets/svgs/WhiteIcons';
import ImageUpload from '../../CustomUpload/ImageUpload.jsx';


const ProductImage = (props) => {
    const [image, setImage] = useState(null);

    const grabImage = (image) => {
        props.setImage(image);
    }

    useEffect(() => {
        if (props.product) {
            setImage(props.product.image);
        }
    }, [props.product])

    return (
        <Styles className="image-upload-style-container">
            <FormGroup className="image-upload-container">
                <div className="title-container">
                    <h4 className="title-h4">
                        Image{' '}
                        <span id="UncontrolledTooltipExample">
                            <i className="tim-icons icon-alert-circle-exc"/>
                        </span>
                        <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
                            Upload a photo of the product for your customers to see
                        </UncontrolledTooltip>
                    </h4>
                    {/* <h4 className="desc-h4">Upload Images</h4> */}
                </div>
                <hr style={{borderColor: '#344675', borderStyle: 'solid'}}></hr>
                <div className="images-container">
                    <div className="button-paragraph">
                        {/* <button className="upload-btn">
                            <span style={{marginLeft: `2px`,}}><UploadIcon />Choose files to upload</span>
                        </button> */}
                        <ImageUpload
                            addBtnColor="default"
                            changeBtnColor="default"
                            removeBtnColor="danger"
                            sendImage={grabImage}
                            existingImage={image}
                        />
                        {/* <p>or drag and drop here.</p> */}
                    </div>
                </div>
            </FormGroup>
         </Styles>
    )
}

export default ProductImage;