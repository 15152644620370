import React, { useState, useEffect } from "react";
import Loading from '../../Global/Loading';
import { EditDaysOfWeek } from './EditDaysOfWeek';
import { Styles, StyledHr } from './Styles/RegularHoursStyles';

const RegularHours = props => {
    
    // const[timezone, setTimezone] = useState(null);
    const [loading, setLoading] = useState(true);
    const [checked, setChecked] = useState([
        false, false, false, false, false, false, false
    ]);

    const onCheckClick = is_closed => {
        let prev = checked;
        prev[is_closed] = !prev[is_closed];
        setChecked(prev);
    };

    
    const handleChange = (dayOfWeek, openTime, closeTime, is_closed) => {
        // console.log("Day Of Week", dayOfWeek);
        // console.log("Day Of Week", openTime);
        // console.log("Day Of Week", closeTime);
        props.fetchRegularHours(dayOfWeek, openTime, closeTime, is_closed);
    }


    useEffect(() => {
        // setTimezone([props.fetchTimezone])
        // console.log("TimeZone", props.fetchTimezone)
        setChecked([
            props.oldStoreTimes[0].open_time !== null,
            props.oldStoreTimes[1].open_time !== null,
            props.oldStoreTimes[2].open_time !== null,
            props.oldStoreTimes[3].open_time !== null,
            props.oldStoreTimes[4].open_time !== null,
            props.oldStoreTimes[5].open_time !== null,
            props.oldStoreTimes[6].open_time !== null
        ])
        setLoading(false);
    }, []);


    if (loading) return <Loading />;
    return (
        <Styles className="regular-hours">
            <h5>Regular Hours of Operation</h5>
            <StyledHr />
            <div className="regular-hours-header">
                <h6>Day</h6>
                <h6>From</h6>
                <p>{" "}</p>
                <h6>To</h6>
                <h6>Open/Closed</h6>
            </div>
            <StyledHr />
            <div className="regular-hrs-grid-container">
                <EditDaysOfWeek
                    label="Sunday"
                    dayOfWeek="0"
                    checked={checked[0]}
                    onCheckClick={onCheckClick}
                    onChange={handleChange}
                    oldOpenTime={props.oldStoreTimes[0].open_time}
                    oldCloseTime={props.oldStoreTimes[0].close_time}
                    getTimezone={props.fetchTimezone}
                />
                <EditDaysOfWeek 
                    label="Monday"
                    dayOfWeek="1"
                    checked={checked[1]}
                    onCheckClick={onCheckClick}
                    onChange={handleChange}
                    oldOpenTime={props.oldStoreTimes[1].open_time}
                    oldCloseTime={props.oldStoreTimes[1].close_time}
                    getTimezone={props.fetchTimezone}
                />
                <EditDaysOfWeek 
                    label="Tuesday"
                    dayOfWeek="2"
                    checked={checked[2]}
                    onCheckClick={onCheckClick}
                    onChange={handleChange}
                    oldOpenTime={props.oldStoreTimes[2].open_time}
                    oldCloseTime={props.oldStoreTimes[2].close_time}
                    getTimezone={props.fetchTimezone}
                />
                <EditDaysOfWeek 
                    label="Wednesday"
                    dayOfWeek="3"
                    checked={checked[3]}
                    onCheckClick={onCheckClick}
                    onChange={handleChange}
                    oldOpenTime={props.oldStoreTimes[3].open_time}
                    oldCloseTime={props.oldStoreTimes[3].close_time}
                    getTimezone={props.fetchTimezone}
                />
                <EditDaysOfWeek 
                    label="Thursday"
                    dayOfWeek="4"
                    checked={checked[4]}
                    onCheckClick={onCheckClick}
                    onChange={handleChange}
                    oldOpenTime={props.oldStoreTimes[4].open_time}
                    oldCloseTime={props.oldStoreTimes[4].close_time}
                    getTimezone={props.fetchTimezone}
                />
                <EditDaysOfWeek 
                    label="Friday"
                    dayOfWeek="5"
                    checked={checked[5]}
                    onCheckClick={onCheckClick}
                    onChange={handleChange}
                    oldOpenTime={props.oldStoreTimes[5].open_time}
                    oldCloseTime={props.oldStoreTimes[5].close_time}
                    getTimezone={props.fetchTimezone}
                />
                <EditDaysOfWeek 
                    label="Saturday"
                    dayOfWeek="6"
                    checked={checked[6]}
                    onCheckClick={onCheckClick}
                    onChange={handleChange}
                    oldOpenTime={props.oldStoreTimes[6].open_time}
                    oldCloseTime={props.oldStoreTimes[6].close_time}
                    getTimezone={props.fetchTimezone}
                />
            </div>
        </Styles>
    )
}

export default RegularHours;