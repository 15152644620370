import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Dropdown, DropdownMenu, DropdownToggle, Alert, Modal, ModalBody, Label, Input, FormGroup } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import * as Semantic from 'semantic-ui-react';
import Switch from 'react-switch';
import { Styles } from './Styles/CategoriesStyles';
import { AddIcon } from '../../assets/svgs/WhiteIcons';
import { PencilIcon, TrashIcon } from '../../assets/svgs/WhiteIcons';
import Loading from '../Global/Loading';
import { Breadcrumbs, Typography } from '@material-ui/core';

import * as inventory_urls from '../../urls/api-inventory/admin';

export const Category = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [category, setCategory] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [index, setIndex] = useState(null);
  const [departmentName, setDepartmentName] = useState('');
  const [departmentTaxRate, setDepartmentTaxRate] = useState(null);
  const [departmentTransactionRate, setDepartmentTransactionRate] = useState(null);
  const [departmentContainsAlcohol, setDepartmentContainsAlcohol] = useState(false);
  const [storeTaxRate, setStoreTaxRate] = useState(null);
  const [storeTransactionRate, setStoreTransactionRate] = useState(null);
  const [storeName, setStoreName] = useState(null);
  const [storeId, setStoreId] = useState(null);

  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const [categoryTaxRate, setCategoryTaxRate] = useState(null);
  const [categoryTaxCode, setCategoryTaxCode] = useState(null);
  const [categoryTransactionRate, setCategoryTransactionRate] = useState(null);
  const [taxCodes, setTaxCodes] = useState([]);
  const [categoryContainsAlcohol, setCategoryContainsAlcohol] = useState(false);

  const [showEditCategoryDialog, setShowEditCategoryDialog] = useState(false);
  const [showAddCategoryDialog, setShowAddCategoryDialog] = useState(false);
  const [editCategoryIndex, setEditCategoryIndex] = useState(null);
  const [deleteCategoryIndex, setDeleteCategoryIndex] = useState(null);

  const [editCategoryObj, setEditCategoryObj] = useState(null);

  let skipGeneralClick = false;
  let history = useHistory();
  let params = useParams();

  const { register, errors } = useForm();

  useEffect(() => {
    getCategories(params.department_id);
    getTaxCodes();
  }, []);

  // useEffect(() => {
  // }, [categories])

  const getCategories = department_id => {
    axios({
      method: `post`,
      url: inventory_urls.hasuraHost,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      data: {
        query: `{Departments(where: {department_id: {_eq: "${department_id}"}}) {
                tax_rate,
                tax_code,
                transaction_rate,
                contains_alcohol,
                name,
                Store {
                    name,
                    tax_rate,
                    transaction_rate,
                    sells_alcohol
                }
                Categories(order_by: {name: asc}) {
                    category_id,
                    name,
                    contains_alcohol,
                    description,
                    tax_rate,
                    tax_code,
                    is_enabled,
                    transaction_rate
                }
            }
            Stores(where: {Departments: {department_id: {_eq: "${department_id}"}}}) {
                store_id
              }}`
      }
    })
      .then(response => {
        setLoading(false);
        if (response.data.errors) {
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].message === 'Malformed Authorization header' || response.data.errors[0].extensions.code === 'invalid-jwt') {
              this.props.history.push('/logout');
            }
          }
        }
        if (response.data.data) {
          setDepartmentName(response.data.data.Departments[0].name);
          setCategories(response.data.data.Departments[0].Categories);
          setDepartmentTaxRate(response.data.data.Departments[0].tax_rate);
          setDepartmentTransactionRate(response.data.data.Departments[0].transaction_rate);
          setDepartmentContainsAlcohol(response.data.data.Departments[0].contains_alcohol);
          setStoreTaxRate(response.data.data.Departments[0].Store.tax_rate);
          setStoreTransactionRate(response.data.data.Departments[0].Store.transaction_rate);
          setStoreName(response.data.data.Departments[0].Store.name);
          setStoreId(response.data.data.Stores[0].store_id);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        setError(error);
        console.log('error: ', error.response);
      });
  };

  const toggleCategory = (cat, index) => {
    var data = {
      is_enabled: !cat.is_enabled
    };
    axios({
      method: `put`,
      url: inventory_urls.editCategory + cat.category_id,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      data: data
    })
      .then(response => {
        let newCat = {
          category_id: response.data.payload.category_id,
          name: response.data.payload.name,
          description: response.data.payload.description,
          tax_rate: Number(response.data.payload.tax_rate),
          transaction_rate: Number(response.data.payload.transaction_rate),
          tax_code: response.data.payload.tax_code,
          is_enabled: response.data.payload.is_enabled
        };
        let cats = categories.slice();
        cats[index] = newCat;
        setCategories(cats);
      })
      .catch(error => {
        setError('Unable to toggle category state. Please try again later');
        console.log('error:', error);
      });
  };

  const getTaxCodes = () => {
    axios({
      method: 'GET',
      url: inventory_urls.getTaxCodes,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    })
      .then(response => {
        // Set taxCodes here:
        let newTaxCodes = [];
        response.data.payload.map((element, key) => {
          newTaxCodes.push({
            key: key,
            text: element.name,
            value: element.tax_code
          });
        });
        setTaxCodes(newTaxCodes);
        setLoading(false);
      })
      .catch(error => {
        console.log('error: ', error.response);
      });
  };

  const editCategory = () => {
    if (editCategoryObj) {
      axios({
        method: `put`,
        url: inventory_urls.editCategory + editCategoryObj.category_id,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'content-type': 'application/json'
        },
        data: editCategoryObj
      })
        .then(response => {
          let newCat = {
            category_id: response.data.payload.category_id,
            name: response.data.payload.name,
            description: response.data.payload.description,
            tax_rate: response.data.payload.tax_rate,
            transaction_rate: response.data.payload.transaction_rate,
            tax_code: response.data.payload.tax_code,
            is_enabled: response.data.payload.is_enabled
          };
          let cats = categories;
          cats[editCategoryIndex] = newCat;
          setCategories(cats);
          setEditCategoryIndex(null);
          setEditCategoryObj(null);
          setShowEditCategoryDialog(false);
        })
        .catch(error => {
          setEditCategoryIndex(null);
          setEditCategoryObj(null);
          setShowEditCategoryDialog(false);
          setError('Unable to update departments. Please try again later');
          console.log('error: ', error.response);
        });
    }
  };

  const addCategory = () => {
    if (categoryName !== '' && categoryDescription !== '') {
      axios({
        method: `post`,
        url: inventory_urls.createCategory,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'content-type': 'application/json'
        },
        data: {
          name: categoryName,
          description: categoryDescription,
          tax_rate: categoryTaxRate,
          transaction_rate: categoryTransactionRate / 100,
          department_id: params.department_id,
          contains_alcohol: categoryContainsAlcohol
        }
      })
        .then(response => {
          let newCat = {
            category_id: response.data.payload.category_id,
            name: response.data.payload.name,
            description: response.data.payload.description,
            tax_rate: response.data.payload.tax_rate,
            transaction_rate: response.data.payload.transaction_rate,
            tax_code: response.data.payload.tax_code,
            is_enabled: response.data.payload.is_enabled
          };
          let cats = categories.concat(newCat);
          setCategories(cats);
          setShowAddCategoryDialog(false);
        })
        .catch(error => {
          setError('Unable to add category. Please try again later');
          console.log('error: ', error.response);
        });
    }
  };

  const deleteCategory = category_id => {
    axios({
      method: 'DELETE',
      url: inventory_urls.deleteCategory + category_id,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token') }
    })
      .then(response => {
        let cats = categories;
        cats.splice(deleteCategoryIndex, 1);
        setCategories(cats);
        setCategory('');
        setDeleteCategoryIndex(null);
        setShowWarning(false);
      })
      .catch(error => {
        setCategory('');
        setDeleteCategoryIndex(null);
        setShowWarning(false);
        setError('Unable to delete category. Please try again later');
        console.log('error: ', error.response);
      });
  };

  const getTrProps = (rowInfo, column, cell) => {
    if (rowInfo) {
      return {
        style: {
          height: `70px`,
          margin: `5px`,
          padding: `0px`,
          background: `transparent`,
          cursor: 'pointer'
        },
        onClick: e => {
          if (!skipGeneralClick) {
            history.push('/admin/inventory/store/' + params.store_id + '/category/' + column.original.category_id);
          }
          skipGeneralClick = false;
        }
      };
    }
    return {};
  };

  const getTheadThProps = () => {
    return {
      style: {
        fontSize: `9px`,
        fontWeight: `600`,
        textAlign: `left`,
        color: `#cdcaca`,
        textTransform: `uppercase`
      }
    };
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: props => <div>{props.value}</div>
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: props => <div>{props.value}</div>
    },
    {
      Header: 'Tax Rate',
      accessor: 'tax_rate',
      Cell: props => (
        <div>{props.value ? (props.value * 100).toFixed(2) + '%' : departmentTaxRate ? (departmentTaxRate * 100).toFixed(2) + '%' : (storeTaxRate * 100).toFixed(2) + '%'}</div>
      )
    },
    {
      Header: 'Transaction Rate',
      accessor: 'transaction_rate',
      Cell: props => (
        <div>
          {props.value
            ? (props.value * 100).toFixed(2) + '%'
            : departmentTransactionRate
            ? (departmentTransactionRate * 100).toFixed(2) + '%'
            : (storeTransactionRate * 100).toFixed(2) + '%'}
        </div>
      )
    },
    {
      Header: 'Available',
      accessor: 'is_enabled',
      width: 100,
      Cell: props => (
        <Switch
          size={20}
          checked={props.value}
          onChange={() => {
            skipGeneralClick = true;
            toggleCategory(props.original, props.index);
          }}
          defaultValue={true}
          onColor="#2bbbae"
          offColor="#f15a29"
        />
      )
    },
    {
      Header: 'Actions',
      width: 100,
      Cell: props => (
        <Row>
          {/* <Col><LocalOfferIcon /></Col> */}
          <Col
            id="edit"
            style={{ cursor: `pointer` }}
            onClick={() => {
              skipGeneralClick = true;
              setEditCategoryObj(props.original);
              setEditCategoryIndex(props.index);
              setShowEditCategoryDialog(true);
            }}
          >
            <PencilIcon />
          </Col>
          <Col
            id="delete"
            style={{ cursor: `pointer` }}
            onClick={() => {
              skipGeneralClick = true;
              setCategory(props.original.category_id);
              setDeleteCategoryIndex(props.index);
              setShowWarning(true);
            }}
          >
            <TrashIcon />
          </Col>
        </Row>
      )
    }
  ];

  if (loading) return <Loading />;
  return (
    <>
      <Styles>
        <Alert color="danger" isOpen={error === '' ? false : true} toggle={() => setError('')}>
          <strong>{error}</strong>
        </Alert>
        <div className="header">
          <div className="routing-title">
            <Link to="/admin/dashboard" className="store-name">
              {departmentName}
            </Link>
            <h4>Categories</h4>
          </div>
          <div className="upload-add-container">
            <Link
              to={{
                pathname: '/admin/inventory/store/' + storeId + '/category/' + categoryId + '/product/' + 'add_product',
                state: {
                  isEdit: false,
                  product: null
                }
              }}
            >
              <button className="product">
                <AddIcon />
                <p style={{ marginLeft: `10px` }}>Add Product</p>
              </button>
            </Link>
            <button onClick={e => setShowAddCategoryDialog(true)} className="product">
              <AddIcon />
              <p style={{ marginLeft: `10px` }}>Add Category</p>
            </button>
          </div>
        </div>
        <div className="table-container">
          {/* <div className="sort-search-container">
                        <label>
                            <input type="search" />
                        </label>
                        <button className="view-all-btn">View All</button>
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle
                                tag="span"
                                data-toggle="dropdown"
                                aria-expanded={dropdownOpen}
                            >
                                Sort by
                            </DropdownToggle>
                            <DropdownMenu>
                                <div onClick={toggle}>Custom dropdown item</div>
                            </DropdownMenu>
                        </Dropdown>
                    </div> */}
          {/*Breadcrumbs*/}
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ color: '#5e72e4' }} to="/admin/inventory">
              Stores
            </Link>
            <Link style={{ color: '#5e72e4' }} to={`/admin/inventory/store/${storeId}`}>
              {storeName}
            </Link>

            <Typography style={{ color: '#5e72e4', fontWeight: 'bold' }}>{departmentName}</Typography>
          </Breadcrumbs>
          {/* End Breadcrumbs */}
          <ReactTable
            data={categories}
            columns={columns}
            resizable={false}
            defaultPageSize={5}
            className="-striped -highlight"
            getTrProps={getTrProps}
            getTheadThProps={getTheadThProps}
          />
        </div>
      </Styles>
      <div id="warning-alert" hidden={!showWarning}>
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-200px' }}
          title="Are you sure?"
          onConfirm={() => {
            deleteCategory(category);
          }}
          onCancel={() => {
            setCategory('');
            setShowWarning(false);
          }}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          <div>You are about to delete a category from your inventory.</div>
          <div>This will delete all products that belong to this category.</div>
          <div>This action cannot be undone.</div>
        </ReactBSAlert>
      </div>
      <Modal isOpen={showAddCategoryDialog} centered>
        <h4 style={{ textAlign: `left`, fontSize: `15px`, color: `#f15a29`, fontWeight: `500`, padding: `20px` }}>Add Category</h4>
        <ModalBody style={{ paddingTop: 0 }}>
          <FormGroup>
            <Label style={{ color: `#494747`, fontSize: `12px` }}>Category Name*</Label>
            <Input
              id="category-name"
              name="createCategoryName"
              type="text"
              onChange={e => setCategoryName(e.target.value)}
              style={{ color: 'black' }}
              placeholder="Powertools, Books, ect."
              ref={register({
                required: 'This field is required.'
              })}
            />
            {errors.createCategoryName}
          </FormGroup>
          <FormGroup>
            <Label style={{ color: `#494747`, fontSize: `12px` }}>Category Description*</Label>
            <Input
              id="category-description"
              name="createCategoryDescription"
              type="text"
              onChange={e => setCategoryDescription(e.target.value)}
              style={{ color: 'black' }}
              placeholder="Jackets, Hammers, Staff Favorites, etc."
              ref={register({
                required: 'This field is required.'
              })}
            />
            {errors.createCategoryDescription}
          </FormGroup>
          <FormGroup>
            <Label style={{ color: `#494747`, fontSize: `12px` }}>Tax Category</Label>
            {/* <Input 
                            id="category-tax-rate" 
                            type="number" 
                            step="0.01" 
                            onChange={(e) => setCategoryTaxRate(e.target.value)} 
                            style={{color: "black"}} 
                            placeholder="10%" 
                        /> */}
            <div>
              <Semantic.Dropdown
                placeholder="Tax Categories"
                button
                style={{
                  width: '100%',
                  // backgroundColor: '#27293d',
                  borderColor: '#000'
                  // color: '#efeeee'
                }}
                search
                selection
                value={categoryTaxCode}
                onChange={(e, data) => {
                  setCategoryTaxCode(data.value);
                }}
                // labeled
                options={taxCodes}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label style={{ color: `#494747`, fontSize: `12px` }}>Transaction Rate(%)</Label>
            <Input
              id="department-transaction-rate"
              onChange={e => setCategoryTransactionRate(e.target.value)}
              type="number"
              step="0.01"
              style={{ color: 'black' }}
              placeholder="10.25"
            />
            <h5 style={{ color: 'gray', marginTop: 10 }}>**Empty tax and transaction rates will inherit store values</h5>
            {departmentContainsAlcohol ? (
              <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    id="schedule-check"
                    onClick={e => {
                      setCategoryContainsAlcohol(!categoryContainsAlcohol);
                    }}
                  />
                  <span className="form-check-sign" />
                  This category may contain alcohol
                </Label>
              </FormGroup>
            ) : null}
            <div style={{ display: `flex`, flexDirection: `row-reverse`, margin: `15px 0px` }}>
              <button
                style={{ backgroundColor: 'orange', color: `#ffffff`, width: `70px`, height: `35px`, borderRadius: `5px`, border: `transparent`, marginLeft: `5px` }}
                onClick={() => {
                  addCategory();
                }}
              >
                Save
              </button>
              <button
                style={{ backgroundColor: `#344675`, color: `#ffffff`, width: `70px`, height: `35px`, borderRadius: `5px`, border: `transparent` }}
                onClick={() => {
                  setCategoryName('');
                  setCategoryDescription('');
                  setCategoryTaxRate(null);
                  setCategoryTransactionRate(null);
                  setShowAddCategoryDialog(false);
                  setCategoryContainsAlcohol(false);
                }}
              >
                Cancel
              </button>
            </div>
          </FormGroup>
        </ModalBody>
      </Modal>
      <Modal isOpen={showEditCategoryDialog} centered>
        <h4 style={{ textAlign: `left`, fontSize: `15px`, color: `#f15a29`, fontWeight: `500`, padding: `20px` }}>Edit Category</h4>
        {editCategoryObj !== null && (
          <ModalBody style={{ paddingTop: 0 }}>
            <FormGroup>
              <Label style={{ color: `#494747`, fontSize: `12px` }}>Category Name*</Label>
              <Input
                id="category-name"
                name="editCategoryName"
                value={editCategoryObj.name}
                onChange={e =>
                  setEditCategoryObj({
                    name: e.target.value,
                    category_id: editCategoryObj.category_id,
                    description: editCategoryObj.description,
                    tax_rate: editCategoryObj.tax_rate,
                    transaction_rate: editCategoryObj.transaction_rate,
                    tax_code: editCategoryObj.tax_code
                  })
                }
                type="text"
                style={{ color: 'black' }}
                placeholder="Powertools, Books, ect."
                ref={register({
                  required: 'This field is required.'
                })}
              />
              {errors.editCategoryName}
            </FormGroup>
            <FormGroup>
              <Label style={{ color: `#494747`, fontSize: `12px` }}>Category Description*</Label>
              <Input
                id="category-description"
                name="editCategoryDescription"
                type="text"
                value={editCategoryObj.description}
                onChange={e =>
                  setEditCategoryObj({
                    name: editCategoryObj.name,
                    category_id: editCategoryObj.cateogry_id,
                    description: e.target.value,
                    tax_rate: editCategoryObj.tax_rate,
                    transaction_rate: editCategoryObj.transaction_rate,
                    tax_code: editCategoryObj.tax_code
                  })
                }
                style={{ color: 'black' }}
                placeholder="Jackets, Hammers, Staff Favorites, etc."
                ref={register({
                  required: 'This field is required.'
                })}
              />
              {errors.editCategoryDescription}
            </FormGroup>
            <FormGroup>
              <Label style={{ color: `#494747`, fontSize: `12px` }}>Tax Category</Label>
              {/* <Input 
                                id="category-tax-rate" 
                                value={editCategoryObj.tax_rate} 
                                onChange={(e) => 
                                    setEditCategoryObj({
                                        name: editCategoryObj.name, 
                                        category_id: editCategoryObj.category_id, 
                                        description: editCategoryObj.description, 
                                        tax_rate: parseFloat(e.target.value), 
                                        transaction_rate: editCategoryObj.transaction_rate
                                    })} 
                                type="number" 
                                step="0.01" 
                                style={{color: "black"}} 
                                placeholder="Powertools, Books, ect." 
                            /> */}
              <div>
                <Semantic.Dropdown
                  placeholder="Tax Categories"
                  button
                  style={{
                    width: '100%',
                    borderColor: '#000'
                  }}
                  search
                  selection
                  value={editCategoryObj.tax_code}
                  onChange={(e, data) => {
                    setEditCategoryObj({
                      name: editCategoryObj.name,
                      category_id: editCategoryObj.category_id,
                      description: editCategoryObj.description,
                      tax_rate: editCategoryObj.tax_rate,
                      transaction_rate: editCategoryObj.transaction_rate,
                      tax_code: data.value
                    });
                  }}
                  options={taxCodes}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label style={{ color: `#494747`, fontSize: `12px` }}>Transaction Rate</Label>
              <Input
                id="category-tax-rate"
                value={editCategoryObj.transaction_rate}
                onChange={e => {
                  setEditCategoryObj({
                    name: editCategoryObj.name,
                    category_id: editCategoryObj.category_id,
                    description: editCategoryObj.description,
                    tax_rate: editCategoryObj.tax_rate,
                    transaction_rate: parseFloat(e.target.value),
                    tax_code: editCategoryObj.tax_code
                  });
                }}
                type="number"
                step="0.01"
                style={{ color: 'black' }}
                placeholder="0.15"
              />
              <div style={{ display: `flex`, flexDirection: `row-reverse`, margin: `15px 0px` }}>
                <button
                  style={{ backgroundColor: 'orange', color: `#ffffff`, width: `70px`, height: `35px`, borderRadius: `5px`, border: `transparent`, marginLeft: `5px` }}
                  onClick={() => {
                    editCategory();
                  }}
                >
                  Save
                </button>
                <button
                  style={{ backgroundColor: `#344675`, color: `#ffffff`, width: `70px`, height: `35px`, borderRadius: `5px`, border: `transparent` }}
                  onClick={() => {
                    setShowEditCategoryDialog(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </FormGroup>
          </ModalBody>
        )}
      </Modal>
    </>
  );
};
