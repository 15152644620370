import config from '../../config';

export const shipmentHost = config.config.api_shipments.merchant.shipmentHost;
// export const shipmentHost = "http://192.168.1.48:8000"

export const hasuraHost = config.config.api_shipments.merchant.hasuraHost;
// export const hasuraHost = "http://192.168.1.48:8000"

export const getShipmentQuote = shipmentHost + '/shipments/direct/quote';
export const getShipmentQuoteWithRate = shipmentHost + '/shipments/direct/quote_with_rate';

export const createShipment = shipmentHost + '/shipments/direct/create';
export const createShipmentWithRate = shipmentHost + '/shipments/direct/create_with_rate';
export const createScheduledShipment = createShipment + '/schedule';
export const createScheduledShipmentWithRate = createShipmentWithRate + '/schedule';
export const createSavedShipment = createShipment + '/save';

export const createRoutedShipment = shipmentHost + '/shipments/direct/routed/create';
export const createRoutedShipmentWithRate = shipmentHost + '/shipments/direct/routed/create_with_rate';
export const createRoutedScheduledShipment = createRoutedShipment + '/schedule';
export const createRoutedScheduledShipmentWithRate = createRoutedShipmentWithRate + '/schedule';
export const createRoutedSavedShipment = createRoutedShipment + '/save';

export const createMultidropShipment = shipmentHost + '/shipments/direct/multidrop/create';
export const createMultidropShipmentWithRate = shipmentHost + '/shipments/direct/multidrop/create_with_rate';
export const createMultidropScheduledShipment = createMultidropShipment + '/schedule';
export const createMultidropScheduledShipmentWithRate = createMultidropShipmentWithRate + '/schedule';
export const createMultidropSavedShipment = createMultidropShipment + '/save';

export const readyItem = shipmentHost + '/shipments/'; // + {shipment_id}/orders/{order_id}/items/{item_id}/ready
export const declineItem = shipmentHost + '/shipments/'; // + {shipment_id}/orders/{order_id}/items/{item_id}/decline
export const ReadyOrder = shipmentHost + '/shipments/'; // + {shipment_id}/orders/{{order_id}}/ready
