import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import axios from 'axios';
import { useHistory, useParams, Link, Switch as RouterSwitch, Route } from "react-router-dom";
import { Row, Col, Dropdown, DropdownMenu, DropdownToggle, Alert } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';

import { Styles } from './Styles/LandingTableStyles';
import { AddIcon } from '../../assets/svgs/WhiteIcons';
import { PencilIcon, TrashIcon } from '../../assets/svgs/WhiteIcons';
import Switch from 'react-switch';

import Loading from '../Global/Loading';
import AddProductContainer from './AddProduct/AddProductContainer';

import * as inventory_urls from '../../urls/api-inventory/admin';
import { Breadcrumbs, Typography } from '@material-ui/core';


export const Product = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [error, setError] = useState("");
    const [showWarning, setShowWarning] = useState(false);
    const [product, setProduct] = useState("");
    const [index, setIndex] = useState(null);
    const [categoryName, setCategoryName] = useState("");
    const [departmentName, setDepartmentName] = useState("");
    const [storeName, setStoreName] = useState(null);
    const [storeId, setStoreId] = useState(null);
    const [departmentId, setDepartmentId] = useState(null);


    let history = useHistory();
    let params = useParams();

    useEffect(() => {
        getInventory(params.category_id);
    }, [props]);

    const getInventory = (category_id) =>{
        axios({
            method: `post`,
            url: inventory_urls.hasuraHost,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token'), 
                'content-type': 'application/json'
            },
            data: {query: `{Categories(where: {category_id: {_eq: "${params.category_id}"}}) {
                name
                Department {
                    name,
                    department_id
                    Store{
                        store_id,
                        name
                    }
                }
            }Products(where: {category_id: {_eq: "${category_id}"}}, order_by: {name: asc}) {
                image
                name
                price
                product_id
                sku
                upc
                weight
                width
                is_enabled
                quantity_on_hand
                length
                height
                description
                Category {
                    Department {
                      name
                      Store {
                          name
                      }
                    }
                    name
                }
              }
            }`},
        }).then(response => {
            setLoading(false);
            if(response.data.errors) {
                if(response.data.errors.length > 0) {
                  if(response.data.errors[0].message === "Malformed Authorization header" || response.data.errors[0].extensions.code === 'invalid-jwt') {
                    this.props.history.push("/logout");
                  }
                }
              }
            if(response.data.data){
                setProducts(response.data.data.Products);
                setCategoryName(response.data.data.Categories[0].name);
                setDepartmentName(response.data.data.Categories[0].Department.name);
                setDepartmentId(response.data.data.Categories[0].Department.department_id);
                setStoreId(response.data.data.Categories[0].Department.Store.store_id);
                setStoreName(response.data.data.Categories[0].Department.Store.name);
            }
        }).catch(error => {
            setLoading(false);
            setError(error)
            console.log("error: ", error.response);
        })
    }

    const toggleProduct = (prod, index) => {
        var data = {
            is_enabled: !prod.is_enabled
        };
        axios({
            method: "PUT",
            url: inventory_urls.editProduct + prod.product_id,
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token') },
            data: data,
        }).then(response => {
            var newProd = {
                image: response.data.payload.image,
                name: response.data.payload.name,
                description: response.data.payload.description,
                price: Number(response.data.payload.price),
                product_id: response.data.payload.product_id,
                sku: response.data.payload.sku,
                upc: response.data.payload.upc,
                weight: response.data.payload.weight,
                length: response.data.payload.length,
                width: response.data.payload.width,
                height: response.data.payload.height,
                is_enabled: response.data.payload.is_enabled,
                quantity_on_hand: response.data.payload.quantity_on_hand,
                Category: { name: categoryName, Department: {name: departmentName}}
            };
            let prods = products.slice();
            prods[index] = newProd;
            setProducts(prods);
        }).catch(error => {
            console.log("error: ", error.response);
        })
    }

    const deleteProduct = (productId) => {
        axios({
            method: "DELETE",
            url: inventory_urls.deleteProduct + productId,
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }
        }).then(response => {

        }).catch(error => {
            console.log("error: ", error.response);
        })
    }

    const getTrProps = (rowInfo, column, cell) => {
        if (rowInfo) {
          return {
            style: {
              height: `70px`,
              margin: `5px`,
              padding: `0px`,
              background: `transparent`,
            }
          }
        }
        return {};
      }

    const getTheadThProps = () => {
        return {
          style: {
              fontSize: `9px`,
              fontWeight: `600`,
              textAlign: `left`,
              color: `#cdcaca`,
              textTransform: `uppercase`,
            }
        }
    }

    const columns = [
        {
            Header: 'Image',
            accessor: 'image',
            width: 150,
            Cell: props => (
                <div>
                    {/* {props.value ? props.value : "Not Pictured"} */}
                    {props.value ? 
                        <img src={props.value} alt="Product Image" style={{width: "40%"}} />
                        : "Not Pictured"
                    }
                </div>
            )
        },
        {
            Header: 'Product Name',
            accessor: 'name',
            Cell: props => (
                <div>
                    {props.value}
                </div>
            )
        },
        {
            Header: 'Department',
            accessor: 'Category.Department.name',
            Cell: props => (
                <div>
                    {props.value}
                </div>
            )
        },
        {
            Header: 'Category',
            accessor: 'Category.name',
            Cell: props => (
                <div>
                    {props.value}
                </div>
            )
        },
        {
            Header: 'Price',
            accessor: 'price',
            Cell: props => (
                <div>
                    {"$" + props.value} 
                </div>
                // toFixed(2);
            )
        },
        {
            Header: "Current Stock",
            accessor: 'quantity_on_hand',
            Cell: props => (
                <div>
                    {props.value}
                </div>
            )
        },
        {
            Header: 'Available',
            accessor: 'is_enabled',
            width: 100,
            Cell: props => (
                <Switch
                    size={20}
                    checked={props.value}
                    onChange={() => {
                        toggleProduct(props.original, props.index);
                    }}
                    defaultValue={true}
                    onColor="#2bbbae"
                    offColor="#f15a29"
                />
            )
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (props) => (
                <Row>
                    {/* <Col><LocalOfferIcon /></Col> */}
                    <Link to={{
                        pathname: '/admin/inventory/store/' + params.store_id + '/category/' + params.category_id + '/product/' + props.original.product_id + '/edit_product',
                        state: {
                            isEdit: true,
                            product: props.original.product_id
                        }
                    }}>
                    <Col style={{cursor: `pointer`}}>
                        <PencilIcon />
                    </Col>
                    </Link>
                    <Col style={{cursor: `pointer`}} 
                         onClick={() => {
                            setProduct(props.original.product_id);
                            setShowWarning(true);
                        }}>
                            <TrashIcon/>
                    </Col>
                </Row>
            )
        }
    ]

    if (loading) return <Loading />;
    return (
        <RouterSwitch>
            <Route path="/admin/inventory/store/:store_id/category/:category_id/product/add_product" component={AddProductContainer}/>
            <Route path="/admin/inventory/store/:store_id/category/:category_id/product/:product_id/edit_product" component={AddProductContainer}/>
            <Route exact path="" render={(props) => (
            <>
            
                <Styles>
                    <Alert 
                        color="danger" 
                        isOpen={error === "" ? false : true} 
                        toggle={() => setError("")}
                    > 
                        <strong>{error}</strong>
                    </Alert>
                    <div className="header">
                        <div className="routing-title">
                            <Link to="/admin/dashboard" className="store-name">{categoryName}</Link>
                            <h4>Products</h4>
                        </div>
                        <div className="upload-add-container">
                            <Link 
                                to={{
                                    pathname: "/admin/inventory/store/" + params.store_id + "/category/" + params.category_id + "/product/" + "add_product",
                                    state: {
                                        isEdit: false,
                                        product: null
                                    }
                                }}
                            >
                                <button className="product">
                                    <AddIcon />
                                    <p style={{marginLeft: `10px`}}>Add Product</p>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="table-container">
                        {/* <div className="sort-search-container">
                            <label>
                                <input type="search" />
                            </label>
                            <button className="view-all-btn">View All</button>
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle
                                    tag="span"
                                    data-toggle="dropdown"
                                    aria-expanded={dropdownOpen}
                                >
                                    Sort by
                                </DropdownToggle>
                                <DropdownMenu>
                                    <div onClick={toggle}>Custom dropdown item</div>
                                </DropdownMenu>
                            </Dropdown>
                        </div> */}
                    {/* </div> */}
                    {/* Breadcrumbs */}
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link style={{color: '#5e72e4'}} to="/admin/inventory">
                            Stores
                        </Link>
                        <Link style={{color: '#5e72e4'}} to={`/admin/inventory/store/${storeId}`}>
                            {storeName}
                        </Link>
                        <Link style={{color: '#5e72e4'}} to={`/admin/inventory/store/${storeId}/department/${departmentId}`}>
                            {departmentName}
                        </Link>
            
                        <Typography style={{color: '#5e72e4', fontWeight: 'bold'}}>{categoryName}</Typography>
                    </Breadcrumbs>
                    {/* End Breadcrumbs */}
                    <ReactTable
                        data={products}
                        columns={columns}
                        resizable={false}
                        defaultPageSize={5}
                        className="-striped -highlight"
                        getTrProps={getTrProps}
                        getTheadThProps={getTheadThProps}
                    />
                    </div>
                </Styles>
                <div id='warning-alert' hidden={!showWarning}>
                    <ReactBSAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title="Are you sure?"
                        onConfirm={() => {
                            deleteProduct(product);
                            setProduct("");
                            setShowWarning(false);
                        }}
                        onCancel={() => {
                            setProduct("");
                            setShowWarning(false);
                        }}
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="default"
                        confirmBtnText="Confirm"
                        cancelBtnText="Cancel"
                        showCancel
                        btnSize=""
                    >
                        <div>You are about to delete a product from your inventory.</div> 
                        <div>This action cannot be undone.</div>
                    </ReactBSAlert>
                </div>
            </>
            )} />
         </RouterSwitch>
    )
}