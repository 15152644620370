import React from 'react';
import { Card, Collapse } from 'reactstrap';
import { formatTimestamp } from '../../../helpers/formatter';
import PickupDetailsReview from './DirectComponents/PickupDetailsReview';
import MultiDropDetailsReview from './DirectComponents/MultiDropDetailsReview';
import WaypointItemsDescription from './DirectComponents/WaypointItemsDescription';
import ShipmentDescription from './DirectComponents/ShipmentDescription';
import ConfirmButtons from './DirectComponents/ConfirmButtons';
import BillingInfoReview from './DirectComponents/BillingInfoReview';

const ItemizedMultiDropConfirmPage = ({
  showForm3,
  pickupInfo,
  pickupInstruction,
  merchantOrderID,
  vehicleType,
  numPeople,
  scheduledFor,
  isExpedited,
  isScheduled,
  handleBack,
  price,
  handleConfirmItemizedMultiDrop,
  setIsSaved,
  setOrderStatus,
  setShipmentStatus,
  pickupItems,
  multiDropWaypointsArray,
  perItemPrice,
  priceRangeLow,
  priceRangeHigh,
  perStopPrice,
  freeItemCount,
  extraPerItemPrice,
  isInvoice,
  billingInfo
}) => {
  return (
    <Collapse isOpen={showForm3} style={{ backgroundColor: '#27293d' }}>
      <Card style={{ backgroundColor: '#27293d', color: '#fff' }} className="card-form">
        <form className="dropship-container" autoComplete="off" style={{ padding: `100px` }}>
          <div>
            <div style={{ marginBottom: 15, marginTop: 25, fontSize: 15, color: '#f7941d' }}>
              Review your order
              <hr style={{ backgroundColor: '#344675' }} />
            </div>
            {isInvoice && (
              <div>
                <BillingInfoReview billingInfo={billingInfo} />
                <hr style={{ backgroundColor: '#344675' }} />
              </div>
            )}
            <div>
              <PickupDetailsReview pickupInfo={pickupInfo} pickupInstruction={pickupInstruction} />
              <hr style={{ backgroundColor: '#344675' }} />
            </div>

            <div>
              <MultiDropDetailsReview multiDropWaypointsArray={multiDropWaypointsArray} />
            </div>
            <div>
              <div style={{ color: '#fff', fontStyle: 'bold' }}>Shipment Description</div>
              <WaypointItemsDescription pickupItems={pickupItems} />
              <ShipmentDescription
                merchantOrderID={merchantOrderID}
                vehicleType={vehicleType}
                numPeople={numPeople}
                scheduledFor={scheduledFor}
                formatTimestamp={formatTimestamp}
              />
              <div style={{ color: '#fff', fontStyle: 'bold' }}>Price per Item</div>
              <div style={{ color: '#cdcaca', marginLeft: '8px', marginBottom: '5px' }}>${Number(perItemPrice).toFixed(2)}</div>
              <div style={{ color: '#fff', fontStyle: 'bold' }}>Price per Stop</div>
              <div style={{ color: '#cdcaca', marginLeft: '8px', marginBottom: '5px' }}>${Number(perStopPrice).toFixed(2)}</div>
              <div style={{ color: '#fff', fontStyle: 'bold' }}>Free Items Count Threshold per Stop</div>
              <div style={{ color: '#cdcaca', marginLeft: '8px', marginBottom: '5px' }}>{Number(freeItemCount)}</div>
              <div style={{ color: '#fff', fontStyle: 'bold' }}>Extra Price per Item</div>
              <div style={{ color: '#cdcaca', marginLeft: '8px', marginBottom: '5px' }}>${Number(extraPerItemPrice).toFixed(2)}</div>
              <hr style={{ backgroundColor: '#344675' }} />
            </div>
          </div>
          <ConfirmButtons
            isExpedited={isExpedited}
            price={price}
            isScheduled={isScheduled}
            handleBack={handleBack}
            handleConfirm={handleConfirmItemizedMultiDrop}
            setIsSaved={setIsSaved}
            setOrderStatus={setOrderStatus}
            setShipmentStatus={setShipmentStatus}
            vehicleType={vehicleType}
            priceRangeLow={priceRangeLow}
            priceRangeHigh={priceRangeHigh}
          />
        </form>
      </Card>
    </Collapse>
  );
};

export default ItemizedMultiDropConfirmPage;
