import React from 'react';
import { Label, FormGroup, Input, Row, Col } from 'reactstrap';
import DropOffCustomerAutoComplete from './DropOffCustomerAutoComplete';
//! Google Map AutoComplete
import GoogleMapAutoComplete from '../../../Helpers/GoogleMapAutoComplete';
import '../../../Helpers/googleMapAutoComplete.css';
import MapboxAutocomplete from '../../../../assets/react-mapbox-autocomplete/ReactMapboxAutocomplete';
import '../../../../assets/react-mapbox-autocomplete/index.css';
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ29waHItZW5naW5lZXJpbmciLCJhIjoiY2syYzd6NjdrMmo3azNtcGU1cXZzMTh1MSJ9.cHMxwcG_MoQk-l4ZO3bdEA';

const DropoffInfoRouted = ({
  isDropoffStore,
  setIsDropoffStore,
  errors,
  storeFirstName,
  dropFirstname,
  setDropFirstname,
  dropCustomerSuggestions,
  setDropCustomerSuggestions,
  storeId,
  setDropLastname,
  setDropPhone,
  dropFullAddress,
  setDropFullAddress,
  setDropoffInstruction,
  isDropOffAutoFilled,
  setIsDropOffAutoFilled,
  setDropAddress1,
  setDropAddress2,
  setDropCity,
  setDropState,
  setDropZip,
  setDropCountry,
  setDropLat,
  setDropLng,
  pickFirstname,
  isPickupStore,
  setDropoffBusinessName,
  storeLastName,
  dropLastname,
  storePhone,
  dropPhone,
  storeName,
  dropoffBusinessName,
  storeAddress,
  handleDropoffSelect,
  vendorLat,
  vendorLong,
  dropoffInstruction,
  isRoundTrip,
  setIsRoundTrip,
  pickLastname,
  pickPhone,
  pickBusinessName,
  pickFullAddress
}) => {
  return (
    <>
      <Row>
        <FormGroup check className="mt-3" style={{ marginLeft: '15px' }}>
          <Label check>
            <Input
              type="checkbox"
              id="round-trip-check"
              value={isRoundTrip}
              checked={isRoundTrip}
              onClick={e => {
                setIsRoundTrip(!isRoundTrip);
                // handleRoundTrip();
              }}
            />
            <span className="form-check-sign" />
            Round Trip
          </Label>
        </FormGroup>
      </Row>
      <Row style={{ paddingTop: 0 }}>
        {isRoundTrip ? (
          <>
            <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="store-address">
              <Label check style={{ color: '#4c5057' }}>
                <Input
                  required
                  type="radio"
                  checked={isPickupStore}
                  value="md"
                  name="store-address-radios4"
                  id="my-store-address"
                  // onClick={() => {
                  //   setIsDropoffStore(true);
                  // }}
                  disabled
                />
                <span id="radio" className="form-check-sign" /> Store Address
              </Label>
            </FormGroup>
            <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="shipment-weight">
              <Label check style={{ color: '#4c5057' }}>
                <Input
                  required
                  type="radio"
                  checked={!isPickupStore}
                  name="store-address-radios4"
                  value="dd"
                  id="different-address"
                  // onClick={() => {
                  //   setIsDropoffStore(false);
                  // }}
                  disabled
                />
                <span id="radio" className="form-check-sign" /> Different Address
              </Label>
            </FormGroup>{' '}
          </>
        ) : (
          <>
            <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="store-address">
              <Label check>
                <Input
                  required
                  type="radio"
                  checked={isDropoffStore}
                  value="md"
                  name="store-address-radios4"
                  id="my-store-address"
                  onClick={() => {
                    setIsDropoffStore(true);
                  }}
                />
                <span id="radio" className="form-check-sign" /> Store Address
              </Label>
            </FormGroup>
            <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="shipment-weight">
              <Label check>
                <Input
                  required
                  type="radio"
                  checked={!isDropoffStore}
                  name="store-address-radios4"
                  value="dd"
                  id="different-address"
                  onClick={() => {
                    setIsDropoffStore(false);
                  }}
                />
                <span id="radio" className="form-check-sign" /> Different Address
              </Label>
            </FormGroup>
          </>
        )}
      </Row>
      <Row form={true}>
        <Col style={{ textAlign: 'left' }}>
          <Label for="customerFirstName">First Name</Label>
          <FormGroup className={errors.dropoffFirstname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
            <DropOffCustomerAutoComplete
              isDropoffStore={isDropoffStore}
              storeFirstName={storeFirstName}
              dropFirstname={dropFirstname}
              setDropFirstname={setDropFirstname}
              dropCustomerSuggestions={dropCustomerSuggestions}
              setDropCustomerSuggestions={setDropCustomerSuggestions}
              storeId={storeId}
              setDropLastname={setDropLastname}
              setDropPhone={setDropPhone}
              dropFullAddress={dropFullAddress}
              setDropFullAddress={setDropFullAddress}
              setDropoffInstruction={setDropoffInstruction}
              isDropOffAutoFilled={isDropOffAutoFilled}
              setIsDropOffAutoFilled={setIsDropOffAutoFilled}
              setDropAddress1={setDropAddress1}
              setDropAddress2={setDropAddress2}
              setDropCity={setDropCity}
              setDropState={setDropState}
              setDropZip={setDropZip}
              setDropCountry={setDropCountry}
              setDropLat={setDropLat}
              setDropLng={setDropLng}
              isRoundTrip={isRoundTrip}
              pickFirstname={pickFirstname}
              isPickupStore={isPickupStore}
              hasThirdWaypoint={true}
              setDropoffBusinessName={setDropoffBusinessName}
            />
            <div hidden={!errors.dropoffFirstname}>
              <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
            </div>
          </FormGroup>
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <Label for="customerLastName">Last Name</Label>
          <FormGroup className={errors.dropoffLastname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
            <Input
              required
              type="text"
              name="customerLastName"
              id="customer-last-name"
              placeholder="Doe"
              // onChange={e => (isDropoffStore ? null : setDropLastname(e.target.value))}
              // value={isRoundTrip && isPickupStore ? storeLastName : isRoundTrip && !isPickupStore ? pickLastname : isDropoffStore ? storeLastName : dropLastname}
              onChange={e => (isRoundTrip || isDropoffStore ? null : setDropLastname(e.target.value))}
              value={isRoundTrip && isPickupStore ? storeLastName : isRoundTrip && !isPickupStore ? pickLastname : isDropoffStore ? storeLastName : dropLastname}
            />
            <div hidden={!errors.dropoffLastname}>
              <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
            </div>
          </FormGroup>
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <Label for="customerPhone">Phone Number</Label>
          <FormGroup className={errors.dropoffPhone ? 'has-danger' : 'thisIsAPlaceholderClass'}>
            <Input
              required
              type="text"
              name="customerPhone"
              id="customer-phone"
              placeholder="555-555-5555"
              onChange={e => (isDropoffStore ? null : setDropPhone(e.target.value))}
              value={isRoundTrip && isPickupStore ? storePhone : isRoundTrip && !isPickupStore ? pickPhone : isDropoffStore ? storePhone : dropPhone}
            />
            <div hidden={!errors.dropoffPhone}>
              <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid phone number (10-13 digits)</p>
            </div>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="order-dropoff">Business Name (optional)</Label>
            <Input
              type="text"
              name="dropoffBusinessName"
              id="dropoff-business-name"
              placeholder="Store Name"
              onChange={e => (isDropoffStore ? null : setDropoffBusinessName(e.target.value))}
              value={isRoundTrip && isPickupStore ? storeName : isRoundTrip && !isPickupStore ? pickBusinessName : isDropoffStore ? storeName : dropoffBusinessName}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>Delivery Address</Label>
            {isRoundTrip ? (
              <Input value={isPickupStore ? storeAddress : pickFullAddress} />
            ) : isDropOffAutoFilled ? (
              <Input value={isDropoffStore ? storeAddress : dropFullAddress} onChange={e => (isDropoffStore ? null : setDropFullAddress(e.target.value))} />
            ) : (
              // <MapboxAutocomplete
              //   id="mapbox-autocomplete3"
              //   inputClass="form-control mb-2"
              //   publicKey={MAPBOX_TOKEN}
              //   onSuggestionSelect={handleDropoffSelect}
              //   country="us"
              //   resetSearch={false}
              //   readOnly={isDropoffStore}
              //   defaultValue={isDropoffStore ? storeAddress : null}
              //   value={isDropoffStore ? storeAddress : dropFullAddress}
              //   query={dropFullAddress}
              //   placeholder="Street Address"
              //   proximityLat={vendorLat}
              //   proximityLng={vendorLong}
              // />
              <GoogleMapAutoComplete
                inputClass="form-control mb-2"
                onSuggestionSelect={handleDropoffSelect}
                country="us"
                resetSearch={false}
                readOnly={isDropoffStore}
                defaultValue={isDropoffStore ? storeAddress : null}
                value={isDropoffStore ? storeAddress : dropFullAddress}
                query={dropFullAddress}
                placeholder="Street Address"
                proximityLat={vendorLat}
                proximityLng={vendorLong}
              />
            )}
            {/* <div hidden={errorMessageDistance.errorCode !== 1}>
              <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageDistance.errorMessage}</p>
            </div> */}
            <div hidden={!errors.dropoffAddress}>
              <p style={{ color: 'red', marginTop: '4px' }}>Please choose an address from the dropdown menu</p>
            </div>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="order-dropoff">Dropoff Instructions (optional)</Label>
            <Input
              type="text"
              name="dropoff"
              id="dropoff-instructions"
              placeholder="Gate Code, Apt. #, etc."
              value={dropoffInstruction}
              onChange={e => {
                if (e.target.value.length < 510) {
                  return setDropoffInstruction(e.target.value);
                } else {
                  return;
                }
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default DropoffInfoRouted;
