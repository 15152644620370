import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';

const DirectTypeSelections = ({ selectedType, setSelectedType }) => {
  const options = ['regular', 'routed', 'multidrop', 'itemized-multidrop'];

  return (
    <div>
      <UncontrolledDropdown>
        {selectedType ? (
          <DropdownToggle caret id="driver-dropdown">
            {selectedType} ✅
          </DropdownToggle>
        ) : (
          <DropdownToggle caret id="driver-dropdown" style={{ color: 'red' }}>
            Select a direct type
          </DropdownToggle>
        )}

        <DropdownMenu>
          {/* <Input type="text" value={storeName} onChange={filterStore} style={{ color: 'black', alignSelf: 'left' }} placeholder="Store Name" id="store-filter" /> */}
          {options.map((option, index) => {
            return (
              <DropdownItem key={index} onClick={() => setSelectedType(option)}>
                <p style={{ color: 'black', alignSelf: 'left' }}>{option}</p>
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default DirectTypeSelections;
