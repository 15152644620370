/*!
* Black Dashboard PRO React - v1.0.0
* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Coded by Creative Tim
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import classnames from "classnames";
import { FormGroup, 
  UncontrolledAlert,
  Input, 
  Row, 
  Col, 
  Button,
  InputGroupAddon,
  InputGroupText,
  InputGroup, } from "reactstrap";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emergency_contacts: [],
      contact_name: "",
      contact_phone: "",
      contact_nameState: "",
      contact_phoneState: "",
      error: ""
    };

    this.handleContactChangeName = this.handleContactChangeName.bind(this);
    this.handleContactChangePhone = this.handleContactChangePhone.bind(this);
    this.handleAddContact = this.handleAddContact.bind(this);
  }

  isValidated = () => {
    if(this.state.emergency_contacts.length !== 0) {
      this.state.emergency_contacts.forEach(contact => {
          if(contact.name === "") {
            this.setState({error: "One or more fields are empty"});
            return false;
          }
          if(contact.phone === "") {
            this.setState({error: "One or more fields are empty"});
            return false;
          }
      });
      return true;
    }
    this.setState({error: "An emergency contact is required"});
    return false;
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  verifyPhone = value => {
    var phoneRex = /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{10}))/;
    if(phoneRex.test(value)) {
      if(value.replace(/[^0-9]/g,"").length > 9 && value.replace(/[^0-9]/g,"").length < 14){
        return true;
      }
    }
    return false;
  };

  handleContactChangeName = index => event => {
    const newContacts = this.state.emergency_contacts.map((value, detIndex) => {
        if (index !== detIndex) return value;
        return { ...value, name: event.target.value}
    });
    this.setState({emergency_contacts: newContacts})
  }

  handleContactDelete = (index) => {
    var contact_mod = [...this.state.emergency_contacts]
    contact_mod.splice(index, 1)
    this.setState({emergency_contacts: contact_mod})
    
}

  handleContactChangePhone = index => event => {
    const newContacts = this.state.emergency_contacts.map((value, detIndex) => {
        if (index !== detIndex) return value;
        return { ...value, phone: event.target.value}
    });
    this.setState({emergency_contacts: newContacts})
  }

  handleAddContact = (event) => {
    if(this.state.contact_name !== "" && this.state.contact_phone !== "") {
        this.setState({emergency_contacts: this.state.emergency_contacts.concat([{name: this.state.contact_name, phone: this.state.contact_phone.replace(/\D/g,'')}]), contact_name: "", contact_phone: ""})
    }
}

  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "phone": 
        if(this.verifyPhone(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };

  render() {
    return (
      <>
        {this.state.error !== "" ? (
          <UncontrolledAlert color="warning" fade={false}>
            <span>
              <b>{this.state.error}</b>
            </span>
          </UncontrolledAlert>
        ) : null}
        <h4 className="info-text">Emergency Contact Information</h4>
        <form autoComplete="off">
          <input autoComplete="off" hidden="true"></input>
          {this.state.emergency_contacts.map((value, index) => {
            return (
              <Row className="justify-content-center">
                <Col sm="4">
                  <FormGroup>
                    <label>Contact Name</label>
                    <Input onChange={this.handleContactChangeName(index)} value={value.name} type="text" />
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <label>Phone</label>
                    <Input onChange={this.handleContactChangePhone(index)} value={value.phone} type="tel" />
                  </FormGroup>
                </Col>
                <Col sm="1">
                  <Button style={{marginTop: 30}} size="sm" className="btn btn-danger" onClick={() => this.handleContactDelete(index)}>
                    -
                  </Button>
                </Col>
              </Row>
            ) 
          })}
          <Row className="justify-content-center">
            <Col sm="4">
              <label>Contact Name</label>
              <InputGroup
                className={classnames(this.state.contact_nameState, {
                  "input-group-focus": this.state.contact_nameFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="contact_name" value={this.state.contact_name} type="text" 
                  onChange={e => this.change(e, "contact_name", "length", 1)}
                  onFocus={e => this.setState({ contact_nameFocus: true })}
                  onBlur={e => this.setState({ contact_nameFocus: false })}
                />
                
                {this.state.contact_nameState === "has-danger" ? (
                  <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>
            </Col>
            <Col sm="3">
                <label>Phone</label>
                <InputGroup
                  className={classnames(this.state.contact_phoneState, {
                    "input-group-focus": this.state.contact_phoneFocus
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="tim-icons icon-mobile" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="contact_phone" value={this.state.contact_phone} type="tel" 
                    placeholder="Ex: 1234567890"
                    onChange={e => this.change(e, "contact_phone", "phone")}
                    onFocus={e => this.setState({ contact_phoneFocus: true })}
                    onBlur={e => this.setState({ contact_phoneFocus: false })}
                  />
                  {this.state.contact_nameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
            </Col>
            <Col sm="1">
              <Button style={{marginTop: 30}} size="sm" className="btn btn-success" onClick={this.handleAddContact}>
                +
              </Button>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

export default Wizard;
