import React from 'react';

const BillingInfoReview = ({ billingInfo }) => {
  let name = null;
  if (billingInfo.first_name && billingInfo.last_name) {
    name = billingInfo.first_name + ' ' + billingInfo.last_name;
  }
  return (
    <>
      <div style={{ color: '#fff', fontStyle: 'bold' }}>Invoice Recipient</div>
      <div style={{ color: '#cdcaca' }}>{name}</div>
      <div style={{ color: '#cdcaca' }}>{billingInfo.phone ? billingInfo.phone : null}</div>
      <div style={{ color: '#cdcaca' }}>{billingInfo.email ? billingInfo.email : null}</div>
    </>
  );
};

export default BillingInfoReview;
