import React, { useState, useEffect } from 'react';
import * as inventory_urls from '../../../urls/api-inventory/admin'
import axios from 'axios';
import Loading from '../../Global/Loading';
import { Dropdown } from 'semantic-ui-react';
import { 
    FormGroup, 
    Input, 
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    UncontrolledTooltip
} from 'reactstrap';
import { Styles } from './Styles/PriceStyles';
import 'semantic-ui-css/semantic.min.css';

const Price = (props) => {
    const [price, setPrice] = useState(null);
    const [tax, setTax] = useState(null);
    const [taxName, setTaxName] = useState(null);
    const [taxCodes, setTaxCodes] = useState([]);
    const [isTaxed, setIsTaxed] = useState(false);
    const [msrp, setMsrp] = useState(null);
    const [transactionRate, setTransactionRate] = useState(null);
    const [promotionalRate, setPromotionalRate] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        props.setPrice(isTaxed, price, tax, msrp, transactionRate, promotionalRate);
    })

    // Get Tax Codes
    useEffect(() => {
        axios({
            method: 'GET',
            url: inventory_urls.getTaxCodes,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        }).then(response => {
            // Set taxCodes here:
            let newTaxCodes = [];
            response.data.payload.map((element, key) => {
                newTaxCodes.push({
                    key: key,
                    text: element.name,
                    value: element.product_tax_code
                })
            })
            setTaxCodes(newTaxCodes);
            setLoading(false)
        }).catch(error => {
            console.log("error: ", error.response);
        })
    }, [])

    // useEffect(() => {
    // }, [tax])

    useEffect(() => {
        if (props.product) {
            setPrice(props.product.price);
            setTransactionRate(props.product.transaction_rate * 100);
            if (props.product.tax_rate) {
                setIsTaxed(true);
                setTax(props.product.tax_rate * 100);
            }
            if (props.product.msrp) {
                setMsrp(props.product.msrp);
            }
            if (props.product.promotional_rate) {
                setPromotionalRate(props.product.promotional_rate * 100);
            }
        }
    }, [props.product])

    useEffect(() => {
        if (!isTaxed) {
            setTax(null);
        }
    }, [isTaxed])

    // const handleTaxChange = (e) => )
    
    if (loading) return <Loading />
    return (
        <Styles className="price-styles-container">
            <FormGroup className="price-container">
                <h4>Price</h4>
                <hr style={{borderColor: '#344675', borderStyle: 'solid'}}></hr>
                <div className="input-container">
                    <div className="price-label-input">
                        <FormGroup>
                            <Label>Price*</Label>
                            <InputGroup className="price-group">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>$</InputGroupText>
                                </InputGroupAddon>
                                <Input 
                                    type="text" 
                                    className="price"
                                    name="price"
                                    placeholder="Product Price" 
                                    onChange={e => {
                                            setPrice(e.target.value);
                                            props.change(e, "price", 1);
                                        }
                                    } 
                                    value={price}
                                />
                            </InputGroup>
                        </FormGroup>
                        {props.priceState === "has-danger" ? (
                        <label className="error" style={{color: '#ff0000'}}>
                            This field is required
                        </label>
                    ) : null}
                    </div>
                    { props.role[0] < 200 ? 
                    <>
                        <div className="checkbox-input">
                            <FormGroup check>
                                <Label check>
                                    <Input 
                                        type="checkbox" 
                                        onChange={e => {
                                            setIsTaxed(e.target.checked);
                                            // props.change(e, "tax", 1);
                                        }}
                                        checked={isTaxed}
                                    />
                                    {' '}
                                    Charge sales tax?{'       '}
                                    <span className="form-check-sign" id="tax-box"></span>
                                    <span id="tax-box">
                                        <i className="tim-icons icon-alert-circle-exc"/>
                                    </span>
                                    <UncontrolledTooltip placement="right" target="tax-box">
                                        If left unchecked, the product's category will determine its tax rate.
                                    </UncontrolledTooltip>
                                </Label>
                            </FormGroup>
                        </div>
                        <div hidden={!isTaxed}>
                            <FormGroup className="price-label-input">
                                <Label>Tax Rate*</Label>
                                <InputGroup className="price-group">
                                {/* <Input 
                                    type="text" 
                                    className="tax"
                                    name="tax"
                                    value={(tax === null) ? "" : tax} 
                                    placeholder="Product Tax" 
                                    onChange={e => {
                                        setTax(e.target.value)
                                        props.change(e, "tax", 1);
                                    }}
                                /> */}
                                    {/* <InputGroupAddon addonType="append">
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroupAddon> */}
                                    <Dropdown
                                        placeholder='Tax Categories'
                                        className='icon'
                                        button
                                        style={{
                                            // width: '90%',
                                            backgroundColor: '#27293d',
                                            borderColor: '#344675',
                                            color: '#efeeee'
                                        }}
                                        icon='gavel'
                                        search
                                        selection
                                        onChange={(e, data) => {
                                            setTax(data.value);
                                        }}
                                        labeled
                                        options={taxCodes}
                                    />
                                </InputGroup>
                            </FormGroup>
                            {props.taxState === "has-danger" && props.isTaxed ? (
                                <label className="error" style={{color: '#ff0000'}}>
                                    This field is required
                                </label>
                            ) : null}
                        </div>
                        </>
                        : null }
                    <div className="price-label-input" >
                        <FormGroup>
                            <Label>MSRP</Label>
                            <InputGroup className="price-group">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>$</InputGroupText>
                                </InputGroupAddon>
                                <Input 
                                    type="text" 
                                    className="price" 
                                    value={(msrp === null) ? "" : msrp} 
                                    placeholder="MSRP" 
                                    onChange={e => setMsrp(e.target.value)} 
                                />
                            </InputGroup>
                        </FormGroup>
                    </div>
                    {props.role[0] < 200 ? 
                    <div className="price-label-input" >
                        <FormGroup>
                            <Label>Transactional Rate</Label>
                            <InputGroup className="price-group">
                            <Input 
                                type="text" 
                                className="price" 
                                value={(transactionRate === null) ? "" : transactionRate} placeholder="Transactional Rate" 
                                onChange={e => setTransactionRate(e.target.value)} 
                            />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>%</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </div>
                    : null }
                    <div className="price-label-input" >
                        <FormGroup>
                            <Label>Promotional Rate</Label>
                            <InputGroup className="price-group">
                            <Input 
                                type="text" 
                                className="price" 
                                value={(promotionalRate === null) ? "" : promotionalRate} placeholder="Promotional Rate" 
                                onChange={e => setPromotionalRate(e.target.value)} 
                            />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>%</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
            </FormGroup>
         </Styles>
    )
}

export default Price;