import React, { useState } from 'react';
import { Button, Row, Col } from 'reactstrap';

const ConfirmButtons = ({
  isExpedited,
  price,
  isScheduled,
  handleBack,
  handleConfirm,
  setIsSaved,
  setOrderStatus,
  setShipmentStatus,
  vehicleType,
  priceRangeLow,
  priceRangeHigh
}) => {
  const [isOrderSubmitDisabled, setIsOrderSubmitDisabled] = useState(false);
  return (
    <>
      <Row style={{ textAlign: 'right' }}>
        <Col className="col-10">
          <div style={{ color: '#fff', fontStyle: 'bold' }}>Shipping Type:</div>
          <div style={{ color: '#fff', fontStyle: 'bold' }}>Total Shipping Cost:</div>
        </Col>
        <Col className="col-2">
          <div style={{ color: '#fff' }}>{isExpedited ? 'Expedited' : 'Standard'}</div>
          {vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler' ? (
            priceRangeLow && (
              <div style={{ color: '#f7941d', fontStyle: 'bold' }}>
                {' '}
                Estimate ${parseFloat(priceRangeLow).toFixed(2)} -- {parseFloat(priceRangeHigh).toFixed(2)}
              </div>
            )
          ) : (
            <div style={{ color: '#f7941d', fontStyle: 'bold' }}>{price ? '$' + price.toFixed(2) : null}</div>
          )}
        </Col>
      </Row>
      {isScheduled ? (
        <Row style={{ marginTop: 20 }}>
          <Col style={{ textAlign: 'left' }}>
            <div>
              <Button className="btn" color="default" style={{ paddingLeft: 57, paddingRight: 57 }} onClick={() => handleBack()}>
                Go Back
              </Button>
            </div>
            {/* <div>
              <Button className="btn cancel-btn" color="default" onClick={reset}>
                Cancel Order
              </Button>
            </div> */}
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <div>
              <Button hidden={true}>Placeholder</Button>
            </div>
            <div>
              <Button
                className="btn submit-btn"
                color="danger"
                onClick={e => {
                  setIsOrderSubmitDisabled(true);
                  handleConfirm('shipment_scheduled', 'order_scheduled', isScheduled);
                  setTimeout(() => {
                    setIsOrderSubmitDisabled(false);
                  }, 3000);
                }}
                disabled={isOrderSubmitDisabled}
              >
                {vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler' ? 'Get Quote and Schedule' : 'Schedule This Order'}
              </Button>
            </div>
            {vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler' ? null : (
              <div>
                <Button
                  className="btn cancel-btn"
                  color="default"
                  onClick={e => {
                    setIsSaved(true);
                    setOrderStatus('order_saved');
                    setShipmentStatus('shipment_saved');
                    setIsOrderSubmitDisabled(true);
                    handleConfirm('shipment_saved', 'order_saved');
                    setTimeout(() => {
                      setIsOrderSubmitDisabled(false);
                    }, 3000);
                  }}
                  disabled={isOrderSubmitDisabled}
                  style={{ paddingLeft: 52, paddingRight: 52 }}
                >
                  Save for Later
                </Button>
              </div>
            )}
          </Col>
        </Row>
      ) : (
        <Row style={{ marginTop: 20 }}>
          <Col style={{ textAlign: 'left' }}>
            <div>
              <Button className="btn" color="default" style={{ paddingLeft: 57, paddingRight: 57 }} onClick={() => handleBack()}>
                Go Back
              </Button>
            </div>
            {/* <div>
              <Button className="btn cancel-btn" color="default" onClick={reset}>
                Cancel Order
              </Button>
            </div> */}
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <div>
              <Button
                className="btn submit-btn"
                color="danger"
                onClick={e => {
                  setIsOrderSubmitDisabled(true);
                  handleConfirm('shipment_ready_for_driver', 'awaiting_driver', isScheduled);
                  setTimeout(() => {
                    setIsOrderSubmitDisabled(false);
                  }, 3000);
                }}
                disabled={isOrderSubmitDisabled}
              >
                {vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler' ? 'Get Quote' : 'Ready for Pick Up'}
              </Button>
            </div>
            {vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler' ? null : (
              <div>
                <Button
                  className="btn cancel-btn"
                  color="default"
                  onClick={e => {
                    setIsSaved(true);
                    setOrderStatus('order_saved');
                    setShipmentStatus('shipment_saved');
                    setIsOrderSubmitDisabled(true);
                    handleConfirm('shipment_saved', 'order_saved', isScheduled);
                    setTimeout(() => {
                      setIsOrderSubmitDisabled(false);
                    }, 3000);
                  }}
                  disabled={isOrderSubmitDisabled}
                  style={{ paddingLeft: 52, paddingRight: 52 }}
                >
                  Save for Later
                </Button>
              </div>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default ConfirmButtons;
