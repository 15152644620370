import styled from 'styled-components';

export const Styles = styled.div`
    width: 100%;
    height: 135px;
    background-color: #27293d;
    padding: 10px 10px;
    border-radius: 5px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
        .header-title {
            h5 {
                font-size: 20px;
                color: #feffff;
            }
        }
        .buttons-container {
                .button-positioning {
                        .save {
                            background-image: linear-gradient(to left, #f15a29, #f7941d);
                            border-radius: 5px;
                            border-color: transparent;
                            color: #efeeee;
                            font-size: 15px;
                            font-weight: 600;
                            width: 200px;
                            height: 50px;
                            margin-left: 2px;
                        }
                        .cancel {
                            background-color: #344675;
                            border-radius: 5px;
                            border-color: transparent;
                            color: #efeeee;
                            font-size: 15px;
                            font-weight: 600;
                            width: 200px;
                            height: 50px;
                            margin-right: 2px;
                        }
                }
        }
`;