import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie9';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AppProvider } from './context';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/css/nucleo-icons.css';
import 'assets/scss/black-dashboard-pro-react.scss?v=1.0.0';
import 'react-notification-alert/dist/animate.css';

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <BrowserRouter basename={'/#/'}>
        <App />
      </BrowserRouter>
    </AppProvider>
  </React.StrictMode>,

  document.getElementById('root')
);
