import React from 'react';

const ShipmentDescription = ({ merchantOrderID, vehicleType, numPeople, scheduledFor, formatTimestamp }) => {
  return (
    <>
      <div hidden={!merchantOrderID} style={{ color: '#fff', fontStyle: 'bold' }}>
        Store Order ID
      </div>
      <div hidden={!merchantOrderID} style={{ color: '#cdcaca', marginLeft: '8px', marginBottom: '5px' }}>
        {merchantOrderID}
      </div>
      <div style={{ color: '#fff', fontStyle: 'bold' }}>Appropriate Vehicle Type</div>
      <div style={{ color: '#cdcaca', marginLeft: '8px', marginBottom: '5px' }}>{vehicleType}</div>

      <div style={{ color: '#fff', fontStyle: 'bold' }}>Number of Gophrs Needed</div>
      <div style={{ color: '#cdcaca', marginLeft: '8px', marginBottom: '5px' }}>{numPeople}</div>
      {scheduledFor ? (
        <div>
          <div style={{ color: '#fff', fontStyle: 'bold' }}>Scheduled For</div>
          <div style={{ color: '#cdcaca' }}>{formatTimestamp(scheduledFor)}</div>
        </div>
      ) : null}
    </>
  );
};

export default ShipmentDescription;
