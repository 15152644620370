import styled from 'styled-components';

export const Styles = styled.div`
    height: 580px;
    width: 50%;
    background-color: #27293d;
    border-radius: 5px;
    border: transparent;
    padding: 10px 10px;
    margin-left: 2%;
        h5 {
            font-size: 15px;
            color: #f7941d;
            font-weight: 600;
        }
        .add-button {
            height: 30px;
            width: 30px;
            background-color: #5e72e4;
            font-size: 20px;
            color: #efeeee;
            border-radius: 5px;
            border: transparent;
            font-weight: 500;
        }
        .dropdown-item {
            display: block;
            width: 100%;
            padding: 0.25rem 1.5rem;
            clear: both;
            font-weight: 400;
            font-size: 12px;
            color: #efeeee;
            text-align: inherit;
            white-space: nowrap;
            background-color: #424d90;
            border: 0;
        }
        .special-hours-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .dropdown-button-combo {
            display: flex;
            flex-direction: row;
        }
        .special-hours-column-header {
            display: grid;
            grid-template-columns: 1fr 1fr 0.5fr 1fr 1fr;
            grid-template-rows: 14.28%;
            grid-row-gap: 10px;
            grid-column-gap: 10px;
        }
        .special-hours-container {
            display: grid;
            grid-template-columns: 1fr 1fr 0.5fr 1fr 1fr;
            grid-template-rows: 14.28%;
            grid-row-gap: 10px;
            grid-column-gap: 10px;
            justify-items: start;
            align-items: start;
            color: #efeeee;
                p {
                    margin: 0 auto;
                }
        }
`;

export const StyledHr = styled.hr`
    background-color: #344675;
    width: auto;
    margin-top: 0;
    padding-top: 0;
    padding-right: 13px;
    padding-left: 13px;
    margin-bottom: 20px;
`;