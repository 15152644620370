import React, { useState, useEffect } from 'react';
import { Label, FormGroup, Input, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import AddButton from '../../assets/img/Group_1383@2x.png';
import DeleteButton from '../../assets/svgs/TrashSimple.svg';

// todo: handle errors
const LineItemsMultipleDrop = ({ title, dropWaypointsArray, setDropWaypointsArray, index, pickList }) => {
  const [showSuggestions, setShowSuggestions] = useState(new Array(dropWaypointsArray[index].dropItems.length).fill(false));

  const [wholePickList, setWholePickList] = useState([]);

  useEffect(() => {
    if (pickList.length > 0) {
      setWholePickList(pickList.map(pl => pl.detail));
    }
  }, [pickList]);

  const selectItem = (e, indexItem, indexAutofill) => {
    setDropWaypointsArray(
      dropWaypointsArray.map((ar, i) =>
        i === index
          ? {
              ...dropWaypointsArray[index],
              dropItems: dropWaypointsArray[index].dropItems.map((dp, j) => (j === indexItem ? { quantity: dp.quantity, detail: wholePickList[indexAutofill] } : dp))
            }
          : ar
      )
    );
    setShowSuggestions(showSuggestions.map((suggestion, i) => (i === indexItem ? false : suggestion)));
  };

  return (
    <>
      <Row form={true} style={{ paddingTop: 0 }}>
        <Col style={{ textAlign: 'left' }}>
          <Label for="LineItems">{title}</Label>
        </Col>
      </Row>
      {dropWaypointsArray[index].dropItems.length > 0 &&
        dropWaypointsArray[index].dropItems.map((item, indexItem) => {
          return (
            <>
              <Row id={'lineItem-' + indexItem}>
                <Col sm="1" style={{ textAlign: 'center' }}>
                  <Label for="LineItems" style={{ marginTop: '8px' }}>
                    Quantity
                  </Label>
                </Col>
                <Col sm="2">
                  <FormGroup style={{ width: '90%' }}>
                    <Input
                      placeholder="1"
                      onChange={e => {
                        setDropWaypointsArray(
                          dropWaypointsArray.map((ar, i) =>
                            i === index
                              ? {
                                  ...dropWaypointsArray[index],
                                  dropItems: dropWaypointsArray[index].dropItems.map((dp, j) => (j === indexItem ? { quantity: Number(e.target.value), detail: dp.detail } : dp))
                                }
                              : ar
                          )
                        );
                      }}
                      type="number"
                      min="1"
                      style={{ WebkitAppearance: 'none' }}
                      value={item.quantity}
                    />
                  </FormGroup>
                </Col>
                <Col sm="1" style={{ textAlign: 'left' }}>
                  <Label for="pickupItems" style={{ marginTop: '8px' }}>
                    Item Detail
                  </Label>
                </Col>
                <Col sm="6">
                  <FormGroup style={{ width: '100%' }}>
                    <ListGroup>
                      <Input
                        placeholder="ie. name, description..., etc"
                        onChange={e => {
                          setDropWaypointsArray(
                            dropWaypointsArray.map((ar, i) =>
                              i === index
                                ? {
                                    ...dropWaypointsArray[index],
                                    dropItems: dropWaypointsArray[index].dropItems.map((dp, j) => (j === indexItem ? { quantity: dp.quantity, detail: e.target.value } : dp))
                                  }
                                : ar
                            )
                          );
                        }}
                        type="text"
                        style={{ WebkitAppearance: 'none' }}
                        value={item.detail}
                        onClick={e => {
                          if (wholePickList.length > 0) {
                            setShowSuggestions(showSuggestions.map((suggestion, i) => (i === indexItem ? true : false)));
                          } else {
                            setShowSuggestions(new Array(dropWaypointsArray[index].dropItems.length).fill(false));
                          }
                        }}
                      />
                      {title === 'Drop off Item(s)' && showSuggestions[indexItem]
                        ? wholePickList.map((pick, indexAutofill) => {
                            return (
                              <ListGroupItem
                                style={{ backgroundColor: '#1e1e2b', border: 'none', color: '#cbcac9' }}
                                key={indexAutofill}
                                onMouseOver={e => (e.target.style.background = 'linear-gradient(90deg, hsla(218, 100%, 42%, 0.6) 0%, hsla(0, 0%, 98%, 1) 200%)')}
                                onMouseLeave={e => (e.target.style.background = '#1e1e2b')}
                                onClick={e => selectItem(e, indexItem, indexAutofill)}
                              >
                                {pick}
                              </ListGroupItem>
                            );
                          })
                        : null}
                    </ListGroup>
                  </FormGroup>

                  <div hidden={!dropWaypointsArray[index].dropItemsError[indexItem]}>
                    <p style={{ color: 'red', marginTop: '4px', marginBottom: '10px' }}>Detail field is required </p>
                  </div>
                </Col>

                <Col sm="1">
                  {/* <div style={{ opacity: 0 }}>Q</div> */}
                  <div style={{ textAlign: 'right', verticalAlign: 'middle', marginTop: '6px' }}>
                    <img
                      alt="add"
                      style={{ cursor: 'pointer', width: 25, height: 25, verticalAlign: 'middle' }}
                      src={AddButton}
                      onClick={() => {
                        // setItemsErrorArray([...itemsErrorArray, false]);
                        setDropWaypointsArray(
                          dropWaypointsArray.map((ar, i) =>
                            i === index
                              ? {
                                  ...dropWaypointsArray[index],
                                  dropItems: [...dropWaypointsArray[index].dropItems, { quantity: 1, detail: '' }],
                                  dropItemsError: [...dropWaypointsArray[index].dropItemsError, false]
                                }
                              : ar
                          )
                        );
                        setShowSuggestions([...showSuggestions, false]);
                      }}
                    />
                  </div>
                </Col>
                <Col sm="1">
                  {/* <div style={{ opacity: 0 }}>Q</div> */}
                  <div style={{ textAlign: 'left', verticalAlign: 'middle', marginTop: '9px' }}>
                    {dropWaypointsArray[index].dropItems.length > 1 && (
                      <img
                        alt="delete"
                        style={{ cursor: 'pointer', width: 20, height: 20, verticalAlign: 'middle' }}
                        src={DeleteButton}
                        onClick={() => {
                          setDropWaypointsArray(
                            dropWaypointsArray.map((ar, i) =>
                              i === index
                                ? {
                                    ...dropWaypointsArray[index],
                                    dropItems: dropWaypointsArray[index].dropItems.filter((dp, j) => {
                                      return j !== indexItem;
                                    }),
                                    dropItemsError: dropWaypointsArray[index].dropItemsError.filter((de, j) => {
                                      return j !== indexItem;
                                    })
                                  }
                                : ar
                            )
                          );
                          //   setItemsErrorArray(
                          //     itemsErrorArray.filter((item, i) => {
                          //       return i !== index;
                          //     })
                          //   );
                          setShowSuggestions(
                            showSuggestions.filter((suggestion, i) => {
                              return i !== indexItem;
                            })
                          );
                        }}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </>
          );
        })}
    </>
  );
};

export default LineItemsMultipleDrop;
