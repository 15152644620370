/*!
 * Black Dashboard PRO React - v1.0.0
 * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import classnames from 'classnames';
// import { Input, InputGroupAddon, InputGroupText, InputGroup, Row, FormGroup, Col } from 'reactstrap';
import StoreHours from '../StoreHours';
// import Agreement from './MSA';

const Wizard = React.forwardRef((props, ref) => {
  // const packageInfo = [
  //   { name: 'Essentials', revenue_share: 'N/A', feature: 'Prepaid Delivery Only', month_fee: 0 },
  //   { name: 'Gophr Basic', revenue_share: 'N/A', feature: 'Delivery with Flexible Terms', month_fee: 9.99 },
  //   {
  //     name: 'Gophr PRO',
  //     revenue_share: `15% + processing fees`,
  //     feature: 'Delivery + Marketplace',
  //     month_fee: 29.99
  //   }
  // ];
  const packageInfo = [
    { name: 'ESSENTIALS', seats_number: '1', feature: 'Pre-Pay Delivery', month_fee: '$0' },
    { name: 'GOPHR BASIC', seats_number: '2($4.99/after)', feature: 'Delivery + POD + Proxy + Marketplace + Tracking', month_fee: '$9.99' },
    {
      name: 'GOPHR PRO',
      seats_number: '5',
      feature: 'Basic + Analytics + Net Terms',
      month_fee: '$29.99'
    },
    {
      name: 'WHITE GLOVE',
      seats_number: 'As needed',
      feature: 'Anything',
      month_fee: 'Quoted'
    }
  ];
  const [storeHours, setStoreHours] = useState([]);
  const [packageSelected, setPackageSelected] = useState('essential');
  // const [showPDF, setShowPDF] = useState(false);

  // const LoadPDFButton = ({ onClick }) => {
  //   return <button onClick={onClick}>Load PDF</button>;
  // };

  // const handleLoadPDFClick = () => {
  //   setShowPDF(true);
  // };

  // const [institutionName, setInstitutionName] = useState('');
  // const [institutionAddress, setInstitutionAddress] = useState('');
  // const [institutionPhone, setInstitutionPhone] = useState('');

  // const [institutionNameState, setInstitutionNameState] = useState('');
  // const [institutionAddressState, setInstitutionAddressState] = useState('');
  // const [institutionPhoneState, setInstitutionPhoneState] = useState('');

  // componentDidMount() {
  //   this.fetchMarkets();
  // }

  // componentDidUpdate() {
  //   navigator.geolocation.getCurrentPosition(position => {
  //     this.setState({
  //       currentLat: position.coords.latitude.toString(),
  //       currentLng: position.coords.longitude.toString()
  //     })
  //   }, error => {
  //     console.log('error', error);
  //   })
  // }
  // const [institutionNameFocus, setInstitutionNameFocus] = useState(false);
  // const [institutionAddressFocus, setInstitutionAddressFocus] = useState('');
  // const [institutionPhoneFocus, setInstitutionPhoneFocus] = useState('');

  // const [tradeReferences, setTradeReferences] = useState([
  //   { companyName: '', contactName: '', address: '', phone: '', accountOpenedSince: '', creditLimit: '', currentBalance: '' },
  //   { companyName: '', contactName: '', address: '', phone: '', accountOpenedSince: '', creditLimit: '', currentBalance: '' },
  //   { companyName: '', contactName: '', address: '', phone: '', accountOpenedSince: '', creditLimit: '', currentBalance: '' }
  // ]);

  // const [tradeReferencesState, setTradeReferencesState] = useState([
  //   { companyName: '', contactName: '', address: '', phone: '', accountOpenedSince: '', creditLimit: '', currentBalance: '' },
  //   { companyName: '', contactName: '', address: '', phone: '', accountOpenedSince: '', creditLimit: '', currentBalance: '' },
  //   { companyName: '', contactName: '', address: '', phone: '', accountOpenedSince: '', creditLimit: '', currentBalance: '' }
  // ]);

  // const [tradeReferencesFocus, setTradeReferencesFocus] = useState([
  //   { companyName: false, contactName: false, address: false, phone: false, accountOpenedSince: false, creditLimit: false, currentBalance: false },
  //   { companyName: false, contactName: false, address: false, phone: false, accountOpenedSince: false, creditLimit: false, currentBalance: false },
  //   { companyName: false, contactName: false, address: false, phone: false, accountOpenedSince: false, creditLimit: false, currentBalance: false }
  // ]);

  // const [isAuthorized, setIsAuthorized] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);

  // const [checksState, setChecksState] = useState(new Array(packageInfo.length).fill(false));

  // const handleCheckbox = position => {
  //   const updatedCheckedState = checksState.map((item, index) => (index === position ? !item : item));
  //   setChecksState(updatedCheckedState);
  // };

  //! needs to put all validation info here
  const isValidated = () => {
    // do some validations
    // decide if you will
    if (isAgreed) {
      return true;
    } else {
      alert('Warning: Please check the fields and agree to the terms and condition!');
      return false;
    }
    // or you will
    // return false;
  };

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    state: {
      storeHours,
      packageSelected
    }
  }));

  // const getTrProps = (rowInfo, column, cell) => {
  //   if (rowInfo) {
  //     if (column) {
  //       return {
  //         onClick: e => {
  //           handleRowClick(column, rowInfo, cell, e);
  //         },
  //         style: {
  //           cursor: 'pointer',
  //           height: `70px`,
  //           margin: `5px`,
  //           borderRadius: `5px`,
  //           background: `#27293d`,
  //           textAlign: 'left',
  //           padding: '0px'
  //         }
  //       };
  //     }

  //     return {};
  //   }
  // };
  const handleDirectOptionChange = event => {
    setPackageSelected(event.target.value);
  };

  const columns = [
    {
      Header: '',
      accessor: '',
      width: 100,
      textAlign: 'left',
      Cell: row =>
        row.index === 0 ? (
          <div>
            <input key={row.index} type="radio" name="directType" value="essential" checked={packageSelected === 'essential'} onChange={handleDirectOptionChange} />
          </div>
        ) : row.index === 1 ? (
          <div>
            <input key={row.index} type="radio" name="directType" value="basic" checked={packageSelected === 'basic'} onChange={handleDirectOptionChange} />
          </div>
        ) : row.index === 2 ? (
          <div>
            <input key={row.index} type="radio" name="directType" value="pro" checked={packageSelected === 'pro'} onChange={handleDirectOptionChange} />
          </div>
        ) : row.index === 3 ? (
          <div>
            <input key={row.index} type="radio" name="directType" value="white_glove" checked={packageSelected === 'white_glove'} onChange={handleDirectOptionChange} />
          </div>
        ) : null
    },
    {
      Header: 'PACKAGE',
      accessor: 'name',
      width: 'auto',
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{props.value}</div>
    },
    {
      Header: '# of Seats',
      accessor: 'seats_number',
      width: 'auto',
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{props.value}</div>
    },
    {
      Header: 'FEATURES',
      accessor: 'feature',
      width: 'auto',
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left', whiteSpace: 'pre-line' }}>{props.value}</div>
    },
    {
      Header: 'MONTHLY FEE',
      accessor: 'month_fee',
      width: 'auto',
      textAlign: 'center',
      Cell: props => <div style={{ textAlign: 'left' }}>{props.value}</div>
    },
    {
      Header: '',
      accessor: '',
      width: 5,
      textAlign: 'left',
      Cell: row => <div></div>
    }
  ];

  return (
    <>
      <h4 className="info-text">Business Hours</h4>
      <StoreHours setStoreHours={setStoreHours} />
      <h4 className="info-text">Service Package</h4>
      <form autoComplete="off">
        <input autoComplete="off" hidden="true"></input>
        <ReactTable
          style={{ margin: 'auto', width: '90%' }}
          key="1"
          data={packageInfo}
          columns={columns}
          sortable={false}
          resizable={true}
          showPagination={false}
          defaultPageSize={5}
          className="-striped -highlight"
          // getTrProps={getTrProps}
          // getTheadThProps={getTheadThProps}
          // onSortedChange={e => resetExpand()}
          // onPageChange={e => resetExpand()}
          collapseOnSortingChange={true}
          collapseOnPageChange={true}
          collapseOnDataChange={true}
          // expanded={expandedItems[selected]}
          SubComponent={row => {
            return (
              <div
                style={{
                  backgroundColor: '#17192D',
                  borderRadius: '6px',
                  paddingTop: '15px',
                  paddingLeft: '10px',
                  marginRight: '10px',
                  marginLeft: '10px',
                  marginBottom: '20px',
                  height: 'auto'
                }}
              ></div>
            );
          }}
        />
      </form>

      <div style={{ margin: 'auto', width: '85%' }}>
        <table cellspacing="0" cellpadding="0" style={{ borderCollapse: 'separate', borderSpacing: '0 12px' }}>
          <tbody>
            <tr>
              <td valign="middle" style={{ width: '18%' }}>
                <p>
                  <b>EXHIBIT A TERMS</b>
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>Monthly Fee</p>
              </td>
              <td valign="middle">
                <p>A monthly Gophr product and delivery service subscription fee</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>Net Terms</p>
              </td>
              <td valign="middle">
                <p>Net terms require credit references and/or a credit check. Terms may be revoked due to late payments</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>Prepaid Delivery</p>
              </td>
              <td valign="middle">
                <p>Delivery Fee will be collected before the driver is dispatched. No User Terms required</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>Essentials</p>
              </td>
              <td valign="middle">
                <p>Gives Users access to the Gophr Dashboard with prepaid delivery access only. No User Terms required</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>Gophr Basic</p>
              </td>
              <td valign="middle">
                <p>
                  Gives Users access to the Gophr Dashboard with flexible invoicing and payment terms. Delivery will be charged upon completion of the order or on net terms. User
                  Terms required
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p></p>
        <p>
          <br />
        </p>
      </div>
      {/* {flexDirectCheck && (
        <>
          <h4 className="info-text" style={{ marginTop: '30px', marginBottom: '-20px' }}>
            Bank Reference
          </h4>
          <form autoComplete="off">
            <input autoComplete="off" hidden="true"></input>
            <Row className="justify-content-center mt-5">
              <Col sm="3">
                <label>Institution Name</label>
                <InputGroup
                  className={classnames(institutionNameState, {
                    'input-group-focus': institutionNameFocus
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="tim-icons icon-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="institution-name"
                    type="text"
                    onChange={e => setInstitutionName(e.target.value)}
                    onFocus={e => setInstitutionNameFocus(true)}
                    onBlur={e => setInstitutionNameFocus(false)}
                  />
                  {institutionNameState === 'has-danger' ? <label className="error">This field is required.</label> : null}
                </InputGroup>
              </Col>
              <Col sm="5">
                <label>Address</label>
                <InputGroup
                  className={classnames(institutionAddressState, {
                    'input-group-focus': institutionAddressFocus
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="tim-icons icon-square-pin" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="institution-address"
                    type="text"
                    onChange={e => setInstitutionAddress(e.target.value)}
                    onFocus={e => setInstitutionAddressFocus(true)}
                    onBlur={e => setInstitutionAddressFocus(false)}
                  />
                  {institutionAddressState === 'has-danger' ? <label className="error">This field is required.</label> : null}
                </InputGroup>
              </Col>
              <Col sm="3">
                <label>Phone</label>
                <InputGroup
                  className={classnames(institutionPhoneState, {
                    'input-group-focus': institutionPhoneFocus
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="tim-icons icon-mobile" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="number"
                    type="tel"
                    placeholder="Ex: 1234567890"
                    onChange={e => setInstitutionPhone(e.target.value)}
                    onFocus={e => setInstitutionPhoneFocus(true)}
                    onBlur={e => setInstitutionPhoneFocus(false)}
                  />
                  {institutionPhoneState === 'has-danger' ? <label className="error">This field is required.</label> : null}
                </InputGroup>
              </Col>
            </Row>
          </form>
          <h4 className="info-text" style={{ marginTop: '30px' }}>
            Trade Reference
          </h4>
          <form autoComplete="off">
            <input autoComplete="off" hidden="true"></input>

            {tradeReferences.map((trade, index) => {
              return (
                <>
                  <h5 style={{ marginLeft: '45px', marginBottom: '-20px' }}>Trade Reference {index + 1}</h5>
                  <Row className="justify-content-center mt-5">
                    <Col sm="3">
                      <label>Company Name</label>
                      <InputGroup
                        className={classnames(tradeReferencesState[index].companyName, {
                          'input-group-focus': tradeReferencesFocus[index].companyName
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="institution-name"
                          type="text"
                          onChange={e =>
                            setTradeReferences(
                              tradeReferences.map((refer, i) => {
                                return index === i ? { ...trade, companyName: e.target.value } : refer;
                              })
                            )
                          }
                          onFocus={e =>
                            setTradeReferencesFocus(
                              tradeReferencesFocus.map((focus, i) => {
                                return index === i ? { ...focus, companyName: true } : focus;
                              })
                            )
                          }
                          onBlur={e =>
                            setTradeReferencesFocus(
                              tradeReferencesFocus.map((focus, i) => {
                                return index === i ? { ...focus, companyName: false } : focus;
                              })
                            )
                          }
                        />
                        {tradeReferencesState[index].companyName === 'has-danger' ? <label className="error">This field is required.</label> : null}
                      </InputGroup>
                    </Col>
                    <Col sm="3">
                      <label>Contact Name</label>
                      <InputGroup
                        className={classnames(tradeReferencesState[index].contactName, {
                          'input-group-focus': tradeReferencesFocus[index].contactName
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="institution-name"
                          type="text"
                          onChange={e =>
                            setTradeReferences(
                              tradeReferences.map((refer, i) => {
                                return index === i ? { ...trade, contactName: e.target.value } : refer;
                              })
                            )
                          }
                          onFocus={e =>
                            setTradeReferencesFocus(
                              tradeReferencesFocus.map((focus, i) => {
                                return index === i ? { ...focus, contactName: true } : focus;
                              })
                            )
                          }
                          onBlur={e =>
                            setTradeReferencesFocus(
                              tradeReferencesFocus.map((focus, i) => {
                                return index === i ? { ...focus, contactName: false } : focus;
                              })
                            )
                          }
                        />
                        {tradeReferencesState[index].contactName === 'has-danger' ? <label className="error">This field is required.</label> : null}
                      </InputGroup>
                    </Col>
                    <Col sm="3">
                      <label>Contact Name</label>
                      <InputGroup
                        className={classnames(tradeReferencesState[index].contactName, {
                          'input-group-focus': tradeReferencesFocus[index].contactName
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="institution-name"
                          type="text"
                          onChange={e =>
                            setTradeReferences(
                              tradeReferences.map((refer, i) => {
                                return index === i ? { ...trade, contactName: e.target.value } : refer;
                              })
                            )
                          }
                          onFocus={e =>
                            setTradeReferencesFocus(
                              tradeReferencesFocus.map((focus, i) => {
                                return index === i ? { ...focus, contactName: true } : focus;
                              })
                            )
                          }
                          onBlur={e =>
                            setTradeReferencesFocus(
                              tradeReferencesFocus.map((focus, i) => {
                                return index === i ? { ...focus, contactName: false } : focus;
                              })
                            )
                          }
                        />
                        {tradeReferencesState[index].contactName === 'has-danger' ? <label className="error">This field is required.</label> : null}
                      </InputGroup>
                    </Col>
                    <Col sm="5">
                      <label>Address</label>
                      <InputGroup
                        className={classnames(institutionAddressState, {
                          'input-group-focus': institutionAddressFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-square-pin" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="institution-address"
                          type="text"
                          onChange={e => setInstitutionAddress(e.target.value)}
                          onFocus={e => setInstitutionAddressFocus(true)}
                          onBlur={e => setInstitutionAddressFocus(false)}
                        />
                        {institutionAddressState === 'has-danger' ? <label className="error">This field is required.</label> : null}
                      </InputGroup>
                    </Col>
                    <Col sm="3">
                      <label>Phone</label>
                      <InputGroup
                        className={classnames(institutionPhoneState, {
                          'input-group-focus': institutionPhoneFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-mobile" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="number"
                          type="tel"
                          placeholder="Ex: 1234567890"
                          onChange={e => setInstitutionPhone(e.target.value)}
                          onFocus={e => setInstitutionPhoneFocus(true)}
                          onBlur={e => setInstitutionPhoneFocus(false)}
                        />
                        {institutionPhoneState === 'has-danger' ? <label className="error">This field is required.</label> : null}
                      </InputGroup>
                    </Col>
                  </Row>
                </>
              );
            })}
          </form> 
        </>
      )} */}
      <h4 className="info-text" style={{ marginTop: '30px' }}>
        Terms and Conditions
      </h4>
      <div style={{ width: '90%', margin: 'auto', overflowY: 'scroll', height: '300px' }}>
        <p>
          GOPHR APP, INC (“
          <b>
            <i>GOPHR</i>
          </b>
          ”) and the undersigned business (the “
          <b>
            <i>User</i>
          </b>
          ”) agree to work together to facilitate the delivery of User’s products and offerings from User’s business identified below (the “
          <b>
            <i>User</i>
          </b>
          ”) as set forth in this GOPHR User Terms (the “
          <b>
            <i>Agreement</i>
          </b>
          ”).<span> </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>
            1. Our Relationship:<span> </span>
          </b>
        </p>
        <ul>
          <li>
            GOPHR is an on demand delivery firm. GOPHR will handle the delivery of orders initiated by the User [“
            <b>
              <i>User Directed Delivery(ies)</i>
            </b>
            ”] via the “
            <b>
              <i>Gophr Delivery Dashboard</i>
            </b>
            ”.
          </li>
          <li>
            GOPHR reserves the right to hire independent contractors to provide delivery services for User. GOPHR will not be liable or responsible for independent contractors
            delivering industrial products or any errors or misrepresentations made by them. GOPHR will have the sole right to determine the particulars of the independent
            contractor’s delivery services, including, without limitation, the delivery fee, delivery area and availability.
          </li>
          <li>
            User will fulfill User Directed Deliveries by preparing and packaging the User's goods for pick up and delivery before the driver arrives. User will prepare item orders
            (i) consistent with User’s descriptions, (ii) in compliance with all applicable health and safety requirements, (iii) in accordance with industry standards, and (iv)
            during the hours of operation specified and as otherwise provided to GOPHR. User will ensure that its User’s descriptions, hours of operation, inventory items, and
            other particulars required for GOPHR to perform the GOPHR Services are up to date. User will ensure that its User’s descriptions are sufficiently detailed as to
            contents.
          </li>
          <li>
            User Directed Deliveries will be initiated and coordinated via the Gophr Delivery Dashboard. Users must ask the GOPHR driver to identify the addressee, delivery address
            and order contents with respect to all User Directed Deliveries.
          </li>
        </ul>
        <p>
          <b>
            2. Fees and Payment Terms:<span> </span>
          </b>
        </p>
        <ul>
          <li>The “Gophr Dashboard” quote provided during the order generation process is subject to change due to the following conditions:</li>
        </ul>
        <div style={{ paddingLeft: '70px' }}>
          <p>
            The information input from the User is not accurate. ie : Weight, dimensions,<span> </span>
            <span> </span>
            <span> </span>vehicle type or special equipment needed.
          </p>
          <p>Special equipment (forklift, dolly, packaging, hazardous material, etc) is needed to complete the delivery.</p>
          <p>
            Demurrage fees will be applied to shipping fee if either the user or the user's customer is preventing the delivery from being loaded or unloaded for longer than 30
            minutes, at a rate of $90/Hr.
          </p>
        </div>
        <p>
          <br />
        </p>
        <ul>
          <li>Deliveries will be invoiced to the User at the frequency selected in Exhibit A . (refer to Exhibit A)</li>
          <li>
            Invoices not paid in the agreed upon terms will incur late charges. 1.5% will be added to the original invoiced amount on the first day of delinquency. An additional
            1.5% will be added every 30 days past this day. If an invoice is 90 days past due, Gophr reserves the right to cancel user terms and forward account onto licensed
            collection agency.
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          <b>3</b>. <b>User’s Representations and Warranties</b>.<span> </span>
        </p>
        <ul>
          <li>
            User represents, warrants and covenants that: (i) it has the authority to enter into the User Terms and to grant the rights granted hereunder, and doing so will not
            violate any other agreement to which it is a party
          </li>
        </ul>
        <p>
          <b>
            4.<span>  </span>Additional Terms:<span> </span>
          </b>
        </p>
        <ul>
          <li>
            GOPHR may include certain content (including without limitation User’s name, photographs, trademarks and logos) provided by the User (the “
            <b>
              <i>User Content</i>
            </b>
            ”) in its marketing materials . User hereby grants to GOPHR a perpetual, royalty-free worldwide right and license to use the User Content for marketing and promotional
            purposes by any means now known or hereinafter developed.
          </li>
          <li>
            During and after the term of the User Terms, GOPHR may use non-identifiable User Content and data for purposes of enhancing the services provided to User, aggregated
            statistical analysis, technical support and other internal and external business purposes.<span> </span>
          </li>
          <li>
            GOPHR and User are independent contractors, and nothing herein may be construed to create any agency, partnership or joint venture between them. Notwithstanding
            anything to the contrary, neither party has any authority of any kind to bind the other party in any respect whatsoever. The User Terms is not intended to benefit, nor
            will it be deemed to give rise to, any rights in any third party. No ambiguity will be construed against any party based upon a claim that such party drafted the
            ambiguous language. The User Terms and any other agreements or terms incorporated herein by reference, constitute the entire agreement between the parties and
            supersedes any prior understanding (written or oral) on the subject matter hereof.
          </li>
          <li>
            User acknowledges that it bears all liability for preparation and packaging (<b>but not delivery</b>) and thus agrees to indemnify, defend and hold harmless GOPHR and
            its affiliates, directors, employees, officers and agents from and against any and all liabilities, obligations, losses, damages, penalties, actions or claims, directly
            or indirectly, resulting from, arising out of or connected with (1) quality, preparation and packaging, including violations of health and safety laws, regulations,
            rules, and codes applicable to preparation; (2) any negligent act or omission of User or User’s agents or other individuals associated with User, (3) any demands,
            assessments, judgments, costs, reasonable attorneys’ fees, or other expenses arising out of, or connected with, any action, suit, proceeding or claim incident to any of
            the foregoing; and (4) responding to, compliance with or defending any subpoenas, levees, seizures, attachments, garnishments, UCC notices brought by creditors,
            judgment creditors, taxing authorities, or any other such person or entity against User or User’s agents or other individuals associated with User.
          </li>
          <li>
            GOPHR shall indemnify, defend and hold harmless User from and against any and all liabilities, obligations, losses, damages, penalties, actions or claims, directly or
            indirectly, resulting from, arising out of or connected with (i) delivery of the goods, (ii) any negligent act or omission of GOPHR, its agents or other individuals
            associated with GOPHR, and (iii) any demands, assessments, judgments, costs, reasonable attorneys’ fees, or other expenses arising out of, or connected with, any
            action, suit, proceeding or claim incident to any of the foregoing.<span> </span>
          </li>
          <li>
            UNDER NO CIRCUMSTANCES WILL GOPHR BE LIABLE TO THE User FOR DAMAGES RESULTING FROM THE FAILURE OF GOPHR’s WEBSITE, SERVICES OR User CONTENT. UNDER NO CIRCUMSTANCES WILL
            GOPHR BE LIABLE TO User FOR INDIRECT, WILLFUL, PUNITIVE, CONSEQUENTIAL, INCIDENTAL, SPECIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATED TO THE AGREEMENT, INCLUDING,
            BUT NOT LIMITED TO LOST PROFITS, LOSS OF BUSINESS, LOSS OR INACCURACY OF DATA OF ANY KIND, WHETHER BASED ON CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, EVEN IF THE PARTY
            IS APPRISED OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING. GOPHR’S MAXIMUM LIABILITY UNDER THE AGREEMENT WILL BE THE AMOUNT OF COMMISSIONS EARNED BY GOPHR DURING THE SIX
            (6) MONTH PERIOD IMMEDIATELY PRIOR TO THE EVENT WHICH GAVE RISE TO SUCH DAMAGES. THE FOREGOING LIMITATIONS WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED
            IN THE AGREEMENT IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE.
          </li>
          <li>
            User and GOPHR agree to maintain the confidentiality of all non-public information that each learns about the other party during the course of performing this
            Agreement, including without limitation all customer information, all User information, all information derived from GOPHR’s App (and supporting platform components),
            and the terms and conditions of this Agreement (the “
            <b>
              <i>Confidential Information</i>
            </b>
            ”).<span> </span>
          </li>
        </ul>
        <p>
          <b>
            5. Miscellaneous Agreements:<span> </span>
          </b>
        </p>
        <ul>
          <li>
            GOPHR reserves the right to modify this Agreement from time to time, including adjusting fees, at its discretion, and any such changes will be effective within 30 days
            after written notice of the same is delivered to the User. The User shall have the right to terminate this Agreement immediately, without penalty and without any
            further action on the part of GOPHR, upon notice of any fee adjustments which the User deems unacceptable in its sole discretion.
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ul>
          <li>All notices to GOPHR will be sent to:</li>
        </ul>
        <div style={{ paddingLeft: '260px' }}>
          <p>Gophr App, Inc.</p>
          <p>619 Ryan St. Suite 206</p>
          <p>Lake Charles, Louisiana 70601</p>
          <p>Email: david@gophr.app</p>
        </div>
        <p>
          <br />
        </p>
        <ul>
          <li>The Agreement may not be amended by the User unless such amendment is signed by an authorized representative of GOPHR.</li>
        </ul>
        <p>
          <br />
        </p>
      </div>
      {/* <p>
         
           <b>Exhibit A</b>
        </p>
        <p>This Exhibit A forms part of the GOPHR User Terms and will not become binding until it is initialed and the User Terms has been signed by both User and Gophr.</p>
        <p>
          <br />
        </p> */}
      {/* <table cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="middle">
                <p>
                  <b>PACKAGE</b>
                </p>
              </td>
              <td valign="middle">
                <p>
                  <b>FEATURES</b>
                </p>
              </td>
              <td valign="middle">
                <p>
                  <b>MONTHLY FEE</b>
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>
                  <span>  </span>▢<span>    </span>Essentials
                </p>
              </td>
              <td valign="middle">
                <p>Prepaid Delivery Only</p>
              </td>
              <td valign="middle">
                <p>$0</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>
                  <span>  </span>▢<span>    </span>Gophr Basic
                </p>
              </td>
              <td valign="middle">
                <p>Delivery with Flexible Terms</p>
              </td>
              <td valign="middle">
                <p>$9.99</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <br />
        </p>
        <table cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="middle">
                <p>
                  <b>PAYMENT TERMS</b>
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>
                  <b>Invoice Frequency</b>
                </p>
              </td>
              <td valign="middle">
                <p>
                  <b>Payment Method</b>
                </p>
              </td>
              <td valign="middle">
                <p>
                  <b>Net Terms</b>
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>
                  <span>  </span>▢<span>  </span>On Demand / Per Order
                </p>
                <p>
                  <span>  </span>▢<span>  </span>Daily
                </p>
                <p>
                  <span>  </span>▢<span>  </span>Weekly
                </p>
              </td>
              <td valign="middle">
                <p>
                  <span> </span>▢<span>  </span>Card on File
                </p>
                <p>
                  <span> </span>▢<span>  </span>ACH*
                </p>
              </td>
              <td valign="middle">
                <p>
                  <span>
                    <span>  </span>
                  </span>{' '}
                  ▢<span>  </span>15 Day<span> </span>
                </p>
                <p>
                  <span>   </span>▢<span>  </span>30 Day
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <br />
        </p>
        <p>
          <b>ACH (Automated Clearing House)*</b>
        </p>
        <p>Account Number ____________________________________________________________</p>
        <p>
          Account Type: Business<span> </span>
          <span>
            {' '}
            ▢<span>  </span>
          </span>
          or Personal<span> </span>
          <span>
            {' '}
            ▢ <span> </span>
          </span>
        </p>
        <p>
          Routing Number<span>  </span>____________________________________________________________
        </p>
        <p>
          Note: All written Debit Authorizations must provide that the receiver may invoke the authorization only by notifying the originator in the manner specified in this
          Exhibit A.This authorization is to remain in full force and effect until GOPHR APP, INC. has received written notification from the business owner of its termination in
          such time and in such a manner as to afford GOPHR APP, INC. and DEPOSITORY a reasonable opportunity to act on it. I/we authorize GOPHR APP, INC. to initiate scheduled
          debit and credit entries on the account.
        </p>
        <p>
          <br />
        </p> */}

      {/*  <table cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>FEDERAL TAXPAYER IDENTIFICATION # (EIN)</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>
                  LEGAL ENTITY <span>     </span>
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>-</p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>STORE ADDRESS</p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>
                  CITY <span>   </span>
                </p>
              </td>
              <td valign="middle">
                <p>STATE</p>
              </td>
              <td valign="middle">
                <p>ZIP</p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>STORE PHONE NUMBER</p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>WEBSITE</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>STORE EMAIL ADDRESS</p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>BILLING EMAIL ADDRESS</p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <br />
        </p>
        <table cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="middle">
                <p>PRIMARY CONTACT INFORMATION</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>MANAGER’S NAME</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>MANAGER’S CELL PHONE #</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>MANAGER’S EMAIL</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <br />
        </p>
        <table cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>MONDAY</p>
              </td>
              <td valign="middle">
                <p>TUESDAY</p>
              </td>
              <td valign="middle">
                <p>WEDNESDAY</p>
              </td>
              <td valign="middle">
                <p>THURSDAY</p>
              </td>
              <td valign="middle">
                <p>FRIDAY</p>
              </td>
              <td valign="middle">
                <p>SATURDAY</p>
              </td>
              <td valign="middle">
                <p>SUNDAY</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>Shipping / Receiving Hours</p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
              <td valign="middle">
                <p>
                  <br />
                </p>
              </td>
            </tr>
          </tbody>
        </table> */}
      {/* <p>
          <input
            key="authorized"
            type="checkbox"
            // checked={}
            onClick={e => {
              setIsAuthorized(!isAuthorized);
            }}
            style={{ marginRight: '5px' }}
          />
          I certify that (i) I am the duly authorized representatives and have read and understood this Agreement; (ii) I understand and agree that GOPHR may modify this Agreement
          at any time in its sole discretion, provided that such modifications will be applied only prospectively; and (iii) I understand and agree GOPHR has the right to notify me
          of updated terms by posting them on the GOPHR System.
        </p>
        <p>
          <input
            key="authorized"
            type="checkbox"
            // checked={}
            onClick={e => {
              setIsAgreed(!isAgreed);
            }}
            style={{ marginRight: '5px' }}
          />
          I have read and agree with the Terms and Conditions.
        </p> */}
      {/* <p>
          User<span>  </span>(AUTHORIZED SIGNATORY)
        </p>
        <p>
          SIGNATURE________________________________________________________________________________<span>      </span>AGREEMENT DATED _____________________
        </p>
        <p>
          PRINT NAME ______________________________________________________________________<span>      </span>TITLE _________________________________________
        </p>
        <p>
          GOPHR<span>  </span>(AUTHORIZED SIGNATORY)
        </p>
        <p>
          SIGNATURE________________________________________________________________________________<span>      </span>AGREEMENT DATED _____________________
        </p>
        <p>
          PRINT NAME ______________________________________________________________________<span>      </span>TITLE _________________________________________
        </p> 
      </div>*/}
      {/* <h4 className="info-text" style={{ marginTop: '30px' }}>
        Terms and Conditions
      </h4>
      {showPDF ? <Agreement /> : <LoadPDFButton onClick={handleLoadPDFClick} />} */}

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}>
        <p style={{ marginTop: '10px' }}>
          <input
            key="authorized"
            type="checkbox"
            // checked={}
            onClick={e => {
              setIsAgreed(!isAgreed);
            }}
            style={{ marginRight: '5px' }}
          />
          I have read and agree with the Terms and Conditions.
        </p>
      </div>
    </>
  );
});

export default Wizard;
