import config from '../../config';

export const shipmentHost = config.config.api_shipments.admin.shipmentHost;
export const hasuraHost = config.config.api_shipments.admin.hasuraHost;
export const wssHasuraHost = config.config.api_shipments.admin.wssHasuraHost;

//admin routes
export const shipmentEndpoint = shipmentHost + '/shipments/';
export const createMerchantAndStore = shipmentHost + '/admins/stores/create/merchant';
export const dropShipmentsEndpoint = shipmentHost + '/admins/dropshipments';
export const createDropShipment = shipmentHost + '/admins/dropship/create';
export const editDropShipment = shipmentHost + '/admins/dropship/edit';
export const deleteDropShipment = shipmentHost + '/admins/dropship/delete';
export const completeDropShipment = shipmentHost + '/admins/shipments/';
export const completeDropRoute = shipmentHost + '/admins/routes/';
export const getQuote = shipmentHost + '/shipments/dropship/quote';
export const adminShipment = shipmentHost + '/admins/shipments/';
export const adminRoute = shipmentHost + '/admins/routes/';
export const adminOrder = shipmentHost + '/admins/orders/';
