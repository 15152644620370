import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

import formatPhone from '../../helpers/phone';
import { formatTimestamp } from '../../helpers/formatter';

const Detailed = props => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const calculateTotalDriverPay = shipment => {
    const preCalculate = shipment.driver_1099_earning + (shipment.num_of_gophrs - 1) * shipment.passenger_1099_earning;
    if (shipment.DriverPays.length === 0) {
      return preCalculate;
    } else {
      let total = 0;
      shipment.DriverPays.map(pay => {
        if (!pay.deleted_at) {
          total += pay.total_earning;
        }
      });
      return total === 0 ? preCalculate : total;
    }
  };

  return (
    <>
      <tr onClick={toggle}>
        <td>{props.shipment.customer_blob.store_name}</td>
        <td>{props.shipment.is_expedited === true ? '✅' : '❌'}</td>
        <td>{props.shipment.shipment_type}</td>
        <td>{props.shipment.shipment_friendly_id}</td>
        <td>{props.shipment.vehicle_type}</td>
        <td>${calculateTotalDriverPay(props.shipment)}</td>
        <td>
          {props.shipment.status === 'shipment_saved'
            ? 'Saved'
            : props.shipment.status === 'shipment_created'
            ? 'Created'
            : props.shipment.status === 'shipment_scheduled'
            ? 'Scheduled'
            : props.shipment.status === 'shipment_accepted'
            ? 'Accepted'
            : props.shipment.status === 'shipment_ready_for_driver'
            ? 'Ready for Driver'
            : props.shipment.status === 'shipment_claimed'
            ? 'Claimed'
            : props.shipment.status === 'shipment_assigned'
            ? 'Assigned'
            : props.shipment.status === 'shipment_enroute'
            ? 'En Route'
            : props.shipment.status === 'shipment_completed'
            ? 'Completed'
            : props.shipment.status === 'shipment_declined'
            ? 'Declined'
            : props.shipment.status === 'shipment_cancelled'
            ? 'Cancelled'
            : null}
        </td>
      </tr>
      <Collapse isOpen={isOpen}>
        <div className="details-container" style={{ display: `flex`, flexDirection: `column` }}>
          <div className="customer-info">
            <h4 style={{ color: `#f7941d` }}>Customer Information</h4>
            <ul style={{ listStyle: `none`, padding: `0px` }}>
              <li>
                <strong>First Name: </strong>
                {props.shipment.customer_blob.first_name}
              </li>
              <li>
                <strong>Last Name: </strong>
                {props.shipment.customer_blob.last_name}
              </li>
              <li>
                <strong>Phone: </strong>
                {formatPhone(props.shipment.customer_blob.phone)}
              </li>
              <li>
                <strong>Address: </strong>
                {props.shipment.customer_blob.address}
              </li>
              <li>
                <strong>Email: </strong>
                {props.shipment.customer_blob.email}
              </li>
            </ul>
          </div>
          <div className="shipment-details">
            <h4 style={{ color: `#f7941d` }}>Shipment Details</h4>
            <ul style={{ listStyle: `none`, padding: `0px` }}>
              <li>
                <strong>Shipment Created at: </strong>
                {formatTimestamp(props.shipment.created_at)}
              </li>
              <li>
                <strong>Updated at: </strong>
                {formatTimestamp(props.shipment.updated_at)}
              </li>
              <li>
                <strong>Description: </strong>
                {props.shipment.description}
              </li>
              <li>
                <strong>Drop Off Instructions: </strong>
                {props.shipment.drop_off_instructions}
              </li>
              <li>
                <strong>Pick Up Instructions: </strong>
                {props.shipment.pick_up_instructions}
              </li>
              <li>
                <strong>Number of Gophrs: </strong>
                {props.shipment.num_of_gophrs}
              </li>
              <li></li>
              <li>
                <strong>Distance: </strong>
                {props.shipment.distance}
              </li>
              <li>
                <strong>Total Weight: </strong>
                {props.shipment.weight}
              </li>
              {props.user.Roles[0].role_description === 'super-admin' || 'admin' ? (
                <div className="totals">
                  <li>
                    <strong>Shipping Fee: </strong>${props.shipment.shipping_fee}
                  </li>
                  <li>
                    <strong>Shipping Total: </strong>${props.shipment.shipment_total}
                  </li>
                  <li>
                    <strong>Gratuity: </strong>${props.shipment.gratuity}
                  </li>
                </div>
              ) : null}
            </ul>
          </div>
          <div className="order-details">
            <h4 style={{ color: `#f7941d` }}>Order Details</h4>
            {props.shipment.Orders.length >= 0
              ? props.shipment.Orders.map(order => {
                  return (
                    <ul style={{ listStyle: `none`, padding: `0px` }}>
                      <li>
                        <strong>Order Status: </strong>
                        {order.status === 'order_completed' ? 'Completed' : order.status === 'order_declined' ? 'Declined' : null}
                      </li>
                      <li>
                        <strong>Created At: </strong>
                        {formatTimestamp(order.created_at)}
                      </li>
                      <li>
                        <strong>Updated At: </strong>
                        {formatTimestamp(order.updated_at)}
                      </li>
                      <li>
                        <strong>Location Info: </strong>
                        {order.location_blob.address_1} {order.location_blob.city}, {order.location_blob.state} {order.location_blob.zip}
                      </li>
                      <li>
                        <strong>Description: </strong>
                        {order.description}
                      </li>
                      <li>
                        <strong>Weight: </strong>
                        {order.weight}
                      </li>
                      <li>
                        <strong>Pick Up Instructions: </strong>
                        {order.pick_up_instructions ? order.pick_up_instructions : 'None'}
                      </li>
                    </ul>
                  );
                })
              : 'No Orders'}
          </div>
          {props.shipment.Route !== null && props.shipment.Route.RouteDrivers.length > 0 ? (
            <div>
              <h4 style={{ color: `#f7941d` }}>Driver Details</h4>
            </div>
          ) : null}
          {props.shipment.Route !== null && props.shipment.Route.RouteDrivers.length >= 0
            ? props.shipment.Route.RouteDrivers.map((driver, i) => {
                return (
                  <div className="driver-details">
                    <ul key={i} style={{ listStyle: `none`, padding: `0px` }}>
                      <li>
                        <strong>Driver First Name: </strong>
                        {driver.driver_blob.first_name}
                      </li>
                      <li>
                        <strong>Driver Last Name: </strong>
                        {driver.driver_blob.last_name}
                      </li>
                      <li>
                        <strong>Carrying Capacity: </strong>
                        {driver.driver_blob.carry_capacity}
                      </li>
                      <li>
                        <strong>Phone Number: </strong>
                        {/* {formatPhone(driver.driver_blob.phone)} */}
                        {driver.driver_blob.phone}
                      </li>
                      <div className="vehicle-information" style={{ marginLeft: `15px` }}>
                        <h6 style={{ marginTop: `15px` }}>Vehicle Information</h6>
                        <ul style={{ listStyle: `none`, padding: `0px` }}>
                          <li>
                            <strong>Year: </strong>
                            {driver.vehicle_blob.year}
                          </li>
                          <li>
                            <strong>Color: </strong>
                            {driver.vehicle_blob.color}
                          </li>
                          <li>
                            <strong>Make: </strong>
                            {driver.vehicle_blob.make}
                          </li>
                          <li>
                            <strong>Model: </strong>
                            {driver.vehicle_blob.model}
                          </li>
                          <li>
                            <strong>Plate State: </strong>
                            {driver.vehicle_blob.plate_state}
                          </li>
                        </ul>
                      </div>
                    </ul>
                    <hr />
                  </div>
                );
              })
            : null}
        </div>
      </Collapse>
    </>
  );
};

export default Detailed;
