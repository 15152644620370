import styled from 'styled-components';

export const Styles = styled.div`
  margin: 0 auto;
  width: 250px;
  margin-left: -290px;
  margin-top: -85px;
  .store-header {
    h4 {
      margin-top: 15px;
      font-size: 18px;
      color: #efeeee;
    }
  }
`;

export const StyledHr = styled.hr`
  background-color: #344675;
  width: auto;
  margin-top: 0;
  padding-top: 0;
  padding-right: 13px;
  padding-left: 13px;
  margin-bottom: 20px;
`;
