/*!
 * Black Dashboard PRO React - v1.0.0
 * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React, { Component } from 'react';
import { Button, Card, Col } from 'reactstrap';
import NotificationAlert from 'react-notification-alert';
import getRoles from '../../Auth/getRoles';

import DropshipForm from '../DropshipForm/DropshipForm';
import DispatchOrderTable from '../Dispatch/DispatchOrderTable';
import MerchantOrderTable from '../Merchant/MerchantOrderTable';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      showForm2: false,
      street: '',
      city: '',
      state: '',
      query: '',
      // activeTab: "pending",
      activeTab: 'inProgress',
      errorSubmit: {},
      errorDistance: {},
      role: '99999',
      expandedItems: { pending: {}, inProgress: {}, completed: {}, saved: {}, ready: {}, progressing: {}, merging: {}, driving: {} }
    };
  }

  componentWillMount() {
    getRoles().then(val => {
      if (val === 'error') {
        this.props.history.push('/logout');
      } else {
        this.setState({ role: val[0] });
      }
    });
  }

  handleCancel = () => {
    this.setState({ showForm: false });
  };

  toggle = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  externalToggle = (showForm, showForm2) => {
    this.setState({
      showForm: showForm,
      showForm2: showForm2
    });
  };

  externalErrorSubmit = error => {
    this.setState({
      errorSubmit: error
    });
  };

  externalErrorDistance = error => {
    this.setState({
      errorDistance: error
    });
  };

  handleClick = e => {
    this.setState({ activeTab: e.target.name });
  };

  notify = (place, errorType, customMessage) => {
    var type = 'info';
    var options = {
      place: place,
      message: (
        <div>
          <div>{errorType === null ? customMessage : errorType === 'distance' ? this.state.errorDistance.errorMessage : this.state.errorSubmit.errorMessage}</div>
        </div>
      ),
      type: type,
      icon: 'tim-icons icon-alert-circle-exc',
      autoDismiss: 10
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  notifySuccess = (place, message) => {
    var type = 'success';
    var options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: 'tim-icons icon-check-2',
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  expand = expandedItems => {
    this.setState({ expandedItems: expandedItems });
  };

  render() {
    return (
      <div
        className="content"
        // style={{width: "1500px"}}
      >
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          {this.props.role >= 200 && this.props.role < 230 && (
            <div style={{ marginRight: `35px` }}>
              <Button className="float-right" size="lg" id="create-shipment-btn" color="danger" onClick={this.toggle}>
                {this.state.showForm ? (
                  <div>
                    Create Delivery <i className="tim-icons icon-simple-delete" />
                  </div>
                ) : (
                  <div>
                    Create Delivery <i className="tim-icons icon-simple-add" />
                  </div>
                )}
              </Button>
              <Card className="card-form">
                <DropshipForm
                  errorDistance={this.state.errorDistance}
                  errorSubmit={this.state.errorSubmit}
                  handleErrorDistance={this.externalErrorDistance}
                  handleErrorSubmit={this.externalErrorSubmit}
                  showForm={this.state.showForm}
                  showForm2={this.state.showForm2}
                  handleForm={this.externalToggle}
                  notify={this.notify}
                  notifySuccess={this.notifySuccess}
                />
              </Card>
            </div>
          )}
          {this.props.role < 200 ? (
            <div>
              <Col style={{ flexDirection: `row`, marginRight: 20 }}>
                <DispatchOrderTable
                  role={this.props.role}
                  notify={this.notifySuccess}
                  notifyError={this.notify}
                  history={this.props.history}
                  expand={this.expand}
                  expandedItems={this.state.expandedItems}
                />
              </Col>
            </div>
          ) : (
            <div
            // style={{marginLeft: `-90px`}}
            >
              <Col>
                <MerchantOrderTable role={this.props.role} notify={this.notifySuccess} history={this.props.history} expand={this.expand} expandedItems={this.state.expandedItems} />
              </Col>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Dashboard;
