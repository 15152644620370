/*!
 * Black Dashboard PRO React - v1.0.0
 * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
import ReactWizard from 'react-bootstrap-wizard';
import { Col, Modal, ModalHeader } from 'reactstrap';
import axios from 'axios';

import * as user_urls from '../../urls/api-users/merchant';

import Step1 from './WizardSteps/SubMerchant_Step1';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

var steps = [
  {
    stepName: 'Account',
    stepIcon: 'tim-icons icon-single-02',
    component: Step1
  }
];

class CreateSubMerchantWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.finishButtonClick = this.finishButtonClick.bind(this);
  }
  finishButtonClick(allStates) {
    const createStoreManagerUrl = user_urls.createSubMerchant + this.props.storeId + '/create/store-manager';
    const createInventoryManagerUrl = user_urls.createSubMerchant + this.props.storeId + '/create/inventory-manager';
    const createPickerUrl = user_urls.createSubMerchant + this.props.storeId + '/create/picker';
    var merchant = {
      firstname: allStates.Account.firstname,
      lastname: allStates.Account.lastname,
      username: allStates.Account.username,
      email: allStates.Account.email,
      phone_number: allStates.Account.phone.replace(/\D/g, ''),
      password: allStates.Account.password
    };

    this.setState({ loading: true });

    axios({
      method: 'post',
      url: allStates.Account.selectedRole.val == '220' ? createStoreManagerUrl : allStates.Account.selectedRole.val == '230' ? createInventoryManagerUrl : createPickerUrl,
      withCredentials: true,
      data: merchant,
      headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('access_token') }
    })
      .then(response => {
        if (response.status === 200) {
          this.setState({ loading: false }, () => {
            this.props.notify('Successfully added Account');
            this.props.toggleDropdown();
          });
        } else {
          this.setState({ loading: false }, () => {
            this.props.notify(response.error.message);
          });
        }
      })
      .catch(error => {
        console.log('error: ', error.response);
        this.setState({ loading: false }, () => {
          // this.props.notify('Something went wrong, please try again');
          if (error.response) {
            if (error.response.data) {
              if (error.response.data.message) {
                if (error.response.data.message.message) {
                  this.props.notify(error.response.data.message.message);
                } else {
                  this.props.notify('Please check all fields, if still not work, contact Gophr for support!');
                }
              } else {
                this.props.notify('Please check all fields, if still not work, contact Gophr for support!');
              }
            } else {
              this.props.notify('Please check all fields, if still not work, contact Gophr for support!');
            }
          } else {
            this.props.notify('Please check all fields, if still not work, contact Gophr for support!');
          }
        });
      });
  }
  render() {
    return (
      <>
        <div className="content" style={{ paddingTop: 20 }}>
          <Col className="mr-auto ml-auto" md="10">
            <ReactWizard
              steps={steps}
              navSteps
              validate
              title="Create a new account"
              headerTextCenter
              finishButtonClasses="btn-wd btn-info"
              nextButtonClasses="btn-wd btn-info"
              previousButtonClasses="btn-wd"
              progressbar
              color="blue"
              finishButtonClick={this.finishButtonClick}
            />
          </Col>
        </div>
        <Modal isOpen={this.state.loading} centered={true}>
          <div style={{ padding: 50, textAlign: 'center' }}>
            <ModalHeader style={{ justifyContent: 'center' }}>
              <h3 style={{ color: 'black' }}>Please wait...</h3>
            </ModalHeader>
            <Loader type="TailSpin" color="#00BFFF" height={100} width={100} />
          </div>
        </Modal>
      </>
    );
  }
}

export default CreateSubMerchantWizard;
