import React from 'react';

export default function Gophrs4(props) {
  return (
    <svg id="Gophrs4" xmlns="http://www.w3.org/2000/svg" width="69.933" height="40.696" viewBox="0 0 759.933 50.696" fill={props.fill ? props.fill : '#5e72e4'}>
      <g transform="translate(-50.953 -270.535)">
        <circle cx="355.45" cy="198.4" r="100.63" />
        <circle cx="564.71" cy="210.97" r="59.46" />
        <path
          d="M413.15,321.9H290.89c-65.39,0-118.4,53.01-118.4,118.4v0c0,31.87,25.83,57.7,57.7,57.7h243.67
		c31.87,0,57.7-25.83,57.7-57.7v0C531.55,374.91,478.54,321.9,413.15,321.9z"
        />
        <path
          d="M609.06,291.02H497.5c-7.09,0-12.84,5.75-12.84,12.84c0,5.34,3.29,10.17,8.31,11.99c36.52,13.3,62.59,48.32,62.59,89.43
		v4.66h74.96c17.96,0,32.53-14.56,32.53-32.53v-32.4C663.06,315.2,638.88,291.02,609.06,291.02z"
        />
        <circle cx="688.31" cy="210.94" r="39.99" />
        <path
          d="M718.13,264.78H643.1c-4.77,0-8.63,3.86-8.63,8.63c0,3.59,2.21,6.84,5.59,8.07c24.56,8.95,42.1,32.5,42.1,60.15v3.14h50.42
		c12.08,0,21.88-9.79,21.88-21.88V301.1C754.45,281.04,738.19,264.78,718.13,264.78z"
        />
        <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -106.0862 263.1803)" cx="143.9" cy="210.97" rx="59.46" ry="59.46" />
        <path
          d="M215.64,315.85c5.02-1.83,8.31-6.66,8.31-11.99c0-7.09-5.75-12.84-12.84-12.84H99.55c-29.82,0-54,24.18-54,54v32.4
		c0,17.96,14.56,32.53,32.53,32.53h74.96v-4.66C153.04,364.17,179.12,329.15,215.64,315.85z"
        />
      </g>
    </svg>
  );
}

export function Gophrs5(props) {
  return (
    <svg id="Gophrs5" xmlns="http://www.w3.org/2000/svg" width="79.933" height="42.696" viewBox="0 0 850.933 80.696" fill={props.fill ? props.fill : '#5e72e4'}>
      <g transform="translate(-2.953 -270.535)">
        <circle cx="400" cy="198.4" r="100.63" />
        <circle cx="609.27" cy="210.97" r="59.46" />
        <path
          d="M457.7,321.9H335.44c-65.39,0-118.4,53.01-118.4,118.4v0c0,31.87,25.83,57.7,57.7,57.7H518.4c31.87,0,57.7-25.83,57.7-57.7
		v0C576.1,374.91,523.09,321.9,457.7,321.9z"
        />
        <path
          d="M653.61,291.02H542.05c-7.09,0-12.84,5.75-12.84,12.84c0,5.34,3.29,10.17,8.31,11.99c36.52,13.3,62.59,48.32,62.59,89.43
		v4.66h74.96c17.96,0,32.53-14.56,32.53-32.53v-32.4C707.61,315.2,683.43,291.02,653.61,291.02z"
        />
        <circle cx="732.86" cy="210.94" r="39.99" />
        <path
          d="M762.68,264.78h-75.03c-4.77,0-8.63,3.86-8.63,8.63c0,3.59,2.21,6.84,5.59,8.07c24.56,8.95,42.1,32.5,42.1,60.15v3.14
		h50.42c12.08,0,21.88-9.79,21.88-21.88V301.1C799,281.04,782.74,264.78,762.68,264.78z"
        />
        <circle cx="67.14" cy="210.94" r="39.99" />
        <path
          d="M115.39,281.48c3.37-1.23,5.59-4.48,5.59-8.07c0-4.77-3.87-8.63-8.63-8.63H37.32C17.26,264.78,1,281.04,1,301.1v21.79
		c0,12.08,9.79,21.88,21.88,21.88H73.3v-3.14C73.3,313.98,90.83,290.43,115.39,281.48z"
        />
        <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -78.5835 304.341)" cx="188.45" cy="210.97" rx="59.46" ry="59.46" />
        <path
          d="M260.19,315.85c5.02-1.83,8.31-6.66,8.31-11.99c0-7.09-5.75-12.84-12.84-12.84H144.1c-29.82,0-54,24.18-54,54v32.4
		c0,17.96,14.56,32.53,32.53,32.53h74.96v-4.66C197.6,364.17,223.67,329.15,260.19,315.85z"
        />
      </g>
    </svg>
  );
}
