import styled from 'styled-components';

const Styles = styled.div`
  height: calc(100vh - 90px);
  width: 80px;
  position: fixed;
  top: 0;
  left: 92%;
  z-index: 1;
  background-size: cover;
  background-position: center center;
  background-color: #344675;
  display: block;
  box-shadow: 0px 0px 45px 0px rgba(0, 0, 0, 0.6);
  margin-top: 82px;
  margin-left: 20px;
  border-radius: 5px;
  transition: margin-left 0.5s;
  :before {
    content: '';
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #344675;
    top: -5px;
    left: 40px;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    .store-manager,
    .user-manager,
    .credentials-manager {
      background: #344675;
      padding: 0;
      margin-top: 18px;
      margin-bottom: 18px;
      :hover,
      :active {
        background: none !important;
      }
    }
  }
  .info-container {
    position: absolute;
    top: 0;
    right: 100%;
    transition: margin-left 0.5s;
  }
`;

export default Styles;
