import React from 'react';
import { Label, FormGroup, Input, Row, Col } from 'reactstrap';
import WaypointCustomerAutoComplete from './WaypointCustomerAutoComplete';
//! Google Map AutoComplete
import GoogleMapAutoComplete from '../../../Helpers/GoogleMapAutoComplete';
import '../../../Helpers/googleMapAutoComplete.css';
import MapboxAutocomplete from '../../../../assets/react-mapbox-autocomplete/ReactMapboxAutocomplete';
import '../../../../assets/react-mapbox-autocomplete/index.css';
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ29waHItZW5naW5lZXJpbmciLCJhIjoiY2syYzd6NjdrMmo3azNtcGU1cXZzMTh1MSJ9.cHMxwcG_MoQk-l4ZO3bdEA';

const WaypointInfo = ({
  isWaypointStore,
  setIsWaypointStore,
  setIsPickupStore,
  isWaypointPickup,
  setIsWaypointPickup,
  isWaypointDropoff,
  setIsWaypointDropoff,
  waypointErrors,
  storeFirstName,
  waypointFirstname,
  setWaypointFirstname,
  waypointCustomerSuggestions,
  setWaypointCustomerSuggestions,
  storeId,
  setWaypointLastname,
  setWaypointPhone,
  waypointFullAddress,
  setWaypointFullAddress,
  setWaypointPickupInstruction,
  setWaypointDropoffInstruction,
  isWaypointAutoFilled,
  setIsWaypointAutoFilled,
  setWaypointAddress1,
  setWaypointAddress2,
  setWaypointCity,
  setWaypointState,
  setWaypointZip,
  setWaypointCountry,
  setWaypointLat,
  setWaypointLng,
  setWaypointBusinessName,
  storeLastName,
  waypointLastname,
  storePhone,
  waypointPhone,
  storeName,
  waypointBusinessName,
  storeAddress,
  handleWaypointSelect,
  vendorLat,
  vendorLong,
  errorMessageDistance,
  errors,
  waypointPickupInstruction,
  waypointDropoffInstruction
}) => {
  return (
    <>
      <Row style={{ paddingTop: 0 }}>
        <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="store-address">
          <Label check>
            <Input
              required
              type="radio"
              checked={isWaypointStore}
              value="wmd"
              name="store-address-radios3"
              id="my-store-address"
              onClick={() => {
                setIsWaypointStore(true);
                setIsPickupStore(false);
              }}
            />
            <span id="radio" className="form-check-sign" /> Store Address
          </Label>
        </FormGroup>

        <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="shipment-weight">
          <Label check>
            <Input
              required
              type="radio"
              checked={!isWaypointStore}
              name="store-address-radios3"
              value="wdd"
              id="different-address"
              onClick={() => {
                setIsWaypointStore(false);
              }}
            />
            <span id="radio" className="form-check-sign" /> Different Address
          </Label>
        </FormGroup>
      </Row>
      <Row>
        <FormGroup check className="mt-3" style={{ marginLeft: '15px', marginBottom: '30px' }}>
          <Label check>
            <Input
              type="checkbox"
              id="pickup-dropoff-check"
              checked={isWaypointPickup}
              value={isWaypointPickup}
              onClick={e => {
                setIsWaypointPickup(!isWaypointPickup);
              }}
            />
            <span className="form-check-sign" />
            Pick Up
          </Label>
        </FormGroup>
        <FormGroup check className="mt-3" style={{ marginLeft: '30px' }}>
          <Label check>
            <Input
              type="checkbox"
              id="pickup-dropoff-check"
              checked={isWaypointDropoff}
              value={isWaypointDropoff}
              onClick={e => {
                setIsWaypointDropoff(!isWaypointDropoff);
              }}
            />
            <span className="form-check-sign" />
            Drop Off
          </Label>
        </FormGroup>
        <div hidden={!waypointErrors.pickOrDrop}>
          <p style={{ color: 'red', marginTop: '15px', marginLeft: '20px' }}>Choose at least one field</p>
        </div>
      </Row>
      <Row form={true}>
        <Col style={{ textAlign: 'left' }}>
          <Label for="customerFirstName">First Name</Label>
          <FormGroup className={waypointErrors.waypointFirstname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
            <WaypointCustomerAutoComplete
              isWaypointStore={isWaypointStore}
              storeFirstName={storeFirstName}
              waypointFirstname={waypointFirstname}
              setWaypointFirstname={setWaypointFirstname}
              waypointCustomerSuggestions={waypointCustomerSuggestions}
              setWaypointCustomerSuggestions={setWaypointCustomerSuggestions}
              storeId={storeId}
              setWaypointLastname={setWaypointLastname}
              setWaypointPhone={setWaypointPhone}
              waypointFullAddress={waypointFullAddress}
              setWaypointFullAddress={setWaypointFullAddress}
              setWaypointPickupInstruction={setWaypointPickupInstruction}
              setWaypointDropoffInstruction={setWaypointDropoffInstruction}
              isWaypointAutoFilled={isWaypointAutoFilled}
              setIsWaypointAutoFilled={setIsWaypointAutoFilled}
              setWaypointAddress1={setWaypointAddress1}
              setWaypointAddress2={setWaypointAddress2}
              setWaypointCity={setWaypointCity}
              setWaypointState={setWaypointState}
              setWaypointZip={setWaypointZip}
              setWaypointCountry={setWaypointCountry}
              setWaypointLat={setWaypointLat}
              setWaypointLng={setWaypointLng}
              setWaypointBusinessName={setWaypointBusinessName}
            />
            <div hidden={!waypointErrors.waypointFirstname}>
              <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
            </div>
          </FormGroup>
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <Label for="customerLastName">Last Name</Label>
          <FormGroup className={waypointErrors.waypointLastname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
            <Input
              required
              type="text"
              name="customerLastName"
              id="customer-last-name"
              placeholder="Doe"
              onChange={e => (isWaypointStore ? null : setWaypointLastname(e.target.value))}
              value={isWaypointStore ? storeLastName : waypointLastname}
            />
            <div hidden={!waypointErrors.waypointLastname}>
              <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
            </div>
          </FormGroup>
        </Col>
        <Col style={{ textAlign: 'left' }}>
          <Label for="customerPhone">Phone Number</Label>
          <FormGroup className={waypointErrors.waypointPhone ? 'has-danger' : 'thisIsAPlaceholderClass'}>
            <Input
              required
              type="text"
              name="customerPhone"
              id="customer-phone"
              placeholder="555-555-5555"
              onChange={e => (isWaypointStore ? null : setWaypointPhone(e.target.value))}
              value={isWaypointStore ? storePhone : waypointPhone}
            />
            <div hidden={!waypointErrors.waypointPhone}>
              <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid phone number (10-13 digits)</p>
            </div>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="order-waypoint">Business Name (optional)</Label>
            <Input
              type="text"
              name="waypointBusinessName"
              id="waypoint-business-name"
              placeholder="Store Name"
              onChange={e => (isWaypointStore ? null : setWaypointBusinessName(e.target.value))}
              value={isWaypointStore ? storeName : waypointBusinessName}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>Delivery Address</Label>
            {isWaypointAutoFilled ? (
              <Input value={isWaypointStore ? storeAddress : waypointFullAddress} onChange={e => (isWaypointStore ? null : setWaypointFullAddress(e.target.value))} />
            ) : (
              // <MapboxAutocomplete
              //   id="mapbox-autocomplete2"
              //   inputClass="form-control mb-2"
              //   publicKey={MAPBOX_TOKEN}
              //   onSuggestionSelect={handleWaypointSelect}
              //   country="us"
              //   resetSearch={false}
              //   readOnly={isWaypointStore}
              //   defaultValue={isWaypointStore ? storeAddress : null}
              //   value={isWaypointStore ? storeAddress : waypointFullAddress}
              //   query={waypointFullAddress}
              //   placeholder="Street Address"
              //   proximityLat={vendorLat}
              //   proximityLng={vendorLong}
              // />
              <GoogleMapAutoComplete
                inputClass="form-control mb-2"
                onSuggestionSelect={handleWaypointSelect}
                country="us"
                resetSearch={false}
                readOnly={isWaypointStore}
                defaultValue={isWaypointStore ? storeAddress : null}
                value={isWaypointStore ? storeAddress : waypointFullAddress}
                query={waypointFullAddress}
                placeholder="Street Address"
                proximityLat={vendorLat}
                proximityLng={vendorLong}
              />
            )}
            <div hidden={errorMessageDistance.errorCode !== 1}>
              <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageDistance.errorMessage}</p>
            </div>
            <div hidden={!errors.waypointAddress}>
              <p style={{ color: 'red', marginTop: '4px' }}>Please choose an address from the dropdown menu</p>
            </div>
          </FormGroup>
        </Col>
        {isWaypointPickup && (
          <Col>
            <FormGroup>
              <Label for="order-pickup">Pickup Instructions (optional)</Label>
              <Input
                type="text"
                name="waypointPickup"
                id="waypoint-pickup-instructions"
                placeholder="Park in back, attendant will assist."
                value={waypointPickupInstruction}
                onChange={e => {
                  if (e.target.value.length < 510) {
                    return setWaypointPickupInstruction(e.target.value);
                  } else {
                    return;
                  }
                }}
              />
            </FormGroup>
          </Col>
        )}
        {isWaypointDropoff && (
          <Col>
            <FormGroup>
              <Label for="order-dropoff">Dropoff Instructions (optional)</Label>
              <Input
                type="text"
                name="waypointDropoff"
                id="waypoint-dropoff-instructions"
                placeholder="Gate Code, Apt. #, etc."
                value={waypointDropoffInstruction}
                onChange={e => {
                  if (e.target.value.length < 510) {
                    return setWaypointDropoffInstruction(e.target.value);
                  } else {
                    return;
                  }
                }}
              />
            </FormGroup>
          </Col>
        )}
      </Row>
    </>
  );
};

export default WaypointInfo;
