import React from 'react';

const WaypointItemsDescription = ({ pickupItems, isWaypointPickup, waypointPickupItems }) => {
  return (
    <>
      <div style={{ color: '#cdcaca', marginBottom: '5px' }}>
        {pickupItems.length > 0 &&
          pickupItems.map(item => {
            return (
              <p style={{ marginLeft: '8px' }}>
                {item.quantity} {item.detail}
              </p>
            );
          })}
        {isWaypointPickup &&
          waypointPickupItems.length > 0 &&
          waypointPickupItems.map(item => {
            return (
              <p style={{ marginLeft: '8px' }}>
                {item.quantity} {item.detail}
              </p>
            );
          })}
      </div>
    </>
  );
};

export default WaypointItemsDescription;
