import React, { useState, useEffect } from 'react';
import { Label, FormGroup, Input, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import AddButton from '../../../../assets/img/Group_1383@2x.png';
import DeleteButton from '../../../../assets/svgs/TrashSimple.svg';

// todo: handle errors
const LineItemsMultipleDrop = ({ title, multiDropWaypointsArray, setMultiDropWaypointsArray, index, pickList }) => {
  const [showSuggestions, setShowSuggestions] = useState(new Array(multiDropWaypointsArray[index].dropItems.length).fill(false));
  const [isDropItemsAutoFilled, setIsDropItemsAutoFilled] = useState(false);
  const [isDropItemsAutoFilledCleared, setIsDropItemsAutoFilledCleared] = useState(false);
  const [wholePickList, setWholePickList] = useState([]);

  // useEffect(() => {
  //   let pickListCopy = JSON.parse(JSON.stringify(pickList)).filter(pl => pl.detail !== '');

  //   if (pickListCopy.length > 0) {
  //     setWholePickList(pickListCopy.map(pl => pl.detail));
  //   }
  // }, [pickList]);

  useEffect(() => {
    let pickListCopy = JSON.parse(JSON.stringify(pickList)).filter(pl => pl.detail !== '');

    if (pickListCopy.length > 0) {
      setWholePickList(pickListCopy.map(pl => pl.detail));
    }

    if (isDropItemsAutoFilled) {
      setIsDropItemsAutoFilledCleared(false);
      let restDropItems = multiDropWaypointsArray.map((dwa, i) => {
        if (i !== index) {
          return dwa.dropItems.filter(item => item.detail !== '');
        } else {
          return [];
        }
      });

      let chooseDrop = [];
      if (pickListCopy.length > 0) {
        if (restDropItems.length > 1) {
          pickListCopy.map(item => {
            let count = item.quantity;
            restDropItems.map((riArray, i) => {
              if (riArray.length > 0) {
                riArray.map(it => {
                  if (item.detail === it.detail) {
                    count -= it.quantity;
                  }
                });
              }
            });
            if (count > 0) {
              chooseDrop.push({ quantity: count, detail: item.detail });
            }
          });
          setMultiDropWaypointsArray(
            multiDropWaypointsArray.map((ar, i) =>
              i === index
                ? {
                    ...multiDropWaypointsArray[index],
                    dropItems: chooseDrop.length > 0 ? chooseDrop : [{ quantity: 1, detail: '' }]
                  }
                : ar
            )
          );
        } else if ((restDropItems.length = 1)) {
          setMultiDropWaypointsArray(
            multiDropWaypointsArray.map((ar, i) =>
              i === index
                ? {
                    ...multiDropWaypointsArray[index],
                    dropItems: pickListCopy
                  }
                : ar
            )
          );
        }
      }
      setTimeout(() => {
        setIsDropItemsAutoFilled(false);
      }, 500);
    }
  }, [pickList, isDropItemsAutoFilled]);

  useEffect(() => {
    if (isDropItemsAutoFilledCleared) {
      setIsDropItemsAutoFilled(false);
      setMultiDropWaypointsArray(
        multiDropWaypointsArray.map((ar, i) =>
          i === index
            ? {
                ...multiDropWaypointsArray[index],
                dropItems: [{ quantity: 1, detail: '' }]
              }
            : ar
        )
      );
    }
  }, [isDropItemsAutoFilledCleared]);

  const selectItem = (e, indexItem, indexAutofill) => {
    setMultiDropWaypointsArray(
      multiDropWaypointsArray.map((ar, i) =>
        i === index
          ? {
              ...multiDropWaypointsArray[index],
              dropItems: multiDropWaypointsArray[index].dropItems.map((dp, j) => (j === indexItem ? { quantity: dp.quantity, detail: wholePickList[indexAutofill] } : dp))
            }
          : ar
      )
    );
    setShowSuggestions(showSuggestions.map((suggestion, i) => (i === indexItem ? false : suggestion)));
  };

  return (
    <>
      <Row form={true} style={{ paddingTop: 0 }}>
        <Col style={{ textAlign: 'left' }}>
          <Label for="LineItems">{title}</Label>
        </Col>
      </Row>
      {index + 1 === multiDropWaypointsArray.length && (
        <Row form={true} style={{ paddingTop: 0 }}>
          <FormGroup check className="mt-3" style={{ marginLeft: '15px', marginBottom: '10px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="round-trip-check"
                value={isDropItemsAutoFilled}
                checked={isDropItemsAutoFilled}
                onClick={e => {
                  setIsDropItemsAutoFilled(!isDropItemsAutoFilled);
                }}
              />
              <span className="form-check-sign" />
              Autofill Items
            </Label>
          </FormGroup>

          <FormGroup check className="mt-3" style={{ marginLeft: '15px', marginBottom: '10px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="round-trip-check"
                value={isDropItemsAutoFilledCleared}
                checked={isDropItemsAutoFilledCleared}
                onClick={e => {
                  setIsDropItemsAutoFilledCleared(!isDropItemsAutoFilledCleared);
                }}
              />
              <span className="form-check-sign" />
              Clear
            </Label>
          </FormGroup>
        </Row>
      )}
      {multiDropWaypointsArray[index].dropItems.length > 0 &&
        multiDropWaypointsArray[index].dropItems.map((item, indexItem) => {
          return (
            <>
              <Row id={'lineItem-' + indexItem}>
                <Col sm="1" style={{ textAlign: 'center' }}>
                  <Label for="LineItems" style={{ marginTop: '8px' }}>
                    Quantity
                  </Label>
                </Col>
                <Col sm="2">
                  <FormGroup style={{ width: '90%' }}>
                    <Input
                      placeholder="1"
                      onChange={e => {
                        setMultiDropWaypointsArray(
                          multiDropWaypointsArray.map((ar, i) =>
                            i === index
                              ? {
                                  ...multiDropWaypointsArray[index],
                                  dropItems: multiDropWaypointsArray[index].dropItems.map((dp, j) =>
                                    j === indexItem ? { quantity: Number(e.target.value), detail: dp.detail } : dp
                                  )
                                }
                              : ar
                          )
                        );
                      }}
                      type="number"
                      min="1"
                      style={{ WebkitAppearance: 'none' }}
                      value={item.quantity}
                    />
                  </FormGroup>
                </Col>
                <Col sm="1" style={{ textAlign: 'left' }}>
                  <Label for="pickupItems" style={{ marginTop: '8px' }}>
                    Item Detail
                  </Label>
                </Col>
                <Col sm="6">
                  <FormGroup style={{ width: '100%' }}>
                    <ListGroup>
                      <Input
                        placeholder="ie. name, description..., etc"
                        onChange={e => {
                          setMultiDropWaypointsArray(
                            multiDropWaypointsArray.map((ar, i) =>
                              i === index
                                ? {
                                    ...multiDropWaypointsArray[index],
                                    dropItems: multiDropWaypointsArray[index].dropItems.map((dp, j) => (j === indexItem ? { quantity: dp.quantity, detail: e.target.value } : dp))
                                  }
                                : ar
                            )
                          );
                        }}
                        type="text"
                        style={{ WebkitAppearance: 'none' }}
                        value={item.detail}
                        onClick={e => {
                          if (wholePickList.length > 0) {
                            setShowSuggestions(showSuggestions.map((suggestion, i) => (i === indexItem ? true : false)));
                          } else {
                            setShowSuggestions(new Array(multiDropWaypointsArray[index].dropItems.length).fill(false));
                          }
                        }}
                      />
                      {title === 'Drop off Item(s)' && showSuggestions[indexItem]
                        ? wholePickList.map((pick, indexAutofill) => {
                            return (
                              <ListGroupItem
                                style={{ backgroundColor: '#1e1e2b', border: 'none', color: '#cbcac9' }}
                                key={indexAutofill}
                                onMouseOver={e => (e.target.style.background = 'linear-gradient(90deg, hsla(218, 100%, 42%, 0.6) 0%, hsla(0, 0%, 98%, 1) 200%)')}
                                onMouseLeave={e => (e.target.style.background = '#1e1e2b')}
                                onClick={e => selectItem(e, indexItem, indexAutofill)}
                              >
                                {pick}
                              </ListGroupItem>
                            );
                          })
                        : null}
                    </ListGroup>
                  </FormGroup>

                  <div hidden={!multiDropWaypointsArray[index].dropItemsError[indexItem]}>
                    <p style={{ color: 'red', marginTop: '4px', marginBottom: '10px' }}>Detail field is required </p>
                  </div>
                </Col>

                <Col sm="1">
                  {/* <div style={{ opacity: 0 }}>Q</div> */}
                  <div style={{ textAlign: 'right', verticalAlign: 'middle', marginTop: '6px' }}>
                    <img
                      alt="add"
                      style={{ cursor: 'pointer', width: 25, height: 25, verticalAlign: 'middle' }}
                      src={AddButton}
                      onClick={() => {
                        // setItemsErrorArray([...itemsErrorArray, false]);
                        setMultiDropWaypointsArray(
                          multiDropWaypointsArray.map((ar, i) =>
                            i === index
                              ? {
                                  ...multiDropWaypointsArray[index],
                                  dropItems: [...multiDropWaypointsArray[index].dropItems, { quantity: 1, detail: '' }],
                                  dropItemsError: [...multiDropWaypointsArray[index].dropItemsError, false]
                                }
                              : ar
                          )
                        );
                        setShowSuggestions([...showSuggestions, false]);
                      }}
                    />
                  </div>
                </Col>
                <Col sm="1">
                  {/* <div style={{ opacity: 0 }}>Q</div> */}
                  <div style={{ textAlign: 'left', verticalAlign: 'middle', marginTop: '9px' }}>
                    {multiDropWaypointsArray[index].dropItems.length > 1 && (
                      <img
                        alt="delete"
                        style={{ cursor: 'pointer', width: 20, height: 20, verticalAlign: 'middle' }}
                        src={DeleteButton}
                        onClick={() => {
                          setMultiDropWaypointsArray(
                            multiDropWaypointsArray.map((ar, i) =>
                              i === index
                                ? {
                                    ...multiDropWaypointsArray[index],
                                    dropItems: multiDropWaypointsArray[index].dropItems.filter((dp, j) => {
                                      return j !== indexItem;
                                    }),
                                    dropItemsError: multiDropWaypointsArray[index].dropItemsError.filter((de, j) => {
                                      return j !== indexItem;
                                    })
                                  }
                                : ar
                            )
                          );
                          //   setItemsErrorArray(
                          //     itemsErrorArray.filter((item, i) => {
                          //       return i !== index;
                          //     })
                          //   );
                          setShowSuggestions(
                            showSuggestions.filter((suggestion, i) => {
                              return i !== indexItem;
                            })
                          );
                        }}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </>
          );
        })}
    </>
  );
};

export default LineItemsMultipleDrop;
