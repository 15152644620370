import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Card, CardTitle, Collapse } from 'reactstrap';
import MerchantSelections from './MerchantSelections';
import DirectTypeSelections from './DirectTypeSelections';
import RegularCreation from './RegularCreation';
import RegularConfirmPage from './RegularConfirmPage';
import RoutedCreation from './RoutedCreation';
import RoutedConfirmPage from './RoutedConfirmPage';
import MultiDropCreation from './MultiDropCreation';
import MultiDropConfirmPage from './MultiDropConfirmPage';
import ItemizedMultiDropCreation from './ItemizedMultiDropCreation';
import ItemizedMultiDropConfirmPage from './ItemizedMultiDropConfirmPage';
import GophrBillingForm from './GophrBillingForm';

import * as shipment_urls from '../../../urls/api-shipments/merchant';
import * as user_urls from '../../../urls/api-users/admin';

const CreateDeliveryForMerchant = () => {
  const ACCESS_TOKEN = 'Bearer ' + window.localStorage.getItem('access_token');
  const jwtDecode = require('jwt-decode');
  const DECODED_ACCESS = jwtDecode(ACCESS_TOKEN);
  const SUB = DECODED_ACCESS.sub;

  const [selectedType, setSelectedType] = useState('');
  const [selectedStore, setSelectedStore] = useState('');
  const [showFormSelection, setShowFormSelection] = useState(true);
  const [showForm1, setShowForm1] = useState(false);
  const [showForm2, setShowForm2] = useState(false);
  const [showForm3, setShowForm3] = useState(false);
  const [pickupInfo, setPickupInfo] = useState({});
  const [pickupInstruction, setPickupInstruction] = useState('');
  const [dropoffInfo, setDropoffInfo] = useState({});
  const [dropoffInstruction, setDropoffInstruction] = useState('');

  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledFor, setScheduledFor] = useState(null);
  const [isExpedited, setIsExpedited] = useState(false);
  const [isSuperExpedited, setIsSuperExpedited] = useState(false);
  const [sellsAlcohol, setSellsAlcohol] = useState(false);
  const [containsAlcohol, setContainsAlcohol] = useState(false);
  const [isRoundTrip, setIsRoundTrip] = useState(false);
  const [vehicleType, setVehicleType] = useState('');
  const [numPeople, setNumPeople] = useState(null);
  const [weight, setWeight] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [orderStatus, setOrderStatus] = useState('awaiting_driver');
  const [shipmentStatus, setShipmentStatus] = useState('shipment_ready_for_driver');
  const [price, setPrice] = useState(null);
  const [distance, setDistance] = useState('');
  const [route, setRoute] = useState({});
  const [storeMarketId, setStoreMarketId] = useState('b641bc13-8f56-4f4b-b1ea-c1f833b982bd');

  const [vendorLat, setVendorLat] = useState('');
  const [vendorLong, setVendorLong] = useState('');
  const [storeAddress1, setStoreAddress1] = useState('');
  const [storeAddress2, setStoreAddress2] = useState('');
  const [storeCity, setStoreCity] = useState('');
  const [storeCountry, setStoreCountry] = useState('');
  const [storeId, setStoreId] = useState('');
  const [storeFirstName, setStoreFirstName] = useState(null);
  const [storeLastName, setStoreLastName] = useState(null);
  const [storeName, setStoreName] = useState('');
  const [storeLat, setStoreLat] = useState(null);
  const [storeLng, setStoreLng] = useState(null);
  const [storePhone, setStorePhone] = useState(null);
  const [storeState, setStoreState] = useState('');
  const [storeZip, setStoreZip] = useState('');
  const [storeAddress, setStoreAddress] = useState('');
  const [merchantOrderID, setMerchantOrderID] = useState(null);
  const [merchantAuth0ID, setMerchantAuth0ID] = useState('');

  const [defaultItems, setDefaultItems] = useState([{ quantity: 1, detail: '' }]);

  const [errorSubmit, setErrorSubmit] = useState({});
  const [errorMessageDistance, setErrorMessageDistance] = useState({ errorMessage: '', errorCode: 0 });
  const [errorMessageSubmit, setErrorMessageSubmit] = useState({ errorMessage: '', errorCode: 0 });

  //Routed
  const [pickupItems, setPickupItems] = useState([{ quantity: 1, detail: '' }]);
  const [dropoffItems, setDropoffItems] = useState([{ quantity: 1, detail: '' }]);
  const [waypointPickupItems, setWaypointPickupItems] = useState([{ quantity: 1, detail: '' }]);
  const [waypointDropoffItems, setWaypointDropoffItems] = useState([{ quantity: 1, detail: '' }]);
  const [waypointInfo, setWaypointInfo] = useState({});
  const [waypointPickupInstruction, setWaypointPickupInstruction] = useState('');
  const [waypointDropoffInstruction, setWaypointDropoffInstruction] = useState('');
  const [isWaypointPickup, setIsWaypointPickup] = useState(false);
  const [isWaypointDropoff, setIsWaypointDropoff] = useState(false);

  //MultiDrop
  const [multiDropWaypointsArray, setMultiDropWaypointsArray] = useState([
    {
      waypointFirstname: '',
      waypointLastname: '',
      waypointPhone: '',
      waypointAddress1: null,
      waypointAddress2: null,
      waypointFullAddress: '',
      waypointLat: '',
      waypointLng: '',
      waypointCity: '',
      waypointState: '',
      waypointCountry: '',
      waypointZip: '',
      waypointDropoffInstruction: '',
      waypointBusinessName: '',
      waypointCustomerSuggestions: [],
      isWaypointAutoFilled: false,
      dropItems: [{ quantity: 1, detail: '' }],
      dropItemsError: [false]
    }
  ]);
  const [multiDropWaypointsErrors, setMultiDropWaypointsErrors] = useState([
    {
      waypointFirstname: false,
      waypointLastname: false,
      waypointPhone: false,
      waypointAddress: false
    }
  ]);
  const [multiDropsInfo, setMultiDropsInfo] = useState([]);

  //! Itemized infinite drops price
  const [perItemPrice, setPerItemPrice] = useState(null);
  const [perStopPrice, setPerStopPrice] = useState(null);
  const [extraPerItemPrice, setExtraPerItemPrice] = useState(null);
  const [freeItemCount, setFreeItemCount] = useState(null);

  //! Equipments and Tools
  const [isForkLift, setIsForkLift] = useState(false);
  const [isDolly, setIsDolly] = useState(false);
  const [isHandLoadUnload, setIsHandLoadUnload] = useState(false);
  const [isHazmat, setIsHazmat] = useState(false);
  const [isHotBag, setIsHotBag] = useState(false);
  const [isCooler, setIsCooler] = useState(false);
  const [isTWICCard, setIsTWICCard] = useState(false);
  const [isLiftgate, setIsLiftgate] = useState(false);
  const [isPalletJack, setIsPalletJack] = useState(false);
  const [isWeatherProtection, setIsWeatherProtection] = useState(false);
  const [isMedical, setIsMedical] = useState(false);

  const [priceRangeLow, setPriceRangeLow] = useState(null);
  const [priceRangeHigh, setPriceRangeHigh] = useState(null);
  const [standardPriceRangeLow, setStandardPriceRangeLow] = useState('');
  const [standardPriceRangeHigh, setStandardPriceRangeHigh] = useState('');
  const [expeditedPriceRangeLow, setExpeditedPriceRangeLow] = useState('');
  const [expeditedPriceRangeHigh, setExpeditedPriceRangeHigh] = useState('');
  const [superExpeditedPriceRangeLow, setSuperExpeditedPriceRangeLow] = useState('');
  const [superExpeditedPriceRangeHigh, setSuperExpeditedPriceRangeHigh] = useState('');

  //! Invoice Billing for Gophr Store
  const [isInvoice, setIsInvoice] = useState(false);
  const [isInvoiceDisabled, setIsInvoiceDisabled] = useState(false);
  const [billingInfo, setBillingInfo] = useState({ first_name: '', last_name: '', phone: '', email: '' });
  const [billingErrors, setBillingErrors] = useState({
    billingFirstname: false,
    billingLastname: false,
    billingPhone: false,
    billingEmail: false
  });

  //! On behalf of merchant
  const [placedByName, setPlacedByName] = useState('');

  function fetchAdminFullName() {
    axios({
      method: 'post',
      headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token'), 'content-type': 'application/json' },
      url: user_urls.hasuraHost,
      data: {
        query: '{Users (where: {auth0_id: {_eq: "' + SUB + '"}}) {firstname lastname}}'
      }
    })
      .then(response => {
        if (response.data.errors) {
          console.log(response.data.errors);
        } else {
          setPlacedByName(response.data.data.Users[0].firstname + ' ' + response.data.data.Users[0].lastname);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchAdminFullName();
  }, []);

  useEffect(() => {
    if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler') {
      setIsInvoice(false);
      setIsInvoiceDisabled(true);
    } else {
      setIsInvoiceDisabled(false);
    }
  }, [vehicleType]);

  useEffect(() => {
    if (selectedStore && selectedType) {
      setShowForm1(true);
      setShowForm3(false);
    }
  }, [selectedStore, selectedType]);

  useEffect(() => {
    if (selectedStore) {
      setVendorLat(selectedStore.latitude.toString());
      setVendorLong(selectedStore.longitude.toString());
      setStoreAddress1(selectedStore.address_1);
      setStoreAddress2(selectedStore.address_2);
      setStoreCity(selectedStore.city);
      setStoreCountry(selectedStore.country);
      setStoreName(selectedStore.name);
      setStoreId(selectedStore.store_id);
      setStoreZip(selectedStore.zip.toString());
      setStoreFirstName(selectedStore.StoreOwnerships[0].firstname);
      setStoreLastName(selectedStore.StoreOwnerships[0].lastname);
      setStorePhone(selectedStore.phone);
      setStoreState(selectedStore.state);
      setStoreAddress(selectedStore.address_1 + ', ' + selectedStore.city + ', ' + selectedStore.state + ' ' + selectedStore.zip + ', ' + selectedStore.country);
      setStoreLat(selectedStore.latitude.toString());
      setStoreLng(selectedStore.longitude.toString());
      setSellsAlcohol(selectedStore.sells_alcohol);
      setMerchantAuth0ID(selectedStore.StoreOwnerships[0].merchant_id);
      setStoreMarketId(selectedStore.market_id);
      setIsInvoice(false);
    }
  }, [selectedStore]);

  const validatePhone = phone => {
    var phoneRex = /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{10}))/;

    if (phone.replace(/[^0-9]/g, '').length > 9 && phone.replace(/[^0-9]/g, '').length < 14) {
      return phoneRex.test(String(phone));
    }
  };

  // function that returns true if value is a valid email, false otherwise
  const validateEmail = email => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(String(email.toLowerCase().trim()));
  };

  const checkInvoice = () => {
    let updatedBillingErrors = billingErrors;

    if (billingInfo.first_name) {
      updatedBillingErrors.billingFirstname = false;
    } else {
      updatedBillingErrors.billingFirstname = true;
    }
    if (billingInfo.last_name) {
      updatedBillingErrors.billingLastname = false;
    } else {
      updatedBillingErrors.billingLastname = true;
    }
    if (validatePhone(billingInfo.phone)) {
      updatedBillingErrors.billingPhone = false;
    } else {
      updatedBillingErrors.billingPhone = true;
    }
    if (validateEmail(billingInfo.email)) {
      updatedBillingErrors.billingEmail = false;
    } else {
      updatedBillingErrors.billingEmail = true;
    }
    setBillingErrors(updatedBillingErrors);
    return Object.values(billingErrors).includes(true);
  };

  const notify = (place, errorType, customMessage) => {
    var type = 'info';
    var options = {
      place: place,
      message: (
        <div>
          <div>{errorType === null ? customMessage : errorType === 'distance' ? errorMessageDistance : errorMessageSubmit}</div>
        </div>
      ),
      type: type,
      icon: 'tim-icons icon-alert-circle-exc',
      autoDismiss: 10
    };
    React.forwardRef().notificationAlert.notificationAlert(options);
  };

  const notifySuccess = (place, message) => {
    var type = 'success';
    var options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: 'tim-icons icon-check-2',
      autoDismiss: 7
    };
    React.forwardRef().notificationAlert.notificationAlert(options);
  };

  const handleErrorSubmit = error => {
    setErrorSubmit(error);
  };

  const handleBack = () => {
    setShowFormSelection(true);
    setShowForm1(true);
    setShowForm2(true);
    setShowForm3(false);
  };

  const handleConfirmRegular = (shipmentStatus, orderStatus, isScheduled) => {
    let url;
    let scheduledForData = null;
    if (dropoffInstruction === null || dropoffInstruction === '') {
      setDropoffInstruction('N/A');
    }
    if (scheduledFor) {
      scheduledForData = scheduledFor.toISOString();
    }
    if (isScheduled) {
      url = shipment_urls.createScheduledShipment;
    } else {
      if (shipmentStatus === 'shipment_saved') {
        url = shipment_urls.createSavedShipment;
      } else {
        url = shipment_urls.createShipment;
      }
    }
    let pickOrder = {
      store_id: storeId,
      // Pickup location
      location_blob: {
        name: pickupInfo.name,
        first_name: pickupInfo.first_name,
        last_name: pickupInfo.last_name,
        phone: pickupInfo.phone,
        address: pickupInfo.address,
        address_1: pickupInfo.address_1,
        address_2: pickupInfo.address_2,
        city: pickupInfo.city,
        zip: pickupInfo.zip,
        state: pickupInfo.state,
        country: 'US',
        business_name: pickupInfo.business_name ? pickupInfo.business_name : '',
        lat: pickupInfo.lat,
        lng: pickupInfo.lng
      },
      weight: weight,
      description: JSON.stringify(defaultItems),
      pick_up_instructions: pickupInstruction,
      actions: {
        pick_up: defaultItems
      }
    };
    let dropOrder = {
      store_id: storeId,
      // Dropoff location
      location_blob: {
        name: dropoffInfo.name,
        first_name: dropoffInfo.first_name,
        last_name: dropoffInfo.last_name,
        phone: dropoffInfo.phone,
        address: dropoffInfo.address,
        address_1: dropoffInfo.address_1,
        address_2: dropoffInfo.address_2,
        city: dropoffInfo.city,
        zip: dropoffInfo.zip,
        state: dropoffInfo.state,
        country: 'US',
        business_name: dropoffInfo.business_name ? dropoffInfo.business_name : '',
        lat: dropoffInfo.lat,
        lng: dropoffInfo.lng
      },
      weight: weight,
      description: JSON.stringify(defaultItems),
      drop_off_instructions: dropoffInstruction,
      actions: {
        drop_off: defaultItems
      }
    };

    let ordersData = [pickOrder, dropOrder];

    let itemNum = 0;
    defaultItems.map(it => {
      itemNum += it.quantity;
    });
    let data = {
      status: shipmentStatus,
      market_id: storeMarketId,
      scheduled_for: scheduledForData,
      weight: weight,
      vehicle_type: vehicleType
        .toLowerCase()
        .split(' ')
        .join('_'),
      is_expedited: isExpedited.toString(),
      is_super_expedited: isSuperExpedited,
      num_of_drops: 1,
      is_roundtrip: isRoundTrip,
      contains_alcohol: containsAlcohol,
      distance: distance.toString(),
      quote: price.toString(),
      quoted_time: route.duration / 60 / 60,
      item_count: itemNum,
      num_of_gophrs: numPeople,
      created_by: merchantAuth0ID,
      is_fork_lift: isForkLift,
      is_dolly: isDolly,
      is_hazmat: isHazmat,
      is_pallet_jack: isPalletJack,
      is_twic_card: isTWICCard,
      is_liftgate: isLiftgate,
      is_hand_loading: isHandLoadUnload,
      is_weather_protection: isWeatherProtection,
      is_medical: isMedical,
      customer_blob: {
        first_name: storeFirstName,
        last_name: storeLastName,
        store_name: storeName,
        phone: storePhone.trim(),
        address: storeAddress,
        address_1: storeAddress1,
        address_2: null,
        city: storeCity,
        state: storeState,
        zip: storeZip,
        country: storeCountry,
        lat: parseFloat(storeLat),
        lng: parseFloat(storeLng)
      },
      address_1: storeAddress1,
      address_2: null,
      city: storeCity,
      zip: storeZip,
      state: storeState,
      country: storeCountry,
      lat: parseFloat(storeLat),
      lng: parseFloat(storeLng),
      pick_up_instructions: pickupInstruction,
      drop_off_instructions: dropoffInstruction,
      description: JSON.stringify(defaultItems),
      merchant_order_id: merchantOrderID == '' || merchantOrderID == null ? null : merchantOrderID,
      billed_route: route,
      actions: {},
      orders: ordersData,
      is_invoice: isInvoice,
      invoice_to: billingInfo,
      is_onBehalf: true,
      placed_by: placedByName
    };

    console.log('data ', data);
    axios({
      method: 'post',
      url: url,
      withCredentials: true,
      headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + window.localStorage.getItem('access_token') },
      data: data
    })
      .then(response => {
        if (shipmentStatus === 'shipment_ready_for_driver' && orderStatus === 'awaiting_driver') {
          // notifySuccess('tc', 'Shipment Created Successfully');
        } else {
          // notifySuccess('tc', 'Shipment Saved Successfully');
        }
        window.location.reload();
      })
      .catch(error => {
        console.log('error on creation: ', error.response);
        handleErrorSubmit({
          errorMessage: 'Something went wrong with your submission.  Please wait a few seconds, and try again.',
          errorCode: 10
        });
        notify('tc', 'submit');
      });
  };

  const handleConfirmRouted = (shipmentStatus, orderStatus, isScheduled) => {
    let url;
    let scheduledForData = null;
    if (dropoffInstruction === null || dropoffInstruction === '') {
      setDropoffInstruction('N/A');
    }
    if (scheduledFor) {
      scheduledForData = scheduledFor.toISOString();
    }
    if (isScheduled) {
      url = shipment_urls.createRoutedScheduledShipment;
    } else {
      if (shipmentStatus === 'shipment_saved') {
        url = shipment_urls.createRoutedSavedShipment;
      } else {
        url = shipment_urls.createRoutedShipment;
      }
    }
    let pickOrder = {
      store_id: storeId,
      // Pickup location
      location_blob: {
        name: pickupInfo.name,
        first_name: pickupInfo.first_name,
        last_name: pickupInfo.last_name,
        phone: pickupInfo.phone,
        address: pickupInfo.address,
        address_1: pickupInfo.address_1,
        address_2: pickupInfo.address_2,
        city: pickupInfo.city,
        zip: pickupInfo.zip,
        state: pickupInfo.state,
        country: 'US',
        business_name: pickupInfo.business_name ? pickupInfo.business_name : '',
        lat: pickupInfo.lat,
        lng: pickupInfo.lng
      },
      weight: weight,
      description: JSON.stringify(pickupItems),
      pick_up_instructions: pickupInstruction,
      actions: {
        pick_up: pickupItems
      }
    };
    let dropOrder = {
      store_id: storeId,
      // Dropoff location
      location_blob: {
        name: dropoffInfo.name,
        first_name: dropoffInfo.first_name,
        last_name: dropoffInfo.last_name,
        phone: dropoffInfo.phone,
        address: dropoffInfo.address,
        address_1: dropoffInfo.address_1,
        address_2: dropoffInfo.address_2,
        city: dropoffInfo.city,
        zip: dropoffInfo.zip,
        state: dropoffInfo.state,
        country: 'US',
        business_name: dropoffInfo.business_name ? dropoffInfo.business_name : '',
        lat: dropoffInfo.lat,
        lng: dropoffInfo.lng
      },
      weight: weight,

      description: JSON.stringify(dropoffItems),
      drop_off_instructions: dropoffInstruction,
      actions: {
        drop_off: dropoffItems
      }
    };
    let waypointPickOrder = {
      store_id: storeId,
      // Dropoff location
      location_blob: {
        name: waypointInfo.name,
        first_name: waypointInfo.first_name,
        last_name: waypointInfo.last_name,
        phone: waypointInfo.phone,
        address: waypointInfo.address,
        address_1: waypointInfo.address_1,
        address_2: waypointInfo.address_2,
        city: waypointInfo.city,
        zip: waypointInfo.zip,
        state: waypointInfo.state,
        country: 'US',
        business_name: waypointInfo.business_name ? waypointInfo.business_name : '',
        lat: waypointInfo.lat,
        lng: waypointInfo.lng
      },
      weight: weight,
      description: JSON.stringify(waypointPickupItems),
      pick_up_instructions: waypointPickupInstruction,
      actions: {
        pick_up: waypointPickupItems
      }
    };
    let waypointDropOrder = {
      store_id: storeId,
      // Dropoff location
      location_blob: {
        name: waypointInfo.name,
        first_name: waypointInfo.first_name,
        last_name: waypointInfo.last_name,
        phone: waypointInfo.phone,
        address: waypointInfo.address,
        address_1: waypointInfo.address_1,
        address_2: waypointInfo.address_2,
        city: waypointInfo.city,
        zip: waypointInfo.zip,
        state: waypointInfo.state,
        country: 'US',
        business_name: waypointInfo.business_name ? waypointInfo.business_name : '',
        lat: waypointInfo.lat,
        lng: waypointInfo.lng
      },
      weight: weight,
      description: JSON.stringify(waypointDropoffItems),
      drop_off_instructions: waypointDropoffInstruction,
      actions: {
        drop_off: waypointDropoffItems
      }
    };

    let waypointPickOrdersArray = [pickOrder, waypointPickOrder, dropOrder];
    let waypointDropOrdersArray = [pickOrder, waypointDropOrder, dropOrder];
    let waypointPickAndDropOrdersArray = [pickOrder, waypointDropOrder, waypointPickOrder, dropOrder];
    let ordersData;

    if (isWaypointPickup && isWaypointDropoff) {
      ordersData = waypointPickAndDropOrdersArray;
    } else if (isWaypointPickup && !isWaypointDropoff) {
      ordersData = waypointPickOrdersArray;
    } else if (!isWaypointPickup && isWaypointDropoff) {
      ordersData = waypointDropOrdersArray;
    }

    let itemNum = 0;
    pickupItems.map(it => {
      itemNum += it.quantity;
    });
    let data = {
      status: shipmentStatus,
      market_id: storeMarketId,
      scheduled_for: scheduledForData,
      weight: weight,
      vehicle_type: vehicleType
        .toLowerCase()
        .split(' ')
        .join('_'),
      is_expedited: isExpedited.toString(),
      is_super_expedited: isSuperExpedited,
      num_of_drops: isWaypointDropoff ? 2 : 1,
      is_roundtrip: isRoundTrip,
      contains_alcohol: containsAlcohol,
      distance: distance.toString(),
      quote: price.toString(),
      quoted_time: route.duration / 60 / 60,
      item_count: itemNum,
      num_of_gophrs: numPeople,
      created_by: merchantAuth0ID,
      is_fork_lift: isForkLift,
      is_dolly: isDolly,
      is_hazmat: isHazmat,
      is_pallet_jack: isPalletJack,
      is_twic_card: isTWICCard,
      is_liftgate: isLiftgate,
      is_hand_loading: isHandLoadUnload,
      is_weather_protection: isWeatherProtection,
      is_medical: isMedical,
      customer_blob: {
        first_name: storeFirstName,
        last_name: storeLastName,
        store_name: storeName,
        phone: storePhone.trim(),
        address: storeAddress,
        address_1: storeAddress1,
        address_2: null,
        city: storeCity,
        state: storeState,
        zip: storeZip,
        country: storeCountry,
        lat: parseFloat(storeLat),
        lng: parseFloat(storeLng)
      },
      address_1: storeAddress1,
      address_2: null,
      city: storeCity,
      zip: storeZip,
      state: storeState,
      country: storeCountry,
      lat: parseFloat(storeLat),
      lng: parseFloat(storeLng),
      pick_up_instructions: pickupInstruction,
      drop_off_instructions: dropoffInstruction,
      // description: description,
      description: 'A Routed Direct Shipment',
      merchant_order_id: merchantOrderID == '' || merchantOrderID == null ? null : merchantOrderID,
      billed_route: route,
      actions: {},
      orders: ordersData,
      is_invoice: isInvoice,
      invoice_to: billingInfo,
      is_onBehalf: true,
      placed_by: placedByName
    };

    console.log('data ', data);
    axios({
      method: 'post',
      url: url,
      withCredentials: true,
      headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + window.localStorage.getItem('access_token') },
      data: data
    })
      .then(response => {
        if (shipmentStatus === 'shipment_ready_for_driver' && orderStatus === 'awaiting_driver') {
          //notifySuccess('tc', 'Shipment Created Successfully');
        } else {
          // notifySuccess('tc', 'Shipment Saved Successfully');
        }
        window.location.reload();
      })
      .catch(error => {
        console.log('error on creation: ', error.response);
        handleErrorSubmit({
          errorMessage: 'Something went wrong with your submission.  Please wait a few seconds, and try again.',
          errorCode: 10
        });
        notify('tc', 'submit');
      });
  };

  const handleConfirmMultiDrop = (shipmentStatus, orderStatus, isScheduled) => {
    let url;
    let scheduledForData = null;
    if (dropoffInstruction === null || dropoffInstruction === '') {
      setDropoffInstruction('N/A');
    }
    if (scheduledFor) {
      scheduledForData = scheduledFor.toISOString();
    }
    if (isScheduled) {
      url = shipment_urls.createMultidropScheduledShipment;
    } else {
      if (shipmentStatus === 'shipment_saved') {
        url = shipment_urls.createMultidropSavedShipment;
      } else {
        url = shipment_urls.createMultidropShipment;
      }
    }
    let pickOrder = {
      store_id: storeId,
      // Pickup location
      location_blob: {
        name: pickupInfo.name,
        first_name: pickupInfo.first_name,
        last_name: pickupInfo.last_name,
        phone: pickupInfo.phone,
        address: pickupInfo.address,
        address_1: pickupInfo.address_1,
        address_2: pickupInfo.address_2,
        city: pickupInfo.city,
        zip: pickupInfo.zip,
        state: pickupInfo.state,
        country: 'US',
        business_name: pickupInfo.business_name ? pickupInfo.business_name : '',
        lat: pickupInfo.lat,
        lng: pickupInfo.lng
      },
      weight: weight,
      description: JSON.stringify(pickupItems),
      pick_up_instructions: pickupInstruction,
      actions: {
        pick_up: pickupItems
      }
    };

    let multiDropOrders = multiDropsInfo.map((drop, index) => {
      return {
        store_id: storeId,
        location_blob: {
          ...drop
        },
        weight: weight,
        description: JSON.stringify(multiDropWaypointsArray[index].dropItems),
        drop_off_instructions: multiDropWaypointsArray[index].waypointDropoffInstruction,
        actions: {
          drop_off: multiDropWaypointsArray[index].dropItems
        }
      };
    });

    let ordersData = [pickOrder, ...multiDropOrders];

    let itemNum = 0;
    pickupItems.map(it => {
      itemNum += it.quantity;
    });
    let data = {
      status: shipmentStatus,
      market_id: storeMarketId,
      scheduled_for: scheduledForData,
      weight: weight,
      vehicle_type: vehicleType
        .toLowerCase()
        .split(' ')
        .join('_'),
      is_expedited: isExpedited.toString(),
      is_super_expedited: isSuperExpedited,
      num_of_drops: multiDropWaypointsArray.length,
      is_roundtrip: isRoundTrip,
      contains_alcohol: containsAlcohol,
      distance: distance.toString(),
      quote: price.toString(),
      quoted_time: route.duration / 60 / 60,
      // is_itemized: perItemPrice && multiDropWaypointsArray.length > 1,
      // per_item_price: perItemPrice,
      item_count: itemNum,
      num_of_gophrs: numPeople,
      created_by: merchantAuth0ID,
      is_fork_lift: isForkLift,
      is_dolly: isDolly,
      is_hazmat: isHazmat,
      is_pallet_jack: isPalletJack,
      is_twic_card: isTWICCard,
      is_liftgate: isLiftgate,
      is_hand_loading: isHandLoadUnload,
      is_weather_protection: isWeatherProtection,
      is_medical: isMedical,
      customer_blob: {
        first_name: storeFirstName,
        last_name: storeLastName,
        store_name: storeName,
        phone: storePhone.trim(),
        address: storeAddress,
        address_1: storeAddress1,
        address_2: null,
        city: storeCity,
        state: storeState,
        zip: storeZip,
        country: storeCountry,
        lat: parseFloat(storeLat),
        lng: parseFloat(storeLng)
      },
      address_1: storeAddress1,
      address_2: null,
      city: storeCity,
      zip: storeZip,
      state: storeState,
      country: storeCountry,
      lat: parseFloat(storeLat),
      lng: parseFloat(storeLng),
      pick_up_instructions: pickupInstruction,
      drop_off_instructions: dropoffInstruction,
      // description: description,
      description: 'A Routed Direct Shipment',
      merchant_order_id: merchantOrderID == '' || merchantOrderID == null ? null : merchantOrderID,
      billed_route: route,
      actions: {},
      orders: ordersData,
      is_invoice: isInvoice,
      invoice_to: billingInfo,
      is_onBehalf: true,
      placed_by: placedByName
    };

    console.log('data ', data);
    axios({
      method: 'post',
      url: url,
      withCredentials: true,
      headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + window.localStorage.getItem('access_token') },
      data: data
    })
      .then(response => {
        if (shipmentStatus === 'shipment_ready_for_driver' && orderStatus === 'awaiting_driver') {
          //notifySuccess('tc', 'Shipment Created Successfully');
        } else {
          // notifySuccess('tc', 'Shipment Saved Successfully');
        }
        window.location.reload();
      })
      .catch(error => {
        console.log('error on creation: ', error.response);
        handleErrorSubmit({
          errorMessage: 'Something went wrong with your submission.  Please wait a few seconds, and try again.',
          errorCode: 10
        });
        notify('tc', 'submit');
      });
  };

  const handleConfirmItemizedMultiDrop = (shipmentStatus, orderStatus, isScheduled) => {
    let url;
    let scheduledForData = null;
    if (dropoffInstruction === null || dropoffInstruction === '') {
      setDropoffInstruction('N/A');
    }
    if (scheduledFor) {
      scheduledForData = scheduledFor.toISOString();
    }
    if (isScheduled) {
      url = shipment_urls.createMultidropScheduledShipment;
    } else {
      if (shipmentStatus === 'shipment_saved') {
        url = shipment_urls.createMultidropSavedShipment;
      } else {
        url = shipment_urls.createMultidropShipment;
      }
    }
    let pickOrder = {
      store_id: storeId,
      // Pickup location
      location_blob: {
        name: pickupInfo.name,
        first_name: pickupInfo.first_name,
        last_name: pickupInfo.last_name,
        phone: pickupInfo.phone,
        address: pickupInfo.address,
        address_1: pickupInfo.address_1,
        address_2: pickupInfo.address_2,
        city: pickupInfo.city,
        zip: pickupInfo.zip,
        state: pickupInfo.state,
        country: 'US',
        business_name: pickupInfo.business_name ? pickupInfo.business_name : '',
        lat: pickupInfo.lat,
        lng: pickupInfo.lng
      },
      weight: 50,
      description: JSON.stringify(pickupItems),
      pick_up_instructions: pickupInstruction,
      actions: {
        pick_up: pickupItems
      }
    };

    let multiDropOrders = multiDropsInfo.map((drop, index) => {
      return {
        store_id: storeId,
        location_blob: {
          ...drop
        },
        weight: 50,
        description: JSON.stringify(multiDropWaypointsArray[index].dropItems),
        drop_off_instructions: multiDropWaypointsArray[index].waypointDropoffInstruction,
        actions: {
          drop_off: multiDropWaypointsArray[index].dropItems
        }
      };
    });

    let ordersData = [pickOrder, ...multiDropOrders];

    let itemNum = 0;
    pickupItems.map(it => {
      itemNum += it.quantity;
    });

    let itemsCountPerStop = [];
    multiDropWaypointsArray.map(ar => {
      let count = 0;
      ar.dropItems.map(it => (count += it.quantity));
      itemsCountPerStop.push(count);
    });

    let data = {
      status: shipmentStatus,
      market_id: storeMarketId,
      scheduled_for: scheduledForData,
      weight: 50,
      vehicle_type: vehicleType
        .toLowerCase()
        .split(' ')
        .join('_'),
      is_expedited: isExpedited.toString(),
      is_super_expedited: isSuperExpedited,
      num_of_drops: multiDropWaypointsArray.length,
      is_roundtrip: isRoundTrip,
      contains_alcohol: containsAlcohol,
      distance: distance.toString(),
      quote: price.toString(),
      quoted_time: route.duration / 60 / 60,
      is_itemized: true,
      per_item_price: perItemPrice,
      per_stop_price: perStopPrice,
      extra_price_per_item: extraPerItemPrice,
      free_items_count_per_stop: freeItemCount,
      items_count_per_stop_array: itemsCountPerStop,
      item_count: itemNum,
      num_of_gophrs: numPeople,
      created_by: merchantAuth0ID,
      is_fork_lift: isForkLift,
      is_dolly: isDolly,
      is_hazmat: isHazmat,
      is_pallet_jack: isPalletJack,
      is_twic_card: isTWICCard,
      is_liftgate: isLiftgate,
      is_hand_loading: isHandLoadUnload,
      is_weather_protection: isWeatherProtection,
      is_medical: isMedical,
      customer_blob: {
        first_name: storeFirstName,
        last_name: storeLastName,
        store_name: storeName,
        phone: storePhone.trim(),
        address: storeAddress,
        address_1: storeAddress1,
        address_2: null,
        city: storeCity,
        state: storeState,
        zip: storeZip,
        country: storeCountry,
        lat: parseFloat(storeLat),
        lng: parseFloat(storeLng)
      },
      address_1: storeAddress1,
      address_2: null,
      city: storeCity,
      zip: storeZip,
      state: storeState,
      country: storeCountry,
      lat: parseFloat(storeLat),
      lng: parseFloat(storeLng),
      pick_up_instructions: pickupInstruction,
      drop_off_instructions: dropoffInstruction,
      // description: description,
      description: 'A Routed Direct Shipment',
      merchant_order_id: merchantOrderID == '' || merchantOrderID == null ? null : merchantOrderID,
      billed_route: route,
      actions: {},
      orders: ordersData,
      is_invoice: isInvoice,
      invoice_to: billingInfo,
      is_onBehalf: true,
      placed_by: placedByName
    };

    console.log('data ', data);
    axios({
      method: 'post',
      url: url,
      withCredentials: true,
      headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + window.localStorage.getItem('access_token') },
      data: data
    })
      .then(response => {
        if (shipmentStatus === 'shipment_ready_for_driver' && orderStatus === 'awaiting_driver') {
          //notifySuccess('tc', 'Shipment Created Successfully');
        } else {
          // notifySuccess('tc', 'Shipment Saved Successfully');
        }
        window.location.reload();
      })
      .catch(error => {
        console.log('error on creation: ', error.response);
        handleErrorSubmit({
          errorMessage: 'Something went wrong with your submission.  Please wait a few seconds, and try again.',
          errorCode: 10
        });
        notify('tc', 'submit');
      });
  };

  return (
    <div className="content">
      <Collapse id="collapse" isOpen={showFormSelection}>
        <Card style={{ backgroundColor: '#27293d', color: '#fff' }} className="card-form">
          <form className="dropship-container" autoComplete="off" style={{ padding: `15px` }}>
            <CardTitle id="form-top" tag="h2" style={{ padding: 20 }}>
              Create a Delivery
            </CardTitle>
            <div hidden={true}>
              <p style={{ color: 'red', marginTop: '4px', paddingBottom: 20 }}>Please fix the problems on this form</p>
            </div>
            <div style={{ display: 'flex', gap: '50px', marginBottom: '30px' }}>
              <MerchantSelections selectedStore={selectedStore} setSelectedStore={setSelectedStore} />
              <DirectTypeSelections selectedType={selectedType} setSelectedType={setSelectedType} />
            </div>
            {(storeId === 'ca3da560-d95f-46e4-a091-e588f67e3e2a' || storeId === 'a0eab2c4-7f10-4f17-9041-1149c7dcb6ce') && !showForm3 && (
              <GophrBillingForm
                isInvoice={isInvoice}
                setIsInvoice={setIsInvoice}
                billingInfo={billingInfo}
                setBillingInfo={setBillingInfo}
                billingErrors={billingErrors}
                vehicleType={vehicleType}
                isInvoiceDisabled={isInvoiceDisabled}
              />
            )}
            {selectedStore && selectedType === 'regular' && (
              <RegularCreation
                showForm1={showForm1}
                setShowForm1={setShowForm1}
                showForm2={showForm2}
                setShowForm2={setShowForm2}
                setShowForm3={setShowForm3}
                pickupInfo={pickupInfo}
                setPickupInfo={setPickupInfo}
                pickupInstruction={pickupInstruction}
                setPickupInstruction={setPickupInstruction}
                merchantOrderID={merchantOrderID}
                setMerchantOrderID={setMerchantOrderID}
                vehicleType={vehicleType}
                setVehicleType={setVehicleType}
                numPeople={numPeople}
                setNumPeople={setNumPeople}
                scheduledFor={scheduledFor}
                setScheduledFor={setScheduledFor}
                setIsExpedited={setIsExpedited}
                isScheduled={isScheduled}
                setIsScheduled={setIsScheduled}
                price={price}
                setPrice={setPrice}
                isSaved={isSaved}
                setIsSaved={setIsSaved}
                setOrderStatus={setOrderStatus}
                setShipmentStatus={setShipmentStatus}
                dropoffInstruction={dropoffInstruction}
                setDropoffInstruction={setDropoffInstruction}
                storeFirstName={storeFirstName}
                storeLastName={storeLastName}
                storePhone={storePhone}
                storeAddress={storeAddress}
                storeAddress1={storeAddress1}
                storeAddress2={storeAddress2}
                storeCity={storeCity}
                storeZip={storeZip}
                storeState={storeState}
                storeCountry={storeCountry}
                storeName={storeName}
                storeLat={storeLat}
                storeLng={storeLng}
                weight={weight}
                setWeight={setWeight}
                defaultItems={defaultItems}
                setDefaultItems={setDefaultItems}
                dropoffInfo={dropoffInfo}
                setDropoffInfo={setDropoffInfo}
                storeMarketId={storeMarketId}
                setIsSuperExpedited={setIsSuperExpedited}
                isRoundTrip={isRoundTrip}
                containsAlcohol={containsAlcohol}
                setContainsAlcohol={setContainsAlcohol}
                setDistance={setDistance}
                setRoute={setRoute}
                errorSubmit={errorSubmit}
                handleErrorSubmit={handleErrorSubmit}
                notify={notify}
                setErrorMessageDistance={setErrorMessageDistance}
                errorMessageSubmit={errorMessageSubmit}
                setErrorMessageSubmit={setErrorMessageSubmit}
                storeId={storeId}
                vendorLat={vendorLat}
                vendorLong={vendorLong}
                isExpedited={isExpedited}
                isSuperExpedited={isSuperExpedited}
                setIsForkLift={setIsForkLift}
                isForkLift={isForkLift}
                setIsDolly={setIsDolly}
                isDolly={isDolly}
                setIsHandLoadUnload={setIsHandLoadUnload}
                isHandLoadUnload={isHandLoadUnload}
                setIsHazmat={setIsHazmat}
                isHazmat={isHazmat}
                setIsHotBag={setIsHotBag}
                isHotBag={isHotBag}
                setIsCooler={setIsCooler}
                isCooler={isCooler}
                isTWICCard={isTWICCard}
                setIsTWICCard={setIsTWICCard}
                isLiftgate={isLiftgate}
                setIsLiftgate={setIsLiftgate}
                isPalletJack={isPalletJack}
                setIsPalletJack={setIsPalletJack}
                isWeatherProtection={isWeatherProtection}
                setIsWeatherProtection={setIsWeatherProtection}
                isMedical={isMedical}
                setIsMedical={setIsMedical}
                standardPriceRangeLow={standardPriceRangeLow}
                setStandardPriceRangeLow={setStandardPriceRangeLow}
                standardPriceRangeHigh={standardPriceRangeHigh}
                setStandardPriceRangeHigh={setStandardPriceRangeHigh}
                expeditedPriceRangeLow={expeditedPriceRangeLow}
                setExpeditedPriceRangeLow={setExpeditedPriceRangeLow}
                expeditedPriceRangeHigh={expeditedPriceRangeHigh}
                setExpeditedPriceRangeHigh={setExpeditedPriceRangeHigh}
                superExpeditedPriceRangeLow={superExpeditedPriceRangeLow}
                setSuperExpeditedPriceRangeLow={setSuperExpeditedPriceRangeLow}
                superExpeditedPriceRangeHigh={superExpeditedPriceRangeHigh}
                setSuperExpeditedPriceRangeHigh={setSuperExpeditedPriceRangeHigh}
                priceRangeLow={priceRangeLow}
                setPriceRangeLow={setPriceRangeLow}
                setPriceRangeHigh={setPriceRangeHigh}
                isInvoice={isInvoice}
                checkInvoice={checkInvoice}
              />
            )}
            {selectedStore && selectedType === 'routed' && (
              <RoutedCreation
                showForm1={showForm1}
                setShowForm1={setShowForm1}
                showForm2={showForm2}
                setShowForm2={setShowForm2}
                setShowForm3={setShowForm3}
                pickupInfo={pickupInfo}
                setPickupInfo={setPickupInfo}
                pickupInstruction={pickupInstruction}
                setPickupInstruction={setPickupInstruction}
                merchantOrderID={merchantOrderID}
                setMerchantOrderID={setMerchantOrderID}
                vehicleType={vehicleType}
                setVehicleType={setVehicleType}
                numPeople={numPeople}
                setNumPeople={setNumPeople}
                scheduledFor={scheduledFor}
                setScheduledFor={setScheduledFor}
                setIsExpedited={setIsExpedited}
                isScheduled={isScheduled}
                setIsScheduled={setIsScheduled}
                price={price}
                setPrice={setPrice}
                isSaved={isSaved}
                setIsSaved={setIsSaved}
                setOrderStatus={setOrderStatus}
                setShipmentStatus={setShipmentStatus}
                dropoffInstruction={dropoffInstruction}
                setDropoffInstruction={setDropoffInstruction}
                storeFirstName={storeFirstName}
                storeLastName={storeLastName}
                storePhone={storePhone}
                storeAddress={storeAddress}
                storeAddress1={storeAddress1}
                storeAddress2={storeAddress2}
                storeCity={storeCity}
                storeZip={storeZip}
                storeState={storeState}
                storeCountry={storeCountry}
                storeName={storeName}
                storeLat={storeLat}
                storeLng={storeLng}
                weight={weight}
                setWeight={setWeight}
                dropoffInfo={dropoffInfo}
                setDropoffInfo={setDropoffInfo}
                storeMarketId={storeMarketId}
                setIsSuperExpedited={setIsSuperExpedited}
                isRoundTrip={isRoundTrip}
                setIsRoundTrip={setIsRoundTrip}
                containsAlcohol={containsAlcohol}
                setContainsAlcohol={setContainsAlcohol}
                setDistance={setDistance}
                setRoute={setRoute}
                errorSubmit={errorSubmit}
                handleErrorSubmit={handleErrorSubmit}
                notify={notify}
                errorMessageDistance={errorMessageDistance}
                setErrorMessageDistance={setErrorMessageDistance}
                errorMessageSubmit={errorMessageSubmit}
                setErrorMessageSubmit={setErrorMessageSubmit}
                storeId={storeId}
                vendorLat={vendorLat}
                vendorLong={vendorLong}
                pickupItems={pickupItems}
                setPickupItems={setPickupItems}
                isExpedited={isExpedited}
                isSuperExpedited={isSuperExpedited}
                waypointInfo={waypointInfo}
                setWaypointInfo={setWaypointInfo}
                waypointPickupInstruction={waypointPickupInstruction}
                setWaypointPickupInstruction={setWaypointPickupInstruction}
                waypointDropoffInstruction={waypointDropoffInstruction}
                setWaypointDropoffInstruction={setWaypointDropoffInstruction}
                isWaypointPickup={isWaypointPickup}
                isWaypointDropoff={isWaypointDropoff}
                waypointPickupItems={waypointPickupItems}
                setWaypointPickupItems={setWaypointPickupItems}
                waypointDropoffItems={waypointDropoffItems}
                setWaypointDropoffItems={setWaypointDropoffItems}
                dropoffItems={dropoffItems}
                setDropoffItems={setDropoffItems}
                setIsWaypointPickup={setIsWaypointPickup}
                setIsWaypointDropoff={setIsWaypointDropoff}
                setIsForkLift={setIsForkLift}
                isForkLift={isForkLift}
                setIsDolly={setIsDolly}
                isDolly={isDolly}
                setIsHandLoadUnload={setIsHandLoadUnload}
                isHandLoadUnload={isHandLoadUnload}
                setIsHazmat={setIsHazmat}
                isHazmat={isHazmat}
                setIsHotBag={setIsHotBag}
                isHotBag={isHotBag}
                setIsCooler={setIsCooler}
                isCooler={isCooler}
                isTWICCard={isTWICCard}
                setIsTWICCard={setIsTWICCard}
                isLiftgate={isLiftgate}
                setIsLiftgate={setIsLiftgate}
                isPalletJack={isPalletJack}
                setIsPalletJack={setIsPalletJack}
                isWeatherProtection={isWeatherProtection}
                setIsWeatherProtection={setIsWeatherProtection}
                isMedical={isMedical}
                setIsMedical={setIsMedical}
                standardPriceRangeLow={standardPriceRangeLow}
                setStandardPriceRangeLow={setStandardPriceRangeLow}
                standardPriceRangeHigh={standardPriceRangeHigh}
                setStandardPriceRangeHigh={setStandardPriceRangeHigh}
                expeditedPriceRangeLow={expeditedPriceRangeLow}
                setExpeditedPriceRangeLow={setExpeditedPriceRangeLow}
                expeditedPriceRangeHigh={expeditedPriceRangeHigh}
                setExpeditedPriceRangeHigh={setExpeditedPriceRangeHigh}
                superExpeditedPriceRangeLow={superExpeditedPriceRangeLow}
                setSuperExpeditedPriceRangeLow={setSuperExpeditedPriceRangeLow}
                superExpeditedPriceRangeHigh={superExpeditedPriceRangeHigh}
                setSuperExpeditedPriceRangeHigh={setSuperExpeditedPriceRangeHigh}
                priceRangeLow={priceRangeLow}
                setPriceRangeLow={setPriceRangeLow}
                setPriceRangeHigh={setPriceRangeHigh}
                isInvoice={isInvoice}
                checkInvoice={checkInvoice}
              />
            )}
            {selectedStore && selectedType === 'multidrop' && (
              <MultiDropCreation
                showForm1={showForm1}
                setShowForm1={setShowForm1}
                showForm2={showForm2}
                setShowForm2={setShowForm2}
                setShowForm3={setShowForm3}
                pickupInfo={pickupInfo}
                setPickupInfo={setPickupInfo}
                pickupInstruction={pickupInstruction}
                setPickupInstruction={setPickupInstruction}
                merchantOrderID={merchantOrderID}
                setMerchantOrderID={setMerchantOrderID}
                vehicleType={vehicleType}
                setVehicleType={setVehicleType}
                numPeople={numPeople}
                setNumPeople={setNumPeople}
                scheduledFor={scheduledFor}
                setScheduledFor={setScheduledFor}
                setIsExpedited={setIsExpedited}
                isScheduled={isScheduled}
                setIsScheduled={setIsScheduled}
                price={price}
                setPrice={setPrice}
                isSaved={isSaved}
                setIsSaved={setIsSaved}
                setOrderStatus={setOrderStatus}
                setShipmentStatus={setShipmentStatus}
                storeFirstName={storeFirstName}
                storeLastName={storeLastName}
                storePhone={storePhone}
                storeAddress={storeAddress}
                storeAddress1={storeAddress1}
                storeAddress2={storeAddress2}
                storeCity={storeCity}
                storeZip={storeZip}
                storeState={storeState}
                storeCountry={storeCountry}
                storeName={storeName}
                storeLat={storeLat}
                storeLng={storeLng}
                weight={weight}
                setWeight={setWeight}
                storeMarketId={storeMarketId}
                setIsSuperExpedited={setIsSuperExpedited}
                isRoundTrip={isRoundTrip}
                containsAlcohol={containsAlcohol}
                setContainsAlcohol={setContainsAlcohol}
                setDistance={setDistance}
                setRoute={setRoute}
                errorSubmit={errorSubmit}
                handleErrorSubmit={handleErrorSubmit}
                notify={notify}
                errorMessageDistance={errorMessageDistance}
                setErrorMessageDistance={setErrorMessageDistance}
                errorMessageSubmit={errorMessageSubmit}
                setErrorMessageSubmit={setErrorMessageSubmit}
                storeId={storeId}
                vendorLat={vendorLat}
                vendorLong={vendorLong}
                pickupItems={pickupItems}
                setPickupItems={setPickupItems}
                multiDropWaypointsArray={multiDropWaypointsArray}
                setMultiDropWaypointsArray={setMultiDropWaypointsArray}
                multiDropWaypointsErrors={multiDropWaypointsErrors}
                setMultiDropWaypointsErrors={setMultiDropWaypointsErrors}
                setMultiDropsInfo={setMultiDropsInfo}
                isExpedited={isExpedited}
                isSuperExpedited={isSuperExpedited}
                setIsForkLift={setIsForkLift}
                isForkLift={isForkLift}
                setIsDolly={setIsDolly}
                isDolly={isDolly}
                setIsHandLoadUnload={setIsHandLoadUnload}
                isHandLoadUnload={isHandLoadUnload}
                setIsHazmat={setIsHazmat}
                isHazmat={isHazmat}
                setIsHotBag={setIsHotBag}
                isHotBag={isHotBag}
                setIsCooler={setIsCooler}
                isCooler={isCooler}
                isTWICCard={isTWICCard}
                setIsTWICCard={setIsTWICCard}
                isLiftgate={isLiftgate}
                setIsLiftgate={setIsLiftgate}
                isPalletJack={isPalletJack}
                setIsPalletJack={setIsPalletJack}
                isWeatherProtection={isWeatherProtection}
                setIsWeatherProtection={setIsWeatherProtection}
                isMedical={isMedical}
                setIsMedical={setIsMedical}
                standardPriceRangeLow={standardPriceRangeLow}
                setStandardPriceRangeLow={setStandardPriceRangeLow}
                standardPriceRangeHigh={standardPriceRangeHigh}
                setStandardPriceRangeHigh={setStandardPriceRangeHigh}
                expeditedPriceRangeLow={expeditedPriceRangeLow}
                setExpeditedPriceRangeLow={setExpeditedPriceRangeLow}
                expeditedPriceRangeHigh={expeditedPriceRangeHigh}
                setExpeditedPriceRangeHigh={setExpeditedPriceRangeHigh}
                superExpeditedPriceRangeLow={superExpeditedPriceRangeLow}
                setSuperExpeditedPriceRangeLow={setSuperExpeditedPriceRangeLow}
                superExpeditedPriceRangeHigh={superExpeditedPriceRangeHigh}
                setSuperExpeditedPriceRangeHigh={setSuperExpeditedPriceRangeHigh}
                priceRangeLow={priceRangeLow}
                setPriceRangeLow={setPriceRangeLow}
                setPriceRangeHigh={setPriceRangeHigh}
                isInvoice={isInvoice}
                checkInvoice={checkInvoice}
              />
            )}
            {selectedStore && selectedType === 'itemized-multidrop' && (
              <ItemizedMultiDropCreation
                showForm1={showForm1}
                setShowForm1={setShowForm1}
                showForm2={showForm2}
                setShowForm2={setShowForm2}
                setShowForm3={setShowForm3}
                pickupInfo={pickupInfo}
                setPickupInfo={setPickupInfo}
                pickupInstruction={pickupInstruction}
                setPickupInstruction={setPickupInstruction}
                merchantOrderID={merchantOrderID}
                setMerchantOrderID={setMerchantOrderID}
                vehicleType={vehicleType}
                setVehicleType={setVehicleType}
                numPeople={numPeople}
                setNumPeople={setNumPeople}
                scheduledFor={scheduledFor}
                setScheduledFor={setScheduledFor}
                setIsExpedited={setIsExpedited}
                isScheduled={isScheduled}
                setIsScheduled={setIsScheduled}
                price={price}
                setPrice={setPrice}
                isSaved={isSaved}
                setIsSaved={setIsSaved}
                setOrderStatus={setOrderStatus}
                setShipmentStatus={setShipmentStatus}
                storeFirstName={storeFirstName}
                storeLastName={storeLastName}
                storePhone={storePhone}
                storeAddress={storeAddress}
                storeAddress1={storeAddress1}
                storeAddress2={storeAddress2}
                storeCity={storeCity}
                storeZip={storeZip}
                storeState={storeState}
                storeCountry={storeCountry}
                storeName={storeName}
                storeLat={storeLat}
                storeLng={storeLng}
                weight={weight}
                setWeight={setWeight}
                storeMarketId={storeMarketId}
                setIsSuperExpedited={setIsSuperExpedited}
                isRoundTrip={isRoundTrip}
                containsAlcohol={containsAlcohol}
                setContainsAlcohol={setContainsAlcohol}
                setDistance={setDistance}
                setRoute={setRoute}
                errorSubmit={errorSubmit}
                handleErrorSubmit={handleErrorSubmit}
                notify={notify}
                errorMessageDistance={errorMessageDistance}
                setErrorMessageDistance={setErrorMessageDistance}
                errorMessageSubmit={errorMessageSubmit}
                setErrorMessageSubmit={setErrorMessageSubmit}
                storeId={storeId}
                vendorLat={vendorLat}
                vendorLong={vendorLong}
                pickupItems={pickupItems}
                setPickupItems={setPickupItems}
                multiDropWaypointsArray={multiDropWaypointsArray}
                setMultiDropWaypointsArray={setMultiDropWaypointsArray}
                multiDropWaypointsErrors={multiDropWaypointsErrors}
                setMultiDropWaypointsErrors={setMultiDropWaypointsErrors}
                setMultiDropsInfo={setMultiDropsInfo}
                isExpedited={isExpedited}
                isSuperExpedited={isSuperExpedited}
                perItemPrice={perItemPrice}
                setPerItemPrice={setPerItemPrice}
                perStopPrice={perStopPrice}
                setPerStopPrice={setPerStopPrice}
                extraPerItemPrice={extraPerItemPrice}
                setExtraPerItemPrice={setExtraPerItemPrice}
                freeItemCount={freeItemCount}
                setFreeItemCount={setFreeItemCount}
                setIsForkLift={setIsForkLift}
                isForkLift={isForkLift}
                setIsDolly={setIsDolly}
                isDolly={isDolly}
                setIsHandLoadUnload={setIsHandLoadUnload}
                isHandLoadUnload={isHandLoadUnload}
                setIsHazmat={setIsHazmat}
                isHazmat={isHazmat}
                setIsHotBag={setIsHotBag}
                isHotBag={isHotBag}
                setIsCooler={setIsCooler}
                isCooler={isCooler}
                isTWICCard={isTWICCard}
                setIsTWICCard={setIsTWICCard}
                isLiftgate={isLiftgate}
                setIsLiftgate={setIsLiftgate}
                isPalletJack={isPalletJack}
                setIsPalletJack={setIsPalletJack}
                isWeatherProtection={isWeatherProtection}
                setIsWeatherProtection={setIsWeatherProtection}
                isMedical={isMedical}
                setIsMedical={setIsMedical}
                standardPriceRangeLow={standardPriceRangeLow}
                setStandardPriceRangeLow={setStandardPriceRangeLow}
                standardPriceRangeHigh={standardPriceRangeHigh}
                setStandardPriceRangeHigh={setStandardPriceRangeHigh}
                expeditedPriceRangeLow={expeditedPriceRangeLow}
                setExpeditedPriceRangeLow={setExpeditedPriceRangeLow}
                expeditedPriceRangeHigh={expeditedPriceRangeHigh}
                setExpeditedPriceRangeHigh={setExpeditedPriceRangeHigh}
                superExpeditedPriceRangeLow={superExpeditedPriceRangeLow}
                setSuperExpeditedPriceRangeLow={setSuperExpeditedPriceRangeLow}
                superExpeditedPriceRangeHigh={superExpeditedPriceRangeHigh}
                setSuperExpeditedPriceRangeHigh={setSuperExpeditedPriceRangeHigh}
                priceRangeLow={priceRangeLow}
                setPriceRangeLow={setPriceRangeLow}
                setPriceRangeHigh={setPriceRangeHigh}
                isInvoice={isInvoice}
                checkInvoice={checkInvoice}
              />
            )}
          </form>
        </Card>
      </Collapse>
      {selectedType === 'regular' && (
        <RegularConfirmPage
          showForm3={showForm3}
          pickupInfo={pickupInfo}
          pickupInstruction={pickupInstruction}
          merchantOrderID={merchantOrderID}
          vehicleType={vehicleType}
          numPeople={numPeople}
          scheduledFor={scheduledFor}
          isExpedited={isExpedited}
          isScheduled={isScheduled}
          handleBack={handleBack}
          price={price}
          handleConfirmRegular={handleConfirmRegular}
          setIsSaved={setIsSaved}
          setOrderStatus={setOrderStatus}
          setShipmentStatus={setShipmentStatus}
          dropoffInfo={dropoffInfo}
          dropoffInstruction={dropoffInstruction}
          defaultItems={defaultItems}
          priceRangeLow={priceRangeLow}
          priceRangeHigh={priceRangeHigh}
          isInvoice={isInvoice}
          billingInfo={billingInfo}
        />
      )}
      {selectedType === 'routed' && (
        <RoutedConfirmPage
          showForm3={showForm3}
          pickupInfo={pickupInfo}
          pickupInstruction={pickupInstruction}
          merchantOrderID={merchantOrderID}
          vehicleType={vehicleType}
          numPeople={numPeople}
          scheduledFor={scheduledFor}
          isExpedited={isExpedited}
          isScheduled={isScheduled}
          handleBack={handleBack}
          price={price}
          handleConfirmRouted={handleConfirmRouted}
          setIsSaved={setIsSaved}
          setOrderStatus={setOrderStatus}
          setShipmentStatus={setShipmentStatus}
          dropoffInfo={dropoffInfo}
          dropoffInstruction={dropoffInstruction}
          waypointInfo={waypointInfo}
          waypointPickupInstruction={waypointPickupInstruction}
          waypointDropoffInstruction={waypointDropoffInstruction}
          pickupItems={pickupItems}
          isWaypointPickup={isWaypointPickup}
          waypointPickupItems={waypointPickupItems}
          priceRangeLow={priceRangeLow}
          priceRangeHigh={priceRangeHigh}
          isInvoice={isInvoice}
          billingInfo={billingInfo}
        />
      )}
      {selectedType === 'multidrop' && (
        <MultiDropConfirmPage
          showForm3={showForm3}
          pickupInfo={pickupInfo}
          pickupInstruction={pickupInstruction}
          merchantOrderID={merchantOrderID}
          vehicleType={vehicleType}
          numPeople={numPeople}
          scheduledFor={scheduledFor}
          isExpedited={isExpedited}
          isScheduled={isScheduled}
          handleBack={handleBack}
          price={price}
          handleConfirmMultiDrop={handleConfirmMultiDrop}
          setIsSaved={setIsSaved}
          setOrderStatus={setOrderStatus}
          setShipmentStatus={setShipmentStatus}
          pickupItems={pickupItems}
          multiDropWaypointsArray={multiDropWaypointsArray}
          priceRangeLow={priceRangeLow}
          priceRangeHigh={priceRangeHigh}
          isInvoice={isInvoice}
          billingInfo={billingInfo}
        />
      )}
      {selectedType === 'itemized-multidrop' && (
        <ItemizedMultiDropConfirmPage
          showForm3={showForm3}
          pickupInfo={pickupInfo}
          pickupInstruction={pickupInstruction}
          merchantOrderID={merchantOrderID}
          vehicleType={vehicleType}
          numPeople={numPeople}
          scheduledFor={scheduledFor}
          isExpedited={isExpedited}
          isScheduled={isScheduled}
          handleBack={handleBack}
          price={price}
          handleConfirmItemizedMultiDrop={handleConfirmItemizedMultiDrop}
          setIsSaved={setIsSaved}
          setOrderStatus={setOrderStatus}
          setShipmentStatus={setShipmentStatus}
          pickupItems={pickupItems}
          multiDropWaypointsArray={multiDropWaypointsArray}
          perItemPrice={perItemPrice}
          priceRangeLow={priceRangeLow}
          priceRangeHigh={priceRangeHigh}
          perStopPrice={perStopPrice}
          freeItemCount={freeItemCount}
          extraPerItemPrice={extraPerItemPrice}
          isInvoice={isInvoice}
          billingInfo={billingInfo}
        />
      )}
    </div>
  );
};

export default CreateDeliveryForMerchant;
