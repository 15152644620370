import styled from 'styled-components';

export const Styles = styled.div`
    display: flex;
    flex-direction: row-reverse;
        .cancel {
            background-color: #344675;
            border-color: transparent;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 600;
            color: #efeeee;
            height: 50px;
            width: 150px;
            margin-right: 2px;
        }
        .save {
            background-image: linear-gradient(to right, #f7941d, #f15a29);
            border-color: transparent;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 600;
            color: #efeeee;
            height: 50px;
            width: 150px;
            margin-left: 2px;
        }
`;