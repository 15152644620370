import styled from 'styled-components';

export const Styles = styled.div`
    margin-bottom: 30px;
    height: auto;
    width: 100%;
    background-color: #27293d;
    border-radius: 5px;
    padding: 20px;
        .title-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
                .title-h4 {
                    color: #f7941d;
                    font-weight: 500;
                    flex-direction: flex-start;
                        i {
                            color: #344675;
                        }
                }
                .desc-h4 {
                    color: #5e72e4;
                    flex-direction: flex-end;
                }
        }
        .images-container {
            width: 100%;
            height: auto;
            border-radius: 5px;
            text-align: center;
            background-color: rgba(94, 114, 228, 0.1);
                .button-paragraph {
                    padding-top: 60px;
                        .upload-btn {
                            background-image: linear-gradient(to left, #f15a29, #f7941d);
                            border-radius: 5px;
                            border-color: transparent;
                            height: 50px;
                            width: 200px;
                            color: #efeeee;
                            font-size: 12px;
                            font-weight: 600;
                            margin-bottom: 10px;
                        }
                        p {
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
                
                }

    `;