import React, { useState, Component } from 'react';
import { DragDropContext, Droppable, Draggable, resetServerContext } from 'react-beautiful-dnd';
import { Row, Col } from 'reactstrap';

// fake data generator
// const getItems = count =>
//   Array.from({ length: count }, (v, k) => k).map(k => ({
//     id: `item-${k}`,
//     content: `item ${k}`
//   }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 0.3,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'lightgray',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'white',
  padding: grid,
  width: 350
});

const ReorderWaypoints = ({ routeWaypoints, setRouteWaypoints }) => {
  // resetServerContext();
  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsUpdate = reorder(routeWaypoints, result.source.index, result.destination.index);

    setRouteWaypoints(itemsUpdate);
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {routeWaypoints.map((item, index) => (
              <Draggable key={item.friendly_id} draggableId={item.friendly_id} index={index}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                    <div>
                      <Row>
                        <Col className="col-4">
                          {item.actions.hasOwnProperty('pick_up') ? (
                            item.actions['pick_up'].item[0].includes('drop') ? (
                              <p style={{ color: 'blue', fontSize: 16, paddingTop: 12 }}>
                                D<span style={{ color: 'green', fontSize: 14 }}>-{item.ship_id}</span>
                              </p>
                            ) : (
                              <p style={{ color: 'red', fontSize: 16, paddingTop: 12 }}>
                                P<span style={{ color: 'green', fontSize: 14 }}>-{item.ship_id}</span>
                              </p>
                            )
                          ) : (
                            <p style={{ color: 'blue', fontSize: 16, paddingTop: 12 }}>
                              D<span style={{ color: 'green', fontSize: 14 }}>-{item.ship_id}</span>
                            </p>
                          )}
                        </Col>

                        <Col className="col-8">
                          <Row>{item.address.name}</Row>
                          <Row>{item.address.address_1 + ', ' + item.address.zip}</Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ReorderWaypoints;
