import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'reactstrap';

import Loading from '../Global/Loading';
import Styles from './Styles/RightSideBarStyles';
import { Info } from '../Merchant/Info';
import { StoreManagerIcon, UserManagerIcon, CredentialsIcon } from '../../assets/svgs/SideBarIcons';

import * as inventory_urls from '../../urls/api-inventory/admin';
import useOutsideClick from '../../helpers/useOutsideClick';
import UserManager from '../Users/UserManager';
import CredentialIcon from '../../assets/img/Merchant_Manager_Icon@2x.png';
import PaymentIcon from '../../assets/img/Payment@2x.png';
import { Credentials } from '../Merchant/Credentials';
import { AddCard } from '../Merchant/Payments/AddCard';
import { SubaccountForm } from '../Merchant/Subaccount/SubaccountForm';

const RightSideBar = props => {
  const [showStore, setShowStore] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [showCredentials, setShowCredentials] = useState(false);
  const [showPayments, setShowPayments] = useState(false);
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState({});

  const storeRef = useRef();
  // useOutsideClick(storeRef, () => {
  //   if (showStore) setShowStore(false);
  // });

  const userRef = useRef();
  // useOutsideClick(userRef, () => {
  //   if (showUsers) setShowUsers(false);
  // });

  const credentialsRef = useRef();
  // useOutsideClick(credentialsRef, () => {
  //   if (showCredentials) setShowCredentials(false);
  //   if (showPayments) {
  //     setShowPayments(showPayments);
  //   } else {
  //     setShowPayments(showPayments);
  //   }
  // });

  const paymentsRef = useRef();
  // useOutsideClick(paymentsRef, () => {
  //   if (showPayments) setShowPayments(false);
  //   if (showCredentials) {
  //     setShowPayments(showCredentials);
  //   } else {
  //     setShowPayments(showCredentials);
  //   }
  // });

  const closeUserModal = () => {
    setShowUsers(false);
  };

  const closeStoreModal = () => {
    setShowStore(false);
  };

  const closePaymentModal = () => {
    setShowPayments(false);
  };

  const closeCredentialsModal = () => {
    setShowCredentials(false);
  };

  const getStore = storeId => {
    axios({
      method: `post`,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: inventory_urls.hasuraHost,
      data: { query: `{Stores (where: { store_id: {_eq: "` + storeId + `" } } ) { store_id city state name phone logo address_1 StoreTimes { day_of_week open_time close_time }}}` }
    }).then(response => {
      setLoading(false);
      if (response.data.errors) {
        if (response.data.errors.length > 0) {
          if (response.data.errors[0].message === 'Malformed Authorization header' || response.data.errors[0].extensions.code === 'invalid-jwt') {
            this.props.history.push('/logout');
          }
        }
      }
      if (response.data.data) {
        setLoading(false);
        setStore(response.data.data.Stores[0]);
      }
    });
  };

  useEffect(() => {
    if (props.storeId) {
      getStore(props.storeId);
    }
  }, []);

  if (loading) return <Loading />;
  return (
    <Styles className="right-sidebar">
      <div className="icon-container">
        {props.roles[0] < 200 ? (
          <Button className="store-manager" onClick={() => setShowStore(!showStore)}>
            <StoreManagerIcon />
          </Button>
        ) : null}
        {showStore && (
          <div className="info-container" ref={storeRef}>
            <Info store={store} closeStoreModal={closeStoreModal} />
          </div>
        )}

        {/* <Button className="user-manager" onClick={() => setShowUsers(!showUsers)}>
          <UserManagerIcon store={store} />
        </Button>
        {showUsers && (
          <div className="user-container" ref={userRef}>
            <SubaccountForm store={store} closeUserModal={closeUserModal} />
          </div>
        )} */}

        <Button
          className="credentials-manager"
          onClick={() => {
            setShowCredentials(!showCredentials);
          }}
        >
          <img src={CredentialIcon} alt="< >" style={{ width: '25px' }}></img>
        </Button>
        {showCredentials && (
          <div ref={credentialsRef}>
            <Credentials store={store} closeCredentialsModal={closeCredentialsModal} />
          </div>
        )}

        <Button
          className="credentials-manager"
          onClick={() => {
            setShowPayments(!showPayments);
          }}
        >
          <img src={PaymentIcon} alt="< >" style={{ width: '25px' }}></img>
        </Button>
        {showPayments && (
          <div ref={paymentsRef}>
            <AddCard closePaymentModal={closePaymentModal} />
          </div>
        )}
      </div>
    </Styles>
  );
};

export default RightSideBar;
