/*!
 * Black Dashboard PRO React - v1.0.0
 * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// This must be the first line in src/index.js
import 'react-app-polyfill/ie9';

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Button, CardTitle, FormGroup, Row, Col, Collapse } from 'reactstrap';
import '../../../assets/react-mapbox-autocomplete/index.css';

import * as shipment_urls from '../../../urls/api-shipments/merchant';
import states from '../../../us_states';
import Loading from '../../Global/Loading';
import { useHistory } from 'react-router-dom';
import LineItems from './DirectComponents/LineItems';
import LineItemsRoutedFinalDrop from './DirectComponents/LineItemsRoutedFinalDrop';
import NumberOfGophrs from './DirectComponents/NumberOfGophrs';
import VehicleTypes from './DirectComponents/VehicleTypes';
import PriceChoices from './DirectComponents/PriceChoices';
import PickupInfoRouted from './DirectComponents/PickupInfoRouted';
import DropoffInfoRouted from './DirectComponents/DropoffInfoRouted';
import WaypointInfo from './DirectComponents/WaypointInfo';
import MerchantOrderID from './DirectComponents/MerchantOrderID';
import ContainsAlcohol from './DirectComponents/ContainsAlcohol';
import WightOptions from './DirectComponents/WeightOptions';
import Tools from './DirectComponents/Tools';

const RoutedCreation = ({
  showForm1,
  setShowForm1,
  setShowForm3,
  showForm2,
  setShowForm2,
  pickupInfo,
  setPickupInfo,
  pickupInstruction,
  setPickupInstruction,
  merchantOrderID,
  setMerchantOrderID,
  vehicleType,
  setVehicleType,
  numPeople,
  setNumPeople,
  scheduledFor,
  setScheduledFor,
  setIsExpedited,
  isScheduled,
  setIsScheduled,
  price,
  setPrice,
  isSaved,
  setIsSaved,
  setOrderStatus,
  setShipmentStatus,
  dropoffInstruction,
  setDropoffInstruction,
  storeFirstName,
  storeLastName,
  storePhone,
  storeAddress,
  storeAddress1,
  storeAddress2,
  storeCity,
  storeZip,
  storeState,
  storeCountry,
  storeName,
  storeLat,
  storeLng,
  weight,
  setWeight,
  dropoffInfo,
  setDropoffInfo,
  storeMarketId,
  setIsSuperExpedited,
  isRoundTrip,
  setIsRoundTrip,
  containsAlcohol,
  setContainsAlcohol,
  setDistance,
  setRoute,
  errorSubmit,
  handleErrorSubmit,
  notify,
  errorMessageDistance,
  setErrorMessageDistance,
  errorMessageSubmit,
  setErrorMessageSubmit,
  storeId,
  vendorLat,
  vendorLong,
  pickupItems,
  setPickupItems,
  isExpedited,
  isSuperExpedited,
  waypointInfo,
  setWaypointInfo,
  waypointPickupInstruction,
  setWaypointPickupInstruction,
  waypointDropoffInstruction,
  setWaypointDropoffInstruction,
  isWaypointPickup,
  isWaypointDropoff,
  waypointPickupItems,
  setWaypointPickupItems,
  waypointDropoffItems,
  setWaypointDropoffItems,
  dropoffItems,
  setDropoffItems,
  setIsWaypointPickup,
  setIsWaypointDropoff,
  setIsForkLift,
  isForkLift,
  setIsDolly,
  isDolly,
  setIsHandLoadUnload,
  isHandLoadUnload,
  setIsHazmat,
  isHazmat,
  setIsHotBag,
  isHotBag,
  setIsCooler,
  isCooler,
  isTWICCard,
  setIsTWICCard,
  isLiftgate,
  setIsLiftgate,
  isPalletJack,
  setIsPalletJack,
  isWeatherProtection,
  setIsWeatherProtection,
  isMedical,
  setIsMedical,
  standardPriceRangeLow,
  setStandardPriceRangeLow,
  standardPriceRangeHigh,
  setStandardPriceRangeHigh,
  expeditedPriceRangeLow,
  setExpeditedPriceRangeLow,
  expeditedPriceRangeHigh,
  setExpeditedPriceRangeHigh,
  superExpeditedPriceRangeLow,
  setSuperExpeditedPriceRangeLow,
  superExpeditedPriceRangeHigh,
  setSuperExpeditedPriceRangeHigh,
  priceRangeLow,
  setPriceRangeLow,
  setPriceRangeHigh,
  isInvoice,
  checkInvoice
}) => {
  var moment = require('moment');

  var tomorrow = moment().add(1, 'day');

  const [address, setAddress] = useState('');
  const [controlLat, setControlLat] = useState('30.21294');
  const [controlLong, setControlLong] = useState('-93.2187');
  const [description, setDescription] = useState('');

  const [dropAddress1, setDropAddress1] = useState(null);
  const [dropAddress2, setDropAddress2] = useState(null);
  const [dropCity, setDropCity] = useState(null);
  const [dropCountry, setDropCountry] = useState(null);
  const [dropFirstname, setDropFirstname] = useState('');
  const [dropFullAddress, setDropFullAddress] = useState('');
  const [dropLastname, setDropLastname] = useState('');
  const [dropLat, setDropLat] = useState(null);
  const [dropLng, setDropLng] = useState(null);
  const [dropPhone, setDropPhone] = useState('');
  const [dropState, setDropState] = useState(null);
  const [dropZip, setDropZip] = useState(null);

  const [dropoffBusinessName, setDropoffBusinessName] = useState('');

  const [errorDistance, setErrorDistance] = useState({});
  const [errors, setErrors] = useState({
    pickupFirstname: false,
    pickupLastname: false,
    pickupPhone: false,
    pickupAddress: false,
    dropoffFirstname: false,
    dropoffLastname: false,
    dropoffPhone: false,
    description: false,
    weight: false,
    gophrs: false,
    size: false,
    price: false
  });

  const [expeditedPrice, setExpeditedPrice] = useState('');
  const [superExpeditedPrice, setSuperExpeditedPrice] = useState('');
  const [isDropoffStore, setIsDropoffStore] = useState(false);
  const [isPickupStore, setIsPickupStore] = useState(true);

  const [loading, setLoading] = useState(true);

  const [pickAddress1, setPickAddress1] = useState(null);
  const [pickAddress2, setPickAddress2] = useState(null);
  const [pickCity, setPickCity] = useState(null);
  const [pickCountry, setPickCountry] = useState(null);
  const [pickFirstname, setPickFirstname] = useState('');
  const [pickFullAddress, setPickFullAddress] = useState('');
  const [pickLastname, setPickLastname] = useState('');
  const [pickLat, setPickLat] = useState(null);
  const [pickLng, setPickLng] = useState(null);
  const [pickPhone, setPickPhone] = useState('');
  const [pickState, setPickState] = useState(null);
  const [pickZip, setPickZip] = useState(null);
  const [pickBusinessName, setPickBusinessName] = useState('');

  const [scheduledDate, setScheduledDate] = useState(tomorrow);
  const [scheduledTime, setScheduledTime] = useState(null);

  const [showForm, setShowForm] = useState(true);
  const [standardPrice, setStandardPrice] = useState('');

  //! autofill customer
  const [pickupCustomerSuggestions, setPickupCustomerSuggestions] = useState([]);
  const [dropCustomerSuggestions, setDropCustomerSuggestions] = useState([]);
  const [isPickupAutoFilled, setIsPickupAutoFilled] = useState(false);
  const [isDropOffAutoFilled, setIsDropOffAutoFilled] = useState(false);
  const [isWaypointAutoFilled, setIsWaypointAutoFilled] = useState(false);

  const [isProceeded, setIsProceeded] = useState(false);

  const [isWaypointStore, setIsWaypointStore] = useState(false);
  const [waypointAddress1, setWaypointAddress1] = useState(null);
  const [waypointAddress2, setWaypointAddress2] = useState(null);
  const [waypointCity, setWaypointCity] = useState(null);
  const [waypointCountry, setWaypointCountry] = useState(null);
  const [waypointFirstname, setWaypointFirstname] = useState('');
  const [waypointFullAddress, setWaypointFullAddress] = useState('');
  const [waypointLastname, setWaypointLastname] = useState('');
  const [waypointLat, setWaypointLat] = useState(null);
  const [waypointLng, setWaypointLng] = useState(null);
  const [waypointPhone, setWaypointPhone] = useState('');
  const [waypointState, setWaypointState] = useState(null);
  const [waypointZip, setWaypointZip] = useState(null);
  const [waypointBusinessName, setWaypointBusinessName] = useState('');

  const [pickupItemsError, setPickupItemsError] = useState([false]);
  const [dropoffItemsError, setDropoffItemsError] = useState([false]);
  const [waypointPickupItemsError, setWaypointPickupItemsError] = useState([false]);
  const [waypointDropoffItemsError, setWaypointDropoffItemsError] = useState([false]);

  const [threeStopsError, setThreeStopsError] = useState(false);
  const [itemsMatchError, setItemsMatchError] = useState(false);

  const [waypointErrors, setWaypointErrors] = useState({
    waypointFirstname: false,
    waypointLastname: false,
    waypointPhone: false,
    waypointAddress: false,
    pickOrDrop: false
  });
  const [waypointCustomerSuggestions, setWaypointCustomerSuggestions] = useState([]);

  const MAPBOX_ENDPOINT = 'https://api.mapbox.com/directions/v5/mapbox/driving/';
  const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ29waHItZW5naW5lZXJpbmciLCJhIjoiY2syYzd6NjdrMmo3azNtcGU1cXZzMTh1MSJ9.cHMxwcG_MoQk-l4ZO3bdEA';
  const ACCESS_TOKEN = 'Bearer ' + window.localStorage.getItem('access_token');
  const jwtDecode = require('jwt-decode');
  const DECODED_ACCESS = jwtDecode(ACCESS_TOKEN);
  const SUB = DECODED_ACCESS.sub;
  const history = useHistory();

  useEffect(() => {
    if (standardPrice) {
      if (isExpedited) {
        setPrice(expeditedPrice);
      } else if (isSuperExpedited) {
        setPrice(superExpeditedPrice);
      } else {
        setPrice(standardPrice);
      }
    }
  }, [isRoundTrip, standardPrice]);

  useEffect(() => {
    if (standardPriceRangeLow && standardPriceRangeHigh) {
      if (isExpedited) {
        setPriceRangeLow(expeditedPriceRangeLow);
        setPriceRangeHigh(expeditedPriceRangeHigh);
      } else if (isSuperExpedited) {
        setPriceRangeLow(superExpeditedPriceRangeLow);
        setPriceRangeHigh(superExpeditedPriceRangeHigh);
      } else {
        setPriceRangeLow(standardPriceRangeLow);
        setPriceRangeHigh(standardPriceRangeHigh);
      }
    }
  }, [standardPriceRangeLow, standardPriceRangeHigh, isExpedited, isSuperExpedited]);

  useEffect(() => {
    let storeInfo = {
      name: storeFirstName + ' ' + storeLastName,
      first_name: storeFirstName,
      last_name: storeLastName,
      phone: storePhone,
      address: storeAddress,
      address_1: storeAddress1,
      address_2: storeAddress2,
      city: storeCity,
      zip: storeZip,
      state: storeState,
      country: storeCountry,
      business_name: storeName,
      lat: parseFloat(storeLat),
      lng: parseFloat(storeLng)
    };
    let dropInfo = {
      name: dropFirstname + ' ' + dropLastname,
      first_name: dropFirstname,
      last_name: dropLastname,
      phone: dropPhone,
      address: dropFullAddress,
      address_1: dropAddress1,
      address_2: dropAddress2,
      city: dropCity,
      zip: dropZip,
      state: dropState,
      country: dropCountry,
      business_name: dropoffBusinessName,
      lat: parseFloat(dropLat),
      lng: parseFloat(dropLng)
    };
    let pickInfo = {
      name: pickFirstname + ' ' + pickLastname,
      first_name: pickFirstname,
      last_name: pickLastname,
      phone: pickPhone,
      address: pickFullAddress,
      address_1: pickAddress1,
      address_2: pickAddress2,
      city: pickCity,
      zip: pickZip,
      state: pickState,
      country: pickCountry,
      business_name: pickBusinessName,
      lat: parseFloat(pickLat),
      lng: parseFloat(pickLng)
    };

    if (!isRoundTrip) {
      if (isPickupStore) {
        setPickupInfo(storeInfo);
      } else {
        setPickupInfo(pickInfo);
      }
      if (isDropoffStore) {
        setDropoffInfo(storeInfo);
      } else {
        setDropoffInfo(dropInfo);
      }
    } else {
      if (isPickupStore) {
        setPickupInfo(storeInfo);
        setDropoffInfo(storeInfo);
      } else {
        setPickupInfo(pickInfo);
        setDropoffInfo(pickInfo);
      }
    }
  }, [
    pickLat,
    pickLng,
    dropLat,
    dropLng,
    storeLat,
    storeLng,
    isPickupStore,
    isDropoffStore,
    pickFirstname,
    pickLastname,
    pickPhone,
    pickBusinessName,
    dropPhone,
    dropFirstname,
    dropLastname,
    dropoffBusinessName,
    isRoundTrip
  ]);

  useEffect(() => {
    if (isWaypointStore) {
      setWaypointInfo({
        name: storeFirstName + ' ' + storeLastName,
        first_name: storeFirstName,
        last_name: storeLastName,
        phone: storePhone,
        address: storeAddress,
        address_1: storeAddress1,
        address_2: storeAddress2,
        city: storeCity,
        zip: storeZip,
        state: storeState,
        country: storeCountry,
        business_name: storeName,
        lat: parseFloat(storeLat),
        lng: parseFloat(storeLng)
      });
    } else {
      setWaypointInfo({
        name: waypointFirstname + ' ' + waypointLastname,
        first_name: waypointFirstname,
        last_name: waypointLastname,
        phone: waypointPhone,
        address: waypointFullAddress,
        address_1: waypointAddress1,
        address_2: waypointAddress2,
        city: waypointCity,
        zip: waypointZip,
        state: waypointState,
        country: waypointCountry,
        business_name: waypointBusinessName,
        lat: parseFloat(waypointLat),
        lng: parseFloat(waypointLng)
      });
    }
  }, [isWaypointStore, waypointLat, waypointLng, storeLat, storeLng, waypointFirstname, waypointLastname, waypointPhone, waypointBusinessName]);

  useEffect(() => {
    if (isScheduled && scheduledTime && scheduledDate) {
      setScheduledFor(
        moment(
          moment(scheduledDate)
            .startOf('day')
            .add(scheduledTime, 'hours')
        )
      );
      setIsProceeded(true);
    } else if (isScheduled && (!scheduledTime || !scheduledDate)) {
      setIsProceeded(false);
    } else if (!isScheduled) {
      setIsProceeded(true);
      setScheduledFor(null);
    }
  }, [isScheduled, scheduledDate, scheduledTime]);

  const handleErrorDistance = error => {
    setErrorDistance(error);
  };

  const handleForm = (show, show2) => {
    setShowForm1(show);
    setShowForm2(show2);
  };

  const handlePickupSelect = (result, lat, lng) => {
    var split = result.split(',');
    var stateZip = split[2].split(' ');
    var state = null;
    //! Mapbox returns full name of the state
    // for (var i = 0; i < states.length; i++) {
    //   if (states[i].label === stateZip[1]) {
    //     state = states[i].val;
    //     break;
    //   }
    // }
    //! Google returns abbreviated name of the state
    state = stateZip[1];
    setPickFullAddress(result);
    setPickAddress1(split[0]);
    setPickCity(split[1]);
    setPickState(state);
    setPickZip(stateZip[2]);
    setPickLat(lat);
    setPickLng(lng);
  };

  const handleDropoffSelect = (result, lat, lng) => {
    var split = result.split(',');
    var stateZip = split[2].split(' ');
    var state = null;
    //! Mapbox returns full name of the state
    // for (var i = 0; i < states.length; i++) {
    //   if (states[i].label === stateZip[1]) {
    //     state = states[i].val;
    //     break;
    //   }
    // }
    //! Google returns abbreviated name of the state
    state = stateZip[1];
    setDropFullAddress(result);
    setDropAddress1(split[0]);
    setDropCity(split[1]);
    setDropState(state);
    setDropZip(stateZip[2]);
    setDropLat(lat);
    setDropLng(lng);
  };

  const handleWaypointSelect = (result, lat, lng) => {
    var split = result.split(',');
    var stateZip = split[2].split(' ');
    var state = null;
    //! Mapbox returns full name of the state
    // for (var i = 0; i < states.length; i++) {
    //   if (states[i].label === stateZip[1]) {
    //     state = states[i].val;
    //     break;
    //   }
    // }
    //! Google returns abbreviated name of the state
    state = stateZip[1];

    setWaypointFullAddress(result);
    setWaypointAddress1(split[0]);
    setWaypointCity(split[1]);
    setWaypointState(state);
    setWaypointZip(stateZip[2]);
    setWaypointLat(lat);
    setWaypointLng(lng);
  };

  const handleWeight = event => {
    setWeight(event.target.value);
  };

  const handleSubmit = () => {
    // Yaboi has had a few and this is how he's error checking...big yikes

    const checkItemsError = (items, itemsError, setItemsError) => {
      let ItemsCheck = false;
      let itemsErrorArray = [...itemsError];
      items.forEach((pi, index) => {
        if (pi.detail === '' || pi.detail === null) {
          itemsErrorArray[index] = true;
          ItemsCheck = true;
        } else {
          itemsErrorArray[index] = false;
        }
      });
      setItemsError(itemsErrorArray);
      return ItemsCheck;
    };

    const genericMatchCheck = (totalPickupItems, totalDropoffItems) => {
      let hasError = false;
      let pickCount = 0;
      let dropCount = 0;

      totalPickupItems.map(item => {
        pickCount += item.quantity;
      });
      totalDropoffItems.map(item => {
        dropCount += item.quantity;
      });

      if (pickCount !== dropCount) {
        hasError = true;
        setItemsMatchError(hasError);
        return hasError;
      }

      let totalPickupItemsCopy = [...totalPickupItems].map(item => item.detail);
      let totalDropoffItemsCopy = [...totalDropoffItems].map(item => item.detail);
      const uniqueArray = array => [...new Set(array.map(item => JSON.stringify(item)))].map(itemBack => JSON.parse(itemBack));

      let totalPickupItemsSet = uniqueArray(totalPickupItemsCopy);
      let totalDropoffItemsSet = uniqueArray(totalDropoffItemsCopy);

      if (!(totalPickupItemsSet.every(item => totalDropoffItemsSet.includes(item)) && totalDropoffItemsSet.every(item => totalPickupItemsSet.includes(item)))) {
        hasError = true;
        setItemsMatchError(hasError);
        return hasError;
      }

      totalPickupItemsSet.forEach(item => {
        let countPick = 0;
        let countDrop = 0;
        totalPickupItems.forEach(pick => {
          if (item === pick.detail) {
            countPick += pick.quantity;
          }
        });
        totalDropoffItems.forEach(drop => {
          if (item === drop.detail) {
            countDrop += drop.quantity;
          }
        });
        if (countPick !== countDrop) {
          hasError = true;
          setItemsMatchError(hasError);
          return hasError;
        }
      });

      setItemsMatchError(hasError);
      return hasError;
    };

    const checkPickAndDropMatchError = () => {
      let totalPickupItems = [...pickupItems, ...waypointPickupItems].filter(item => {
        return item.detail !== '';
      });
      let totalDropoffItems = [...dropoffItems, ...waypointDropoffItems].filter(item => {
        return item.detail !== '';
      });
      return genericMatchCheck(totalPickupItems, totalDropoffItems);
    };

    let updatedErrors = errors;
    let updateWaypointErrors = waypointErrors;

    if (!isPickupStore) {
      if (pickFirstname) {
        updatedErrors.pickupFirstname = false;
      } else {
        updatedErrors.pickupFirstname = true;
      }
      if (pickLastname) {
        updatedErrors.pickupLastname = false;
      } else {
        updatedErrors.pickupLastname = true;
      }

      if (validatePhone(pickPhone)) {
        updatedErrors.pickupPhone = false;
      } else {
        updatedErrors.pickupPhone = true;
      }
      if (pickLat && pickLng) {
        updatedErrors.pickupAddress = false;
      } else {
        updatedErrors.pickupAddress = true;
      }
    } else {
      updatedErrors.pickupFirstname = false;
      updatedErrors.pickupLastname = false;
      updatedErrors.pickupPhone = false;
      updatedErrors.pickupAddress = false;
    }

    if (!isWaypointStore) {
      if (waypointFirstname) {
        updateWaypointErrors.waypointFirstname = false;
      } else {
        updateWaypointErrors.waypointFirstname = true;
      }
      if (waypointLastname) {
        updateWaypointErrors.waypointLastname = false;
      } else {
        updateWaypointErrors.waypointLastname = true;
      }
      if (validatePhone(waypointPhone)) {
        updateWaypointErrors.waypointPhone = false;
      } else {
        updateWaypointErrors.waypointPhone = true;
      }
      if (waypointLat && waypointLng) {
        updateWaypointErrors.waypointAddress = false;
      } else {
        updateWaypointErrors.waypointAddress = true;
      }
    } else {
      updateWaypointErrors.waypointFirstname = false;
      updateWaypointErrors.waypointLastname = false;
      updateWaypointErrors.waypointPhone = false;
      updateWaypointErrors.waypointAddress = false;
    }
    if (!isWaypointPickup && !isWaypointDropoff) {
      updateWaypointErrors.pickOrDrop = true;
    } else {
      updateWaypointErrors.pickOrDrop = false;
    }
    if (!isDropoffStore && !isRoundTrip) {
      if (dropFirstname) {
        updatedErrors.dropoffFirstname = false;
      } else {
        updatedErrors.dropoffFirstname = true;
      }
      if (dropLastname) {
        updatedErrors.dropoffLastname = false;
      } else {
        updatedErrors.dropoffLastname = true;
      }

      if (validatePhone(dropPhone)) {
        updatedErrors.dropoffPhone = false;
      } else {
        updatedErrors.dropoffPhone = true;
      }
      if (dropLat && dropLng) {
        updatedErrors.dropoffAddress = false;
      } else {
        updatedErrors.dropoffAddress = true;
      }
    } else {
      updatedErrors.dropoffFirstname = false;
      updatedErrors.dropoffLastname = false;
      updatedErrors.dropoffPhone = false;
      updatedErrors.dropoffAddress = false;
    }

    if (weight === null || weight === '') {
      updatedErrors.weight = true;
    } else {
      updatedErrors.weight = false;
    }
    if (numPeople === null || numPeople === '') {
      updatedErrors.gophrs = true;
    } else {
      updatedErrors.gophrs = false;
    }
    if (vehicleType === null || vehicleType === '') {
      updatedErrors.size = true;
    } else {
      updatedErrors.size = false;
    }
    if (price || priceRangeLow) {
      updatedErrors.price = false;
    } else {
      updatedErrors.price = true;
    }

    setErrors(updatedErrors);
    setWaypointErrors(updateWaypointErrors);

    let invoiceCheck = false;
    if (isInvoice) {
      invoiceCheck = checkInvoice();
    }

    let regularCheck = Object.values(errors).includes(true);
    let waypointCheck = Object.values(waypointErrors).includes(true);
    let pickupItemsCheck = checkItemsError(pickupItems, pickupItemsError, setPickupItemsError);
    let dropoffItemsCheck = checkItemsError(dropoffItems, dropoffItemsError, setDropoffItemsError);
    let waypointPickupItemsCheck = checkItemsError(waypointPickupItems, waypointPickupItemsError, setWaypointPickupItemsError);
    let waypointDropoffItemsCheck = checkItemsError(waypointDropoffItems, waypointDropoffItemsError, setWaypointDropoffItemsError);
    let pickAndDropMatchError = checkPickAndDropMatchError();
    if (!(regularCheck || waypointCheck || pickupItemsCheck || dropoffItemsCheck || pickAndDropMatchError || invoiceCheck)) {
      if (isWaypointPickup && isWaypointDropoff) {
        if (!waypointPickupItemsCheck && !waypointDropoffItemsCheck) {
          handleForm(false, true);
          setShowForm3(true);
          setThreeStopsError(false);
        } else {
          setThreeStopsError(true);
        }
      } else if (isWaypointPickup) {
        if (!waypointPickupItemsCheck) {
          handleForm(false, true);
          setShowForm3(true);
          setThreeStopsError(false);
        } else {
          setThreeStopsError(true);
        }
      } else if (isWaypointDropoff) {
        if (!waypointDropoffItemsCheck) {
          handleForm(false, true);
          setShowForm3(true);
          setThreeStopsError(false);
        } else {
          setThreeStopsError(true);
        }
      }
    } else {
      setThreeStopsError(true);
    }
  };

  const validatePhone = phone => {
    var phoneRex = /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{10}))/;

    if (phone.replace(/[^0-9]/g, '').length > 9 && phone.replace(/[^0-9]/g, '').length < 14) {
      return phoneRex.test(String(phone));
    }
  };

  // Fully resets the component
  const reset = () => {
    let radWeight = document.getElementsByName('exampleRadio1');
    let radNumPeople = document.getElementsByName('exampleRadio2');
    let radIsExpedited = document.getElementsByName('exampleRadio3');
    let radScheduleTime = document.getElementsByName('exampleRadio4');
    let scheduleCheck = document.getElementById('schedule-check');

    handleForm(true, false);

    setPickupInfo({});
    setDropoffInfo({});
    setAddress('');
    setContainsAlcohol(false);
    setDescription('');
    setMerchantOrderID('');
    setDropoffInstruction('');
    setDropAddress1(null);
    setDropAddress2(null);
    setDropCity(null);
    setDropCountry(null);
    setDropFirstname('');
    setDropFullAddress('');
    setDropLastname('');
    setDropLat(null);
    setDropLng(null);
    setDropPhone('');
    setDropState(null);
    setDropZip(null);
    setErrors({
      pickupFirstname: false,
      pickupLastname: false,
      pickupPhone: false,
      pickupAddress: false,
      dropoffFirstname: false,
      dropoffLastname: false,
      dropoffPhone: false,
      description: false,
      weight: false,
      gophrs: false,
      size: false,
      price: false
    });
    setWaypointErrors({
      waypointFirstname: false,
      waypointLastname: false,
      waypointPhone: false,
      waypointAddress: false,
      pickOrDrop: false
    });

    setExpeditedPrice(null);
    setIsExpedited(false);
    setIsSaved(false);
    setIsScheduled(false);
    setNumPeople(null);
    setOrderStatus('awaiting_driver');
    setPickFullAddress('');
    setPickAddress1(null);
    setPickAddress2(null);
    setPickCity(null);
    setPickCountry(null);
    setPickFirstname('');
    setPickLastname('');
    setPickLat(null);
    setPickLng(null);
    setPickPhone('');
    setPickState(null);
    setPickZip(null);
    setPickupInstruction('');

    // waypoint reset
    setWaypointPickupInstruction('');
    setWaypointDropoffInstruction('');
    setWaypointAddress1(null);
    setWaypointAddress2(null);
    setWaypointCity(null);
    setWaypointCountry(null);
    setWaypointFirstname('');
    setWaypointLastname('');
    setWaypointFullAddress('');
    setWaypointLat(null);
    setWaypointLng(null);
    setWaypointPhone('');
    setWaypointState(null);
    setWaypointZip(null);

    setScheduledFor(null);
    setShipmentStatus('shipment_ready_for_driver');
    setShowForm3(false);
    setStandardPrice(null);
    setVehicleType('');
    setWeight('');

    setIsPickupStore(true);
    setIsDropoffStore(false);
    handleErrorDistance({ errorMessage: '', errorCode: 0 });
    handleErrorSubmit({ errorMessage: '', errorCode: 0 });

    // Clear the radio bubbles/checkboxes
    radWeight.forEach(element => (element.checked = false));
    radNumPeople.forEach(element => (element.checked = false));
    radIsExpedited.forEach(element => (element.checked = false));
    radScheduleTime.forEach(element => (element.checked = false));
    scheduleCheck.checked = false;

    // Clear the address input
    document.getElementById('mapbox-autocomplete').value = '';
    // ! need a way to clear mapbox
    document.getElementsByClassName('react-mapbox-ac-input')[0].value = '';

    //reset line items
    setPickupItems([{ quantity: 1, detail: '' }]);
    setDropoffItems([{ quantity: 1, detail: '' }]);
    setWaypointPickupItems([{ quantity: 1, detail: '' }]);
    setWaypointDropoffItems([{ quantity: 1, detail: '' }]);
  };

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const previousNumPeople = usePrevious(numPeople);
  const previousVehicleType = usePrevious(vehicleType);
  const previousWeight = usePrevious(weight);
  const previousDrop = usePrevious(dropoffInfo);
  const previousPick = usePrevious(pickupInfo);
  const previousWaypoint = usePrevious(waypointInfo);
  const previousAlc = usePrevious(containsAlcohol);
  const previousRoundTrip = usePrevious(isRoundTrip);

  const previousForkLift = usePrevious(isForkLift);
  const previousDolly = usePrevious(isDolly);
  const previousHazmat = usePrevious(isHazmat);
  const previousTWICCard = usePrevious(isTWICCard);
  const previousLiftgate = usePrevious(isLiftgate);
  const previousPalletJack = usePrevious(isPalletJack);
  const previousHandLoad = usePrevious(isHandLoadUnload);
  const previousWeatherProtection = usePrevious(isWeatherProtection);
  const previousMedical = usePrevious(isMedical);

  useEffect(() => {
    if (isSaved) {
      setShipmentStatus('shipment_saved');
      setOrderStatus('order_saved');
    } else {
      setShipmentStatus('shipment_ready_for_driver');
      setOrderStatus('awaiting_driver');
    }

    setShowForm(showForm);
    setErrorMessageSubmit(errorSubmit);
    setErrorMessageDistance(errorDistance);

    //! This method used to compare equality of array of objects
    var isEqual = function(value, other) {
      // Get the value type
      var type = Object.prototype.toString.call(value);

      // If the two objects are not the same type, return false
      if (type !== Object.prototype.toString.call(other)) return false;

      // If items are not an object or array, return false
      if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

      // Compare the length of the length of the two items
      var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
      var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
      if (valueLen !== otherLen) return false;

      // Compare two items
      var compare = function(item1, item2) {
        // Get the object type
        var itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
          if (!isEqual(item1, item2)) return false;
        }

        // Otherwise, do a simple comparison
        else {
          // If the two items are not the same type, return false
          if (itemType !== Object.prototype.toString.call(item2)) return false;

          // Else if it's a function, convert to a string and compare
          // Otherwise, just compare
          if (itemType === '[object Function]') {
            if (item1.toString() !== item2.toString()) return false;
          } else {
            if (item1 !== item2) return false;
          }
        }
      };

      // Compare properties
      if (type === '[object Array]') {
        for (var i = 0; i < valueLen; i++) {
          if (compare(value[i], other[i]) === false) return false;
        }
      } else {
        for (var key in value) {
          if (value.hasOwnProperty(key)) {
            if (compare(value[key], other[key]) === false) return false;
          }
        }
      }

      // If nothing failed, return true
      return true;
    };

    if (
      previousDrop != dropoffInfo ||
      previousAlc != containsAlcohol ||
      previousPick != pickupInfo ||
      previousNumPeople !== numPeople ||
      previousVehicleType !== vehicleType ||
      previousWeight !== weight ||
      previousWaypoint !== waypointInfo ||
      previousRoundTrip !== isRoundTrip ||
      previousDolly !== isDolly ||
      previousHazmat !== isHazmat ||
      previousTWICCard !== isTWICCard ||
      previousLiftgate !== isLiftgate ||
      previousPalletJack !== isPalletJack ||
      previousHandLoad !== isHandLoadUnload ||
      previousWeatherProtection !== isWeatherProtection ||
      previousMedical !== isMedical
    ) {
      // Check if form entries are valid
      let end = dropoffInfo.lng + ',' + dropoffInfo.lat;
      let start = pickupInfo.lng + ',' + pickupInfo.lat;
      let waypoint = waypointInfo.lng + ',' + waypointInfo.lat;
      //! This distance check does not make sense somehow, did not cover all cases even for 2 stops
      let requestEndpointDistanceCheck = MAPBOX_ENDPOINT + controlLong + ',' + controlLat + ';' + end + '?geometries=geojson&access_token=' + MAPBOX_TOKEN;
      let requestEndpointWithWaypoint = MAPBOX_ENDPOINT + start + ';' + waypoint + ';' + end + '?alternatives=true&geometries=geojson&access_token=' + MAPBOX_TOKEN;

      if (
        dropoffInfo.lat &&
        dropoffInfo.lng &&
        pickupInfo.lat &&
        pickupInfo.lng &&
        numPeople !== '' &&
        numPeople !== null &&
        vehicleType !== '' &&
        vehicleType !== null &&
        weight !== null &&
        weight !== '' &&
        waypointInfo.lat
      ) {
        // Get distance from MapBox
        axios
          .get(requestEndpointWithWaypoint)
          .then(response => {
            var route = response.data.routes[0];
            for (let i = 1; i < response.data.routes.length; i++) {
              if (response.data.routes[i].duration < route.duration) {
                route = response.data.routes[i];
              }
            }
            if (route) {
              setDistance(route.distance / 1609.344);
              setRoute(route);
              let standardData = {
                distance: route.distance / 1609.344,
                weight: weight,
                is_expedited: false,
                is_super_expedited: false,
                quoted_time: route.duration / 60 / 60,
                num_of_gophrs: numPeople,
                vehicle_type: vehicleType
                  .toLowerCase()
                  .split(' ')
                  .join('_'),
                contains_alcohol: containsAlcohol,
                market_id: storeMarketId,
                order_points: [[pickupInfo.lat, pickupInfo.lng], [waypointInfo.lat, waypointInfo.lng], [dropoffInfo.lat, dropoffInfo.lng]],
                num_of_drops: isWaypointDropoff ? 2 : 1,
                is_roundtrip: isRoundTrip,
                is_fork_lift: isForkLift,
                is_dolly: isDolly,
                is_hazmat: isHazmat,
                is_pallet_jack: isPalletJack,
                is_twic_card: isTWICCard,
                is_liftgate: isLiftgate,
                is_hand_loading: isHandLoadUnload,
                is_weather_protection: isWeatherProtection,
                is_medical: isMedical
              };
              console.log('standardData ', standardData);
              let expeditedData = {
                ...standardData,
                is_expedited: true
              };
              let superExpeditedData = {
                ...standardData,
                is_super_expedited: true
              };
              axios.get(requestEndpointDistanceCheck).then(response => {
                let quickDistance = route.distance / 1609.344;
                if (quickDistance > 10000) {
                  handleErrorDistance({
                    errorMessage:
                      "We're sorry, but your delivery distance of " + parseInt(quickDistance) + ' miles is too far.  Please, contact a Gophr representative for more information.',
                    errorCode: 1
                  });
                } else {
                  // Use distance to get both price quotes
                  handleErrorDistance({ errorMessage: '', errorCode: 0 });
                  axios({
                    method: 'post',
                    url: shipment_urls.getShipmentQuote,
                    withCredentials: true,
                    headers: { 'content-type': 'application/json', Authorization: ACCESS_TOKEN },
                    data: standardData
                  })
                    .then(response => {
                      handleErrorDistance({ errorMessage: '', errorCode: 0 });
                      if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler') {
                        setStandardPriceRangeLow(response.data.payload.shipping_fee_range.min);
                        setStandardPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                        setStandardPrice(response.data.payload.shipping_fee);
                      } else {
                        setStandardPrice(response.data.payload);
                      }
                    })
                    .catch(error => {
                      console.log('error: ', error.response);
                      handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 2 });
                      notify('tc', 'distance');
                    });
                  axios({
                    method: 'post',
                    url: shipment_urls.getShipmentQuote,
                    withCredentials: true,
                    headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + window.localStorage.getItem('access_token') },
                    data: expeditedData
                  })
                    .then(response => {
                      handleErrorDistance({ errorMessage: '', errorCode: 0 });
                      if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler') {
                        setExpeditedPriceRangeLow(response.data.payload.shipping_fee_range.min);
                        setExpeditedPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                        setExpeditedPrice(response.data.payload.shipping_fee);
                      } else {
                        setExpeditedPrice(response.data.payload);
                      }
                      handleForm(true, true);
                    })
                    .catch(error => {
                      console.log('error: ', error);
                      handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 3 });
                    });
                  axios({
                    method: 'post',
                    url: shipment_urls.getShipmentQuote,
                    withCredentials: true,
                    headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + window.localStorage.getItem('access_token') },
                    data: superExpeditedData
                  })
                    .then(response => {
                      handleErrorDistance({ errorMessage: '', errorCode: 0 });
                      if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler') {
                        setSuperExpeditedPriceRangeLow(response.data.payload.shipping_fee_range.min);
                        setSuperExpeditedPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                        setSuperExpeditedPrice(response.data.payload.shipping_fee);
                      } else {
                        setSuperExpeditedPrice(response.data.payload);
                      }
                      handleForm(true, true);
                    })
                    .catch(error => {
                      console.log('error: ', error);
                      handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 3 });
                    });
                }
              });
            }
          })
          .catch(error => {
            console.log('distance error: ', error.response);
            handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 3 });
            notify('tc', 'distance');
          });
      }
    }
  });

  const priceChoice = (standardPrice, expeditedPrice, superExpeditedPrice) => {
    return (
      <PriceChoices
        showForm2={showForm2}
        address={address}
        standardPrice={standardPrice}
        expeditedPrice={expeditedPrice}
        superExpeditedPrice={superExpeditedPrice}
        setIsScheduled={setIsScheduled}
        setIsExpedited={setIsExpedited}
        setIsSuperExpedited={setIsSuperExpedited}
        price={price}
        setPrice={setPrice}
        priceChoice={priceChoice}
        setIsProceeded={setIsProceeded}
        errorMessageSubmit={errorMessageSubmit}
        scheduledTime={scheduledTime}
        setScheduledTime={setScheduledTime}
        isScheduled={isScheduled}
        setScheduledDate={setScheduledDate}
        scheduledFor={scheduledFor}
        errors={errors}
        vehicleType={vehicleType}
        isProceeded={isProceeded}
        handleSubmit={handleSubmit}
        standardPriceRangeLow={standardPriceRangeLow}
        standardPriceRangeHigh={standardPriceRangeHigh}
        expeditedPriceRangeLow={expeditedPriceRangeLow}
        expeditedPriceRangeHigh={expeditedPriceRangeHigh}
        superExpeditedPriceRangeLow={superExpeditedPriceRangeLow}
        superExpeditedPriceRangeHigh={superExpeditedPriceRangeHigh}
        priceRangeLow={priceRangeLow}
      />
    );
  };

  return (
    <div>
      <Collapse id="collapse2" isOpen={showForm1}>
        {/* <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div> */}
        <CardTitle tag="h4" style={{ padding: 5, paddingTop: 10, textAlign: 'left', paddingBottom: 0 }}>
          Pickup Information
        </CardTitle>
        <PickupInfoRouted
          isPickupStore={isPickupStore}
          setIsPickupStore={setIsPickupStore}
          errors={errors}
          storeFirstName={storeFirstName}
          pickFirstname={pickFirstname}
          setPickFirstname={setPickFirstname}
          pickupCustomerSuggestions={pickupCustomerSuggestions}
          setPickupCustomerSuggestions={setPickupCustomerSuggestions}
          storeId={storeId}
          setPickLastname={setPickLastname}
          setPickPhone={setPickPhone}
          pickFullAddress={pickFullAddress}
          setPickFullAddress={setPickFullAddress}
          setPickupInstruction={setPickupInstruction}
          isPickupAutoFilled={isPickupAutoFilled}
          setIsPickupAutoFilled={setIsPickupAutoFilled}
          setPickAddress1={setPickAddress1}
          setPickAddress2={setPickAddress2}
          setPickCity={setPickCity}
          setPickState={setPickState}
          setPickZip={setPickZip}
          setPickCountry={setPickCountry}
          setPickLat={setPickLat}
          setPickLng={setPickLng}
          setPickBusinessName={setPickBusinessName}
          storeLastName={storeLastName}
          pickLastname={pickLastname}
          storePhone={storePhone}
          pickPhone={pickPhone}
          storeName={storeName}
          pickBusinessName={pickBusinessName}
          storeAddress={storeAddress}
          handlePickupSelect={handlePickupSelect}
          vendorLat={vendorLat}
          vendorLong={vendorLong}
          pickupInstruction={pickupInstruction}
          setIsWaypointStore={setIsWaypointStore}
        />
        <LineItems
          title="Pick up Item(s)"
          items={pickupItems}
          setItems={setPickupItems}
          itemsErrorArray={pickupItemsError}
          setItemsErrorArray={setPickupItemsError}
          pickList1={pickupItems}
          pickList2={waypointPickupItems}
        />
        <hr style={{ borderColor: '#344675', borderStyle: 'solid' }}></hr>
        <CardTitle tag="h4" style={{ padding: 5, paddingTop: 10, textAlign: 'left' }}>
          Pickup/Dropoff Information
        </CardTitle>
        <WaypointInfo
          isWaypointStore={isWaypointStore}
          setIsWaypointStore={setIsWaypointStore}
          setIsPickupStore={setIsPickupStore}
          isWaypointPickup={isWaypointPickup}
          setIsWaypointPickup={setIsWaypointPickup}
          isWaypointDropoff={isWaypointDropoff}
          setIsWaypointDropoff={setIsWaypointDropoff}
          waypointErrors={waypointErrors}
          storeFirstName={storeFirstName}
          waypointFirstname={waypointFirstname}
          setWaypointFirstname={setWaypointFirstname}
          waypointCustomerSuggestions={waypointCustomerSuggestions}
          setWaypointCustomerSuggestions={setWaypointCustomerSuggestions}
          storeId={storeId}
          setWaypointLastname={setWaypointLastname}
          setWaypointPhone={setWaypointPhone}
          waypointFullAddress={waypointFullAddress}
          setWaypointFullAddress={setWaypointFullAddress}
          setWaypointPickupInstruction={setWaypointPickupInstruction}
          setWaypointDropoffInstruction={setWaypointDropoffInstruction}
          isWaypointAutoFilled={isWaypointAutoFilled}
          setIsWaypointAutoFilled={setIsWaypointAutoFilled}
          setWaypointAddress1={setWaypointAddress1}
          setWaypointAddress2={setWaypointAddress2}
          setWaypointCity={setWaypointCity}
          setWaypointState={setWaypointState}
          setWaypointZip={setWaypointZip}
          setWaypointCountry={setWaypointCountry}
          setWaypointLat={setWaypointLat}
          setWaypointLng={setWaypointLng}
          setWaypointBusinessName={setWaypointBusinessName}
          storeLastName={storeLastName}
          waypointLastname={waypointLastname}
          storePhone={storePhone}
          waypointPhone={waypointPhone}
          storeName={storeName}
          waypointBusinessName={waypointBusinessName}
          storeAddress={storeAddress}
          handleWaypointSelect={handleWaypointSelect}
          vendorLat={vendorLat}
          vendorLong={vendorLong}
          errorMessageDistance={errorMessageDistance}
          errors={errors}
          waypointPickupInstruction={waypointPickupInstruction}
          waypointDropoffInstruction={waypointDropoffInstruction}
        />
        {isWaypointPickup && (
          <LineItems
            title="Pick up Item(s)"
            items={waypointPickupItems}
            setItems={setWaypointPickupItems}
            itemsErrorArray={waypointPickupItemsError}
            setItemsErrorArray={setWaypointPickupItemsError}
            pickList1={pickupItems}
            pickList2={waypointPickupItems}
          />
        )}
        {isWaypointDropoff && (
          <LineItems
            title="Drop off Item(s)"
            items={waypointDropoffItems}
            setItems={setWaypointDropoffItems}
            itemsErrorArray={waypointDropoffItemsError}
            setItemsErrorArray={setWaypointDropoffItemsError}
            pickList1={pickupItems}
            pickList2={waypointPickupItems}
          />
        )}
        <hr style={{ borderColor: '#344675', borderStyle: 'solid' }}></hr>
        <CardTitle tag="h4" style={{ padding: 5, paddingTop: 10, textAlign: 'left' }}>
          Dropoff Information
        </CardTitle>
        <DropoffInfoRouted
          isDropoffStore={isDropoffStore}
          setIsDropoffStore={setIsDropoffStore}
          errors={errors}
          storeFirstName={storeFirstName}
          dropFirstname={dropFirstname}
          setDropFirstname={setDropFirstname}
          dropCustomerSuggestions={dropCustomerSuggestions}
          setDropCustomerSuggestions={setDropCustomerSuggestions}
          storeId={storeId}
          setDropLastname={setDropLastname}
          setDropPhone={setDropPhone}
          dropFullAddress={dropFullAddress}
          setDropFullAddress={setDropFullAddress}
          setDropoffInstruction={setDropoffInstruction}
          isDropOffAutoFilled={isDropOffAutoFilled}
          setIsDropOffAutoFilled={setIsDropOffAutoFilled}
          setDropAddress1={setDropAddress1}
          setDropAddress2={setDropAddress2}
          setDropCity={setDropCity}
          setDropState={setDropState}
          setDropZip={setDropZip}
          setDropCountry={setDropCountry}
          setDropLat={setDropLat}
          setDropLng={setDropLng}
          pickFirstname={pickFirstname}
          isPickupStore={isPickupStore}
          setDropoffBusinessName={setDropoffBusinessName}
          storeLastName={storeLastName}
          dropLastname={dropLastname}
          storePhone={storePhone}
          dropPhone={dropPhone}
          storeName={storeName}
          dropoffBusinessName={dropoffBusinessName}
          storeAddress={storeAddress}
          handleDropoffSelect={handleDropoffSelect}
          vendorLat={vendorLat}
          vendorLong={vendorLong}
          dropoffInstruction={dropoffInstruction}
          isRoundTrip={isRoundTrip}
          setIsRoundTrip={setIsRoundTrip}
          pickLastname={pickLastname}
          pickPhone={pickPhone}
          pickBusinessName={pickBusinessName}
          pickFullAddress={pickFullAddress}
        />
        <LineItemsRoutedFinalDrop
          title="Drop off Item(s)"
          items={dropoffItems}
          setItems={setDropoffItems}
          itemsErrorArray={dropoffItemsError}
          setItemsErrorArray={setDropoffItemsError}
          pickList1={pickupItems}
          pickList2={waypointPickupItems}
          dropWaypointList={waypointDropoffItems}
        />
        {itemsMatchError && (
          <p style={{ color: 'red', marginTop: '4px', marginBottom: '5px' }}>Please check to make sure quantity or detail of pick up item(s) matching drop off item(s) </p>
        )}
        <hr style={{ borderColor: '#344675', borderStyle: 'solid' }}></hr>
        <CardTitle tag="h4" style={{ padding: 5, paddingTop: 10, textAlign: 'left' }}>
          Shipment Information
        </CardTitle>
        <MerchantOrderID merchantOrderID={merchantOrderID} setMerchantOrderID={setMerchantOrderID} />
        <Row className="justify-content-md-center" style={{ textAlign: 'left', marginBottom: 30, marginLeft: 30 }}>
          <WightOptions errors={errors} handleWeight={handleWeight} weight={weight} />
          <Tools
            setIsForkLift={setIsForkLift}
            isForkLift={isForkLift}
            setIsDolly={setIsDolly}
            isDolly={isDolly}
            setIsHandLoadUnload={setIsHandLoadUnload}
            isHandLoadUnload={isHandLoadUnload}
            setIsHazmat={setIsHazmat}
            isHazmat={isHazmat}
            setIsHotBag={setIsHotBag}
            isHotBag={isHotBag}
            setIsCooler={setIsCooler}
            isCooler={isCooler}
            isTWICCard={isTWICCard}
            setIsTWICCard={setIsTWICCard}
            isLiftgate={isLiftgate}
            setIsLiftgate={setIsLiftgate}
            isPalletJack={isPalletJack}
            setIsPalletJack={setIsPalletJack}
            isWeatherProtection={isWeatherProtection}
            setIsWeatherProtection={setIsWeatherProtection}
            isMedical={isMedical}
            setIsMedical={setIsMedical}
            weight={weight}
            vehicleType={vehicleType}
          />
        </Row>
        <Row className="justify-content-md-center" style={{ textAlign: 'left', marginBottom: 30, marginLeft: 30 }}>
          <Col md="auto" style={{ marginTop: 30, marginRight: 100 }}>
            <NumberOfGophrs numPeople={numPeople} setNumPeople={setNumPeople} />
          </Col>
          <Col md="auto" style={{ marginTop: 30 }}>
            <VehicleTypes weight={weight} vehicleType={vehicleType} setVehicleType={setVehicleType} />
          </Col>
        </Row>
        <div hidden={errorMessageSubmit.errorCode !== 8}>
          <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageSubmit.errorMessage}</p>
        </div>
        <ContainsAlcohol containsAlcohol={containsAlcohol} setContainsAlcohol={setContainsAlcohol} />
        {priceChoice(standardPrice, expeditedPrice, superExpeditedPrice)}
        <div hidden={!threeStopsError}>
          <p style={{ color: 'red', marginTop: '4px', paddingBottom: 20 }}>Please fix the problem(s) on this form</p>
        </div>
        <Row style={{ paddingLeft: `15px`, marginTop: '25px' }}>
          <FormGroup>
            <Button className="btn cancel-btn" color="default" onClick={reset}>
              Cancel
            </Button>
          </FormGroup>
        </Row>
      </Collapse>
    </div>
  );
};

export default RoutedCreation;
