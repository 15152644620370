import styled from 'styled-components';

export const Styles = styled.div`
    .container {   
        display: flex;
        flex-direction: column;
        background-color: #344675;
        height: 120px;
        width: 170px;
        border: transparent;
        border-radius: 5px;
        margin-right: 20px;
        margin-top:20px;
    }
    h6 {
        align-self: left;
        order: 1;
        font-weight: 600;
        font-size: 12px;
        color: #efeeee;
        padding-top: 5px;
    }
    p {
        align-self: left;
        order: 2;
        font-size: 11px;
        color: #efeeee;
        font-weight: 500;
    }
    i {
        order: 0;
        align-self: flex-end;
        padding-top: 10px;
    }
`;