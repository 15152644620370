import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Semantic from 'semantic-ui-react';

import { FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AddButton from '../../../assets/img/Group_1383@2x.png';

import { Styles } from './Styles/GeneralInfoStyles';

import * as inventory_urls from '../../../urls/api-inventory/admin';

const GeneralInfo = props => {
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  // brought from departments.js
  const [departmentNameGet, setDepartmentNameGet] = useState('');
  const [departmentDescription, setDepartmentDescription] = useState('');
  const [departmentTaxCode, setDepartmentTaxCode] = useState(null);
  const [taxCodes, setTaxCodes] = useState(null);
  const [departmentTransactionRate, setDepartmentTransactionRate] = useState(null);
  const [departmentTaxRate, setDepartmentTaxRate] = useState(null);
  const { register, errors } = useForm();
  const [showAddDepartmentDialog, setShowAddDepartmentDialog] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  // ********************************

  // brought from categories.js
  const [categoryNameGet, setCategoryNameGet] = useState('');
  const [showAddCategoryDialog, setShowAddCategoryDialog] = useState(false);
  const [categoryDescription, setCategoryDescription] = useState('');
  const [categoryTaxCode, setCategoryTaxCode] = useState(null);
  const [categoryTransactionRate, setCategoryTransactionRate] = useState(null);
  const [categoryTaxRate, setCategoryTaxRate] = useState(null);

  // *********************************

  const [departments, setDepartments] = useState([]);
  const [productDescription, setProductDescription] = useState('');
  const [productTitle, setProductTitle] = useState('');
  const [isAlcohol, setIsAlcohol] = useState(false);
  const [alcoholType, setAlcoholType] = useState(null);
  const [isFood, setIsFood] = useState(false);

  const params = useParams();

  useEffect(() => {
    props.setGeneralInfo(productTitle, productDescription, categoryId, alcoholType, isFood);
  });

  useEffect(() => {
    fetchDepartments();
    if (props.product) {
      setProductTitle(props.product.name);
      setProductDescription(props.product.description);
      setAlcoholType(props.product.alcohol_type);
      setIsFood(props.product.is_food);
    }
  }, [props.product]);

  const fetchDepartments = () => {
    axios({
      method: 'POST',
      url: inventory_urls.hasuraHost,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      data: {
        query: `{
                Stores(where: {store_id: {_eq: "${params.store_id}"}}) {
                  Departments(order_by: {createdAt: desc}) {
                    department_id
                    name
                  }
                }
              }`
      }
    })
      .then(response => {
        setDepartments(response.data.data.Stores[0].Departments);
        if (props.product) {
          setDepartmentId(props.product.department_id);
          fetchCategories(props.product.department_id, false);
        } else {
          setDepartmentId(response.data.data.Stores[0].Departments[0].department_id);
          setDepartmentName(response.data.data.Stores[0].Departments[0].name);
          fetchCategories(response.data.data.Stores[0].Departments[0].department_id, false);
        }
      })
      .catch(error => {});
  };

  const fetchCategories = (departmentId, changed) => {
    axios({
      method: 'POST',
      url: inventory_urls.hasuraHost,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      data: {
        query:
          `{
                Categories(order_by: {createdAt: desc}, where: {Department: {department_id: {_eq: "` +
          departmentId +
          `"}}}) {
                  category_id
                  name
                }
              }`
      }
    })
      .then(response => {
        setCategories(response.data.data.Categories);
        if (props.product && !changed) {
          setCategoryId(props.product.category_id);
        } else {
          setCategoryId(response.data.data.Categories[0].category_id);
          setCategoryName(response.data.data.Categories[0].name);
          var e = {};
          e.target.value = response.data.data.Categories[0].category_id;
          // console.log('e', e);
          props.change(e, 'category', 1);
        }
      })
      .catch(error => {
        // Hasura does not return errors
      });
  };

  // functions from departments.js

  // console.log("params store id",params.category_id);
  const addDepartment = () => {
    if (departmentNameGet !== '' && departmentDescription !== '') {
      axios({
        method: `post`,
        url: inventory_urls.createDepartment,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'content-type': 'application/json'
        },
        data: {
          name: departmentNameGet,
          description: departmentDescription,
          tax_rate: departmentTaxRate,
          tax_code: departmentTaxCode,
          transaction_rate: departmentTransactionRate,
          store_id: params.store_id
        }
      })
        .then(response => {
          let newDept = {
            department_id: response.data.payload.department_id,
            name: response.data.payload.name,
            description: response.data.payload.description,
            tax_rate: response.data.payload.tax_rate,
            tax_code: response.data.payload.tax_code,
            transaction_rate: response.data.payload.transaction_rate,
            is_enabled: response.data.payload.is_enabled
          };
          let depts = departments.concat(newDept);
          setDepartments(depts);
          setShowAddDepartmentDialog(false);
          axios({
            method: 'POST',
            url: inventory_urls.hasuraHost,
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('access_token'),
              'content-type': 'application/json'
            },
            data: {
              query: `{
                        Stores(where: {store_id: {_eq: "${params.store_id}"}}) {
                          Departments(order_by: {createdAt: desc}) {
                            department_id
                            name
                          }
                        }
                      }`
            }
          })
            .then(response => {
              setDepartments(response.data.data.Stores[0].Departments);
              if (props.product) {
                setDepartmentId(props.product.department_id);
                fetchCategories(props.product.department_id, false);
              } else {
                setDepartmentId(response.data.data.Stores[0].Departments[0].department_id);
                setDepartmentName(response.data.data.Stores[0].Departments[0].name);
                fetchCategories(response.data.data.Stores[0].Departments[0].department_id, false);
              }
            })
            .catch(error => {});
        })
        .catch(error => {
          setError('Unable to add department. Please try again later');
          console.log('error: ', error.response);
        });
    }
  };

  const getTaxCodes = () => {
    axios({
      method: 'GET',
      url: inventory_urls.getTaxCodes,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    })
      .then(response => {
        // Set taxCodes here:
        let newTaxCodes = [];
        response.data.payload.map((element, key) => {
          newTaxCodes.push({
            key: key,
            text: element.name,
            value: element.tax_code
          });
        });
        setTaxCodes(newTaxCodes);
        setLoading(false);
      })
      .catch(error => {
        console.log('error: ', error.response);
      });
  };

  useEffect(() => {
    getTaxCodes();
  }, []);

  // ****************************

  // functions from categories.js

  const addCategory = () => {
    if (categoryNameGet !== '' && categoryDescription !== '') {
      axios({
        method: `post`,
        url: inventory_urls.createCategory,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'content-type': 'application/json'
        },
        data: {
          name: categoryNameGet,
          description: categoryDescription,
          tax_rate: categoryTaxRate,
          transaction_rate: categoryTransactionRate / 100,
          department_id: departmentId //params.department_id does not work here because add product is linked with storeid and categoryid only.
        }
      })
        .then(response => {
          let newCat = {
            category_id: response.data.payload.category_id,
            name: response.data.payload.name,
            description: response.data.payload.description,
            tax_rate: response.data.payload.tax_rate,
            transaction_rate: response.data.payload.transaction_rate,
            tax_code: response.data.payload.tax_code,
            is_enabled: response.data.payload.is_enabled
          };
          let cats = categories.concat(newCat);
          setCategories(cats);
          setShowAddCategoryDialog(false);
          axios({
            method: 'POST',
            url: inventory_urls.hasuraHost,
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('access_token'),
              'content-type': 'application/json'
            },
            data: {
              query:
                `{
                        Categories(order_by: {createdAt: desc}, where: {Department: {department_id: {_eq: "` +
                departmentId +
                `"}}}) {
                          category_id
                          name
                        }
                      }`
            }
          })
            .then(response => {
              setCategories(response.data.data.Categories);
              if (props.product) {
                setCategoryId(props.product.category_id);
              } else {
                setCategoryId(response.data.data.Categories[0].category_id);
                setCategoryName(response.data.data.Categories[0].name);
                var e = {};
                e.target.value = response.data.data.Categories[0].category_id;
                // console.log('e', e);
                props.change(e, 'category', 1);
              }
            })
            .catch(error => {
              // Hasura does not return errors
            });
        })
        .catch(error => {
          setError('Unable to add category. Please try again later');
          console.log('error: ', error.response);
        });
    }
  };

  // **************************

  return (
    <Styles>
      <h4>General Info</h4>
      <hr style={{ borderColor: '#344675', borderStyle: 'solid' }}></hr>
      <div className="input-container">
        <FormGroup>
          <Label htmlFor="productTitle">Product Title*</Label>
          <Input
            type="text"
            name="productTitle"
            className="product-title"
            placeholder="Long-Sleeve Flannel Shirt"
            value={productTitle}
            onChange={e => {
              setProductTitle(e.target.value);
              props.change(e, 'productTitle', 1);
            }}
          />
          {props.productTitleState === 'has-danger' ? (
            <label className="error" style={{ color: '#ff0000' }}>
              This field is required
            </label>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="description">Description*</Label>
          <Input
            type="text"
            className="description"
            name="description"
            placeholder="Tip: Try to keep the description simple and understandable with a focus on the ideal buyer."
            value={productDescription}
            onChange={e => {
              setProductDescription(e.target.value);
              props.change(e, 'description', 1);
            }}
          />
          {props.descriptionState === 'has-danger' ? (
            <label className="error" style={{ color: '#ff0000' }}>
              This field is required
            </label>
          ) : null}
        </FormGroup>
      </div>
      <div className="dropdown-container">
        <FormGroup className="department">
          <Label htmlFor="department">Department*</Label>
          <div className="input-button">
            <Input
              type="select"
              name="department"
              value={departmentId}
              onChange={e => {
                setDepartmentName(e.target.name);
                fetchCategories(e.target.value, true);
                setDepartmentId(e.target.value);
                props.change(e, 'department', 1);
              }}
            >
              {/* {console.log("Department ID", departmentId)} */}
              {/* {console.log("Department Name", departmentName)} */}
              {departments.map((dept, idx) => (
                // {console.log("dept", dept)},
                <option style={{ color: 'black' }} value={dept.department_id} name={dept.name.toString()}>
                  {dept.name.toString()}
                </option>
              ))}
            </Input>

            {/* Add department Button Starts */}
            <img src={AddButton} onClick={() => setShowAddDepartmentDialog(true)} alt="..." className="plus-button"></img>
            <Modal isOpen={showAddDepartmentDialog} centered>
              <h4 style={{ textAlign: `left`, fontSize: `15px`, color: `#f15a29`, fontWeight: `500`, padding: `20px` }}>Add Department</h4>
              <ModalBody style={{ paddingTop: 0 }}>
                <FormGroup>
                  <Label style={{ color: `#494747`, fontSize: `12px` }}>Department Name*</Label>
                  <Input
                    id="department-name"
                    name="createDepartmentNameGet"
                    onChange={e => setDepartmentNameGet(e.target.value)}
                    type="text"
                    style={{ color: 'black' }}
                    placeholder="Powertools, Books, ect."
                    ref={register({
                      required: 'This field is required.',
                      minLength: {
                        value: 3,
                        message: 'Must be at least 3 characters.'
                      },
                      maxLength: {
                        value: 25,
                        message: 'Cannot be more than 25 characters.'
                      }
                    })}
                  />
                  {errors.createDepartmentNameGet && errors.createDepartmentNameGet.message}
                </FormGroup>
                <FormGroup>
                  <Label style={{ color: `#494747`, fontSize: `12px` }}>Department Description*</Label>
                  <Input
                    id="department-description"
                    name="createDepartmentDescription"
                    type="text"
                    onChange={e => setDepartmentDescription(e.target.value)}
                    style={{ color: 'black' }}
                    placeholder="Jackets, Hammers, Staff Favorites, etc."
                    ref={register({
                      required: 'This field is required.',
                      minLength: {
                        value: 3,
                        message: 'Must be at least 3 characters.'
                      },
                      maxLength: {
                        value: 25,
                        message: 'Cannot be more than 25 characters.'
                      }
                    })}
                  />
                  {errors.createDepartmentDescription && errors.createDepartmentDescription.message}
                </FormGroup>
                <FormGroup>
                  <Label style={{ color: `#494747`, fontSize: `12px` }}>Tax Category</Label>
                  {/* <Input 
                            id="department-tax-rate" 
                            onChange={(e) => setDepartmentTaxRate(e.target.value)} 
                            type="number" 
                            step="0.01" 
                            style={{color: "black"}} 
                            placeholder="10%" 
                        /> */}
                  <div>
                    <Semantic.Dropdown
                      placeholder="Tax Category"
                      button
                      style={{
                        width: '100%',
                        // backgroundColor: '#27293d',
                        borderColor: '#000'
                      }}
                      search
                      selection
                      value={departmentTaxCode}
                      onChange={(e, data) => {
                        setDepartmentTaxCode(data.value);
                      }}
                      options={taxCodes}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label style={{ color: `#494747`, fontSize: `12px` }}>Transaction Rate</Label>
                  <Input
                    id="department-transaction-rate"
                    name="department-tran-rate"
                    onChange={e => setDepartmentTransactionRate(e.target.value)}
                    type="number"
                    step="0.01"
                    style={{ color: 'black' }}
                    placeholder="10.5%"
                  />
                  {/* {console.log(departmentTransactionRate)} */}
                  <h5 style={{ color: 'gray', marginTop: 10 }}>**Empty tax and transaction rates will inherit store values</h5>
                  <div style={{ display: `flex`, flexDirection: `row-reverse`, margin: `15px 0px` }}>
                    <button
                      style={{ backgroundColor: '#cdcaca', color: `#ffffff`, width: `70px`, height: `35px`, borderRadius: `5px`, border: `transparent`, marginLeft: `5px` }}
                      onClick={() => {
                        addDepartment();
                      }}
                    >
                      Save
                    </button>
                    <button
                      style={{ backgroundColor: `#cdcaca`, color: `#ffffff`, width: `70px`, height: `35px`, borderRadius: `5px`, border: `transparent` }}
                      onClick={() => {
                        setDepartmentNameGet('');
                        setDepartmentDescription('');
                        setDepartmentTaxRate(null);
                        setDepartmentTransactionRate(null);
                        setShowAddDepartmentDialog(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </FormGroup>
              </ModalBody>
            </Modal>
            {/* Add department Button Ends */}

            {/* {props.departmentState === "has-danger" ? (
                        <label className="error">
                            "This field is required."
                        </label>
                    ) : null} */}
          </div>
        </FormGroup>
        <FormGroup className="collection">
          <Label htmlFor="category">Category*</Label>
          <div className="input-button">
            <Input
              type="select"
              name="category"
              value={categoryId}
              onChange={e => {
                setCategoryName(e.target.name);
                setCategoryId(e.target.value);
                props.change(e, 'category', 1);
              }}
            >
              {categories.map(cat => (
                <option style={{ color: 'black' }} value={cat.category_id} name={cat.name.toString()}>
                  {cat.name.toString()}
                </option>
              ))}
            </Input>

            {/* Add category Button Starts */}

            <img src={AddButton} onClick={e => setShowAddCategoryDialog(true)} alt="..." className="plus-button"></img>
            <Modal isOpen={showAddCategoryDialog} centered>
              <h4 style={{ textAlign: `left`, fontSize: `15px`, color: `#f15a29`, fontWeight: `500`, padding: `20px` }}>Add Category</h4>
              <ModalBody style={{ paddingTop: 0 }}>
                <FormGroup>
                  <Label style={{ color: `#494747`, fontSize: `12px` }}>Category Name*</Label>
                  <Input
                    id="category-name"
                    name="createCategoryNameGet"
                    type="text"
                    onChange={e => setCategoryNameGet(e.target.value)}
                    style={{ color: 'black' }}
                    placeholder="Powertools, Books, ect."
                    ref={register({
                      required: 'This field is required.'
                    })}
                  />
                  {errors.createCategoryNameGet}
                </FormGroup>
                <FormGroup>
                  <Label style={{ color: `#494747`, fontSize: `12px` }}>Category Description*</Label>
                  <Input
                    id="category-description"
                    name="createCategoryDescription"
                    type="text"
                    onChange={e => setCategoryDescription(e.target.value)}
                    style={{ color: 'black' }}
                    placeholder="Jackets, Hammers, Staff Favorites, etc."
                    ref={register({
                      required: 'This field is required.'
                    })}
                  />
                  {errors.createCategoryDescription}
                </FormGroup>
                <FormGroup>
                  <Label style={{ color: `#494747`, fontSize: `12px` }}>Tax Category</Label>
                  {/* <Input 
                            id="category-tax-rate" 
                            type="number" 
                            step="0.01" 
                            onChange={(e) => setCategoryTaxRate(e.target.value)} 
                            style={{color: "black"}} 
                            placeholder="10%" 
                        /> */}
                  <div>
                    <Semantic.Dropdown
                      placeholder="Tax Categories"
                      button
                      style={{
                        width: '100%',
                        // backgroundColor: '#27293d',
                        borderColor: '#000'
                        // color: '#efeeee'
                      }}
                      search
                      selection
                      value={categoryTaxCode}
                      onChange={(e, data) => {
                        setCategoryTaxCode(data.value);
                      }}
                      // labeled
                      options={taxCodes}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label style={{ color: `#494747`, fontSize: `12px` }}>Transaction Rate(%)</Label>
                  <Input
                    id="department-transaction-rate"
                    onChange={e => setCategoryTransactionRate(e.target.value)}
                    type="number"
                    step="0.01"
                    style={{ color: 'black' }}
                    placeholder="10.5"
                  />
                  <h5 style={{ color: 'gray', marginTop: 10 }}>**Empty tax and transaction rates will inherit store values</h5>
                  <div style={{ display: `flex`, flexDirection: `row-reverse`, margin: `15px 0px` }}>
                    <button
                      style={{ backgroundColor: 'orange', color: `#ffffff`, width: `70px`, height: `35px`, borderRadius: `5px`, border: `transparent`, marginLeft: `5px` }}
                      onClick={() => {
                        addCategory();
                      }}
                    >
                      Save
                    </button>
                    <button
                      style={{ backgroundColor: `#344675`, color: `#ffffff`, width: `70px`, height: `35px`, borderRadius: `5px`, border: `transparent` }}
                      onClick={() => {
                        setCategoryNameGet('');
                        setCategoryDescription('');
                        setCategoryTaxRate(null);
                        setCategoryTransactionRate(null);
                        setShowAddCategoryDialog(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </FormGroup>
              </ModalBody>
            </Modal>
            {/* Add category Button Ends */}

            {/* {props.categoryState === "has-danger" ? (
                            <label className="error">
                                "This field is required."
                            </label>
                        ) : null} */}
          </div>
          {props.roles[0] < 200 ? (
            <>
              <div>
                <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
                  <Label check>
                    <Input
                      checked={isFood}
                      type="checkbox"
                      id="food-check"
                      onClick={e => {
                        setIsFood(!isFood);
                      }}
                    />
                    <span className="form-check-sign" />
                    This is a <strong>food</strong> item
                  </Label>
                </FormGroup>
              </div>
              <div>
                <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
                  <Label check>
                    <Input
                      checked={isAlcohol}
                      type="checkbox"
                      id="alcohol-check"
                      onClick={e => {
                        setIsAlcohol(!isAlcohol);
                        if (isAlcohol) {
                          setAlcoholType('beer');
                        }
                      }}
                    />
                    <span className="form-check-sign" />
                    This is an <strong>alcoholic</strong> item
                  </Label>
                </FormGroup>
              </div>
              {isAlcohol ? (
                <div>
                  <Input
                    type="select"
                    name="alcohol"
                    // value={alcoholType}
                    onChange={e => {
                      setAlcoholType(e.target.value);
                    }}
                  >
                    {/* {console.log("Department ID", departmentId)} */}
                    {/* {console.log("Department Name", departmentName)} */}
                    <option style={{ color: 'black' }} value={'beer'}>
                      Beer
                    </option>
                    <option style={{ color: 'black' }} value={'wine'}>
                      Wine
                    </option>
                    <option style={{ color: 'black' }} value={'sparkling_wine'}>
                      Sparkling Wine
                    </option>
                    <option style={{ color: 'black' }} value={'spirits'}>
                      Spirits
                    </option>
                    )
                  </Input>
                </div>
              ) : null}
            </>
          ) : null}
        </FormGroup>
      </div>
    </Styles>
  );
};

export default GeneralInfo;
