import React, { useState, useEffect } from 'react';
import { Input, ListGroup, ListGroupItem } from 'reactstrap';
import * as shipment_urls from '../../../../urls/api-shipments/merchant';
import axios from 'axios';

const DropOffCustomerAutoComplete = ({
  isDropoffStore,
  storeFirstName,
  dropFirstname,
  setDropFirstname,
  dropCustomerSuggestions,
  setDropCustomerSuggestions,
  storeId,
  setDropLastname,
  setDropPhone,
  dropFullAddress,
  setDropFullAddress,
  setDropoffInstruction,
  isDropOffAutoFilled,
  setIsDropOffAutoFilled,
  setDropAddress1,
  setDropAddress2,
  setDropCity,
  setDropState,
  setDropZip,
  setDropCountry,
  setDropLat,
  setDropLng,
  isRoundTrip,
  pickFirstname,
  isPickupStore,
  hasThirdWaypoint,
  setDropoffBusinessName
}) => {
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState('');
  const [hasSelected, setHasSelected] = useState(false);
  const [focus, setFocus] = useState(true);

  const reset = () => {
    setDropFirstname('');
    setDropCustomerSuggestions('');
    setDropLastname('');
    setDropPhone('');
    setDropFullAddress('');
    setDropoffInstruction('');
    setIsDropOffAutoFilled(false);
    setDropAddress1('');
    setDropAddress2('');
    setDropCity('');
    setDropState('');
    setDropZip('');
    setDropCountry('');
    setDropLat('');
    setDropLng('');
    setDropoffBusinessName('');
  };

  useEffect(() => {
    if (!focus && !hasSelected) {
      setShowSuggestions(false);
    }
  }, [focus, hasSelected]);

  useEffect(() => {
    if (dropCustomerSuggestions.length > 0) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [dropCustomerSuggestions]);

  useEffect(() => {
    if (!hasSelected) {
      fetchCustomer(input, storeId);
    }
  }, [input, hasSelected]);

  useEffect(() => {
    if (input == '' && isDropOffAutoFilled) {
      reset();
    }
    // setIsDropOffAutoFilled(true);
  }, [input]);

  useEffect(() => {
    if (isDropOffAutoFilled) {
      setIsDropOffAutoFilled(false);
    }
  }, [dropFullAddress]);

  const fetchCustomer = (customerName, storeId) => {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        query:
          `{
          direct_customer_search(args: {customer_name: "` +
          customerName +
          `", st_id: "` +
          storeId +
          `"}) {
            drop_off_instructions
            pick_up_instructions
            location_blob
          }
        }`
      }
    })
      .then(response => {
        if (response.data.data) {
          setDropCustomerSuggestions(response.data.data.direct_customer_search);
          //   console.log('response ', response.data.data);
        } else {
          console.log('getting autofill customer error: ', response);
        }
      })
      .catch(error => {
        console.log('autofill-error ', error);
      });
  };

  const onChange = e => {
    if (isDropoffStore) {
      return;
    }

    const userInput = e.target.value;

    setInput(userInput);
    setDropFirstname(userInput);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const onClick = index => {
    let selectedInfo = dropCustomerSuggestions[index];
    setHasSelected(true); //! solve the suggestion shows up again after the click
    // console.log('dropOff-selected ', selectedInfo);
    setIsDropOffAutoFilled(true);
    setDropFirstname(selectedInfo.location_blob.first_name);
    setDropLastname(selectedInfo.location_blob.last_name);
    setDropPhone(selectedInfo.location_blob.phone);
    setDropFullAddress(selectedInfo.location_blob.address);
    setDropAddress1(selectedInfo.location_blob.address_1);
    setDropAddress2(selectedInfo.location_blob.address_2);
    setDropCity(selectedInfo.location_blob.city);
    setDropState(selectedInfo.location_blob.state);
    setDropZip(selectedInfo.location_blob.zip);
    setDropCountry(selectedInfo.location_blob.country);
    setDropLat(selectedInfo.location_blob.lat);
    setDropLng(selectedInfo.location_blob.lng);
    setDropoffInstruction(selectedInfo.pick_up_instructions);
    if (selectedInfo.location_blob.business_name) {
      setDropoffBusinessName(selectedInfo.location_blob.business_name);
    }
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    setDropCustomerSuggestions([]);
    setHasSelected(false);
    // setIsDropOffAutoFilled(false);
  };

  // const onKeyDown = e => {
  //   // User pressed the enter key
  //   if (e.keyCode === 13) {
  //     setDropFirstname(filteredSuggestions[activeSuggestionIndex]);
  //     setInput(filteredSuggestions[activeSuggestionIndex]);
  //     setActiveSuggestionIndex(0);
  //     setShowSuggestions(false);
  //   }
  //   // User pressed the up arrow
  //   else if (e.keyCode === 38) {
  //     if (activeSuggestionIndex === 0) {
  //       return;
  //     }

  //     setActiveSuggestionIndex(activeSuggestionIndex - 1);
  //   }
  //   // User pressed the down arrow
  //   else if (e.keyCode === 40) {
  //     if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
  //       return;
  //     }

  //     setActiveSuggestionIndex(activeSuggestionIndex + 1);
  //   }
  // };

  const SuggestionsListComponent = () => {
    return dropCustomerSuggestions.length ? (
      <ListGroup flush>
        {dropCustomerSuggestions.map((suggestion, index) => {
          if (index < 5) {
            return (
              <ListGroupItem
                style={{ backgroundColor: '#1e1e2b', border: 'none', color: '#cbcac9' }}
                onMouseOver={e => (e.target.style.background = 'linear-gradient(90deg, hsla(218, 100%, 42%, 0.6) 0%, hsla(0, 0%, 98%, 1) 200%)')}
                onMouseLeave={e => (e.target.style.background = '#1e1e2b')}
                key={index}
                onClick={() => onClick(index)}
              >
                {`${suggestion.location_blob.name}, ${suggestion.location_blob.phone}, ${suggestion.location_blob.address_1}`}
              </ListGroupItem>
            );
          }
        })}
      </ListGroup>
    ) : null;
  };

  return (
    <>
      <Input
        type="text"
        onChange={onChange}
        onBlur={() => {
          setTimeout(() => {
            setFocus(false);
          }, '200');
        }}
        onClick={() => setFocus(true)}
        // onKeyDown={onKeyDown}
        required
        readOnly={isDropoffStore}
        name="storeFirstName"
        id="customer-first-name"
        placeholder="John"
        // value={isRoundTrip && isPickupStore ? storeFirstName : isRoundTrip && !isPickupStore ? pickFirstname : isDropoffStore ? storeFirstName : dropFirstname}
        // prettier-ignore
        value={hasThirdWaypoint ? (isRoundTrip ? (isPickupStore ? storeFirstName :  pickFirstname ) : (isDropoffStore ? storeFirstName : dropFirstname)): (isDropoffStore ? storeFirstName : dropFirstname)}
      />
      {showSuggestions && input && <SuggestionsListComponent />}
    </>
  );
};

export default DropOffCustomerAutoComplete;
