import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { GoogleApiWrapper } from 'google-maps-react';
import config from '../../config';

const GoogleMapAutoComplete = props => {
  const [hidden, setHidden] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [query, setQuery] = useState(props.query || '');
  const [queryResults, setQueryResults] = useState([]);
  const [resultClicked, setResultClicked] = useState(false);
  const [index, setIndex] = useState(props.index || 0);

  const wrapperRef = useRef(null);

  const updateQuery = event => {
    setResultClicked(false);
    setQuery(event.target.value);
    setHidden(false);

    // Call the function to perform the Autocomplete search
    performAutocompleteSearch(event.target.value);
  };

  const performAutocompleteSearch = query => {
    const { google } = props;
    const autocompleteService = new google.maps.places.AutocompleteService();

    if (query.length < 6) {
      // Reset the error state and query results when the query is less than 6 characters
      setError(false);
      setErrorMsg('');
      setQueryResults([]);
      return;
    }

    const request = {
      input: query,
      componentRestrictions: { country: 'us' } // Adjust this based on your requirements
    };

    autocompleteService.getPlacePredictions(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        setError(false);
        setQueryResults(results);
      } else {
        setError(true);
        setErrorMsg('There was a problem. Please try again.');
        setQueryResults([]);
      }
    });
  };

  const resetSearch = () => {
    setQuery('');
    setQueryResults([]);
  };

  const onSuggestionSelect = place => {
    setResultClicked(true);
    // Fetch the detailed information for the selected place using the place_id
    const { google } = props;
    const placesService = new google.maps.places.PlacesService(document.createElement('div'));

    const request = {
      placeId: place.place_id,
      fields: ['name', 'geometry', 'formatted_address', 'address_components']
    };

    placesService.getDetails(request, (placeDetails, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        // Get the GPS coordinates
        const lat = placeDetails.geometry.location.lat();
        const lng = placeDetails.geometry.location.lng();
        // Get the formatted address and address components
        const formattedAddress = placeDetails.formatted_address;
        // Pass the selected place and additional information to the onSuggestionSelect prop
        props.onSuggestionSelect(formattedAddress, lat, lng, index);
      } else {
        // Handle the error if the detailed information is not available
        // You can still pass the basic information to the onSuggestionSelect prop
        // props.onSuggestionSelect(place);
        console.log('Can not get detail information about the place');
      }
    });
  };

  useEffect(() => {
    const handleOutsideClick = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (!hidden) {
          setHidden(true);
        }
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [hidden]);

  return (
    <div>
      <form autoComplete="off">
        <input autoComplete="off" hidden={true}></input>
        <Input
          placeholder={props.placeholder || 'Search'}
          className={props.inputClass ? props.inputClass + ' react-google-ac-input' : 'react-google-ac-input'}
          onChange={updateQuery}
          value={resultClicked ? props.value : props.defaultValue ? props.defaultValue : query}
          readOnly={props.defaultValue}
          type="text"
          id="google-autocomplete"
          onFocus={() => {
            setHidden(false);
          }}
        />
        <span ref={wrapperRef} hidden={hidden}>
          <div className="react-google-ac-menu" style={queryResults.length > 0 || error ? { display: 'block' } : { display: 'none' }} onClick={resetSearch}>
            {queryResults.map((place, i) => {
              return (
                <div className="react-google-ac-suggestion" onClick={() => onSuggestionSelect(place)} key={i} style={{ fontSize: props.fontSize }}>
                  {place.description}
                </div>
              );
            })}

            {error && <div className="react-google-ac-suggestion">{errorMsg}</div>}
          </div>
        </span>
      </form>
    </div>
  );
};

GoogleMapAutoComplete.propTypes = {
  inputClass: PropTypes.string,
  publicKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onSuggestionSelect: PropTypes.func.isRequired,
  country: PropTypes.string,
  query: PropTypes.string,
  resetSearch: PropTypes.bool
};

export default GoogleApiWrapper({
  apiKey: config.config.GOOGLE_MAP.accessKey
})(GoogleMapAutoComplete);
