export function getCustomerNameFromRoute(shipments) {
  let customers = [];
  shipments.forEach(shipment => {
    let shipmentType = shipment.shipment_type;

    if (shipmentType === 'direct' || shipmentType === 'routed_direct' || shipmentType === 'multidrop_direct' || shipmentType === 'school_lunch') {
      customers.push(shipment.customer_blob.store_name);
    } else {
      customers.push(shipment.customer_blob.first_name + ' ' + shipment.customer_blob.last_name);
    }
  });
  // Use a Set to remove duplicate elements
  const uniqueCustomers = [...new Set(customers)];

  // Join elements with "/" if there's more than one
  if (uniqueCustomers.length > 1) {
    return uniqueCustomers.join('/');
  } else {
    return uniqueCustomers[0];
  }
}
