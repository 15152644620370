import { Component } from 'react';

export default class Polygon extends Component {
  static rectangle = [];
  renderPolygons() {
    const { id, map, maps, routes } = this.props;
    const hexColors = ['#FF0000', '#3299CC', '#912CEE', '#E3701A	', '#ECC8EC', '#FFE303', '#7FFFD4	', '#87421F', '#EE00EE	', '#5DFC0A'];

    for (let i = 0; i < Polygon.rectangle.length; i++) {
      Polygon.rectangle[i].setMap(null);
    }

    routes.map((route, index) => {
      let coord = [
        { lat: route.bounds.min_lat, lng: route.bounds.min_lng },
        { lat: route.bounds.min_lat, lng: route.bounds.max_lng },
        { lat: route.bounds.max_lat, lng: route.bounds.max_lng },
        { lat: route.bounds.max_lat, lng: route.bounds.min_lng },
        { lat: route.bounds.min_lat, lng: route.bounds.min_lng }
      ];
      let poly = addPoly(coord, hexColors[index % hexColors.length], 0.8, 2, hexColors[index % hexColors.length], 0.35);
      poly.setMap(map);
      Polygon.rectangle[index] = poly;
      Polygon.rectangle[index].setMap(map);
    });

    function addPoly(coorArray, sColor, sOpacity, weight, fColor, fOpacity) {
      let poly = new maps.Polygon({
        paths: coorArray,
        strokeColor: sColor,
        strokeOpacity: sOpacity,
        strokeWeight: weight,
        fillColor: fColor,
        fillOpacity: fOpacity
      });
      return poly;
    }
  }

  render() {
    this.renderPolygons();
    return null;
  }
}
