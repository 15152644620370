import React from 'react';

const PickupDetailsReview = ({ pickupInfo, pickupInstruction }) => {
  return (
    <>
      <div style={{ color: '#fff', fontStyle: 'bold' }}>Pickup Details</div>
      <div style={{ color: '#cdcaca' }}>{pickupInfo.name ? pickupInfo.name : null}</div>
      <div style={{ color: '#cdcaca' }}>{pickupInfo.phone ? pickupInfo.phone : null}</div>
      <div style={{ color: '#cdcaca', marginTop: '10px', marginBottom: '10px' }}>{pickupInfo.address}</div>
      <div hidden={pickupInstruction !== '' && pickupInstruction !== null ? false : true} style={{ color: '#fff', fontStyle: 'bold' }}>
        Pickup Instructions
      </div>
      <div hidden={pickupInstruction !== '' ? false : true} style={{ color: '#cdcaca' }}>
        {pickupInstruction}
      </div>
    </>
  );
};

export default PickupDetailsReview;
