import React from 'react';
import { Link } from 'react-router-dom';
import { Styles } from './Styles/HeaderStyles';
import SubmitButtons from './SubmitButtons';

const Header = props => {
    return (
        <Styles className="header-container">
            <div className="left-header-content">
                <Link className="header-link" to="#">Storefront</Link>
                <h4>{props.showEdit ? 'Edit Product' : 'Add Product'}</h4>
            </div>
            <SubmitButtons storeId={props.storeId} isValidated={props.isValidated}/>
        </Styles>
    )
}

export default Header;