// Imports
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { Form } from 'reactstrap';

import Header from './Header';
import GeneralInfo from './GeneralInfo';
import ProductImage from './ProductImage';
import Variant from './Variant';
import Dimensions from './Dimensions';
import Price from './Price';
import SubmitButtons from './SubmitButtons';
import Loader from 'react-loader-spinner';
import { Modal, ModalHeader } from 'reactstrap'

import * as inventory_urls from '../../../urls/api-inventory/admin';
import * as validators from '../../../helpers/validators';
import Loading from '../../Global/Loading';
import getRoles from '../../../Auth/getRoles';

const Styles = styled.div`
    width: 100%;
    margin: -40px 0 0px 0px;
        .right-header-content {
            display: flex;
            flex-direction: row;
        }
`;

export default function AddProductContainer (props) {
    const history = useHistory();
    const params = useParams();
    const [role, setRole] = useState("99999");
    const [hasError, setHasError] = useState(null);
    const [storeId, setStoreId] = useState(params.store_id);
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState(null);
    const [productTitle, setProductTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [category, setCategory] = useState("");
    const [department, setDepartment] = useState("");
    const [image, setImage] = useState(null);
    const [price, setPrice] = useState(null);
    const [tax, setTax] = useState(null);
    const [transactionRate, setTransactionRate] = useState(null);
    const [promotionalRate, setPromotionalRate] = useState(null);
    const [variants, setVariants] = useState([
        {
            name: null,
            min_required: 1,
            max_allowed: 1,
            options: []
        }
    ]);
    const [hasVariants, setHasVariants] = useState(false);
    const [weight, setWeight] = useState(null);
    const [length, setLength] = useState(null);
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const [sku, setSku] = useState(null);
    const [upc, setUpc] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [msrp, setMsrp] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const [productTitleState, setProductTitleState] = useState("");
    const [descriptionState, setDescriptionState] = useState("");
    const [departmentState, setDepartmentState] = useState("");
    const [categoryState, setCategoryState] = useState("");
    const [priceState, setPriceState] = useState("");
    const [taxState, setTaxState] = useState("");
    const [isTaxed, setIsTaxed] = useState(false);

    const [weightState, setWeightState] = useState("");
    const [lengthState, setLengthState] = useState("");
    const [widthState, setWidthState] = useState("");
    const [heightState, setHeightState] = useState("");
    const [inputState, setInputState] = useState("");

    const [alcoholType, setAlcoholType] = useState(null);
    const [isFood, setIsFood] = useState(false);

    useEffect(() => {
        if (props.location.state.isEdit) {
            setIsEdit(true);
            axios({
                method: 'POST',
                url: inventory_urls.hasuraHost,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                },
                data: {query: `{
                    Products(where: {product_id: {_eq: "` + props.location.state.product + `"}}) {
                      category_id
                      department_id
                      description
                      height
                      image
                      length
                      alcohol_type
                      is_food
                      msrp
                      name
                      price
                      promotional_rate
                      quantity_on_hand
                      sku
                      store_id
                      tax_rate
                      tax_code
                      transaction_rate
                      upc
                      weight
                      width
                      Variants {
                        name
                        min_required
                        max_allowed
                        variant_id
                        product_id
                        options:VariantOptions {
                          image
                          option_id
                          price
                          quantity
                          sku
                          value
                          variant_id
                        }
                      }
                    }
                  }`}
            }).then(response => {
                if (response.data.data) {
                    setProduct(response.data.data.Products[0]);
                    setLoading(false);
                }
                else {
                    console.log("error: ", response.data.errors[0]);
                }
            })
        } else {
            setLoading(false);
        }
    }, [props.location.state])

    useEffect(() => {
        getRoles().then(val => {
            if(val === "error") {
              history.push("/logout");
            } else {
              setRole(val)
            }
        })
    }, [])

    const fetchGeneralInfo = (title, description, category, alcoholType, isFood) => {
        setProductTitle(title);
        setDescription(description);
        setCategory(category);
        setAlcoholType(alcoholType);
        setIsFood(isFood);
    }

    const fetchImage = (image) => {
        setImage(image);
    }

    const fetchPrice = (isTaxed, price, tax, msrp, trans, promo) => {
        setIsTaxed(isTaxed);
        setPrice(price);
        setTax(tax);
        setMsrp(msrp);
        setTransactionRate(trans);
        setPromotionalRate(promo);
    }

    const fetchVariants = (isVariants, variants, quant, sku, upc) => {
        if (isVariants) {
            setHasVariants(true);
            setVariants(variants);
        } else if (isVariants === false) {
            setHasVariants(false);
            setVariants([]);
        }
        setQuantity(quant);
        setSku(sku);
        setUpc(upc)
    }

    const fetchDimensions = (weight, length, width, height) => {
        setWeight(weight);
        setLength(length);
        setWidth(width);
        setHeight(height);
    }

    const getData = () => {
        if (isEdit) {
            editProduct();
        } else {
            createProduct();
        }
    }

    const setValidation = () => {
        if(productTitle !== null || productTitle !== "") {
            setProductTitleState("has-success");
        }
        else {
            setProductTitleState("has-danger");
        }
    }

    const isValidated = () => {
        // setValidation();
        // if(
        //     productTitleState === "has-success" && 
        //     descriptionState === "has-success" && 
        //     priceState === "has-success" && 
        //     ((isTaxed && (taxState === "has-success")) || !isTaxed) && 
        //     weightState === "has-success" && 
        //     lengthState === "has-success" && 
        //     widthState === "has-success" && 
        //     heightState === "has-success"
        // ) {
        if (!productTitle || !description || !price) {
            setHasError(true);
        } else if (isTaxed && !tax) {
            setHasError(true)
        } else {
            setHasError(false);
            getData();
        }
        //     return true;
        // } else {
        //     if (productTitleState !== "has-success") {
        //         setProductTitleState("has-danger");
        //     }
        //     if(descriptionState !== "has-success") {
        //         setDescriptionState("has-danger");
        //     }
        //     if(priceState !== "has-success") {
        //         setPriceState("has-danger");
        //     }
        //     if (isTaxed) {
        //         if(taxState !== "has-success") {
        //             setTaxState("has-danger");
        //         }
        //     }
        //     if(weightState !== "has-success") {
        //         setWeightState("has-danger");
        //     }
        //     if(lengthState !== "has-success") {
        //         setLengthState("has-danger");
        //     }
        //     if(widthState !== "has-success") {
        //         setWidthState("has-danger");
        //     }
        //     if(heightState !== "has-success") {
        //         setHeightState("has-danger");
        //     }
        //     return false;
        // }
        return true;
    }

    const change = (e, type, stateNameEqualTo) => {
        switch(type) {
            case "productTitle":
                if(validators.verifyLength(e.target.value, stateNameEqualTo)) {
                    setProductTitleState("has-success");
                } else {
                    setProductTitleState("has-danger");
                }
                setProductTitle(e.target.value);
                break;
            case "description":
                if(validators.verifyLength(e.target.value, stateNameEqualTo)) {
                    setDescriptionState("has-success");
                } else {
                    setDescriptionState("has-danger");
                }
                setDescription(e.target.value);
                break;
            case "price":
                if(validators.verifyLength(e.target.value, stateNameEqualTo)) {
                //  && validators.verifyNumber(e.target.value, stateNameEqualTo)) {
                    setPriceState("has-success");
                } else {
                    setPriceState("has-danger");
                }
                setPrice(e.target.value);
                break;
            case "tax":
                if(validators.verifyLength(e.target.value, stateNameEqualTo)) {
                // && validators.verifyNumber(e.target.value, stateNameEqualTo)) {
                    setTaxState("has-success");
                } else {
                    setTaxState("has-danger");
                }
                setTax(e.target.value);
                break;
            case "weight":
                if(validators.verifyLength(e.target.value, stateNameEqualTo)) {
                // && validators.verifyNumber(e.target.value, stateNameEqualTo)) {
                    setWeightState("has-success");
                } else {
                    setWeightState("has-danger");
                }
                setWeight(e.target.value);
                break;
            case "height":
                if(validators.verifyLength(e.target.value, stateNameEqualTo)) {
                // && validators.verifyNumber(e.target.value, stateNameEqualTo)) {
                    setHeightState("has-success");
                } else {
                    setHeightState("has-danger")
                }
                setHeight(e.target.value);
                break;
            case "width":
                if(validators.verifyLength(e.target.value, stateNameEqualTo)) {
                //  && validators.verifyNumber(e.target.value, stateNameEqualTo)) {
                    setWidthState("has-success");
                } else {
                    setWidthState("has-danger");
                }
                setWidth(e.target.value);
                break;
            case "length":
                if(validators.verifyLength(e.target.value, stateNameEqualTo)) {
                //  && validators.verifyNumber(e.target.value, stateNameEqualTo)) {
                    setLengthState("has-success");
                } else {
                    setLengthState("has-danger");
                }
                setLength(e.target.value);
                break;
                default: break;
        }
    }


    const editProduct = () => {
        setLoading(true);
        let thisMsrp = null;
        let thisTrans = null;
        let thisPromo = null;
        let thisTax = null;

        if (msrp !== null && msrp !== "") {
            thisMsrp = parseFloat(msrp);
        }
        if (transactionRate !== null && transactionRate !== "") {
            thisTrans = parseFloat(transactionRate / 100);
        }
        if (promotionalRate !== null && promotionalRate !== "") {
            thisPromo = parseFloat(promotionalRate / 100);
        }
        // if (isTaxed) {
        //     thisTax = tax / 100;
        // }

        let data = {
            "sku": sku,
            "upc": upc,
            "name": productTitle,
            "description": description,
            // "image": image,
            "category_id": category,
            "quantity_on_hand": parseInt(quantity),
            "msrp": thisMsrp,
            "price": parseFloat(price),
            "tax_rate": tax,
            "tax_code": tax,
            "is_food": isFood,
            // "is_alcohol": alcoholType ? true : false,
            "alcohol_type": alcoholType,
            "transaction_rate": thisTrans,
            "promotional_rate": thisPromo,
            "weight": parseFloat(weight),
            "length": parseFloat(length),
            "width": parseFloat(width),
            "height": parseFloat(height),
            "variants": variants
        }

        axios({
            method: 'PUT',
            url: inventory_urls.editProduct + props.location.state.product,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
            data: data
        }).then(response => {
            if (image) {
                axios({
                    method: 'POST',
                    url: inventory_urls.addImage + response.data.payload.product_id + "/image",
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem('access_token'),
                        "content-type": "application/json"
                    },
                    data: {"data": image}
                }).then(response => {
                    setLoading(false)
                    history.push("/admin/inventory/store/" + params.store_id);
                }).catch(error => {
                    setLoading(false);
                    if (error.response) {
                        console.log("error: ", error.response);
                    } else {
                        console.log("error: ", error);
                    }
                });
            } else {
                setLoading(false);
                history.push("/admin/inventory/store/" + params.store_id);
            }
        }).catch(error => {
            setLoading(false);
            if (error.response) {
                console.log("error: ", error.response);
            } else {
                console.log("error: ", error);
            }
        })
    }

    const createProduct = () => {
        setLoading(true);
        let thisMsrp = null;
        let thisTrans = null;
        let thisPromo = null;
        let thisTax = null;
        let thisVariants = null;

        if (msrp !== null && msrp !== "") {
            thisMsrp = parseFloat(msrp);
        }
        if (transactionRate !== null && transactionRate !== "") {
            thisTrans = parseFloat(transactionRate / 100);
        }
        if (promotionalRate !== null && promotionalRate !== "") {
            thisPromo = parseFloat(promotionalRate / 100);
        }
        // if (isTaxed) {
        //     thisTax = tax / 100;
        // }
        if (hasVariants) {
            thisVariants = variants;
        }

        let data = {
            "sku": sku,
            "upc": upc,
            "name": productTitle,
            "description": description,
            "category_id": category,
            "quantity_on_hand": parseInt(quantity),
            "msrp": thisMsrp,
            "price": parseFloat(price),
            "tax_rate": tax,
            "is_food": isFood,
            // "is_alcohol": alcoholType ? true : false,
            "alcohol_type": alcoholType,
            // "tax_code": tax ? tax.toString() : "",
            "transaction_rate": thisTrans,
            "promotional_rate": thisPromo,
            "weight": parseFloat(weight),
            "length": parseFloat(length),
            "width": parseFloat(width),
            "height": parseFloat(height),
            "variants": thisVariants
        }
        axios({
            method: 'POST',
            url: inventory_urls.createProduct,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
            data: data
        }).then(response => {
            if (image) {
                axios({
                    method: 'POST',
                    url: inventory_urls.addImage + response.data.payload.product_id + "/image",
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem('access_token'),
                        "content-type": "application/json"
                    },
                    data: {"data": image}
                }).then(response => {
                    setLoading(false)
                    history.push("/admin/inventory/store/" + params.store_id);
                }).catch(error => {
                    setLoading(false);
                    if (error.response) {
                        console.log("error: ", error.response);
                    } else {
                        console.log("error: ", error);
                    }
                });
            } else {
                setLoading(false);
                history.push("/admin/inventory/store/" + params.store_id);
            }
        }).catch(error => {
            setLoading(false);
            if (error.response) {
                console.log("error: ", error.response);
            } else {
                console.log("error: ", error);
            }
        });
    }

    // if (loading) return <Loading />
    return (
        // <>
        <Styles className="add-product-container">
            {/* <Form onSubmit={e => e.preventDefault()}> */}
                <Header storeId={storeId} isValidated={isValidated} showEdit={props.location.state.isEdit}/>
                <div hidden={!hasError} style={{color: 'red'}}>Please, fill out all required fields before pressing the Save button</div>
                <GeneralInfo 
                    setGeneralInfo={fetchGeneralInfo} 
                    product={product} 
                    change={change}
                    productTitleState={productTitleState}
                    descriptionState={descriptionState}
                    roles={role}
                />
                <ProductImage setImage={fetchImage} product={product} />
                <Price 
                    role={role}
                    setPrice={fetchPrice} 
                    product={product} 
                    change={change}
                    priceState={priceState}
                    isTaxed={isTaxed}
                    taxState={taxState}
                />
                <Variant setVariants={fetchVariants} storeId={storeId} product={product} />
                <Dimensions 
                    setDimensions={fetchDimensions} 
                    product={product} 
                    change={change}
                    heightState={heightState}
                    lengthState={lengthState}
                    widthState={widthState}
                    weightState={weightState}
                />
                <div hidden={!hasError} style={{color: 'red'}}>Please, fill out all required fields before pressing the Save button</div>
                <SubmitButtons storeId={storeId} isValidated={isValidated}/>
                <Modal isOpen={loading} centered={true}>
                    <div style={{padding: 50, textAlign: "center"}}>
                        <ModalHeader style={{justifyContent: 'center'}}>
                            Please wait..
                        </ModalHeader>
                        <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        />
                    </div>
                </Modal>
            {/* </Form> */}
        </Styles>
        // </>
    )
}