export const verifyLength = (value, length) => {
    if (value.length >= length) {
        return true;
      }
    return false;
} 

export const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    // var numberRex = new RegExp("^\d*[0-9](|.\d*[0-9]|,\d*[0-9])?$")
    if (numberRex.test(value)) {
      return true;
    }
    return false;
};

export const verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  export const verifyPhone = value => {
    var phoneRex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if(phoneRex.test(value)) {
      return true;
    }
    return false;
  };