import styled from 'styled-components';

export const Styles = styled.div`
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 30px;
        width: 98%;
        background-color: #27293d;
        border-radius: 5px;
        padding: 10px;
            h3 {
                font-size: 20px;
                font-color: #feffff;
                font-weight: 500;
            }
            .create-merchant-btn {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: initial;
                height: 50px;
                width: 150px;
                background-image: linear-gradient(to right, #f7941d, #f15a29);
                border-color: transparent;
                border-radius: 5px;
                font-size: 12px;
                font-weight: 600;
                color: #efeeee;
            }
    }
`