import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Auth0Lock from 'auth0-lock';
import { AUTH_CONFIG } from './auth0-variables';

class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            loggedIn: false,
        };
        this.lock = new Auth0Lock(AUTH_CONFIG.clientId, AUTH_CONFIG.domain, {
            allowSignUp: false,
            closable: false,
            allowedConnections: ['Username-Password-Authentication'],
            languageDictionary: {
              title: "Gophr"
            },
            theme: {
              primaryColor: "#f15a29",
            },
            auth: {
              responseType: 'token id_token',
              params: {
                  scope: 'openid email profile',
                  audience: AUTH_CONFIG.audience
              }
            }
        });
        this.lock.on("authenticated", (authResult) => {
          this.lock.getUserInfo(authResult.accessToken, (error, profile) => {
            if (error) {
              console.log(error);
              return;
            }
            let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
            localStorage.setItem("expires_at", expiresAt);
            localStorage.setItem("access_token", authResult.accessToken);
            localStorage.setItem("id_token", authResult.idToken);
            this.lock.hide();
            this.props.history.push('/admin/dashboard');
          });
        });
    }
      componentDidMount() {
          if(localStorage.getItem("access_token")) {
            this.lock.getUserInfo(localStorage.getItem("access_token"), (error, profile) => {
                if (error) {
                  console.log(error);
                  this.lock.show();
                  return;
                }
                this.props.history.push('/admin/dashboard');
              });
          }
          else {
            this.lock.show();
          }
      }

      render(){
        const style = { marginTop: '32px' }
        const { location } = this.props;
         return(
            this.state.loggedIn ? (
                <Redirect to={{
                  pathname: '/admin/dashboard',
                  state: { from: location }
                }} />
              )
              :
              (
                  <div>
                    <div id={AUTH_CONFIG.container} style={style}></div>
                  </div>
              )
         )
      }
}


export default Login;