var vehicle_types = [
    {
        "label": "Sedan",
        "val": "sedan"
    },
    {
        "label": "SUV",
        "val": "suv"
    },
    {
        "label": "Minivan",
        "val": "minivan"
    },
    {
        "label": "Pickup Truck",
        "val": "pickup_truck"
    },
    {
        "label": "Truck",
        "val": "truck"
    }
]

module.exports = vehicle_types;