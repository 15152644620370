import React, { useEffect } from 'react';

let nonGeodesicPolylines = []; // Declare it outside the component to maintain state across renders

function Polyline({ map, google, shipments }) {
  const hexColors = ['#FF0000', '#3299CC', '#912CEE', '#E3701A', '#ECC8EC', '#FFE303', '#7FFFD4', '#87421F', '#EE00EE', '#5DFC0A'];

  useEffect(() => {
    // Clear any existing non-geodesic polylines
    for (let i = 0; i < nonGeodesicPolylines.length; i++) {
      nonGeodesicPolylines[i].setMap(null);
    }

    // Render non-geodesic polylines for each shipment
    shipments.forEach((shipment, index) => {
      const markers = shipment.Orders.map(order => ({ lat: order.location_blob.lat, lng: order.location_blob.lng }));
      const poly = addPoly(markers, hexColors[index % hexColors.length], 0.7, 3);
      nonGeodesicPolylines[index] = poly;
      nonGeodesicPolylines[index].setMap(map);
    });

    function addPoly(coorArray, sColor, sOpacity, weight) {
      const poly = new google.maps.Polyline({
        path: coorArray,
        geodesic: false,
        strokeColor: sColor,
        strokeOpacity: sOpacity,
        strokeWeight: weight
      });
      return poly;
    }
  }, [map, google, shipments]);

  return null;
}

export default Polyline;
