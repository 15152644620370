/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import classnames from 'classnames';
import { Input, InputGroupAddon, InputGroupText, InputGroup, Row, FormGroup, Col } from 'reactstrap';
import states from '../../../us_states';
import MapboxAutocomplete from '../../../assets/react-mapbox-autocomplete/ReactMapboxAutocomplete';
import Select from 'react-select';
import * as shipment_urls from '../../../urls/api-shipments/admin';
import axios from 'axios';

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      store_name: '',
      store_phone: '',
      logo: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      latitude: '',
      longitude: '',
      timezone: '',
      tax_rate: '',
      transaction_rate: '',
      market: '',
      markets: [],
      ein: '',
      currentLat: '',
      currentLng: '',
      firstnameState: '',
      lastnameState: '',
      emailState: '',
      phoneState: '',
      usernameState: '',
      store_nameState: '',
      store_phoneState: '',
      logoState: '',
      addressState: '',
      address2State: '',
      cityState: '',
      stateState: '',
      zipState: '',
      latitudeState: '',
      longitudeState: '',
      timezoneState: '',
      tax_rateState: '',
      transaction_rateState: '',
      marketState: '',
      einState: 'has-success'
    };

    this.handlePlaceSuggestion = this.handlePlaceSuggestion.bind(this);
  }

  fetchMarkets() {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        query: `{
        Markets(where: {deleted_at: {_is_null: true}}) {
          market_name
          market_id
        }
      }`
      }
    })
      .then(res => {
        let optionsBack = res.data.data.Markets.map(d => ({
          val: d.market_id,
          label: d.market_name
        }));
        this.setState({ markets: optionsBack });
      })
      .catch(error => {
        throw error;
      });
  }

  componentDidMount() {
    this.fetchMarkets();
  }

  componentWillMount() {
    navigator.geolocation.getCurrentPosition(position => {
      this.setState(
        {
          currentLat: position.coords.latitude.toString(),
          currentLng: position.coords.longitude.toString()
        },
        () => {},
        error => {
          console.log('error', error);
        }
      );
    });
  }

  handlePlaceSuggestion(result, lat, lng, text) {
    var split = result.split(',');
    var stateZip = split[2].split(' ');
    var state = null;
    for (var i = 0; i < states.length; i++) {
      if (states[i].label === stateZip[1]) {
        state = states[i];
        break;
      }
    }
    this.setState({
      address: split[0],
      city: split[1],
      state: state,
      zip: stateZip[2],
      latitude: lat,
      longitude: lng,
      addressState: 'has-success',
      cityState: 'has-success',
      stateState: 'has-success',
      zipState: 'has-success',
      latitudeState: 'has-success',
      longitudeState: 'has-success'
    });
  }

  verifyUsername = value => {
    var usernameRex = /^[0-9A-Za-z_.-]+$/;
    if (usernameRex.test(value)) {
      return true;
    }
    return false;
  };

  // function that returns true if value is email, false otherwise
  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  verifyPhone = value => {
    var phoneRex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (value.replace(/[^0-9]/g, '').length > 9 && value.replace(/[^0-9]/g, '').length < 14) {
      return true;
    }
    return false;
  };

  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  // function that verifies if value contains only numbers
  verifyNumber = value => {
    var numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  verifyEIN = value => {
    var numberRex = new RegExp('^\\d{2}-?\\d{7}$');
    if (numberRex.test(value) || !value) {
      return true;
    }
    return false;
  };

  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      case 'ein':
        if (this.verifyEIN(event.target.value)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      case 'username':
        if (this.verifyUsername(event.target.value)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };

  isValidated = () => {
    if (
      this.state.firstnameState === 'has-success' &&
      this.state.lastnameState === 'has-success' &&
      this.state.emailState === 'has-success' &&
      this.state.phoneState === 'has-success' &&
      this.state.usernameState === 'has-success' &&
      this.state.store_nameState === 'has-success' &&
      this.state.store_phoneState === 'has-success' &&
      this.state.addressState === 'has-success' &&
      this.state.cityState === 'has-success' &&
      this.state.stateState === 'has-success' &&
      this.state.zipState === 'has-success' &&
      this.state.latitudeState === 'has-success' &&
      this.state.longitudeState === 'has-success' &&
      this.state.timezoneState === 'has-success' &&
      this.state.tax_rateState === 'has-success' &&
      this.state.transaction_rateState === 'has-success' &&
      this.state.marketState === 'has-success' &&
      this.state.einState === 'has-success'
    ) {
      return true;
    } else {
      if (this.state.firstnameState !== 'has-success') {
        this.setState({ firstnameState: 'has-danger' });
      }
      if (this.state.lastnameState !== 'has-success') {
        this.setState({ lastnameState: 'has-danger' });
      }
      if (this.state.emailState !== 'has-success') {
        this.setState({ emailState: 'has-danger' });
      }
      if (this.state.phoneState !== 'has-success') {
        this.setState({ phoneState: 'has-danger' });
      }
      if (this.state.usernameState !== 'has-success' || this.state.usernameState !== 'too-long') {
        this.setState({ usernameState: 'has-danger' });
      }
      if (this.state.store_nameState !== 'has-success') {
        this.setState({ store_nameState: 'has-danger' });
      }
      if (this.state.store_phoneState !== 'has-success') {
        this.setState({ store_phoneState: 'has-danger' });
      }
      if (this.state.addressState !== 'has-success') {
        this.setState({ addressState: 'has-danger' });
      }
      if (this.state.cityState !== 'has-success') {
        this.setState({ cityState: 'has-danger' });
      }
      if (this.state.stateState !== 'has-success') {
        this.setState({ stateState: 'has-danger' });
      }
      if (this.state.zipState !== 'has-success') {
        this.setState({ zipState: 'has-danger' });
      }
      if (this.state.latitudeState !== 'has-success') {
        this.setState({ latitudeState: 'has-danger' });
      }
      if (this.state.longitudeState !== 'has-success') {
        this.setState({ longitudeState: 'has-danger' });
      }
      if (this.state.timezoneState !== 'has-success') {
        this.setState({ timezoneState: 'has-danger' });
      }
      if (this.state.tax_rateState !== 'has-success') {
        this.setState({ tax_rateState: 'has-danger' });
      }
      if (this.state.transaction_rateState !== 'has-success') {
        this.setState({ transaction_rateState: 'has-danger' });
      }
      if (this.state.marketState !== 'has-success') {
        this.setState({ marketState: 'has-danger' });
      }
      if (this.state.einState !== 'has-success') {
        this.setState({ einState: 'has-danger' });
      }
      // for testing reason to skip this step
      // return true;
      return false;
    }
  };
  render() {
    return (
      <>
        <h4 className="info-text">User Profile Information</h4>
        <form autoComplete="off">
          <input autoComplete="off" hidden="true"></input>
          <Row className="justify-content-center mt-5">
            <Col sm="3">
              <label>First Name</label>
              <InputGroup
                className={classnames(this.state.firstnameState, {
                  'input-group-focus': this.state.firstnameFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="firstname"
                  type="text"
                  onChange={e => this.change(e, 'firstname', 'length', 1)}
                  onFocus={e => this.setState({ firstnameFocus: true })}
                  onBlur={e => this.setState({ firstnameFocus: false })}
                />
                {this.state.firstnameState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="3">
              <label>Last Name</label>
              <InputGroup
                className={classnames(this.state.lastnameState, {
                  'input-group-focus': this.state.lastnameFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="lastname"
                  type="text"
                  onChange={e => this.change(e, 'lastname', 'length', 1)}
                  onFocus={e => this.setState({ lastnameFocus: true })}
                  onBlur={e => this.setState({ lastnameFocus: false })}
                />
                {this.state.lastnameState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="4">
              <label>User Name (Max 15 chars)</label>
              <InputGroup
                className={classnames(this.state.usernameState, {
                  'input-group-focus': this.state.usernameFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="username"
                  type="text"
                  maxLength={15}
                  value={this.state.username}
                  onChange={e => this.change(e, 'username', 'username')}
                  onFocus={e => this.setState({ usernameFocus: true })}
                  onBlur={e => this.setState({ usernameFocus: false })}
                />
                {this.state.usernameState === 'has-danger' ? <label className="error">This field is required.</label> : null}
                {this.state.emailState === 'too-long' ? <label className="error">Too long, must be less than 15 characters.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="5">
              <label>Email</label>
              <InputGroup
                className={classnames(this.state.emailState, {
                  'input-group-focus': this.state.emailFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-email-85" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="email"
                  type="email"
                  onChange={e => this.change(e, 'email', 'email')}
                  onFocus={e => this.setState({ emailFocus: true })}
                  onBlur={e => this.setState({ emailFocus: false })}
                />
                {this.state.emailState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="5">
              <label>Primary Phone Number</label>
              <InputGroup
                className={classnames(this.state.phoneState, {
                  'input-group-focus': this.state.phoneFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-mobile" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="number"
                  type="tel"
                  placeholder="Ex: 1234567890"
                  onChange={e => this.change(e, 'phone', 'phone')}
                  onFocus={e => this.setState({ phoneFocus: true })}
                  onBlur={e => this.setState({ phoneFocus: false })}
                />
                {this.state.phoneState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
          </Row>
          <h4 className="info-text" style={{ marginTop: '30px' }}>
            Store Information
          </h4>
          <Row className="justify-content-center mt-5">
            <Col sm="3">
              <label>Store Name</label>
              <InputGroup
                className={classnames(this.state.store_nameState, {
                  'input-group-focus': this.state.storeNameFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-bag-16" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="store_name"
                  type="text"
                  onChange={e => this.change(e, 'store_name', 'length', 1)}
                  onFocus={e => this.setState({ storeNameFocus: true })}
                  onBlur={e => this.setState({ storeNameFocus: false })}
                />
                {this.state.store_nameState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="3">
              <label>Store Phone</label>
              <InputGroup
                className={classnames(this.state.store_phoneState, {
                  'input-group-focus': this.state.storePhoneFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-mobile" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="store_phone"
                  type="tel"
                  placeholder="Ex: 1234567890"
                  onChange={e => this.change(e, 'store_phone', 'phone')}
                  onFocus={e => this.setState({ storePhoneFocus: true })}
                  onBlur={e => this.setState({ storePhoneFocus: false })}
                />
                {this.state.store_phoneState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="4">
              <label>Federal Taxpayer Identification # (EIN) (optional)</label>
              <InputGroup
                className={classnames(this.state.einState, {
                  'input-group-focus': this.state.einFocus
                })}
              >
                <Input
                  name="ein"
                  type="text"
                  placeholder="Ex: 12-3456789"
                  onChange={e => this.change(e, 'ein', 'ein')}
                  onFocus={e => this.setState({ einFocus: true })}
                  onBlur={e => this.setState({ einFocus: false })}
                />
                {this.state.einState === 'has-danger' ? <label className="error">Please use the correct format</label> : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm="5">
              <label>Store Address</label>
              <div class="form-group">
                <MapboxAutocomplete
                  publicKey="pk.eyJ1IjoiZ29waHItZW5naW5lZXJpbmciLCJhIjoiY2syYzd6NjdrMmo3azNtcGU1cXZzMTh1MSJ9.cHMxwcG_MoQk-l4ZO3bdEA"
                  inputClass="form-control mb-2"
                  onSuggestionSelect={this.handlePlaceSuggestion}
                  country="us"
                  resetSearch={true}
                  value={this.state.address}
                  placeholder="Address 1"
                  proximityLat={this.state.currentLat}
                  proximityLng={this.state.currentLng}
                />
                <InputGroup
                  className={classnames(this.state.addressState, {
                    'input-group-focus': this.state.addressFocus
                  })}
                >
                  {this.state.addressState === 'has-danger' ? <label className="error">This field is required.</label> : null}
                </InputGroup>
              </div>
            </Col>
            <Col sm="5">
              <InputGroup
                className={classnames(this.state.address2State, {
                  'input-group-focus': this.state.address2Focus
                })}
              >
                <Input
                  name="address2"
                  type="text"
                  placeholder="Address 2"
                  onChange={e => this.change(e, 'address2', 'length', 1)}
                  onFocus={e => this.setState({ address2Focus: true })}
                  onBlur={e => this.setState({ address2Focus: false })}
                  style={{ marginTop: '23px' }}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm="4">
              <label>City</label>
              <InputGroup
                className={classnames(this.state.cityState, {
                  'input-group-focus': this.state.cityFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-square-pin" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="city"
                  type="text"
                  value={this.state.city}
                  onChange={e => this.change(e, 'city', 'length', 1)}
                  onFocus={e => this.setState({ cityFocus: true })}
                  onBlur={e => this.setState({ cityFocus: false })}
                />
                {this.state.cityState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <label>State</label>
                <Select
                  className={classnames('react-select info', this.state.stateState, {
                    'input-group-focus': this.state.stateFocus
                  })}
                  classNamePrefix="react-select"
                  name="state"
                  value={this.state.state}
                  onChange={value => this.setState({ state: value, stateState: 'has-success' })}
                  options={states}
                  placeholder="Select a state"
                />
              </FormGroup>
            </Col>
            <Col sm="3">
              <label>Zip Code</label>
              <InputGroup
                className={classnames(this.state.zipState, {
                  'input-group-focus': this.state.zipFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-square-pin" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="zip"
                  type="text"
                  value={this.state.zip}
                  onChange={e => this.change(e, 'zip', 'length', 1)}
                  onFocus={e => this.setState({ zipFocus: true })}
                  onBlur={e => this.setState({ zipFocus: false })}
                />
                {this.state.zipState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm="5">
              <label>Tax Rate (%)</label>
              <InputGroup
                className={classnames(this.state.tax_rateState, {
                  'input-group-focus': this.state.tax_rateFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-tag" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="tax_rate"
                  type="number"
                  placeholder="10.25"
                  step="0.01"
                  min={0}
                  onChange={e => this.change(e, 'tax_rate', 'length', 1)}
                  onFocus={e => this.setState({ tax_rateFocus: true })}
                  onBlur={e => this.setState({ tax_rateFocus: false })}
                />
                {this.state.tax_rateState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>

            <Col sm="5">
              <label>Transaction Rate (%)</label>
              <InputGroup
                className={classnames(this.state.transaction_rateState, {
                  'input-group-focus': this.state.transaction_rateFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-tag" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="transaction_rate"
                  type="number"
                  placeholder="9.80"
                  step="0.01"
                  min={0}
                  onChange={e => this.change(e, 'transaction_rate', 'length', 1)}
                  onFocus={e => this.setState({ transaction_rateFocus: true })}
                  onBlur={e => this.setState({ transaction_rateFocus: false })}
                />
                {/* <InputGroupAddon addonType="append">
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon> */}
                {this.state.transaction_rateState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm="5">
              <FormGroup>
                <label>Default Market</label>
                <Select
                  className={classnames('react-select info', this.state.marketState, {
                    'input-group-focus': this.state.stateFocus
                  })}
                  classNamePrefix="react-select"
                  name="market"
                  onChange={value => this.setState({ market: value, marketState: 'has-success' })}
                  // options={this.fetchMarkets}
                  options={this.state.markets}
                  placeholder="Select a default market"
                />
              </FormGroup>
            </Col>
            <Col sm="5">
              <FormGroup>
                <label>Timezone</label>
                <Select
                  className={classnames('react-select info', this.state.timezoneState, {
                    'input-group-focus': this.state.timezoneFocus
                  })}
                  classNamePrefix="react-select"
                  name="timezone"
                  onChange={value => this.setState({ timezone: value, timezoneState: 'has-success' })}
                  options={[
                    {
                      label: 'Eastern',
                      val: 'US / Eastern'
                    },
                    {
                      label: 'Central',
                      val: 'America/Chicago'
                    },
                    {
                      label: 'Mountain',
                      val: 'US / Mountain'
                    },
                    {
                      label: 'Pacific',
                      val: 'US / Pacific'
                    }
                  ]}
                  placeholder="Select a timezone"
                />
              </FormGroup>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

export default Wizard;
