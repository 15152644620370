import React from 'react';

const DropoffDetailsReview = ({ dropoffInfo, dropoffInstruction }) => {
  return (
    <>
      <div style={{ color: '#fff', fontStyle: 'bold' }}>Dropoff Details</div>
      <div style={{ color: '#cdcaca' }}>{dropoffInfo.name ? dropoffInfo.name : null}</div>
      <div style={{ color: '#cdcaca' }}>{dropoffInfo.phone ? dropoffInfo.phone : null}</div>
      <div style={{ color: '#cdcaca', marginTop: '10px', marginBottom: '10px' }}>{dropoffInfo.address ? dropoffInfo.address : null}</div>
      <div hidden={dropoffInstruction !== '' && dropoffInstruction !== null ? false : true} style={{ color: '#fff', fontStyle: 'bold' }}>
        Dropoff Instructions
      </div>
      <div hidden={dropoffInstruction !== 'N/A' ? false : true} style={{ color: '#cdcaca' }}>
        {dropoffInstruction}
      </div>
    </>
  );
};

export default DropoffDetailsReview;
