import { Component } from 'react';

export default class Polyline extends Component {
  static nonGeodesicPolyline = [];
  renderPolylines() {
    const { id, map, maps, routes } = this.props;
    const hexColors = ['#FF0000', '#3299CC', '#912CEE', '#E3701A	', '#ECC8EC', '#FFE303', '#7FFFD4	', '#87421F', '#EE00EE	', '#5DFC0A'];

    for (let i = 0; i < Polyline.nonGeodesicPolyline.length; i++) {
      Polyline.nonGeodesicPolyline[i].setMap(null);
    }

    /** Example of rendering geodesic polyline */
    // let geodesicPolyline = new maps.Polyline({
    //   path: markers,
    //   geodesic: true,
    //   strokeColor: '#e4e4e4',
    //   strokeOpacity: 1.0,
    //   strokeWeight: 4
    // });
    // geodesicPolyline.setMap(map);

    /** Example of rendering non geodesic polyline (straight line) */
    routes.map((route, index) => {
      let markers = [];
      for (let pa of route.path) {
        markers.push({ lat: pa.address.lat, lng: pa.address.lng });
      }
      let poly = addPoly(markers, hexColors[index % hexColors.length], 0.7, 3);
      Polyline.nonGeodesicPolyline[index] = poly;
      Polyline.nonGeodesicPolyline[index].setMap(map);
    });

    function addPoly(coorArray, sColor, sOpacity, weight) {
      let poly = new maps.Polyline({
        path: coorArray,
        geodesic: false,
        strokeColor: sColor,
        strokeOpacity: sOpacity,
        strokeWeight: weight
      });
      return poly;
    }
  }

  render() {
    this.renderPolylines();
    return null;
  }
}
