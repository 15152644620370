import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Table, Input, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Row, Col } from 'reactstrap';

import Loading from '../Global/Loading';
import Styles from './Styles/UserStyles';
import DetailedSubMerchant from './DetailedSubMerchant';
import { Breadcrumbs, Typography } from '@material-ui/core';

import formatPhone from '../../helpers/phone';
import * as user_urls from '../../urls/api-users/admin';
import * as shipment_urls from '../../urls/api-shipments/admin';

import config from '../../config';

const UserSubMerchant = props => {
  let params = useParams();
  const toggle = () => setIsOpen(!isOpen);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [shipments, setShipments] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [allShipments, setAllShipments] = useState(null);

  useEffect(() => {
    axios({
      method: `post`,
      url: user_urls.hasuraHost,
      // headers: {
      //   Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      //   'content-type': 'application/json'
      // },
      headers: { 'content-type': 'application/json', 'x-hasura-admin-secret': config.config.api_users.admin.usersHasuraAdminSecret },
      data: {
        query:
          `{Users (where: {auth0_id: {_eq: "` +
          params.userId +
          `"}}) {auth0_id username firstname lastname email phone Addresses { address_1 address_2 city state country zip } Roles { role_description role }}}`
      }
    }).then(response => {
      if (response.data.data) {
        setUser(response.data.data.Users[0]);
        setFirstName(response.data.data.Users[0].firstname);
        setLastName(response.data.data.Users[0].lastname);
        if (response.data.data.Users[0].Roles[0].role >= 400) {
          getCustomerShipments(response.data.data.Users[0]);
        } else if (response.data.data.Users[0].Roles[0].role >= 300) {
          getDriverShipments(response.data.data.Users[0]);
        } else if (response.data.data.Users[0].Roles[0].role >= 200) {
          getMerchantShipments(response.data.data.Users[0]);
        } else if (response.data.data.Users[0].Roles[0].role >= 0) {
          getAdminShipments(response.data.data.Users[0]);
        }
      } else {
        console.log('error: ', response);
      }
    });
  }, []);

  const getCustomerShipments = user => {
    // This function returns every shipment placed by a
    // specific customer.

    axios({
      method: 'post',
      url: shipment_urls.hasuraHost,
      // headers: {
      //   Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      //   'content-type': 'application/json'
      // },
      headers: { 'content-type': 'application/json', 'x-hasura-admin-secret': config.config.api_shipments.admin.shipmentsHasuraAdminSecret },
      data: {
        query:
          `
            {
                Shipments(order_by: {updated_at: desc}, where: {cust_auth0_id: {_eq: "` +
          user.auth0_id +
          `"}}) {
                  is_expedited
                  num_of_gophrs
                  shipment_friendly_id
                  customer_blob
                  distance
                  gratuity
                  shipment_total
                  shipping_fee
                  sum_of_orders_total
                  weight
                  created_at
                  updated_at
                  scheduled_for
                  description
                  drop_off_instructions
                  pick_up_instructions
                  shipment_type
                  status
                  vehicle_type
                  Orders {
                    location_blob
                    order_friendly_id
                    status
                    subtotal
                    sum_tax
                    total
                    created_at
                    updated_at
                    pick_up_instructions
                    description
                    weight
                  }
                  Route {
                    RouteDrivers {
                      distance
                      driver_auth0_id
                      driver_blob
                      vehicle_blob
                      status
                    }
                    route_id
                    Shipments {
                      address_2
                      billed_route
                      created_at
                      created_by
                      cust_auth0_id
                      customer_blob
                      deleted_at
                      description
                      distance
                      distance_rate
                      drop_off_instructions
                      gratuity
                      is_expedited
                      pick_up_instructions
                      restricted_zone
                      restrictions
                      route_id
                      scheduled_for
                      shipment_total
                      shipping_fee
                      shipping_fee_blob
                      sum_of_orders_total
                      updated_at
                      updated_by
                      weight
                      sum_of_orders_total
                    }
                  }
                }
              }`
      }
    }).then(response => {
      if (response.data.data) {
        setShipments(response.data.data.Shipments);
        setAllShipments(response.data.data.Shipments);
        setLoading(false);
      } else {
        console.log('error: ', response);
      }
    });
  };

  const getDriverShipments = user => {
    // This function returns every shipment assigned
    // to a specific driver.  A shipment will be
    // displayed regardless of whether the user was
    // the driver or a passenger on it.

    axios({
      method: 'post',
      url: shipment_urls.hasuraHost,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      data: {
        query:
          `
            {
                Shipments(order_by: {updated_at: desc}, where: {Route: {RouteDrivers: {driver_auth0_id: {_in: "` +
          user.auth0_id +
          `"}}}}) {
                  is_expedited
                  num_of_gophrs
                  shipment_friendly_id
                  customer_blob
                  distance
                  gratuity
                  shipment_total
                  shipping_fee
                  sum_of_orders_total
                  weight
                  created_at
                  updated_at
                  scheduled_for
                  description
                  drop_off_instructions
                  pick_up_instructions
                  shipment_type
                  status
                  vehicle_type
                  Orders {
                    location_blob
                    order_friendly_id
                    status
                    subtotal
                    sum_tax
                    total
                    created_at
                    updated_at
                    pick_up_instructions
                    description
                    weight
                  }
                  Route {
                    RouteDrivers {
                      distance
                      driver_auth0_id
                      driver_blob
                      vehicle_blob
                      status
                    }
                    route_id
                    Shipments {
                      address_2
                      billed_route
                      created_at
                      created_by
                      cust_auth0_id
                      customer_blob
                      deleted_at
                      description
                      distance
                      distance_rate
                      drop_off_instructions
                      gratuity
                      is_expedited
                      pick_up_instructions
                      restricted_zone
                      restrictions
                      route_id
                      scheduled_for
                      shipment_total
                      shipping_fee
                      shipping_fee_blob
                      sum_of_orders_total
                      updated_at
                      updated_by
                      weight
                    }
                  }
                }
              }`
      }
    }).then(response => {
      if (response.data.data) {
        setShipments(response.data.data.Shipments);
        setAllShipments(response.data.data.Shipments);
        setLoading(false);
      } else {
        console.log('error: ', response);
      }
    });
  };

  const getMerchantShipmentsOld = u => {
    // This function returns all shipments containing
    // one or more orders from a store of which the
    // specified merchant is a store owner.

    axios({
      method: 'post',
      url: shipment_urls.hasuraHost,
      // headers: {
      //   Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      //   'content-type': 'application/json'
      // },
      headers: { 'content-type': 'application/json', 'x-hasura-admin-secret': config.config.api_shipments.admin.shipmentsHasuraAdminSecret },
      data: {
        query:
          `
            {
                Shipments(order_by: {updated_at: desc}, where: {Orders: {OrderStore: {StoreMerchants: {merchant_auth0_id: {_in: "` +
          u.auth0_id +
          `"}}}}}) {
                  is_expedited
                  num_of_gophrs
                  shipment_friendly_id
                  customer_blob
                  distance
                  gratuity
                  shipment_total
                  shipping_fee
                  sum_of_orders_total
                  weight
                  created_at
                  updated_at
                  scheduled_for
                  description
                  drop_off_instructions
                  pick_up_instructions
                  shipment_type
                  status
                  vehicle_type
                  Orders {
                    location_blob
                    order_friendly_id
                    status
                    subtotal
                    sum_tax
                    total
                    created_at
                    updated_at
                    pick_up_instructions
                    description
                    weight
                  }
                  Route {
                    RouteDrivers {
                      distance
                      driver_auth0_id
                      driver_blob
                      vehicle_blob
                      status
                    }
                    route_id
                    Shipments {
                      address_2
                      billed_route
                      created_at
                      created_by
                      cust_auth0_id
                      customer_blob
                      deleted_at
                      description
                      distance
                      distance_rate
                      drop_off_instructions
                      gratuity
                      is_expedited
                      pick_up_instructions
                      restricted_zone
                      restrictions
                      route_id
                      scheduled_for
                      shipment_total
                      shipping_fee
                      shipping_fee_blob
                      updated_at
                      updated_by
                      weight
                      sum_of_orders_total
                    }
                  }
                }
              }
            `
      }
    }).then(response => {
      if (response.data.data) {
        setShipments(response.data.data.Shipments);
        setAllShipments(response.data.data.Shipments);
        setLoading(false);
      } else {
        console.log('error: ', response);
      }
    });
  };

  const getMerchantShipments = u => {
    // This function returns all shipments containing
    // one or more orders from a store of which the
    // specified merchant is a store owner.

    axios({
      method: 'post',
      url: shipment_urls.hasuraHost,
      // headers: {
      //   Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      //   'content-type': 'application/json'
      // },
      headers: { 'content-type': 'application/json', 'x-hasura-admin-secret': config.config.api_shipments.admin.shipmentsHasuraAdminSecret },
      data: {
        query:
          `
            {
                Shipments(order_by: {updated_at: desc}, where: {created_by: {_eq: "` +
          u.auth0_id +
          `"}}) {
                  is_expedited
                  num_of_gophrs
                  shipment_friendly_id
                  customer_blob
                  distance
                  gratuity
                  shipment_total
                  shipping_fee
                  sum_of_orders_total
                  weight
                  created_at
                  updated_at
                  scheduled_for
                  description
                  drop_off_instructions
                  pick_up_instructions
                  shipment_type
                  status
                  vehicle_type
                  Orders {
                    location_blob
                    order_friendly_id
                    status
                    subtotal
                    sum_tax
                    total
                    created_at
                    updated_at
                    pick_up_instructions
                    description
                    weight
                  }
                  Route {
                    RouteDrivers {
                      distance
                      driver_auth0_id
                      driver_blob
                      vehicle_blob
                      status
                    }
                    route_id
                    Shipments {
                      address_2
                      billed_route
                      created_at
                      created_by
                      cust_auth0_id
                      customer_blob
                      deleted_at
                      description
                      distance
                      distance_rate
                      drop_off_instructions
                      gratuity
                      is_expedited
                      pick_up_instructions
                      restricted_zone
                      restrictions
                      route_id
                      scheduled_for
                      shipment_total
                      shipping_fee
                      shipping_fee_blob
                      updated_at
                      updated_by
                      weight
                      sum_of_orders_total
                    }
                  }
                }
              }
            `
      }
    }).then(response => {
      if (response.data.data) {
        let directShipmnets = response.data.data.Shipments;
        axios({
          method: 'post',
          url: shipment_urls.hasuraHost,
          headers: { 'content-type': 'application/json', 'x-hasura-admin-secret': config.config.api_shipments.admin.shipmentsHasuraAdminSecret },
          data: {
            query:
              `
            {
                Shipments(order_by: {updated_at: desc}, where:  {Orders: {Items: {updated_by: {_eq: "` +
              u.auth0_id +
              `"}}}}) {
                  is_expedited
                  num_of_gophrs
                  shipment_friendly_id
                  customer_blob
                  distance
                  gratuity
                  shipment_total
                  shipping_fee
                  sum_of_orders_total
                  weight
                  created_at
                  updated_at
                  scheduled_for
                  description
                  drop_off_instructions
                  pick_up_instructions
                  shipment_type
                  status
                  vehicle_type
                  Orders {
                    location_blob
                    order_friendly_id
                    status
                    subtotal
                    sum_tax
                    total
                    created_at
                    updated_at
                    pick_up_instructions
                    description
                    weight
                  }
                  Route {
                    RouteDrivers {
                      distance
                      driver_auth0_id
                      driver_blob
                      vehicle_blob
                      status
                    }
                    route_id
                    Shipments {
                      address_2
                      billed_route
                      created_at
                      created_by
                      cust_auth0_id
                      customer_blob
                      deleted_at
                      description
                      distance
                      distance_rate
                      drop_off_instructions
                      gratuity
                      is_expedited
                      pick_up_instructions
                      restricted_zone
                      restrictions
                      route_id
                      scheduled_for
                      shipment_total
                      shipping_fee
                      shipping_fee_blob
                      updated_at
                      updated_by
                      weight
                      sum_of_orders_total
                    }
                  }
                }
              }
            `
          }
        }).then(response => {
          if (response.data.data) {
            let marketplaceBridgeShipments = response.data.data.Shipments;
            setShipments([...directShipmnets, ...marketplaceBridgeShipments]);
            setAllShipments([...directShipmnets, ...marketplaceBridgeShipments]);
            setLoading(false);
          } else {
            console.log('errorMarketplaceBridge: ', response);
          }
        });
      } else {
        console.log('errorDirect: ', response);
      }
    });
  };

  const getAdminShipments = u => {
    // This function returns all shipments which
    // a specific admin user has interacted with at
    // some point in its shipment lifecycle (assign,
    // cancel, complete, etc.)

    axios({
      method: 'post',
      url: shipment_urls.hasuraHost,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      data: {
        query: `
            {
                Shipments(order_by: {updated_at: desc}, where: {ShipmentHistories: {actor: {_eq: "${u.auth0_id}"}}}) {
                  is_expedited
                  num_of_gophrs
                  shipment_friendly_id
                  customer_blob
                  distance
                  gratuity
                  shipment_total
                  shipping_fee
                  sum_of_orders_total
                  weight
                  created_at
                  updated_at
                  scheduled_for
                  description
                  drop_off_instructions
                  pick_up_instructions
                  shipment_type
                  status
                  vehicle_type
                  Orders {
                    location_blob
                    order_friendly_id
                    status
                    subtotal
                    sum_tax
                    total
                    created_at
                    updated_at
                    pick_up_instructions
                    description
                    weight
                  }
                  Route {
                    RouteDrivers {
                      distance
                      driver_auth0_id
                      driver_blob
                      vehicle_blob
                      status
                    }
                    route_id
                    Shipments {
                      address_2
                      billed_route
                      created_at
                      created_by
                      cust_auth0_id
                      customer_blob
                      deleted_at
                      description
                      distance
                      distance_rate
                      drop_off_instructions
                      gratuity
                      is_expedited
                      pick_up_instructions
                      restricted_zone
                      restrictions
                      route_id
                      scheduled_for
                      shipment_total
                      shipping_fee
                      shipping_fee_blob
                      sum_of_orders_total
                      updated_at
                      updated_by
                      weight
                      ShipmentHistories {
                        actor
                      }
                    }
                  }
                }
              }
            `
      }
    }).then(response => {
      if (response.data.data) {
        setShipments(response.data.data.Shipments);
        setAllShipments(response.data.data.Shipments);
        setLoading(false);
      } else {
        console.log('error: ', response);
      }
    });
  };

  const getAllShipments = () => {
    // This function isn't currently being used, but it
    // will be useful for a singular, fat-ass archive of
    // every shipment we have.  If we want it in a different
    // file, we can just copy pasta.

    axios({
      method: 'post',
      url: shipment_urls.hasuraHost,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      data: {
        query: `
            {
                Shipments(order_by: {updated_at: desc}) {
                  is_expedited
                  num_of_gophrs
                  shipment_friendly_id
                  customer_blob
                  distance
                  gratuity
                  shipment_total
                  shipping_fee
                  sum_of_orders_total
                  weight
                  created_at
                  updated_at
                  scheduled_for
                  description
                  drop_off_instructions
                  pick_up_instructions
                  shipment_type
                  status
                  vehicle_type
                  Orders {
                    location_blob
                    order_friendly_id
                    status
                    subtotal
                    sum_tax
                    total
                    created_at
                    updated_at
                    pick_up_instructions
                    description
                    weight
                  }
                  Route {
                    RouteDrivers {
                      distance
                      driver_auth0_id
                      driver_blob
                      vehicle_blob
                      status
                    }
                    route_id
                    Shipments {
                      address_2
                      billed_route
                      created_at
                      created_by
                      cust_auth0_id
                      customer_blob
                      deleted_at
                      description
                      distance
                      distance_rate
                      drop_off_instructions
                      gratuity
                      is_expedited
                      pick_up_instructions
                      restricted_zone
                      restrictions
                      route_id
                      scheduled_for
                      shipment_total
                      shipping_fee
                      shipping_fee_blob
                      sum_of_orders_total
                      updated_at
                      updated_by
                      weight
                      ShipmentHistories {
                        actor
                      }
                    }
                  }
                }
              }
            `
      }
    }).then(response => {
      if (response.data.data) {
        setShipments(response.data.data.Shipments);
        setAllShipments(response.data.data.Shipments);
        setLoading(false);
      } else {
        console.log('error: ', response);
      }
    });
  };

  const handleSearchChange = event => {
    var val = event.target.value;
    var filtered = [];
    if (event.target.value === '') {
      setShipments(allShipments);
      // console.log("setShipments", setShipments);
    } else {
      shipments.map(element => {
        // console.log("Customer blob", element.customer_blob)

        let name = element.customer_blob.first_name + ' ' + element.customer_blob.last_name;
        if (
          element.shipment_type.toLowerCase().includes(val.toLowerCase()) ||
          element.shipment_friendly_id
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase()) ||
          element.shipping_fee
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase()) ||
          name.toLowerCase().includes(val.toLowerCase()) ||
          element.status.toLowerCase().includes(val.toLowerCase())
        ) {
          filtered.push(element);
          console.log('inside if else if', element.status);
        }
      });
      setShipments(filtered);
    }
    // console.log("Inside search", val);
    // console.log("Inside search", filtered);
  };

  const handleStatusFilter = selectedStatus => {
    // setShipments(allShipments);
    var filtered = [];
    if (selectedStatus === 'all') {
      console.log('All selected', allShipments);
      // console.log("1")
      setShipments(allShipments);
    } else {
      allShipments.map(element => {
        if (element.status === selectedStatus) {
          filtered.push(element);
          setShipments(filtered);
        }
      });
    }
  };

  // const handleResetShipment = () => {
  //   setShipments(allShipments);
  // }

  if (loading) return <Loading />;
  return (
    <Styles className="user-profile-wrapper">
      <div className="user-profile-header-left">
        <div className="user-info">
          {/* Breadcrumbs */}
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/admin/users" style={{ color: '#5e72e4' }}>
              Users
            </Link>

            <Typography style={{ color: '#5e72e4', fontWeight: 'bold' }}>
              {firstName} {lastName}
            </Typography>
          </Breadcrumbs>
          {/* End Breadcrumbs */}
          <h2>User Info</h2>
          <p>
            <strong>First & Last Name: </strong>
            {`${user.firstname} ${user.lastname}`}
          </p>
          <p>
            <strong>Role: </strong>
            {user.Roles[0].role_description == 'super-merchant'
              ? 'Store Owner'
              : user.Roles[0].role_description == 'picker'
              ? 'Delivery Manager'
              : user.Roles[0].role_description == 'store-manager'
              ? 'Store Manager'
              : user.Roles[0].role_description == 'inventory_manager'
              ? 'Inventory Manager'
              : user.Roles[0].role_description}
          </p>
          <p>
            <strong>Username: </strong>
            {user.username}
          </p>
          <p>
            <strong>Email: </strong>
            {user.email}
          </p>
          <p>
            <strong>Primary Phone: </strong>
            {/* Wanting to come back to phone number formatting for all display instances */}
            {/* {formatPhone(user.phone)} */}
            {user.phone}
          </p>
          {user.address_1 ? (
            <p>
              <strong>Primary Address: </strong>
              {`${user.address_1} ${user.address_2}, ${user.city} ${user.state}, ${user.zip}`}
            </p>
          ) : null}
        </div>
      </div>
      <div className="user-orders-right">
        {user.Roles[0].role_description === 'super-admin' ? (
          <h2></h2>
        ) : user.Roles[0].role_description === 'admin' ? (
          <h2>Total Orders</h2>
        ) : user.Roles[0].role_description === 'super-merchant' ? (
          <h2>{`${user.firstname} ${user.lastname} - Total Orders`}</h2>
        ) : user.Roles[0].role_description === 'driver' ? (
          <h2>{`${user.firstname} ${user.lastname} - Completed Orders`}</h2>
        ) : user.Roles[0].role_description === 'customer' ? (
          <h2>{`${user.firstname} ${user.lastname} - Total Orders`}</h2>
        ) : user.Roles[0].role_description === 'inventory_manager' ? (
          <h2>{`${user.firstname} ${user.lastname} - Total Orders`}</h2>
        ) : null}
        <Row>
          <Col xs="10">
            <Input placeholder="Search here..." onChange={handleSearchChange}></Input>
          </Col>
          <Col>
            <UncontrolledDropdown style={{ marginRight: 20, marginTop: 5 }}>
              <DropdownToggle
                caret
                className="btn-link btn-icon"
                color="default"
                data-toggle="dropdown"
                type="button"
                // onClick={() => handleResetShipment()}
              >
                <i className="fas fa-filter"></i>
              </DropdownToggle>
              <DropdownMenu left>
                <DropdownItem
                  // className="text-success"
                  onClick={() => handleStatusFilter('all')}
                >
                  All
                </DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter('shipment_created')}>Created</DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter('shipment_ready_for_driver')}>Ready For Driver</DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter('shipment_accepted')}>Accepted</DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter('shipment_assigned')}>Assigned</DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter('shipment_completed')}>Completed</DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter('shipment_saved')}>Saved</DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter('shipment_declined')}>Declined</DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter('shipment_cancelled')}>Cancelled</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>

        {/* <Table style={{ cursor: `pointer` }}>
          <thead className="text-primary">
            <tr onClick={toggle}>
              <th>Store Name</th>
              <th>Expedited?</th>
              <th>Shipment Type</th>
              <th>ID</th>
              <th>Vehicle Type</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {shipments.map(s => {
              return <DetailedSubMerchant shipment={s} user={user} />;
            })}
          </tbody>
        </Table> */}
        <div style={{ overflowX: 'auto' }}>
          <Table style={{ cursor: 'pointer', tableLayout: 'fixed' }}>
            <thead className="text-primary">
              <tr onClick={toggle}>
                <th style={{ width: '20%' }}>Store Name</th>
                <th style={{ width: '10%' }}>Expedited</th>
                <th style={{ width: '20%' }}>Shipment Type</th>
                <th style={{ width: '15%' }}>ID</th>
                <th style={{ width: '15%' }}>Vehicle Type</th>
                <th style={{ width: '20%' }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {shipments.map(s => {
                return <DetailedSubMerchant shipment={s} user={user} />;
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </Styles>
  );
};

export default UserSubMerchant;
