import styled from 'styled-components';

export const Styles = styled.div`
    background-color: #27293d;
    height: auto;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 10px;
        .price-container {
            padding: 10px 10px;
                h4 {
                    color: #f7941d;
                    font-weight: 500;
                }
                .input-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                        .price-label-input {
                            display: flex;
                            flex-direction: column;
                            width: 50%;
                                label {
                                    font-size: 13px;
                                    font-weight: 300px;
                                    color: #efeeee;
                                }
                                .price-group {
                                    width: 90%;
                                }
                                .form-group .input-group-prepend .input-group-text, .form-group .input-group-text, .input-group .input-group-prepend .input-group-text, .input-group .input-group-append .input-group-text {
                                    background-color: #344675;
                                    border-color: #344675;
                                    padding: 5px 10px;
                                }
                        }
                        .checkbox-input {
                            display: flex;
                            flex-direction: row;
                            width: 33%;
                            label {
                                color: #efeeee;
                            }
                            .form-check input[type="checkbox"]:checked + .form-check-sign::before,
                            .form-check input[type="checkbox"]:checked + .form-check-sign::before {
                                background-color: #5e72e4;
                                border: 1px solid #344675;
                            }
                        }
                }   
            }
`;