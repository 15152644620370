import React from 'react';
import { Label, FormGroup, Input, Row, Col, CardTitle, Button } from 'reactstrap';

const GophrBillingForm = ({ isInvoice, setIsInvoice, billingInfo, setBillingInfo, billingErrors, isInvoiceDisabled }) => {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
        <CardTitle tag="h3" style={{ padding: 5, paddingTop: 10, textAlign: 'left', paddingBottom: 0, marginTop: 5 }}>
          Invoice
        </CardTitle>
        <Input type="checkbox" id="invoice-check" onClick={() => setIsInvoice(!isInvoice)} value={isInvoice} checked={isInvoice} disabled={isInvoiceDisabled} />
      </div>

      {isInvoice && (
        <div style={{ marginTop: 10 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <CardTitle tag="h4" style={{ padding: 5, paddingTop: 10, textAlign: 'left', paddingBottom: 0, marginBottom: 30 }}>
              Billing Information
            </CardTitle>
            <Button className="btn cancel-btn" size="sm" color="default" onClick={() => setBillingInfo({ first_name: '', last_name: '', phone: '', email: '' })}>
              Reset
            </Button>
          </div>
          <Row form={true}>
            <Col style={{ textAlign: 'left' }}>
              <Label for="billingFirstName">First Name</Label>
              <FormGroup className={billingErrors.billingFirstname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                <Input
                  required
                  type="text"
                  name="billingFirstName"
                  id="billing-first-name"
                  placeholder="John"
                  value={billingInfo.first_name}
                  onChange={e => setBillingInfo({ ...billingInfo, first_name: e.target.value })}
                />
                <div hidden={!billingErrors.billingFirstname}>
                  <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                </div>
              </FormGroup>
            </Col>
            <Col style={{ textAlign: 'left' }}>
              <Label for="billingLastName">Last Name</Label>
              <FormGroup className={billingErrors.billingLastname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                <Input
                  required
                  type="text"
                  name="billingLastName"
                  id="billing-last-name"
                  placeholder="Doe"
                  onChange={e => setBillingInfo({ ...billingInfo, last_name: e.target.value })}
                  value={billingInfo.last_name}
                />
                <div hidden={!billingErrors.billingLastname}>
                  <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                </div>
              </FormGroup>
            </Col>
            <Col style={{ textAlign: 'left' }}>
              <Label for="billingPhone">Phone Number</Label>
              <FormGroup className={billingErrors.billingPhone ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                <Input
                  required
                  type="text"
                  name="billingPhone"
                  id="billing-phone"
                  placeholder="555-555-5555"
                  onChange={e => setBillingInfo({ ...billingInfo, phone: e.target.value })}
                  value={billingInfo.phone}
                />
                <div hidden={!billingErrors.billingPhone}>
                  <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid phone number (10-13 digits)</p>
                </div>
              </FormGroup>
            </Col>
            <Col style={{ textAlign: 'left' }}>
              <Label for="billingEmail">Email</Label>
              <FormGroup className={billingErrors.billingEmail ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                <Input
                  type="text"
                  name="billingEmail"
                  id="billing-email"
                  placeholder="johndoe@gophr.app"
                  onChange={e => setBillingInfo({ ...billingInfo, email: e.target.value })}
                  value={billingInfo.email}
                />
                <div hidden={!billingErrors.billingEmail}>
                  <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid email address</p>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <hr style={{ borderColor: '#344675', borderStyle: 'solid', marginBottom: 20, marginTop: 20 }}></hr>
        </div>
      )}
    </>
  );
};

export default GophrBillingForm;
