import React, { useState, useEffect } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';

import { useGlobalContext } from '../../../context';
import * as inventory_urls from '../../../urls/api-inventory/admin';
import axios from 'axios';

const MerchantSelections = ({ selectedStore, setSelectedStore }) => {
  const { marketId } = useGlobalContext();
  const [stores, setStores] = useState([]);
  const [storeName, setStoreName] = useState('');
  const [isFilterStore, setIsFilterStore] = useState(false);
  const [filteredStoreForSelect, setFilteredStoreForSelect] = useState([]);

  const HASURA_ENDPOINT = inventory_urls.hasuraHost;

  const storesQuery = {
    query:
      `{Stores (where: {market_id: {_eq: "` +
      marketId +
      `"}}) {state phone latitude longitude name address_1 address_2 city country zip store_id market_id sells_alcohol direct_type itemized_price StoreOwnerships {firstname lastname merchant_id }}}`
  };

  useEffect(() => {
    axios({
      method: 'post',
      headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token'), 'content-type': 'application/json' },
      url: HASURA_ENDPOINT,
      data: storesQuery
    })
      .then(response => {
        if (response) {
          setStores(response.data.data.Stores);
        }
      })
      .catch(function(error) {});
  }, []);

  const filterStore = e => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = stores.filter(store => {
        return store.name.toLowerCase().startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });

      setIsFilterStore(true);
      setFilteredStoreForSelect(results);
    } else {
      setFilteredStoreForSelect(stores);
      // If the text field is empty, show all users
    }

    setStoreName(keyword);
  };

  return (
    <div>
      <UncontrolledDropdown>
        {selectedStore ? (
          <DropdownToggle caret id="driver-dropdown">
            {selectedStore.name} ✅
          </DropdownToggle>
        ) : (
          <DropdownToggle caret id="driver-dropdown" style={{ color: 'red' }}>
            Select a store/merchant
          </DropdownToggle>
        )}

        <DropdownMenu>
          <Input type="text" value={storeName} onChange={filterStore} style={{ color: 'black', alignSelf: 'left' }} placeholder="Store Name" id="store-filter" />

          {filteredStoreForSelect.length > 0 && isFilterStore ? (
            filteredStoreForSelect.map((store, key) => {
              if (key < 10) {
                return (
                  <DropdownItem key={key} onClick={() => setSelectedStore(store)}>
                    <p style={{ color: 'black', alignSelf: 'left' }}>{store.name}</p>
                  </DropdownItem>
                );
              }
            })
          ) : filteredStoreForSelect.length === 0 && !isFilterStore ? (
            stores.map((store, key) => {
              if (key < 10) {
                return (
                  <DropdownItem key={key} onClick={() => setSelectedStore(store)}>
                    <p style={{ color: 'black', alignSelf: 'left' }}>{store.name}</p>
                  </DropdownItem>
                );
              }
            })
          ) : (
            <p style={{ color: 'red', margin: 10, marginLeft: 25 }}>No stores found</p>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default MerchantSelections;
