import moment from 'moment';

export const weekDays = moment.weekdays();

export const sunday = moment.weekdays(0);
export const monday = moment.weekdays(1);
export const tuesday = moment.weekdays(2);
export const wednesday = moment.weekdays(3);
export const thursday = moment.weekdays(4);
export const friday = moment.weekdays(5);
export const saturday = moment.weekdays(6);

export const regularHours = [
    {
      day: sunday,
      index: 0,
    },
    {
      day: monday,
      index: 1,
    },
    {
      day: tuesday,
      index: 2,
    },
    {
      day: wednesday,
      index: 3,
    },
    {
      day: thursday,
      index: 4,
    },
    {
      day: friday,
      index: 5,
    },
    {
      day: saturday,
      index: 6,
    }
  ];