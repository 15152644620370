import React, { useEffect, useRef } from 'react';
import { Button, Card, CardBody, Label, FormGroup, Input, Row, Col, Collapse } from 'reactstrap';
import moment from 'moment';
import ReactDatetime from 'react-datetime';
import { formatTimestamp } from '../../../../helpers/formatter';
const PriceChoices = ({
  showForm2,
  address,
  standardPrice,
  expeditedPrice,
  superExpeditedPrice,
  setIsScheduled,
  setIsExpedited,
  setIsSuperExpedited,
  price,
  setPrice,
  priceChoice,
  setIsProceeded,
  errorMessageSubmit,
  scheduledTime,
  setScheduledTime,
  isScheduled,
  setScheduledDate,
  scheduledFor,
  errors,
  vehicleType,
  isProceeded,
  handleSubmit,
  standardPriceRangeLow,
  standardPriceRangeHigh,
  expeditedPriceRangeLow,
  expeditedPriceRangeHigh,
  superExpeditedPriceRangeLow,
  superExpeditedPriceRangeHigh,
  priceRangeLow
}) => {
  let now = moment();
  let tomorrow = moment().add(1, 'day');
  let limit = moment().add(31, 'day');

  const handleScheduleTime = event => {
    setScheduledTime(event.target.value);
  };

  const verifyDate = (current, selected) => {
    // return current.day() !== 0 && current.isAfter(now) && limit.isAfter(current);
    return current.isAfter(now) && limit.isAfter(current); //! remove sunday
  };

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const previousStandardPrice = usePrevious(standardPrice);
  const previousVehicleType = usePrevious(vehicleType);
  const previousStandardPriceRangeLow = usePrevious(standardPriceRangeLow);

  useEffect(() => {
    if (previousStandardPrice !== standardPrice || previousStandardPriceRangeLow !== standardPriceRangeLow || previousVehicleType !== vehicleType) {
      setIsProceeded(false);
    } else {
      setIsProceeded(true);
    }
  }, [standardPrice, standardPriceRangeLow]);

  const ScheduleTimeOptions = ({ timeValue, price, expedite, superExpedite, timeLabel, priceRangeLow, priceRangeHigh }) => {
    return (
      <FormGroup check className="form-check-radio" id="schedule-time">
        <Label check>
          <Input
            required
            type="radio"
            name="exampleRadio4"
            id="exampleRadios4"
            value={timeValue}
            onClick={e => {
              handleScheduleTime(e);
              setPrice(price);
              setIsExpedited(expedite);
              setIsSuperExpedited(superExpedite);
            }}
            checked={scheduledTime == timeValue}
          />
          <p id="radio" className="form-check-sign">
            {timeLabel}
            {vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler' ? (
              <span style={{ color: '#f7941d' }}>
                Estimate ${parseFloat(priceRangeLow).toFixed(2)} -- {parseFloat(priceRangeHigh).toFixed(2)}
              </span>
            ) : (
              <span style={{ color: '#f7941d' }}>${parseFloat(price).toFixed(2)}</span>
            )}
          </p>
        </Label>
      </FormGroup>
    );
  };

  const StandardOn = () => {
    return (
      <FormGroup check className="form-check-radio" id="shipment-price">
        <Label check>
          <Input
            required
            id="standard-price"
            type="radio"
            name="exampleRadio3"
            value={standardPrice}
            onClick={() => {
              setIsScheduled(false);
              setIsExpedited(false);
              setIsSuperExpedited(false);
              setPrice(standardPrice);
              priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
              setIsProceeded(true);
            }}
            checked={
              vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler'
                ? priceRangeLow == standardPriceRangeLow && isScheduled == false && isProceeded == true
                : price == standardPrice && isScheduled == false && isProceeded == true
            }
          />
          <span className="form-check-sign" /> Standard Price Today:{' '}
          {vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler' ? (
            <div style={{ color: '#f7941d' }}>
              Estimate ${parseFloat(standardPriceRangeLow).toFixed(2)} -- {parseFloat(standardPriceRangeHigh).toFixed(2)}, we will get you accurate quote back asap
            </div>
          ) : (
            <div style={{ color: '#f7941d' }}>${parseFloat(standardPrice).toFixed(2)}</div>
          )}{' '}
          (Orders placed <span style={{ color: '#f7941d' }}>before 5 PM</span> will be delivered the <span style={{ color: '#f7941d' }}>same day</span>)
        </Label>
      </FormGroup>
    );
  };

  const StandardOff = () => {
    return (
      <FormGroup check className="form-check-radio" id="shipment-price">
        <Label check>
          <Input
            required
            id="standard-price"
            type="radio"
            name="exampleRadio3"
            value={standardPrice}
            onClick={() => {
              setIsExpedited(false);
              setIsScheduled(false);
              setIsSuperExpedited(false);
              setPrice(standardPrice);
              priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
            }}
            disabled
          />

          <div style={{ color: 'gray' }}>
            <span className="form-check-sign" /> Standard Price Today:{' '}
            {vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler' ? (
              <div style={{ color: '#gray' }}>
                Estimate ${parseFloat(standardPriceRangeLow).toFixed(2)} -- {parseFloat(standardPriceRangeHigh).toFixed(2)}
              </div>
            ) : (
              <div style={{ color: 'gray' }}>${parseFloat(standardPrice).toFixed(2)}</div>
            )}{' '}
            (<span style={{ color: 'gray' }}>If you would like the same day delivery for order placed after 5 PM, </span>{' '}
            <span style={{ color: '#f7941d' }}>please choose expedited</span>)
          </div>
        </Label>
      </FormGroup>
    );
  };

  const ExpediteOn = () => {
    return (
      <FormGroup check className="form-check-radio" id="shipment-price">
        <Label check>
          <Input
            required
            id="expedited-price"
            type="radio"
            name="exampleRadio3"
            value={expeditedPrice}
            onClick={() => {
              setIsScheduled(false);
              setIsExpedited(true);
              setIsSuperExpedited(false);
              setPrice(expeditedPrice);
              priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
              setIsProceeded(true);
            }}
            checked={
              vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler'
                ? priceRangeLow == expeditedPriceRangeLow && isScheduled == false && isProceeded == true
                : price == expeditedPrice && isScheduled == false && isProceeded == true
            }
          />
          <span className="form-check-sign" /> Expedited Price Today:{' '}
          {vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler' ? (
            <>
              <div style={{ color: '#f7941d' }}>
                Estimate ${parseFloat(expeditedPriceRangeLow).toFixed(2)} -- {parseFloat(expeditedPriceRangeHigh).toFixed(2)}, we will get you accurate quote back asap
              </div>
              (<span style={{ color: '#f7941d' }}>Driver will be </span> dispatched <span style={{ color: '#f7941d' }}>upon approval</span>)
            </>
          ) : (
            <>
              <div style={{ color: '#f7941d' }}>${parseFloat(expeditedPrice).toFixed(2)}</div>(<span style={{ color: '#f7941d' }}>Driver will be </span> dispatched{' '}
              <span style={{ color: '#f7941d' }}>immediately</span>)
            </>
          )}
        </Label>
      </FormGroup>
    );
  };

  const ExpediteOff = () => {
    return (
      <FormGroup check className="form-check-radio" id="shipment-price">
        <Label check>
          <Input
            required
            id="expedited-price"
            type="radio"
            name="exampleRadio3"
            value={expeditedPrice}
            onClick={() => {
              setIsExpedited(true);
              setIsScheduled(false);
              setIsSuperExpedited(false);
              setPrice(expeditedPrice);
              priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
            }}
            disabled
          />

          <div style={{ color: 'gray' }}>
            <span className="form-check-sign" /> Expedited Price Today:{' '}
            {vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler' ? (
              <div style={{ color: 'gray' }}>
                Estimate ${parseFloat(expeditedPriceRangeLow).toFixed(2)} -- {parseFloat(expeditedPriceRangeHigh).toFixed(2)}
              </div>
            ) : (
              <div style={{ color: 'gray' }}>${parseFloat(expeditedPrice).toFixed(2)}</div>
            )}{' '}
            (<span style={{ color: 'gray' }}>Please schedule for tomorrow, or if you have emergency </span>
            <span style={{ color: '#f7941d' }}>please call (833) 467-4748</span>)
          </div>
        </Label>
      </FormGroup>
    );
  };

  return (
    <>
      <Collapse id="collapse2" isOpen={showForm2}>
        <Card style={{ backgroundColor: '#344674', color: '#fff' }} className="card-form">
          <CardBody>
            Shipment Route Quote
            <p>
              Deliver To <p style={{ color: '#f7941d' }}>{address}</p>
            </p>
            <Col>
              {now.hour() < 17 ? (
                <>
                  <StandardOn />
                  <ExpediteOn />
                </>
              ) : now.hour() >= 17 && now.hour() < 20 ? (
                <>
                  <StandardOff />
                  <ExpediteOn />
                </>
              ) : now.hour() >= 20 ? (
                <>
                  <StandardOff />
                  <ExpediteOff />
                </>
              ) : null}
              <FormGroup check className="form-check-radio" id="shipment-price">
                <Label check>
                  <Input
                    required
                    id="schedule-price"
                    type="radio"
                    name="exampleRadio3"
                    // value={standardPrice}
                    onClick={() => {
                      setIsScheduled(true);
                      setIsExpedited(false);
                      setIsSuperExpedited(false);
                      // priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
                    }}
                  />
                  <span className="form-check-sign"> Schedule For Different Date</span>
                  <Collapse isOpen={true} hidden={!isScheduled} style={{ marginTop: '6px' }}>
                    <Label className="form-check-label"></Label>
                    <Row className="justify-content-md-center">
                      <Col md="auto">
                        <FormGroup>
                          <ReactDatetime
                            input={false}
                            inputProps={{
                              className: 'form-control',
                              placeholder: formatTimestamp(tomorrow),
                              readOnly: true,
                              cursor: 'text'
                            }}
                            onChange={e => (e.toISOString ? setScheduledDate(e) : null)}
                            dateFormat="dddd, MMMM D, YYYY"
                            timeFormat={false}
                            isValidDate={(currentDate, selectedDate) => verifyDate(currentDate, selectedDate)}
                            // timeConstraints={scheduledFor ? (scheduledFor.day() === 6 ? saturdayTimeConstraint : weekdayTimeConstraint) : weekdayTimeConstraint}
                            defaultValue={tomorrow}
                            // closeOnSelect={true}
                            closeOnTab={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="auto" style={{ marginTop: 50 }}>
                        <ScheduleTimeOptions
                          timeValue={0}
                          price={superExpeditedPrice}
                          expedite={false}
                          superExpedite={true}
                          timeLabel="12 AM - 2 AM :"
                          priceRangeLow={superExpeditedPriceRangeLow}
                          priceRangeHigh={superExpeditedPriceRangeHigh}
                        />
                        <ScheduleTimeOptions
                          timeValue={2}
                          price={superExpeditedPrice}
                          expedite={false}
                          superExpedite={true}
                          timeLabel="2 AM - 4 AM :"
                          priceRangeLow={superExpeditedPriceRangeLow}
                          priceRangeHigh={superExpeditedPriceRangeHigh}
                        />
                        <ScheduleTimeOptions
                          timeValue={4}
                          price={expeditedPrice}
                          expedite={true}
                          superExpedite={false}
                          timeLabel="4 AM - 6 AM :"
                          priceRangeLow={expeditedPriceRangeLow}
                          priceRangeHigh={expeditedPriceRangeHigh}
                        />
                        <ScheduleTimeOptions
                          timeValue={6}
                          price={expeditedPrice}
                          expedite={true}
                          superExpedite={false}
                          timeLabel="6 AM - 8 AM :"
                          priceRangeLow={expeditedPriceRangeLow}
                          priceRangeHigh={expeditedPriceRangeHigh}
                        />
                        <ScheduleTimeOptions
                          timeValue={8}
                          price={standardPrice}
                          expedite={false}
                          superExpedite={false}
                          timeLabel="8 AM - 10 AM :"
                          priceRangeLow={standardPriceRangeLow}
                          priceRangeHigh={standardPriceRangeHigh}
                        />
                        <ScheduleTimeOptions
                          timeValue={10}
                          price={standardPrice}
                          expedite={false}
                          superExpedite={false}
                          timeLabel="10 AM - 12 PM :"
                          priceRangeLow={standardPriceRangeLow}
                          priceRangeHigh={standardPriceRangeHigh}
                        />
                      </Col>
                      <Col md="auto" style={{ marginTop: 50 }}>
                        <ScheduleTimeOptions
                          timeValue={12}
                          price={standardPrice}
                          expedite={false}
                          superExpedite={false}
                          timeLabel="12 PM - 2 PM :"
                          priceRangeLow={standardPriceRangeLow}
                          priceRangeHigh={standardPriceRangeHigh}
                        />
                        <ScheduleTimeOptions
                          timeValue={14}
                          price={standardPrice}
                          expedite={false}
                          superExpedite={false}
                          timeLabel="2 PM - 4 PM :"
                          priceRangeLow={standardPriceRangeLow}
                          priceRangeHigh={standardPriceRangeHigh}
                        />
                        <ScheduleTimeOptions
                          timeValue={16}
                          price={expeditedPrice}
                          expedite={true}
                          superExpedite={false}
                          timeLabel="4 PM - 6 PM :"
                          priceRangeLow={expeditedPriceRangeLow}
                          priceRangeHigh={expeditedPriceRangeHigh}
                        />
                        <ScheduleTimeOptions
                          timeValue={18}
                          price={expeditedPrice}
                          expedite={true}
                          superExpedite={false}
                          timeLabel="6 PM - 8 PM :"
                          priceRangeLow={expeditedPriceRangeLow}
                          priceRangeHigh={expeditedPriceRangeHigh}
                        />
                        <ScheduleTimeOptions
                          timeValue={20}
                          price={superExpeditedPrice}
                          expedite={false}
                          superExpedite={true}
                          timeLabel="8 PM - 10 PM :"
                          priceRangeLow={superExpeditedPriceRangeLow}
                          priceRangeHigh={superExpeditedPriceRangeHigh}
                        />
                        <ScheduleTimeOptions
                          timeValue={22}
                          price={superExpeditedPrice}
                          expedite={false}
                          superExpedite={true}
                          timeLabel="10 PM - 12 PM :"
                          priceRangeLow={superExpeditedPriceRangeLow}
                          priceRangeHigh={superExpeditedPriceRangeHigh}
                        />
                      </Col>
                    </Row>
                    <div hidden={errorMessageSubmit.errorCode !== 12}>
                      <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageSubmit.errorMessage}</p>
                    </div>{' '}
                    <div>
                      {JSON.stringify(scheduledFor) && scheduledFor != null ? (
                        <div>
                          {' '}
                          <p style={{ marginBottom: 10 }}>
                            You select the scheduled <span style={{ color: '#f7941d' }}>pickup</span> time window:{' '}
                            <span style={{ color: '#f7941d' }}>
                              {scheduledFor
                                ? JSON.stringify(
                                    moment(scheduledFor).format('dddd, MMMM-DD-YYYY, hh:mm A') +
                                      '--' +
                                      moment(scheduledFor)
                                        .add(2, 'hours')
                                        .format('hh:mm A')
                                  )
                                : ''}
                            </span>
                          </p>
                          <p>
                            If you need <span style={{ color: '#f7941d' }}>pickup</span> at a specific time, please add it to{' '}
                            <span style={{ color: '#f7941d' }}>Pickup Instructions</span>
                          </p>
                          <p>
                            If you need <span style={{ color: 'green' }}>dropoff</span> at a specific time, please add it to{' '}
                            <span style={{ color: 'green' }}>Dropoff Instructions</span>
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p>
                            Please select date and time window for delivery <span style={{ color: '#f7941d', fontWeight: 'bold' }}>to be picked up</span>
                          </p>
                          <p>
                            If you need <span style={{ color: '#f7941d' }}>pickup</span> at a specific time, please add it to{' '}
                            <span style={{ color: '#f7941d' }}>Pickup Instructions</span>
                          </p>
                          <p>
                            If you need <span style={{ color: 'green' }}>dropoff</span> at a specific time, please add it to{' '}
                            <span style={{ color: 'green' }}>Dropoff Instructions</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </Collapse>
                </Label>
              </FormGroup>
              <div hidden={!errors.price}>
                <p style={{ color: 'red', marginTop: '4px' }}>Please select a shipping preference</p>
              </div>
              {vehicleType ? null : <p style={{ color: 'red', marginTop: '4px' }}>Please select a vehicle for delivery</p>}
              {isProceeded ? (
                <Button
                  className="btn submit-btn"
                  color="danger"
                  onClick={() => {
                    handleSubmit();
                    window.scrollTo(0, 0);
                    // console.log('click handle submit');
                    // history.push('/admin/delivery_creation/#form-top');
                  }}
                >
                  Proceed
                </Button>
              ) : (
                <Button className="btn submit-btn" style={{ color: 'gray' }} onClick={() => {}}>
                  Proceed
                </Button>
              )}

              <div hidden={errorMessageSubmit.errorCode !== 10}>
                <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageSubmit.errorMessage}</p>
              </div>
            </Col>
          </CardBody>
        </Card>
      </Collapse>
    </>
  );
};

export default PriceChoices;
