import styled from 'styled-components';

export const Styles = styled.div`
    background: #27293d;
    height: 120px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
        .left-header-content {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
                .header-link {
                    color: #f7941d;
                    font-size: 15px;
                    font-weight: 400;
                }
                h4 {
                    font-size: 20px;
                    font-color: #feffff;
                    font-weight: 400;
                }
        }
        .right-header-content {
            display: flex;
            flex-direction: row;
                .cancel, .save {
                    width: 170px;
                    height: 50px;
                    color: #efeeee;
                    font-size: 12px;
                    font-weight: 600;
                    border-radius: 5px;
                    border: transparent;
                }
                .cancel {
                    margin-right: 5px;
                    background-color: #344675;
                }
                .save {
                    margin-left: 5px;
                    background-image: linear-gradient(to right, #f7941d, #f15a29);
                }
        }
`;