import config from '../../config';

export const inventoryHost = config.config.api_inventory.admin.inventoryHost;
export const hasuraHost = config.config.api_inventory.admin.hasuraHost;

export const createStore = inventoryHost + "/merchant/stores/create";
export const editStore = inventoryHost + "/merchant/stores/edit";

export const createDepartment = inventoryHost + "/merchant/departments/create";
export const editDepartment = inventoryHost + "/merchant/departments/edit/";
export const deleteDepartment = inventoryHost + "/merchant/departments/delete/";

export const createProduct = inventoryHost + "/merchant/products/create"
export const deleteProduct = inventoryHost + "/merchant/products/delete/"; // + product_id
export const addImage = inventoryHost + "/merchant/products/" // + product_id + "/image"
export const editProduct = inventoryHost + "/merchant/products/edit/" // + product_id

export const createCategory = inventoryHost + "/merchant/categories/create";
export const editCategory = inventoryHost + "/merchant/categories/edit/";
export const deleteCategory = inventoryHost + "/merchant/categories/delete/";

export const regularHoursEndpoint = inventoryHost + "/merchant/hours";
export const createRegularHours = inventoryHost + "/merchant/stores/"; // + :storeId + "/hours/create/" + :dayOfWeek
export const editRegularHours = inventoryHost + "/merchant/stores/";
export const deleteRegularHours = inventoryHost + "/merchant/stores/";

export const getTaxCodes = inventoryHost + "/tax-codes";