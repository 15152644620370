import React from 'react';

const DefaultItemsDescription = ({ defaultItems }) => {
  return (
    <>
      <div style={{ color: '#cdcaca', marginBottom: '5px' }}>
        {defaultItems.length > 0 &&
          defaultItems.map(item => {
            return (
              <p style={{ marginLeft: '8px' }}>
                {item.quantity} {item.detail}
              </p>
            );
          })}
      </div>
    </>
  );
};

export default DefaultItemsDescription;
