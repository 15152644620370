import React, { useState, useEffect } from "react";
import {
    Label,
    Input,
    FormGroup,
    Row,
    Col
} from 'reactstrap';
import { Styles, StyledHr } from './Styles/DimensionsStyles';

const Dimensions = (props) => {
    const [weight, setWeight] = useState(null);
    const [length, setLength] = useState(null);
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const [weightUnit, setWeightUnit] = useState("lbs");
    const [lengthUnit, setLengthUnit] = useState("in");
    const [widthUnit, setWidthUnit] = useState("in");
    const [heightUnit, setHeightUnit] = useState("in");

    useEffect(() => {
            if (weightUnit === "lbs") {
                var sentWeight = weight;
            } else if (weightUnit === "gms") {
                var sentWeight = weight * 0.00220462;
            } else if (weightUnit === "Kg") {
                var sentWeight = weight / 0.45359237;
            } else if (weightUnit === "oz") {
                var sentWeight = weight * 0.0625;
            }
            if (widthUnit === "in") {
                var sentWidth = width;
            } else if (widthUnit === "cm") {
                var sentWidth = width * 0.393701;
            }
            if (heightUnit === "in") {
                var sentHeight = height;
            } else if (heightUnit === "cm") {
                var sentHeight = height * 0.393701;
            }
            if (lengthUnit === "in") {
                var sentLength = length;
            } else if (lengthUnit === "cm") {
                var sentLength = length * 0.393701;
            }
            props.setDimensions(sentWeight, sentLength, sentWidth, sentHeight);
    });

    useEffect(() => {
        if (props.product) {
            setWeight(props.product.weight);
            setWidth(props.product.width);
            setHeight(props.product.height);
            setLength(props.product.length);
        }
    }, [props.product]);

    return (
        <Styles className="dimensions-container">
            <h4>Dimensions</h4>
            <StyledHr />
            <Row className="weight-row">
                <Col>
                    <FormGroup>
                        <Label for="itemWeight">Weight</Label>
                            <div className="input-select-container">
                                <Input
                                    type="text"
                                    name="itemWeight"
                                    onChange={e => {
                                            setWeight(e.target.value)
                                            props.change(e, "weight", 1);
                                        }
                                    } 
                                    placeholder="Product Weight" 
                                    value={weight}
                                />
                                <select 
                                    type="text"
                                    name="itemWeight1" 
                                    onChange={e => setWeightUnit(e.target.value)}
                                >
                                    <option value="lbs">lbs</option>
                                    <option value="gms">gms</option>
                                </select>
                            </div>
                    </FormGroup>
                    {props.weightState === "has-danger" ? (
                        <label className="error" style={{color: '#ff0000'}}>
                            This field is required
                        </label>
                    ) : null}
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label for="itemLength">Length</Label>
                            <div className="input-select-container">
                                <Input 
                                    type="text"
                                    name="itemLength"
                                    onChange={ e => {
                                            setLength(e.target.value);
                                            props.change(e, "length", 1);
                                        }
                                    } 
                                    placeholder="Product Length" 
                                    value={length}
                                />
                                <select 
                                    type="text" 
                                    name="itemLength1" 
                                    onChange={e => setLengthUnit(e.target.value)}
                                >
                                    <option value="in">in</option>
                                    <option value="cm">cm</option>
                                </select>
                            </div>
                    </FormGroup>
                    {props.lengthState === "has-danger" ? (
                        <label className="error" style={{color: '#ff0000'}}>
                            This field is required
                        </label>
                    ) : null}
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="itemWidth">Width</Label>
                            <div className="input-select-container">
                                <Input 
                                    type="text"
                                    name="itemWidth"
                                    onChange={e => {
                                            setWidth(e.target.value);
                                            props.change(e, "width", 1);
                                        }
                                    } 
                                    placeholder="Product Width" 
                                    value={width}
                                />
                                <select 
                                    type="text" 
                                    onChange={e => setWidthUnit(e.target.value)} 
                                    name="itemWidth1" 
                                >
                                    <option value="in">in</option>
                                    <option value="cm">cm</option>
                                </select>
                            </div>
                    </FormGroup>
                    {props.widthState === "has-danger" ? (
                        <label className="error" style={{color: '#ff0000'}}>
                            This field is required
                        </label>
                    ) : null}
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="itemHeight">Height</Label>
                            <div className="input-select-container">
                                <Input 
                                    type="text" 
                                    name="itemHeight"
                                    onChange={e => {
                                            setHeight(e.target.value);
                                            props.change(e, "height", 1);
                                        }
                                    } 
                                    placeholder="Product Height"
                                    value={height}
                                />
                                <select 
                                    type="text" 
                                    onChange={e => setHeightUnit(e.target.value)} 
                                    name="itemHeight"
                                >
                                    <option value="in">in</option>
                                    <option value="cm">cm</option>
                                </select>
                            </div>
                    </FormGroup>
                    {props.heightState === "has-danger" ? (
                        <label className="error" style={{color: '#ff0000'}}>
                            This field is required
                        </label>
                    ) : null}
                </Col>
            </Row>
        </Styles>
    )
}

export default Dimensions;