import React from 'react';

const MultiDropDetailsReview = ({ multiDropWaypointsArray }) => {
  return (
    <>
      {multiDropWaypointsArray.map((dr, index) => {
        return (
          <div>
            <div style={{ color: '#fff', fontStyle: 'bold' }}>Dropoff ({index + 1}) Details</div>
            <div style={{ color: '#cdcaca' }}>{dr.waypointFirstname ? dr.waypointFirstname + ' ' + dr.waypointLastname : null}</div>
            <div style={{ color: '#cdcaca' }}>{dr.waypointPhone ? dr.waypointPhone : null}</div>
            <div style={{ color: '#cdcaca', marginTop: '10px', marginBottom: '10px' }}>{dr.waypointFullAddress ? dr.waypointFullAddress : null}</div>
            <div hidden={dr.waypointDropoffInstruction !== '' && dr.waypointDropoffInstruction !== null ? false : true} style={{ color: '#fff', fontStyle: 'bold' }}>
              Dropoff Instructions
            </div>
            <div hidden={dr.waypointDropoffInstruction !== 'N/A' ? false : true} style={{ color: '#cdcaca' }}>
              {dr.waypointDropoffInstruction}
            </div>
            <hr style={{ backgroundColor: '#344675' }} />
          </div>
        );
      })}
    </>
  );
};

export default MultiDropDetailsReview;
