import React, { useState, useEffect, useRef } from "react";
import { ReactDOM } from "react";
import {
    Card,
    CardBody,
    Row,
    Col,
    Label,
    Input,
    FormGroup
} from 'reactstrap';
import { checkPropTypes } from "prop-types";
// react plugin that creates an input with badges
import TagsInput from "../../../assets/custom/ReactTagsInput";
// import { render } from "node-sass";

export default class Variant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            singleSelect: null,
            multipleSelect: null,
            tagsinput: [],
            new_variant_name: "",
            quantity: null,
            sku: null,
            upc: null,
            variants: props.product ? props.product.Variants : [{
                name: null,
                min_required: 1,
                max_allowed: 1,
                options: []
            }],
            showNewVariant: null,
            showModal: false,
            errors: {
                exampleRadio1: '',
                quantity: '',
                sku: ''
            }
        }
        // if(this.props.variants) {
        //     if(this.props.variants.length === 1) {
        //         if(this.props.variants[0].name !== null) {
        //             this.state.showNewVariant = true;
        //         }
        //     }
        // }
        this.emptyVariant = {
            name: null,
            min_required: 1,
            max_allowed: 1,
            options: [
    
            ]
        };
        this.check = this.check.bind(this);
        this.reset = this.reset.bind(this);
        this.handleTagsinput = this.handleTagsinput.bind(this);
    }

    // componentDidMount() {
    //     this.props.setVariants(this.state.showNewVariant, this.state.variants, this.state.quantity, this.state.sku, this.state.upc);
    // }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.submit !== this.props.submit) {
    //         if (this.props.submit) {
    //             this.props.setVariants(this.state.showNewVariant, this.state.variants, this.state.quantity, this.state.sku, this.state.upc);
    //         }
    //     }
    // }

    addAnotherVariant() {
        var updatedVariants = this.state.variants;
        updatedVariants.push({name: "", min_required: 1, max_allowed: 1, options: []});
        this.setState({variants: updatedVariants});
        this.props.setVariants(this.state.showNewVariant, updatedVariants, this.state.quantity, this.state.sku, this.state.upc);
    }
    
    componentWillMount() {
        if (this.state.showNewVariant !== null) {
            document.getElementsByClassName('yes')[2].checked = this.state.showNewVariant;
            document.getElementsByClassName('no')[2].checked = !this.state.showNewVariant;
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.product !== nextProps.product) {
            if (nextProps.product) {
                if (nextProps.product.Variants.length > 0) {
                    this.setState({
                        showNewVariant: true,
                        variants: nextProps.product.Variants,
                        quantity: nextProps.product.quantity_on_hand,
                        sku: nextProps.product.sku,
                        upc: nextProps.product.upc
                    }, () => {
                        this.props.setVariants(this.state.showNewVariant, this.state.variants, this.state.quantity, this.state.sku, this.state.upc);
                    })
                } else {
                    this.setState({
                        showNewVariant: false,
                        quantity: nextProps.product.quantity_on_hand,
                        sku: nextProps.product.sku,
                        upc: nextProps.product.upc
                    }, () => {
                        this.props.setVariants(this.state.showNewVariant, this.state.variants, this.state.quantity, this.state.sku, this.state.upc);
                    })
                }
            }
        }
    }

    // componentDidUpdate() {
    //     if () {
    //         if (nextProps.product) {
    //             if (nextProps.product.Variants.length > 0) {
    //                 this.setState({
    //                     showNewVariant: true,
    //                     variants: nextProps.product.Variants,
    //                     quantity: nextProps.product.quantity_on_hand,
    //                     sku: nextProps.product.sku,
    //                     upc: nextProps.product.upc
    //                 }, () => {
    //                     this.props.setVariants(this.state.showNewVariant, this.state.variants, this.state.quantity, this.state.sku, this.state.upc);
    //                 })
    //             } else {
    //                 this.setState({
    //                     showNewVariant: false,
    //                     quantity: nextProps.product.quantity_on_hand,
    //                     sku: nextProps.product.sku,
    //                     upc: nextProps.product.upc
    //                 }, () => {
    //                     this.props.setVariants(this.state.showNewVariant, this.state.variants, this.state.quantity, this.state.sku, this.state.upc);
    //                 })
    //             }
    //         }
    //     }
    // }

    handleVariantName = (event, index) => {
        let updatedVariants = this.state.variants;
        updatedVariants[index].name = event.target.value;
        
        this.setState({variants: updatedVariants});
        this.props.setVariants(this.state.showNewVariant, updatedVariants, this.state.quantity, this.state.sku, this.state.upc);

    }

    handleMin = (event, index) => {
        let updatedVariants = this.state.variants;
        updatedVariants[index].min_required = event.target.value;
        
        this.setState({variants: updatedVariants});
        this.props.setVariants(this.state.showNewVariant, updatedVariants, this.state.quantity, this.state.sku, this.state.upc);

    }

    handleMax = (event, index) => {
        let updatedVariants = this.state.variants;
        updatedVariants[index].max_allowed = event.target.value;
        
        this.setState({variants: updatedVariants});
        this.props.setVariants(this.state.showNewVariant, updatedVariants, this.state.quantity, this.state.sku, this.state.upc);

    }

    handleQuantity = (event, variantIndex, optionIndex) => {
        let updatedVariants = this.state.variants;
        updatedVariants[variantIndex].options[optionIndex].quantity = event.target.value.toString();
        
        this.setState({variants: updatedVariants});
        this.props.setVariants(this.state.showNewVariant, updatedVariants, this.state.quantity, this.state.sku, this.state.upc);

    }

    handlePrice = (event, variantIndex, optionIndex) => {
        let updatedVariants = this.state.variants;
        updatedVariants[variantIndex].options[optionIndex].price = event.target.value.replace("+$", "");        
        this.setState({variants: updatedVariants});
        this.props.setVariants(this.state.showNewVariant, updatedVariants, this.state.quantity, this.state.sku, this.state.upc);
    }

    handleSku = (event, variantIndex, optionIndex) => {
        let updatedVariants = this.state.variants;
        updatedVariants[variantIndex].options[optionIndex].sku = event.target.value;
        
        this.setState({variants: updatedVariants});
        this.props.setVariants(this.state.showNewVariant, updatedVariants, this.state.quantity, this.state.sku, this.state.upc);

    }

    handleBarcode = (event, variantIndex, optionIndex) => {
        let updatedVariants = this.state.variants;
        updatedVariants[variantIndex].options[optionIndex].upc = event.target.value;
        
        this.setState({variants: updatedVariants});
        this.props.setVariants(this.state.showNewVariant, updatedVariants, this.state.quantity, this.state.sku, this.state.upc);

    }

    // handleNoVariants = (quantity, sku, upc) => {
    //     this.setState({
    //         quantity: quantity,
    //         sku: sku,
    //         upc: upc
    //     })
    //     this.props.setVariants(this.state.showNewVariant, this.state.variants, quantity, sku, upc);
    // }

    reset = () => {
        this.setState({
            showNewVariant: null,
            tagsinput: []
        })
    }

    handleTagsinput = tagsinput => {
        this.setState({tagsinput: tagsinput});
    }

    addTagsToExistingVariant = (tagsinput, idx) => {
        var updatedVariants = this.state.variants;
        updatedVariants[idx].options = tagsinput;
         
        // updatedVariants[idx].options.push({
        //     name: tagsinput,
        //     quantity: 0,
        //     price: 0,
        //     sku: '',
        //     barcode: ''
        // })
        
        
        this.setState({variants: updatedVariants});
        this.props.setVariants(this.state.showNewVariant, updatedVariants, this.state.quantity, this.state.sku, this.state.upc);
    }

    check = (event) => {
        if (event.target.id === "yes") {
            this.setState({showNewVariant: true});
            document.getElementsByClassName('yes')[3].checked = true;
        } else if (event.target.id === "no") {
            this.setState({showNewVariant: false});
            document.getElementsByClassName('no')[3].checked = true;
        }
    }

    removeVariant = (index) => {
        var updatedVariants = this.state.variants;
        updatedVariants.splice(index, 1);
        this.setState({variants: updatedVariants});
        this.props.setVariants(this.state.showNewVariant, updatedVariants, this.state.quantity, this.state.sku, this.state.upc);

        // this.state.variants.splice(index, 1);
    }

    handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        let errors = this.state.errors;
        switch(name) {
            case 'quantity':
                errors.quantity = 
                    value !== typeof Number ? "Input must be a number." : ''
                break;
            case 'sku':
                errors.sku = 
                    value < 3 ? "Must enter in a valid SKU." : ''
                break;
            case 'exampleRadio1':
                errors.exampleRadio1 =
                    value === "Yes" || "No" ? "Must select either yes or no." : ''
                break;
            default: break;
        }
        this.setState({errors, [name]: false});
    }

    render() {
        const { errors } = this.state;
        return (
        <div style={{width: '100%'}}>
            <Card style={{backgroundColor: '#27293d', textAlign: 'center', paddingBottom: 20, paddingLeft: 14, }} >
                <div className="h4" style={{color: '#f7941d', paddingTop: 22, textAlign: 'left'}}>
                    Inventory & Variants
                </div>
                <hr style={{backgroundColor: '#344675', width: 'auto', marginTop: 0, paddingTop: 0, paddingRight: '13px', paddingLeft: '13px', marginBottom: 20}}></hr>
                <Row style={{textAlign: 'left'}}>
                    <Label style={{color: '#cdcaca', paddingLeft: 15}} sm="5">
                        Does this product have different variants, like color, size, and material?*
                    </Label>
                        <FormGroup check className="form-check-radio" id="shipment-weight">
                            <Label 
                            check 
                            style={{marginRight: 10}} 
                            onClick={e => this.setState({
                                            showNewVariant: true,
                                        }, () => 
                                        this.props.setVariants(
                                            true, 
                                            this.state.variants, 
                                            this.state.quantity, 
                                            this.state.sku, 
                                            this.state.upc
                                    ))}
                            >
                                <Input 
                                    required 
                                    checked={this.state.showNewVariant} 
                                    type="radio" 
                                    name="exampleRadio1" 
                                    id="exampleRadios1" 
                                />
                                <span id="radio" className="form-check-sign" />
                                {" "}Yes
                            </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio">
                            <Label 
                            check 
                            onClick={e => {
                                this.setState({showNewVariant: false, variants: [this.emptyVariant]});
                                this.props.setVariants(
                                        false, 
                                        [this.emptyVariant], 
                                        this.state.quantity, 
                                        this.state.sku, 
                                        this.state.upc);                            
                                    }}
                            >
                                <Input 
                                    required 
                                    checked={!this.state.showNewVariant} 
                                    type="radio" 
                                    name="exampleRadio1" 
                                    id="exampleRadios2" 
                                />
                                <span id="radio" className="form-check-sign" />
                                {" "}No{"  "}
                            </Label>
                        </FormGroup>
                </Row>
                { this.state.showNewVariant ?
                <div>
                    <hr style={{backgroundColor: '#344675', width: 'auto', marginTop: 20, paddingTop: 0, paddingRight: '13px', paddingLeft: '13px', marginBottom: 20}}></hr>
                    <div style={{textAlign: 'left'}} id="variant-container">
                    <div style={{textAlign: 'left'}}>
                        <Label style={{color: '#efeeee'}}>Quantity*</Label>
                        <FormGroup style={{width: '48.7%'}}>
                        <Input 
                            placeholder="In-stock Quantity" 
                            onChange={e => {
                                // this.handleChange();
                                this.setState({quantity: e.target.value});
                                this.props.setVariants(
                                    this.state.showNewVariant, 
                                    this.state.variants, 
                                    e.target.value, 
                                    this.state.sku, 
                                    this.state.upc
                                );
                            }} 
                            type="number" 
                            value={this.state.quantity} 
                            style={{WebkitAppearance: 'none'}} 
                        />
                        </FormGroup>
                        <Row style={{marginTop: 30}}>
                            <Col>
                                <Label style={{color: '#efeeee'}}>SKU*</Label>
                                <FormGroup>
                                    <Input 
                                        placeholder="Stock Keeping Unit" 
                                        onChange={e => {
                                            // this.handleChange();
                                            this.setState({sku: e.target.value});
                                            this.props.setVariants(
                                                this.state.showNewVariant, 
                                                this.state.variants, 
                                                this.state.quantity, 
                                                e.target.value,
                                                this.state.upc
                                            );
                                        }} 
                                        type="text" 
                                        value={this.state.sku} 
                                        autoComplete="off" 
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <Label style={{color: '#efeeee'}}>UPC</Label>
                                <FormGroup>
                                    <Input 
                                        placeholder="UPC, ISBN, etc." 
                                        type="text" 
                                        onChange={e => {
                                            this.setState({upc: e.target.value});
                                            this.props.setVariants(
                                                this.state.showNewVariant, 
                                                this.state.variants, 
                                                this.state.quantity, 
                                                this.state.sku, 
                                                e.target.value
                                            );    
                                        }} 
                                        value={this.state.upc} 
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                        {this.state.variants.map((variant, idx) => {
                            return (
                                <div>
                                <hr style={{
                                    backgroundColor: '#344675',
                                    width: 'auto', 
                                    marginTop: 20, 
                                    paddingTop: 0, 
                                    paddingRight: '13px', 
                                    paddingLeft: '13px', 
                                    marginBottom: 20}}>
                                </hr>
                                <Row id={"variant-"+idx}>
                                    <Col sm="2">
                                        <Label style={{color: '#efeeee'}}>Variant Name*</Label>
                                        <FormGroup style={{width: '90%'}}>
                                            <Input 
                                                placeholder="Size, Color, etc." 
                                                onChange={e => this.handleVariantName(e, idx)} 
                                                type="text" 
                                                style={{WebkitAppearance: 'none'}} 
                                                value={variant.name} 
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="2">
                                        <label style={{color: '#efeeee'}}>Min Required</label>
                                        <FormGroup style={{width: '90%'}}>
                                            <Input 
                                                placeholder="0" 
                                                onChange={e => this.handleMin(e, idx)}
                                                type="number" 
                                                style={{WebkitAppearance: 'none'}} 
                                                value={variant.min_required}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="2">
                                        <label style={{color: '#efeeee'}}>Max Allowed</label>
                                        <FormGroup style={{width: '90%'}}>
                                            <Input 
                                                placeholder="0" 
                                                onChange={e => this.handleMax(e, idx)} 
                                                type="number" 
                                                style={{WebkitAppearance: 'none'}} 
                                                value={variant.max_allowed}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="5" id="variant-option-0">
                                        <div style={{opacity: 0}}>Q</div>
                                        <TagsInput
                                            onChange={(val) => this.addTagsToExistingVariant(val, idx)}
                                            tagProps={{ 
                                                className: "react-tagsinput-tag", 
                                                style:{color: "#efeeee", backgroundColor: '#2bbbae', fontWeight: 'bold'}
                                            }}
                                            inputProps={{placeholder: 'Add Option'}}
                                            value={variant.options}
                                        />
                                    </Col>
        
                                    <Col sm="1" style={{textAlign: 'right'}}>
                                        <div style={{opacity: 0}}>Q</div>
                                        {/* This is where the trashcan icon should go */}
                                        {this.state.variants.length > 1 ? 
                                            <div style={{paddingRight: 20}}>
                                                <img 
                                                    alt="delete" 
                                                    style={{cursor: 'pointer', width: 20, height: 20, }} 
                                                    src={require("../../../assets/svgs/TrashSimple.svg")} onClick={() => this.removeVariant(idx)}
                                                />
                                            </div>
                                        : null }
                                    </Col>
                                </Row>
                                </div>
                            )
                        })}
                        <hr style={{backgroundColor: '#344675', width: 'auto', marginTop: 10, paddingTop: 0, paddingRight: '13px', paddingLeft: '13px', marginBottom: 20}}></hr>
                        <div style={{color: '#5e72e4', cursor: 'pointer'}} onClick={() => this.addAnotherVariant()}>
                            Add Another
                        </div>
                    </div>
                    <div className="h4" style={{color: '#f7941d', paddingTop: 22, textAlign: 'left'}}>
                        Variant Manager
                    </div>
                    <hr style={{backgroundColor: '#344675', width: 'auto', marginTop: 0, paddingTop: 0, paddingRight: '13px', paddingLeft: '13px', marginBottom: 20}}></hr>
                    <Row style={{marginTop: 10}}>
                        <Col id="variants">
                            <div style={{color: '#cdcaca', fontSize: '10px', textAlign: 'left'}}>VARIANTS*</div>
                        </Col>
                        <Col id="quantity">
                            <div style={{color: '#cdcaca', fontSize: '10px', textAlign: 'left'}}>QUANTITY*</div>
                        </Col>
                        <Col id="additional-cost">
                            <div style={{color: '#cdcaca', fontSize: '10px', textAlign: 'left'}}>ADDITIONAL COST</div>
                        </Col>
                        <Col id="sku">
                            <div style={{color: '#cdcaca', fontSize: '10px', textAlign: 'left'}}>SKU*</div>
                        </Col>
                        <Col id="barcode">
                            <div style={{color: '#cdcaca', fontSize: '10px', textAlign: 'left'}}>BARCODE</div>
                        </Col>
                    </Row>
                    {this.state.variants.map((variant, variantIndex) => {
                        return (
                            variant.options.map((option, optionIndex) => {
                                return (
                                    <Row style={{marginTop: 10}}>
                                        <Col id="variants">
                                            <Input style={{color: '#2bbbae'}} value={option.value}/>
                                        </Col>
                                        <Col id="quantity">
                                            <Input 
                                                style={{color: '#cdcaca'}} 
                                                placeholder="0" 
                                                type="number" 
                                                value={option.quantity} 
                                                onChange={e => this.handleQuantity(e, variantIndex, optionIndex)}
                                            />
                                        </Col>
                                        <Col id="additional-cost">
                                            <Input 
                                                style={{color: '#cdcaca'}} 
                                                placeholder="0" 
                                                value={option.price ? "+$" + option.price : ""} 
                                                onChange={e => this.handlePrice(e, variantIndex, optionIndex)} 
                                            />
                                        </Col>
                                        <Col id="sku">
                                            <Input 
                                                style={{color: '#cdcaca'}} 
                                                placeholder="GO123PHR" 
                                                value={option.sku} 
                                                onChange={e => this.handleSku(e, variantIndex, optionIndex)} 
                                            />
                                        </Col>
                                        <Col id="barcode">
                                            <Input 
                                                style={{color: '#cdcaca'}} 
                                                placeholder="12345678901" 
                                                value={option.barcode} 
                                                onChange={e => this.handleBarcode(e, variantIndex, optionIndex)} 
                                            />
                                        </Col>
                                    </Row>
                                )
                            })
                        )
                    })}
                </div>
                : 
                <div style={{textAlign: 'left'}}>
                    <label style={{color: '#efeeee'}}>Quantity*</label>
                    <FormGroup style={{width: '48.7%'}}>
                    <Input 
                        placeholder="In-stock Quantity" 
                        onChange={e => {
                            this.setState({quantity: e.target.value});
                            this.props.setVariants(
                                this.state.showNewVariant, 
                                this.state.variants, 
                                e.target.value, 
                                this.state.sku, 
                                this.state.upc
                            );
                        }} 
                        type="number" 
                        value={this.state.quantity} 
                        style={{WebkitAppearance: 'none'}} 
                    />
                    </FormGroup>
                    <Row style={{marginTop: 30}}>
                        <Col>
                            <label style={{color: '#efeeee'}}>SKU*</label>
                            <FormGroup>
                                <Input 
                                    placeholder="Stock Keeping Unit" 
                                    onChange={e => {
                                        this.setState({sku: e.target.value});
                                        this.props.setVariants(
                                                this.state.showNewVariant, 
                                                this.state.variants, 
                                                this.state.quantity, 
                                                e.target.value, 
                                                this.state.upc
                                            );
                                        }} 
                                    value={this.state.sku} 
                                    type="text" 
                                    autoComplete="off" 
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <label style={{color: '#efeeee'}}>UPC*</label>
                            <FormGroup>
                                <Input 
                                    placeholder="UPC, ISBN, etc." 
                                    type="text" 
                                    onChange={e => {
                                        this.setState({upc: e.target.value});
                                        this.props.setVariants(
                                            this.state.showNewVariant, 
                                            this.state.variants, 
                                            this.state.quantity, 
                                            this.state.sku, 
                                            e.target.value
                                        );    
                                    }} 
                                    value={this.state.upc} 
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                }
            </Card>
        </div>
        )
    }
}