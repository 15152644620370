import React from 'react';

const WaypointDetailsReview = ({ waypointInfo, waypointPickupInstruction, waypointDropoffInstruction }) => {
  return (
    <>
      <div style={{ color: '#fff', fontStyle: 'bold' }}>Pickup/Dropoff Details</div>
      <div style={{ color: '#cdcaca' }}>{waypointInfo.name ? waypointInfo.name : null}</div>
      <div style={{ color: '#cdcaca' }}>{waypointInfo.phone ? waypointInfo.phone : null}</div>
      <div style={{ color: '#cdcaca', marginTop: '10px', marginBottom: '10px' }}>{waypointInfo.address}</div>
      <div hidden={waypointPickupInstruction !== '' && waypointPickupInstruction !== null ? false : true} style={{ color: '#fff', fontStyle: 'bold' }}>
        Pickup Instructions
      </div>
      <div hidden={waypointPickupInstruction !== '' ? false : true} style={{ color: '#cdcaca' }}>
        {waypointPickupInstruction}
      </div>

      <div hidden={waypointDropoffInstruction !== '' && waypointDropoffInstruction !== null ? false : true} style={{ color: '#fff', fontStyle: 'bold' }}>
        Dropoff Instructions
      </div>
      <div hidden={waypointDropoffInstruction !== '' ? false : true} style={{ color: '#cdcaca' }}>
        {waypointDropoffInstruction}
      </div>
    </>
  );
};

export default WaypointDetailsReview;
