import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Input } from 'reactstrap';
import './index.css';

class ReactMapboxAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }
  state = {
    hidden: false,
    error: false,
    errorMsg: '',
    query: this.props.query ? this.props.query : '',
    queryResults: [],
    publicKey: this.props.publicKey,
    resetSearch: this.props.resetSearch ? this.props.resetSearch : false,
    proximityLat: this.props.proximityLat ? this.props.proximityLat : '',
    proximityLng: this.props.proximityLng ? this.props.proximityLng : '',
    defaultValue: this.props.defaultValue ? this.props.defaultValue : null,
    resultClicked: false,
    index: this.props.index ? this.props.index : 0
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  _updateQuery = event => {
    this.setState({
      resultClicked: false,
      query: event.target.value,
      hidden: false
    });
    const header = { 'Content-Type': 'application/json' };
    let path =
      'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
      this.state.query +
      '.json?access_token=' +
      this.state.publicKey +
      '&proximity=' +
      this.state.proximityLng +
      ',' +
      this.state.proximityLat;

    if (this.props.country) {
      path =
        'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
        this.state.query +
        '.json?access_token=' +
        this.state.publicKey +
        '&country=' +
        this.props.country +
        '&proximity=' +
        this.state.proximityLng +
        ',' +
        this.state.proximityLat;
    }

    if (this.state.query.length >= 6) {
      return fetch(path, {
        headers: header
      })
        .then(res => {
          if (!res.ok) throw Error(res.statusText);
          return res.json();
        })
        .then(json => {
          this.setState({
            error: false,
            queryResults: json.features
          });
        })
        .catch(err => {
          this.setState({
            error: true,
            errorMsg: 'There was a problem. Please refresh the page and try again',
            queryResults: []
          });
        });
    } else {
      this.setState({
        error: false,
        queryResults: []
      });
    }
  };

  _resetSearch = () => {
    if (this.state.resetSearch) {
      this.setState({
        query: '',
        queryResults: []
      });
    } else {
      this.setState({ queryResults: [] });
    }
  };

  _onSuggestionSelect = event => {
    if (this.state.resetSearch === false) {
      this.setState({ query: event.target.getAttribute('data-suggestion') });
    }
    this.setState({ resultClicked: true });
    this.props.onSuggestionSelect(
      event.target.getAttribute('data-suggestion'),
      event.target.getAttribute('data-lat'),
      event.target.getAttribute('data-lng'),
      this.state.index,
      event.target.getAttribute('data-text')
    );
  };

  componentDidMount() {
    document.addEventListener('mousedown', event => {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        if (!this.state.hidden) {
          this.setState({ hidden: true });
        }
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', event => {});
  }

  componentDidUpdate(props) {
    if (props) {
      this.state.proximityLat = props.proximityLat;
      this.state.proximityLng = props.proximityLng;
    }
  }

  render() {
    return (
      <div>
        <form autoComplete="off">
          <input autoComplete="off" hidden={true}></input>
          <Input
            placeholder={this.props.placeholder || 'Search'}
            className={this.props.inputClass ? this.props.inputClass + ' react-mapbox-ac-input' : 'react-mapbox-ac-input'}
            onChange={this._updateQuery}
            value={this.state.resultClicked ? this.props.value : this.props.defaultValue ? this.props.defaultValue : this.state.query}
            readOnly={this.props.defaultValue}
            type="text"
            id="mapbox-autocomplete"
            onFocus={() => {
              this.setState({ hidden: false });
            }}
          />
          <span ref={this.setWrapperRef} hidden={this.state.hidden}>
            <div
              className="react-mapbox-ac-menu"
              style={this.state.queryResults.length > 0 || this.state.error ? { display: 'block' } : { display: 'none' }}
              onClick={this._resetSearch}
            >
              {map(this.state.queryResults, (place, i) => {
                return (
                  <div
                    className="react-mapbox-ac-suggestion"
                    onClick={this._onSuggestionSelect}
                    key={i}
                    data-suggestion={place.place_name}
                    data-lng={place.center[0]}
                    data-lat={place.center[1]}
                    data-text={place.text}
                    style={{ fontSize: this.props.fontSize }}
                  >
                    {place.place_name}
                  </div>
                );
              })}

              {this.state.error && <div className="react-mapbox-ac-suggestion">{this.state.errorMsg}</div>}
            </div>
          </span>
        </form>
      </div>
    );
  }
}

ReactMapboxAutocomplete.propTypes = {
  inputClass: PropTypes.string,
  publicKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onSuggestionSelect: PropTypes.func.isRequired,
  country: PropTypes.string,
  query: PropTypes.string,
  resetSearch: PropTypes.bool
};

export default ReactMapboxAutocomplete;
