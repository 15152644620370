import React from 'react';
import { Label, Row } from 'reactstrap';
import { FaUserAlt, FaUserFriends, FaUsers } from 'react-icons/fa';
import Gophrs4, { Gophrs5 } from '../../../../assets/svgs/Gophrs';

const NumberOfGophrs = ({ numPeople, setNumPeople }) => {
  return (
    <>
      <Label inline style={{ marginLeft: '20px', marginBottom: '20px' }}>
        How many Gophrs will be needed to load/unload the shipment?
      </Label>
      <Row style={{ textAlign: 'center' }}>
        <FaUserAlt
          id="num1"
          size="39"
          style={{ cursor: 'pointer', marginLeft: '35', marginTop: '8' }}
          color={numPeople === 1 ? '#f7941d' : '#5e72e4'}
          onClick={() => setNumPeople(1)}
        />

        <FaUserFriends
          id="num2"
          size="55"
          style={{ cursor: 'pointer', marginLeft: '30', marginRight: '30' }}
          color={numPeople === 2 ? '#f7941d' : '#5e72e4'}
          onClick={() => setNumPeople(2)}
        />

        <FaUsers
          style={{ cursor: 'pointer', marginLeft: '5', marginRight: '30' }}
          id="num3"
          size="55"
          color={numPeople === 3 ? '#f7941d' : '#5e72e4'}
          onClick={() => setNumPeople(3)}
        />
        <div style={{ cursor: 'pointer', marginTop: '7px', marginRight: '25px' }} onClick={() => setNumPeople(4)}>
          <Gophrs4 id="num4" size="55" fill={numPeople === 4 ? '#f7941d' : '#5e72e4'}></Gophrs4>
        </div>
        <div style={{ cursor: 'pointer', marginTop: '7px' }} onClick={() => setNumPeople(5)}>
          <Gophrs5 id="num5" size="55" fill={numPeople === 5 ? '#f7941d' : '#5e72e4'}></Gophrs5>
        </div>
      </Row>
    </>
  );
};

export default NumberOfGophrs;
