import styled from 'styled-components';

export const Styles = styled.div`
    padding-top: 15px;
    width: 98%;
    height: auto;
    background-color: #27293d;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
        .sorting-search {
            display: flex;
            flex-direction: flex-end;
        }
        .recently-added-container, .all-merchants-container  {
            margin-bottom: 20px;
            margin-left: 10px;
                h5 {
                    color: #f7941d;
                    font-size: 1rem;
                }
        }
        .recently-added, .all-merchants {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top:10px;
        }
    `;