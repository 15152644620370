import React, { useState, useEffect } from "react";
import { FormGroup, Input, Label, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import * as Image from '../../../assets/img/imageplaceholder.png';
import { UploadIcon } from '../../../assets/svgs/WhiteIcons';
import { Styles, StyledHr } from './Styles/GeneralStyles';
import Loading from '../../Global/Loading';
import Switch from 'react-switch';

const General = (props) => {
    const [storeName, setStoreName] = useState(null);
    const [address, setAddress] = useState(null);
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [transactionRate, setTransactionRate] = useState(null);
    const [promotionalRate, setPromotionalRate] = useState(null);
    const [isEnabled, setIsEnabled] = useState(null);
    const [sellsAlcohol, setSellsAlcohol] = useState(null);
    const [logo, setLogo] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [loading, setLoading] = useState(true);
    const [permitType, setPermitType] = useState(null);

    useEffect(() => {
        setStoreName(props.name);
        setAddress(props.address);
        setPhone(props.phone);
        setEmail(props.email);
        setLogo(props.logo);
        setLatitude(props.latitude);
        setLongitude(props.longitude);
        setTransactionRate(props.transactionRate);
        setPromotionalRate(props.promotionalRate);
        setIsEnabled(props.isEnabled);
        setSellsAlcohol(props.sellsAlcohol);
        setLoading(false);

        if (!props.sellsAlcohol) {
            setPermitType(null);
        }
    }, [props]);

    if (loading) return <Loading />;
    return (
        <Styles>
            <h5>General</h5>
            <StyledHr />
            <FormGroup className="general-form-container">
                <div className="text-input">
                    <Label>Store Name*</Label>
                    <Input 
                        type="text" 
                        className="store-name"
                        name="storeName"
                        placeholder="Store Name"
                        onChange={(e) => {
                                setStoreName(e.target.value)
                                props.change(e, "name", 1);
                            }
                        }
                        value={storeName}
                    />
                    {props.nameState === "has-danger" ? (
                        <label className="error">
                            This field is required.
                        </label>
                    ) : null}
                    <div className="address-container">
                        <Label>Address*</Label>
                        <Input 
                            type="text"
                            disabled
                            className="address"
                            name="address"
                            placeholder="123 Main St. Lake Charles, LA 70434"
                            value={address}
                        />
                        {props.addressState === "has-danger" ? (
                        <label className="error">
                            This field is required.
                        </label>
                    ) : null}
                    </div>
                    <div className="phone-email-container">
                        <div className="phone-container">
                            <Label>Phone Number*</Label>
                            <Input 
                                type="text" 
                                className="phone" 
                                name="phoneNumber"
                                placeholder="(337) 555-5555"
                                onChange={(e) => {
                                        setPhone(e.target.value);
                                        props.change(e, "phone", 1);
                                    }
                                }
                                value={phone}
                            />
                            {props.phoneState === "has-danger" ? (
                                <label className="error">
                                    This field is required.
                                </label>
                            ) : null}
                        </div>
                        <div className="email-container">
                            <Label>Supervisor Email</Label>
                            <Input
                                disabled
                                type="email" 
                                className="email" 
                                name="email"
                                placeholder="info@gophrapp.com"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                            {props.emailState === "has-danger" ? (
                                <label className="error">
                                    This field is required.
                                </label>
                            ) : null}
                        </div>
                    </div>
                    <div className="phone-email-container">
                        <div className="phone-container">
                            <Label>Transaction Rate* (Values in percent form)</Label>
                            <Input 
                                type="number" 
                                name="transactionRate"
                                placeholder="10.2"
                                onChange={(e) => {
                                        setTransactionRate(e.target.value);
                                        props.change(e, "transactionRate", 1);
                                    }
                                }
                                value={transactionRate}
                            />
                            {props.transactionRateState === "has-danger" ? (
                                <label className="error">
                                    This field is required.
                                </label>
                            ) : null}
                        </div>
                        <div className="email-container">
                            <Label>Promotional Rate (Values in percent form)</Label>
                            
                            <Input 
                                type="number" 
                                className="promotionalRate" 
                                name="promotionalRate"
                                placeholder="10.2"
                                onChange={(e) => {
                                        setPromotionalRate(e.target.value);
                                        props.change(e, "promotionalRate", 1);
                                    }
                                }
                                value={promotionalRate }
                            />
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <Label style={{marginRight: 10}}>Availability</Label>
                        
                        <Switch
                            size={20}
                            checked={isEnabled}
                            onChange={(e) => {
                                setIsEnabled(!isEnabled);
                                props.change(e, "isEnabled", 1);
                            }}
                            defaultValue={true}
                            onColor="#2bbbae"
                            offColor="#f15a29"
                        />
                    </div>
                    <div style={{display: 'flex', marginTop: '10px'}}>
                        <Label style={{marginRight: 10}}>Sells Alcohol</Label>
                        <Switch
                            size={20}
                            checked={sellsAlcohol}
                            onChange={(e) => {
                                setSellsAlcohol(!sellsAlcohol);
                                props.change(e, "sellsAlcohol", 1);
                                console.log("e", e);
                                if (e) {
                                    setPermitType("louisiana_calcasieu_class_a");
                                    props.change("louisiana_calcasieu_class_a", "permitType", 1);
                                } else {
                                    setPermitType(null);
                                    props.change(null, "permitType", 1)
                                }
                            }}
                            // defaultValue={sellsAlcohol}
                            onColor="#2bbbae"
                            offColor="#f15a29"
                        />
                    </div>
                    {sellsAlcohol ? 
                        <div style={{display: 'flex', marginTop: '10px'}}>
                            <Label style={{marginRight: 10}} >Permit Type</Label>
                            <div>
                        <Input 
                            type="select"
                            name="alcohol"
                            // value={alcoholType}
                            onChange={e => {
                                setPermitType(e.target.value);
                                props.change(e.target.value, "permitType", 1)
                            }}
                        >
                        {/* {console.log("Department ID", departmentId)} */}
                        {/* {console.log("Department Name", departmentName)} */}
                            <option 
                                style={{color: "black"}} 
                                value={"louisiana_calcasieu_class_a"} 
                            >
                                Louisiana Calcasieu Class A
                            </option>
                            <option 
                                style={{color: "black"}} 
                                value={"louisiana_calcasieu_class_b"} 
                            >
                                Louisiana Calcasieu Class B
                            </option>
                            <option 
                                style={{color: "black"}} 
                                value={"louisiana_calcasieu_class_ar"} 
                            >
                                Louisiana Calcasieu Class AR
                            </option>
                        )
                        </Input>
                    </div>
                        </div>
                    : null}
                </div>
                {/* <div className="image-input">
                    <img src={logo ? logo : Image} alt="some stuff" height="120px" width="180px"/>
                    <button className="upload-button">
                        <span><UploadIcon /></span>{" "}Upload Logo
                    </button>
                </div> */}
            </FormGroup>
        </Styles>
    )
}

export default General;