import styled from 'styled-components';

export const Styles = styled.div`
    height: auto;
    width: 100%;
    background-color: #27293d;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 10px 10px;
        h5 {
            font-size: 15px;
            color: #f7941d;
            font-weight: 600;
        }
        .general-form-container {
            display: flex;
            flex-direction: row;
                label {
                    color: #efeeee;
                }
                .text-input {
                    width: 100%;
                    margin-right: 10px;
                        .store-name {
                            margin-bottom: 10px;
                        }
                        .address-container {
                            margin-bottom: 10px;
                        }
                        .phone-email-container {
                            display: flex;
                            flex-direction: row;
                            margin-bottom: 10px;
                                .phone-container, .email-container {
                                    display: flex;
                                    flex-direction: column;
                                    width: 50%;
                                }
                                .phone-container {
                                    margin-right: 5px;
                                }
                                .email-container {
                                    margin-left: 5px;
                                }
                        }
                }
                .image-input {
                    width: 20%;
                    margin-left: 6%;
                        .upload-button {
                            width: 150px;
                            background-color: transparent;
                            font-size: 12px;
                            color: #efeeee;
                            border: transparent;
                            font-weight: 500;
                            margin-bottom: 5px;
                            margin-top: 5px;
                                span {
                                    margin-right: 5px;
                                }
                        }
                }
        }
        
`;


export const StyledHr = styled.hr`
    background-color: #344675;
    width: auto;
    margin-top: 0;
    padding-top: 0;
    padding-right: 13px;
    padding-left: 13px;
    margin-bottom: 20px;
`;