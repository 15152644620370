import React, { useState, useContext } from 'react';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [marketId, setMarketId] = useState('b641bc13-8f56-4f4b-b1ea-c1f833b982bd');
  const [marketIdArray, setMarketIdArray] = useState(['b641bc13-8f56-4f4b-b1ea-c1f833b982bd']);
  const [isSingleMarket, setIsSingleMarket] = useState(true);
  // const [singleMarketId, setSingleMarketId] = useState(['b641bc13-8f56-4f4b-b1ea-c1f833b982bd']);
  // const [regionMarketId, setRegionMarketId] = useState(['b641bc13-8f56-4f4b-b1ea-c1f833b982bd']);
  // const [superRegionMarketId, setSuperRegionMarketId] = useState(['b641bc13-8f56-4f4b-b1ea-c1f833b982bd']);
  const [regionId, setRegionId] = useState('');

  const [marketIdMerchants, setMarketIdMerchants] = useState('b641bc13-8f56-4f4b-b1ea-c1f833b982bd');
  const [coordinates, setCoordinates] = useState({ latitude: 30.23119, longitude: -93.21163 });
  const [trafficLayer, setTrafficLayer] = useState(false);
  const [displayPolygon, setDisplayPolygon] = useState(false);
  const [routesReadyCheck, setRoutesReadyCheck] = useState(false);
  const [routesDeliveringCheck, setRoutesDeliveringCheck] = useState(false);
  const [routesExpeditedCheck, setRoutesExpeditedCheck] = useState(false);
  const [routesAlcoholCheck, setRoutesAlcoholCheck] = useState(false);
  const [driversAvailableCheck, setDriversAvailableCheck] = useState(false);
  const [driversEnrouteCheck, setDriversEnrouteCheck] = useState(false);
  const [driversAlcoholCheck, setDriversAlcoholCheck] = useState(false);
  const [driversCarCheck, setDriversCarCheck] = useState(false);
  const [driversSUVCheck, setDriversSUVCheck] = useState(false);
  const [driversTruckCheck, setDriversTruckCheck] = useState(false);
  const [driversTrailerCheck, setDriversTrailerCheck] = useState(false);
  const [routeClick, setRouteClick] = useState('');
  const [routeClickTwice, setRouteClickTwice] = useState({
    path: [{ address: { lat: 30.219754, lng: -93.219149 } }, { address: { lat: 30.224563, lng: -93.206998 } }],
    bounds: { min_lat: 30.1760821, min_lng: -93.2492377, max_lat: 30.185249, max_lng: -93.2095957 }
  });
  const [enrouteClickDriver, setEnrouteClickDriver] = useState('');
  const [readyRoutes, setReadyRoutes] = useState([]);
  const [deliveringRoutes, setDeliveringRoutes] = useState([]);
  const [availableDrivers, setAvailableDrivers] = useState([]);
  const [enrouteDrivers, setEnrouteDrivers] = useState([]);
  const [selectedMergingRoutes, setSelectedMergingRoutes] = useState([]);
  const [showMergingRoutesMap, setShowMergingRoutesMap] = useState(false);

  const [selectedMarketLabelValue, setSelectedMarketLabelValue] = useState({
    label: 'Lake Charles, LA',
    value: { id: 'b641bc13-8f56-4f4b-b1ea-c1f833b982bd', coordinates: { latitude: 30.23119, longitude: -93.21163 } }
  });

  return (
    <AppContext.Provider
      value={{
        marketId,
        setMarketId,
        marketIdArray,
        setMarketIdArray,
        isSingleMarket,
        setIsSingleMarket,
        // singleMarketId,
        // setSingleMarketId,
        // regionMarketId,
        // setRegionMarketId,
        // superRegionMarketId,
        // setSuperRegionMarketId,
        regionId,
        setRegionId,
        marketIdMerchants,
        setMarketIdMerchants,
        coordinates,
        setCoordinates,
        trafficLayer,
        setTrafficLayer,
        displayPolygon,
        setDisplayPolygon,
        routesReadyCheck,
        setRoutesReadyCheck,
        routesDeliveringCheck,
        setRoutesDeliveringCheck,
        routesExpeditedCheck,
        setRoutesExpeditedCheck,
        routesAlcoholCheck,
        setRoutesAlcoholCheck,
        driversAvailableCheck,
        setDriversAvailableCheck,
        driversEnrouteCheck,
        setDriversEnrouteCheck,
        driversAlcoholCheck,
        setDriversAlcoholCheck,
        driversCarCheck,
        setDriversCarCheck,
        driversSUVCheck,
        setDriversSUVCheck,
        driversTruckCheck,
        setDriversTruckCheck,
        driversTrailerCheck,
        setDriversTrailerCheck,
        routeClick,
        setRouteClick,
        routeClickTwice,
        setRouteClickTwice,
        enrouteClickDriver,
        setEnrouteClickDriver,
        readyRoutes,
        setReadyRoutes,
        deliveringRoutes,
        setDeliveringRoutes,
        availableDrivers,
        setAvailableDrivers,
        enrouteDrivers,
        setEnrouteDrivers,
        selectedMergingRoutes,
        setSelectedMergingRoutes,
        showMergingRoutesMap,
        setShowMergingRoutesMap,
        selectedMarketLabelValue,
        setSelectedMarketLabelValue
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
