/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import classnames from 'classnames';
import { Input, InputGroupAddon, InputGroupText, InputGroup, Row, FormGroup, Col } from 'reactstrap';
import Select from 'react-select';
import getRoles from '../../../Auth/getRoles';

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      password: '',
      selectedRole: '',
      currentRole: '',
      usernameState: '',
      firstnameState: '',
      lastnameState: '',
      emailState: '',
      phoneState: '',
      passwordState: '',
      selectedRoleState: 'has-danger'
    };
  }

  componentWillMount() {
    getRoles().then(val => {
      if (val === 'error') {
        this.props.history.push('/logout');
      } else {
        this.setState({ currentRole: val[0] });
      }
    });
  }
  verifyUsername = value => {
    var usernameRex = /^[0-9A-Za-z_.-]+$/;
    if (usernameRex.test(value)) {
      return true;
    }
    return false;
  };

  // function that returns true if value is email, false otherwise
  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  verifyPhone = value => {
    var phoneRex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (value.replace(/[^0-9]/g, '').length > 9 && value.replace(/[^0-9]/g, '').length < 14) {
      return true;
    }
    return false;
  };

  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  // function that verifies if value contains only numbers
  verifyNumber = value => {
    var numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      case 'username':
        if (this.verifyUsername(event.target.value)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      case 'password':
        if (event.target.value) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      default:
        break;
    }
    switch (stateName) {
      case 'username':
        if (this.state.username.length > 15) {
          this.setState({ usernameState: 'too-long' });
        }
    }
    // switch (stateName) {
    //   case 'lastname':
    //     this.setState({ username: this.state.firstname.toLowerCase() + '.' + event.target.value.toLowerCase(), usernameState: 'has-success' });
    //     break;
    //   case 'firstname':
    //     this.setState({ username: event.target.value.toLowerCase() + '.' + this.state.lastname, usernameState: 'has-success' });
    //     break;
    // }
    this.setState({ [stateName]: event.target.value });
  };
  isValidated = () => {
    if (
      this.state.firstnameState === 'has-success' &&
      this.state.lastnameState === 'has-success' &&
      this.state.emailState === 'has-success' &&
      this.state.phoneState === 'has-success' &&
      this.state.usernameState === 'has-success'
    ) {
      return true;
    } else {
      if (this.state.firstnameState !== 'has-success') {
        this.setState({ firstnameState: 'has-danger' });
      }
      if (this.state.lastnameState !== 'has-success') {
        this.setState({ lastnameState: 'has-danger' });
      }
      if (this.state.emailState !== 'has-success') {
        this.setState({ emailState: 'has-danger' });
      }
      if (this.state.phoneState !== 'has-success') {
        this.setState({ phoneState: 'has-danger' });
      }
      if (this.state.usernameState !== 'has-success' || this.state.usernameState == 'too-long') {
        this.setState({ usernameState: 'has-danger' });
      }
      return true;
    }
  };

  render() {
    return (
      <>
        <h4 className="info-text">Account Profile Information</h4>
        <form autoComplete="off">
          <input autoComplete="off" hidden="true"></input>
          <Row className="justify-content-center mt-10">
            <Col sm="10">
              <FormGroup>
                <label>Account Type</label>
                <Select
                  className={classnames('react-select info', this.state.selectedRoleState, {
                    'input-group-focus': this.state.selectedRoleFocus
                  })}
                  classNamePrefix="react-select"
                  name="role"
                  onChange={value => {
                    this.setState({ selectedRole: value, selectedRoleState: 'has-success' });
                  }}
                  // options={
                  //   this.state.currentRole == '200'
                  //     ? [{ label: 'Store Manager', val: '220' }, { label: 'Inventory Manger', val: '230' }, { label: 'Delivery Manager', val: '240' }]
                  //     : [{ label: 'Inventory Manger', val: '230' }, { label: 'Delivery Manager', val: '240' }]
                  // }
                  options={this.state.currentRole == '200' ? [{ label: 'Store Manager', val: '220' }] : []}
                  placeholder="Select a role"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col sm="5">
              <label>First Name</label>
              <InputGroup
                className={classnames(this.state.firstnameState, {
                  'input-group-focus': this.state.firstnameFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="firstname"
                  type="text"
                  onChange={e => this.change(e, 'firstname', 'length', 1)}
                  onFocus={e => this.setState({ firstnameFocus: true })}
                  onBlur={e => this.setState({ firstnameFocus: false })}
                />
                {this.state.firstnameState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="5">
              <label>Last Name</label>
              <InputGroup
                className={classnames(this.state.lastnameState, {
                  'input-group-focus': this.state.lastnameFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="lastname"
                  type="text"
                  onChange={e => this.change(e, 'lastname', 'length', 1)}
                  onFocus={e => this.setState({ lastnameFocus: true })}
                  onBlur={e => this.setState({ lastnameFocus: false })}
                />
                {this.state.lastnameState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="5">
              <label>Email</label>
              <InputGroup
                className={classnames(this.state.emailState, {
                  'input-group-focus': this.state.emailFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-email-85" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="email"
                  type="email"
                  onChange={e => this.change(e, 'email', 'email')}
                  onFocus={e => this.setState({ emailFocus: true })}
                  onBlur={e => this.setState({ emailFocus: false })}
                />
                {this.state.emailState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="5">
              <label>Phone Number</label>
              <InputGroup
                className={classnames(this.state.phoneState, {
                  'input-group-focus': this.state.phoneFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-mobile" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="number"
                  type="tel"
                  placeholder="Ex: 1234567890"
                  onChange={e => this.change(e, 'phone', 'phone')}
                  onFocus={e => this.setState({ phoneFocus: true })}
                  onBlur={e => this.setState({ phoneFocus: false })}
                />
                {this.state.phoneState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm="5">
              <label>User Name (Max 15 characters)</label>
              <InputGroup
                className={classnames(this.state.usernameState, {
                  'input-group-focus': this.state.usernameFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="username"
                  type="text"
                  maxLength={15}
                  value={this.state.username}
                  onChange={e => this.change(e, 'username', 'username')}
                  onFocus={e => this.setState({ usernameFocus: true })}
                  onBlur={e => this.setState({ usernameFocus: false })}
                />
                {this.state.usernameState === 'has-danger' ? <label className="error">This field is required.</label> : null}
                {this.state.usernameState === 'too-long' ? <label className="error">Too long, must be less than 15 characters.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="5">
              <label>Password (Minimal 10, must include at least 1 capital, 1 number and 1 special character)</label>
              <InputGroup
                className={classnames(this.state.passwordState, {
                  'input-group-focus': this.state.passwordFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-laptop" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="password"
                  type="password"
                  minLength={10}
                  value={this.state.password}
                  onChange={e => this.change(e, 'password', 'password')}
                  onFocus={e => this.setState({ passwordFocus: true })}
                  onBlur={e => this.setState({ passwordFocus: false })}
                />
                {this.state.passwordState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

export default Wizard;
