import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Row, Col, Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody, FormGroup, Label, Input, Alert } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { useHistory, useParams, Link } from 'react-router-dom';

import { AddIcon, UploadIcon } from '../../assets/svgs/WhiteIcons';
import { Styles } from './Styles/DepartmentsStyles';
import { PencilIcon, TrashIcon } from '../../assets/svgs/WhiteIcons';
import Loading from '../Global/Loading';
import * as Semantic from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import Switch from 'react-switch';
import * as inventory_urls from '../../urls/api-inventory/admin';
import { Breadcrumbs, Typography } from '@material-ui/core';
// import BreadcrumbNav from '../../BreadcrumbNav';

export const Department = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [error, setError] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [department, setDepartment] = useState('');
  const [index, setIndex] = useState(null);
  const [showAddDepartmentDialog, setShowAddDepartmentDialog] = useState(false);
  const [showEditDepartmentDialog, setShowEditDepartmentDialog] = useState(false);
  const [storeName, setStoreName] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const [departmentTaxRate, setDepartmentTaxRate] = useState(null);
  const [departmentTaxCode, setDepartmentTaxCode] = useState(null);
  const [departmentContainsAlcohol, setDepartmentContainsAlcohol] = useState(false);
  const [departmentTransactionRate, setDepartmentTransactionRate] = useState(null);
  const [departmentDescription, setDepartmentDescription] = useState('');
  const [editDepartmentObj, setEditDepartmentObj] = useState(null);
  const [editDepartmentIndex, setEditDepartmentIndex] = useState(null);
  const [deleteDepartmentIndex, setDeleteDepartmentIndex] = useState(null);
  let skipGeneralClick = false;
  let history = useHistory();
  let params = useParams();
  const [storeTransactionRate, setStoreTransactionRate] = useState(null);
  const [storeTaxRate, setStoreTaxRate] = useState(null);
  const [taxCodes, setTaxCodes] = useState(null);
  const { register, errors } = useForm();

  const [storeId, setStoreId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [store, setStore] = useState(null);

  useEffect(() => {
    getDepartments(params.id);
    getTaxCodes();
  }, []);

  const getDepartments = () => {
    axios({
      method: `post`,
      url: inventory_urls.hasuraHost,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      data: {
        query: `{Stores(where: {store_id: {_eq: "${params.id}"}}) {
                name,
                store_id,
                tax_rate,
                transaction_rate,
                sells_alcohol
                Departments(order_by: {name: asc}) {
                    department_id,
                    name,
                    description,
                    tax_rate,
                    is_enabled,
                    tax_code,
                    transaction_rate
                    contains_alcohol
                }
              }Categories(where: {store_id: {_eq: "${params.id}"}}) {
                category_id
                contains_alcohol
              }
            }`
      }
    })
      .then(response => {
        setLoading(false);
        if (response.data.errors) {
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].message === 'Malformed Authorization header' || response.data.errors[0].extensions.code === 'invalid-jwt') {
              this.props.history.push('/logout');
            }
          }
        }
        if (response.data.data) {
          setDepartments(response.data.data.Stores[0].Departments);
          setStoreName(response.data.data.Stores[0].name);
          setStoreTransactionRate(response.data.data.Stores[0].transaction_rate);
          setStoreTaxRate(response.data.data.Stores[0].tax_rate);
          setStoreId(response.data.data.Stores[0].store_id);
          setCategoryId(response.data.data.Categories[0].category_id);
          setDepartmentId(response.data.data.Stores[0].Departments.department_id);
          setStore(response.data.data.Stores[0]);
        }
      })
      .catch(error => {
        setLoading(false);
        setError('Unable to fetch departments. Please try again later');
        console.log('error: ', error);
      });
  };

  const toggleDepartment = (dept, index) => {
    // setLoading(true);
    var data = {
      is_enabled: !dept.is_enabled
    };
    axios({
      method: `put`,
      url: inventory_urls.editDepartment + dept.department_id,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      data: data
    })
      .then(response => {
        let newDept = {
          department_id: response.data.payload.department_id,
          name: response.data.payload.name,
          description: response.data.payload.description,
          tax_rate: Number(response.data.payload.tax_rate),
          tax_code: Number(response.data.payload.tax_code),
          transaction_rate: response.data.payload.transaction_rate,
          is_enabled: response.data.payload.is_enabled
        };
        let depts = departments.slice();
        depts[index] = newDept;
        setDepartments(depts);
        // setLoading(false);
      })
      .catch(error => {
        // setLoading(false);
        setError('Unable to toggle department state. Please try again later');
        console.log('error:', error);
      });
  };

  const getTaxCodes = () => {
    axios({
      method: 'GET',
      url: inventory_urls.getTaxCodes,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    })
      .then(response => {
        // Set taxCodes here:
        let newTaxCodes = [];
        response.data.payload.map((element, key) => {
          newTaxCodes.push({
            key: key,
            text: element.name,
            value: element.tax_code
          });
        });
        setTaxCodes(newTaxCodes);
        setLoading(false);
      })
      .catch(error => {
        console.log('error: ', error.response);
      });
  };

  const getTrProps = (rowInfo, column, cell) => {
    if (rowInfo) {
      return {
        style: {
          height: `70px`,
          margin: `5px`,
          padding: `0px`,
          background: `transparent`,
          cursor: 'pointer'
        },
        onClick: e => {
          if (!skipGeneralClick) {
            history.push('/admin/inventory/store/' + params.id + '/department/' + column.original.department_id);
          }
          skipGeneralClick = false;
        }
      };
    }
    return {};
  };

  const getTheadThProps = () => {
    return {
      style: {
        fontSize: `9px`,
        fontWeight: `600`,
        textAlign: `left`,
        color: `#cdcaca`,
        textTransform: `uppercase`
      }
    };
  };

  const deleteDepartment = department_id => {
    axios({
      method: 'DELETE',
      url: inventory_urls.deleteDepartment + department_id,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token') }
    })
      .then(response => {
        let deps = departments;
        deps.splice(deleteDepartmentIndex, 1);
        setDepartments(deps);
        setDepartment('');
        setDeleteDepartmentIndex(null);
        setShowWarning(false);
      })
      .catch(error => {
        setDepartment('');
        setDeleteDepartmentIndex(null);
        setShowWarning(false);
        setError('Unable to delete department. Please try again later');
        console.log('error: ', error);
      });
  };

  const editDepartment = () => {
    if (editDepartmentObj) {
      axios({
        method: `put`,
        url: inventory_urls.editDepartment + editDepartmentObj.department_id,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'content-type': 'application/json'
        },
        data: editDepartmentObj
      })
        .then(response => {
          let newDept = {
            department_id: response.data.payload.department_id,
            name: response.data.payload.name,
            description: response.data.payload.description,
            tax_rate: response.data.payload.tax_rate,
            tax_code: response.data.payload.tax_code,
            transaction_rate: response.data.payload.transaction_rate,
            is_enabled: response.data.payload.is_enabled
          };
          let depts = departments;
          depts[editDepartmentIndex] = newDept;
          setDepartments(depts);
          setEditDepartmentIndex(null);
          setEditDepartmentObj(null);
          setShowEditDepartmentDialog(false);
        })
        .catch(error => {
          setEditDepartmentIndex(null);
          setEditDepartmentObj(null);
          setShowEditDepartmentDialog(false);
          setError('Unable to update departments. Please try again later');
          console.log('error: ', error);
        });
    }
  };

  const addDepartment = () => {
    if (departmentName !== '' && departmentDescription !== '') {
      axios({
        method: `post`,
        url: inventory_urls.createDepartment,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'content-type': 'application/json'
        },
        data: {
          name: departmentName,
          description: departmentDescription,
          tax_rate: departmentTaxRate,
          tax_code: departmentTaxCode,
          transaction_rate: departmentTransactionRate / 100,
          store_id: params.id,
          contains_alcohol: departmentContainsAlcohol
        }
      })
        .then(response => {
          let newDept = {
            department_id: response.data.payload.department_id,
            name: response.data.payload.name,
            description: response.data.payload.description,
            tax_rate: response.data.payload.tax_rate,
            tax_code: response.data.payload.tax_code,
            transaction_rate: response.data.payload.transaction_rate,
            is_enabled: response.data.payload.is_enabled
          };
          let depts = departments.concat(newDept);
          setDepartments(depts);
          setShowAddDepartmentDialog(false);
        })
        .catch(error => {
          setError('Unable to add department. Please try again later');
          console.log('error: ', error.response);
        });
    }
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: props => <div>{props.value}</div>
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: props => <div>{props.value}</div>
    },
    {
      Header: 'Tax Rate',
      accessor: 'tax_rate',
      Cell: props => <div>{props.value ? (props.value * 100).toFixed(2) + '%' : (storeTaxRate * 100).toFixed(2) + '%'}</div>
    },
    {
      Header: 'Transaction Rate',
      accessor: 'transaction_rate',
      Cell: props => <div>{props.value ? (props.value * 100).toFixed(2) + '%' : (storeTransactionRate * 100).toFixed(2) + '%'}</div>
    },
    {
      Header: 'Available',
      accessor: 'is_enabled',
      width: 100,
      Cell: props => (
        <Switch
          size={20}
          checked={props.value}
          onChange={() => {
            skipGeneralClick = true;
            toggleDepartment(props.original, props.index);
          }}
          defaultValue={true}
          onColor="#2bbbae"
          offColor="#f15a29"
        />
      )
    },
    {
      Header: 'Actions',
      width: 100,
      Cell: props => (
        <Row>
          {/* <Col><LocalOfferIcon /></Col> */}
          <Col
            id="edit"
            style={{ cursor: `pointer` }}
            onClick={() => {
              skipGeneralClick = true;
              setEditDepartmentObj(props.original);
              setShowEditDepartmentDialog(true);
              setEditDepartmentIndex(props.index);
            }}
          >
            <PencilIcon />
          </Col>
          <Col
            id="delete"
            style={{ cursor: `pointer` }}
            onClick={() => {
              skipGeneralClick = true;
              setDeleteDepartmentIndex(props.index);
              setDepartment(props.original.department_id);
              setShowWarning(true);
            }}
          >
            <TrashIcon />
          </Col>
        </Row>
      )
    }
  ];

  const handleDisplayPath = () => {};

  if (loading) return <Loading />;
  return (
    <>
      <Styles>
        <Alert color="danger" isOpen={error === '' ? false : true} toggle={() => setError('')}>
          <strong>{error}</strong>
        </Alert>
        {/* <ol className="breadcrumb">
                    <li><a>Stores </a></li>
                    <li><a> / Departments</a></li>
                </ol> */}
        <div className="header">
          <div className="routing-title">
            <Link to="/admin/dashboard" className="store-name">
              {storeName}
            </Link>
            <h4>Departments</h4>
          </div>
          <div className="upload-add-container">
            <Link
              to={{
                pathname: '/admin/inventory/store/' + params.id + '/category/' + categoryId + '/product/' + 'add_product',
                state: {
                  isEdit: false,
                  product: null
                }
              }}
            >
              <button className="product">
                <AddIcon />
                <p style={{ marginLeft: `10px` }}>Add Product</p>
              </button>
            </Link>
            {/* <button className="upload">
                            <UploadIcon />
                            <p style={{marginLeft: `10px`}}>Upload CSV</p>
                        </button> */}
            <button className="product" onClick={() => setShowAddDepartmentDialog(true)}>
              <AddIcon />
              <p style={{ marginLeft: `10px` }}>Add Department</p>
            </button>
          </div>
        </div>
        <div className="table-container">
          {/* <div className="sort-search-container">
                        <label>
                            <input type="search" />
                        </label>
                        <button className="view-all-btn">View All</button>
                        <Dropdown isOpen={dropdownOpen} search toggle={toggle}>
                            <DropdownToggle
                                tag="span"
                                data-toggle="dropdown"
                                aria-expanded={dropdownOpen}
                            >
                                Sort by
                            </DropdownToggle>
                            <DropdownMenu search >
                                <div onClick={toggle}>Custom dropdown item</div>
                            </DropdownMenu>
                        </Dropdown>
                    </div> */}
          {/* Breadcrumbs */}
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/admin/inventory" style={{ color: '#5e72e4' }}>
              Stores
            </Link>

            <Typography style={{ color: '#5e72e4', fontWeight: 'bold' }}>{storeName}</Typography>
          </Breadcrumbs>
          {/* End Breadcrumbs */}
          <ReactTable
            data={departments}
            columns={columns}
            resizable={false}
            defaultPageSize={5}
            className="-striped -highlight"
            getTrProps={getTrProps}
            getTheadThProps={getTheadThProps}
          />
        </div>
      </Styles>
      <div id="warning-alert" hidden={!showWarning}>
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-200px' }}
          title="Are you sure?"
          onConfirm={() => {
            deleteDepartment(department);
          }}
          onCancel={() => {
            setDepartment('');
            setShowWarning(false);
          }}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          <div>You are about to delete a department from your inventory.</div>
          <div>This will delete all categories and products that belong to this department.</div>
          <div>This action cannot be undone.</div>
        </ReactBSAlert>
      </div>
      <Modal isOpen={showAddDepartmentDialog} centered>
        <h4 style={{ textAlign: `left`, fontSize: `15px`, color: `#f15a29`, fontWeight: `500`, padding: `20px` }}>Add Department</h4>
        <ModalBody style={{ paddingTop: 0 }}>
          <FormGroup>
            <Label style={{ color: `#494747`, fontSize: `12px` }}>Department Name*</Label>
            <Input
              id="department-name"
              name="createDepartmentName"
              onChange={e => setDepartmentName(e.target.value)}
              type="text"
              style={{ color: 'black' }}
              placeholder="Powertools, Books, ect."
              ref={register({
                required: 'This field is required.',
                minLength: {
                  value: 3,
                  message: 'Must be at least 3 characters.'
                },
                maxLength: {
                  value: 25,
                  message: 'Cannot be more than 25 characters.'
                }
              })}
            />
            {errors.createDepartmentName && errors.createDepartmentName.message}
          </FormGroup>
          <FormGroup>
            <Label style={{ color: `#494747`, fontSize: `12px` }}>Department Description*</Label>
            <Input
              id="department-description"
              name="createDepartmentDescription"
              type="text"
              onChange={e => setDepartmentDescription(e.target.value)}
              style={{ color: 'black' }}
              placeholder="Jackets, Hammers, Staff Favorites, etc."
              ref={register({
                required: 'This field is required.',
                minLength: {
                  value: 3,
                  message: 'Must be at least 3 characters.'
                },
                maxLength: {
                  value: 25,
                  message: 'Cannot be more than 25 characters.'
                }
              })}
            />
            {errors.createDepartmentDescription && errors.createDepartmentDescription.message}
          </FormGroup>
          <FormGroup>
            <Label style={{ color: `#494747`, fontSize: `12px` }}>Tax Category</Label>
            {/* <Input 
                            id="department-tax-rate" 
                            onChange={(e) => setDepartmentTaxRate(e.target.value)} 
                            type="number" 
                            step="0.01" 
                            style={{color: "black"}} 
                            placeholder="10%" 
                        /> */}
            <div>
              <Semantic.Dropdown
                placeholder="Tax Category"
                button
                style={{
                  width: '100%',
                  // backgroundColor: '#27293d',
                  borderColor: '#000'
                }}
                search
                selection
                value={departmentTaxCode}
                onChange={(e, data) => {
                  setDepartmentTaxCode(data.value);
                }}
                options={taxCodes}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label style={{ color: `#494747`, fontSize: `12px` }}>Transaction Rate(%)</Label>
            <Input
              id="department-transaction-rate"
              name="department-tran-rate"
              onChange={e => setDepartmentTransactionRate(e.target.value)}
              type="number"
              step="0.01"
              style={{ color: 'black' }}
              placeholder="10.25"
            />
            <h5 style={{ color: 'gray', marginTop: 10 }}>**Empty tax and transaction rates will inherit store values</h5>
            {store ? (
              store.sells_alcohol ? (
                <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
                  <Label check>
                    <Input
                      type="checkbox"
                      id="schedule-check"
                      onClick={e => {
                        setDepartmentContainsAlcohol(!departmentContainsAlcohol);
                      }}
                    />
                    <span className="form-check-sign" />
                    This department may contain alcohol
                  </Label>
                </FormGroup>
              ) : null
            ) : null}
            <div style={{ display: `flex`, flexDirection: `row-reverse`, margin: `15px 0px` }}>
              <button
                style={{ backgroundColor: 'orange', color: `#ffffff`, width: `70px`, height: `35px`, borderRadius: `5px`, border: `transparent`, marginLeft: `5px` }}
                onClick={() => {
                  addDepartment();
                }}
              >
                Save
              </button>
              <button
                style={{ backgroundColor: `#344675`, color: `#ffffff`, width: `70px`, height: `35px`, borderRadius: `5px`, border: `transparent` }}
                onClick={() => {
                  setDepartmentName('');
                  setDepartmentDescription('');
                  setDepartmentTaxRate(null);
                  setDepartmentTransactionRate(null);
                  setShowAddDepartmentDialog(false);
                  setDepartmentContainsAlcohol(false);
                }}
              >
                Cancel
              </button>
            </div>
          </FormGroup>
        </ModalBody>
      </Modal>
      <Modal isOpen={showEditDepartmentDialog} centered>
        <h4 style={{ textAlign: `left`, fontSize: `15px`, color: `#f15a29`, fontWeight: `500`, padding: `20px` }}>Edit Department</h4>
        {editDepartmentObj !== null && (
          <ModalBody style={{ paddingTop: 0 }}>
            <FormGroup>
              <Label style={{ color: `#494747`, fontSize: `12px` }}>Department Name*</Label>
              <Input
                id="department-name"
                name="editDepartmentName"
                value={editDepartmentObj.name}
                onChange={e =>
                  setEditDepartmentObj({
                    name: e.target.value,
                    department_id: editDepartmentObj.department_id,
                    description: editDepartmentObj.description,
                    tax_rate: editDepartmentObj.tax_rate,
                    transaction_rate: editDepartmentObj.transaction_rate,
                    tax_code: editDepartmentObj.tax_code
                  })
                }
                type="text"
                style={{ color: 'black' }}
                placeholder="Powertools, Books, ect."
                ref={register({
                  required: 'This field is required.',
                  minLength: {
                    value: 3,
                    message: 'Must be at least 3 characters.'
                  },
                  maxLength: {
                    value: 25,
                    message: 'Cannot be more than 25 characters.'
                  }
                })}
              />
              {errors.editDepartmentName && errors.editDepartmentName.message}
            </FormGroup>
            <FormGroup>
              <Label style={{ color: `#494747`, fontSize: `12px` }}>Department Description*</Label>
              <Input
                id="department-description"
                name="editDepartmentDescription"
                type="text"
                value={editDepartmentObj.description}
                onChange={e =>
                  setEditDepartmentObj({
                    name: editDepartmentObj.name,
                    department_id: editDepartmentObj.department_id,
                    description: e.target.value,
                    tax_rate: editDepartmentObj.tax_rate,
                    transaction_rate: editDepartmentObj.transaction_rate,
                    tax_code: editDepartmentObj.tax_code
                  })
                }
                style={{ color: 'black' }}
                placeholder="Jackets, Hammers, Staff Favorites, etc."
                ref={register({
                  required: 'This field is required.',
                  minLength: {
                    value: 3,
                    message: 'Must be at least 3 characters.'
                  },
                  maxLength: {
                    value: 25,
                    message: 'Cannot be more than 25 characters.'
                  }
                })}
              />
              {errors.editDepartmentDescription && errors.editDepartmentDescription.message}
            </FormGroup>
            <FormGroup>
              <Label style={{ color: `#494747`, fontSize: `12px` }}>Tax Category</Label>
              {/* <Input 
                                id="department-tax-rate"
                                name="departmentTaxRate"
                                value={editDepartmentObj.tax_rate} 
                                onChange={(e) => setEditDepartmentObj({
                                    name: editDepartmentObj.name, 
                                    department_id: editDepartmentObj.department_id, 
                                    description: editDepartmentObj.description, 
                                    tax_rate: parseFloat(e.target.value), 
                                    transaction_rate: editDepartmentObj.transaction_rate
                                })} 
                                type="number" 
                                step="0.01" 
                                style={{color: "black"}} 
                                placeholder="Powertools, Books, ect."
                            /> */}
              <div>
                <Semantic.Dropdown
                  placeholder="Tax Categories"
                  button
                  style={{
                    width: '100%',
                    borderColor: '#000'
                  }}
                  search
                  selection
                  onChange={(e, data) => {
                    setEditDepartmentObj({
                      name: editDepartmentObj.name,
                      department_id: editDepartmentObj.department_id,
                      description: editDepartmentObj.description,
                      tax_rate: editDepartmentObj.tax_rate,
                      tax_code: data.value,
                      transaction_rate: editDepartmentObj.transaction_rate
                    });
                  }}
                  value={editDepartmentObj.tax_code}
                  options={taxCodes}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label style={{ color: `#494747`, fontSize: `12px` }}>Transaction Rate</Label>
              <Input
                id="department-tax-rate"
                value={editDepartmentObj.transaction_rate}
                onChange={e => {
                  setEditDepartmentObj({
                    name: editDepartmentObj.name,
                    department_id: editDepartmentObj.department_id,
                    description: editDepartmentObj.description,
                    tax_rate: editDepartmentObj.tax_rate,
                    transaction_rate: parseFloat(e.target.value)
                  });
                }}
                type="number"
                step="0.01"
                style={{ color: 'black' }}
                placeholder="0.01"
              />
              <div style={{ display: `flex`, flexDirection: `row-reverse`, margin: `15px 0px` }}>
                <button
                  style={{ backgroundColor: 'orange', color: `#ffffff`, width: `70px`, height: `35px`, borderRadius: `5px`, border: `transparent`, marginLeft: `5px` }}
                  onClick={() => {
                    editDepartment();
                  }}
                >
                  Save
                </button>
                <button
                  style={{ backgroundColor: `#344675`, color: `#ffffff`, width: `70px`, height: `35px`, borderRadius: `5px`, border: `transparent` }}
                  onClick={() => {
                    setShowEditDepartmentDialog(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </FormGroup>
          </ModalBody>
        )}
      </Modal>
    </>
  );
};
