import styled from 'styled-components';

export const Styles = styled.div`
    .ReactTable .rt-table {
        margin: 120px 30px 10px 115px;
        padding: 20px 20px;
        width: 100%;
        margin: 0 auto;
        background-color: #27293d;
    }
    .sort-search-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: baseline;
        label {
            position: relative;
            margin-right: 10px;
        }
            input[type=search] {
                background-color: #27293d;
                border: solid 1px #424d90;
                border-radius: 5px;
                width: 35px;
                height: 30px;
                font-size: 13px;
                -webkit-transition: width 0.4s ease-in-out;
                transition: width 0.4s ease-in-out;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='Component_71_1' width='22' height='22' data-name='Component 71 – 1' viewBox='0 0 22 22'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bisolation:isolate%7D.cls-2%7Bfill:%23efeeee%7D %3C/style%3E%3C/defs%3E%3Cg id='Group_1355' class='cls-1' data-name='Group 1355'%3E%3Cg id='Group_1348' data-name='Group 1348'%3E%3Cpath id='Path_339' d='M.229 15.557l3.42-3.42a7.841 7.841 0 1 1 2.213 2.213l-3.419 3.42a.782.782 0 0 1-1.107 0L.23 16.664a.782.782 0 0 1-.001-1.107zm16.206-7.731a6.261 6.261 0 1 0-6.261 6.261 6.268 6.268 0 0 0 6.261-6.261z' class='cls-2' data-name='Path 339'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") center / contain no-repeat;
            }
            input[type=search]::focus {
                width: 100%;
            }
            .view-all-btn {
                height: 30px;
                background-color: #424d90;
                color: #efeeee;
                border-radius: 5px;
                border: transparent;
                font-size: 13px;
                margin-right: 10px;
            }
            .dropdown {
                background-color: #27293d;
                border: solid 1px #424d90;
                border-radius: 10px;
                height: 30px;
                font-size: 13px;
                color: #efeeee;
                width: 180px;
                padding: 5px;
                    span {
                    }
            }
    }
`;