import React, { useState } from 'react';
import { Button } from 'reactstrap';

const DriverSMSRefreshButton = ({ retrieveMessage, messageRecipient }) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonColor, setButtonColor] = useState('success');

  const handleButtonClick = () => {
    retrieveMessage(messageRecipient);
    setButtonDisabled(true);
    setButtonColor('grey');

    setTimeout(() => {
      setButtonDisabled(false);
      setButtonColor('success');
    }, 1000);
  };

  return (
    <div>
      <Button color={buttonColor} disabled={buttonDisabled} onClick={handleButtonClick}>
        Refresh
      </Button>
    </div>
  );
};

export default DriverSMSRefreshButton;
