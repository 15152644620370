import styled from 'styled-components';

export const Styles = styled.div`
    height: 580px;
    width: 50%;
    background-color: #27293d;
    border-radius: 5px;
    border: transparent;
    padding: 20px;
        h5 {
            font-size: 15px;
            color: #f7941d;
            font-weight: 600;
        }
        h6 {
            text-transform: uppercase;
            font-size: 12px;
            color: #cdcaca;
        }
        .regular-hours-header {
            display: grid;
            grid-template-columns: 1fr 1fr 0.5fr 1fr 1fr;
            grid-template-rows: 14.28%;
            grid-row-gap: 10px;
            grid-column-gap: 10px;
        }
        
`;

export const StyledHr = styled.hr`
    background-color: #344675;
    width: auto;
    margin-top: 0;
    padding-top: 0;
    padding-right: 13px;
    padding-left: 13px;
    margin-bottom: 20px;
`;