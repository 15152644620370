import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Button, Card, CardBody, CardTitle, Label, FormGroup, Input, Row, Col, Collapse, Container } from 'reactstrap';
import { useGlobalContext } from '../../context';

export default function MapFilters({ marketIdArray }) {
  const {
    trafficLayer,
    setTrafficLayer,
    displayPolygon,
    setDisplayPolygon,
    routesReadyCheck,
    setRoutesReadyCheck,
    routesDeliveringCheck,
    setRoutesDeliveringCheck,
    routesExpeditedCheck,
    setRoutesExpeditedCheck,
    routesAlcoholCheck,
    setRoutesAlcoholCheck,
    driversAvailableCheck,
    setDriversAvailableCheck,
    driversEnrouteCheck,
    setDriversEnrouteCheck,
    driversAlcoholCheck,
    setDriversAlcoholCheck,
    driversCarCheck,
    setDriversCarCheck,
    driversSUVCheck,
    setDriversSUVCheck,
    driversTruckCheck,
    setDriversTruckCheck,
    driversTrailerCheck,
    setDriversTrailerCheck
  } = useGlobalContext();

  function reset() {
    let trafficCB = document.getElementById('traffic-layer');
    trafficCB.checked = false;
    let displayPolygonCB = document.getElementById('polygon');
    displayPolygonCB.checked = false;
    let routeReadyCB = document.getElementById('route-ready');
    routeReadyCB.checked = false;
    let routeDeliveringCB = document.getElementById('route-delivering');
    routeDeliveringCB.checked = false;
    let routeExpeditedCB = document.getElementById('route-expedited');
    routeExpeditedCB.checked = false;
    let routeAlcoholCB = document.getElementById('route-alcohol');
    routeAlcoholCB.checked = false;
    let driverAvailableCB = document.getElementById('driver-available');
    driverAvailableCB.checked = false;
    let driverEnrouteCB = document.getElementById('driver-enroute');
    driverEnrouteCB.checked = false;
    let driverAlcoholCB = document.getElementById('driver-alcohol');
    driverAlcoholCB.checked = false;
    let driverCarCB = document.getElementById('driver-car');
    driverCarCB.checked = false;
    let driverSUVCB = document.getElementById('driver-SUV');
    driverSUVCB.checked = false;
    let driverTruckCB = document.getElementById('driver-truck');
    driverTruckCB.checked = false;
    let driverTrailerCB = document.getElementById('driver-trailer');
    driverTrailerCB.checked = false;
    setTrafficLayer(false);
    setDisplayPolygon(false);
    setRoutesReadyCheck(false);
    setRoutesDeliveringCheck(false);
    setRoutesExpeditedCheck(false);
    setRoutesAlcoholCheck(false);
    setDriversAvailableCheck(false);
    setDriversEnrouteCheck(false);
    setDriversAlcoholCheck(false);
    setDriversCarCheck(false);
    setDriversSUVCheck(false);
    setDriversTruckCheck(false);
    setDriversTrailerCheck(false);
  }

  useEffect(() => {
    reset();
  }, [marketIdArray]);

  return (
    <Container>
      <Row>
        <Col xs lg="1">
          <p style={{ marginTop: '0.15rem' }}>Traffic:</p>
        </Col>
        <Col xs lg="2">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="traffic-layer"
                value={trafficLayer}
                onClick={e => {
                  setTrafficLayer(!trafficLayer);
                }}
              />
              <span className="form-check-sign" />
              Traffic
            </Label>
          </FormGroup>
        </Col>
        <Col xs lg="1">
          <p style={{ marginTop: '0.15rem' }}>Polygons:</p>
        </Col>
        <Col xs lg="6">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="polygon"
                value={displayPolygon}
                onClick={e => {
                  setDisplayPolygon(!displayPolygon);
                }}
              />
              <span className="form-check-sign" />
              Display
            </Label>
          </FormGroup>
        </Col>
        <Col xs="auto">
          <Button
            size="sm"
            color="default"
            onClick={() => {
              reset();
            }}
          >
            Clear
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs lg="1">
          <p style={{ marginTop: '0.15rem' }}>Routes:</p>
        </Col>
        <Col xs lg="2">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="route-ready"
                onClick={e => {
                  setRoutesReadyCheck(!routesReadyCheck);
                }}
              />
              <span className="form-check-sign" />
              Ready
            </Label>
          </FormGroup>
        </Col>
        <Col xs lg="2">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="route-delivering"
                onClick={e => {
                  setRoutesDeliveringCheck(!routesDeliveringCheck);
                }}
              />
              <span className="form-check-sign" />
              Delivering
            </Label>
          </FormGroup>
        </Col>
        <Col xs lg="2">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="route-expedited"
                onClick={e => {
                  setRoutesExpeditedCheck(!routesExpeditedCheck);
                }}
              />
              <span className="form-check-sign" />
              Expedited
            </Label>
          </FormGroup>
        </Col>
        <Col xs lg="2">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="route-alcohol"
                onClick={e => {
                  setRoutesAlcoholCheck(!routesAlcoholCheck);
                }}
              />
              <span className="form-check-sign" />
              Alcohol
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs lg="1">
          <p style={{ marginTop: '0.15rem' }}>Drivers:</p>
        </Col>
        <Col xs lg="2">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="driver-available"
                // defaultChecked="checked"
                onClick={e => {
                  setDriversAvailableCheck(!driversAvailableCheck);
                }}
              />
              <span className="form-check-sign" />
              Available
            </Label>
          </FormGroup>
        </Col>
        <Col xs lg="2">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="driver-enroute"
                // defaultChecked="checked"
                onClick={e => {
                  setDriversEnrouteCheck(!driversEnrouteCheck);
                }}
              />
              <span className="form-check-sign" />
              Enroute
            </Label>
          </FormGroup>
        </Col>
        <Col xs lg="2">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="driver-alcohol"
                onClick={e => {
                  setDriversAlcoholCheck(!driversAlcoholCheck);
                }}
              />
              <span className="form-check-sign" />
              Alcohol
            </Label>
          </FormGroup>
        </Col>
        <Col xs lg="1">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="driver-car"
                onClick={e => {
                  setDriversCarCheck(!driversCarCheck);
                }}
              />
              <span className="form-check-sign" />
              Car
            </Label>
          </FormGroup>
        </Col>
        <Col xs lg="1">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="driver-SUV"
                onClick={e => {
                  setDriversSUVCheck(!driversSUVCheck);
                }}
              />
              <span className="form-check-sign" />
              SUV
            </Label>
          </FormGroup>
        </Col>
        <Col xs lg="1">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="driver-truck"
                onClick={e => {
                  setDriversTruckCheck(!driversTruckCheck);
                }}
              />
              <span className="form-check-sign" />
              Truck
            </Label>
          </FormGroup>
        </Col>
        <Col xs lg="2">
          <FormGroup check className="mt-1" style={{ marginTop: '0px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="driver-trailer"
                onClick={e => {
                  setDriversTrailerCheck(!driversTrailerCheck);
                }}
              />
              <span className="form-check-sign" />
              Trailer
            </Label>
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
}
