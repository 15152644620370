import styled from 'styled-components';

export const Styles = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr 1fr 1fr;
    grid-template-rows: 14.28%;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    justify-items: start;
    align-items: start;
    color: #efeeee;
    padding-bottom: 10px;
        p {
            margin: 0 auto;
        }
        .input-group, .form-group{
            background-color: #344675;
            border-radius: 20px;
        }
`;