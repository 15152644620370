import React from 'react';
import { Label, FormGroup, Input, Row, Col, Button, CardTitle } from 'reactstrap';
import MultiDropCustomerAutoComplete from './MultiDropCustomerAutoComplete';
import LineItemsMultipleDrop from './LineItemsMultipleDrop';
//! Google Map AutoComplete
import GoogleMapAutoComplete from '../../../Helpers/GoogleMapAutoComplete';
import '../../../Helpers/googleMapAutoComplete.css';
import MapboxAutocomplete from '../../../../assets/react-mapbox-autocomplete/ReactMapboxAutocomplete';
import '../../../../assets/react-mapbox-autocomplete/index.css';
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ29waHItZW5naW5lZXJpbmciLCJhIjoiY2syYzd6NjdrMmo3azNtcGU1cXZzMTh1MSJ9.cHMxwcG_MoQk-l4ZO3bdEA';

const DropoffInfoMultiDrop = ({
  multiDropWaypointsArray,
  setMultiDropWaypointsArray,
  multiDropWaypointsErrors,
  setMultiDropWaypointsErrors,
  storeId,
  handleMultiDropWaypointSelect,
  vendorLat,
  vendorLong,
  pickupItems
}) => {
  return (
    <>
      {multiDropWaypointsArray.map((waypoint, index) => {
        return (
          <>
            <hr style={{ borderColor: '#344675', borderStyle: 'solid' }}></hr>
            <Row>
              <Col style={{ textAlign: 'left' }}>
                <Button
                  className="btn btn-info"
                  size="sm"
                  onClick={() => {
                    let updateArray = [];
                    let errorsUpdate = [];
                    for (let i = 0; i < index; i++) {
                      updateArray.push(multiDropWaypointsArray[i]);
                      errorsUpdate.push(multiDropWaypointsErrors[i]);
                    }
                    updateArray.push({
                      waypointFirstname: '',
                      waypointLastname: '',
                      waypointPhone: '',
                      waypointAddress1: null,
                      waypointAddress2: null,
                      waypointFullAddress: '',
                      waypointLat: '',
                      waypointLng: '',
                      waypointCity: '',
                      waypointState: '',
                      waypointCountry: '',
                      waypointZip: '',
                      waypointInfo: {},
                      waypointDropoffInstruction: '',
                      waypointBusinessName: '',
                      waypointCustomerSuggestions: [],
                      isWaypointAutoFilled: false,
                      dropItems: [{ quantity: 1, detail: '' }],
                      dropItemsError: [false]
                    });
                    errorsUpdate.push({
                      waypointFirstname: false,
                      waypointLastname: false,
                      waypointPhone: false,
                      waypointAddress: false
                    });
                    for (let i = index; i < multiDropWaypointsArray.length; i++) {
                      updateArray.push(multiDropWaypointsArray[i]);
                      errorsUpdate.push(multiDropWaypointsErrors[i]);
                    }
                    setMultiDropWaypointsArray(updateArray);
                    setMultiDropWaypointsErrors(errorsUpdate);
                  }}
                >
                  Add Drop Above{' '}
                  <span style={{ display: 'inline' }} role="img">
                    🔺
                  </span>
                </Button>
              </Col>

              {multiDropWaypointsArray.length > 1 && (
                <Col style={{ textAlign: 'right' }}>
                  <Button
                    className="btn btn-warning"
                    size="sm"
                    onClick={() => {
                      setMultiDropWaypointsArray(
                        multiDropWaypointsArray.filter((ar, i) => {
                          return i !== index;
                        })
                      );
                      setMultiDropWaypointsErrors(
                        multiDropWaypointsErrors.filter((ie, i) => {
                          return i !== index;
                        })
                      );
                    }}
                  >
                    Delete
                  </Button>
                </Col>
              )}
            </Row>
            <CardTitle tag="h4" style={{ padding: 5, paddingTop: 10, textAlign: 'left' }}>
              Dropoff ({index + 1}) Information
            </CardTitle>
            <Row form={true}>
              <Col style={{ textAlign: 'left' }}>
                <Label for="infiniteDropCustomerFirstName">First Name</Label>
                <FormGroup className={multiDropWaypointsErrors[index].waypointFirstname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                  <MultiDropCustomerAutoComplete
                    multiDropWaypointsArray={multiDropWaypointsArray}
                    setMultiDropWaypointsArray={setMultiDropWaypointsArray}
                    index={index}
                    storeId={storeId}
                  />
                  <div hidden={!multiDropWaypointsErrors[index].waypointFirstname}>
                    <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                  </div>
                </FormGroup>
              </Col>
              <Col style={{ textAlign: 'left' }}>
                <Label for="customerLastName">Last Name</Label>
                <FormGroup className={multiDropWaypointsErrors[index].waypointLastname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                  <Input
                    required
                    type="text"
                    name="customerLastName"
                    id="customer-last-name"
                    placeholder="Doe"
                    onChange={e => {
                      setMultiDropWaypointsArray(
                        multiDropWaypointsArray.map((ar, i) => (i === index ? { ...multiDropWaypointsArray[index], waypointLastname: e.target.value } : ar))
                      );
                    }}
                    value={multiDropWaypointsArray[index].waypointLastname}
                  />
                  <div hidden={!multiDropWaypointsErrors[index].waypointLastname}>
                    <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                  </div>
                </FormGroup>
              </Col>
              <Col style={{ textAlign: 'left' }}>
                <Label for="customerPhone">Phone Number</Label>
                <FormGroup className={multiDropWaypointsErrors[index].waypointPhone ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                  <Input
                    required
                    type="text"
                    name="customerPhone"
                    id="customer-phone"
                    placeholder="555-555-5555"
                    onChange={e =>
                      setMultiDropWaypointsArray(multiDropWaypointsArray.map((ar, i) => (i === index ? { ...multiDropWaypointsArray[index], waypointPhone: e.target.value } : ar)))
                    }
                    value={multiDropWaypointsArray[index].waypointPhone}
                  />
                  <div hidden={!multiDropWaypointsErrors[index].waypointPhone}>
                    <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid phone number (10-13 digits)</p>
                  </div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="order-multidrop">Business Name (optional)</Label>
                  <Input
                    type="text"
                    name="multidropBusinessName"
                    id="multidrop-business-name"
                    placeholder="Store Name"
                    value={multiDropWaypointsArray[index].waypointBusinessName}
                    onChange={e => {
                      setMultiDropWaypointsArray(
                        multiDropWaypointsArray.map((ar, i) => (i === index ? { ...multiDropWaypointsArray[index], waypointBusinessName: e.target.value } : ar))
                      );
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Delivery Address</Label>
                  {waypoint.isWaypointAutoFilled ? (
                    <Input
                      value={multiDropWaypointsArray[index].waypointFullAddress}
                      onChange={e =>
                        setMultiDropWaypointsArray(
                          multiDropWaypointsArray.map((ar, i) => (i === index ? { ...multiDropWaypointsArray[index], waypointFullAddress: e.target.value } : ar))
                        )
                      }
                    />
                  ) : (
                    // <MapboxAutocomplete
                    //   id="mapbox-autocomplete2"
                    //   inputClass="form-control mb-2"
                    //   publicKey={MAPBOX_TOKEN}
                    //   index={index}
                    //   onSuggestionSelect={handleMultiDropWaypointSelect}
                    //   country="us"
                    //   resetSearch={false}
                    //   // readOnly={isWaypointStore}
                    //   defaultValue={null}
                    //   value={multiDropWaypointsArray[index].waypointFullAddress}
                    //   query={multiDropWaypointsArray[index].waypointFullAddress}
                    //   placeholder="Street Address"
                    //   proximityLat={vendorLat}
                    //   proximityLng={vendorLong}
                    // />
                    <GoogleMapAutoComplete
                      inputClass="form-control mb-2"
                      index={index}
                      onSuggestionSelect={handleMultiDropWaypointSelect}
                      country="us"
                      resetSearch={false}
                      // readOnly={isWaypointStore}
                      defaultValue={null}
                      value={multiDropWaypointsArray[index].waypointFullAddress}
                      query={multiDropWaypointsArray[index].waypointFullAddress}
                      placeholder="Street Address"
                      proximityLat={vendorLat}
                      proximityLng={vendorLong}
                    />
                  )}
                  {/* <div hidden={errorMessageDistance.errorCode !== 1}>
                    <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageDistance.errorMessage}</p>
                  </div> */}
                  <div hidden={!multiDropWaypointsErrors[index].waypointAddress}>
                    <p style={{ color: 'red', marginTop: '4px' }}>Please choose an address from the dropdown menu</p>
                  </div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="order-dropoff">Dropoff Instructions (optional)</Label>
                  <Input
                    type="text"
                    name="waypointDropoff"
                    id="waypoint-dropoff-instructions"
                    placeholder="Gate Code, Apt. #, etc."
                    value={multiDropWaypointsArray[index].waypointDropoffInstruction}
                    onChange={e => {
                      if (e.target.value.length < 510) {
                        return setMultiDropWaypointsArray(
                          multiDropWaypointsArray.map((ar, i) => (i === index ? { ...multiDropWaypointsArray[index], waypointDropoffInstruction: e.target.value } : ar))
                        );
                      } else {
                        return;
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <LineItemsMultipleDrop
              title="Drop off Item(s)"
              multiDropWaypointsArray={multiDropWaypointsArray}
              setMultiDropWaypointsArray={setMultiDropWaypointsArray}
              index={index}
              pickList={pickupItems}
            />
            <Row>
              <Col style={{ textAlign: 'left' }}>
                <Button
                  className="btn btn-info"
                  size="sm"
                  onClick={() => {
                    let updateArray = [];
                    let errorsUpdate = [];
                    for (let i = 0; i < index + 1; i++) {
                      updateArray.push(multiDropWaypointsArray[i]);
                      errorsUpdate.push(multiDropWaypointsErrors[i]);
                    }

                    updateArray.push({
                      waypointFirstname: '',
                      waypointLastname: '',
                      waypointPhone: '',
                      waypointAddress1: null,
                      waypointAddress2: null,
                      waypointFullAddress: '',
                      waypointLat: '',
                      waypointLng: '',
                      waypointCity: '',
                      waypointState: '',
                      waypointCountry: '',
                      waypointZip: '',
                      waypointInfo: {},
                      waypointDropoffInstruction: '',
                      waypointCustomerSuggestions: [],
                      isWaypointAutoFilled: false,
                      dropItems: [{ quantity: 1, detail: '' }],
                      dropItemsError: [false]
                    });
                    errorsUpdate.push({
                      waypointFirstname: false,
                      waypointLastname: false,
                      waypointPhone: false,
                      waypointAddress: false
                    });
                    for (let i = index + 1; i < multiDropWaypointsArray.length; i++) {
                      updateArray.push(multiDropWaypointsArray[i]);
                      errorsUpdate.push(multiDropWaypointsErrors[i]);
                    }
                    setMultiDropWaypointsArray(updateArray);
                    setMultiDropWaypointsErrors(errorsUpdate);
                  }}
                >
                  Add Drop Below{' '}
                  <span style={{ display: 'inline' }} role="img">
                    🔻
                  </span>
                </Button>
              </Col>
            </Row>
          </>
        );
      })}
    </>
  );
};

export default DropoffInfoMultiDrop;
