/*!
 * Black Dashboard PRO React - v1.0.0
 * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
import classNames from 'classnames';
import { Button, Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Input, NavbarBrand, Navbar, NavLink, Nav, Container, Modal } from 'reactstrap';

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: 'navbar-transparent'
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateColor);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateColor);
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: 'bg-white'
      });
    } else {
      this.setState({
        color: 'navbar-transparent'
      });
    }
  };

  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: 'navbar-transparent'
      });
    } else {
      this.setState({
        color: 'bg-white'
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };

  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };

  render() {
    return (
      <>
        <Navbar
          className={classNames('navbar-absolute', {
            [this.state.color]: this.props.location.pathname.indexOf('full-screen-map') === -1
          })}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              {!this.props.hideSidebar && (
                <div>
                  <div className="navbar-minimize d-inline">
                    <Button className="minimize-sidebar btn-just-icon" color="link" id="tooltip209599" onClick={this.props.handleMiniClick}>
                      <i className="tim-icons icon-align-center visible-on-sidebar-regular" style={{ color: '#f15a29' }} />
                      <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" style={{ color: '#f15a29' }} />
                    </Button>
                  </div>
                  <div
                    className={classNames('navbar-toggle d-inline', {
                      toggled: this.props.sidebarOpened
                    })}
                  >
                    <button className="navbar-toggler" type="button" onClick={this.props.toggleSidebar}>
                      <span className="navbar-toggler-bar bar1" />
                      <span className="navbar-toggler-bar bar2" />
                      <span className="navbar-toggler-bar bar3" />
                    </button>
                  </div>
                </div>
              )}

              <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                {this.props.brandText}
              </NavbarBrand>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navigation"
              aria-expanded={false}
              aria-label="Toggle navigation"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>
                {/* <InputGroup className="search-bar" tag="li">
                  <Button
                    color="link"
                    data-target="#searchModal"
                    data-toggle="modal"
                    id="search-button"
                    onClick={this.toggleModalSearch}
                  >
                    <i className="tim-icons icon-zoom-split" />
                    <span className="d-lg-none d-md-block">Search</span>
                  </Button>
                </InputGroup> */}
                {/* <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                  >
                    <div className="notification d-none d-lg-block d-xl-block" />
                    <i className="tim-icons icon-sound-wave" />
                    <p className="d-lg-none">Notifications</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Mike John responded to your email
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        You have 5 more tasks
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Your friend Michael is in town
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Another notification
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Another one
                      </DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                <img
                  src={require('../../assets/img/gophr-logo.png')}
                  alt="Gophr Logo"
                  style={{
                    width: 'auto',
                    height: 50,
                    marginLeft: 'auto'
                  }}
                />
                <UncontrolledDropdown nav>
                  <DropdownToggle caret color="default" data-toggle="dropdown" nav onClick={e => e.preventDefault()}>
                    <div className="photo">
                      <img alt="..." src={this.props.pictureUrl} />
                    </div>
                    <b className="caret d-none d-lg-block d-xl-block" />
                    {/* <p className="d-lg-none">Log out</p> */}
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <DropdownItem
                        className="nav-item"
                        onClick={() => {
                          this.props.history.push('/logout');
                        }}
                      >
                        Log out
                      </DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <Modal modalClassName="modal-search" isOpen={this.state.modalSearch} toggle={this.toggleModalSearch}>
          <div className="modal-header">
            <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleModalSearch}>
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AdminNavbar;
