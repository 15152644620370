import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import { Button } from 'react-bootstrap';
import { Spinner } from 'reactstrap';

import CardSection from './CardSection';
import * as user_urls from '../../../urls/api-users/admin';

export default function CardSetupForm() {
  const [submitting, setSubmitting] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState('');

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setSubmitting(true);
    setIsSuccessful(false);
    setHasError(false);
    // Call stripe.confirmCardPayment() with the client secret.

    axios({
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: user_urls.usersHost + '/payment/stripe-client-secret'
    })
      .then(response => {
        const output = ReactHtmlParser(response.data.payload);
        const clientSecret = output[2].props['data-secret'];
        stripe
          .confirmCardSetup(clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement)
              // billing_details: {
              //   name: 'Gophr APP'
              // }
            }
          })
          .then(result => {
            if (result.error) {
              // Display result.error.message in your UI.
              setSubmitting(false);
              setError(result.error.message);
            } else {
              // The setup has succeeded. Display a success message and send
              // result.setupIntent.payment_method to your server to save the
              // card to a Customer
              setSubmitting(false);
              setIsSuccessful(true);
              elements.getElement(CardElement).clear();
              setError('');
            }
          })
          .catch(error => {
            console.log('error: ', error.message);
            setSubmitting(false);
            setError('Something wrong, please contact Gophr for support!');
          });
      })
      .catch(error => {
        console.log('error: ', error.message);
        setSubmitting(false);
        setError('Something wrong, please contact Gophr for support!');
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <CardSection />
      </div>

      <div style={{ paddingTop: '20px' }}>
        {!submitting ? (
          <button disabled={!stripe} style={{ backgroundColor: 'orange', fontSize: '12px', height: '35px', width: '80px', borderRadius: '10px' }}>
            Save Card
          </button>
        ) : (
          // <button style={{ backgroundColor: 'blue', fontSize: '12px', height: '35px', width: '180px' }}>
          //   <strong>
          //     <p>Submitting in Process...</p>
          //   </strong>
          // </button>
          <>
            {/* <Button variant="light" disabled>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              Submitting...
            </Button> */}
            <Button variant="light" size="sm">
              <div className="loading-spinner" display="flex" flex-direction="column">
                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                <p> Submitting...</p>
              </div>
            </Button>
          </>
        )}
      </div>
      <div>
        {isSuccessful ? (
          <div style={{ marginTop: '20px', backgroundColor: 'green', fontSize: '12px', padding: '8px', width: '90%' }}>
            <strong>
              <p> You have successfully added the card!</p>
            </strong>
          </div>
        ) : null}
      </div>
      <div>
        {error ? (
          <div style={{ marginTop: '20px', backgroundColor: 'red', fontSize: '12px', padding: '8px', width: '90%' }}>
            <strong>
              <p> {error}</p>
            </strong>
          </div>
        ) : null}
      </div>
    </form>
  );
}
