/*!
 * Black Dashboard PRO React - v1.0.0
 * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
import ReactWizard from 'react-bootstrap-wizard';
import { Col, Modal, ModalHeader } from 'reactstrap';
import axios from 'axios';

import * as user_urls from '../../urls/api-users/admin';

import Step1 from './WizardSteps/Merchant_Step1';
import Step2 from './WizardSteps/Merchant_Step2';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

var steps = [
  {
    stepName: 'Account',
    stepIcon: 'tim-icons icon-single-02',
    component: Step1
  },
  {
    stepName: 'Package',
    stepIcon: 'fas fa-users',
    component: Step2
  }
];

class CreateMerchantWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.finishButtonClick = this.finishButtonClick.bind(this);
  }
  finishButtonClick(allStates) {
    var merchant = {
      firstname: allStates.Account.firstname,
      lastname: allStates.Account.lastname,
      username: allStates.Account.username,
      email: allStates.Account.email,
      phone_number: allStates.Account.phone.replace(/\D/g, ''),
      address_1: allStates.Account.address,
      address_2: allStates.Account.address_2,
      city: allStates.Account.city,
      state: allStates.Account.state.val,
      zip: allStates.Account.zip,
      store: {
        name: allStates.Account.store_name,
        phone: allStates.Account.store_phone.replace(/\D/g, ''),
        address_1: allStates.Account.address,
        address_2: allStates.Account.address2,
        city: allStates.Account.city,
        state: allStates.Account.state.val,
        zip: allStates.Account.zip,
        latitude: allStates.Account.latitude,
        longitude: allStates.Account.longitude,
        tax_rate: allStates.Account.tax_rate / 100,
        transaction_rate: allStates.Account.transaction_rate / 100,
        timezone: allStates.Account.timezone.val,
        merchant_firstname: allStates.Account.firstname,
        merchant_lastname: allStates.Account.lastname,
        merchant_email: allStates.Account.email,
        role: '200',
        market_id: allStates.Account.market.val,
        ein: allStates.Account.ein,
        store_time: allStates.Package.storeHours,
        selected_package: allStates.Package.packageSelected,
        msa_version: 'v2022.1' // hard code for now
      }
    };
    this.setState({ loading: true });

    axios({
      method: 'post',
      url: user_urls.createSuperMerchant,
      withCredentials: true,
      data: merchant,
      headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('access_token') }
    })
      .then(response => {
        if (response.status === 200) {
          this.setState({ loading: false }, () => {
            this.props.notify('Successfully added merchant');
            this.props.toggleDropdown();
          });
        } else {
          this.setState({ loading: false }, () => {
            this.props.notify('Something went wrong, please try again');
          });
        }
      })
      .catch(error => {
        console.log('error: ', error.response);
        this.setState({ loading: false }, () => {
          this.props.notify('Something went wrong, please try again');
        });
      });
  }
  render() {
    return (
      <>
        <div className="content" style={{ paddingTop: 20 }}>
          <Col className="mr-auto ml-auto" md="10">
            <ReactWizard
              steps={steps}
              navSteps
              validate
              title="Create a merchant"
              headerTextCenter
              finishButtonClasses="btn-wd btn-info"
              nextButtonClasses="btn-wd btn-info"
              previousButtonClasses="btn-wd"
              progressbar
              color="blue"
              finishButtonClick={this.finishButtonClick}
            />
          </Col>
        </div>
        <Modal isOpen={this.state.loading} centered={true}>
          <div style={{ padding: 50, textAlign: 'center' }}>
            <ModalHeader style={{ justifyContent: 'center' }}>
              <h3 style={{ color: 'black' }}>Please wait...</h3>
            </ModalHeader>
            <Loader type="TailSpin" color="#00BFFF" height={100} width={100} />
          </div>
        </Modal>
      </>
    );
  }
}

export default CreateMerchantWizard;
