/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactWizard from 'react-bootstrap-wizard';
import { Col, Modal, ModalHeader } from 'reactstrap';
import axios from 'axios';

import * as urls from '../../urls/api-users/admin';

import Step1 from './WizardSteps/Driver_Step1';
import Step2 from './WizardSteps/Driver_Step2';
import Step3 from './WizardSteps/Driver_Step3';

import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

var steps = [
  {
    stepName: 'Account',
    stepIcon: 'tim-icons icon-single-02',
    component: Step1
  },
  {
    stepName: 'Vehicle',
    stepIcon: 'fas fa-car',
    component: Step2
  },
  {
    stepName: 'Contacts',
    stepIcon: 'fas fa-users',
    component: Step3
  }
];

class CreateMerchantWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.finishButtonClick = this.finishButtonClick.bind(this);
  }

  finishButtonClick(allStates) {
    var driver_id = '';
    var driver = {
      email: allStates.Account.email,
      phone_number: allStates.Account.phone.replace(/\D/g, ''),
      username: allStates.Account.username,
      firstname: allStates.Account.firstname,
      lastname: allStates.Account.lastname,
      carrying_capacity: allStates.Account.carrying_capacity,
      address_1: allStates.Account.address,
      address_2: allStates.Account.address2,
      dob: allStates.Account.dob,
      city: allStates.Account.city,
      state: allStates.Account.state.val,
      zip: allStates.Account.zip,
      is_available: true,
      market_id: allStates.Account.market.val
    };

    var vehicle = {
      make: allStates.Vehicle.make,
      model: allStates.Vehicle.model,
      color: allStates.Vehicle.color,
      year: allStates.Vehicle.year,
      description: allStates.Vehicle.description,
      type: allStates.Vehicle.type.val,
      plate_state: allStates.Vehicle.plate_state.val,
      plate_value: allStates.Vehicle.plate_value
    };

    var emergency_contacts = {
      contacts: allStates.Contacts.emergency_contacts
    };
    this.setState({ loading: true });
    axios({
      method: 'post',
      url: urls.createDriver,
      withCredentials: true,
      data: driver,
      headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('access_token') }
    })
      .then(response => {
        driver_id = response.data.payload.auth0_id;
        //  var available_request = axios({
        //   method: 'put',
        //   headers: {
        //     'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
        //     'content-type': 'application/json'
        //   },
        //   url: user_urls.driverEndpoint + driver_id + "/set/available",
        //   });
        var vehicle_request = axios({
          method: 'post',
          url: urls.driverEndpoint + driver_id + '/vehicle',
          withCredentials: true,
          data: vehicle,
          headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('access_token') }
        });
        var contact_request = axios({
          method: 'post',
          url: urls.driverEndpoint + driver_id + '/emergency-contact',
          withCredentials: true,
          data: emergency_contacts,
          headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('access_token') }
        });
        axios
          .all([vehicle_request, contact_request])
          .then(
            axios.spread((vehicle, contact) => {
              if (vehicle.status === 200 && contact.status === 200) {
                this.setState({ loading: false }, () => {
                  this.props.notify('Successfully added driver');
                  this.props.toggleDropdown();
                });
              } else {
                this.setState({ loading: false }, () => {
                  this.props.notify('Something went wrong');
                });
              }
            })
          )
          .catch(error2 => {
            console.log('error2', error2.response);
            this.setState({ loading: false }, () => {
              this.props.notify('Something went wrong');
            });
          });
      })
      .catch(error => {
        console.log('error', error.response);
        this.setState({ loading: false }, () => {
          this.props.notify('Something went wrong');
        });
        return false;
      });
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.loading} centered={true}>
          <div style={{ padding: 50, textAlign: 'center' }}>
            <ModalHeader style={{ justifyContent: 'center' }}>
              <h3 style={{ color: 'black' }}>Please wait...</h3>
            </ModalHeader>
            <Loader type="TailSpin" color="#00BFFF" height={100} width={100} />
          </div>
        </Modal>
        <div className="content" style={{ paddingTop: 20 }}>
          <Col className="mr-auto ml-auto" md="10">
            <ReactWizard
              steps={steps}
              navSteps
              validate
              title="Create a Driver"
              headerTextCenter
              finishButtonClasses="btn-wd btn-info"
              nextButtonClasses="btn-wd btn-info"
              previousButtonClasses="btn-wd"
              progressbar
              color="blue"
              finishButtonClick={this.finishButtonClick}
            />
          </Col>
        </div>
      </>
    );
  }
}

export default CreateMerchantWizard;
