import React, { useState, useEffect } from 'react';
import { Label, FormGroup, Input, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import AddButton from '../../../../assets/img/Group_1383@2x.png';
import DeleteButton from '../../../../assets/svgs/TrashSimple.svg';

const LineItemsRoutedFinalDrop = ({ title, items, setItems, itemsErrorArray, setItemsErrorArray, pickList1, pickList2, dropWaypointList }) => {
  const [showSuggestions, setShowSuggestions] = useState(new Array(items.length).fill(false));
  const [wholePickList, setWholePickList] = useState([]);
  const [isFinalDropItemsAutoFilled, setIsFinalDropItemsAutoFilled] = useState(false);
  const [isFinalDropItemsAutoFilledCleared, setIsFinalDropItemsAutoFilledCleared] = useState(false);

  // useEffect(() => {
  //   setWholePickList(
  //     Array.from(
  //       new Set(
  //         [...pickList1, ...pickList2]
  //           .filter(pick => {
  //             return pick.detail !== '';
  //           })
  //           .map(pi => pi.detail)
  //       )
  //     )
  //   );
  // }, [pickList1, pickList2]);

  useEffect(() => {
    let detailsArray = Array.from(
      new Set(
        [...pickList1, ...pickList2]
          .filter(pick => {
            return pick.detail !== '';
          })
          .map(pi => pi.detail)
      )
    );
    setWholePickList(detailsArray);
    if (isFinalDropItemsAutoFilled) {
      setIsFinalDropItemsAutoFilledCleared(false);
      let combineArray = [];
      let pickList1Copy = JSON.parse(JSON.stringify(pickList1)).filter(ca => ca.detail !== '');
      let pickList2Copy = JSON.parse(JSON.stringify(pickList2)).filter(ca => ca.detail !== '');
      detailsArray.map(da => {
        let count = 0;
        pickList1Copy.map(p1 => {
          if (da === p1.detail) {
            count += p1.quantity;
          }
        });
        pickList2Copy.map(p2 => {
          if (da === p2.detail) {
            count += p2.quantity;
          }
        });
        combineArray.push({ quantity: count, detail: da });
      });

      let restDropArray = [];
      let dropWaypointListCopy = JSON.parse(JSON.stringify(dropWaypointList)).filter(ca => ca.detail !== '');
      if (dropWaypointListCopy.length === 0) {
        if (combineArray.length > 0) {
          setItems(combineArray);
        } else {
          setItems([{ quantity: 1, detail: '' }]);
        }
      } else {
        combineArray.map(ca => {
          let isFind = false;
          dropWaypointListCopy.map(dc => {
            if (ca.detail === dc.detail) {
              isFind = true;
              let count = ca.quantity - dc.quantity;
              if (count > 0) {
                restDropArray.push({ quantity: ca.quantity - dc.quantity, detail: ca.detail });
              }
            }
          });
          if (!isFind) {
            restDropArray.push(ca);
          }
        });
        setItems(restDropArray);
      }
    }
  }, [pickList1, pickList2, isFinalDropItemsAutoFilled, dropWaypointList]);

  useEffect(() => {
    if (isFinalDropItemsAutoFilledCleared) {
      setIsFinalDropItemsAutoFilled(false);
      setItems([{ quantity: 1, detail: '' }]);
    }
  }, [isFinalDropItemsAutoFilledCleared]);

  const selectItem = (e, index, indexAutofill) => {
    setItems(
      items.map((pi, i) => {
        return i === index ? { quantity: items[index].quantity, detail: wholePickList[indexAutofill] } : pi;
      })
    );
    setShowSuggestions(showSuggestions.map((suggestion, i) => (i === index ? false : suggestion)));
  };

  return (
    <>
      <Row form={true} style={{ paddingTop: 0 }}>
        <Col style={{ textAlign: 'left' }}>
          <Label for="LineItems">{title}</Label>
        </Col>
      </Row>
      <Row form={true} style={{ paddingTop: 0 }}>
        <FormGroup check className="mt-3" style={{ marginLeft: '15px', marginBottom: '10px' }}>
          <Label check>
            <Input
              type="checkbox"
              id="round-trip-check"
              value={isFinalDropItemsAutoFilled}
              checked={isFinalDropItemsAutoFilled}
              onClick={e => {
                setIsFinalDropItemsAutoFilled(!isFinalDropItemsAutoFilled);
              }}
            />
            <span className="form-check-sign" />
            Autofill Items
          </Label>
        </FormGroup>
        <FormGroup check className="mt-3" style={{ marginLeft: '15px', marginBottom: '10px' }}>
          <Label check>
            <Input
              type="checkbox"
              id="round-trip-check"
              value={isFinalDropItemsAutoFilledCleared}
              checked={isFinalDropItemsAutoFilledCleared}
              onClick={e => {
                setIsFinalDropItemsAutoFilledCleared(!isFinalDropItemsAutoFilledCleared);
              }}
            />
            <span className="form-check-sign" />
            Clear
          </Label>
        </FormGroup>
      </Row>
      {items.length > 0 &&
        items.map((item, index) => {
          return (
            <>
              <Row id={'lineItem-' + index}>
                <Col sm="1" style={{ textAlign: 'center' }}>
                  <Label for="LineItems" style={{ marginTop: '8px' }}>
                    Quantity
                  </Label>
                </Col>
                <Col sm="2">
                  <FormGroup style={{ width: '90%' }}>
                    <Input
                      placeholder="1"
                      onChange={e => {
                        setItems(
                          items.map((pi, i) => {
                            return i === index ? { quantity: Number(e.target.value), detail: item.detail } : pi;
                          })
                        );
                      }}
                      type="number"
                      min="1"
                      style={{ WebkitAppearance: 'none' }}
                      value={items[index].quantity}
                    />
                  </FormGroup>
                </Col>
                <Col sm="1" style={{ textAlign: 'left' }}>
                  <Label for="pickupItems" style={{ marginTop: '8px' }}>
                    Item Detail
                  </Label>
                </Col>
                <Col sm="6">
                  <FormGroup style={{ width: '100%' }}>
                    <ListGroup>
                      <Input
                        placeholder="ie. name, description..., etc"
                        onChange={e => {
                          setItems(
                            items.map((pi, i) => {
                              return i === index ? { quantity: item.quantity, detail: e.target.value } : pi;
                            })
                          );
                        }}
                        type="text"
                        style={{ WebkitAppearance: 'none' }}
                        value={items[index].detail}
                        onClick={e => {
                          if (wholePickList.length > 0) {
                            setShowSuggestions(showSuggestions.map((suggestion, i) => (i === index ? true : false)));
                          } else {
                            setShowSuggestions(new Array(items.length).fill(false));
                          }
                        }}
                      />
                      {title === 'Drop off Item(s)' && showSuggestions[index]
                        ? wholePickList.map((pick, indexAutofill) => {
                            return (
                              <ListGroupItem
                                style={{ backgroundColor: '#1e1e2b', border: 'none', color: '#cbcac9' }}
                                key={indexAutofill}
                                onMouseOver={e => (e.target.style.background = 'linear-gradient(90deg, hsla(218, 100%, 42%, 0.6) 0%, hsla(0, 0%, 98%, 1) 200%)')}
                                onMouseLeave={e => (e.target.style.background = '#1e1e2b')}
                                onClick={e => selectItem(e, index, indexAutofill)}
                              >
                                {pick}
                              </ListGroupItem>
                            );
                          })
                        : null}
                    </ListGroup>
                  </FormGroup>

                  <div hidden={!itemsErrorArray[index]}>
                    <p style={{ color: 'red', marginTop: '4px', marginBottom: '10px' }}>Detail field is required </p>
                  </div>
                </Col>

                <Col sm="1">
                  {/* <div style={{ opacity: 0 }}>Q</div> */}
                  <div style={{ textAlign: 'right', verticalAlign: 'middle', marginTop: '6px' }}>
                    <img
                      alt="add"
                      style={{ cursor: 'pointer', width: 25, height: 25, verticalAlign: 'middle' }}
                      src={AddButton}
                      onClick={() => {
                        setItems([...items, { quantity: 1, detail: '' }]);
                        setItemsErrorArray([...itemsErrorArray, false]);
                        setShowSuggestions([...showSuggestions, false]);
                      }}
                    />
                  </div>
                </Col>
                <Col sm="1">
                  {/* <div style={{ opacity: 0 }}>Q</div> */}
                  <div style={{ textAlign: 'left', verticalAlign: 'middle', marginTop: '9px' }}>
                    {items.length > 1 && (
                      <img
                        alt="delete"
                        style={{ cursor: 'pointer', width: 20, height: 20, verticalAlign: 'middle' }}
                        src={DeleteButton}
                        onClick={() => {
                          setItems(
                            items.filter((item, i) => {
                              return i !== index;
                            })
                          );
                          setItemsErrorArray(
                            itemsErrorArray.filter((item, i) => {
                              return i !== index;
                            })
                          );
                          setShowSuggestions(
                            showSuggestions.filter((suggestion, i) => {
                              return i !== index;
                            })
                          );
                        }}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </>
          );
        })}
    </>
  );
};

export default LineItemsRoutedFinalDrop;
