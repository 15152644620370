import styled from 'styled-components';

export const Styles = styled.div`
  margin: 0 auto;
  width: 420px;
  margin-left: -420px;
  margin-top: -35px;
  .specific-day-hours {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .image-placeholder {
    padding-bottom: 10px;
  }
  .store-header {
    h4 {
      font-size: 18px;
      color: #f7941d;
    }
  }
  .store-basics {
    margin-bottom: 25px;
    h5 {
      font-size: 15px;
    }
    h6 {
      font-size: 14px;
      color: #5e72e4;
      text-transform: uppercase;
    }
    p {
      font-size: 12px;
      color: #efeeee;
      span {
        margin-right: 30px;
      }
    }
  }
  .hours-and-settings {
    color: #efeeee;
    .store-hours {
      margin-bottom: 25px;
      span {
        margin-right: 15px;
      }
      span {
        cursor: pointer;
      }
    }
    .store-info {
      margin-bottom: 25px;
      span {
        margin-right: 15px;
      }
    }
    .settings-btn {
      background-color: transparent;
      border: none;
    }
  }
`;

export const StyledHr = styled.hr`
  background-color: #344675;
  width: auto;
  margin-top: 0;
  padding-top: 0;
  padding-right: 13px;
  padding-left: 13px;
  margin-bottom: 20px;
`;
