import React from 'react';
import { Label, FormGroup, Input, Row, Col } from 'reactstrap';
const MerchantOrderID = ({ merchantOrderID, setMerchantOrderID }) => {
  return (
    <>
      <Row>
        <Col className="col-6">
          <FormGroup>
            <Label for="merchant-order-id">Store Order ID (optional)</Label>
            <Input
              type="text"
              name="merchant_store_id"
              id="merchant-store-id"
              placeholder="Internal identifier for purchase order/ticket number(i.e. SYC05698)"
              onChange={e => {
                if (e.target.value.length < 510) {
                  return setMerchantOrderID(e.target.value);
                } else {
                  return;
                }
              }}
              value={merchantOrderID}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default MerchantOrderID;
