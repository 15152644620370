import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Styles } from './Styles/SubmitButtonsStyles';

const SubmitButtons = (props) => {
    return (
        <Styles className="submit-styles-container">
            <Button className="save" onClick={() => {props.isValidated()}}>Save</Button>
            <Link to={"/admin/inventory/store/" + props.storeId}>
                <Button className="cancel">Cancel</Button>
            </Link>
        </Styles>
    )
}

export default SubmitButtons;