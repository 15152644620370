import React, { useState } from 'react';
import update_icon from '../../../assets/img/update_icon.png';
import { formatETA } from '../../Helpers/functions/TimesFormat';

function ETAUpdate({ handleOrderETAUpdate, order }) {
  const [inputValue, setInputValue] = useState(formatETA(order.estimated_arrival_time));

  const handleInputChange = e => {
    setInputValue(e.target.value);
  };

  const handleUpdateValue = () => {
    handleOrderETAUpdate(order, inputValue);
    setInputValue(inputValue);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
      <label htmlFor="eta" style={{ marginTop: '8px', marginRight: '8px', color: 'white' }}>
        ETA:
      </label>
      <input type="text" id="eta" value={inputValue} onChange={handleInputChange} placeholder="Enter a time" style={{ width: '66%', marginRight: '8px' }} />

      <img
        src={update_icon}
        alt="Update"
        style={{ width: '20px', height: '20px', cursor: 'pointer' }} // Add cursor style for better user experience
        onClick={handleUpdateValue}
      />
    </div>
  );
}

export default ETAUpdate;
