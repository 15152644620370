import React from 'react';
import { Styles } from './Styles/MerchantListHeaderStyles';
import { AddIcon } from '../../assets/svgs/WhiteIcons';

const MerchantListHeader = () => {
    return (
        <Styles>
            <h3>Merchants</h3>
            {/* <button className="create-merchant-btn"><AddIcon />Create Merchant</button> */}
        </Styles>
    )
}

export default MerchantListHeader;