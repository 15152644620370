import React, { useEffect, useRef } from 'react';
import moment from 'moment';

const MessageLogsContainer = ({ driverMessageRetrieved }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the container when it mounts or updates
    const container = containerRef.current;
    container.scrollTop = container.scrollHeight;
  }, [driverMessageRetrieved]);

  return (
    <div className="message-logs-container" ref={containerRef}>
      {driverMessageRetrieved.map(log => (
        <div className={`message-log ${log.to === '+18334674748' ? 'received' : 'sent'}`} key={log.sid}>
          <div>
            <div className={`${log.from === '+18334674748' ? 'sent-message' : 'received-message'}`}>{log.body}</div>
            <div className="message-info">
              <div className="message-date">{moment(log.time).format('dddd, MMMM-DD-YYYY, hh:mm A')}</div>
              <div className={`${log.status === 'undelivered' ? 'message-status-alert' : 'message-status'}`}>{log.status}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MessageLogsContainer;
