import React, { useState, useEffect, useRef } from 'react';
import ReactTable from 'react-table';
import { useSubscription } from '@apollo/react-hooks';
import { Row, ButtonGroup, Button, Col, Badge, Modal, ModalBody, ModalFooter } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import moment from 'moment';
import tz from 'moment-timezone';
import './MerchantOrderTable.css';
import { formatETA } from '../Helpers/functions/TimesFormat';

import {
  FaHourglassHalf,
  FaPeopleCarry,
  FaHome,
  FaRegCheckCircle,
  FaClock,
  FaBookmark,
  FaCaretDown,
  FaCaretRight,
  FaExclamationTriangle,
  FaExclamationCircle
} from 'react-icons/fa';

import { SUBSCRIBE_TO_ORDERS } from '../../graphql/ShipmentQueries';
import { formatTimestamp } from '../../helpers/formatter';

import Loading from '../Global/Loading';
import Car, { SUV, Truck, BoxTruck, Trailer } from '../../assets/svgs/CarTypes';
import * as shipment_urls from '../../urls/api-shipments/merchant';
import * as inventory_urls from '../../urls/api-inventory/admin';
import * as user_urls from '../../urls/api-users/admin';
import useSound from 'use-sound';
import Sound from '../../assets/CashRegister.mp3';
import MerchantMap from './MerchantMap';
import { Resizable, ResizableBox } from 'react-resizable';
import { useGlobalContext } from '../../context';

const MerchantOrderTable = props => {
  const [almostReady, setAlmostReady] = useState([]);
  const [expandedItems, setExpandedItems] = useState(props.expandedItems);
  const [filter, setFilter] = useState([]);
  const [order, setOrder] = useState([]);
  const [shipment, setShipment] = useState('');
  const [shipmentsToDisplay, setShipmentsToDisplay] = useState([]);
  const [ready, setReady] = useState([]);
  // const [selected, setSelected] = useState('pending');
  const [selected, setSelected] = useState('inProgress');
  const [selectAction, setSelectAction] = useState(null);
  const [selectSavedAction, setSelectSavedAction] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showDecline, setShowDecline] = useState(false);
  const [showDeclineQuoteShipmentAlert, setShowDeclineQuoteShipmentAlert] = useState(false);

  const [showReady, setShowReady] = useState(false);
  const [item, setItem] = useState(null);
  const [store, setStore] = useState(null);
  const [storeLoading, setStoreLoading] = useState(true);
  const [handshakeUrl, setHandshakeUrl] = useState('');

  const [showSignature, setShowSignature] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState('');

  const [amountPending, setAmountPending] = useState(0);
  const [amountApproval, setAmountApproval] = useState(0);

  //! MAP
  const [coordinates, setCoordinates] = useState({ latitude: 30.23119, longitude: -93.21163 });
  const [trafficLayer, setTrafficLayer] = useState(false);
  const [readyShipments, setReadyShipments] = useState([]);
  const [deliveringShipments, setDeliveringShipments] = useState([]);
  const [driversEnroute, setDriversEnroute] = useState([]);
  const [readyShipmentsCheck, setReadyShipmentsCheck] = useState(false);
  const [deliveringShipmentsCheck, setDeliveringShipmentsCheck] = useState(false);
  const [driversEnrouteCheck, setDriversEnrouteCheck] = useState(false);
  const [shipmentClick, setShipmentClick] = useState('');

  //! Market
  const [marketNote, setMarketNote] = useState('');

  const { loading, error, data } = useSubscription(SUBSCRIBE_TO_ORDERS);
  const { setMarketIdMerchants } = useGlobalContext();

  const now = moment();
  const tomorrow = moment().add(1, 'day');

  const [play, { stop, pause }] = useSound(Sound);

  const toggleSignature = () => {
    setShowSignature(!showSignature);
  };

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previousAmountPending = usePrevious(amountPending);
  const previousAmountApproval = usePrevious(amountApproval);

  useEffect(() => {
    if (data) {
      let i = 0;
      let j = 0;
      data.Shipments.forEach(element => {
        if (element.status === 'shipment_created' || element.status === 'shipment_pending' || element.status === 'shipment_pending_schedule') {
          i++;
        }
        if ((element.status === 'shipment_pending' || element.status === 'shipment_pending_schedule') && element.shipping_fee > 0) {
          j++;
        }
      });
      setAmountPending(i);
      setAmountApproval(j);
      if (i > previousAmountPending || j > previousAmountApproval) {
        play();
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      if (error.message === 'cannot start as connection_init failed with : Could not verify JWT: JWTExpired') {
        props.history.push('/');
      }
    }
  }, [error]);

  // useEffect(() => {
  //   if (error) {
  //     if (error.message === 'cannot start as connection_init failed with : Could not verify JWT: JWTExpired') {
  //       props.history.push('/');
  //     }
  //   }
  // }, [error]);

  useEffect(() => {
    axios({
      method: `post`,
      url: inventory_urls.hasuraHost,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      data: { query: `{Stores { store_id sells_alcohol permit_type timezone latitude longitude, market_id } }` }
    })
      .then(response => {
        // setLoading(false);
        if (response.data.errors) {
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].message === 'Malformed Authorization header' || response.data.errors[0].extensions.code === 'invalid-jwt') {
              this.props.history.push('/logout');
            }
          }
        }
        if (response.data.data) {
          setMarketIdMerchants(response.data.data.Stores[0].market_id);
          setStore(response.data.data.Stores[0]);
          setCoordinates({ latitude: response.data.data.Stores[0].latitude, longitude: response.data.data.Stores[0].longitude });
          setStoreLoading(false);
          axios({
            method: 'post',
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('access_token'),
              'content-type': 'application/json'
            },
            url: shipment_urls.hasuraHost,
            data: {
              query:
                `{
              Markets (where: {market_id: {_eq: "` +
                response.data.data.Stores[0].market_id +
                `"}}){
                market_name
                note
                is_disabled            
                }
              }`
            }
          })
            .then(res => {
              if (res.data.data) {
                setMarketNote(res.data.data.Markets[0].note);
              } else if (res.data.errors) {
                console.log('fetch market error: ', res.data.errors);
              }
            })
            .catch(err => {
              console.log('catch fetch market error: ', err);
              // throw error;
            });
        }
      })
      .catch(error => {});
    // NA
  }, []);

  useEffect(() => {
    if (data) {
      var shownShipments = [];
      data.Shipments.forEach(element => {
        if (filter.includes(element.status)) {
          shownShipments.push(element);
        }
      });
      setShipmentsToDisplay(shownShipments);
    }
  }, [filter]);

  useEffect(() => {
    if (data) {
      if (filter) {
        var shownOrders = [];
        data.Shipments.forEach(element => {
          if (filter.includes(element.status)) {
            shownOrders.push(element);
          }
        });
        setShipmentsToDisplay(shownOrders);
      }
      // setFilter(["shipment_created"]);
      if (filter.length === 0) {
        setFilter(['shipment_ready_for_driver', 'shipment_assigned', 'shipment_claimed']);
      }
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      data.Shipments.forEach(element => {
        if (element.status === 'order_scheduled') {
          if (now.isAfter(element.scheduled_for) && !ready.includes(element.order_friendly_id)) {
            if (almostReady.includes(element.order_friendly_id)) {
              for (var i = 0; i < almostReady.length; i++) {
                if (almostReady[i] === element.order_friendly_id) {
                  almostReady.splice(i, 1);
                }
              }
            }
            ready.push(element.order_friendly_id);
          } else {
            if (tomorrow.isAfter(element.scheduled_for) && !almostReady.includes(element.order_friendly_id) && !ready.includes(element.order_friendly_id)) {
              almostReady.push(element.order_friendly_id);
            }
          }
        }
      });
    }
  }, [data]);

  const resetExpand = () => {
    expandedItems[selected] = {};
    props.expand(expandedItems);
  };

  useEffect(() => {
    if (!readyShipmentsCheck) {
      setReadyShipments([]);
    } else {
      if (data) {
        let allReadyShipments = [];
        data.Shipments.map(shipment => {
          if (shipment.status === 'shipment_ready_for_driver') {
            allReadyShipments.push(shipment);
          }
        });
        setReadyShipments(allReadyShipments);
      } else {
        setReadyShipments([]);
      }
    }
  }, [data, readyShipmentsCheck]);

  useEffect(() => {
    if (!deliveringShipmentsCheck) {
      setDeliveringShipments([]);
    } else {
      if (data) {
        let allDeliveringShipments = [];
        data.Shipments.map(shipment => {
          if (shipment.status === 'shipment_claimed' || shipment.status === 'shipment_assigned') {
            allDeliveringShipments.push(shipment);
          }
        });
        setDeliveringShipments(allDeliveringShipments);
      } else {
        setDeliveringShipments([]);
      }
    }
  }, [data, deliveringShipmentsCheck]);

  useEffect(() => {
    if (!driversEnrouteCheck) {
      setDriversEnroute([]);
    } else {
      if (data) {
        let allDriverIDs = [];
        let allDriversInfo = [];
        data.Shipments.map(shipment => {
          if (shipment.status === 'shipment_claimed' || shipment.status === 'shipment_assigned') {
            allDriverIDs.push(shipment.Route.RouteDrivers[0].driver_id);
            allDriversInfo.push(shipment.Route.RouteDrivers[0]);
          }
        });
        let driverIDset = Array.from(new Set(allDriverIDs));

        const fetchDrivers = async () => {
          let drivers = await axios({
            method: 'post',
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('access_token'),
              'content-type': 'application/json'
            },
            url: user_urls.hasuraHost,
            data: {
              query: ` query LastDriverActions ($driverIDs: [uuid!]) {LastDriverActions (where: {driver_id: {_in: $driverIDs}}) {
                  driver_id
                  location_blob  
                  Driver {
                   profile_photo_url
                   restricted_area_credentials
                   can_deliver_alcohol
                  }
                  }
                }`,
              variables: { driverIDs: driverIDset }
            }
          });
          let driversDisplay = drivers.data.data.LastDriverActions.map(driver => {
            let user = {};
            allDriversInfo.map(info => {
              if (driver.driver_id === info.driver_id) {
                user.first_name = info.driver_blob.first_name;
                user.last_name = info.driver_blob.last_name;
                user.phone = info.driver_blob.phone;
                let vehicle = info.vehicle_blob;
                user.vehicle_info = vehicle.year + ' ' + vehicle.make + ' ' + vehicle.model + ' (' + vehicle.color + ')';
                user.profile_photo_url = driver.Driver.profile_photo_url;
                let permits = [];
                if (driver.Driver.can_deliver_alcohol) {
                  permits.push('Alcohol');
                }
                driver.Driver.restricted_area_credentials.map(credential => {
                  permits.push(credential);
                });
                user.permit = permits.map(permit => permit).join(', ');
              }
            });
            return { LastDriverActions: driver, User: user };
          });
          setDriversEnroute(driversDisplay);
        };
        if (driverIDset.length > 0) {
          fetchDrivers();
        }
      } else {
        setDriversEnroute([]);
      }
    }
  }, [data, driversEnrouteCheck]);

  const DeliveryDescription = ({ shipment }) => {
    return (
      <>
        {shipment.description.includes('{') &&
        shipment.description.includes('[') &&
        shipment.description.includes(':') &&
        shipment.description.includes('quantity') &&
        shipment.description.includes('detail') ? (
          JSON.parse(shipment.description).map(de => {
            return (
              <p style={{ wordSpacing: '2px' }}>
                {de.quantity}-{de.detail}
              </p>
            );
          })
        ) : (
          <p> {shipment.description}</p>
        )}
      </>
    );
  };

  const RoutedDirectItemList = ({ order }) => {
    return order.actions.hasOwnProperty('pick_up')
      ? order.actions['pick_up'].map((item, index) => {
          return index === 0 ? <p>Pick: {item}</p> : <p style={{ textIndent: '30px' }}>{item}</p>;
        })
      : order.actions['drop_off'].map((item, index) => {
          return index === 0 ? <p>Drop: {item}</p> : <p style={{ textIndent: '35px' }}>{item}</p>;
        });
  };

  const RoutedDirectItemListTwist = ({ order }) => {
    return order.actions['pick_up'].map((item, index) => {
      if (item.includes('(drop)')) {
        return index === 0 ? <p>Drop: {item.replace('(drop)', '')}</p> : <p style={{ textIndent: '30px' }}>{item.replace('(drop)', '')}</p>;
      } else {
        return index === 0 ? <p>Pick: {item}</p> : <p style={{ textIndent: '30px' }}>{item}</p>;
      }
    });
  };

  const BasicInfo = ({ order }) => {
    return (
      <>
        <p>{order.location_blob.name}</p>
        <p>{order.location_blob.phone}</p>
        <p>{order.location_blob.email}</p>
        <p>{order.location_blob.address_1}</p>
        <p>
          {order.location_blob.city}, {order.location_blob.state}, {order.location_blob.zip}
        </p>
      </>
    );
  };

  const BasicInfoDirectPick = ({ shipment }) => {
    let order;
    shipment.Orders.map(od => {
      if (od.actions.hasOwnProperty('pick_up')) {
        order = od;
      }
    });
    return (
      <>
        <strong>Pickup Info</strong>
        <p>{order.location_blob.name}</p>
        <p>{order.location_blob.phone}</p>
        <p>{order.location_blob.email}</p>
        <p>{order.location_blob.address_1}</p>
        <p>
          {order.location_blob.city}, {order.location_blob.state}, {order.location_blob.zip}
        </p>
        <PickOrDropInstructions order={order} />
        {(shipment.status === 'shipment_assigned' || shipment.status === 'shipment_claimed') && (
          <p>
            <strong>Status:</strong> {order.status === 'order_completed' ? 'done✅' : 'approaching'}
          </p>
        )}
        {(shipment.status === 'shipment_assigned' || shipment.status === 'shipment_claimed') && order.estimated_arrival_time && (
          <p>ETA: {formatETA(order.estimated_arrival_time)}</p>
        )}
      </>
    );
  };

  const BasicInfoDirectDrop = ({ shipment }) => {
    let order;
    let orderPick;
    shipment.Orders.map(od => {
      if (od.actions.hasOwnProperty('drop_off')) {
        order = od;
      } else {
        orderPick = od;
      }
    });
    return (
      <>
        <strong>Dropoff Info</strong>
        <p>{order.location_blob.name}</p>
        <p>{order.location_blob.phone}</p>
        <p>{order.location_blob.email}</p>
        <p>{order.location_blob.address_1}</p>
        <p>
          {order.location_blob.city}, {order.location_blob.state}, {order.location_blob.zip}
        </p>
        <PickOrDropInstructions order={order} />
        {(shipment.status === 'shipment_assigned' || shipment.status === 'shipment_claimed') && <WaypointStatus pickOrder={orderPick} currentOrder={order} />}
      </>
    );
  };

  const PickOrDropInstructions = ({ order }) => {
    if (order.actions.hasOwnProperty('pick_up')) {
      return order.pick_up_instructions ? (
        <>
          <p style={{ textIndent: '10px' }}> Instructions: </p>
          <p style={{ textIndent: '30px' }}>{order.pick_up_instructions}</p>
        </>
      ) : null;
    } else if (order.actions.hasOwnProperty('drop_off')) {
      return order.drop_off_instructions ? (
        <>
          <p style={{ textIndent: '10px' }}> Instructions: </p>
          <p style={{ textIndent: '30px' }}>{order.drop_off_instructions}</p>
        </>
      ) : null;
    }
  };

  const PickOrDropInstructionsTwist = ({ order }) => {
    let isDrop = false;
    order.actions['pick_up'].map(item => {
      if (item.includes('(drop)')) {
        isDrop = true;
      }
    });
    return isDrop ? (
      <>
        {order.drop_off_instructions ? (
          <>
            <p style={{ textIndent: '10px' }}> Instructions: </p>
            <p style={{ textIndent: '30px' }}>{order.drop_off_instructions}</p>
          </>
        ) : null}
      </>
    ) : (
      <>
        {order.pick_up_instructions ? (
          <>
            <p style={{ textIndent: '10px' }}> Instructions: </p>
            <p style={{ textIndent: '30px' }}>{order.pick_up_instructions}</p>
          </>
        ) : null}
      </>
    );
  };

  const BasicInfoMultidropWaypoint = ({ orders, display }) => {
    let pickOrder;
    let finalDropOrder;
    let middleDropOrders = [];
    orders.map(order => {
      if (order.actions.hasOwnProperty('pick_up')) {
        if (order.actions['pick_up'][0].includes('drop')) {
          middleDropOrders.push(order);
        } else {
          pickOrder = order;
        }
      } else if (order.actions.hasOwnProperty('drop_off')) {
        finalDropOrder = order;
      }
    });
    return (
      <>
        <Col className="col-2.5">
          <strong>Pickup Info</strong>
          <BasicInfo order={pickOrder} />
          <RoutedDirectItemList order={pickOrder} />
          <PickOrDropInstructions order={pickOrder} />
          {(pickOrder.status === 'driver_assigned' || pickOrder.status === 'driver_claimed' || pickOrder.status === 'order_completed') && display && (
            <p>
              <strong>Status:</strong> {pickOrder.status === 'order_completed' ? 'done✅' : 'approaching'}
            </p>
          )}
          {(pickOrder.status === 'driver_assigned' || pickOrder.status === 'driver_claimed') && pickOrder.estimated_arrival_time && (
            <p>ETA: {formatETA(pickOrder.estimated_arrival_time)}</p>
          )}
        </Col>
        {middleDropOrders.map(order => {
          return (
            <Col className="col-2.5">
              <strong>Dropoff Info</strong>
              <BasicInfo order={order} />
              <RoutedDirectItemListTwist order={order} /> <PickOrDropInstructionsTwist order={order} />
              {(order.status === 'driver_assigned' || order.status === 'driver_claimed' || order.status === 'order_completed') && display && (
                <WaypointStatus pickOrder={pickOrder} currentOrder={order} />
              )}
            </Col>
          );
        })}
        <Col className="col-2.5">
          <strong>Dropoff Info</strong>
          <BasicInfo order={finalDropOrder} />
          <RoutedDirectItemList order={finalDropOrder} />
          <PickOrDropInstructions order={finalDropOrder} />
          {(finalDropOrder.status === 'driver_assigned' || finalDropOrder.status === 'driver_claimed' || finalDropOrder.status === 'order_completed') && display && (
            <WaypointStatus pickOrder={pickOrder} currentOrder={finalDropOrder} />
          )}
        </Col>
      </>
    );
  };

  const BasicInfoRoutedWaypoint = ({ orders, display }) => {
    let pickOrders = [];
    let firstPickOrder;
    let middlePickOrder;
    let middleDropOrder;
    let finalDropOrder;
    let hasMiddleDrop = false;

    orders.map(order => {
      if (order.actions.hasOwnProperty('drop_off')) {
        finalDropOrder = order;
      } else if (order.actions.hasOwnProperty('pick_up')) {
        if (order.actions['pick_up'][0].includes('drop')) {
          middleDropOrder = order;
          hasMiddleDrop = true;
        } else {
          pickOrders.push(order);
        }
      }
    });

    if (orders.length === 4) {
      pickOrders.map(po => {
        if (po.location_blob.address === middleDropOrder.location_blob.address) {
          middlePickOrder = po;
        } else {
          firstPickOrder = po;
        }
      });
    } else if (orders.length === 3) {
      if (hasMiddleDrop) {
        firstPickOrder = pickOrders[0];
      } else {
        firstPickOrder = pickOrders[0];
        middlePickOrder = pickOrders[1];
      }
    }

    return (
      <>
        <Col className="col-2.5">
          <strong>Pickup Info</strong>
          <BasicInfo order={firstPickOrder} />
          <RoutedDirectItemList order={firstPickOrder} />
          {(firstPickOrder.status === 'driver_assigned' || firstPickOrder.status === 'driver_claimed' || firstPickOrder.status === 'order_completed') && display && (
            <p>
              <strong>Status:</strong> {firstPickOrder.status === 'order_completed' ? 'done✅' : 'approaching'}
            </p>
          )}
          {(firstPickOrder.status === 'driver_assigned' || firstPickOrder.status === 'driver_claimed') && firstPickOrder.estimated_arrival_time && (
            <p>ETA: {formatETA(firstPickOrder.estimated_arrival_time)}</p>
          )}
        </Col>

        <Col className="col-2.5">
          <strong>Pickup/Dropoff Info</strong>
          <BasicInfo order={middlePickOrder ? middlePickOrder : middleDropOrder} />
          {orders.length === 3 ? (
            !hasMiddleDrop ? (
              <>
                <RoutedDirectItemList order={middlePickOrder} />
                <PickOrDropInstructionsTwist order={middlePickOrder} />
                {(middlePickOrder.status === 'driver_assigned' || middlePickOrder.status === 'driver_claimed' || middlePickOrder.status === 'order_completed') && display && (
                  <WaypointStatus pickOrder={firstPickOrder} currentOrder={middlePickOrder} />
                )}
              </>
            ) : (
              <>
                <RoutedDirectItemListTwist order={middleDropOrder} />
                <PickOrDropInstructionsTwist order={middleDropOrder} />
                {(middleDropOrder.status === 'driver_assigned' || middleDropOrder.status === 'driver_claimed' || middleDropOrder.status === 'order_completed') && display && (
                  <WaypointStatus pickOrder={firstPickOrder} currentOrder={middleDropOrder} />
                )}
              </>
            )
          ) : (
            <div>
              <RoutedDirectItemList order={middlePickOrder} />
              <PickOrDropInstructions order={middlePickOrder} />
              {(middlePickOrder.status === 'driver_assigned' || middlePickOrder.status === 'driver_claimed' || middlePickOrder.status === 'order_completed') && display && (
                <WaypointStatus pickOrder={firstPickOrder} currentOrder={middlePickOrder} />
              )}
              <RoutedDirectItemListTwist order={middleDropOrder} />
              <PickOrDropInstructionsTwist order={middleDropOrder} />

              {(middleDropOrder.status === 'driver_assigned' || middleDropOrder.status === 'driver_claimed' || middleDropOrder.status === 'order_completed') && display && (
                <WaypointStatus pickOrder={firstPickOrder} currentOrder={middleDropOrder} />
              )}
            </div>
          )}
        </Col>
        <Col className="col-2.5">
          <strong>Dropoff Info</strong>
          <BasicInfo order={finalDropOrder} />
          <RoutedDirectItemList order={finalDropOrder} />
          <PickOrDropInstructions order={finalDropOrder} />
          {(finalDropOrder.status === 'driver_assigned' || finalDropOrder.status === 'driver_claimed' || finalDropOrder.status === 'order_completed') && display && (
            <WaypointStatus pickOrder={firstPickOrder} currentOrder={finalDropOrder} />
          )}
        </Col>
      </>
    );
  };

  const LoadDetails = ({ shipment }) => {
    let blob = shipment.shipping_fee_blob.calculated;
    return (
      <>
        {shipment.merchant_order_id ? (
          <p>
            Store Order ID: <strong>{'  ' + shipment.merchant_order_id} </strong>{' '}
          </p>
        ) : null}
        <p>
          Vehicle Type Suggested:{' '}
          <strong>{shipment.vehicle_type === 'car' ? ' Sedan' : shipment.vehicle_type === 'suv' ? ' SUV' : capitalize(shipment.vehicle_type.split('_').join(' '))}</strong>
        </p>
        <p>
          Estimated Weight: <strong>{'  ' + shipment.weight} lbs.</strong>
        </p>
        <p>
          Gophrs Needed: <strong>{'  ' + shipment.num_of_gophrs}</strong>
        </p>
        {shipment.placed_by ? (
          <p>
            Placed By: <strong>{'  ' + shipment.placed_by} </strong>{' '}
          </p>
        ) : null}
        {shipment.scheduled_for && (
          <p>
            Scheduled For: <strong>{formatTimestamp(shipment.scheduled_for)}</strong>{' '}
          </p>
        )}
        {/* <p>
          Delivery Fee: <strong>{'  $' + shipment.shipping_fee.toFixed(2)}</strong>
        </p> */}
        {shipment.is_roundtrip && (
          <p>
            {' '}
            <strong>round trip</strong>{' '}
          </p>
        )}

        <>
          <p>{shipment.shipping_fee_blob.shipment.is_personal_protective_equipment && 'PPE required'}</p>
          <p>{blob.hasOwnProperty('hand_load_fee') && blob.hand_load_fee >= 0 && 'Hand Load: $' + blob.hand_load_fee.toFixed(2)}</p>
          <p>{blob.hasOwnProperty('dolly_fee') && 'Dolly: $' + blob.dolly_fee.toFixed(2)}</p>
          <p>{blob.hasOwnProperty('pallet_jack_fee') && 'Pallet Jack: $' + blob.pallet_jack_fee.toFixed(2)}</p>
          <p>{blob.hasOwnProperty('twic_card_fee') && 'TWIC Card: $' + blob.twic_card_fee.toFixed(2)}</p>
          <p>{blob.hasOwnProperty('liftgate_fee') && 'Liftgate: $' + blob.liftgate_fee.toFixed(2)}</p>
          <p>{blob.hasOwnProperty('hazmat_fee') && 'Hazmat: $' + blob.hazmat_fee.toFixed(2)}</p>
          <p>{blob.hasOwnProperty('weather_protection_fee') && 'Weather Protection: $' + blob.weather_protection_fee.toFixed(2)}</p>
          <p>{blob.hasOwnProperty('medical_fee') && 'Medical: $' + blob.medical_fee.toFixed(2)}</p>
        </>

        <p>
          Total Delivery Fee:{' '}
          {shipment.shipping_fee != 0 ? (
            <strong>{'  $' + shipment.shipping_fee.toFixed(2)}</strong>
          ) : (
            <strong>
              {'  $' + shipment.shipping_fee_blob.calculated.shipping_fee_range.min.toFixed(2) + '--' + shipment.shipping_fee_blob.calculated.shipping_fee_range.max.toFixed(2)}{' '}
            </strong>
          )}
        </p>
      </>
    );
  };

  const DriverInfo = ({ shipment }) => {
    return (
      <>
        <strong>Driver</strong>
        {shipment.Route.RouteDrivers.length > 0 ? (
          <div>
            <p>{shipment.Route.RouteDrivers[0].driver_blob.first_name + ' ' + shipment.Route.RouteDrivers[0].driver_blob.last_name}</p>
            <p>{formatPhoneNumber(shipment.Route.RouteDrivers[0].driver_blob.phone)}</p>
            <p>
              {shipment.Route.RouteDrivers[0].vehicle_blob.year +
                ' ' +
                shipment.Route.RouteDrivers[0].vehicle_blob.make +
                ' ' +
                shipment.Route.RouteDrivers[0].vehicle_blob.model +
                ' (' +
                shipment.Route.RouteDrivers[0].vehicle_blob.color +
                ')'}
            </p>
            {shipmentClick.driver && <p>{shipmentClick.driver.User.permit ? 'Permit: ' + shipmentClick.driver.User.permit : null}</p>}
          </div>
        ) : (
          <p>Awaiting for driver</p>
        )}
      </>
    );
  };

  const SchoolLunchInfo = ({ order, shipment }) => {
    return (
      <>
        <div style={{ paddingTop: '10px', paddingBottom: '10px', marginLeft: '10px', textAlign: 'left' }}>
          <Row>
            <Col className="col-3">
              <strong>Dining Period</strong>
              <p>{order.actions.pick_up[0].slice(15).slice(0, -3)}</p>
            </Col>

            <Col className="col-3">
              <strong>Quantity - Meal Type</strong>
              {order.actions.pick_up.map((meal, index) => {
                if (index > 0) {
                  return <p>{meal}</p>;
                }
              })}
            </Col>
            <Col className="col-3">
              <strong>Amount</strong>
              <p>${order.subtotal.toFixed(2)}</p>
            </Col>

            {order.status === 'order_created' && (
              <Col className="col-3">
                <div>
                  <ButtonGroup>
                    <Button
                      style={{ width: `100%`, textAlign: 'center' }}
                      color="info"
                      name="ready"
                      className={'btn btn-simple'}
                      onClick={e => {
                        e.stopPropagation();
                        readyOrder(order);
                      }}
                    >
                      Ready
                    </Button>
                  </ButtonGroup>
                </div>
              </Col>
            )}
            {(shipment.status === 'shipment_claimed' || shipment.status === 'shipment_assigned') && (
              <Col className="col-3">
                <strong>Driver</strong>
                {shipment.Route.RouteDrivers.length > 0 ? (
                  <div>
                    <p>{shipment.Route.RouteDrivers[0].driver_blob.first_name + ' ' + shipment.Route.RouteDrivers[0].driver_blob.last_name}</p>
                    <p>{formatPhoneNumber(shipment.Route.RouteDrivers[0].driver_blob.phone)}</p>
                  </div>
                ) : (
                  <p>Awaiting for driver</p>
                )}
              </Col>
            )}
            {shipment.status === 'shipment_completed' && (
              <Col className="col-3">
                <strong>Status</strong>
                <p>{shipment.status === 'shipment_completed' ? 'Complete' : 'None Found'}</p>
                {shipment.ShipmentHandshakes.length > 0 && (
                  <>
                    <img
                      src={shipment.ShipmentHandshakes[0].url}
                      alt="Signature"
                      height={100}
                      onClick={() => {
                        setShowSignature(true);
                        setSignatureUrl(shipment.ShipmentHandshakes[0].url);
                      }}
                    />
                    {/* <p>Click to see full image</p> */}
                  </>
                )}
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  };

  const WaypointStatus = ({ pickOrder, currentOrder }) => {
    return (
      <>
        <p>
          <strong>Status:</strong>
          {pickOrder.status === 'order_completed' && currentOrder.status === 'order_completed'
            ? ' done✅'
            : pickOrder.status === 'order_completed' && currentOrder.status !== 'order_completed'
            ? ' delivering'
            : ' waiting'}
        </p>
        {(currentOrder.status === 'driver_assigned' || currentOrder.status === 'driver_claimed') && currentOrder.estimated_arrival_time && (
          <p>ETA: {formatETA(currentOrder.estimated_arrival_time)}</p>
        )}
      </>
    );
  };

  const QuoteOrderButtons = ({ shipment }) => {
    return (
      <>
        <Row>
          <ButtonGroup>
            {shipment.shipping_fee === 0 ? (
              <Button style={{ width: `100%`, textAlign: 'center' }} color="info" name="waitingApproval" className={'btn btn-simple'} disabled>
                Waiting Approval
              </Button>
            ) : (
              <Button style={{ width: `100%`, textAlign: 'center' }} color="success" name="quoteApproved" className={'btn btn-simple'} disabled>
                Approved
              </Button>
            )}
          </ButtonGroup>
        </Row>
        <Row>
          <ButtonGroup>
            {shipment.shipping_fee > 0 && (
              <Button
                style={{ width: `100%`, textAlign: 'center' }}
                color="info"
                name="ready"
                className={'btn btn-simple'}
                onClick={() => {
                  shipment.scheduled_for ? schedulePendingShipment(shipment) : readyShipment(shipment);
                }}
                disabled={shipment.shipping_fee === 0}
              >
                {shipment.scheduled_for ? 'Schedule' : 'Ready'}
              </Button>
            )}

            <Button
              style={{ width: `100%`, textAlign: `center` }}
              // color="info"
              color="danger"
              name="decline"
              className={'btn btn-simple'}
              onClick={() => {
                setShipment(shipment);
                setShowDeclineQuoteShipmentAlert(true);
              }}
            >
              Decline
            </Button>
          </ButtonGroup>
        </Row>
      </>
    );
  };

  const checkOrder = (permit, shipment, item) => {
    // console.log('shipment: ', shipment);
    // console.log('permit: ', permit);
    // console.log('item: ', item);
    let numFoodItems = 0;
    let subtotal = shipment.Orders[0].subtotal;
    let foodTotal = 0;
    let beerTotal = 0;
    let wineTotal = 0;
    let spiritsTotal = 0;
    let otherTotal = 0;

    shipment.Orders[0].Items.map(item => {
      if (item.product_blob.is_food && item.status !== 'item_declined') {
        numFoodItems++;
        foodTotal = foodTotal + item.product_price;
      } else if (!item.contains_alcohol && item.status !== 'item_declined') {
        otherTotal = otherTotal + item.product_price;
      } else if (item.product_blob.alcohol_type === 'beer' && item.status !== 'item_declined') {
        beerTotal = beerTotal + item.product_price;
      } else if ((item.product_blob.alcohol_type === 'wine' || item.product_blob.alcohol_type === 'sparking_wine') && item.status !== 'item_declined') {
        wineTotal = wineTotal + item.product_price;
      } else if (item.product_price.alcohol_type === 'spirit' && item.status !== 'item_declined') {
        spiritsTotal = spiritsTotal + item.product_price;
      }
    });

    // console.log('numFoodItems: ', numFoodItems);
    // console.log('foodTotal: ', foodTotal);

    if (permit === 'louisiana_calcasieu_class_b') {
      if (item.product_blob.is_food) {
        if (numFoodItems <= 1) {
          return classBAlert();
        } else {
          return defaultAlert();
        }
      } else {
        return defaultAlert();
      }
    } else if (permit === 'louisiana_calcasieu_class_a' || permit === 'louisiana_calcasieu_class_ar') {
      if (item.product_blob.is_food && foodTotal !== 0) {
        if ((foodTotal - item.product_price) / (subtotal - wineTotal - item.product_price) < 0.3) {
          return classAAlert();
        } else {
          return defaultAlert();
        }
      } else {
        return defaultAlert();
      }
    } else {
      return null;
    }
    // axios({
    //   method: 'post',
    //   url: inventory_urls.hasuraHost,
    //   headers: {
    //       'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
    //       'content-type': 'application/json'
    //   },
    //   data: shipment.Orders
    // })
  };

  const defaultAlert = () => {
    // console.log('shipment: ', shipment);
    return (
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-200px' }}
        title="Item Unavailable?"
        onConfirm={() => {
          if (shipment.shipment_type === 'marketplace') {
            declineItem(item);
          } else {
            declineItemBridge(item);
          }

          setItem(null);
          setShowDecline(false);
        }}
        onCancel={() => {
          setItem(null);
          setShowDecline(false);
        }}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        {shipment.shipment_type === 'marketplace' ? (
          <div>
            This item will be marked as <strong>"Out of Stock"</strong> on the Gophr app until you manually re-enable it in your Inventory Manager.
          </div>
        ) : (
          <div>
            Are you sure this item is <strong>"Out of Stock"</strong> ? Please check again before confirming.
          </div>
        )}
      </ReactBSAlert>
    );
  };

  const classBAlert = () => {
    return (
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-200px' }}
        title="Item Unavailable?"
        onConfirm={() => {
          declineItem(item);
          setItem(null);
          setShowDecline(false);
        }}
        onCancel={() => {
          setItem(null);
          setShowDecline(false);
        }}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Delete Shipment"
        cancelBtnText="Nevermind"
        showCancel
        btnSize=""
      >
        <div>
          Because of alcohol delivery regulations, declining this item will void the entire order. Are you sure you don't want to contact the customer and offer a substitution
          instead?
        </div>
      </ReactBSAlert>
    );
  };

  const classAAlert = () => {
    return (
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-200px' }}
        title="Item Unavailable?"
        onConfirm={() => {
          declineItem(item);
          setItem(null);
          setShowDecline(false);
        }}
        onCancel={() => {
          setItem(null);
          setShowDecline(false);
        }}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Delete Shipment"
        cancelBtnText="Nevermind"
        showCancel
        btnSize=""
      >
        <div>
          Because of alcohol delivery regulations, declining this item will void the entire order. Are you sure you don't want to contact the customer and offer a substitution
          instead?
        </div>
      </ReactBSAlert>
    );
  };

  // Disables an item in inventory and changes item status.
  const declineItem = item => {
    axios({
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.declineItem + item.Order.Shipment.shipment_id + '/orders/' + item.Order.order_id + '/items/' + item.item_id + '/decline'
    })
      .then(response => {
        // setSelected('pending');
        // setSelected("inProgress");
      })
      .catch(error => {
        console.log('error: ', error.response);
      });
  };

  const declineItemBridge = item => {
    axios({
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.declineItem + item.Order.Shipment.shipment_id + '/orders/' + item.Order.order_id + '/items/' + item.item_id + '/bridge/decline'
    })
      .then(response => {
        // setSelected('pending');
        // setSelected("inProgress");
      })
      .catch(error => {
        console.log('error: ', error.response);
      });
  };

  const readyItem = item => {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.declineItem + item.Order.Shipment.shipment_id + '/orders/' + item.Order.order_id + '/items/' + item.item_id + '/ready'
    })
      .then(response => {
        // setFilter(["shipment_created"]);
        // setSelected('pending');
        // console.log('reached here');
      })
      .catch(error => {
        console.log('error: ', error.response);
      });
  };

  const readyOrder = order => {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.ReadyOrder + order.shipment_id + '/orders/' + order.order_id + '/ready'
    })
      .then(response => {
        // setFilter(["shipment_created"]);
        // setSelected('pending');
        // console.log('reached here');
      })
      .catch(error => {
        console.log('readyOrder error: ', error.response);
      });
  };

  const getHandshake = shipId => {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        query:
          `{ShipmentHandshakes(where: shipment_id: {eq: "` +
          shipId +
          `"}){
          url
        }}`
      }
    })
      .then(response => {
        if (response.data.data) {
          setHandshakeUrl(response.data.data);
        } else {
          console.log('handshake error: ', response);
        }
      })
      .catch(error => {
        console.log('handshake try-catch error: ', error);
      });
  };

  const makeAlmostReady = order_friendly_id => {
    if (almostReady.includes(order_friendly_id)) {
      // return <FaExclamationTriangle size="30" color="#fcb23a" />
      return 'Scheduled for Tomorrow';
    } else {
      // return <FaExclamationTriangle size="30" color="#fcb23a" />
      return 'Scheduled for Tomorrow';
    }
  };

  // Technically, this function should never get hit anymore
  const makeReady = order_friendly_id => {
    if (ready.includes(order_friendly_id)) {
      return 'Overdue';
    } else {
      return 'Overdue';
    }
  };

  const capitalize = s => {
    if (typeof s !== 'string') return s;
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const fetchEnrouteAndDriver = async shipment => {
    if (shipment.status === 'shipment_claimed' || shipment.status === 'shipment_assigned') {
      let driverId = shipment.Route.RouteDrivers[0].driver_id;
      let driver = await axios({
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'content-type': 'application/json'
        },
        url: user_urls.hasuraHost,
        data: {
          query: ` query LastDriverActions ($driverID: uuid!) {LastDriverActions (where: {driver_id: {_eq: $driverID}}) {
                  driver_id
                  location_blob
                    Driver {
                    profile_photo_url
                    restricted_area_credentials
                    can_deliver_alcohol
                  }
                  }
                }`,
          variables: { driverID: driverId }
        }
      });
      let user = {};
      let info = shipment.Route.RouteDrivers[0];
      user.first_name = info.driver_blob.first_name;
      user.last_name = info.driver_blob.last_name;
      user.phone = info.driver_blob.phone;
      let vehicle = info.vehicle_blob;
      user.vehicle_info = vehicle.year + ' ' + vehicle.make + ' ' + vehicle.model + ' (' + vehicle.color + ')';
      let driver_info = driver.data.data.LastDriverActions[0];
      user.profile_photo_url = driver_info.Driver.profile_photo_url;
      let permits = [];
      if (driver_info.Driver.can_deliver_alcohol) {
        permits.push('Alcohol');
      }
      driver_info.Driver.restricted_area_credentials.map(credential => {
        permits.push(credential);
      });
      user.permit = permits.map(permit => permit).join(', ');
      setShipmentClick({ ...shipment, driver: { LastDriverActions: driver.data.data.LastDriverActions[0], User: user } });
    } else {
      setShipmentClick(shipment);
    }
  };

  const handleRowClick = async (event, rowInfo, cell, element) => {
    if (selected === 'pending') {
      if (expandedItems.pending[event.viewIndex]) {
        expandedItems.pending[event.viewIndex] = !expandedItems.pending[event.viewIndex];
      } else {
        expandedItems.pending[event.viewIndex] = true;
      }
    } else if (selected === 'inProgress') {
      if (expandedItems.inProgress[event.viewIndex]) {
        expandedItems.inProgress[event.viewIndex] = !expandedItems.inProgress[event.viewIndex];
        setShipmentClick('');
      } else {
        expandedItems.inProgress[event.viewIndex] = true;
        // setShipmentClick(event.original);
        await updateTQLTracking(event.original);
        fetchEnrouteAndDriver(event.original);
      }
    } else if (selected === 'completed') {
      if (expandedItems.completed[event.viewIndex]) {
        expandedItems.completed[event.viewIndex] = !expandedItems.completed[event.viewIndex];
      } else {
        expandedItems.completed[event.viewIndex] = true;
      }
    } else if (selected === 'saved') {
      if (element.target.toString().split(' ')[1] !== 'SVGPathElement]' && element.target.toString().split(' ')[1] !== 'SVGSVGElement]') {
        if (element.target.className.split(' ')[0] === 'btn' || element.target.className.split(' ')[0] === 'btn-simple') {
          return;
        }
      }
      if (expandedItems.saved[event.viewIndex]) {
        expandedItems.saved[event.viewIndex] = !expandedItems.saved[event.viewIndex];
      } else {
        expandedItems.saved[event.viewIndex] = true;
      }
    }
    props.expand(expandedItems);
  };

  const updateTQLTracking = async shipment => {
    let shipId = shipment.shipment_id;
    let po_number;
    if (shipment.Route.RouteDrivers[0].tracking_blob && shipment.Route.RouteDrivers[0].tracking_blob.hasOwnProperty('po_number')) {
      po_number = shipment.Route.RouteDrivers[0].tracking_blob.po_number;
    } else {
      return;
    }
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/shipments/direct/' + shipId + '/tql_ftl_tracking',
      data: { po_number }
    })
      .then(response => {
        console.log('response: ', response);
        return response;
      })
      .catch(error => {
        if (error.response) {
          console.log('error-response: ', error.response);
        } else {
          console.log('error: ', error);
        }
      });
  };

  const cancelShipment = shipment => {
    for (let i = 0; i < almostReady.length; i++) {
      if (almostReady[i] === order.order_friendly_id) {
        almostReady.splice(i, 1);
      }
    }
    for (let i = 0; i < ready.length; i++) {
      if (ready[i] === order.order_friendly_id) {
        ready.splice(i, 1);
      }
    }
    axios({
      method: 'delete',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/shipments/' + shipment.shipment_id + '/decline'
    })
      .then(response => {
        // setSelected('pending');
        setSelected('inProgress');
        resetExpand();
        props.notify('tc', 'Delivery Successfully Canceled');
      })
      .catch(error => {
        console.log('error: ', error.response);
      });
  };

  const readyShipment = shipment => {
    // Mark order as ready for driver
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/shipments/' + shipment.shipment_id + '/ready'
    })
      .then(response => {
        resetExpand();
        // setSelected('pending');
        setSelected('inProgress');
        props.notify('tc', 'Order Successfully Marked As Ready');
      })
      .catch(error => {
        console.log('error: ', error.response);
      });
  };

  const schedulePendingShipment = shipment => {
    // Mark order as ready for driver
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/shipments/' + shipment.shipment_id + '/pending_schedule'
    })
      .then(response => {
        resetExpand();
        // setSelected('pending');
        setSelected('inProgress');
        props.notify('tc', 'Order Successfully Marked As Scheduled');
      })
      .catch(error => {
        console.log('error: ', error.response);
      });
  };

  const isPending = element => element.status === 'item_created';

  const getTrProps = (rowInfo, column, cell) => {
    if (rowInfo) {
      if (column) {
        if (!loading) {
          if ((column.original.status === 'shipment_pending' || column.original.status === 'shipment_pending_schedule') && column.original.shipping_fee > 0) {
            return {
              onClick: e => {
                handleRowClick(column, rowInfo, cell, e);
              },
              style: {
                cursor: 'pointer',
                height: `70px`,
                margin: `5px`,
                borderRadius: `5px`,
                background: `#27293d`,
                padding: '0px',
                border: '2px solid green',
                textAlign: 'left'
              }
            };
          }
          if (column.original.is_expedited) {
            return {
              onClick: e => {
                handleRowClick(column, rowInfo, cell, e);
              },
              style: {
                cursor: 'pointer',
                height: `70px`,
                margin: `5px`,
                borderRadius: `5px`,
                background: `#27293d`,
                padding: '0px',
                border: '1px solid #f79400',
                textAlign: 'left'
              }
            };
          } else {
            return {
              onClick: e => {
                handleRowClick(column, rowInfo, cell, e);
              },
              style: {
                cursor: 'pointer',
                height: `70px`,
                margin: `5px`,
                borderRadius: `5px`,
                background: `#27293d`,
                padding: '0px'
              }
            };
          }
        } else {
          return {};
        }
      }
      return {};
    }
  };

  const formatPhoneNumber = phone => {
    //Filter only numbers from the input
    let cleaned = ('' + phone).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }

    return null;
  };

  const pendingColumns = [
    {
      expander: true,
      Expander: ({ isExpanded, ...rest }) => (
        <div style={{ textAlign: 'left' }}>
          {!isExpanded ? (
            <span>
              <FaCaretRight color={'#BFC0C5'} size="20" />
            </span>
          ) : (
            <span>
              <FaCaretDown color={'#BFC0C5'} size="20" />
            </span>
          )}
        </div>
      )
    },
    {
      Header: 'ship_id',
      accessor: 'shipment_friendly_id',
      width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          {row.value}
        </div>
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 'auto',
      // width: 250,
      textAlign: 'left',
      Cell: row => (
        <div style={{ textAlign: 'left' }}>
          {row.original.shipment_type === 'school_lunch' ? (
            'Pending Approval'
          ) : (row.original.shipment_type === 'direct' || row.original.shipment_type === 'routed_direct' || row.original.shipment_type === 'multidrop_direct') &&
            row.original.shipping_fee > 0 ? (
            'Quote Approved'
          ) : (row.original.shipment_type === 'direct' || row.original.shipment_type === 'routed_direct' || row.original.shipment_type === 'multidrop_direct') &&
            row.original.shipping_fee === 0 ? (
            'Quote Pending'
          ) : (
            <span style={{ textAlign: 'left' }}>{row.original.Orders[0].Items.some(isPending) ? 'Pending Approval' : 'Waiting on Other Merchant(s)'}</span>
          )}
        </div>
      )
    },
    {
      Header: 'Priority',
      accessor: 'is_expedited',
      // width: 250,
      width: 'auto',
      Cell: props => (
        <div
          style={{
            width: 1,
            // color: '#f7941d',
            color: 'red',
            fontWeight: `bold`,
            fontSize: '1.2em'
          }}
        >
          {props.value === true ? '🚀 Expedited 🚀' : ''}
        </div>
      )
    },
    {
      Header: 'Type',
      accessor: 'shipment_type',
      width: 'auto',
      textAlign: 'left',
      Cell: props => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          {props.value === 'direct' || props.value === 'routed_direct' || props.value === 'multidrop_direct'
            ? 'Direct'
            : props.value === 'bridge'
            ? 'Bridge'
            : props.value === 'marketplace'
            ? 'Marketplace'
            : 'SchoolLunch'}
        </div>
      )
    },
    {
      Header: 'Recipient',
      // accessor: 'customer_blob',
      // width: 150,
      width: 'auto',
      textAlign: 'left',
      Cell: props => (
        <div
          style={{
            // width: 1,
            fontWeight: 'bold',
            textAlign: 'left'
          }}
        >
          {/* {props.value.first_name + ' ' + props.value.last_name} */}
          {props.original.shipment_type === 'direct' || props.original.shipment_type === 'routed_direct' || props.original.shipment_type === 'multidrop_direct'
            ? props.original.Orders[props.original.Orders.length - 1].location_blob.name
            : props.original.customer_blob.first_name + ' ' + props.original.customer_blob.last_name}
        </div>
      )
    },
    {
      Header: 'Recipient Ph.',
      // accessor: 'customer_blob',
      width: 'auto',
      textAlign: 'left',
      Cell: props => (
        <div
          style={{
            // width: 'auto',
            textAlign: 'left'
          }}
        >
          {/* {formatPhoneNumber(props.value.phone)} */}
          {props.original.shipment_type === 'marketplace' || props.original.shipment_type === 'bridge' || shipment.shipment_type === 'school_lunch'
            ? formatPhoneNumber(props.original.customer_blob.phone)
            : formatPhoneNumber(props.original.Orders[props.original.Orders.length - 1].location_blob.phone)}
        </div>
      )
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      width: 'auto',
      textAlign: 'left',
      // Cell: props => <div style={{ textAlign: 'left' }}>{formatTimestamp(props.value)}</div>
      Cell: props => <div style={{ textAlign: 'left' }}>{formatTimestamp(tz(props.value).tz(store.timezone))}</div>
    }
  ];

  const inProgressColumns = [
    {
      Header: 'ship_id',
      accessor: 'shipment_friendly_id',
      width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          {row.value}
        </div>
      )
    },
    {
      Header: 'Status',
      // accessor: 'status',
      // width: 250,
      width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          <span style={{ textAlign: 'left' }}>
            {// row.value === "shipment_created" ? <FaHourglassHalf size="30" color="#be132d" /> :
            row.original.status === 'shipment_ready_for_driver'
              ? 'Awaiting Driver'
              : (row.original.status === 'shipment_assigned' || row.original.status === 'shipment_claimed') && row.original.Orders[0].status === 'order_completed'
              ? 'Delivering'
              : (row.original.status === 'shipment_assigned' || row.original.status === 'shipment_claimed') && row.original.Orders[0].status !== 'order_completed'
              ? 'Approaching'
              : 'None Found'}
          </span>
          {/* <span> {row.original.Route.RouteDrivers.length > 0 ? ` (P: ${formatPhoneNumber(row.original.Route.RouteDrivers[0].driver_blob.phone)})` : null}</span> */}
        </div>
      )
    },
    {
      Header: 'Priority',
      accessor: 'is_expedited',
      // width: 250,
      width: 'auto',
      Cell: props => (
        <div
          style={{
            width: 1,
            // color: '#f7941d',
            color: 'red',
            fontWeight: `bold`,
            fontSize: '1.2em'
          }}
        >
          {props.value === true ? '🚀 Expedited 🚀' : ''}
        </div>
      )
    },
    {
      Header: 'Type',
      accessor: 'shipment_type',
      width: 'auto',
      textAlign: 'left',
      Cell: props => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          {props.value === 'direct' || props.value === 'routed_direct' || props.value === 'multidrop_direct'
            ? 'Direct'
            : props.value === 'bridge'
            ? 'Bridge'
            : props.value === 'marketplace'
            ? 'Marketplace'
            : 'SchoolLunch'}
        </div>
      )
    },
    {
      Header: 'Recipient',
      // width: 150,
      width: 'auto',
      textAlign: 'left',
      Cell: props => (
        <div
          style={{
            fontWeight: 'bold',
            textAlign: 'left'
          }}
        >
          {/* {props.original.Orders[props.original.Orders.length - 1].location_blob.name} */}
          {props.original.shipment_type === 'direct' || props.original.shipment_type === 'routed_direct' || props.original.shipment_type === 'multidrop_direct'
            ? props.original.Orders[props.original.Orders.length - 1].location_blob.name
            : props.original.customer_blob.first_name + ' ' + props.original.customer_blob.last_name}
        </div>
      )
    },
    {
      Header: 'Recipient Ph.',
      // accessor: 'Orders[1].location_blob.phone',
      textAlign: 'left',
      width: 'auto',
      // Cell: props => <div style={{ textAlign: 'left' }}>{props.original.Orders[props.original.Orders.length - 1].location_blob.phone}</div>
      Cell: props => (
        <div style={{ textAlign: 'left' }}>
          {props.original.shipment_type === 'marketplace' || props.original.shipment_type === 'bridge' || shipment.shipment_type === 'school_lunch'
            ? formatPhoneNumber(props.original.customer_blob.phone)
            : formatPhoneNumber(props.original.Orders[props.original.Orders.length - 1].location_blob.phone)}
        </div>
      )
    },
    {
      Header: 'Updated At',
      accessor: 'updated_at',
      width: 'auto',
      // Cell: props => <div>{formatTimestamp(props.value)}</div>
      Cell: props => <div style={{ textAlign: 'left' }}>{formatTimestamp(tz(props.value).tz(store.timezone))}</div>
    },
    {
      Header: 'Delivery Fee',
      accessor: 'shipping_fee',
      width: 'auto',
      textAlign: 'left',
      // Cell: props => <div style={{ textAlign: 'left' }}>{'$' + props.value.toFixed(2)}</div>
      Cell: props => (
        <div style={{ textAlign: 'left' }}>
          {' '}
          {props.original.shipping_fee == 0 && props.original.shipment_type != 'school_lunch'
            ? '  $' +
              props.original.shipping_fee_blob.calculated.shipping_fee_range.min.toFixed(2) +
              '--' +
              props.original.shipping_fee_blob.calculated.shipping_fee_range.max.toFixed(2)
            : '  $' + props.original.shipping_fee.toFixed(2)}
        </div>
      )
    }
  ];

  const completedColumns = [
    // {
    //   expander: true,
    //   Expander: ({isExpanded, ...rest}) =>
    //   <div>
    //     { !isExpanded ? <span><FaCaretRight color={'#BFC0C5'} size="20" /></span> :
    //     <span><FaCaretDown color={'#BFC0C5'} size="20" /></span> }
    //   </div>
    // },
    {
      Header: 'ship_id',
      accessor: 'shipment_friendly_id',
      width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          {row.value}
        </div>
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 'auto',
      // width: 250,
      textAlign: 'left',
      Cell: row => (
        <div style={{ textAlign: 'left' }}>
          <span style={{ textAlign: 'left' }}>{row.original.status === 'shipment_completed' ? 'Order Completed' : ''}</span>
        </div>
      )
    },
    {
      Header: 'Type',
      accessor: 'shipment_type',
      width: 'auto',
      textAlign: 'left',
      Cell: props => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          {props.value === 'direct' || props.value === 'routed_direct' || props.value === 'multidrop_direct'
            ? 'Direct'
            : props.value === 'bridge'
            ? 'Bridge'
            : props.value === 'marketplace'
            ? 'Marketplace'
            : 'SchoolLunch'}
        </div>
      )
    },
    {
      Header: 'Recipient',
      // width: 150,
      width: 'auto',
      textAlign: 'left',
      Cell: props => (
        <div
          style={{
            fontWeight: 'bold',
            textAlign: 'left'
          }}
        >
          {/* {props.original.Orders[props.original.Orders.length - 1].location_blob.name} */}
          {props.original.shipment_type === 'direct' || props.original.shipment_type === 'routed_direct' || props.original.shipment_type === 'multidrop_direct'
            ? props.original.Orders[props.original.Orders.length - 1].location_blob.name
            : props.original.customer_blob.first_name + ' ' + props.original.customer_blob.last_name}
        </div>
      )
    },
    {
      Header: 'Recipient Ph.',
      // accessor: 'Orders[1].location_blob.phone',
      textAlign: 'left',
      width: 'auto',
      // Cell: props => <div style={{ textAlign: 'left' }}>{props.original.Orders[props.original.Orders.length - 1].location_blob.phone}</div>
      Cell: props => (
        <div style={{ textAlign: 'left' }}>
          {props.original.shipment_type === 'marketplace' || props.original.shipment_type === 'bridge' || shipment.shipment_type === 'school_lunch'
            ? formatPhoneNumber(props.original.customer_blob.phone)
            : formatPhoneNumber(props.original.Orders[props.original.Orders.length - 1].location_blob.phone)}
        </div>
      )
    },
    {
      Header: 'Completed At',
      accessor: 'updated_at',
      width: 'auto',
      textAlign: 'left',
      // Cell: props => (
      //   <div
      //     style={{
      //       textAlign: 'left'
      //     }}
      //   >
      //     {formatTimestamp(props.value)}
      //   </div>
      // )
      Cell: props => <div style={{ textAlign: 'left' }}>{formatTimestamp(tz(props.value).tz(store.timezone))}</div>
    },
    {
      Header: 'Delivery Fee',
      accessor: 'shipping_fee',
      width: 'auto',
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{'$' + props.value.toFixed(2)}</div>
    }
  ];

  const savedForLaterColumns = [
    // {
    //   expander: true,
    //   textAlign: 'center',
    //   Expander: ({isExpanded, ...rest}) =>
    //   <div style={{
    //     width: 20,
    //     textAlign: 'center'
    //   }}>
    //     { !isExpanded ?
    //     <span><FaCaretRight color={'#BFC0C5'} size="20" /></span>
    //      :
    //     <span><FaCaretDown color={'#BFC0C5'} size="20" /></span> }
    //   </div>
    // },
    // {
    //   Header: 'ID',
    //   accessor: 'order_friendly_id',
    //   width: 40,
    //   textAlign: 'left'
    // },
    {
      Header: 'ship_id',
      accessor: 'shipment_friendly_id',
      width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          {row.value}
        </div>
      )
    },
    {
      Header: 'Status',
      // accessor: 'status',
      // width: 'auto',
      width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          <span style={{ textAlign: 'left' }}>
            {row.original.status === 'shipment_scheduled'
              ? now.isAfter(row.original.scheduled_for)
                ? makeReady(row.original.order_friendly_id)
                : tomorrow.isAfter(row.original.scheduled_for)
                ? makeAlmostReady(row.original.order_friendly_id)
                : 'Scheduled'
              : row.original.status === 'shipment_saved'
              ? 'Saved'
              : 'Unknown Status'
            // : <FaClock size="30" color="#2bbbae" />
            // : row.value === 'shipment_saved' ? <FaBookmark size="30" color="be1e2d" />
            // : "None Found"
            }
          </span>
        </div>
      )
    },
    {
      Header: 'Type',
      accessor: 'shipment_type',
      width: 'auto',
      textAlign: 'left',
      Cell: props => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          {props.value === 'direct' || props.value === 'routed_direct' || props.value === 'multidrop_direct'
            ? 'Direct'
            : props.value === 'bridge'
            ? 'Bridge'
            : props.value === 'marketplace'
            ? 'Marketplace'
            : 'SchoolLunch'}
        </div>
      )
    },
    // {
    //   Header: 'Order Created At',
    //   accessor: 'created_at',
    //   width: 200,
    //   textAlign: 'left',
    //   Cell: props => (
    //     <div style={{
    //       textAlign: 'left'
    //     }}>
    //       {formatTimestamp(props.value)}
    //     </div>
    //   )
    // },
    {
      Header: 'Scheduled For',
      accessor: 'scheduled_for',
      width: 'auto',
      textAlign: 'left',
      Cell: props =>
        props.value ? (
          // <div style={{ textAlign: 'left' }}>
          //   <strong>{formatTimestamp(props.value)}</strong>
          // </div>
          <div style={{ textAlign: 'left' }}>
            <strong>{formatTimestamp(tz(props.value).tz(store.timezone))}</strong>
          </div>
        ) : (
          <div style={{ textAlign: 'left' }}>Not Scheduled</div>
        )
    },
    {
      Header: 'Recipient',
      // accessor: 'Orders[1].location_blob.name',
      width: 'auto',
      textAlign: 'left',
      Cell: props => (
        <div
          style={{
            fontWeight: 'bold',
            textAlign: 'left'
          }}
        >
          {/* {props.original.Orders[props.original.Orders.length - 1].location_blob.name} */}
          {props.original.shipment_type === 'direct' || props.original.shipment_type === 'routed_direct' || props.original.shipment_type === 'multidrop_direct'
            ? props.original.Orders[props.original.Orders.length - 1].location_blob.name
            : props.original.customer_blob.first_name + ' ' + props.original.customer_blob.last_name}
        </div>
      )
    },
    {
      Header: 'Recipient Ph.',
      accessor: 'Orders[1].location_blob.phone',
      textAlign: 'left',
      width: 'auto',
      // Cell: props => <div style={{ textAlign: 'left' }}>{props.original.Orders[props.original.Orders.length - 1].location_blob.phone}</div>
      Cell: props => (
        <div style={{ textAlign: 'left' }}>
          {props.original.shipment_type === 'marketplace' || props.original.shipment_type === 'bridge' || shipment.shipment_type === 'school_lunch'
            ? formatPhoneNumber(props.original.customer_blob.phone)
            : formatPhoneNumber(props.original.Orders[props.original.Orders.length - 1].location_blob.phone)}
        </div>
      )
    },
    {
      Header: 'Delivery Fee',
      accessor: 'shipping_fee',
      width: 'auto',
      textAlign: 'left',
      // Cell: props => <div style={{ textAlign: 'left' }}>{'$' + props.value.toFixed(2)}</div>
      Cell: props => (
        <div style={{ textAlign: 'left' }}>
          {' '}
          {props.original.shipping_fee == 0 && props.original.shipment_type != 'school_lunch'
            ? '  $' +
              props.original.shipping_fee_blob.calculated.shipping_fee_range.min.toFixed(2) +
              '--' +
              props.original.shipping_fee_blob.calculated.shipping_fee_range.max.toFixed(2)
            : '  $' + props.original.shipping_fee.toFixed(2)}
        </div>
      )
    }
    // {
    //   Header: 'Size',
    //   accessor: 'vehicle_type',
    //   width: '100',
    //   textAlign: 'left',
    //   Cell: row => (
    //     <div style={{textAlign: 'left'}}>
    //       <span>
    //         {
    //           row.value === 'car' ?  <Car/> :
    //           row.value === 'suv' ?  <SUV/> :
    //           row.value === 'truck' ? <Truck/> :
    //           row.value === 'trailer' ? <Trailer size={1.5} /> :
    //           row.value === 'box_truck' ? <BoxTruck/> : "None Found"
    //         }
    //       </span>
    //     </div>
    //   )
    // },
    // {
    //   Header: 'Description',
    //   accessor: 'Shipment.description',
    //   width: 'auto',
    //   textAlign: 'left',
    //   Cell: props => (
    //     <div style={{
    //       width: 'auto',
    //       textAlign: 'left',
    //       //paddingLeft: 10,
    //       overflow: `ellipsis`
    //     }}>
    //       {props.value}
    //     </div>
    //   )
    // },
  ];

  const columns = [
    {
      expander: true,
      Expander: ({ isExpanded, ...rest }) => (
        <div>
          {!isExpanded ? (
            <span>
              <FaCaretRight color={'#BFC0C5'} size="20" />
            </span>
          ) : (
            <span>
              <FaCaretDown color={'#BFC0C5'} size="20" />
            </span>
          )}
        </div>
      )
    },
    {
      Header: 'ship_id',
      accessor: 'shipment_friendly_id',
      width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          {row.value}
        </div>
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 65,
      Cell: row => (
        <div>
          <span>
            {row.value === 'shipment_created' ? (
              <FaHourglassHalf size="30" color="#be1e2d" />
            ) : row.value === 'shipment_ready_for_driver' ? (
              <FaHourglassHalf size="30" color="#be1e2d" />
            ) : row.value === 'shipment_assigned' ? (
              <FaPeopleCarry size="30" color="#ff6500" />
            ) : row.value === 'shipment_enroute' ? (
              <FaHome size="30" color="#fcb23a" />
            ) : row.value === 'shipment_completed' ? (
              <FaRegCheckCircle size="30" color="#2bbbae" />
            ) : row.value === 'shipment_scheduled' ? (
              <FaClock size="30" color="#2bbbae" />
            ) : row.value === 'shipment_saved' ? (
              <FaBookmark size="30" color="#be1e2d" />
            ) : (
              'None Found'
            )}
          </span>
        </div>
      )
    },
    {
      Header: 'Type',
      accessor: 'Shipment.shipment_type',
      width: 100
    },
    {
      Header: 'Customer',
      accessor: 'Shipment',
      width: 150,
      Cell: props => (
        <div
          style={{
            width: 1,
            fontWeight: 'bold'
          }}
        >
          {props.value.customer_blob.first_name + ' ' + props.value.customer_blob.last_name}
        </div>
      )
    },
    {
      Header: 'Size',
      accessor: 'Shipment.vehicle_type',
      width: 100,
      Cell: row => (
        <div>
          <span>
            {row.value === 'car' ? (
              <Car />
            ) : row.value === 'suv' ? (
              <SUV />
            ) : row.value === 'truck' ? (
              <Truck />
            ) : row.value === 'trailer' ? (
              <Trailer size={1.5} />
            ) : row.value === 'box_truck' ? (
              <BoxTruck />
            ) : (
              row.value
            )}
          </span>
        </div>
      )
    },
    {
      Header: 'Gophrs',
      accessor: 'Shipment.num_of_gophrs',
      width: 60,
      Cell: props => (
        <div
          style={{
            width: `${props.value}%`
          }}
        >
          {}
          {props.value.toString()}
        </div>
      )
    },
    {
      Header: 'Priority',
      accessor: 'Shipment.is_expedited',
      width: 120,
      Cell: props => (
        <div
          style={{
            width: 1,
            color: '#f7941d',
            fontWeight: `bold`,
            fontSize: '1.2em'
          }}
        >
          {props.value === true ? 'Expedited' : ''}
        </div>
      )
    },
    {
      Header: 'Description',
      accessor: 'Shipment.description',
      width: 300,
      Cell: props => (
        <div
          style={{
            width: `${props.value}%`,
            overflow: `ellipsis`
          }}
        >
          {props.value}
        </div>
      )
    },
    {
      Header: 'Time Stamp',
      accessor: 'created_at',
      width: 190,
      Cell: props => (
        <div
          style={{
            textAlign: 'center'
          }}
        >
          {formatTimestamp(props.value)}
        </div>
      )
    }
  ];

  const getTheadThProps = () => {
    return {
      style: {
        fontSize: `12px`,
        fontWeight: `600`,
        textAlign: `left`,
        color: `#cdcaca`
      }
    };
  };

  if (loading || storeLoading) return <Loading />;
  return (
    <div className="content">
      {marketNote && (
        <div>
          <p
            style={{
              fontFamily: 'Lato, sans-serif',
              fontSize: '20px',
              fontStyle: 'italic',
              fontWeight: 'bold',
              color: 'red',
              marginBottom: '10px',
              marginLeft: '200px',
              marginRight: '200px',
              lineHeight: '2.5',
              backgroundColor: 'white',
              border: '2px solid #333',
              borderRadius: '20px',
              padding: '10px'
            }}
          >
            {marketNote}
          </p>
        </div>
      )}
      <div className="float-container">
        <ResizableBox className="box" width={5000} height={4200} minConstraints={[1000, 4000]} maxConstraints={[10000, 5000]} draggableOpts={{ grid: [10, 10] }} axis="x">
          <div className="float-child-table">
            {' '}
            <ButtonGroup className="center" style={{ width: '100%' }}>
              <Button
                style={{ width: '25%' }}
                color="info"
                className={selected === 'pending' ? 'btn' : 'btn-simple'}
                name="pending"
                onClick={e => {
                  setFilter(['shipment_created', 'shipment_pending', 'shipment_pending_schedule']);
                  setSelected(e.target.name);
                }}
              >
                Pending
                {amountApproval > 0 && (
                  <div className="approve-amount-container">
                    <p className="approval-amount">{amountApproval}</p>
                  </div>
                )}
                {amountPending > 0 ? (
                  <div className="amount-container">
                    <p className="total-amount">{amountPending}</p>
                  </div>
                ) : null}
              </Button>
              <Button
                style={{ width: '25%' }}
                // style={{width: '33%'}}
                className={selected === 'inProgress' ? 'btn' : 'btn-simple'}
                name="inProgress"
                color="info"
                onClick={e => {
                  setFilter(['shipment_ready_for_driver', 'shipment_assigned', 'shipment_claimed']);
                  setSelected(e.target.name);
                }}
              >
                In Progress
              </Button>
              <Button
                style={{ width: '25%' }}
                // style={{width: '34%'}}
                className={selected === 'completed' ? 'btn' : 'btn-simple'}
                name="completed"
                color="info"
                onClick={e => {
                  setFilter(['shipment_completed', 'shipment_enroute']);
                  setSelected(e.target.name);
                }}
              >
                Completed
              </Button>
              <Button
                style={{ width: '25%' }}
                // style={{width: '33%'}}
                className={selected === 'saved' ? 'btn' : 'btn-simple'}
                name="saved"
                color="info"
                onClick={e => {
                  setFilter(['shipment_scheduled', 'shipment_saved']);
                  setSelected(e.target.name);
                }}
              >
                Saved for Later
                <Badge
                  hidden={almostReady.length === 0}
                  name="saved"
                  style={{
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    backgroundColor: '#F7941D',
                    color: '#ffffff'
                  }}
                >
                  {almostReady.length}
                </Badge>
                <Badge
                  hidden={ready.length === 0}
                  name="saved"
                  style={{
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    backgroundColor: '#be1e2d',
                    color: '#ffffff'
                  }}
                >
                  {ready.length}
                </Badge>
              </Button>
            </ButtonGroup>
            <ReactTable
              data={shipmentsToDisplay}
              columns={
                filter.includes('shipment_scheduled') || filter.includes('shipment_saved')
                  ? savedForLaterColumns
                  : filter.includes('shipment_completed') || filter.includes('shipment_enroute')
                  ? completedColumns
                  : filter.includes('shipment_ready_for_driver') || filter.includes('shipment_assigned') || filter.includes('shipment_claimed')
                  ? inProgressColumns
                  : filter.includes('shipment_created') || filter.includes('shipment_pending') || filter.includes('shipment_pending_schedule')
                  ? pendingColumns
                  : columns
              }
              sortable={true}
              resizable={true}
              defaultPageSize={10}
              expanded={
                selected === 'pending'
                  ? expandedItems.pending
                  : selected === 'inProgress'
                  ? expandedItems.inProgress
                  : selected === 'completed'
                  ? expandedItems.completed
                  : expandedItems.saved
              }
              getTrProps={getTrProps}
              getTheadThProps={getTheadThProps}
              collapseOnSortingChange={true}
              collapseOnPageChange={true}
              collapseOnDataChange={false}
              onSortedChange={e => resetExpand()}
              onPageChange={e => resetExpand()}
              onFetchData={e => {
                resetExpand();
              }}
              SubComponent={row => {
                return (
                  <div
                    style={{
                      backgroundColor: '#17192D',
                      borderRadius: '6px',
                      paddingTop: '15px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      marginRight: '10px',
                      marginLeft: '10px',
                      marginBottom: '20px',
                      height: 'auto',
                      paddingBottom: '15px'
                    }}
                  >
                    <strong>
                      <p style={{ paddingTop: 5, paddingBottom: 5, fontSize: 12, textAlign: 'left' }}>Order Information</p>
                      <p style={{ textAlign: 'left' }}>ID -- {row.original.shipment_friendly_id}</p>
                    </strong>
                    {/* {selected === 'pending' && row.original.is_expedited ? (
                <strong>
                  <p style={{ color: '#f7941d' }}>Expedited Delivery</p>
                </strong>
              ) : null} */}
                    {selected === 'pending' ? (
                      row.original.shipment_type === 'marketplace' ? (
                        row.original.Orders[0].Items.map((item, index, items) => {
                          return (
                            <div key={item.item_id} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                              {/* {console.log("row: ", row)}
                                {console.log("SHipment type from merchant pending", row.original.shipment_type)} */}
                              {/* <div>
                                  <strong>Image</strong>
                                  <p>{item.product_blob.image ? <img src={item.product_blob.image} alt="Product Image" style={{width: "10%"}} />
                                  : "Not Pictured"}</p>
                                </div> */}
                              <div>
                                <strong>Quantity</strong>
                                <p style={{ textAlign: 'center' }}>{item.quantity}</p>
                              </div>
                              <div>
                                <strong>Product Name</strong>
                                {/* <p>{item.product_blob.name}</p> */}
                                <p>
                                  {item.product_blob.name + ' '}
                                  {item.ItemOptions
                                    ? item.ItemOptions.length > 0
                                      ? item.ItemOptions.map((option, index) => {
                                          if (index === 0) {
                                            return '(' + option.option_name;
                                          } else if (index > 0 && index !== item.ItemOptions.length - 1) {
                                            return ', ' + option.option_name;
                                          } else {
                                            return ', ' + option.option_name + ')';
                                          }
                                        })
                                      : null
                                    : null}
                                </p>
                              </div>
                              <div>
                                <strong>Price</strong>
                                {item.product_blob.subtotal ? <p>${item.product_blob.subtotal.toFixed(2)}</p> : <p>Not Found</p>}
                              </div>
                              <div>
                                <strong>SKU</strong>
                                <p>{item.product_blob.sku}</p>
                              </div>
                              {item.status === 'item_created' ? (
                                <div>
                                  <ButtonGroup>
                                    <Button style={{ width: `50%`, textAlign: 'center' }} color="info" name="ready" className={'btn btn-simple'} onClick={() => readyItem(item)}>
                                      Ready
                                    </Button>
                                    <Button
                                      style={{ width: `50%` }}
                                      color="info"
                                      name="decline"
                                      className={'btn btn-simple'}
                                      onClick={() => {
                                        setShipment(row.original);
                                        setItem(item);
                                        setShowDecline(true);
                                      }}
                                    >
                                      Decline
                                    </Button>
                                  </ButtonGroup>
                                </div>
                              ) : item.status === 'item_declined' ? (
                                <div>
                                  <Button style={{ width: `100%`, textAlign: 'center' }} color="danger" name="declined" className={'btn btn-simple'} disabled>
                                    Declined
                                  </Button>
                                </div>
                              ) : item.status === 'item_ready' ? (
                                <div>
                                  <Button style={{ width: `100%`, textAlign: 'center' }} color="success" name="approved" className={'btn btn-simple'} disabled>
                                    Approved for Pickup
                                  </Button>
                                </div>
                              ) : null}
                            </div>
                          );
                        })
                      ) : row.original.shipment_type === 'school_lunch' ? (
                        <SchoolLunchInfo order={row.original.Orders[0]} shipment={row.original} />
                      ) : row.original.shipment_type === 'direct' ? (
                        <>
                          <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'left' }}>
                            <Col className="col-2">
                              <BasicInfoDirectPick shipment={row.original} />
                            </Col>
                            {row.original.Orders[1] ? (
                              <Col className="col-2">
                                <BasicInfoDirectDrop shipment={row.original} />
                              </Col>
                            ) : null}
                            <Col className="col-3">
                              <strong>Load Details</strong>
                              <LoadDetails shipment={row.original} />
                            </Col>
                            <Col className="col-2">
                              <strong>Item(s) Description</strong>
                              <DeliveryDescription shipment={row.original} />
                            </Col>
                            <Col className="col-2">
                              <QuoteOrderButtons shipment={row.original} />
                            </Col>
                          </Row>
                        </>
                      ) : row.original.shipment_type === 'routed_direct' ? (
                        <>
                          <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'left' }}>
                            <BasicInfoRoutedWaypoint orders={row.original.Orders} display={false} />

                            <Col className="col-2">
                              <strong>Load Details</strong>
                              <LoadDetails shipment={row.original} />
                            </Col>

                            <Col className="col-2">
                              <QuoteOrderButtons shipment={row.original} />
                            </Col>

                            {/* <Col className="col-2">
                          <strong>Description of Item(s)</strong>
                          <RoutedDirectItemList orders={row.original.Orders} />
                        </Col> */}
                          </Row>
                        </>
                      ) : row.original.shipment_type === 'multidrop_direct' ? (
                        <>
                          <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'left' }}>
                            <BasicInfoMultidropWaypoint orders={row.original.Orders} display={false} />
                            <Col className="col-2">
                              <strong>Load Details</strong>
                              <LoadDetails shipment={row.original} />
                            </Col>
                            <Col className="col-2">
                              <QuoteOrderButtons shipment={row.original} />
                            </Col>
                          </Row>
                        </>
                      ) : (
                        //! Direct styled Bridge Shipment
                        // <>
                        //   <strong style={{color: '#cdcaca', }}>Bridge Delivery - {row.original.status === "shipment_ready_for_driver" || row.original.status === "shipment_assigned" || row.original.status === "shipment_claimed" ? "Awaiting Driver " : "Status Unknown "}{row.original.is_expedited ? <p style={{color: '#f7941d'}}>Expedited Delivery</p> : null}</strong>
                        //     {console.log("order: ", row.original.Orders[0])}
                        //     <Row style={{paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'center'}}>
                        //       <Col className='col-2'>
                        //         <strong>Pickup Location</strong>
                        //         <p>{row.original.Orders[0].location_blob.phone}</p>
                        //         <p>{row.original.Orders[0].location_blob.email}</p>
                        //         <p>{row.original.Orders[0].location_blob.address_1}</p>
                        //         <p>{row.original.Orders[0].location_blob.city}, {row.original.Orders[0].location_blob.state}, {row.original.Orders[0].location_blob.zip}</p>
                        //       </Col>
                        //       { row.original.Orders[1] ?
                        //       <Col className='col-2'>
                        //         <strong>Dropoff Info</strong>
                        //         <p>{row.original.Orders[1].location_blob.phone}</p>
                        //         <p>{row.original.Orders[1].location_blob.email}</p>
                        //         <p>{row.original.Orders[1].location_blob.address_1}</p>
                        //         <p>{row.original.Orders[1].location_blob.city}, {row.original.Orders[1].location_blob.state}, {row.original.Orders[1].location_blob.zip}</p>
                        //       </Col>
                        //       : null }
                        //       <Col className='col-3'>
                        //         <strong>Load Details</strong>
                        //         <p>Vehicle Type Suggested: <strong>{"  " + row.original.vehicle_type === 'car' ? 'Sedan' : row.original.vehicle_type === 'suv' ? 'SUV' : capitalize(row.original.vehicle_type.split('_').join(' '))}</strong></p>
                        //         <p>Estimated Weight: <strong>{"  " + row.original.weight} lbs.</strong></p>
                        //         <p>Gophrs Needed: <strong>{"  " + row.original.num_of_gophrs}</strong></p>
                        //       </Col>
                        //       <Col className='col-4'>
                        //         <strong>Description of Item(s)</strong>
                        //         <p>{row.original.description}</p>
                        //       </Col>
                        //   </Row>
                        // </>
                        //! Marketplace styled Bridge Shipment
                        row.original.Orders[0].Items.map((item, index, items) => {
                          return (
                            <>
                              <div key={item.item_id} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                {/* {console.log("row: ", row)}
                                {console.log("SHipment type from merchant pending", row.original.shipment_type)} */}
                                {/* <div>
                                  <strong>Image</strong>
                                  <p>{item.product_blob.image ? <img src={item.product_blob.image} alt="Product Image" style={{width: "10%"}} />
                                  : "Not Pictured"}</p>
                                </div> */}
                                <div>
                                  <strong>Quantity</strong>
                                  <p style={{ textAlign: 'center' }}>{item.quantity}</p>
                                </div>
                                <div>
                                  <strong>Product Name</strong>
                                  {/* <p>{item.product_blob.name}</p> */}
                                  <p>
                                    {item.product_blob.name + ' '}
                                    {item.ItemOptions
                                      ? item.ItemOptions.length > 0
                                        ? item.ItemOptions.map((option, index) => {
                                            if (index === 0) {
                                              return '(' + option.option_name;
                                            } else if (index > 0 && index !== item.ItemOptions.length - 1) {
                                              return ', ' + option.option_name;
                                            } else {
                                              return ', ' + option.option_name + ')';
                                            }
                                          })
                                        : null
                                      : null}
                                  </p>
                                </div>
                                <div>
                                  <strong>Price</strong>
                                  {item.product_price == 0.0 ? <p>N/A</p> : <p>item.product_price</p>}
                                </div>

                                <div>
                                  <strong>SKU</strong>
                                  {item.product_blob.sku ? <p>item.product_blob.sku</p> : <p>N/A</p>}
                                </div>
                                <div>
                                  <strong>Wight</strong>
                                  <p>{item.product_blob.weight.toFixed(2)}</p>
                                </div>
                                <div>
                                  <strong>Dimensions</strong>
                                  <p>
                                    L: {item.product_blob.length ? item.product_blob.length.toFixed(2) : 'N/A'}, W:{' '}
                                    {item.product_blob.width ? item.product_blob.width.toFixed(2) : 'N/A'}, H:{' '}
                                    {item.product_blob.height ? item.product_blob.height.toFixed(2) : 'N/A'}
                                  </p>
                                </div>
                                {item.status === 'item_created' ? (
                                  <div>
                                    <ButtonGroup>
                                      <Button
                                        style={{ width: `50%`, textAlign: 'center' }}
                                        color="success"
                                        name="ready"
                                        className={'btn btn-simple'}
                                        onClick={() => readyItem(item)}
                                      >
                                        Ready
                                      </Button>
                                      <Button
                                        style={{ width: `50%`, textAlign: `center` }}
                                        // color="info"
                                        color="danger"
                                        name="decline"
                                        className={'btn btn-simple'}
                                        // className={'btn btn-danger'}
                                        onClick={() => {
                                          setShipment(row.original);
                                          setItem(item);
                                          setShowDecline(true);
                                        }}
                                      >
                                        Decline
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                ) : item.status === 'item_declined' ? (
                                  <div>
                                    <Button style={{ width: `100%`, textAlign: 'center' }} color="danger" name="declined" className={'btn btn-simple'} disabled>
                                      Declined
                                    </Button>
                                  </div>
                                ) : item.status === 'item_ready' ? (
                                  <div>
                                    <Button style={{ width: `100%`, textAlign: 'center' }} color="success" name="approved" className={'btn btn-simple'} disabled>
                                      Approved for Pickup
                                    </Button>
                                  </div>
                                ) : null}
                              </div>
                            </>
                          );
                        })
                      )
                    ) : selected === 'inProgress' ? (
                      <div style={{ paddingLeft: 20 }}>
                        {row.original.shipment_type === 'direct' ? (
                          <>
                            <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'left' }}>
                              <Col className="col-2">
                                <BasicInfoDirectPick shipment={row.original} />
                              </Col>
                              {row.original.Orders[1] ? (
                                <Col className="col-2">
                                  <BasicInfoDirectDrop shipment={row.original} />
                                </Col>
                              ) : null}
                              <Col className="col-3">
                                <strong>Load Details</strong>
                                <LoadDetails shipment={row.original} />
                              </Col>
                              <Col className="col-2">
                                <DriverInfo shipment={row.original} />
                              </Col>
                              <Col className="col-3">
                                <strong>Item(s) Description</strong>
                                <DeliveryDescription shipment={row.original} />
                              </Col>
                            </Row>
                          </>
                        ) : row.original.shipment_type === 'routed_direct' ? (
                          <>
                            <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'left' }}>
                              <BasicInfoRoutedWaypoint orders={row.original.Orders} display={true} />
                              <Col className="col-2">
                                <strong>Load Details</strong>
                                <LoadDetails shipment={row.original} />
                              </Col>
                              <Col className="col-2.5">
                                <DriverInfo shipment={row.original} />
                              </Col>
                              {/* <Col className="col-2">
                          <strong>Description of Item(s)</strong>
                          <RoutedDirectItemList orders={row.original.Orders} />
                        </Col> */}
                            </Row>
                          </>
                        ) : row.original.shipment_type === 'multidrop_direct' ? (
                          <>
                            <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'left' }}>
                              <BasicInfoMultidropWaypoint orders={row.original.Orders} display={true} />
                              <Col className="col-2">
                                <strong>Load Details</strong>
                                <LoadDetails shipment={row.original} />
                              </Col>
                              <Col className="col-2.5">
                                <DriverInfo shipment={row.original} />
                              </Col>
                            </Row>
                          </>
                        ) : row.original.shipment_type === 'bridge' ? (
                          //! Direct style bridge shipment
                          // <>
                          //   <strong style={{ color: '#cdcaca' }}>
                          //     Bridge Delivery -{' '}
                          //     {row.original.status === 'shipment_ready_for_driver' || row.original.status === 'shipment_assigned' || row.original.status === 'shipment_claimed'
                          //       ? 'Awaiting Driver '
                          //       : 'Status Unknown '}
                          //     {row.original.is_expedited ? <p style={{ color: '#f7941d' }}>Expedited Delivery</p> : null}
                          //   </strong>
                          //   {console.log('order: ', row.original.Orders[0])}
                          //   <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'center' }}>
                          //     <Col className="col-2">
                          //       <strong>Pickup Location</strong>
                          //       <p>{row.original.Orders[0].location_blob.phone}</p>
                          //       <p>{row.original.Orders[0].location_blob.email}</p>
                          //       <p>{row.original.Orders[0].location_blob.address_1}</p>
                          //       <p>
                          //         {row.original.Orders[0].location_blob.city}, {row.original.Orders[0].location_blob.state}, {row.original.Orders[0].location_blob.zip}
                          //       </p>
                          //     </Col>
                          //     {row.original.Orders[1] ? (
                          //       <Col className="col-2">
                          //         <strong>Dropoff Info</strong>
                          //         <p>{row.original.Orders[1].location_blob.phone}</p>
                          //         <p>{row.original.Orders[1].location_blob.email}</p>
                          //         <p>{row.original.Orders[1].location_blob.address_1}</p>
                          //         <p>
                          //           {row.original.Orders[1].location_blob.city}, {row.original.Orders[1].location_blob.state}, {row.original.Orders[1].location_blob.zip}
                          //         </p>
                          //       </Col>
                          //     ) : null}
                          //     <Col className="col-3">
                          //       <strong>Load Details</strong>
                          //       <p>
                          //         Vehicle Type Suggested:{' '}
                          //         <strong>
                          //           {'  ' + row.original.vehicle_type === 'car'
                          //             ? 'Sedan'
                          //             : row.original.vehicle_type === 'suv'
                          //             ? 'SUV'
                          //             : capitalize(row.original.vehicle_type.split('_').join(' '))}
                          //         </strong>
                          //       </p>
                          //       <p>
                          //         Estimated Weight: <strong>{'  ' + row.original.weight} lbs.</strong>
                          //       </p>
                          //       <p>
                          //         Gophrs Needed: <strong>{'  ' + row.original.num_of_gophrs}</strong>
                          //       </p>
                          //     </Col>
                          //     <Col className="col-4">
                          //       <strong>Description of Item(s)</strong>
                          //       <p>{row.original.description}</p>
                          //     </Col>
                          //   </Row>
                          // </>
                          //! Marketplace style bridge shipment
                          <>
                            {/* <strong style={{ color: '#cdcaca' }}>
                        Bridge Delivery -{' '}
                        {row.original.status === 'shipment_ready_for_driver' || row.original.status === 'shipment_assigned' || row.original.status === 'shipment_claimed'
                          ? 'Awaiting Driver '
                          : 'Status Unknown '}
                        {row.original.is_expedited ? <p style={{ color: '#f7941d' }}>Expedited Delivery</p> : null}
                      </strong> */}
                            {row.original.Orders[0].Items.map((item, index, items) => {
                              return (
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                  <div>
                                    <strong>Quantity</strong>
                                    <p style={{ textAlign: 'center' }}>{item.quantity}</p>
                                  </div>
                                  <div>
                                    <strong>Product Name</strong>
                                    <p>
                                      {item.product_blob.name + ' '}
                                      {item.ItemOptions
                                        ? item.ItemOptions.length > 0
                                          ? item.ItemOptions.map((option, index) => {
                                              if (index === 0) {
                                                return '(' + option.option_name;
                                              } else if (index > 0 && index !== item.ItemOptions.length - 1) {
                                                return ', ' + option.option_name;
                                              } else {
                                                return ', ' + option.option_name + ')';
                                              }
                                            })
                                          : null
                                        : null}
                                    </p>
                                  </div>
                                  <div>
                                    <strong>Price</strong>
                                    {item.product_price == 0.0 ? <p>N/A</p> : <p>item.product_price</p>}
                                  </div>

                                  <div>
                                    <strong>SKU</strong>
                                    {item.product_blob.sku ? <p>item.product_blob.sku</p> : <p>N/A</p>}
                                  </div>
                                  <div>
                                    <strong>Wight</strong>
                                    <p>{item.product_blob.weight.toFixed(2)}</p>
                                  </div>
                                  <div>
                                    <strong>Dimensions</strong>
                                    <p>
                                      L: {item.product_blob.length ? item.product_blob.length.toFixed(2) : 'N/A'}, W:{' '}
                                      {item.product_blob.width ? item.product_blob.width.toFixed(2) : 'N/A'}, H:{' '}
                                      {item.product_blob.height ? item.product_blob.height.toFixed(2) : 'N/A'}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : row.original.shipment_type === 'school_lunch' ? (
                          <SchoolLunchInfo order={row.original.Orders[0]} shipment={row.original} />
                        ) : (
                          <>
                            {/* <strong style={{ color: '#cdcaca' }}>
                        Gophr App Delivery -{' '}
                        {row.original.status === 'shipment_ready_for_driver' || row.original.status === 'shipment_assigned' || row.original.status === 'shipment_claimed'
                          ? 'Awaiting Driver '
                          : 'Status Unknown '}
                        {row.original.is_expedited ? <p style={{ color: '#f7941d' }}>Expedited Delivery</p> : null}
                      </strong> */}
                            {row.original.Orders[0].Items.map((item, index, items) => {
                              return (
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                  <div>
                                    <strong>Quantity</strong>
                                    <p style={{ textAlign: 'center' }}>{item.quantity}</p>
                                  </div>
                                  <div>
                                    <strong>Product Name</strong>
                                    <p>
                                      {item.product_blob.name + ' '}
                                      {item.ItemOptions
                                        ? item.ItemOptions.length > 0
                                          ? item.ItemOptions.map((option, index) => {
                                              if (index === 0) {
                                                return '(' + option.option_name;
                                              } else if (index > 0 && index !== item.ItemOptions.length - 1) {
                                                return ', ' + option.option_name;
                                              } else {
                                                return ', ' + option.option_name + ')';
                                              }
                                            })
                                          : null
                                        : null}
                                    </p>
                                  </div>
                                  <div>
                                    <strong>Price</strong>
                                    {item.product_blob.subtotal ? <p>${item.product_blob.subtotal.toFixed(2)}</p> : <p>Not Found</p>}
                                  </div>
                                  <div>
                                    <strong>SKU</strong>
                                    <p>{item.product_blob.sku}</p>
                                  </div>
                                  {/* {item.status === "item_created" ?
                                      <div>
                                        <ButtonGroup>
                                          <Button 
                                            style={{width: `50%`, textAlign: 'center'}}
                                            color="info"
                                            name="ready"
                                            className={"btn btn-simple"}
                                            onClick={() => readyItem(item)}
                                          >
                                            Ready
                                          </Button>
                                          <Button 
                                            style={{width: `50%`}}
                                            color="info"
                                            name="decline"
                                            className={"btn btn-simple"}
                                            onClick={() =>{
                                              setItem(item);
                                              setShowDecline(true)
                                            }}
                                          >
                                            Decline
                                          </Button>
                                        </ButtonGroup>
                                      </div>
                                    : item.status === "item_declined" ?
                                      <div>
                                        <Button 
                                          style={{width: `100%`, textAlign: 'center'}}
                                          color="danger"
                                          name="declined"
                                          className={"btn btn-simple"}
                                          disabled
                                        >
                                          Declined
                                        </Button>
                                      </div>
                                    : item.status === "item_ready" ?
                                      <div>
                                        <Button 
                                          style={{width: `100%`, textAlign: 'center'}}
                                          color="success"
                                          name="approved"
                                          className={"btn btn-simple"}
                                          disabled
                                        >
                                          Approved for Pickup
                                        </Button>
                                      </div>
                                    : null } */}
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    ) : selected === 'completed' ? (
                      row.original.shipment_type === 'direct' ? (
                        <>
                          <Row style={{ paddingTop: 20, paddingBottom: 20, marginRight: 0, marginLeft: 0, textAlign: 'left' }}>
                            <Col className="col-2">
                              <BasicInfoDirectPick shipment={row.original} />
                            </Col>
                            {row.original.Orders[1] ? (
                              <Col className="col-2">
                                <BasicInfoDirectDrop shipment={row.original} />
                              </Col>
                            ) : null}
                            <Col className="col-2">
                              <strong>Load Details</strong>

                              <LoadDetails shipment={row.original} />
                            </Col>
                            <Col className="col-3">
                              <strong>Order Description</strong>
                              <DeliveryDescription shipment={row.original} />
                            </Col>
                            <Col className="col-2">
                              <strong>Status</strong>
                              <p>
                                {row.original.status === 'shipment_enroute' ? 'Delivery in Progress' : row.original.status === 'shipment_completed' ? 'Complete' : 'None Found'}
                              </p>
                              {row.original.ShipmentHandshakes.length > 0 && (
                                <>
                                  <img
                                    src={row.original.ShipmentHandshakes[0].url}
                                    alt="Signature"
                                    height={100}
                                    onClick={() => {
                                      setShowSignature(true);
                                      setSignatureUrl(row.original.ShipmentHandshakes[0].url);
                                    }}
                                  />
                                  {/* <p>Click to see full image</p> */}
                                </>
                              )}
                            </Col>
                            {/* <Col>
                                <strong>Driver Info</strong>
                                <p>{row.original.Drivers[0].driver_blob.first_name}</p>
                                <p>{row.original.Drivers[0].vehicle_blob.year}{" "}{row.original.Drivers[0].vehicle_blob.color}{" "}{row.original.Drivers[0].vehicle_blob.make}{" "}{row.original.Drivers[0].vehicle_blob.model}</p>
                            </Col> */}
                          </Row>
                        </>
                      ) : row.original.shipment_type === 'routed_direct' ? (
                        <>
                          <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'left' }}>
                            <BasicInfoRoutedWaypoint orders={row.original.Orders} display={false} />
                            <Col className="col-2">
                              <strong>Load Details</strong>
                              <LoadDetails shipment={row.original} />
                            </Col>

                            {/* <Col className="col-2">
                        <strong>Description of Item(s)</strong>
                        <RoutedDirectItemList orders={row.original.Orders} />
                      </Col> */}

                            <Col className="col-2.5">
                              <strong>Status</strong>
                              <p>
                                {row.original.status === 'shipment_enroute' ? 'Delivery in Progress' : row.original.status === 'shipment_completed' ? 'Complete' : 'None Found'}
                              </p>
                              {row.original.ShipmentHandshakes.length > 0 && (
                                <>
                                  <img
                                    src={row.original.ShipmentHandshakes[0].url}
                                    alt="Signature"
                                    height={100}
                                    onClick={() => {
                                      setShowSignature(true);
                                      setSignatureUrl(row.original.ShipmentHandshakes[0].url);
                                    }}
                                  />
                                  {/* <p>Click to see full image</p> */}
                                </>
                              )}
                            </Col>
                          </Row>
                        </>
                      ) : row.original.shipment_type === 'multidrop_direct' ? (
                        <>
                          <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'left' }}>
                            <BasicInfoMultidropWaypoint orders={row.original.Orders} display={false} />
                            <Col className="col-2">
                              <strong>Load Details</strong>
                              <LoadDetails shipment={row.original} />
                            </Col>
                            <Col className="col-2.5">
                              <strong>Status</strong>
                              <p>
                                {row.original.status === 'shipment_enroute' ? 'Delivery in Progress' : row.original.status === 'shipment_completed' ? 'Complete' : 'None Found'}
                              </p>
                              {row.original.ShipmentHandshakes.length > 0 && (
                                <>
                                  <img
                                    src={row.original.ShipmentHandshakes[0].url}
                                    alt="Signature"
                                    height={100}
                                    onClick={() => {
                                      setShowSignature(true);
                                      setSignatureUrl(row.original.ShipmentHandshakes[0].url);
                                    }}
                                  />
                                  {/* <p>Click to see full image</p> */}
                                </>
                              )}
                            </Col>
                          </Row>
                        </>
                      ) : row.original.shipment_type === 'bridge' ? (
                        // ! Direct style bridge shipment
                        // <>
                        //   <strong style={{ color: '#cdcaca' }}>
                        //     Bridge Delivery - {row.original.status === 'shipment_completed' ? 'Completed' : 'Status Unknown'}
                        //     {row.original.is_expedited ? <p style={{ color: '#f7941d' }}>Expedited Delivery</p> : null}
                        //   </strong>
                        //   <Row style={{ paddingTop: 20, paddingBottom: 20, marginRight: 0, marginLeft: 0 }}>
                        //     <Col className="col-2">
                        //       <strong>Pickup Information</strong>
                        //       <p>
                        //         {row.original.Orders[0].location_blob.first_name} {row.original.Orders[0].location_blob.last_name}
                        //       </p>
                        //       <p>{row.original.Orders[0].location_blob.phone}</p>
                        //       <p>{row.original.Orders[0].location_blob.email}</p>
                        //       <p>{row.original.Orders[0].location_blob.address_1}</p>
                        //       <p>
                        //         {row.original.Orders[0].location_blob.city}, {row.original.Orders[0].location_blob.state}, {row.original.Orders[0].location_blob.zip}
                        //       </p>
                        //     </Col>
                        //     {row.original.Orders[1] ? (
                        //       <Col className="col-2">
                        //         <strong>Dropoff Location</strong>
                        //         <p>{row.original.Orders[1].location_blob.phone}</p>
                        //         <p>{row.original.Orders[1].location_blob.email}</p>
                        //         <p>{row.original.Orders[1].location_blob.address_1}</p>
                        //         <p>
                        //           {row.original.Orders[1].location_blob.city}, {row.original.Orders[1].location_blob.state}, {row.original.Orders[1].location_blob.zip}
                        //         </p>
                        //       </Col>
                        //     ) : null}
                        //     <Col className="col-2">
                        //       <strong>Details</strong>
                        //       <p>
                        //         Suggested Vehicle:{' '}
                        //         <strong>
                        //           {'  ' + row.original.vehicle_type === 'car'
                        //             ? 'Sedan'
                        //             : row.original.vehicle_type === 'suv'
                        //             ? 'SUV'
                        //             : capitalize(row.original.vehicle_type.split('_').join(' '))}
                        //         </strong>
                        //       </p>
                        //       <p>
                        //         Estimated Weight: <strong>{'  ' + row.original.weight} lbs.</strong>
                        //       </p>
                        //       <p>
                        //         Gophrs Needed: <strong>{'  ' + row.original.num_of_gophrs}</strong>
                        //       </p>
                        //     </Col>
                        //     <Col className="col-3">
                        //       <strong>Order Description</strong>
                        //       <p>{row.original.description}</p>
                        //     </Col>
                        //     <Col className="col-2">
                        //       <strong>Status</strong>
                        //       <p>{row.original.status === 'shipment_enroute' ? 'Delivery in Progress' : row.original.status === 'shipment_completed' ? 'Complete' : 'None Found'}</p>
                        //     </Col>
                        //     {/* <Col>
                        //             <strong>Driver Info</strong>
                        //             <p>{row.original.Drivers[0].driver_blob.first_name}</p>
                        //             <p>{row.original.Drivers[0].vehicle_blob.year}{" "}{row.original.Drivers[0].vehicle_blob.color}{" "}{row.original.Drivers[0].vehicle_blob.make}{" "}{row.original.Drivers[0].vehicle_blob.model}</p>
                        //         </Col> */}
                        //   </Row>
                        // </>
                        // ! Marketplace style bridge shipment
                        <>
                          {/* <strong style={{ color: '#cdcaca' }}>
                      Gophr App Delivery - {row.original.status === 'shipment_completed' ? 'Completed' : 'Status Unknown '}
                      {row.original.is_expedited ? <p style={{ color: '#f7941d' }}>Expedited Delivery</p> : null}
                    </strong> */}
                          {row.original.Orders[0].Items.map((item, index, items) => {
                            return (
                              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                {/* <div>
                                  <strong>Image</strong>
                                  
                                </div> */}
                                <div>
                                  <strong>Quantity</strong>
                                  <p style={{ textAlign: 'center' }}>{item.quantity}</p>
                                </div>
                                <div>
                                  <strong>Product Name</strong>
                                  <p>
                                    {item.product_blob.name + ' '}
                                    {item.ItemOptions
                                      ? item.ItemOptions.length > 0
                                        ? item.ItemOptions.map((option, index) => {
                                            if (index === 0) {
                                              return '(' + option.option_name;
                                            } else if (index > 0 && index !== item.ItemOptions.length - 1) {
                                              return ', ' + option.option_name;
                                            } else {
                                              return ', ' + option.option_name + ')';
                                            }
                                          })
                                        : null
                                      : null}
                                  </p>
                                </div>
                                <div>
                                  <strong>Price</strong>
                                  {item.product_price == 0.0 ? <p>N/A</p> : <p>item.product_price</p>}
                                </div>

                                <div>
                                  <strong>SKU</strong>
                                  {item.product_blob.sku ? <p>item.product_blob.sku</p> : <p>N/A</p>}
                                </div>
                                <div>
                                  <strong>Wight</strong>
                                  <p>{item.product_blob.weight.toFixed(2)}</p>
                                </div>
                                <div>
                                  <strong>Dimensions</strong>
                                  <p>
                                    L: {item.product_blob.length ? item.product_blob.length.toFixed(2) : 'N/A'}, W:{' '}
                                    {item.product_blob.width ? item.product_blob.width.toFixed(2) : 'N/A'}, H:{' '}
                                    {item.product_blob.height ? item.product_blob.height.toFixed(2) : 'N/A'}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : row.original.shipment_type === 'school_lunch' ? (
                        <SchoolLunchInfo order={row.original.Orders[0]} shipment={row.original} />
                      ) : (
                        <>
                          {/* <strong style={{ color: '#cdcaca' }}>
                      Gophr App Delivery - {row.original.status === 'shipment_completed' ? 'Completed' : 'Status Unknown '}
                      {row.original.is_expedited ? <p style={{ color: '#f7941d' }}>Expedited Delivery</p> : null}
                    </strong> */}
                          {row.original.Orders[0].Items.map((item, index, items) => {
                            return (
                              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                {/* <div>
                                  <strong>Image</strong>
                                  
                                </div> */}
                                <div>
                                  <strong>Quantity</strong>
                                  <p style={{ textAlign: 'center' }}>{item.quantity}</p>
                                </div>
                                <div>
                                  <strong>Product Name</strong>
                                  <p>
                                    {item.product_blob.name + ' '}
                                    {item.ItemOptions
                                      ? item.ItemOptions.length > 0
                                        ? item.ItemOptions.map((option, index) => {
                                            if (index === 0) {
                                              return '(' + option.option_name;
                                            } else if (index > 0 && index !== item.ItemOptions.length - 1) {
                                              return ', ' + option.option_name;
                                            } else {
                                              return ', ' + option.option_name + ')';
                                            }
                                          })
                                        : null
                                      : null}
                                  </p>
                                </div>
                                <div>
                                  <strong>Price</strong>
                                  {item.product_blob.total ? <p>${item.product_blob.total.toFixed(2)}</p> : <p>Not Found</p>}
                                </div>
                                <div>
                                  <strong>SKU</strong>
                                  <p>{item.product_blob.sku}</p>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )
                    ) : selected === 'saved' ? (
                      <div className="saved-container">
                        {/* {row.original.shipment_type === 'direct' ? (
                    <strong style={{ color: '#cdcaca' }}>
                      Direct Delivery -{' '}
                      {row.original.status === 'shipment_scheduled'
                        ? 'Scheduled for ' + formatTimestamp(row.original.scheduled_for)
                        : row.original.status === 'shipment_saved'
                        ? 'Saved'
                        : 'Status Unknown '}
                      {row.original.is_expedited ? <p style={{ color: '#f7941d' }}>This is an expedited delivery</p> : null}
                    </strong>
                  ) : //! bridge should not have save for later if in marketplace style
                  // ) : row.original.shipment_type === 'bridge' ? (
                  //   <strong style={{ color: '#cdcaca' }}>
                  //     Bridge Delivery -{' '}
                  //     {row.original.status === 'shipment_scheduled'
                  //       ? 'Scheduled for ' + formatTimestamp(row.original.scheduled_for)
                  //       : row.original.status === 'shipment_saved'
                  //       ? 'Saved'
                  //       : 'Status Unknown '}
                  //     {row.original.is_expedited ? <p style={{ color: '#f7941d' }}>This is an expedited delivery</p> : null}
                  //   </strong>
                  null} */}
                        {row.original.shipment_type === 'direct' ? (
                          <>
                            <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'left' }}>
                              <Col className="col-2">
                                <BasicInfoDirectPick shipment={row.original} />
                              </Col>
                              {row.original.Orders[1] ? (
                                <Col className="col-2">
                                  <BasicInfoDirectDrop shipment={row.original} />
                                </Col>
                              ) : null}
                              <Col className="col-3">
                                <strong>Load Details</strong>
                                <LoadDetails shipment={row.original} />
                              </Col>
                              <Col className="col-2">
                                <strong>Item(s) Description</strong>
                                <DeliveryDescription shipment={row.original} />
                              </Col>
                              <Col className="col-3">
                                <div className="second-row" style={{ display: `flex`, flexDirection: `row`, justifyContent: `center` }}>
                                  <ButtonGroup>
                                    <Button
                                      color="info"
                                      name="ready-pickup"
                                      style={{ padding: 10 }}
                                      onClick={e => {
                                        setSelectSavedAction(e.target.value);
                                        setShipment(row.original);
                                        setShowAlert1(true);
                                      }}
                                      className={selectSavedAction === 'ready-pickup' ? 'btn' : 'btn-simple'}
                                    >
                                      Ready for Pick Up
                                    </Button>
                                    <Button
                                      color="info"
                                      name="delete-order"
                                      style={{ padding: 10 }}
                                      onClick={e => {
                                        setSelectSavedAction(e.target.value);
                                        setShipment(row.original);
                                        setShowAlert(true);
                                      }}
                                      className={selectSavedAction === 'delete-order' ? 'btn' : 'btn-simple'}
                                    >
                                      Delete Order
                                    </Button>
                                  </ButtonGroup>
                                </div>
                              </Col>
                            </Row>
                          </>
                        ) : row.original.shipment_type === 'routed_direct' ? (
                          <>
                            <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'left' }}>
                              <BasicInfoRoutedWaypoint orders={row.original.Orders} display={false} />
                              <Col className="col-2">
                                <strong>Load Details</strong>
                                <LoadDetails shipment={row.original} />
                              </Col>

                              {/* <Col className="col-2">
                          <strong>Description of Item(s)</strong>
                          <RoutedDirectItemList orders={row.original.Orders} />
                        </Col> */}
                              <Col className="col-2.5">
                                <div className="second-row" style={{ display: `flex`, flexDirection: `row`, justifyContent: `center` }}>
                                  <ButtonGroup>
                                    <Button
                                      color="info"
                                      name="ready-pickup"
                                      style={{ padding: 10 }}
                                      onClick={e => {
                                        setSelectSavedAction(e.target.value);
                                        setShipment(row.original);
                                        setShowAlert1(true);
                                      }}
                                      className={selectSavedAction === 'ready-pickup' ? 'btn' : 'btn-simple'}
                                    >
                                      Ready for Pick Up
                                    </Button>
                                    <Button
                                      color="info"
                                      name="delete-order"
                                      style={{ padding: 10 }}
                                      onClick={e => {
                                        setSelectSavedAction(e.target.value);
                                        setShipment(row.original);
                                        setShowAlert(true);
                                      }}
                                      className={selectSavedAction === 'delete-order' ? 'btn' : 'btn-simple'}
                                    >
                                      Delete Order
                                    </Button>
                                  </ButtonGroup>
                                </div>
                              </Col>
                            </Row>
                          </>
                        ) : row.original.shipment_type === 'multidrop_direct' ? (
                          <>
                            <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'left' }}>
                              <BasicInfoMultidropWaypoint orders={row.original.Orders} display={false} />
                              <Col className="col-2">
                                <strong>Load Details</strong>
                                <LoadDetails shipment={row.original} />
                              </Col>

                              <Col className="col-2.5">
                                <div className="second-row" style={{ display: `flex`, flexDirection: `row`, justifyContent: `center` }}>
                                  <ButtonGroup>
                                    <Button
                                      color="info"
                                      name="ready-pickup"
                                      style={{ padding: 10 }}
                                      onClick={e => {
                                        setSelectSavedAction(e.target.value);
                                        setShipment(row.original);
                                        setShowAlert1(true);
                                      }}
                                      className={selectSavedAction === 'ready-pickup' ? 'btn' : 'btn-simple'}
                                    >
                                      Ready for Pick Up
                                    </Button>
                                    <Button
                                      color="info"
                                      name="delete-order"
                                      style={{ padding: 10 }}
                                      onClick={e => {
                                        setSelectSavedAction(e.target.value);
                                        setShipment(row.original);
                                        setShowAlert(true);
                                      }}
                                      className={selectSavedAction === 'delete-order' ? 'btn' : 'btn-simple'}
                                    >
                                      Delete Order
                                    </Button>
                                  </ButtonGroup>
                                </div>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <>
                            {' '}
                            {row.original.Orders[0].Items.map((item, index, items) => {
                              return (
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                  <div>
                                    <strong>Quantity</strong>
                                    <p style={{ textAlign: 'center' }}>{item.quantity}</p>
                                  </div>
                                  <div>
                                    <strong>Product Name</strong>
                                    <p>
                                      {item.product_blob.name + ' '}
                                      {item.ItemOptions
                                        ? item.ItemOptions.length > 0
                                          ? item.ItemOptions.map((option, index) => {
                                              if (index === 0) {
                                                return '(' + option.option_name;
                                              } else if (index > 0 && index !== item.ItemOptions.length - 1) {
                                                return ', ' + option.option_name;
                                              } else {
                                                return ', ' + option.option_name + ')';
                                              }
                                            })
                                          : null
                                        : null}
                                    </p>
                                  </div>
                                  <div>
                                    <strong>Price</strong>
                                    {item.product_price == 0.0 ? <p>N/A</p> : <p>item.product_price</p>}
                                  </div>

                                  <div>
                                    <strong>SKU</strong>
                                    {item.product_blob.sku ? <p>item.product_blob.sku</p> : <p>N/A</p>}
                                  </div>
                                  <div>
                                    <strong>Wight</strong>
                                    <p>{item.product_blob.weight.toFixed(2)}</p>
                                  </div>
                                  <div>
                                    <strong>Dimensions</strong>
                                    <p>
                                      L: {item.product_blob.length ? item.product_blob.length.toFixed(2) : 'N/A'}, W:{' '}
                                      {item.product_blob.width ? item.product_blob.width.toFixed(2) : 'N/A'}, H:{' '}
                                      {item.product_blob.height ? item.product_blob.height.toFixed(2) : 'N/A'}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              }}
            />
          </div>
        </ResizableBox>
        <ResizableBox className="box" width={3000} height={1200} minConstraints={[1000, 1000]} maxConstraints={[10000, 2000]} draggableOpts={{ grid: [10, 10] }} axis="x">
          <div className="float-child-map">
            <MerchantMap
              drivers={'driversToDisplay'}
              coordinates={coordinates}
              trafficLayer={trafficLayer}
              setTrafficLayer={setTrafficLayer}
              readyShipments={readyShipments}
              deliveringShipments={deliveringShipments}
              readyShipmentsCheck={readyShipmentsCheck}
              setReadyShipmentsCheck={setReadyShipmentsCheck}
              deliveringShipmentsCheck={deliveringShipmentsCheck}
              setDeliveringShipmentsCheck={setDeliveringShipmentsCheck}
              driversEnroute={driversEnroute}
              setDriversEnroute={setDriversEnroute}
              driversEnrouteCheck={driversEnrouteCheck}
              setDriversEnrouteCheck={setDriversEnrouteCheck}
              shipmentClick={shipmentClick}
            />
          </div>
        </ResizableBox>
      </div>

      <div id="warning-alert" hidden={!showAlert1}>
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-200px' }}
          title="Are you sure?"
          onConfirm={() => {
            // setSelected("pending");
            setSelected('inProgress');
            readyShipment(shipment);
            setShipment('');
            setShowAlert1(false);
          }}
          onCancel={() => {
            setShipment('');
            setShowAlert1(false);
          }}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          <div>You are about to mark an order as Ready for Pick Up.</div>
          <div>This action cannot be undone.</div>
        </ReactBSAlert>
      </div>

      <div id="warning-alert" hidden={!showAlert}>
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-200px' }}
          title="Are you sure?"
          onConfirm={() => {
            cancelShipment(shipment);
            setShipment('');
            setShowAlert(false);
            // setSelected("pending");
            setSelected('inProgress');
          }}
          onCancel={() => {
            setShipment('');
            setShowAlert(false);
          }}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          <div>You are about to delete a saved delivery.</div>
          <div>This action cannot be undone.</div>
        </ReactBSAlert>
      </div>

      <div id="warning-alert-decline-quote-shipment" hidden={!showDeclineQuoteShipmentAlert}>
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-200px' }}
          title="Are you sure?"
          onConfirm={() => {
            cancelShipment(shipment);
            setShipment('');
            setShowDeclineQuoteShipmentAlert(false);
            // setSelected('pending');
            setSelected('inProgress');
          }}
          onCancel={() => {
            setShipment('');
            setShowDeclineQuoteShipmentAlert(false);
          }}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          <div>You are about to cancel this delivery.</div>
          <div>This action cannot be undone.</div>
        </ReactBSAlert>
      </div>

      <div id="warning-alert" hidden={!showDecline}>
        {item
          ? !item.Order.contains_alcohol
            ? // <ReactBSAlert
              //   warning
              //   style={{ display: "block", marginTop: "-200px" }}
              //   title="Item Unavailable?"
              //   onConfirm={() => {
              //     declineItem(item);
              //     setItem(null);
              //     setShowDecline(false);
              //   }}
              //   onCancel={() => {
              //     setItem(null);
              //     setShowDecline(false);
              //   }}
              //   confirmBtnBsStyle="danger"
              //   cancelBtnBsStyle="default"
              //   confirmBtnText="Confirm"
              //   cancelBtnText="Cancel"
              //   showCancel
              //   btnSize=""
              // >
              //   <div>This item will be marked as <strong>"Out of Stock"</strong> on the Gophr app until you manually re-enable it in your Inventory Manager.</div>
              //   <div>This action cannot be undone.</div>
              // </ReactBSAlert>
              defaultAlert()
            : checkOrder(store.permit_type, shipment, item)
          : null}
      </div>
      <div>
        <Modal isOpen={showSignature} toggle={toggleSignature}>
          <ModalBody>
            <img src={signatureUrl} alt="Signature" />
          </ModalBody>
          <ModalFooter style={{ alignSelf: 'center' }}>
            <Button
              color="default"
              onClick={() => {
                toggleSignature();
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default MerchantOrderTable;
