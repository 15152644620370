/*!
* Black Dashboard PRO React - v1.0.0
* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Coded by Creative Tim
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/*eslint-disable*/
import React from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";

const Footer = (props) => {
    return (
      <footer
        className={"footer" + (props.default ? " footer-default" : "")}>
          
        <Container fluid={props.fluid ? true : false}>
          <em className="contact-us-footer">If you have any questions, call us at <b>(833) GOPHR-IT</b></em>
          <div className="copyright"> 
              <em>© {new Date().getFullYear()} Made with love by</em> 
              <a href="https://gophr.app" target="_blank" rel="noopener noreferrer">
                {" "}<em>Gophr App Inc.</em>
              </a>
          </div>
        </Container>
      </footer>
    );
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
