import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { useHistory } from 'react-router';
// import moment from 'moment';
import moment from 'moment-timezone';

import { Styles, StyledHr } from './Styles/InfoStyles';
import Loading from '../Global/Loading';
import { LocationIcon, PhoneIcon, EmailIcon, CalendarIcon, StoreMallIcon } from '../../assets/svgs/BlueIcons';
import { SettingsCog } from '../../assets/svgs/WhiteIcons';
import { FaTimes } from 'react-icons/fa';

import formatPhone from '../../helpers/phone';

export const Info = props => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  if (loading) return <Loading />;
  return (
    <Styles className="store-manager-container">
      <button
        style={{
          position: 'relative',
          top: '2.7rem',
          left: '27.4rem',
          fontSize: '1.5rem',
          cursor: 'pointer',
          color: 'hsl(360, 67%, 44%)',
          zIndex: '1',
          background: 'transparent'
        }}
        onClick={props.closeStoreModal}
      >
        <FaTimes />
      </button>
      <Card>
        <CardHeader className="store-header">
          <h4>Store Manager</h4>
          <StyledHr />
        </CardHeader>
        <CardBody>
          <img src={require('../../assets/svgs/ImagePlaceholder.svg')} alt="store-logo-default" className="image-placeholder" />
          <section className="store-basics">
            <h5>{props.store.name}</h5>
            <h6>Store ID#: {props.store.store_id}</h6>
            <div className="address-container">
              <p>
                <span>
                  <LocationIcon />
                </span>
                {props.store.address_1} {props.store.address_2}, {props.store.city}, {props.store.state} {props.store.zip}
              </p>
            </div>
            <p>
              <span>
                <PhoneIcon />
              </span>
              {formatPhone(props.store.phone)}
            </p>
            {/* <p>
                                    <span><EmailIcon/></span>{props.store.StoreOwnerships[0].email}
                                </p> */}
          </section>
          <StyledHr />
          <section className="hours-and-settings">
            <nav className="store-hours">
              <span onClick={toggle}>
                <CalendarIcon />
                Store Times
                <Collapse isOpen={isOpen}>
                  {props.store.StoreTimes[0] === null ? (
                    <div>Add Hours!</div>
                  ) : (
                    <div className="specific-day-hours">
                      <ul style={{ listStyle: 'none' }}>
                        <li>
                          {props.store.StoreTimes.map(day => {
                            let dayinwords;
                            if (day.day_of_week === 0) {
                              dayinwords = 'Mon ';
                            } else if (day.day_of_week === 1) {
                              dayinwords = 'Tue ';
                              // console.log("day.open_time", day.open_time)
                              // console.log("day.open_time in info", moment.utc().day(day.day_of_week).hour(day.open_time.split(':')[0]).minute(day.open_time.split(':')[1]).second(0).millisecond(0).tz('America/Chicago').format("HH:mm A"));
                            } else if (day.day_of_week === 2) {
                              dayinwords = 'Wed ';
                            } else if (day.day_of_week === 3) {
                              dayinwords = 'Thu ';
                            } else if (day.day_of_week === 4) {
                              dayinwords = 'Fri ';
                            } else if (day.day_of_week === 5) {
                              dayinwords = 'Sat ';
                            } else {
                              dayinwords = 'Sun ';
                            }
                            // if(day.open_time !== null){
                            //     console.log("day.open_time in info", moment.utc().day(day.day_of_week).hour(day.open_time.split(':')[0]).minute(day.open_time.split(':')[1]).second(0).millisecond(0).tz('America/Chicago'));

                            // }
                            return (
                              <>
                                <div>
                                  {dayinwords + ': '}
                                  {day.open_time
                                    ? moment
                                        .utc()
                                        .isoWeekday(day.day_of_week)
                                        .hour(day.open_time.split(':')[0])
                                        .minute(day.open_time.split(':')[1])
                                        .second(0)
                                        .millisecond(0)
                                        // .tz('America/Chicago')
                                        .format('HH:mm')
                                    : 'CLOSED'}
                                  {day.close_time
                                    ? ' - ' +
                                      moment
                                        .utc()
                                        .day(day.day_of_week)
                                        .hour(day.close_time.split(':')[0])
                                        .minute(day.close_time.split(':')[1])
                                        .second(0)
                                        .millisecond(0)
                                        // .tz('America/Chicago')
                                        .format('HH:mm')
                                    : ''}
                                </div>
                              </>
                            );
                          })}
                        </li>
                      </ul>
                    </div>
                  )}
                </Collapse>
              </span>
            </nav>
            <StyledHr />
            <nav className="store-info-settings">
              <span>
                <StoreMallIcon />
              </span>
              Store Info
              <button className="settings-btn" onClick={() => history.push(`/admin/inventory/store/${props.store.store_id}/edit`)}>
                <SettingsCog />
              </button>
            </nav>
          </section>
        </CardBody>
      </Card>
    </Styles>
  );
};
