import React, { useState, useEffect } from 'react';
import { Input, ListGroup, ListGroupItem } from 'reactstrap';
import * as shipment_urls from '../../urls/api-shipments/merchant';
import axios from 'axios';

const WaypointCustomerAutoComplete = ({
  isWaypointStore,
  storeFirstName,
  waypointFirstname,
  setWaypointFirstname,
  waypointCustomerSuggestions,
  setWaypointCustomerSuggestions,
  storeId,
  setWaypointLastname,
  setWaypointPhone,
  waypointFullAddress,
  setWaypointFullAddress,
  setWaypointPickupInstruction,
  setWaypointDropoffInstruction,
  isWaypointAutoFilled,
  setIsWaypointAutoFilled,
  setWaypointAddress1,
  setWaypointAddress2,
  setWaypointCity,
  setWaypointState,
  setWaypointZip,
  setWaypointCountry,
  setWaypointLat,
  setWaypointLng,
  setWaypointBusinessName
}) => {
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState('');
  const [hasSelected, setHasSelected] = useState(false);
  const [focus, setFocus] = useState(true);

  const reset = () => {
    setWaypointFirstname('');
    setWaypointCustomerSuggestions('');
    setWaypointLastname('');
    setWaypointPhone('');
    setWaypointFullAddress('');
    setWaypointPickupInstruction('');
    setWaypointDropoffInstruction('');
    setIsWaypointAutoFilled(false);
    setWaypointAddress1('');
    setWaypointAddress2('');
    setWaypointCity('');
    setWaypointState('');
    setWaypointZip('');
    setWaypointCountry('');
    setWaypointLat('');
    setWaypointLng('');
    setWaypointBusinessName();
  };

  useEffect(() => {
    if (!focus && !hasSelected) {
      setShowSuggestions(false);
    }
  }, [focus, hasSelected]);

  useEffect(() => {
    if (waypointCustomerSuggestions.length > 0) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [waypointCustomerSuggestions]);

  useEffect(() => {
    if (!hasSelected) {
      fetchCustomer(input, storeId);
    }
  }, [input, hasSelected]);

  useEffect(() => {
    if (input == '' && isWaypointAutoFilled) {
      reset();
    }
    // setIsWaypointAutoFilled(true);
  }, [input]);

  useEffect(() => {
    if (isWaypointAutoFilled) {
      setIsWaypointAutoFilled(false);
    }
  }, [waypointFullAddress]);

  const fetchCustomer = (customerName, storeId) => {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        query:
          `{
          direct_customer_search(args: {customer_name: "` +
          customerName +
          `", st_id: "` +
          storeId +
          `"}) {
            drop_off_instructions
            pick_up_instructions
            location_blob
          }
        }`
      }
    })
      .then(response => {
        if (response.data.data) {
          setWaypointCustomerSuggestions(response.data.data.direct_customer_search);
          //   console.log('response ', response.data.data);
        } else {
          console.log('getting autofill customer error: ', response);
        }
      })
      .catch(error => {
        console.log('autofill-error ', error);
      });
  };

  const onChange = e => {
    if (isWaypointStore) {
      return;
    }

    const userInput = e.target.value;
    setInput(userInput);
    setWaypointFirstname(userInput);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const onClick = index => {
    let selectedInfo = waypointCustomerSuggestions[index];
    setHasSelected(true); //! solve the suggestion shows up again after the click
    setIsWaypointAutoFilled(true);
    setWaypointFirstname(selectedInfo.location_blob.first_name);
    setWaypointLastname(selectedInfo.location_blob.last_name);
    setWaypointPhone(selectedInfo.location_blob.phone);
    setWaypointFullAddress(selectedInfo.location_blob.address);
    setWaypointAddress1(selectedInfo.location_blob.address_1);
    setWaypointAddress2(selectedInfo.location_blob.address_2);
    setWaypointCity(selectedInfo.location_blob.city);
    setWaypointState(selectedInfo.location_blob.state);
    setWaypointZip(selectedInfo.location_blob.zip);
    setWaypointCountry(selectedInfo.location_blob.country);
    setWaypointLat(selectedInfo.location_blob.lat);
    setWaypointLng(selectedInfo.location_blob.lng);
    setWaypointPickupInstruction(selectedInfo.pick_up_instructions);
    setWaypointDropoffInstruction(selectedInfo.drop_off_instructions);
    if (selectedInfo.location_blob.business_name) {
      setWaypointBusinessName(selectedInfo.location_blob.business_name);
    }
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    setWaypointCustomerSuggestions([]);
    setHasSelected(false);
    // setIsWaypointAutoFilled(false);
  };

  // const onKeyDown = e => {
  //   // User pressed the enter key
  //   if (e.keyCode === 13) {
  //     setWaypointFirstname(filteredSuggestions[activeSuggestionIndex]);
  //     setInput(filteredSuggestions[activeSuggestionIndex]);
  //     setActiveSuggestionIndex(0);
  //     setShowSuggestions(false);
  //   }
  //   // User pressed the up arrow
  //   else if (e.keyCode === 38) {
  //     if (activeSuggestionIndex === 0) {
  //       return;
  //     }

  //     setActiveSuggestionIndex(activeSuggestionIndex - 1);
  //   }
  //   // User pressed the down arrow
  //   else if (e.keyCode === 40) {
  //     if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
  //       return;
  //     }

  //     setActiveSuggestionIndex(activeSuggestionIndex + 1);
  //   }
  // };

  const SuggestionsListComponent = () => {
    return waypointCustomerSuggestions.length ? (
      <ListGroup flush>
        {waypointCustomerSuggestions.map((suggestion, index) => {
          if (index < 5) {
            return (
              <ListGroupItem
                style={{ backgroundColor: '#1e1e2b', border: 'none', color: '#cbcac9' }}
                onMouseOver={e => (e.target.style.background = 'linear-gradient(90deg, hsla(218, 100%, 42%, 0.6) 0%, hsla(0, 0%, 98%, 1) 200%)')}
                onMouseLeave={e => (e.target.style.background = '#1e1e2b')}
                key={index}
                onClick={() => onClick(index)}
              >
                {`${suggestion.location_blob.name}, ${suggestion.location_blob.phone}, ${suggestion.location_blob.address_1}`}
              </ListGroupItem>
            );
          }
        })}
      </ListGroup>
    ) : null;
  };

  return (
    <>
      <Input
        type="text"
        onChange={onChange}
        onBlur={() => {
          setTimeout(() => {
            setFocus(false);
          }, '200');
        }}
        onClick={() => setFocus(true)}
        // onKeyDown={onKeyDown}
        required
        readOnly={isWaypointStore}
        name="storeFirstName"
        id="customer-first-name"
        placeholder="John"
        value={isWaypointStore ? storeFirstName : waypointFirstname}
      />
      {showSuggestions && input && <SuggestionsListComponent />}
    </>
  );
};

export default WaypointCustomerAutoComplete;
