import React, { useState } from 'react';
import { useEffect } from 'react';
import WorkingHours from './components/working_hours';

import './working_hours.scss';

const StoreHours = ({ setStoreHours }) => {
  const [data, setData] = useState({});
  const days = [
    { key: 'mon', name: 'Monday' },
    { key: 'tue', name: 'Tuesday' },
    { key: 'wed', name: 'Wednesday' },
    { key: 'thu', name: 'Thursday' },
    { key: 'fri', name: 'Friday' },
    { key: 'sat', name: 'Saturday' },
    { key: 'sun', name: 'Sunday' }
  ];

  var fieldName = 'location[working_hours]';

  const isEmpty = obj => {
    return Object.keys(obj).length === 0;
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      let businessHours = [];
      data.days.map((day, index) => {
        let start = day.timeCells.find(time => time.selected === true);
        let startIndex = start ? start.index : null;
        let end = day.timeCells.findLast(time => time.selected === true);
        let endIndex = end ? end.index : null;
        if (startIndex !== null && endIndex !== null) {
          businessHours.push({
            day_of_week: index,
            open_time: day.timeCells[startIndex].timeFrom,
            close_time: day.timeCells[endIndex].timeTo,
            is_24hr: day.timeCells[startIndex].timeFrom === day.timeCells[endIndex].timeTo
          });
        }
      });
      setStoreHours(businessHours);
    }
  }, [data]);

  return (
    <div className="App">
      <WorkingHours days={days} fieldName={fieldName} data={data} setData={setData} />
    </div>
  );
};

export default StoreHours;
