import React from "react";
import { Button } from 'reactstrap';
import { Styles } from './Styles/HeaderStyles';
import { Link } from 'react-router-dom';

const Header = (props) => {
    return (
        <Styles>
            <div className="header-title">
                <h4>Store Manager</h4>
                <h5>Edit Store Info</h5>
            </div>
            <div className="buttons-container">
                <div className="button-positioning">
                    <Link to={"/admin/inventory/store/" + props.storeId}>
                        <Button className="cancel">Cancel</Button>
                    </Link>
                    <Button 
                        className="save"
                        onClick={() => {
                                props.isValidated();
                            }
                        }
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Styles>
    )
}

export default Header;