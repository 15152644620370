import React from 'react';

export const LocationIcon = ({
    fill = '#5e72e4',
    width = '17',
    className = 'location-icon',
    height = '18',
    viewBox = '0 0 17 18',
}) => 
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        width={width} 
        height={height} 
        viewBox={viewBox}
    >
        <path d="M16.7 7.4a5.4 5.4 0 1 0-10.8 0c0 4.05 5.4 9.9 5.4 9.9s5.4-5.85 5.4-9.9zm-7.2 0a1.8 1.8 0 1 1 1.8 1.8 1.805 1.805 0 0 1-1.8-1.8zM5 18.2V20h12.6v-1.8z" fill={fill} />
    </svg>;

export const PhoneIcon = ({
    fill = '#5e72e4',
    width = '17',
    className = 'phone-icon',
    height = '20',
    viewBox = '0 0 17 20',
}) => 
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        width={width} 
        height={height} 
        viewBox={viewBox}
    >
        <path d="M13.882 10.729h-1.576v1.576h1.576zm3.153 0h-1.576v1.576h1.576zm2.365 5.124a8.992 8.992 0 0 1-2.814-.449.791.791 0 0 0-.8.193l-1.734 1.738a11.891 11.891 0 0 1-5.195-5.191l1.734-1.738a.791.791 0 0 0 .193-.8 9.016 9.016 0 0 1-.449-2.818A.786.786 0 0 0 9.547 6H6.788A.786.786 0 0 0 6 6.788a13.4 13.4 0 0 0 13.4 13.4.786.786 0 0 0 .788-.788v-2.759a.786.786 0 0 0-.788-.788zm-.788-5.124v1.576h1.576v-1.576z" fill={fill} />
    </svg>;

export const EmailIcon = ({
    fill = '#5e72e4',
    width = '14.188',
    className = 'email-icon',
    height = '14.188',
    viewBox = '0 0 14.188 14.188',
}) => 
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        width={width} 
        height={height} 
        viewBox={viewBox}
    >
        <path d="M10.784 15.189a.677.677 0 0 1-.6-.374L8.174 10.8a.678.678 0 0 1 .049-.687l3.236-4.383-4.388 3.236a.678.678 0 0 1-.687.049l-4.01-2a.676.676 0 0 1 .068-1.238L15.28 1.042a.675.675 0 0 1 .868.868l-4.73 12.838a.674.674 0 0 1-.6.441z" fill={fill} />
    </svg>;

export const CalendarIcon = ({
    fill = '#5e72e4',
    width = '14.612',
    className = 'calendar-icon',
    height = '14.612',
    viewBox = '0 0 14.612 14.612',
}) => 
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        width={width} 
        height={height} 
        viewBox={viewBox}
    >
        <path d="M13.7 1.827h-1.828V.913A.863.863 0 0 0 10.959 0h-.913a.863.863 0 0 0-.913.913v.913H5.48V.913A.863.863 0 0 0 4.566 0h-.913a.863.863 0 0 0-.913.913v.913H.913A.863.863 0 0 0 0 2.74V13.7a.863.863 0 0 0 .913.913H13.7a.863.863 0 0 0 .913-.913V2.74a.863.863 0 0 0-.913-.913zm-.913 10.959H1.827V6.393h10.959z" fill={fill} />
    </svg>;

export const StoreMallIcon = ({
    fill = '#5e72e4',
    width = '25',
    className = 'store-mall-icon',
    height = '25',
    viewBox = '0 0 25 25',
}) => 
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        width={width} 
        height={height} 
        viewBox={viewBox}
    >
        <path d="M19.767 8H6.81v1.62h12.957zm.81 8.1v-1.62l-.81-4.049H6.81L6 14.479V16.1h.81v4.859h8.1V16.1h3.239v4.859h1.62V16.1zm-7.289 3.239H8.43V16.1h4.859z" fill={fill} />
    </svg>;

export const Settings = ({
    fill = '#ffffff',
    width = '25',
    className = 'settings-icon',
    height = '25',
    viewBox = '0 0 25 25',
}) => 
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        width={width} 
        height={height} 
        viewBox={viewBox}
    >
        <path d="M19.767 8H6.81v1.62h12.957zm.81 8.1v-1.62l-.81-4.049H6.81L6 14.479V16.1h.81v4.859h8.1V16.1h3.239v4.859h1.62V16.1zm-7.289 3.239H8.43V16.1h4.859z" fill={fill} />
    </svg>;
