/*!
 * Black Dashboard PRO React - v1.0.0
 * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
import classnames from 'classnames';
import { Input, InputGroupAddon, InputGroupText, InputGroup, Row, FormGroup, Col } from 'reactstrap';
import states from '../../../us_states';
// import markets from '../../../markets';
import Select from 'react-select';
import * as shipment_urls from '../../../urls/api-shipments/admin';
import axios from 'axios';

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      dob: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      carrying_capacity: '',
      usernameState: '',
      firstnameState: '',
      lastnameState: '',
      emailState: '',
      phoneState: '',
      dobState: '',
      addressState: '',
      address2State: '',
      cityState: '',
      stateState: 'has-danger',
      marketState: 'has-danger',
      zipState: '',
      carrying_capacityState: '',
      market: '',
      markets: []
    };
  }

  fetchMarkets() {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        query: `{
        Markets(where: {deleted_at: {_is_null: true}}) {
          market_name
          market_id
        }
      }`
      }
    })
      .then(res => {
        let optionsBack = res.data.data.Markets.map(d => ({
          val: d.market_id,
          label: d.market_name
        }));
        this.setState({ markets: optionsBack });
      })
      .catch(error => {
        throw error;
      });
  }

  componentDidMount() {
    this.fetchMarkets();
  }

  verifyUsername = value => {
    var usernameRex = /^[0-9A-Za-z_.-]+$/;
    if (usernameRex.test(value)) {
      return true;
    }
    return false;
  };

  // function that returns true if value is email, false otherwise
  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  verifyPhone = value => {
    var phoneRex = /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{10}))/;
    if (phoneRex.test(value)) {
      if (value.replace(/[^0-9]/g, '').length > 9 && value.replace(/[^0-9]/g, '').length < 14) {
        return true;
      }
    }
    return false;
  };

  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  // function that verifies if value contains only numbers
  verifyNumber = value => {
    var numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      case 'username':
        if (this.verifyUsername(event.target.value)) {
          this.setState({ [stateName + 'State']: 'has-success' });
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' });
        }
        break;
      default:
        break;
    }
    switch (stateName) {
      case 'username':
        if (this.state.username.length > 15) {
          this.setState({ usernameState: 'too-long' });
        }
    }
    // switch(stateName) {
    //   case "lastname":
    //     if(this.state.username.length <= 15) {
    //       this.setState({username: this.state.firstname.toLowerCase() + "." + event.target.value.toLowerCase(), usernameState: "has-success"});
    //     }
    //     else {
    //       this.setState({usernameState: "too-long"});
    //     }
    //     break;
    //   case "firstname":
    //     if(this.state.username.length <= 15) {
    //       this.setState({username: this.state.firstname.toLowerCase() + "." + event.target.value.toLowerCase(), usernameState: "has-success"});
    //     }
    //     else {
    //       this.setState({usernameState: "too-long"});
    //     }
    //     break;
    // }
    this.setState({ [stateName]: event.target.value });
  };

  isValidated() {
    if (
      this.state.firstnameState === 'has-success' &&
      this.state.lastnameState === 'has-success' &&
      this.state.emailState === 'has-success' &&
      this.state.phoneState === 'has-success' &&
      this.state.usernameState === 'has-success' &&
      this.state.addressState === 'has-success' &&
      this.state.cityState === 'has-success' &&
      this.state.stateState === 'has-success' &&
      this.state.marketState === 'has-success' &&
      this.state.zipState === 'has-success' &&
      this.state.dobState === 'has-success' &&
      this.state.carrying_capacityState === 'has-success'
    ) {
      return true;
    } else {
      if (this.state.firstnameState !== 'has-success') {
        this.setState({ firstnameState: 'has-danger' });
      }
      if (this.state.lastnameState !== 'has-success') {
        this.setState({ lastnameState: 'has-danger' });
      }
      if (this.state.emailState !== 'has-success') {
        this.setState({ emailState: 'has-danger' });
      }
      if (this.state.phoneState !== 'has-success') {
        this.setState({ phoneState: 'has-danger' });
      }
      if (this.state.usernameState !== 'has-success') {
        this.setState({ usernameState: 'has-danger' });
      }
      if (this.state.addressState !== 'has-success') {
        this.setState({ addressState: 'has-danger' });
      }
      if (this.state.cityState !== 'has-success') {
        this.setState({ cityState: 'has-danger' });
      }
      if (this.state.stateState !== 'has-success') {
        this.setState({ stateState: 'has-danger' });
      }
      if (this.state.marketState !== 'has-success') {
        this.setState({ marketState: 'has-danger' });
      }
      if (this.state.zipState !== 'has-success') {
        this.setState({ zipState: 'has-danger' });
      }
      if (this.state.carrying_capacityState !== 'has-success') {
        this.setState({ carrying_capacityState: 'has-danger' });
      }
      if (this.state.dobState !== 'has-success') {
        this.setState({ dobState: 'has-danger' });
      }
      return false;
    }
  }

  render() {
    return (
      <>
        <h4 className="info-text">User Profile Information</h4>
        <form autoComplete="off">
          <input autoComplete="off" hidden="true"></input>

          <Row className="justify-content-center mt-5">
            <Col sm="5">
              <label>First Name</label>
              <InputGroup
                className={classnames(this.state.firstnameState, {
                  'input-group-focus': this.state.firstnameFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="firstname"
                  type="text"
                  onChange={e => this.change(e, 'firstname', 'length', 1)}
                  onFocus={e => this.setState({ firstnameFocus: true })}
                  onBlur={e => this.setState({ firstnameFocus: false })}
                />
                {this.state.firstnameState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="5">
              <label>Last Name</label>
              <InputGroup
                className={classnames(this.state.lastnameState, {
                  'input-group-focus': this.state.lastnameFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="lastname"
                  type="text"
                  onChange={e => this.change(e, 'lastname', 'length', 1)}
                  onFocus={e => this.setState({ lastnameFocus: true })}
                  onBlur={e => this.setState({ lastnameFocus: false })}
                />
                {this.state.lastnameState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm="5">
              <label>Email</label>
              <InputGroup
                className={classnames(this.state.emailState, {
                  'input-group-focus': this.state.emailFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-email-85" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="email"
                  type="email"
                  onChange={e => this.change(e, 'email', 'email')}
                  onFocus={e => this.setState({ emailFocus: true })}
                  onBlur={e => this.setState({ emailFocus: false })}
                />
                {this.state.emailState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="5">
              <label>Primary Phone Number</label>
              <InputGroup
                className={classnames(this.state.phoneState, {
                  'input-group-focus': this.state.phoneFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-mobile" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="number"
                  type="tel"
                  placeholder="Ex: 1234567890"
                  onChange={e => this.change(e, 'phone', 'phone')}
                  onFocus={e => this.setState({ phoneFocus: true })}
                  onBlur={e => this.setState({ phoneFocus: false })}
                />
                {this.state.phoneState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm="5">
              <label>Date of Birth</label>
              <InputGroup
                className={classnames(this.state.dobState, {
                  'input-group-focus': this.state.dobFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="date"
                  name="dob"
                  placeholder="date placeholder"
                  onChange={e => this.change(e, 'dob', 'length', 1)}
                  onFocus={e => this.setState({ dobFocus: true })}
                  onBlur={e => this.setState({ dobFocus: false })}
                />
                {this.state.dobState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="5">
              <label>Carrying capacity</label>
              <InputGroup
                className={classnames(this.state.carrying_capacityState, {
                  'input-group-focus': this.state.carrying_capacityFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="carrying_capacity"
                  type="number"
                  value={this.state.carrying_capacity}
                  onChange={e => this.change(e, 'carrying_capacity', 'length', 1)}
                  onFocus={e => this.setState({ carrying_capacityFocus: true })}
                  onBlur={e => this.setState({ carrying_capacityFocus: false })}
                />
                {this.state.carrying_capacityState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm="10">
              <label>User Name (Max 15 chars)</label>
              <InputGroup
                className={classnames(this.state.usernameState, {
                  'input-group-focus': this.state.usernameFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="username"
                  type="text"
                  maxLength={15}
                  value={this.state.username}
                  onChange={e => this.change(e, 'username', 'username')}
                  onFocus={e => this.setState({ usernameFocus: true })}
                  onBlur={e => this.setState({ usernameFocus: false })}
                />
                {this.state.usernameState === 'has-danger' ? <label className="error">This field is required.</label> : null}
                {this.state.emailState === 'too-long' ? <label className="error">Too long, must be less than 15 characters.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="10">
              <label>Primary Address</label>
              <InputGroup
                className={classnames(this.state.addressState, {
                  'input-group-focus': this.state.addressFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-square-pin" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="address"
                  type="text"
                  onChange={e => this.change(e, 'address', 'length', 1)}
                  onFocus={e => this.setState({ addressFocus: true })}
                  onBlur={e => this.setState({ addressFocus: false })}
                />
                {this.state.addressState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="10">
              <InputGroup
                className={classnames(this.state.address2State, {
                  'input-group-focus': this.state.address2Focus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-square-pin" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="address2"
                  type="text"
                  placeholder="Address 2"
                  onChange={e => this.change(e, 'address2', 'length', 1)}
                  onFocus={e => this.setState({ address2Focus: true })}
                  onBlur={e => this.setState({ address2Focus: false })}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm="4">
              <label>City</label>
              <InputGroup
                className={classnames(this.state.cityState, {
                  'input-group-focus': this.state.cityFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-square-pin" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="city"
                  type="text"
                  onChange={e => this.change(e, 'city', 'length', 1)}
                  onFocus={e => this.setState({ cityFocus: true })}
                  onBlur={e => this.setState({ cityFocus: false })}
                />
                {this.state.cityState === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <label>State</label>
                <Select
                  className={classnames('react-select info', this.state.stateState, {
                    'input-group-focus': this.state.stateFocus
                  })}
                  classNamePrefix="react-select"
                  name="state"
                  onChange={value => this.setState({ state: value, stateState: 'has-success' })}
                  options={states}
                  placeholder="Select a state"
                />
              </FormGroup>
            </Col>
            <Col sm="3">
              <label>Zip Code</label>
              <InputGroup
                className={classnames(this.state.zipState, {
                  'input-group-focus': this.state.zipFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-square-pin" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="zip"
                  type="text"
                  onChange={e => this.change(e, 'zip', 'length', 1)}
                  onFocus={e => this.setState({ zipFocus: true })}
                  onBlur={e => this.setState({ zipFocus: false })}
                />
                {this.state.zipFocus === 'has-danger' ? <label className="error">This field is required.</label> : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm="10">
              <FormGroup>
                <label>Default Market</label>
                <Select
                  className={classnames('react-select info', this.state.marketState, {
                    'input-group-focus': this.state.stateFocus
                  })}
                  classNamePrefix="react-select"
                  name="market"
                  onChange={value => this.setState({ market: value, marketState: 'has-success' })}
                  // options={this.fetchMarkets}
                  options={this.state.markets}
                  placeholder="Select a default market"
                />
              </FormGroup>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

export default Wizard;
