import React from 'react';

export default function ForkLift(props) {
  return (
    <svg id="ForkLift" xmlns="http://www.w3.org/2000/svg" width="60.933" height="45.696" viewBox="0 0 809.933 50.696" fill={props.fill ? props.fill : '#5e72e4'}>
      <g transform="translate(-6.1 -250)" stroke={props.fill ? props.fill : '#5e72e4'} stroke-width="4px">
        <path
          d="M308.01,375.4c-33.8,0-61.3,27.5-61.3,61.3s27.5,61.3,61.3,61.3s61.3-27.5,61.3-61.3S341.81,375.4,308.01,375.4z
		 M308.01,476.46c-21.92,0-39.76-17.84-39.76-39.76s17.84-39.75,39.76-39.75s39.75,17.83,39.75,39.75S329.93,476.46,308.01,476.46z"
        />
        <path
          d="M758.72,429.91H414.47v-8.54c0-5.95-4.82-10.77-10.77-10.77c-5.95,0-10.77,4.82-10.77,10.77v19.31
		c0,5.95,4.82,10.77,10.77,10.77h355.01c5.95,0,10.77-4.82,10.77-10.77S764.67,429.91,758.72,429.91z"
        />
        <path
          d="M404.65,393.97h309.19c5.95,0,10.77-4.82,10.77-10.77c0-5.95-4.82-10.77-10.77-10.77H413.11V39.14
		c0-5.95-4.82-10.77-10.77-10.77h-70.05c-5.95,0-10.77,4.82-10.77,10.77V77.1c0,5.95,4.82,10.77,10.77,10.77s10.77-4.82,10.77-10.77
		V49.91h48.51v330.98C391.57,388.1,397.44,393.97,404.65,393.97z"
        />
        <path
          d="M173.02,271l29.13,42.7c2.01,2.94,5.34,4.7,8.9,4.7h134.72v22.37c0,5.95,4.82,10.77,10.77,10.77
		c5.95,0,10.77-4.82,10.77-10.77v-33.14c0-5.95-4.82-10.77-10.77-10.77h-41.91c0.13-1.42-0.02-2.89-0.5-4.34L247.5,91.07h20.1
		c5.95,0,10.77-4.82,10.77-10.77c0-5.95-4.82-10.77-10.77-10.77H108.66c-4.49,0-8.51,2.79-10.09,6.99L31.19,256.45
		c-0.76,2.03-0.85,4.14-0.41,6.11V430.4c0,5.95,4.82,10.77,10.77,10.77h37.98c1.9,0,3.65-0.53,5.2-1.39
		C86.34,472.14,113.1,498,145.86,498c33.8,0,61.3-27.5,61.3-61.3s-27.5-61.3-61.3-61.3c-28.78,0-52.93,19.96-59.48,46.75
		c-1.87-1.56-4.24-2.53-6.86-2.53h-27.2v-93.41h87.01c5.95,0,10.77-4.82,10.77-10.77c0-5.95-4.82-10.77-10.77-10.77H52.32V271
		H173.02z M145.86,396.95c21.92,0,39.75,17.83,39.75,39.75s-17.83,39.76-39.75,39.76s-39.76-17.84-39.76-39.76
		S123.94,396.95,145.86,396.95z M292.87,296.86h-76.14l-29.13-42.7c-2.01-2.94-5.34-4.7-8.9-4.7H150.1V91.07h74.71L292.87,296.86z
		 M116.12,91.07h12.43v158.39H56.81L116.12,91.07z"
        />
      </g>
    </svg>
  );
}

export function HandLoadUnload(props) {
  return (
    <svg id="HandLoadUnload" xmlns="http://www.w3.org/2000/svg" width="60.933" height="45.696" viewBox="0 0 709.933 50.696" fill={props.fill ? props.fill : '#5e72e4'}>
      <g transform="translate(-6.1 -250)" stroke={props.fill ? props.fill : '#5e72e4'} stroke-width="4px">
        <path
          d="M309.76,107.5c24.16,0,43.81-19.65,43.81-43.81s-19.65-43.81-43.81-43.81c-24.16,0-43.81,19.65-43.81,43.81
		S285.6,107.5,309.76,107.5z M309.76,38.34c13.98,0,25.35,11.37,25.35,25.35s-11.37,25.35-25.35,25.35s-25.35-11.37-25.35-25.35
		S295.78,38.34,309.76,38.34z"
        />
        <path
          d="M425.25,222.37h-32.31l-35.18-85.46c-1.42-3.46-4.79-5.71-8.53-5.71H291.9c-17.74,0-32.17,14.43-32.17,32.17v145.7
		c0,2.66,1.15,5.19,3.15,6.95l10.53,9.21l-17.32,162.57c-0.54,5.07,3.13,9.61,8.2,10.16c0.33,0.03,0.66,0.05,0.99,0.05
		c4.66,0,8.66-3.51,9.17-8.25l15.95-149.67l67.81,59.3l15.78,90.97c0.78,4.49,4.68,7.65,9.08,7.65c0.52,0,1.06-0.04,1.59-0.14
		c5.02-0.87,8.39-5.65,7.51-10.67L375.82,393c-0.36-2.08-1.42-3.98-3.02-5.37l-94.62-82.75V163.38c0-7.56,6.15-13.72,13.72-13.72
		h51.14l35.18,85.46c1.42,3.46,4.79,5.71,8.53,5.71h38.49c2.3,0,4.43,1.04,5.84,2.86c1.41,1.82,1.89,4.13,1.32,6.36
		c-0.84,3.27-3.78,5.56-7.16,5.56H372.3l-32.42-61.54c-2.38-4.51-7.96-6.23-12.47-3.86c-4.51,2.38-6.24,7.96-3.86,12.47l16.44,31.21
		v64.13c0,2.4,0.93,4.7,2.6,6.42l72.47,74.73v109.61c0,5.1,4.13,9.23,9.23,9.23s9.23-4.13,9.23-9.23V375.42c0-2.4-0.93-4.7-2.6-6.42
		l-72.47-74.73v-25.35l0.12,0.22c1.6,3.03,4.74,4.93,8.17,4.93h58.52c11.81,0,22.11-7.99,25.04-19.43c2-7.79,0.31-15.9-4.62-22.26
		C440.74,226.02,433.3,222.37,425.25,222.37z"
        />
        <path
          d="M320.49,408.53c-5.03-0.94-9.83,2.36-10.77,7.37l-13.34,71.18c-0.94,5.01,2.36,9.83,7.37,10.77
		c0.58,0.11,1.15,0.16,1.71,0.16c4.35,0,8.23-3.09,9.06-7.53l13.34-71.18C328.8,414.29,325.5,409.46,320.49,408.53z"
        />
        <path
          d="M534.74,126.26h-119.1c-5.1,0-9.23,4.13-9.23,9.23v51.8c0,5.1,4.13,9.23,9.23,9.23c5.1,0,9.23-4.13,9.23-9.23v-42.57
		h100.65v113.83h-48.45c-5.1,0-9.23,4.13-9.23,9.23c0,5.1,4.13,9.23,9.23,9.23h57.68c5.1,0,9.23-4.13,9.23-9.23V135.49
		C543.97,130.39,539.84,126.26,534.74,126.26z"
        />
      </g>
    </svg>
  );
}

export function Dolly(props) {
  return (
    <svg id="Dolly" xmlns="http://www.w3.org/2000/svg" width="60.933" height="45.696" viewBox="0 0 709.933 50.696" fill={props.fill ? props.fill : '#5e72e4'}>
      <g transform="translate(-6.1 -250)" stroke={props.fill ? props.fill : '#5e72e4'} stroke-width="4px">
        <path
          d="M510.45,395.08l-101.58,32.65c-4.77,1.53-7.39,6.64-5.86,11.41c1.24,3.85,4.8,6.3,8.63,6.3c0.92,0,1.86-0.14,2.78-0.44
		L516,412.34c4.77-1.53,7.39-6.64,5.86-11.41C520.33,396.16,515.2,393.53,510.45,395.08z"
        />
        <path
          d="M344.58,335.41L265.79,78.45c-0.96-3.14-3.54-5.51-6.75-6.21l-39.36-8.53c-4.89-1.07-9.72,2.05-10.78,6.94
		c-1.06,4.89,2.05,9.72,6.94,10.78L250,88.85l77.22,251.82l13.05,43.82c-6.07-3.13-12.68-5.29-19.65-6.22
		c-15.92-2.12-31.71,2.09-44.46,11.86c-26.33,20.15-31.35,57.96-11.2,84.29c9.76,12.75,23.91,20.94,39.82,23.05
		c2.69,0.36,5.37,0.53,8.04,0.53c13.13,0,25.83-4.28,36.42-12.39c12.75-9.76,20.94-23.91,23.05-39.83
		c1.4-10.56-0.04-21.05-3.98-30.64L344.58,335.41z M354.32,443.39c-1.48,11.12-7.19,20.99-16.1,27.81
		c-8.9,6.82-19.94,9.76-31.05,8.28c-11.12-1.48-20.99-7.2-27.81-16.1c-6.82-8.91-9.76-19.93-8.28-31.05
		c1.48-11.12,7.19-20.99,16.1-27.81c7.4-5.66,16.26-8.65,25.43-8.65c1.86,0,3.74,0.12,5.62,0.37c11.11,1.48,20.99,7.2,27.81,16.1
		c2.12,2.77,3.83,5.77,5.18,8.89l0.95,3.2c0.23,0.76,0.57,1.45,0.96,2.1C354.65,431.95,355.08,437.66,354.32,443.39z"
        />
        <path
          d="M590.94,334.92l-45.47-153.24c-0.68-2.31-2.26-4.25-4.37-5.39c-2.11-1.15-4.59-1.41-6.9-0.72l-64.75,19.22L437.19,86.13
		c-1.42-4.8-6.46-7.54-11.27-6.11l-113.68,33.73c-4.8,1.42-7.54,6.47-6.11,11.27l33.73,113.68c0.38,1.3,1.07,2.46,1.96,3.45
		l45.47,153.21c0.68,2.31,2.26,4.25,4.37,5.39c1.34,0.73,2.83,1.1,4.32,1.1c0.86,0,1.74-0.12,2.58-0.38l186.26-55.28
		C589.63,344.77,592.36,339.72,590.94,334.92z M326.1,128.55l96.29-28.58l28.58,96.29l-96.29,28.58L326.1,128.55z M402.1,381.5
		l-40.31-135.85l168.87-50.12l40.31,135.85L402.1,381.5z"
        />
      </g>
    </svg>
  );
}

export function Hazmat(props) {
  return (
    <svg id="Hazmat" xmlns="http://www.w3.org/2000/svg" width="60.933" height="45.696" viewBox="0 0 709.933 50.696" fill={props.fill ? props.fill : '#5e72e4'}>
      <g transform="translate(-6.1 -250)" stroke={props.fill ? props.fill : '#5e72e4'} stroke-width="4px">
        <path
          d="M546.07,103.96c-39.01-39.01-90.87-60.49-146.04-60.49S293,64.95,253.99,103.96c-3.51,3.51-3.51,9.19,0,12.7
		c3.51,3.51,9.19,3.51,12.7,0c35.62-35.62,82.97-55.23,133.34-55.23s97.72,19.62,133.34,55.23c35.62,35.62,55.23,82.97,55.23,133.34
		s-19.62,97.72-55.23,133.34c-35.62,35.62-82.97,55.23-133.34,55.23s-97.72-19.62-133.34-55.23
		c-57.08-57.09-71.54-144.09-35.96-216.5c2.19-4.45,0.35-9.83-4.1-12.02c-4.45-2.19-9.84-0.35-12.02,4.1
		c-38.96,79.31-23.13,174.6,39.39,237.12c39.01,39.01,90.87,60.49,146.04,60.49s107.03-21.48,146.04-60.49
		c39.01-39.01,60.49-90.87,60.49-146.04S585.07,142.97,546.07,103.96z"
        />
        <path
          d="M402.48,414.42c28.21,0,54.74-7.16,77.89-19.76l-55.92-96.77c-7.34,3.87-15.7,6.09-24.58,6.09
		c-8.95,0-17.38-2.25-24.76-6.18l-52.67,95.62C346.09,406.77,373.39,414.42,402.48,414.42z"
        />
        <path
          d="M239.28,251.23c0,0.14,0.01,0.28,0.01,0.42l107.92-2.47c0.64-16.65,8.96-31.28,21.55-40.51l-53.23-95.53
		C269.73,142.05,239.28,193.07,239.28,251.23z"
        />
        <path d="M452.53,249.08l113.1,0.26c-0.68-59.59-33.28-111.47-81.51-139.4l-57.1,96.1C441.76,214.92,451.79,230.78,452.53,249.08z" />
        <path
          d="M401.25,219.32c-17.62,0-31.9,14.28-31.9,31.9c0,17.62,14.28,31.9,31.9,31.9c17.62,0,31.9-14.28,31.9-31.9
		C433.16,233.61,418.87,219.32,401.25,219.32z"
        />
      </g>
    </svg>
  );
}

export function HotBag(props) {
  return (
    <svg id="HotBag" xmlns="http://www.w3.org/2000/svg" width="60.933" height="45.696" viewBox="0 0 809.933 50.696" fill={props.fill ? props.fill : '#5e72e4'}>
      <g transform="translate(-6.1 -250)" stroke={props.fill ? props.fill : '#5e72e4'} stroke-width="4px">
        <path
          d="M613.01,306.81c-1.23-4.38-5.39-7.14-9.76-6.77c-1.11-0.07-2.24,0.04-3.37,0.39l-47.66,15.08
		c-4.92,1.56-7.64,6.8-6.08,11.72c1.26,3.98,4.93,6.53,8.9,6.53c0.93,0,1.88-0.14,2.82-0.44l39.68-12.55l34.06,108.36H78.12
		l33.96-107.98l38.46,12.17c0.94,0.3,1.89,0.44,2.82,0.44c3.96,0,7.64-2.55,8.9-6.53c1.56-4.92-1.17-10.16-6.08-11.72l-42.86-13.56
		l-35.2-125.41h96.54v17.43c0,5.16,4.18,9.34,9.34,9.34c5.16,0,9.34-4.18,9.34-9.34v-17.43h280.35v17.43c0,5.16,4.18,9.34,9.34,9.34
		s9.34-4.18,9.34-9.34v-17.43h37.6c5.16,0,9.34-4.18,9.34-9.34c0-5.16-4.18-9.34-9.34-9.34h-40.64
		c-7.41-10.39-17.63-20.59-30.5-30.34c-65.57-49.66-154.89-54.05-227.57-11.15c-24.5,14.47-41.96,28.4-52.04,41.49H65.8
		c-2.92,0-5.68,1.37-7.44,3.7c-1.77,2.33-2.34,5.35-1.55,8.16l39.18,139.6L56.81,435.94c-0.79,2.82-0.22,5.84,1.55,8.16
		c1.77,2.33,4.52,3.7,7.44,3.7h578.11c2.92,0,5.68-1.37,7.44-3.7c1.77-2.33,2.34-5.35,1.55-8.16L613.01,306.81z M240.74,132.46
		c66.12-39.03,147.3-35.13,206.8,9.95c6.71,5.09,12.57,10.25,17.54,15.45H204.83C213.76,149.84,225.83,141.26,240.74,132.46z"
        />
        <path
          d="M587.34,186.82l1.86,3.05c5.29,8.58,6.02,27.25-20.51,67.46c-2.84,4.3-1.65,10.1,2.65,12.94c1.58,1.04,3.37,1.54,5.13,1.54
		c3.03,0,6.01-1.47,7.81-4.19c26.01-39.44,32.82-68.07,20.82-87.55l-1.82-2.98c-4.97-8.14-11.16-18.28-17.7-24.65
		c-7.27-7.07-18.24-17.76,4.91-85.89c1.66-4.88-0.96-10.19-5.84-11.85c-4.89-1.67-10.19,0.96-11.84,5.84
		c-22.54,66.32-17.16,88.83-0.26,105.28C577.4,170.55,582.91,179.57,587.34,186.82z"
        />
        <path
          d="M669.64,177.1c-4.98-8.15-11.16-18.29-17.7-24.66c-7.27-7.07-18.24-17.76,4.91-85.89c1.66-4.88-0.95-10.19-5.84-11.85
		c-4.88-1.67-10.19,0.96-11.85,5.84c-22.53,66.33-17.16,88.83-0.26,105.28c4.85,4.73,10.37,13.75,14.79,21.01l1.86,3.04
		c5.29,8.58,6.02,27.25-20.51,67.46c-2.84,4.3-1.65,10.1,2.65,12.94c1.58,1.04,3.37,1.54,5.13,1.54c3.03,0,6.01-1.47,7.81-4.19
		c26.01-39.43,32.82-68.07,20.82-87.55L669.64,177.1z"
        />
        <path
          d="M737.81,180.07l-1.83-2.99c-4.97-8.14-11.15-18.27-17.69-24.63c-7.27-7.07-18.24-17.76,4.91-85.89
		c1.66-4.88-0.96-10.19-5.84-11.85c-4.88-1.67-10.19,0.96-11.84,5.84c-22.54,66.32-17.16,88.83-0.26,105.28
		c4.85,4.72,10.35,13.74,14.78,20.98l1.87,3.06c5.29,8.58,6.02,27.24-20.51,67.46c-2.84,4.31-1.65,10.1,2.66,12.94
		c1.58,1.04,3.37,1.54,5.13,1.54c3.03,0,6.01-1.47,7.8-4.19C743,228.18,749.81,199.54,737.81,180.07z"
        />
      </g>
    </svg>
  );
}

export function Cooler(props) {
  return (
    <svg id="Cooler" xmlns="http://www.w3.org/2000/svg" width="60.933" height="45.696" viewBox="0 0 809.933 50.696" fill={props.fill ? props.fill : '#5e72e4'}>
      <g transform="translate(-6.1 -250)" stroke={props.fill ? props.fill : '#5e72e4'} stroke-width="4px">
        <path
          d="M668.55,108.97c0-31.21-25.39-56.6-56.59-56.6H184.97c-31.21,0-56.6,25.39-56.6,56.6V217.8c0,4.81,3.9,8.71,8.71,8.71
		c4.81,0,8.71-3.9,8.71-8.71v-21.51h18.31v242.63c0,4.81,3.9,8.71,8.71,8.71h454.37c4.81,0,8.71-3.9,8.71-8.71v-41.75
		c0-4.81-3.9-8.71-8.71-8.71s-8.71,3.9-8.71,8.71v33.04H181.52V196.29h436.95v147.5c0,4.81,3.9,8.71,8.71,8.71s8.71-3.9,8.71-8.71
		v-147.5h17.71l0.61,21.75c0.13,4.73,4,8.46,8.7,8.46c0.08,0,0.17,0,0.25,0c4.81-0.14,8.6-4.14,8.46-8.95L668.55,108.97z
		 M618.47,178.87H181.52v-24.05c0-6.15,5-11.15,11.15-11.15h414.66c6.14,0,11.15,5,11.15,11.15V178.87z M635.89,178.87v-24.05
		c0-15.75-12.81-28.57-28.56-28.57H192.67c-15.75,0-28.56,12.82-28.56,28.57v24.05h-18.31v-69.9c0-21.6,17.58-39.18,39.18-39.18
		h426.99c21.6,0,39.18,17.58,39.18,39.42l1.97,69.66H635.89z"
        />
        <path
          d="M485.13,280.31c-2.04-2.04-5.35-2.04-7.39,0l-26.77,26.77c-0.01,0-0.01,0-0.02,0h-36.85l17.22-17.22h20.37
		c2.89,0,5.23-2.34,5.23-5.23c0-2.88-2.34-5.23-5.23-5.23H435.7v-15.98c0-2.88-2.34-5.23-5.23-5.23s-5.23,2.34-5.23,5.23v18.09
		c-0.39,0.22-0.79,0.41-1.12,0.74l-17.82,17.81v-34.2c0-0.99-0.29-1.9-0.76-2.68l27.53-27.54c2.04-2.04,2.04-5.35,0-7.39
		c-2.04-2.04-5.35-2.04-7.39,0l-20.48,20.48v-16.78c0-2.88-2.34-5.23-5.23-5.23s-5.23,2.34-5.23,5.23v16.78l-20.48-20.48
		c-2.04-2.04-5.35-2.04-7.39,0c-2.04,2.04-2.04,5.35,0,7.39L396,264.75c-0.08,0.36-0.13,0.74-0.13,1.13v36.38l-21.12-21.12v-17.71
		c0-2.88-2.34-5.23-5.23-5.23s-5.23,2.34-5.23,5.23v15.98h-15.98c-2.89,0-5.23,2.34-5.23,5.23c0,2.89,2.34,5.23,5.23,5.23h20.37
		l17.22,17.22h-33.42c-0.99,0-1.9,0.29-2.68,0.76l-27.54-27.53c-2.04-2.04-5.35-2.04-7.39,0c-2.04,2.04-2.04,5.35,0,7.39
		l20.48,20.47h-16.78c-2.89,0-5.23,2.34-5.23,5.23c0,2.88,2.34,5.23,5.23,5.23h16.78l-20.48,20.48c-2.04,2.04-2.04,5.35,0,7.39
		c1.02,1.02,2.36,1.53,3.69,1.53s2.67-0.51,3.69-1.53l29.09-29.09c0.37,0.08,0.74,0.13,1.13,0.13h36.38l-22.83,22.83h-17.71
		c-2.89,0-5.23,2.34-5.23,5.23c0,2.88,2.34,5.23,5.23,5.23h15.98v15.98c0,2.88,2.34,5.23,5.23,5.23s5.23-2.34,5.23-5.23v-20.36
		l21.12-21.12v36.87l-28.96,28.96c-2.04,2.04-2.04,5.35,0,7.39c2.04,2.04,5.35,2.04,7.39,0l20.48-20.47v16.78
		c0,2.88,2.34,5.23,5.23,5.23s5.23-2.34,5.23-5.23v-16.78l20.48,20.47c1.02,1.02,2.36,1.53,3.69,1.53c1.34,0,2.67-0.51,3.69-1.53
		c2.04-2.04,2.04-5.35,0-7.39l-26.77-26.77c0-0.01,0-0.02,0-0.02v-36.85l18.93,18.93v20.36c0,2.88,2.34,5.23,5.23,5.23
		s5.23-2.34,5.23-5.23v-15.98h15.98c2.89,0,5.23-2.34,5.23-5.23c0-2.89-2.34-5.23-5.23-5.23h-18.09c-0.22-0.39-0.41-0.79-0.74-1.12
		l-21.71-21.71h37.64l28.96,28.96c1.02,1.02,2.36,1.53,3.69,1.53s2.67-0.51,3.69-1.53c2.04-2.04,2.04-5.35,0-7.39l-20.48-20.48
		h16.78c2.89,0,5.23-2.34,5.23-5.23c0-2.89-2.34-5.23-5.23-5.23h-16.78l20.48-20.47C487.17,285.65,487.17,282.35,485.13,280.31z"
        />
      </g>
    </svg>
  );
}

export function Bike(props) {
  return (
    <svg id="Bike" xmlns="http://www.w3.org/2000/svg" width="60.933" height="45.696" viewBox="0 0 709.933 50.696" fill={props.fill ? props.fill : '#5e72e4'}>
      <g transform="translate(-6.1 -250)" stroke={props.fill ? props.fill : '#5e72e4'} stroke-width="4px">
        <path
          d="M267.63,281.21l13.22-36.18l91.3,116.81c1.46,1.87,3.64,2.85,5.85,2.85c1.6,0,3.2-0.51,4.56-1.57
		c3.23-2.52,3.8-7.18,1.28-10.4l-96.19-123.07c-0.26-0.33-0.55-0.61-0.84-0.88l20.17-55.21h54.72v18.43c0,4.09,3.32,7.41,7.41,7.41
		c4.09,0,7.41-3.32,7.41-7.41v-25.84c0-4.09-3.32-7.41-7.41-7.41h-67.31c-3.11,0-5.89,1.95-6.96,4.87l-41.21,112.8
		c-9.38-2.54-19.24-3.92-29.41-3.92c-62.13,0-112.69,50.55-112.69,112.69c0,62.14,50.55,112.69,112.69,112.69
		c62.14,0,112.69-50.55,112.69-112.69C336.88,338.43,308.26,298.25,267.63,281.21z M224.19,483.04c-53.96,0-97.86-43.9-97.86-97.87
		c0-53.96,43.9-97.86,97.86-97.86c8.39,0,16.54,1.06,24.31,3.06l-26.84,73.47c-10.67,1.26-18.96,10.32-18.96,21.33
		c0,11.87,9.62,21.49,21.49,21.49c11.87,0,21.49-9.62,21.49-21.49c0-7.44-3.78-13.99-9.52-17.85l26.37-72.18
		c34.96,14.95,59.52,49.67,59.52,90.03C322.05,439.14,278.15,483.04,224.19,483.04z"
        />
        <path
          d="M575.81,272.63c-29.47,0-56.32,11.38-76.42,29.96l-14.97-13.93l36.46-47.05h17.84v14.49c0,4.09,3.32,7.41,7.41,7.41
		s7.41-3.32,7.41-7.41v-21.9c0-4.09-3.32-7.41-7.41-7.41h-71.18c-4.09,0-7.41,3.32-7.41,7.41s3.32,7.41,7.41,7.41h27.17
		l-30.26,39.04L328.24,219.4c-3.76-1.61-8.12,0.14-9.72,3.91c-1.6,3.76,0.14,8.12,3.91,9.72l140.06,59.74l-48.63,62.75
		c-2.51,3.23-1.92,7.89,1.32,10.4c1.35,1.05,2.95,1.55,4.53,1.55c2.21,0,4.4-0.99,5.86-2.87l49.73-64.17l13.88,12.91
		c-16.26,19.54-26.06,44.63-26.06,71.98c0,3.56,0.19,7.08,0.51,10.55l-36.96,5.57c-5.88-12.74-18.74-21.62-33.66-21.62
		c-20.45,0-37.08,16.64-37.08,37.08c0,20.45,16.63,37.08,37.08,37.08c20.45,0,37.08-16.64,37.08-37.08c0-0.33-0.04-0.65-0.05-0.98
		L466,410.5c11.47,50.05,56.34,87.5,109.81,87.5c62.14,0,112.69-50.55,112.69-112.69S637.95,272.63,575.81,272.63z M393.01,439.15
		c-12.27,0-22.25-9.99-22.25-22.26c0-12.27,9.98-22.26,22.25-22.26c12.27,0,22.26,9.99,22.26,22.26
		C415.27,429.17,405.29,439.15,393.01,439.15z M477.95,385.31c0-23.28,8.18-44.68,21.81-61.49l55.77,58.21l-77.2,11.63
		C478.09,390.9,477.95,388.13,477.95,385.31z M575.81,483.18c-46.05,0-84.75-31.98-95.11-74.89l76.59-11.53
		c3.32,7.58,10.88,12.88,19.69,12.88c11.87,0,21.49-9.62,21.49-21.49c0-11.87-9.62-21.49-21.49-21.49c-4.48,0-8.64,1.38-12.09,3.72
		l-54.96-57.36c17.4-15.87,40.53-25.56,65.88-25.56c53.96,0,97.87,43.9,97.87,97.86S629.78,483.18,575.81,483.18z"
        />
      </g>
    </svg>
  );
}

export function TWIC(props) {
  return (
    <svg id="TWIC" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" width="60.933" height="50.696">
      <g transform="translate(-16 -25)" stroke={props.fill ? props.fill : '#5e72e4'} stroke-width="2px">
        <line x1="-0.09356" y1="-23.904569" x2="0.09356" y2="23.904569" transform="translate(31.568689 50)" fill="none" stroke="#5e72e4" stroke-width="2" />
        <line x1="-18.431311" y1="0" x2="18.431311" y2="0" transform="matrix(1.060913 0 0 1 50 26.656791)" fill="none" stroke="#5e72e4" stroke-width="2" />
        <line x1="0.233899" y1="-23.623889" x2="-0.233899" y2="23.623889" transform="translate(68.384532 50.185798)" fill="none" stroke="#5e72e4" stroke-width="2" />
        <line
          x1="-18.571651"
          y1="0.04678"
          x2="18.571651"
          y2="-0.04678"
          transform="matrix(1.030224 0.00259 0.002514-.999997 50 72.82863)"
          fill="none"
          stroke="#5e72e4"
          stroke-width="2"
        />
        <line x1="0" y1="-7.040386" x2="0" y2="7.040387" transform="translate(35.311087 42.959613)" fill="none" stroke="#5e72e4" />
        <line x1="-5.145798" y1="0" x2="5.145798" y2="0" transform="translate(40.456885 35.919227)" fill="none" stroke="#5e72e4" />
        <line x1="0" y1="-7.040386" x2="0" y2="7.040387" transform="translate(45.602683 42.959613)" fill="none" stroke="#5e72e4" />
        <line x1="5.145798" y1="0" x2="-5.145798" y2="0" transform="translate(40.456885 50)" fill="none" stroke="#5e72e4" />
        <text dx="0" dy="0" font-family='"eVCiufWI2E71:::Roboto"' font-size="5" font-weight="400" transform="translate(47.848125 38.094492)" fill="#5e72e4" stroke-width="2">
          <tspan y="0" font-size="10" font-weight="400" stroke-width="1">
            T
          </tspan>
          <tspan y="0" font-size="5" font-weight="400" stroke-width="1"></tspan>
        </text>
        <text dx="0" dy="0" font-family='"eVCiufWI2E71:::Roboto"' font-size="10" font-weight="400" transform="translate(50.654925 48.947447)" fill="#5e72e4" stroke-width="2">
          <tspan y="0" font-weight="400" stroke-width="1">
            W
          </tspan>
        </text>
        <text dx="0" dy="0" font-family='"eVCiufWI2E71:::Roboto"' font-size="10" font-weight="400" transform="translate(57.135506 60.642442)" fill="#5e72e4" stroke-width="2">
          <tspan y="0" font-weight="400" stroke-width="1">
            I
          </tspan>
        </text>
        <text dx="0" dy="0" font-family='"eVCiufWI2E71:::Roboto"' font-size="10" font-weight="400" transform="translate(58.981768 70.301189)" fill="#5e72e4" stroke-width="2">
          <tspan y="0" font-weight="400" stroke-width="1">
            C
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export function PalletJack(props) {
  return (
    <svg id="PalletJack" xmlns="http://www.w3.org/2000/svg" width="60.933" height="45.696" viewBox="0 0 709.933 50.696" fill={props.fill ? props.fill : '#5e72e4'}>
      <g transform="translate(-6.1 -250)" stroke={props.fill ? props.fill : '#5e72e4'} stroke-width="4px">
        <path
          d="M670.92,462.15h-362v-4.94c0-5.06-4.1-9.15-9.15-9.15c-5.06,0-9.15,4.1-9.15,9.15v14.1c0,5.06,4.1,9.15,9.15,9.15h371.16
		c5.06,0,9.15-4.1,9.15-9.15C680.07,466.25,675.98,462.15,670.92,462.15z"
        />
        <path
          d="M296.48,424.2l328.59-1.29c5.06-0.02,9.14-4.13,9.12-9.19c-0.02-5.04-4.11-9.12-9.15-9.12c-0.01,0-0.03,0-0.04,0
		l-319.4,1.25v-20.11c0-23.85-13.54-51.7-51.69-51.7h-45.77v-133.3l64.03-57.04c6.83-6.09,9.14-15.54,5.88-24.09
		s-11.27-14.07-20.42-14.07H141.8c-9.15,0-17.16,5.52-20.42,14.07s-0.95,18,5.88,24.09l62.56,55.73v181.44
		c-28.54,4.06-50.57,28.59-50.57,58.23c0,32.47,26.42,58.89,58.89,58.89s58.89-26.42,58.89-58.89c0-29.06-21.18-53.2-48.89-57.97
		v-28.79h45.77c30.13,0,33.39,23.35,33.39,33.39v29.3c0,2.43,0.97,4.77,2.69,6.49c1.72,1.71,4.04,2.67,6.46,2.67
		C296.45,424.2,296.47,424.2,296.48,424.2z M260.94,126.13c0.33,0.85,0.63,2.5-0.95,3.91l-51.85,46.19v-52.39h49.49
		C259.75,123.85,260.61,125.28,260.94,126.13z M139.44,130.04c-1.59-1.42-1.28-3.06-0.95-3.91c0.33-0.85,1.19-2.28,3.31-2.28h48.02
		v51.08L139.44,130.04z M238.72,439.11c0,22.38-18.2,40.58-40.58,40.58s-40.58-18.2-40.58-40.58s18.2-40.58,40.58-40.58
		S238.72,416.74,238.72,439.11z"
        />
      </g>
    </svg>
  );
}

export function Liftgate(props) {
  return (
    <svg
      id="Liftgate"
      xmlns="http://www.w3.org/2000/svg"
      width="60.933"
      height="45.696"
      viewBox="0 0 659.933 50.696"
      fill={props.fill ? props.fill : '#5e72e4'}
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
    >
      <g transform="translate(-90 -200)" stroke={props.fill ? props.fill : '#5e72e4'} stroke-width="4px">
        <path
          d="M575.4,380.05c-32.52,0-58.98,26.46-58.98,58.98s26.46,58.97,58.98,58.97s58.98-26.46,58.98-58.98-26.46-58.97-58.98-58.97Zm0,101.81c-23.62,0-42.84-19.22-42.84-42.84s19.22-42.84,42.84-42.84s42.84,19.22,42.84,42.84-19.22,42.84-42.84,42.84Z"
          transform="matrix(.792927 0 0 0.816094-111.543356 16.457539)"
          stroke-width="15"
        />
        <path
          d="M575.4,380.05c-32.52,0-58.98,26.46-58.98,58.98s26.46,58.97,58.98,58.97s58.98-26.46,58.98-58.98-26.46-58.97-58.98-58.97Zm0,101.81c-23.62,0-42.84-19.22-42.84-42.84s19.22-42.84,42.84-42.84s42.84,19.22,42.84,42.84-19.22,42.84-42.84,42.84Z"
          transform="matrix(.792927 0 0 0.816094-224.384427 16.457539)"
          stroke-width="15"
        />
        <path
          d="M790.93,396.34h-60.39v-138.85c0-4.46-3.61-8.07-8.07-8.07s-8.07,3.61-8.07,8.07v175.87c0,1.79-1.45,3.24-3.24,3.24h-46.66c-4.46,0-8.07,3.61-8.07,8.07s3.61,8.07,8.07,8.07h46.66c10.69,0,19.38-8.7,19.38-19.38v-20.88h60.39c4.46,0,8.07-3.61,8.07-8.07c0-4.45-3.61-8.07-8.07-8.07Z"
          transform="translate(-250.083736-67.051302)"
        />
        <line
          x1="-120.424762"
          y1="0"
          x2="152.424762"
          y2="0"
          transform="translate(319.70684 96.301337)"
          fill="none"
          stroke={props.fill ? props.fill : '#5e72e4'}
          stroke-width="17"
          stroke-linecap="round"
        />
        <line
          x1="-12.424762"
          y1="0"
          x2="52.424762"
          y2="0"
          transform="matrix(0 1-1 0 472.131602 108.726099)"
          fill="none"
          stroke={props.fill ? props.fill : '#5e72e4'}
          stroke-width="17"
          stroke-linecap="round"
        />
        <line
          x1="-52.424762"
          y1="0"
          x2="152.424762"
          y2="0"
          transform="matrix(.999843 0.017731-.017731 0.999843 530.047785 338.727686)"
          fill="none"
          stroke={props.fill ? props.fill : '#5e72e4'}
          stroke-width="20"
          stroke-linecap="round"
        />
      </g>
    </svg>
  );
}

export function Umbrella(props) {
  return (
    <svg id="Umbrella" xmlns="http://www.w3.org/2000/svg" width="60.933" height="45.696" viewBox="0 0 709.933 50.696" fill={props.fill ? props.fill : '#5e72e4'}>
      <g transform="translate(-6.1 -250)" stroke={props.fill ? props.fill : '#5e72e4'} stroke-width="4px">
        <path
          d="M561.68,138.2c-3.1-3.65-8.56-4.1-12.21-1.01c-3.65,3.1-4.1,8.56-1.01,12.21c16.42,19.36,28.76,41.66,36.52,65.49
		c-14.36-12.92-33.36-20.79-54.15-20.79c-22.42,0-43.38,9.26-58.4,24.86c-4.48-76.19-27.57-116.37-45.84-136.55
		c8.74,1.19,17.38,2.98,25.85,5.35c4.62,1.28,9.39-1.41,10.68-6.01c1.29-4.61-1.41-9.39-6.02-10.68c-15.74-4.39-32-6.93-48.44-7.59
		v-11.3c0-4.79-3.88-8.66-8.66-8.66c-4.79,0-8.66,3.88-8.66,8.66v11.3c-112.83,4.56-203.22,97.76-203.22,211.68
		c0,4.79,3.88,8.66,8.66,8.66s8.67-3.88,8.67-8.66c0-35.14,28.59-63.72,63.72-63.72c23.82,0,45.35,13.06,56.42,34.13
		c1.22,3.31,4.39,5.67,8.12,5.67c3.73,0,6.9-2.36,8.12-5.67c9.98-19.01,28.49-31.5,49.5-33.76v195.48
		c0,17.58-14.31,31.89-31.89,31.89c-17.58,0-31.89-14.31-31.89-31.89v-3.54c0-4.79-3.88-8.66-8.66-8.66c-4.79,0-8.67,3.88-8.67,8.66
		v3.54c0,27.14,22.08,49.22,49.22,49.22c27.14,0,49.22-22.08,49.22-49.22V212.29c20.95,3.47,39.1,17.27,47.89,37.12
		c0.01,0.03,0.03,0.05,0.04,0.08c0.01,0.02,0.01,0.03,0.02,0.05c0,0.01,0.01,0.01,0.01,0.02c0.15,0.35,0.34,0.68,0.53,1.01
		c0.03,0.05,0.06,0.11,0.1,0.16c0.2,0.32,0.43,0.62,0.67,0.91c0.04,0.04,0.08,0.08,0.12,0.13c0.21,0.24,0.44,0.47,0.68,0.69
		c0.06,0.05,0.12,0.11,0.18,0.16c0.28,0.24,0.57,0.47,0.88,0.67c0.04,0.02,0.08,0.05,0.12,0.07c0.28,0.18,0.58,0.34,0.89,0.49
		c0.06,0.03,0.12,0.06,0.19,0.09c0.35,0.16,0.7,0.3,1.07,0.41c0.02,0.01,0.04,0.01,0.07,0.02c0.34,0.1,0.68,0.17,1.03,0.23
		c0.09,0.01,0.18,0.03,0.27,0.04c0.38,0.05,0.76,0.08,1.14,0.08c0.39,0,0.77-0.03,1.14-0.08c0.09-0.01,0.18-0.03,0.27-0.04
		c0.35-0.06,0.69-0.13,1.02-0.23c0.03-0.01,0.05-0.01,0.08-0.02c0.36-0.11,0.71-0.25,1.06-0.4c0.07-0.03,0.14-0.07,0.21-0.1
		c0.29-0.14,0.58-0.3,0.86-0.48c0.05-0.03,0.09-0.06,0.14-0.09c0.3-0.2,0.59-0.42,0.86-0.66c0.07-0.06,0.14-0.12,0.2-0.18
		c0.23-0.21,0.44-0.42,0.64-0.65c0.05-0.05,0.1-0.11,0.15-0.16c0.23-0.28,0.45-0.57,0.65-0.88c0.04-0.07,0.09-0.14,0.13-0.21
		c0.18-0.29,0.34-0.59,0.48-0.91c0.01-0.03,0.03-0.06,0.05-0.09c10.17-23.15,33.08-38.11,58.35-38.11
		c35.14,0,63.72,28.59,63.72,63.72c0,4.79,3.88,8.66,8.66,8.66s8.67-3.88,8.67-8.66C611.88,225.02,594.05,176.38,561.68,138.2z
		 M269.17,194.09c-20.81,0-39.81,7.88-54.18,20.82c22.57-69.16,82.92-121.4,156.56-132.24c-18.71,19.94-41.54,59.34-45.79,134.44
		C310.88,202.56,290.78,194.09,269.17,194.09z M398.25,194.09c-20.85,0-40.33,7.87-55.03,21.51c6.17-96.64,44.9-126.87,56.51-133.81
		c11.38,7.59,48.83,39.65,55.21,135.44C440.06,202.68,419.84,194.09,398.25,194.09z"
        />
        <path
          d="M503.35,141.66L503.35,141.66c17.16,0,29.16-16.97,23.44-33.15L507.13,58.8c-1.35-3.42-6.2-3.42-7.55,0l-19.66,49.71
		C474.19,124.69,486.19,141.66,503.35,141.66z"
        />
      </g>
    </svg>
  );
}

export function Medical(props) {
  return (
    <svg id="Medical" xmlns="http://www.w3.org/2000/svg" width="60.933" height="45.696" viewBox="0 0 709.933 50.696" fill={props.fill ? props.fill : '#5e72e4'}>
      <g transform="translate(-6.1 -250)" stroke={props.fill ? props.fill : '#5e72e4'} stroke-width="4px">
        <path
          d="M602.57,289.76L533.71,250l68.86-39.76c2.08-1.2,3.6-3.18,4.22-5.5c0.62-2.32,0.29-4.79-0.91-6.88l-57.8-100.1
		c-1.2-2.08-3.18-3.6-5.5-4.22c-2.32-0.62-4.79-0.3-6.88,0.91l-68.86,39.76V54.69c0-5-4.06-9.06-9.06-9.06H342.2
		c-5,0-9.06,4.06-9.06,9.06v79.51l-68.86-39.76c-2.08-1.2-4.55-1.52-6.88-0.91c-2.32,0.62-4.3,2.14-5.5,4.22l-57.8,100.1
		c-1.2,2.08-1.53,4.55-0.91,6.88c0.62,2.32,2.14,4.3,4.22,5.5L266.29,250l-68.86,39.76c-2.08,1.2-3.6,3.18-4.22,5.5
		c-0.62,2.32-0.29,4.79,0.91,6.88l57.8,100.1c1.2,2.08,3.18,3.6,5.5,4.22c2.32,0.62,4.79,0.3,6.88-0.91l68.86-39.76v79.51
		c0,5,4.06,9.06,9.06,9.06H457.8c5,0,9.06-4.06,9.06-9.06v-79.51l68.86,39.76c2.08,1.2,4.55,1.53,6.88,0.91
		c2.32-0.62,4.3-2.14,5.5-4.22l57.8-100.1c1.2-2.08,1.53-4.55,0.91-6.88C606.17,292.94,604.65,290.96,602.57,289.76z M536.93,385.33
		l-74.6-43.07c-2.8-1.62-6.26-1.62-9.06,0c-2.8,1.62-4.53,4.61-4.53,7.85v86.14h-97.47V350.1c0-3.24-1.73-6.23-4.53-7.85
		c-2.8-1.62-6.26-1.62-9.06,0l-74.6,43.07l-48.74-84.41l74.6-43.07c2.8-1.62,4.53-4.61,4.53-7.85s-1.73-6.23-4.53-7.85l-74.6-43.07
		l48.74-84.41l74.6,43.07c2.8,1.62,6.26,1.62,9.06,0c2.8-1.62,4.53-4.61,4.53-7.85V63.75h97.47v86.14c0,3.24,1.73,6.23,4.53,7.85
		c2.8,1.62,6.26,1.62,9.06,0l74.6-43.07l48.74,84.41l-74.6,43.07c-2.8,1.62-4.53,4.61-4.53,7.85s1.73,6.23,4.53,7.85l74.6,43.07
		L536.93,385.33z"
        />
        <path
          d="M398.98,150.35c8.81,0,15.94-7.14,15.94-15.94c0-8.8-7.14-15.94-15.94-15.94c-8.81,0-15.94,7.14-15.94,15.94
		C383.03,143.22,390.17,150.35,398.98,150.35z"
        />
        <path d="M375.41,206.9C375.51,207.36,375.56,207.53,375.41,206.9L375.41,206.9z" />
        <path
          d="M363.98,228.88c2.29,1.88,4.61,3.18,7.69,3.18c2.67,0,5.85-1.18,7.69-3.18c3.66-3.99,4.7-11.52,0-15.37
		c-0.61-0.5-1.2-1.02-1.75-1.58c-0.12-0.13-0.24-0.26-0.36-0.39c-0.29-0.52-0.7-1.04-0.97-1.54c-0.06-0.11-0.08-0.15-0.13-0.23
		c-0.42-1.39-0.7-2.77-1.05-4.17c0.16,0.65,0.26,1.04,0.32,1.31c-0.08-0.38-0.17-0.93-0.19-1.32c-0.02-0.79-0.01-1.57,0.03-2.35
		c0.01-0.17,0.03-0.35,0.04-0.52c0.15-0.63,0.23-1.3,0.38-1.91c0.19-0.78,0.42-1.56,0.67-2.33c0.08-0.23,0.16-0.45,0.24-0.68
		c0.74-1.24,1.29-2.62,2.08-3.84c0.13-0.2,0.26-0.39,0.4-0.58c0.38-0.4,0.72-0.88,1.08-1.25c0.48-0.51,0.98-1,1.51-1.47
		c0.17-0.15,0.24-0.23,0.35-0.32c0.01-0.01,0.01-0.01,0.03-0.02c1.65-1.08,3.28-2.13,5.05-3c0.27-0.13,0.55-0.26,0.83-0.39
		c0.9-0.31,1.79-0.65,2.71-0.93c0.74-0.23,1.52-0.35,2.27-0.54v78.85c0,5,4.06,9.06,9.06,9.06c5,0,9.06-4.06,9.06-9.06v-79.92
		c0.42,0.03,0.85,0.04,1.27,0.07c0.92,0.08,1.84,0.19,2.75,0.3c0.36,0.05,0.72,0.11,1.08,0.16c2.99,0.46,5.66,0.49,8.38-1.1
		c2.25-1.32,4.41-3.9,4.99-6.5c1.18-5.2-1.58-12.44-7.59-13.37c-16.05-2.5-33.17-2.64-47.73,5.61
		c-12.86,7.28-21.61,21.93-20.64,36.9C354.07,214.9,357.28,223.39,363.98,228.88z"
        />
        <path
          d="M423.69,245.77c-4.47,4.47-3.77,10.85,0,15.37c0.27,0.32,0.99,1.95,1.08,3.23c0.16,2.39-0.06,3.95-1.2,6.83
		c-1.51,3.78-4.71,6.89-8.8,9.33c-11.01,6.58-23.59,9.84-34.03,17.5c-11.46,8.4-20.95,20.58-19.02,35.57
		c1.74,13.55,12.15,25.92,26.62,25.63c1.74-0.03,3.26-0.4,4.56-1.02v15.35c0,5,4.06,9.06,9.06,9.06c5,0,9.06-4.06,9.06-9.06v-42.91
		c0-5-4.06-9.06-9.06-9.06c-5,0-9.06,4.06-9.06,9.06v7.67c-1.31-0.56-2.83-0.87-4.56-0.83c-0.51-0.02-0.78-0.02-0.94-0.01
		c-0.16-0.04-0.25-0.06-0.31-0.07c-0.42-0.28-0.91-0.6-1.07-0.68c-0.36-0.4-0.85-0.95-0.98-1.06c-0.52-0.97-1.55-3.12-1.14-2
		c-1-2.8-0.89-2.39-0.76-4.9c-0.09,1.72,0.37-1.6,0.56-2.14c0.28-0.79,0.72-1.67,1.39-2.76c5.3-8.73,16.93-13.57,26.45-17.7
		c11.56-5.02,23.22-10.63,29.77-22.01c7.13-12.39,7.06-27.26-2.23-38.4C435.3,241.26,427.57,241.88,423.69,245.77z"
        />
      </g>
    </svg>
  );
}
