import React from 'react';
import { Spinner } from 'reactstrap';

const Loading = props => {
  return (
    <div className="loading-spinner" display="flex" flex-direction="column" hidden={props ? props.hidden : false}>
      <div className="loading-text">
        <h2>Loading</h2>
      </div>
      {/* changed from dark to white Harry 05/07/2021 */}
      <Spinner type="grow" color="white" />
      <Spinner type="grow" color="white" />
      <Spinner type="grow" color="white" />
      <Spinner type="grow" color="white" />
      <Spinner type="grow" color="white" />
    </div>
  );
};

export default Loading;
