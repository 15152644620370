/*!
 * Black Dashboard PRO React - v1.0.0
 * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import NotificationAlert from 'react-notification-alert';

import jwtDecode from 'jwt-decode';
import { ApolloClient } from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { ApolloLink } from 'apollo-link';
import getRoles from '../../Auth/getRoles';

import { adminRoutes, merchantRoutes, pickerRoutes, inventoryManagerRoutes } from 'routes.js';
import * as shipment_urls from '../../urls/api-shipments/admin';
import * as user_urls from '../../urls/api-users/admin';

import axios from 'axios';
import { onError } from 'apollo-link-error';

import AdminNavbar from 'components/Global/AdminNavbar';
import Footer from 'components/Global/Footer';
import Sidebar from 'components/Global/Sidebar';
// import MarketsSelection from './MarketsSelection';

var ps;
class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeColor: 'red',
      sidebarMini: true,
      opacity: 0,
      role: null,
      routes: null,
      pictureUrl: '',
      hideSidebar: true,
      sidebarOpened: false
    };

    this.httpLink_ship = new HttpLink({
      uri: shipment_urls.hasuraHost,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token') }
    });

    this.wssLink_ship = new WebSocketLink({
      uri: shipment_urls.wssHasuraHost,
      options: {
        reconnect: true,
        timeout: 30000,
        connectionParams: () => ({
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          }
        })
      }
    });

    this.httpLink_user = new HttpLink({
      uri: user_urls.hasuraHost,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token') }
    });

    this.wssLink_user = new WebSocketLink({
      uri: user_urls.wssHasuraHost,
      options: {
        reconnect: true,
        timeout: 30000,
        connectionParams: () => ({
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          }
        })
      }
    });

    // this.link = split(
    //     ({ query }) => {
    //         const { kind, operation } = getMainDefinition(query);
    //         return kind === 'OperationDefinition' && operation === 'subscription';
    //     },
    //     //operation => operation.getContext().clientName === 'httpLink_user',
    //     this.wssLink_ship,
    //     this.httpLink_ship,
    //     this.wssLink_user,
    //     this.httpLink_user,
    //     onError(({ graphQLErrors, networkError }) => {
    //         console.log('error received');
    //         if (graphQLErrors) graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));

    //         if (networkError) console.log(`[Network error]: ${networkError}`);
    //     })
    // );

    // prettier-ignore
    this.link = ApolloLink.split(
            operation => operation.getContext().clientName === 'userClient',
            this.wssLink_user,
            this.wssLink_ship
        );
    this.shipmentsClient = new ApolloClient({
      link: this.link,
      cache: new InMemoryCache()
    });
  }

  initializeAxiosInterceptor = () => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        console.log(error);
        if (error.response.status === 401) {
          this.props.history.push('/logout');
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );
  };

  componentDidMount() {
    getRoles().then(val => {
      var sidebar = true;
      if (val === 'error') {
        this.props.history.push('/logout');
      } else {
        this.initializeAxiosInterceptor();
        var r = null;
        if (val[0] === 0 || val[0] === 100) {
          sidebar = false;
          r = adminRoutes;
        } else if (val[0] === 200 || val[0] === 220) {
          sidebar = false;
          r = merchantRoutes;
        } else if (val[0] === 230) {
          sidebar = false;
          r = inventoryManagerRoutes;
        } else if (val[0] === 240) {
          sidebar = false;
          r = pickerRoutes;
        } else if (val[0] > 240) {
          // ! may show something else later
          sidebar = false;
          r = pickerRoutes;
        }
        var id_token_decoded = jwtDecode(localStorage.getItem('id_token'));
        this.setState({ role: val[0], routes: r, hideSidebar: sidebar, pictureUrl: id_token_decoded.picture });
      }
    });
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(this.refs.mainPanel);
      let tables = document.querySelectorAll('.table-responsive');
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener('scroll', this.showNavbarButton);
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-off';
      document.documentElement.classList.remove('perfect-scrollbar-on');
    }
    window.removeEventListener('scroll', this.showNavbarButton);
  }

  componentDidUpdate(e) {
    if (e.location.pathname !== e.history.location.pathname) {
      if (navigator.platform.indexOf('Win') > -1) {
        let tables = document.querySelectorAll('.table-responsive');
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  showNavbarButton = () => {
    if (document.documentElement.scrollTop > 50 || document.scrollingElement.scrollTop > 50 || this.refs.mainPanel.scrollTop > 50) {
      this.setState({ opacity: 1 });
    } else if (document.documentElement.scrollTop <= 50 || document.scrollingElement.scrollTop <= 50 || this.refs.mainPanel.scrollTop <= 50) {
      this.setState({ opacity: 0 });
    }
  };

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return <Route path={prop.layout + prop.path} render={props => <prop.component {...props} role={this.state.role} />} />;
      } else {
        return null;
      }
    });
  };

  getActiveRoute = routes => {
    let activeRoute = 'Gophr Dashboard';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };

  handleMiniClick = () => {
    let notifyMessage = 'Sidebar mini ';
    if (document.body.classList.contains('sidebar-mini')) {
      this.setState({ sidebarMini: false });
      notifyMessage += 'deactivated...';
    } else {
      this.setState({ sidebarMini: true });
      notifyMessage += 'activated...';
    }
    let options = {};
    options = {
      place: 'tr',
      message: notifyMessage,
      type: 'primary',
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7
    };
    document.body.classList.toggle('sidebar-mini');
  };

  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened
    });
    document.documentElement.classList.toggle('nav-open');
  };

  closeSidebar = () => {
    this.setState({
      sidebarOpened: false
    });
    document.documentElement.classList.remove('nav-open');
  };

  render() {
    return (
      this.state.role !== null && (
        <ApolloProvider client={this.shipmentsClient}>
          <div className="wrapper">
            <div className="rna-container">
              <NotificationAlert ref="notificationAlert" />
            </div>
            {!this.state.hideSidebar && (
              <div>
                <div className="navbar-minimize-fixed" style={{ opacity: this.state.opacity }}>
                  <button className="minimize-sidebar btn btn-link btn-just-icon" onClick={this.handleMiniClick}>
                    <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" style={{ color: '#f15a29' }} />
                    <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" style={{ color: '#f15a29' }} />
                  </button>
                </div>
                <Sidebar {...this.props} routes={this.state.routes} activeColor={this.state.activeColor} closeSidebar={this.closeSidebar} />
              </div>
            )}
            {/* <div>
              <MarketsSelection />
            </div> */}
            <div className="main-panel" ref="mainPanel" data={this.state.activeColor}>
              <AdminNavbar
                {...this.props}
                handleMiniClick={this.handleMiniClick}
                brandText={this.getActiveRoute(this.state.routes)}
                sidebarOpened={this.state.sidebarOpened}
                toggleSidebar={this.toggleSidebar}
                hideSidebar={this.state.hideSidebar}
                pictureUrl={this.state.pictureUrl}
              />
              <Switch>{this.getRoutes(this.state.routes)}</Switch>
              <Footer />
            </div>
          </div>
        </ApolloProvider>
      )
    );
  }
}

export default Admin;
