// importing required contents

import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from "react-router-dom";
import styled from 'styled-components';
import axios from 'axios';
import { Alert, Modal, ModalHeader } from 'reactstrap';
import Loading from '../../Global/Loading';
import Loader from 'react-loader-spinner';
import Header from './Header';
import General from './General';
import RegularHours from './RegularHours';
import SpecialHours from './SpecialHours';
import { Breadcrumbs, Typography } from '@material-ui/core';

import * as inventory_urls from '../../../urls/api-inventory/admin';
import * as validators from '../../../helpers/validators';
// import Breadcrumb from '../../../BreadcrumbNav';

//function for style(not very important right now)
const Styles = styled.div`
    margin-top: -40px;
        .hours-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
`;
/*********************************************/

//Main function
const EditStoreContainer = props => {
    //hooks to return an object value pairs of URL parameter
    let params = useParams(); 
    //hooks to access history instance used to navigate
    let history = useHistory();

// declaring required fields
    const [loading, setLoading] = useState(true);
    const [activeRequest, setActiveRequest] = useState(false);
    const [storeId, setStoreId] = useState(params.store_id);

    const [error, setError] = useState("");

    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [address, setAddress] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [logo, setLogo] = useState(null);
    const [promotionalRate, setPromotionalRate] = useState(null);
    const [transactionRate, setTransactionRate] = useState(null);
    const [isEnabled, setIsEnabled] = useState(null);
    const [timezone, setTimezone] = useState(null);
    const [sellsAlcohol, setSellsAlcohol] = useState(null);
    const [permitType, setPermitType] = useState(null)


    const [nameState, setNameState] = useState(null);
    const [addressState, setAddressState] = useState(null);
    const [phoneState, setPhoneState] = useState(null);
    const [emailState, setEmailState] = useState(null);
    const [logoState, setLogoState] = useState(null);
    const [transactionRateState, setTransactionRateState] = useState(null);

    const [oldStoreTimes, setOldStoreTimes] = useState({
        "days": [
        {
            "day_of_week": 0,
            "open_time": null,
            "close_time": null
        },
        {
            "day_of_week": 1,
            "open_time": null,
            "close_time": null
        },
        {
            "day_of_week": 2,
            "open_time": null,
            "close_time": null
        },
        {
            "day_of_week": 3,
            "open_time": null,
            "close_time": null
        },
        {
            "day_of_week": 4,
            "open_time": null,
            "close_time": null
        },
        {
            "day_of_week": 5,
            "open_time": null,
            "close_time": null
        },
        {
            "day_of_week": 6,
            "open_time": null,
            "close_time": null
        }
    ]});
    const [newStoreTimes, setNewStoreTimes] = useState({
        "days": [
        {
            "day_of_week": 0,
            "open_time": null,
            "close_time": null
        },
        {
            "day_of_week": 1,
            "open_time": null,
            "close_time": null
        },
        {
            "day_of_week": 2,
            "open_time": null,
            "close_time": null
        },
        {
            "day_of_week": 3,
            "open_time": null,
            "close_time": null
        },
        {
            "day_of_week": 4,
            "open_time": null,
            "close_time": null
        },
        {
            "day_of_week": 5,
            "open_time": null,
            "close_time": null
        },
        {
            "day_of_week": 6,
            "open_time": null,
            "close_time": null
        }
    ]});
    // 
    useEffect(() => {
        // console.log("above axios inside useeffect")
        //making axios call to pull data
        axios({
            method: 'POST',
            url: inventory_urls.hasuraHost,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
            data: {
                query: `{Stores (where: {store_id: {_eq: "`+ storeId +`"}}) { 
                    StoreTimes {
                        day_of_week
                        open_time
                        close_time
                        is_closed
                    }
                    StoreOwnerships {
                        email
                    }
                    name
                    state
                    phone
                    logo
                    address_1
                    city
                    latitude
                    longitude
                    transaction_rate
                    promotional_rate
                    is_enabled
                    sells_alcohol
                    permit_type
                    zip
                    timezone
                    }}`
                }
        }).then(response => {
            // console.log("before hasura when catches response", response)
            // if hasura getting a response, storing those value in store.
            if (response.data.data) {
                // console.log("inside if hasura", response.data)
                // console.log("inside if hasura", response.data.data)
                let store = response.data.data.Stores[0];
                setName(store.name);
                setAddress(`${store.address_1} ${store.city} ${store.state} ${store.zip}`);
                setLatitude(store.latitude);
                setLongitude(store.longitude);
                setPhone(store.phone);
                setEmail(store.StoreOwnerships[0].email);
                setPromotionalRate(store.promotional_rate * 100);
                setTransactionRate(store.transaction_rate * 100);
                setIsEnabled(store.is_enabled);
                setSellsAlcohol(store.sells_alcohol);
                setLogo(store.logo);
                setSuccessStates();
                setTimezone(store.timezone);
                setPermitType(store.permit_type);
                
                // console.log("before inside if if")
                // console.log("Store: ", store.StoreTimes)
                // store.StoreTimes.length is giving a number of days that alredy contains store time
                // checking if they have atleast one time entered
                if (store.StoreTimes.length > 0) {
                    // console.log("inside if if")
                    // running a loop through store.StoreTimes using map to update time that is entered
                    store.StoreTimes.map(day => { //day is just a parameter used to iterate through days of week later
                        let updatedTimes = oldStoreTimes; // setting a value to updated times as oldStoreTimes
                        
                        // next line is putting a value in updatedTimes, looking through day_of_week, filling all required fields for that particular day_of_week
                        
                        updatedTimes.days[parseInt(day.day_of_week)] = {"day_of_week": day.day_of_week, "open_time": day.open_time, "close_time": day.close_time};
                        setOldStoreTimes(updatedTimes); // time is updated here as old store time
                        // console.log("updated times stored", updatedTimes)
                    });
                }
                // console.log("after if werwerif")
                
            }
            // if we do not get response from hasura
             else {
                console.log(response)
            }
            // no matter what, loading is false
            // console.log("above loading")
            setLoading(false);
        }).catch(error => {
            // Hasura doesn't return an error
        })
    }, []);

    //giving values to setNameState... using a function
    //to check if all the fields are being used
    const setSuccessStates = () => {
        setNameState("has-success");
        setAddressState("has-success");
        setEmailState("has-success");
        setPhoneState("has-success");
        setLogoState("has-success");
        setTransactionRateState("has-success");
    }

    // fetchRegularHours here uses 3 different parameter, which is called in render method with another component props.
    const fetchRegularHours = (dayOfWeek, openTime, closeTime, is_closed) => {
        let updatedTimes = newStoreTimes;
        // console.log("fetch regular hours open time", moment.utc().day(dayOfWeek).hour(openTime.split(':')[0]).minute(openTime.split(':')[1]).second(0).millisecond(0).tz('America/Chicago'));
        updatedTimes.days[dayOfWeek] = {
            "day_of_week": dayOfWeek,
            "open_time": openTime,
            "close_time": closeTime,
            "is_closed": false
        }
        setNewStoreTimes(updatedTimes);
    }

    // just like fetchregularhours, here fetchgeneralinfo is fetching information using props from another component
    const fetchGeneralInfo = (name, phone, address, email) => {
        setName(name);
        setPhone(phone);
        setEmail(email);
        setAddress(address);
    }

    // const createRegularHours = (day) => {
        // console.log(day.day_of_week);
        // console.log(day.open_time);
        // console.log(day.close_time);
    //     return axios({
    //         method: 'POST',
    //         url: inventory_urls.createRegularHours + storeId + "/hours/create",
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    //         },
    //         data: {
    //             "day_of_week": day.day_of_week, 
    //             "open_time": day.open_time, 
    //             "close_time": day.close_time
    //         }
    //     }).then(response => {
            // console.log(response);
    //     }).catch(error => {
            // console.log("Error: ", error.response);
    //     });
    // }

    // const editRegularHours = (day) => {
    //     let data = {
    //         "open_time": day.open_time,
    //         "close_time": day.close_time
    //     }
    //     return axios({
    //         method: `put`,
    //         url: inventory_urls.inventoryHost + "/merchant/stores/" + storeId + "/hours/edit/" + day.day_of_week,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    //         },
    //         data: data
    //     });
    // }

    // const deleteRegularHours = (day) => {
    //     return axios({
    //         method: 'DELETE',
    //         url: inventory_urls.inventoryHost + "/merchant/stores/" + storeId + "/hours/delete/" + day.day_of_week,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    //         }
    //     }).catch(error => {
            // console.log("error: ", error.response);
    //     })
    // }

    const createRegularHoursMultiDay = (arrayOfDays) => {
        axios({
            method: 'POST',
            url: inventory_urls.createRegularHours + storeId + "/hours/create",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
            data: arrayOfDays
            
        }).then(response => {
            console.log(response);
        }).catch(error => {
            console.log("Error in create: ", error.response);
        });
        console.log("after axios inside createregularhourmultiday")
    }

    const editRegularHoursMultiDay = (arrayOfDays) => {
        // console.log("inside editregularhourmultiday", arrayOfDays)
        axios({
            method: 'PUT',
            url: inventory_urls.editRegularHours + storeId + "/hours/edit",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
            data: arrayOfDays
            
        })
        .then(response => {
            console.log(response);
        }).catch(error => {
            console.log("Error in edit: ", error.response);
            // console.log(arrayOfDays)
        });
        console.log("after axios inside editregularhourmultiday")
    }

    const deleteRegularHoursMultiDay = (arrayOfDays) => {
        axios({
            method: 'PUT',
            url: inventory_urls.deleteRegularHours + storeId + "/hours/edit",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
            data: arrayOfDays
            
        })
        // console.log("after deleteregularhoursmultiday", arrayOfDays)
        .then(response => {
            console.log(response);
        }).catch(error => {
            console.log("Error in delete: ", error.response);
        });
        console.log("after axios inside deleteregularhourmultiday")
    }

    const onSave = async () => {
        let toBeCreated = {"days": []};
        let toBeEdited = {"days": []};
        let toBeDeleted = {"days": []};
        // console.log("old", oldStoreTimes)
        // console.log("new", newStoreTimes)
        console.log("onSave: ")
        
        newStoreTimes.days.map((day, i) => {
            // day.open_time = day.open_time;
            // day.close_time = day.close_time;
            // console.log("day.open_time", day.open_time.utc().format("HH:mm"));
            // console.log("day.close_time", day.close_time.utc().format("HH:mm"));


            console.log("inside map before first if", newStoreTimes.days[i])
            // let andrew = moment().utc().day(day.day_of_week).hour(oldStoreTimes.days[parseInt(day.day_of_week)].open_time.split(':')[0]).minute(oldStoreTimes.days[parseInt(day.day_of_week)].open_time.split(':')[1]).second(0).millisecond(0);
            // console.log("OOF", andrew);
            if ((day.open_time !== oldStoreTimes.days[parseInt(day.day_of_week)].open_time) && (day.close_time !== oldStoreTimes.days[parseInt(day.day_of_week)].close_time)) {
                if (newStoreTimes.days.day_of_week && (oldStoreTimes.days[parseInt(day.day_of_week)].open_time === null && oldStoreTimes.days[parseInt(day.day_of_week)].close_time === null)) {
                    toBeCreated.days.push({
                        "day_of_week": parseInt(day.day_of_week),
                        "open_time": day.open_time.utc().format("HH:mm"),
                        "close_time": day.close_time.utc().format("HH:mm"),
                        "is_closed": false
                    })
                } else if (!day.open_time && !day.close_time) {
                        toBeDeleted.days.push({
                        "day_of_week": parseInt(day.day_of_week),
                        "open_time": null,
                        "close_time": null,
                        "is_closed": true
                    })
                } else {
                    console.log("inside edit push else", parseInt(day.day_of_week))
                    toBeEdited.days.push({
                        "day_of_week": parseInt(day.day_of_week),
                        "open_time": day.open_time.utc().format("HH:mm"),
                        "close_time": day.close_time.utc().format("HH:mm"),
                        "is_closed": false
                    })
                }
            }
        });
        console.log("beforetobecreated.length: ", toBeCreated.days)
        if (toBeCreated.days.length > 0) {
            // console.log("tobecreated.length > 0: ")
            createRegularHoursMultiDay(toBeCreated);
        }
        if (toBeEdited.days.length > 0) {
            editRegularHoursMultiDay(toBeEdited);
        }
        if (toBeDeleted.days.length > 0) {
            deleteRegularHoursMultiDay(toBeDeleted);
        }
        try {
            var promoRate = null;
            if(promotionalRate) {
                promoRate = Number(promotionalRate / 100);
            }
            var response = await axios({
                method: 'PUT',
                url: inventory_urls.editStore + "/" + storeId,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                },
                data: {
                    name: name,
                    phone: phone,
                    transaction_rate: Number(transactionRate / 100),
                    promotional_rate: promoRate ,
                    is_enabled: isEnabled,
                    sells_alcohol: sellsAlcohol,
                    permit_type: permitType
                }
            });
            setActiveRequest(false);
            history.push('/admin/inventory/store/'+storeId);
        } catch (err) {
            console.log(err);
            setActiveRequest(false);
            setError('Unable to update store information');
        }
    
    }

    const isValidated = () => {
        if(
            nameState === "has-success" && 
            addressState === "has-success" && 
            emailState === "has-success" && 
            phoneState === "has-success" &&
            transactionRateState === 'has-success'
        ) {
            onSave();
        } else {
            if (nameState !== "has-success") {
                setNameState("has-danger");
            }
            if(addressState !== "has-success") {
                setAddressState("has-danger");
            }
            if(emailState !== "has-success") {
                setEmailState("has-danger");
            }
            if(phoneState !== "has-success") {
                setPhoneState("has-danger");
            }
            if(transactionRateState !== "has-success") {
                setTransactionRateState("has-danger");
            }
            return false;
        }
    }

    const change = (e, type, stateNameEqualsTo) => {
        switch(type) {
            case "name":
                if(validators.verifyLength(e.target.value, stateNameEqualsTo)) {
                    setNameState("has-success");
                } else {
                    setNameState("has-danger");
                }
                setName(e.target.value);
                break;
            case "address":
                if(validators.verifyLength(e.target.value, stateNameEqualsTo)) {
                    setAddressState("has-success");
                } else {
                    setAddressState("has-danger");
                }
                setAddress(e.target.value);
                break;
            case "email":
                if(validators.verifyLength(e.target.value, stateNameEqualsTo) && validators.verifyEmail(e.target.value, stateNameEqualsTo)) {
                    setEmailState("has-success");
                } else {
                    setEmailState("has-danger");
                }
                setEmail(e.target.value);
                break;
            case "phone":
                if(validators.verifyLength(e.target.value, stateNameEqualsTo) && validators.verifyPhone(e.target.value, stateNameEqualsTo)) {
                    setPhoneState("has-success");
                } else {
                    setPhoneState("has-danger");
                }
                setPhone(e.target.value);
                break;
            case "transactionRate":
                if(validators.verifyLength(e.target.value.toString(), stateNameEqualsTo)) {
                    setTransactionRateState("has-success");
                } else {
                    setTransactionRateState("has-danger");
                }
                setTransactionRate(e.target.value);
                break;
            case "promotionalRate":
                setPromotionalRate(e.target.value);
                break;
            case "isEnabled":
                setIsEnabled(!isEnabled);
                break;
            case "sellsAlcohol":
                setSellsAlcohol(!sellsAlcohol);
                break;
            case "permitType":
                console.log("e: ", e);
                setPermitType(e);
                break;
                default: break;
        }
    }

    if(loading) return <Loading />;
    return (
        <>
        
        <Styles className="edit-store-container">
            <Alert 
                color="danger" 
                isOpen={error === "" ? false : true} 
                toggle={() => setError("")}
            > 
                <strong>{error}</strong>
            </Alert>
            <Modal isOpen={activeRequest} centered={true}>
                <div style={{padding: 50, textAlign: "center"}}>
                    <ModalHeader style={{justifyContent: 'center'}}>
                    <h3 style={{color: 'black'}}>Please wait...</h3>
                    </ModalHeader>
                    <Loader
                    type="TailSpin"
                    color="#00BFFF"
                    height={100}
                    width={100}
                    />
                </div>
            </Modal>
            {/* <Breadcrumb 
            name = {name}/> */}
            <Header onSave={onSave} isValidated={isValidated} storeId={storeId}/>
            <form>
                {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb">
                <Link to="/admin/inventory" style={{color: '#5e72e4'}}>
                    Stores
                </Link>
                <Link to={`/admin/inventory/store/${storeId}`} style={{color: '#5e72e4'}}>
                    {name}
                </Link>
            
                <Typography style={{color: '#5e72e4', fontWeight: 'bold'}}>Edit Store</Typography>
            </Breadcrumbs>
            {/* End Breadcrumbs */}
                <General 
                    fetchGeneralInfo={fetchGeneralInfo}
                    name={name}
                    phone={phone}
                    email={email}
                    address={address}
                    latitude={latitude}
                    longitude={longitude}
                    logo={logo}
                    transactionRate={transactionRate}
                    promotionalRate={promotionalRate}
                    isEnabled={isEnabled}
                    sellsAlcohol={sellsAlcohol}
                    nameState={nameState}
                    phoneState={phoneState}
                    emailState={emailState}
                    addressState={addressState}
                    transactionRateState={transactionRateState}
                    change={change}
                    permitType={permitType}
                />
                <div className="hours-container">
                    {/* <EditStoreHours /> */}
                    <RegularHours 
                        fetchRegularHours={fetchRegularHours}
                        oldStoreTimes={oldStoreTimes.days}
                        logo={logo} 
                        name={name} 
                        phone={phone} 
                        email={email} 
                        address={address}  
                        latitude={latitude} 
                        longitude={longitude}
                        change={change}
                        fetchTimezone={timezone}
                    />
                    {/* <SpecialHours /> */}
                </div>
            </form>
        </Styles>
        </>
    )
}

export default EditStoreContainer;