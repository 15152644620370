import React from 'react';
import { FormGroup, Label, Input, Col } from 'reactstrap';

const WightOptions = ({ errors, handleWeight, weight }) => {
  //! add checked to Input to solve the double click in order to check the radio button
  const WightSelections = ({ value, label }) => {
    return (
      <FormGroup check className="form-check-radio" id="shipment-weight">
        <Label check>
          <Input required type="radio" name="exampleRadio1" id="exampleRadio1" value={value} onClick={e => handleWeight(e)} checked={weight == value} />
          <span id="radio" className="form-check-sign" /> {label}
        </Label>
      </FormGroup>
    );
  };
  return (
    <>
      <Col md="auto">
        <Label inline for="shipment-weight" style={{ textAlign: 'left' }}>
          Total Shipment Weight
        </Label>
        <WightSelections value={50} label="Less than 50 lbs" />
        <WightSelections value={100} label="50 to 100 lbs" />
        <WightSelections value={200} label="100 to 200 lbs" />
        <WightSelections value={300} label="200 to 300 lbs" />
        <WightSelections value={400} label="300 to 400 lbs" />
        <WightSelections value={500} label="400 to 500 lbs" />
        <WightSelections value={1000} label="500 to 1000 lbs" />
        <WightSelections value={1500} label="1000 to 2000 lbs" />
        <WightSelections value={2500} label="2000 to 3000 lbs" />
      </Col>
      <Col md="auto" style={{ marginTop: 23, marginRight: 250 }}>
        <WightSelections value={3500} label="3000 to 4000 lbs" />
        <WightSelections value={4500} label="4000 to 5000 lbs" />
        <WightSelections value={6250} label="5000 to 7500 lbs" />
        <WightSelections value={8750} label="7500 to 10000 lbs" />
        <WightSelections value={11250} label="10000 to 12500 lbs" />
        <WightSelections value={13750} label="12500 to 15000 lbs" />
        <WightSelections value={16250} label="15000 to 17500 lbs" />
        <WightSelections value={18750} label="17500 to 20000 lbs" />
        <WightSelections value={20000} label=" over 20000 lbs" />
        <div hidden={!errors.weight}>
          <p style={{ color: 'red', marginTop: '4px' }}>Please give us your best estimate of the total delivery weight</p>
        </div>
      </Col>
    </>
  );
};

export default WightOptions;
