import moment from 'moment';

function formatETA(etaString) {
  const date = moment(etaString);

  if (!date.isValid()) {
    // If the date is not valid, return the original string
    return etaString;
  }

  // Format the date as "MMM D, YYYY h:mm A"
  const formattedDate = date.format('MMM D, YYYY h:mm A');

  return formattedDate;
}

export { formatETA };
