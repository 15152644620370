import React from 'react';
import { Styles } from './Styles/MerchantCardStyles';
import { Tooltip } from 'reactstrap';

const MerchantCard = props => {
  return (
    <Styles>
      <div className="container">
        <i id={'indicator-for-' + props.store.store_id} style={{ color: props.store.is_enabled ? '#2bbbae' : '#be1e2d' }} className="fas fa-circle indicator"></i>
        <h6>{props.store.name}</h6>
        <p>
          {props.store.city}, {props.store.state}
        </p>
        <Tooltip target={'indicator-for-' + props.store.store_id}>{props.store.is_enabled ? 'Enabled' : 'Disabled'}</Tooltip>
      </div>
    </Styles>
  );
};

export default MerchantCard;
