import styled from 'styled-components';

const Styles = styled.div`
  margin: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 88%;
  .user-profile-header-left {
    width: 35%;
  }
  .user-orders-right {
    width: 65%;
  }
`;

export default Styles;
